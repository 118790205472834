import React from 'react'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'

import '../Assets/Css/Inicio.css'
import imgen from '../Assets/Img/Persona.png'

interface IProps {
    Modal: boolean
    HideModal: any
    CambioFoto: any
    Request: boolean
    base64Image: any
    convertbase64: any
    Empleado: any
}

export default function  ModalCambioFoto(Props: IProps) {

    const imageSource = Props.Empleado.Foto
  ? `data:image/png;base64,${Props.Empleado.Foto}`
  : imgen;

    const RenderFooter = () => {
        return(
            <Button 
                label = { Props.Request ? "Cargando..." : "Aceptar" } 
                style = {{
                    background : '#4D80E4',
                    border : '1px solid #4D80E4'}}
                icon = { Props.Request ? "" : "pi pi-check" }
                className = "p-ml-auto"
                disabled = { Props.Request }
                autoFocus />

        )
    }
    
    return(
        <Dialog
            header='Cambio de Foto'
            visible={Props.Modal}
            style={{ width: '50vh' }}
            footer={RenderFooter()} 
            onHide={Props.HideModal}>
                <div className="modal-body p-mt-3" >
                <div className="list" style={{background:'white'}}>
                        <div className="TopBox">
                            <div className="Image" style={{ backgroundImage: `url(${imageSource})` }}></div>
                        </div>
                        <div className="BottonBoxGuardarFoto"> 
                                    <div className="file-select">
                                        <input 
                                            type="File"
                                            name='Foto'
                                            onChange={Props.convertbase64}
                                        />
                                    </div>
                                </div> 
                        </div>
                </div> 
        </Dialog>
    )
}