import { HTTP } from '../Http/Http'

export const ItemPlanPruebaRepository = {
    ListarPaginacion: async (Paginacion : any) => {
        return await HTTP.POST('ItemPlanPrueba/ListarPaginado',Paginacion,{})
    },
    CrearActualizar: async (ItemPlanPrueba: any) => {
        return await HTTP.POST('ItemPlanPrueba/CrearActualizar',ItemPlanPrueba,{})
    },
}

