import { SolicitudRepository } from '../../Infrastructure/Repositories/SolicitudRepository'
import { ISolicitud } from '../Models/ISolicitud'

interface IErroresSolicitud{
    Nombre?: string
    Detalle?: string
    Cliente?: string
    Empleado?: string
    Producto?: string
    VersionProducto?: string
}

const ValidarSchema = (Solicitud: ISolicitud) => {
    const Errores: IErroresSolicitud = {}
    if(!Solicitud.Nombre) Errores.Nombre  = 'Nombre es requerido'
    if(!Solicitud.Detalle) Errores.Detalle = 'Detalle es requerido'
    if(!Solicitud.Cliente.PKID) Errores.Cliente  = 'Cliente es requerido'
    if(!Solicitud.Empleado.PKID) Errores.Empleado = 'Empleado es requerido'
    if(!Solicitud.Producto.PKID) Errores.Producto = 'Producto es requerido'
    if(!Solicitud.VersionProducto.PKID) Errores.VersionProducto = 'Versión es requerido'
    return Errores
}

const ObtenerObjetoEstado = (Array: Array<ISolicitud>, PKID: string, IDEstado: number) => {
    let aObj: Array<ISolicitud> =  Array.filter( (Obj : ISolicitud) =>{
        return Obj.PKID === parseInt(PKID,10) 
    })
    let Obj: ISolicitud = aObj[0] 
    return {
        ...Obj,
        Estado:{
            ...Obj.Estado,
            PKID: IDEstado
        }
    }
}

export const SolicitudService = {
    ValidarSchema,
    ObtenerObjetoEstado,
    Listar: () => {
        return SolicitudRepository.Listar()
    },
    ListarPaginacion: (Paginacion: any) => {
        return SolicitudRepository.ListarPaginacion(Paginacion)
    },
    CrearActualizar: (Solicitud: ISolicitud) => {
        return SolicitudRepository.CrearActualizar(Solicitud)
    }
}