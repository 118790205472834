import React from 'react'
import ItemPlanDePruebaHeader from '../Components/ItemPlanDePruebaHeader'
import ItemPlanDePruebaBody from '../Components/ItemPlanDePruebaBody'
import CasoPruebaVersionModal from '../Components/CasoPruebaVersionModal'
import CasoPruebaModalPaso from '../Components/CasoPruebaModalPaso'
import { getItemPlanPrueba, setCasoPruebaVersion } from '../../Services/LocalStorage'
import { getPlanPrueba, setItemPlanPrueba } from '../../Services/LocalStorage'
import ListaCasoPruebaModal from '../Components/ListaCasoPruebaModal'
import axios from 'axios'
import { Toast } from 'primereact/toast'
import { initAxiosInterceptors , url } from './../../Services/Axios'
import { AuthHelper } from '../../Domain/Utils/AuthHelper'
import { CasoPruebaService } from '../../Domain/Services/CasoPruebaService'
import { CalidadService } from '../../Domain/Services/CalidadService'
import { ItemPlanPruebaService } from '../../Domain/Services/ItemPlanPruebaService'

import { IItemPlanDePrueba } from '../../Domain/Models/IItemPlanDePrueba'
import { ICasoPruebaVersion } from '../../Domain/Models/ICasoPruebaVersion'
import { IPaso } from '../../Domain/Models/IPaso'
import { IPaginacion } from '../../Domain/Models/IPaginacion'
import CasoPrueba from './CasoPrueba'

interface IProps {
}

interface IState {
    Pendiente: IPaginacion
    EnCurso: IPaginacion
    Hecho: IPaginacion
    ListaPendienteOriginal: IPaginacion
    ListaEnCursoOriginal: IPaginacion
    ListaHechoOriginal: IPaginacion
    ItemPlanDePrueba: IItemPlanDePrueba
    Paso: IPaso   
    FiltroNombreCasoPrueba : String
    ListaCasoPrueba: Array<object>
    ListaCasoPruebaSeleccionado: Array<object>
    ListaOriginalCasoPrueba: Array<object>
    ListaCasoPruebaDDL: Array<object>
    ListaTipoPrueba: Array<object>
    ListaModulo: Array<object>
    Modal: boolean
    ModalPaso: boolean
    ModalListaCasoPrueba: boolean
    CasoPruebaVersion: ICasoPruebaVersion
    ErrorItemPlanDePrueba: any
    ErrorCasoPruebaVersion: any
    ErroresPaso: any
    Request: boolean
}

export default class ItemPlanDePrueba extends React.Component<IProps, IState> {

    cPaso = {
        PKID : 0,
        NumeroPaso : 1,
        Accion : '',
        ResultadoEsperado : '',
        Correcto : 1,
        FechaCreacion : new Date()
    }

    cPaginacion = {
        Lista: [],
        Paginacion: {
            NumeroPagina: 1, 
            RegistrosPagina: 5, 
            NumeroPaginas: 0, 
            IDEstado: 1, 
            IDUsuario: parseInt(AuthHelper.GetDecoded().sub,10),
            IDItemPlanPrueba: getItemPlanPrueba() ? JSON.parse(getItemPlanPrueba() || '{}').PKID : 0
        },
        Loading: true
    }

    cItemPlanDePrueba = {
        PKID:0,
        IDPlanDePrueba:0,
        Nombre:'',
        FechaCreacion: new Date(),
        Requerimiento: {
            PKID : 0,
            Requisito : '',
            Estimacion : 0,
            FactorAjuste : 1,
            EstimacionAjustada : 0,
            IDBackLog : 0,
            FechaInicio : new Date(),
            FechaFin : new Date(),
            PorcentajeAvance : 0,
            Estado:{
                PKID: 0,
                Descripcion: ''
            },
            HorasEjecutadas : 0,
            ContextoEstandar : '',
            ProblemaEstandar : '',
            Responsable:{
                PKID:0,
                Nombre:''
            },
            Solicitud:{
                PKID:0,
                Nombre:'',
                Empleado:{
                    PKID:0,
                    Codigo:'',
                    Nombre:'',
                    Area:{
                        PKID:0,
                        Codigo:'',
                        Nombre:''
                    }
                }
            },            
            ArchivosAdjuntos:[],
            NumeroOCS: '',
            DescripcionOCS: '',
            Descripcion:'',
            ImplementacionItemBackLog:{
                PKID:0,
                Nombre:''
            },
            ArchivosSolucionAdjuntos: []
        },
        Empleado:{
            PKID: parseInt(AuthHelper.GetDecoded().sub,10),
            Nombre: AuthHelper.GetDecoded().Empleado
        },
        Estado: {
            PKID: 1,
            Descripcion: 'Pendiente'
        },
        EsDeRegresion: false,
        ListaCasoPrueba: [],
        ListaCasoPruebaSeleccionado: [],
        ListaCheckHabilitado: []
    }

    cCasoPruebaVersion = {
        PKID: 0,
        IDItemPlanPrueba: getItemPlanPrueba() ? JSON.parse(getItemPlanPrueba() || '{}').PKID : 0,
        IDCasoPrueba: 0,
        Nombre: '',
        Descripcion: '',
        Modulo: {
            PKID: 0,
            Nombre: ''
        },
        Version: 0,
        FechaCreacion: new Date(),
        Empleado: {
            PKID: parseInt(AuthHelper.GetDecoded().sub,10),
            Nombre: AuthHelper.GetDecoded().Empleado
        },
        TipoPrueba: {
            PKID: 0,
            Nombre: ''
        },
        Pasos: [],        
        Incidentes: [],
        Estado: {
            PKID: 1,
            Descripcion: 'Pendiente'
        },
    }

    constructor(Props: any){
        super(Props)
        this.state={
            Pendiente: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 1}},
            EnCurso: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 2}},
            Hecho: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 3}},
            ListaPendienteOriginal: { ...this.cPaginacion, Paginacion: { ...this.cPaginacion.Paginacion, IDEstado: 1 } },
            ListaEnCursoOriginal: { ...this.cPaginacion, Paginacion: { ...this.cPaginacion.Paginacion, IDEstado: 2 } },
            ListaHechoOriginal: { ...this.cPaginacion, Paginacion: { ...this.cPaginacion.Paginacion, IDEstado: 3 } },
            ItemPlanDePrueba: this.cItemPlanDePrueba,
            Paso: this.cPaso,
            ListaCasoPrueba: [],
            ListaCasoPruebaSeleccionado: [],
            ListaOriginalCasoPrueba: [],
            ListaCasoPruebaDDL: [],
            ListaTipoPrueba: [],
            ListaModulo: [],
            Modal: false,
            ModalPaso: false,
            ModalListaCasoPrueba: false,
            CasoPruebaVersion: this.cCasoPruebaVersion,
            ErrorItemPlanDePrueba : {},
            ErrorCasoPruebaVersion : {},
            ErroresPaso : {},
            Request : false,          
        FiltroNombreCasoPrueba : ''      
        }
    }

    componentDidMount(){
        if(getItemPlanPrueba()){
            this.setState({
                ItemPlanDePrueba: JSON.parse(getItemPlanPrueba() || '{}')
            },()=>{
                this.ListarCasoPruebaVersionPaginado('Pendiente')
                this.ListarCasoPruebaVersionPaginado('EnCurso')
                this.ListarCasoPruebaVersionPaginado('Hecho')
                if(this.state.ItemPlanDePrueba.Requerimiento !== null && this.state.ItemPlanDePrueba.Requerimiento !== undefined){
                    if(this.state.ItemPlanDePrueba.Requerimiento.PKID > 0){
                        //this.ListarCasoPruebaDLL()
                    }                   
                }
                this.ListarTipoPrueba()
                this.ListarModulo()
            })
        }else{
            alert('No se encuentra relacionado a ningun item plan de prueba.')
        }
        this.ListarCasoPruebaAll(getItemPlanPrueba() ? JSON.parse(getItemPlanPrueba() || '{}').PKID : 0);
    }



    ListarCasoPruebaAll = (Filtro:String) => {        
        CasoPruebaService.Listar(Filtro)
        .then( (response: any) => {
            console.log(JSON.parse(response.data))
            if (response.success) {
                this.setState({
                    ListaCasoPrueba:JSON.parse(response.data),
                    ListaOriginalCasoPrueba:JSON.parse(response.data),
                })
            }else{
                alert(response.data)
            }
        })
        .catch((error) => {
            alert(error)
        })
    }

    ListarCasoPruebaVersionPaginado = ( Estado : string) => {
        CasoPruebaService.ListarVersionPaginacion(
            {
                'Pendiente': this.state.Pendiente.Paginacion,
                'EnCurso' : this.state.EnCurso.Paginacion,
                'Hecho' : this.state.Hecho.Paginacion
            }[Estado]
        )
        .then((response: any) => {
            if(response.success){
                let jParse = JSON.parse(response.data)
                let rCasoPruebaVersion: Array<ICasoPruebaVersion | any> = jParse.CasoPruebaVersion ? jParse.CasoPruebaVersion : []
                let rPaginacion: any = jParse.Paginacion
                switch(Estado){
                    case 'Pendiente': 
                        this.setState({Pendiente: {Lista: this.state.Pendiente.Lista.concat(rCasoPruebaVersion),Paginacion: rPaginacion,Loading: false}})
                        this.setState({ListaPendienteOriginal: {Lista: this.state.ListaPendienteOriginal.Lista.concat(rCasoPruebaVersion),Paginacion: rPaginacion,Loading: false}})
                        break
                    case 'EnCurso' : 
                        this.setState({EnCurso: {Lista: this.state.EnCurso.Lista.concat(rCasoPruebaVersion),Paginacion: rPaginacion,Loading: false}})
                        this.setState({ListaEnCursoOriginal: {Lista: this.state.ListaEnCursoOriginal.Lista.concat(rCasoPruebaVersion),Paginacion: rPaginacion,Loading: false}})
                        break 
                    case 'Hecho' : 
                        this.setState({Hecho: {Lista: this.state.Hecho.Lista.concat(rCasoPruebaVersion),Paginacion: rPaginacion,Loading: false}})
                        this.setState({ListaHechoOriginal: {Lista: this.state.ListaHechoOriginal.Lista.concat(rCasoPruebaVersion),Paginacion: rPaginacion,Loading: false}})           
                        break
                    default:
                        break
                }
            }else{
                alert(response.data)
            }
        })
    }

    ListarCasoPruebaDLL(){
        CalidadService.ListarCasoPruebaDLL(this.state.ItemPlanDePrueba.Requerimiento.PKID)
        .then((response: any) => {
            if (response.success) {
                let jList1: Array<object> = [], jList2: Array<object> = []
                Object.keys(JSON.parse(response.data)).forEach(function(key){
                    jList2.push(JSON.parse(response.data)[key])
                    jList1.push({
                        code:JSON.parse(response.data)[key].PKID,
                        name:JSON.parse(response.data)[key].Nombre
                    })
                })
                this.setState({
                    ListaCasoPruebaDDL: jList1,
                    ListaCasoPrueba: jList2
                })
            }else{
                //alert('No hay casos de prueba, por favor primero cree un caso de prueba en el módulo de "CASO  DE PRUEBA" '+ response.data)
            }
        })
        
    }

    
    ListarCasoPrueba = (Filtro:String) => {
        CasoPruebaService.Listar(Filtro)
        .then( (response: any) => {
            if (response.success) {
                console.log(response.data)
                let jList1: Array<object> = [], jList2: Array<object> = []
                Object.keys(JSON.parse(response.data)).forEach(function(key){
                    jList2.push(JSON.parse(response.data)[key])
                    jList1.push({
                        code:JSON.parse(response.data)[key].PKID,
                        name:JSON.parse(response.data)[key].Nombre
                    })
                })
                this.setState({
                    ListaCasoPruebaDDL: jList1,
                    ListaCasoPrueba: jList2
                })
            }else{
                alert(response.data)
            }
        })
        .catch((error) => {
            alert(error)
        })
    }

    ListarTipoPrueba(){
        CasoPruebaService.ListarTipoPrueba()
        .then( (response: any) => {
            if (response.success) {
                let jLista: Array<object>=[]
                Object.keys(JSON.parse(response.data)).forEach(function(key){
                    jLista.push({
                        code:JSON.parse(response.data)[key].PKID,
                        name:JSON.parse(response.data)[key].Nombre
                    })
                })
                this.setState({
                    ListaTipoPrueba: jLista
                })
            }else{
                alert(response.data)
            }
        })
        .catch( (error) => {
            alert(error)
        })
    }

    ListarModulo(){
        CalidadService.ListarModulo()
        .then( (response: any) => {
            if (response.success) {
                let jLista: Array<object>=[]
                Object.keys(JSON.parse(response.data)).forEach(function(key){
                    jLista.push({
                        code:JSON.parse(response.data)[key].PKID,
                        name:JSON.parse(response.data)[key].Nombre
                    })
                })
                this.setState({ListaModulo : jLista})
            }else{
                alert(response.data)
            }
        })
        .catch( (Error) => {
            alert(Error)
        })
    }

    HandleChangePaso = (Event: any) => {
        this.setState({
            Paso:{
                ...this.state.Paso,
                [Event.target.name]: Event.target.value
            }
        })
    }

    HandleChangeCasoPrueba = (Event: any) => {        
        this.setState({
            FiltroNombreCasoPrueba:Event.target.value
        })
    }

    OnRowSelectCasoPrueba = (row: any) => {        
        let Obj: any = this.state.ListaCasoPruebaSeleccionado.filter( (e: any) => 
        { return e.PKID === row.data.PKID})
        this.setState({
            CasoPruebaVersion: Obj[0]
        },()=>{
            this.setState({
                CasoPruebaVersion: {
                    ...this.state.CasoPruebaVersion,
                    PKID: 0,
                    IDCasoPrueba: Obj[0].PKID,
                    IDItemPlanPrueba: this.state.ItemPlanDePrueba.PKID,
                    Empleado: {
                        PKID: parseInt(AuthHelper.GetDecoded().sub,10),
                        Nombre: AuthHelper.GetDecoded().Empleado
                    }
                }
            })
        })        
    }



    OnRowCheckSelect= (v: any) => {        
        this.setState({ListaCasoPruebaSeleccionado: v,
            CasoPruebaVersion: this.cCasoPruebaVersion        
        })   
    }

    HandleSubmitPaso = (Event: any) => {
        Event.preventDefault()
        const result = ItemPlanPruebaService.ValidarPaso(this.state.Paso)
        if(Object.keys(result).length) return this.setState({ErroresPaso : result});
        else this.CrearActualizarPaso()
    }



    CrearActualizarPaso(){
        let listaPasos=this.state.CasoPruebaVersion.Pasos
        if(listaPasos.filter( (e: any) => { return e.NumeroPaso === this.state.Paso.NumeroPaso}).length > 0){
            listaPasos = listaPasos.filter( (e: any) => { return e.NumeroPaso !== this.state.Paso.NumeroPaso})
        }
        listaPasos.push(this.state.Paso)
        listaPasos.sort( function(a: any, b: any) {
            return a.NumeroPaso - b.NumeroPaso
        })
        this.setState({
            Paso : this.cPaso,
            CasoPruebaVersion:{
                ...this.state.CasoPruebaVersion,
                Pasos:listaPasos
            }
        },() => this.HideVisibleModalPaso(false))
    }

    CrearActualizarListaCasoPrueba(){
        let listaPasos=this.state.CasoPruebaVersion.Pasos
        if(listaPasos.filter( (e: any) => { return e.NumeroPaso === this.state.Paso.NumeroPaso}).length > 0){
            listaPasos = listaPasos.filter( (e: any) => { return e.NumeroPaso !== this.state.Paso.NumeroPaso})
        }
        listaPasos.push(this.state.Paso)
        listaPasos.sort( function(a: any, b: any) {
            return a.NumeroPaso - b.NumeroPaso
        })
        this.setState({
            Paso : this.cPaso,
            CasoPruebaVersion:{
                ...this.state.CasoPruebaVersion,
                Pasos:listaPasos
            }
        },() => this.HideVisibleModalListaCasoPrueba(false))
    }

    Paginacion = (Estado : string) => {
        if(
            (
                {
                    'Pendiente': ((this.state.Pendiente.Paginacion.NumeroPagina < this.state.Pendiente.Paginacion.NumeroPaginas) && !this.state.Pendiente.Loading),
                    'EnCurso' : ((this.state.EnCurso.Paginacion.NumeroPagina < this.state.EnCurso.Paginacion.NumeroPaginas) && !this.state.EnCurso.Loading),
                    'Hecho' : ((this.state.Hecho.Paginacion.NumeroPagina < this.state.Hecho.Paginacion.NumeroPaginas) && !this.state.Hecho.Loading)
                }[Estado]
            )){
                switch(Estado){
                    case 'Pendiente':
                        this.setState({
                            Pendiente: {
                                ...this.state.Pendiente,
                                Paginacion:{
                                    ...this.state.Pendiente.Paginacion,
                                    NumeroPagina: this.state.Pendiente.Paginacion.NumeroPagina + 1},
                                Loading: true
                            }
                        },() => this.ListarCasoPruebaVersionPaginado('Pendiente'))
                        break
                    case 'EnCurso' : 
                        this.setState({
                            EnCurso: {
                                ...this.state.EnCurso
                                ,Paginacion:{
                                    ...this.state.EnCurso.Paginacion,
                                    NumeroPagina: this.state.EnCurso.Paginacion.NumeroPagina + 1},
                                Loading: true
                            }
                        },() => this.ListarCasoPruebaVersionPaginado('EnCurso'))
                        break
                    case 'Hecho' : 
                        this.setState({
                            Hecho: {
                                ...this.state.Hecho,
                                Paginacion:{
                                    ...this.state.Hecho.Paginacion,
                                    NumeroPagina: this.state.Hecho.Paginacion.NumeroPagina + 1},
                                Loading: true
                            }
                        },() => this.ListarCasoPruebaVersionPaginado('Hecho'))
                        break
                    default :
                        break
                }
        }
    }

    MoverEstado = ( EstadoI : string, EstadoF : string, PKID : string) => {
        console.log(EstadoI)
        console.log(EstadoF)
        if(EstadoI !== EstadoF){
            let Obj = {'Pendiente': this.state.Pendiente.Lista,'EnCurso' : this.state.EnCurso.Lista,'Hecho' : this.state.Hecho.Lista}[EstadoI] || []
            switch(EstadoF){
                case 'Pendiente' :
                    this.setState({
                        CasoPruebaVersion: CasoPruebaService.ObtenerObjetoEstado(Obj,PKID,1)
                    },() => this.CrearActualizarCasoPruebaVersion())
                    break
                case 'EnCurso' :
                    this.setState({
                        CasoPruebaVersion: CasoPruebaService.ObtenerObjetoEstado(Obj,PKID,2)
                    },() => this.CrearActualizarCasoPruebaVersion())
                    break
                case 'Hecho' :
                    this.setState({
                        CasoPruebaVersion: CasoPruebaService.ObtenerObjetoEstado(Obj,PKID,3)
                    },() => this.CrearActualizarCasoPruebaVersion())
                    break
                default:
                    break
            }
        }
    }

    CrearActualizarCasoPruebaVersion(){
        let ListaCasoPruebaObject : Array<object> = new Array<Object>();
        
        ListaCasoPruebaObject[0] = this.state.CasoPruebaVersion;
        
        CasoPruebaService.CrearActualizarVersion(ListaCasoPruebaObject)
        .then( (response: any) => {
            if(response.success){
                this.setState({
                    Pendiente: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 1}},
                    EnCurso: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 2}},
                    Hecho: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 3}},
                    CasoPruebaVersion : this.cCasoPruebaVersion,
                    ErrorCasoPruebaVersion : {},
                    Modal : false
                },() => this.componentDidMount())
            }else{
                alert(response.data)
            }
        })
        .catch( (error) => {
            alert(error)
        })
        .finally(() => {
            this.setState({Request: false})
        }) 
    }

    EditarCasoPrueba = (Event: any) => {
        setCasoPruebaVersion(JSON.stringify(Event));
        window.location.href = 'ItemPlanDePrueba/CasoPruebaVersion'
    }

    EliminarCasoPrueba = (Event: any) =>{
        this.EliminarCasoPruebaVersion(Event.PKID)
    }

    EliminarCasoPruebaVersion(PKID: number){        
        axios.post(url()+'Calidad/EliminarCasoPruebaVersion?id='+PKID)
        .then( (response) => {
            if(response.data.success){
                this.setState({
                    Pendiente: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 1}},
                    EnCurso: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 2}},
                    Hecho: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 3}},
                    CasoPruebaVersion : this.cCasoPruebaVersion,
                    ErrorCasoPruebaVersion : {},
                    Modal : false
                },() => this.componentDidMount()) 
            }else{
                throw new Error(response.data.data)
            }
        })
        .catch( (error) => {
            alert(error.message)            
        })
    }

    HandleChange = (Event: any) => {
        switch(Event.target.name){
            case 'CasoPrueba':
                let Obj: any = this.state.ListaCasoPrueba.filter( (e: any) => { return e.PKID === Event.value.code})
                this.setState({
                    CasoPruebaVersion: Obj[0]
                },()=>{
                    this.setState({
                        CasoPruebaVersion: {
                            ...this.state.CasoPruebaVersion,
                            PKID: 0,
                            IDCasoPrueba: Obj[0].PKID,
                            IDItemPlanPrueba: this.state.ItemPlanDePrueba.PKID,
                            Empleado: {
                                PKID: parseInt(AuthHelper.GetDecoded().sub,10),
                                Nombre: AuthHelper.GetDecoded().Empleado
                            }
                        }
                    })
                })
                break
            case 'Modulo':
                break
            case 'TipoPrueba':
                break
            default:
                this.setState({
                    CasoPruebaVersion:{
                        ...this.state.CasoPruebaVersion,
                        [Event.target.name]: Event.target.value
                    }
                })
                break
        }
    }

    CrearNuevoPaso = () => {
        this.setState({
            Paso:{
                ...this.cPaso,
                NumeroPaso: this.state.CasoPruebaVersion.Pasos.length + 1
            }
        },() => this.HideVisibleModalPaso(true))
    }

    AbrirListaCasoPrueba = () => {
        this.setState({
            Paso:{
                ...this.cPaso,
                NumeroPaso: this.state.CasoPruebaVersion.Pasos.length + 1
            },
            ListaCasoPrueba:this.state.ListaOriginalCasoPrueba,
        },() => this.HideVisibleModalListaCasoPrueba(true))
    }

    HideVisibleModalPaso = (Mode: boolean) => {
        this.setState({
            ModalPaso: Mode
        })
    }

    HideVisibleModalListaCasoPrueba = (Mode: boolean) => {
        this.setState({
            ModalListaCasoPrueba: Mode
        })
    }

    ActualizarPaso = (Event: any)=>{
        this.setState({
            Paso : Event,
            ErroresPaso: {}
        },() => this.HideVisibleModalPaso(true))
    }

    EliminarPaso = (RowData: any) => {
        let lPasos = this.state.CasoPruebaVersion.Pasos
        if(lPasos.filter( (Event: any) => { return Event.NumeroPaso === RowData.NumeroPaso}).length > 0){
            lPasos = lPasos.filter( (Event: any) => { return Event.NumeroPaso !== RowData.NumeroPaso})
        }
        var lOrdenado: Array<object> = []
        if(lPasos.length > 0){
            lPasos.forEach((Element,Index) => {
                Element = {
                    ...Element,
                    NumeroPaso : Index + 1
                }
                lOrdenado.push(Element)
            });
        }
        this.setState({
            CasoPruebaVersion:{
                ...this.state.CasoPruebaVersion,
                Pasos: lOrdenado
            }
        })
    }

    HandleSubmit = (Event: any) => {        
        Event.preventDefault();        
        let IDItemPlanPrueba = this.state.ItemPlanDePrueba.PKID;         
        let ListaCasoPruebaSeleccionadoObject : Array<object>  = this.state.ListaCasoPruebaSeleccionado;        
            this.setState({
                Request : true
            });            
            Object.keys(ListaCasoPruebaSeleccionadoObject).forEach((name, index) => {                 
                let CasoPruebaVersionObject: any =  ListaCasoPruebaSeleccionadoObject[index]               
                CasoPruebaVersionObject.IDCasoPrueba = CasoPruebaVersionObject.PKID
                CasoPruebaVersionObject.IDItemPlanPrueba = IDItemPlanPrueba
                CasoPruebaVersionObject.Empleado.PKID = parseInt(AuthHelper.GetDecoded().sub,10)
                CasoPruebaVersionObject.Empleado.Nombre = AuthHelper.GetDecoded().Empleado  
                CasoPruebaVersionObject.PKID = 0                
            });
            CasoPruebaService.CrearActualizarVersion(ListaCasoPruebaSeleccionadoObject)
                .then( (response: any) => {
                    if(response.success){                         
                            this.ShowResult(); 
                    }else{                       
                        alert(response.data);                                               
                    }
                })
                .catch( (error) => {
                    alert(error); 
                });              
        this.setState({Request: false});                                                   
    }

 

    ShowResult() {        
            this.setState({
                Pendiente: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 1}},
                EnCurso: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 2}},
                Hecho: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 3}},
                CasoPruebaVersion : this.cCasoPruebaVersion,
                ErrorCasoPruebaVersion : {},
                Modal : false
            },() => {this.componentDidMount();}); 
    }

    HandleChangeItemPlanDePruebas = (Event: any) => {
        this.setState({
            ItemPlanDePrueba:{
                ...this.state.ItemPlanDePrueba,
                [Event.target.name]: Event.target.value
            }
        })
    }

    ActualizarItemPlanDePrueba = (Event: any) => {
        Event.preventDefault()
        const result = ItemPlanPruebaService.ValidarSchema(this.state.ItemPlanDePrueba)
        if(Object.keys(result).length){
            return this.setState({ErrorItemPlanDePrueba: result})
        }else{
            this.setState({
                Request : true
            }, () => this.CrearActualizarItemPlanPruebas())
        }
    }

    CrearActualizarItemPlanPruebas(){
        ItemPlanPruebaService.CrearActualizar(this.state.ItemPlanDePrueba)
        .then( (response: any) => {
            if(response.success){
                setItemPlanPrueba(JSON.stringify(this.state.ItemPlanDePrueba))
                this.setState({
                    Pendiente: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 1}},
                    EnCurso: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 2}},
                    Hecho: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 3}},
                    ErrorItemPlanDePrueba : {}
                },() => this.componentDidMount())

            }else{
                alert(response.data)
            }
        })
        .catch( (Error) => {
            alert(Error)
        })
        .finally( () => {
            this.setState({ Request : false})
        })
    }
    
    HandleChangeBuscador = (e: any) => {
        const ListaPendienteOriginal = this.state.ListaPendienteOriginal.Lista
        const ListaEnCursoOriginal = this.state.ListaEnCursoOriginal.Lista
        const ListaHechoOriginal = this.state.ListaHechoOriginal.Lista

        const ListaPendienteFiltoBefore = this.state.ListaPendienteOriginal.Lista
        const ListaEnCursoFiltroBefore = this.state.ListaEnCursoOriginal.Lista
        const ListaHechoFiltroBefore = this.state.ListaHechoOriginal.Lista

        if (e.target.value.length===0) {

            this.setState({
                Pendiente: {
                    ...this.state.Pendiente,
                    Lista: ListaPendienteOriginal
                }
            })
            this.setState({
                EnCurso: {
                    ...this.state.EnCurso,
                    Lista: ListaEnCursoOriginal
                }
            })
            this.setState({
                Hecho: {
                    ...this.state.Hecho,
                    Lista: ListaHechoOriginal
                }
            })

        } else {
            const listaFiltradaPendiente = ListaPendienteFiltoBefore.filter((list) => list.Nombre.toLowerCase().includes(e.target.value.toLowerCase()))
            const listaFiltradaEnCurso = ListaEnCursoFiltroBefore.filter((list) => list.Nombre.toLowerCase().includes(e.target.value.toLowerCase()))
            const listaFiltradaHecho = ListaHechoFiltroBefore.filter((list) => list.Nombre.toLowerCase().includes(e.target.value.toLowerCase()))

            this.setState({
                Pendiente: {
                    ...this.state.Pendiente,
                    Lista: listaFiltradaPendiente
                }
            })
            this.setState({
                EnCurso: {
                    ...this.state.EnCurso,
                    Lista: listaFiltradaEnCurso
                }
            })
            this.setState({
                Hecho: {
                    ...this.state.Hecho,
                    Lista: listaFiltradaHecho
                }
            })
        }
    }
    HandleChangeCheckItemPlanDePrueba = (event:any, PKID:any) => {
        //console.log(event.target.name)
        //console.log(PKID)
        /*
        let listaChecks=this.state.ItemPlanDePrueba.ListaCheckHabilitado
        if(listaPasos.filter( (e: any) => { return e.NumeroPaso === this.state.Paso.NumeroPaso}).length > 0){
            listaPasos = listaPasos.filter( (e: any) => { return e.NumeroPaso !== this.state.Paso.NumeroPaso})
        }
        listaPasos.push(this.state.Paso)

        this.setState({
            ItemPlanDePrueba: {
                ...this.state.ItemPlanDePrueba,
                ListaCheckHabilitado: event.target.name
            }
        })
        */
        console.log(this.state.ItemPlanDePrueba.ListaCheckHabilitado)
        /*
        const { checked } = event.target;
        setEntidades(prevEntidades => {
            return prevEntidades.map(entidad => {
                if (entidad.PKID === PKID) {
                    return { ...entidad, CheckHabilitado: checked };
                }
                return entidad;
            });
        });
        */
        /*
        let IDItemPlanPrueba = this.state.ItemPlanDePrueba.PKID;         
        let ListaCasoPruebaSeleccionadoObject : Array<object>  = this.state.ListaCasoPruebaSeleccionado;        
            this.setState({
                Request : true
            });            
            Object.keys(ListaCasoPruebaSeleccionadoObject).forEach((name, index) => {                 
                let CasoPruebaVersionObject: any =  ListaCasoPruebaSeleccionadoObject[index]               
                CasoPruebaVersionObject.IDCasoPrueba = CasoPruebaVersionObject.PKID
                CasoPruebaVersionObject.IDItemPlanPrueba = IDItemPlanPrueba
                CasoPruebaVersionObject.Empleado.PKID = parseInt(AuthHelper.GetDecoded().sub,10)
                CasoPruebaVersionObject.Empleado.Nombre = AuthHelper.GetDecoded().Empleado  
                CasoPruebaVersionObject.PKID = 0                
            });
        */
    };
    
    render(){
        return(
            <div className="container">
                <ItemPlanDePruebaHeader
                    HandleChange= {this.HandleChangeItemPlanDePruebas}
                    FormValues= {this.state.ItemPlanDePrueba}
                    ActualizarItemPlanDePrueba= {this.ActualizarItemPlanDePrueba}
                    Errores= {this.state.ErrorItemPlanDePrueba}
                    Request= {this.state.Request}
                    OnChangeBuscador = {this.HandleChangeBuscador}/>
                <ItemPlanDePruebaBody
                    Paginacion= {this.Paginacion}
                    Pendiente= {this.state.Pendiente}
                    EnCurso= {this.state.EnCurso}
                    Hecho= {this.state.Hecho}
                    OnResponse= {() => {
                        this.setState(({
                            Modal: true,
                        ListaCasoPruebaSeleccionado:[]
                        }))
                    }}
                    EditarCasoPrueba={this.EditarCasoPrueba}
                    EliminarCasoPruebaVersion={this.EliminarCasoPrueba}
                    MoverEstado = {this.MoverEstado}
                    HandleChangeCheckItemPlanDePrueba = {this.HandleChangeCheckItemPlanDePrueba}
                    ListaCheckHabilitado={this.state.ItemPlanDePrueba.ListaCheckHabilitado} />
                
                    <CasoPruebaVersionModal
                        Modal= {this.state.Modal}
                        HideModal= {() =>
                            this.setState({
                                CasoPruebaVersion : this.cCasoPruebaVersion,
                                ErrorCasoPruebaVersion : {},
                                Modal : false
                            })
                        }
                        FormValues= {this.state.CasoPruebaVersion}
                        HandleChange= {this.HandleChange}
                        CrearNuevoPaso= {this.CrearNuevoPaso}
                        AbrirListaCasoPrueba= {this.AbrirListaCasoPrueba}
                        ActualizarPaso= {this.ActualizarPaso}
                        EliminarPaso= {this.EliminarPaso}
                        ListaCasoPrueba= {this.state.ListaCasoPruebaDDL}
                        ListaCasoPruebaSeleccionado=  {this.state.ListaCasoPruebaSeleccionado}
                        ListaModulo= {this.state.ListaModulo}
                        ListaTipoPrueba= {this.state.ListaTipoPrueba}
                        OnRowSelectCasoPrueba= {this.OnRowSelectCasoPrueba}
                        Errores= {this.state.ErrorCasoPruebaVersion}
                        HandleSubmit = {this.HandleSubmit}
                        Request= {this.state.Request}/>
                
                <form autoComplete="Off" onSubmit={this.HandleSubmitPaso}>
                    <CasoPruebaModalPaso
                        Modal = {this.state.ModalPaso}
                        HideModal= {() => this.HideVisibleModalPaso(false)}
                        FormValues = {this.state.Paso}
                        HandleChange = {this.HandleChangePaso}
                        Errores = {this.state.ErroresPaso}/>
                </form>
               
                    <ListaCasoPruebaModal
                        Modal = {this.state.ModalListaCasoPrueba}
                        HideModal= {() => this.HideVisibleModalListaCasoPrueba(false)}
                        FormValues = {this.state.ListaCasoPrueba}
                        ListaCasoPruebaSeleccionado = {this.state.ListaCasoPruebaSeleccionado}                       
                        FiltroNombreCasoPrueba = {this.state.FiltroNombreCasoPrueba}
                        HandleChange = {this.HandleChangeCasoPrueba}                        
                        OnRowCheckSelect = {this.OnRowCheckSelect}                        
                        Errores = {this.state.ErroresPaso}
                        OnChangeBuscador = {this.HandleChangeBuscador}/>
                
            </div>
        )
    }
}


