import React from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button'

export default function DTEmpleados(props : any){

    const header = (
        <div className="p-d-flex p-jc-between">
            <h4>Empleados</h4>
            <Button 
                label="Agregar Empleado" 
                onClick={props.openModal} 
                autoFocus/>
        </div>
    )

    return(
        <div className="datatable-responsive-demo">
            <DataTable 
                value={props.ListaEmpleado} 
                className="p-datatable-responsive-demo" 
                onSelectionChange={e => props.handleSelectRow(e.value)} 
                selectionMode="single"
                paginator 
                rows={10} 
                header={header}>
                <Column field="Codigo" header="Codigo"/>
                <Column field="Nombre" header="Nombre"/>
                <Column field="CorreoElectronico" header="Correo Electronico"/>
                <Column field="Area.Nombre" header="Area"/>
            </DataTable>
        </div>
    )
}