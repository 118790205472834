import { HTTP } from '../Http/Http'

export const PlanPruebaRepository = {
    Listar: async () => {
        return await HTTP.POST('PlanPrueba/Listar','',{})
    },
    ListarPaginacion: async (Paginacion: any) => {
        return await HTTP.POST('PlanPrueba/ListarPaginado',Paginacion,{})
    },
    CrearActualizar: async (PlanPrueba: any) => {
        return await HTTP.POST('PlanPrueba/CrearActualizar',PlanPrueba,{})
    },
    Consultar: async (PlanPrueba: any) => {
        return await HTTP.POST('PlanPrueba/Consultar',PlanPrueba,{})
    }
}

