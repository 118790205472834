import React from 'react'
import { Route, Redirect} from "react-router-dom"

import { AuthHelper } from '../../Domain/Utils/AuthHelper'

import Inicio from '../../Infrastructure/Pages/Inicio'
import Asistencia from '../../Infrastructure/Pages/Asistencia'
import Proyecto from "../../Infrastructure/Pages/Proyecto"
import Requerimiento from '../../Infrastructure/Pages/Requerimiento'
import ItemRequerimiento from '../../Infrastructure/Pages/ItemRequerimiento'
import Tareo from "../../Desarrollo/Pages/Tareo"
import TareoImplantacion from "../../Desarrollo/Pages/TareoImplantacion"
import Ticket from "../../Infrastructure/Pages/Ticket"
import Solicitud from '../../Infrastructure/Pages/Solicitud'
import Producto from '../../Infrastructure/Pages/Producto'
import Categorizacion from '../../Infrastructure/Pages/Categorizacion'
import Prioridad from '../../Infrastructure/Pages/Prioridad'
import MotivoVersionamiento from '../../Infrastructure/Pages/MotivoVersionamiento'
import Documentacion from '../../Infrastructure/Pages/Documentacion'
import Calidad from '../../Infrastructure/Pages/Calidad'
import PlanDePrueba from '../../Infrastructure/Pages/PlanDePrueba'
import ItemPlanDePrueba from '../../Infrastructure/Pages/ItemPlanDePrueba'
import CasoPruebaVersion from '../../Desarrollo/Pages/CasoPruebaVersion'
import CasoPrueba from '../../Infrastructure/Pages/CasoPrueba'
import Incidente from '../../Infrastructure/Pages/Incidente'
import Usuarios from '../../Infrastructure/Pages/SeguridadUsuario'
import Area from '../Pages/SeguridadArea'
import Empleado from '../Pages/SeguridadEmpleado'
import BackLog from "../../Implementacion/Pages/BackLog"
import ItemBackLog from "../../Implementacion/Pages/ItemBackLog"

import NavigationBar from '../../Infrastructure/Components/NavigationBar'
import ReporteAsistencia from '../../Infrastructure/Pages/ReporteAsistencia'

interface IProps{
}

interface IState{
}

export default class Desarrollo extends React.Component<IProps, IState> {

    RouteProyecto = ({ match } : any) => (
        <>
            <Route path={`${match.path}/`} component={Proyecto} exact />
            <Route path={`${match.path}/ItemProyecto`} component={Requerimiento} exact/>
            <Route path={`${match.path}/ItemProyecto/ItemRequerimiento`} component={ItemRequerimiento} exact/>
        </>
    ) 

    RouteBackLog = ({ match } : any) => (
        <>
            <Route path={`${match.path}/`} component={BackLog} exact />
            <Route path={`${match.path}/ItemBackLog`} component={ItemBackLog} exact/>
        </>
    ) 

    RouteCalidad = ({ match } : any) => (
        <>
            <Route path={`${match.path}/`} component={Calidad}exact />
            <Route path={`${match.path}/PlanDePrueba`} component={PlanDePrueba} exact/>
            <Route path={`${match.path}/PlanDePrueba/ItemPlanDePrueba`} component={ItemPlanDePrueba} exact/>
            <Route path={`${match.path}/PlanDePrueba/ItemPlanDePrueba/CasoPruebaVersion`} component={CasoPruebaVersion}/>
        </>
    ) 

    RouteSeguridad = ({ match } : any) => (
        <>
            <Route path={`${match.path}/`} component={Usuarios} exact />
            <Route path={`${match.path}/Area`} component={Area} />
            <Route path={`${match.path}/Empleado`} component={Empleado} />
        </>
    )


    render(){
        return(
            <Route
                render={(props : any) => (AuthHelper.Get()  ? 
                    <>
                        <NavigationBar/>
                        <Route
                            path="/Desarrollo"    
                            render={({ match: { url } }) => (
                                <>
                                    <Route path={`${url}/`} component={Tareo} exact />
                                    {AuthHelper.GetDecoded().Area === 'Administracion'
                                        ? <Route path={`${url}/Reporte/Asistencia`} component={ReporteAsistencia}/>
                                        : <></>
                                    }

                                    <Route path={`${url}/Asistencia`} component={Asistencia}/>
                                    <Route path={`${url}/Inicio`} component={Inicio}/>
                                    <Route path={`${url}/Proyecto`} component={this.RouteProyecto}/>
                                    <Route path={`${url}/BackLog`} component={this.RouteBackLog}/>
                                    <Route path={`${url}/Ticket`} component={Ticket} />
    
                                    {AuthHelper.GetDecoded().Area === 'Desarrollo'
                                        ? <Route path={`${url}/Tareo`} component={Tareo} />
                                        : <Route path={`${url}/TareoImplantacion`} component={TareoImplantacion} />
                                    }
                                    <Route path={`${url}/VersionProducto`} component={Producto} />
                                    <Route path={`${url}/Categorizacion`} component={Categorizacion} />
                                    <Route path={`${url}/Prioridad`} component={Prioridad} />
                                    <Route path={`${url}/MotivoVersionamiento`} component={MotivoVersionamiento} />
                                    <Route path={`${url}/Solicitud`} component={Solicitud} />
                                    <Route path={`${url}/Documentacion`} component={Documentacion} />
                                    <Route path={`${url}/CasoPrueba`} component={CasoPrueba} />
                                    <Route path={`${url}/Incidente`} component={Incidente} />
                                    <Route path={`${url}/Calidad`} component={this.RouteCalidad} />
                                    {AuthHelper.GetDecoded().Administrador === 'True'? 
                                        <Route path={`${url}/Seguridad`} component={this.RouteSeguridad} />
                                        :
                                        <></>}
                                </>
                            )}
                        />
                    </>
                :
                <Redirect to={{ pathname: '/', state: { from: props.location } }} />)} 
            />
        )
    }
}