import { DocumentoRepository } from '../../Infrastructure/Repositories/DocumentoRepository'
import { IDocumento } from '../Models/IDocumento'

interface IErroresDocumento{
    Nombre?: string
    Link?: string
}

const ValidarSchema = (Documento: IDocumento) => {
    const Errores: IErroresDocumento = {}
    if(!Documento.Nombre) Errores.Nombre = 'Nombre es requerido'
    if(!Documento.Link) Errores.Link = 'Link es requerido'
    return Errores
}

export const DocumentacionService = {  
    ListarPaginacion: (Paginacion: any) => {
        return DocumentoRepository.ListarPaginacion(Paginacion)
    },
    ValidarSchema,
    CrearActualizar: (Documento: IDocumento) => {
        return DocumentoRepository.CrearActualizar(Documento)
    }
}