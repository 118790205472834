import React from 'react'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button';

import '../Assets/Css/Inicio.css'
import imgen from '../Assets/Img/Persona.png'

interface IProps{
    Modal: any
    ModalFoto: any
    FormValues: any
    convertbase64 : any
    base64Image: any
    Empleado: any
}


export default function InicioBody(Props: IProps){

    const imageSource = Props.Empleado.Foto
  ? `data:image/png;base64,${Props.Empleado.Foto}`
  : imgen;

    return(
        <div className="ContainerTrello">
            <div className="p-col-12 p-md-8 p-lg-6 p-xl-4">
                <div className="list-wrapper">
                    <div className="list" style={{height:'82vh', background:'white'}}>
                        <div className="TopBox">
                            <div className="Image" style={{ backgroundImage: `url(${imageSource})` }}></div>
                        </div>
                        <div className="BottonBox"> 
                            <div className="p-grid">

                                <div className="p-col">
                                    <div className="abrir">
                                        <Button 
                                            type="button"
                                            label="Cambio foto"
                                            onClick={Props.ModalFoto}
                                            style={{
                                                background:'#4D80E4',
                                                border: '1px solid #4D80E4'}}
                                            
                                        />
                                    </div>
                                </div>
                                <div className="p-col-12">
                                    <div className="p-grid p-fluid">
                                        <div className="p-col">
                                            <label 
                                                style={{
                                                    color: '#4D80E4',
                                                    fontFamily: 'Sarala',
                                                    fontSize: '19px'}}>
                                            Nombre</label>
                                            <InputText 
                                                name="Nombre"
                                                type="text" 
                                                value={Props.FormValues.Empleado.Nombre}
                                                disabled/>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-col-12">
                                    <div className="p-grid p-fluid">
                                        <div className="p-col">
                                            <label 
                                                style={{
                                                    color: '#4D80E4',
                                                    fontFamily: 'Sarala',
                                                    fontSize: '19px'}}>
                                            Area</label>
                                            <InputText 
                                                name="Area"
                                                type="text"
                                                value={Props.FormValues.Empleado.Area.Nombre}
                                                disabled/>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-col-12">
                                    <div className="p-grid p-fluid">
                                        <div className="p-col">
                                            <label 
                                                style={{
                                                    color: '#4D80E4',
                                                    fontFamily: 'Sarala',
                                                    fontSize: '19px'}}>
                                            Correo Electronico</label>
                                            <InputText 
                                                name="CorreoElectronico"
                                                type="text"
                                                value={Props.FormValues.Empleado.CorreoElectronico} 
                                                disabled/>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-col-12">
                                    <div className="p-grid p-fluid">
                                        <div className="p-col">
                                        <Button 
                                            type="button"
                                            label="Cambio contraseña"
                                            onClick={Props.Modal}
                                            style={{
                                                background:'#4D80E4',
                                                border: '1px solid #4D80E4'}}
                                            className="p-ml-auto"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}