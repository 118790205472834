import React,{ useRef,useEffect,useCallback,MutableRefObject } from 'react'
import useNearScreen from './useNearScreen'
import debounce from 'just-debounce-it'
import { Menu } from 'primereact/menu';
import ItemBackLog from '../../Implementacion/Pages/ItemBackLog';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';

interface IProps {
    Paginacion: any
    Pendiente: any
    EnCurso: any
    StandBy:any
    Pruebas: any
    Hecho: any
    OnResponse: any
    MoverEstado: any
    ActualizarFechaFin: any
}

export default function RequerimientoBody(Props : IProps){

    const RefPendiente = React.createRef<HTMLInputElement>()
    const RefEnCurso = React.createRef<HTMLInputElement>()
    const RefStandBy = React.createRef<HTMLInputElement>()
    const RefPruebas = React.createRef<HTMLInputElement>()
    const RefHecho = React.createRef<HTMLInputElement>()

    const NSPendiente = useNearScreen({
        externalRef :  !Props.Pendiente.Loading && RefPendiente,
        once: false
    }).isNearScreen

    const NSEnCurso = useNearScreen({
        externalRef : !Props.EnCurso.Loading && RefEnCurso,
        once: false
    }).isNearScreen

    const NSStandBy = useNearScreen({
        externalRef : !Props.StandBy.Loading && RefStandBy,
        once: false
    }).isNearScreen

    const NSPruebas = useNearScreen({
        externalRef : !Props.Pruebas.Loading && RefPruebas,
        once: false
    }).isNearScreen

    const NSHecho = useNearScreen({
        externalRef : !Props.Hecho.Loading && RefHecho,
        once: false
    }).isNearScreen

    const DebounceNextPage = useCallback((Estado : string) => {
        debounce(Props.Paginacion(Estado),5000)
    },[])

    useEffect(function (){
        if(NSPendiente) DebounceNextPage('Pendiente')
        if(NSEnCurso) DebounceNextPage('EnCurso')
        if(NSStandBy) DebounceNextPage('StandBy')
        if(NSPruebas) DebounceNextPage('Pruebas')
        if(NSHecho) DebounceNextPage('Hecho')
    })

    const DragStart = (Event : any) => {
        Event.dataTransfer.setData('EstadoPKID', Event.target.id)
    }

    const DragOver = (Event : React.SyntheticEvent) => {
        Event.preventDefault()
        Event.stopPropagation()
    }

    const DropPendiente = (Event : React.DragEvent) => {
        Event.preventDefault()
        Props.MoverEstado(Event.dataTransfer.getData('EstadoPKID').split('-')[0],'Pendiente', Event.dataTransfer.getData('EstadoPKID').split('-')[1])
    }

    const DropEnCurso = (Event : React.DragEvent) => {
        Event.preventDefault()
        Props.MoverEstado(Event.dataTransfer.getData('EstadoPKID').split('-')[0],'EnCurso', Event.dataTransfer.getData('EstadoPKID').split('-')[1])
    }

    const DropStandBy = (Event : React.DragEvent) => {
        Event.preventDefault()
        Props.MoverEstado(Event.dataTransfer.getData('EstadoPKID').split('-')[0],'StandBy', Event.dataTransfer.getData('EstadoPKID').split('-')[1])
    }

    const DropPruebas = (Event : React.DragEvent) => {
        Event.preventDefault()
        Props.MoverEstado(Event.dataTransfer.getData('EstadoPKID').split('-')[0],'Pruebas', Event.dataTransfer.getData('EstadoPKID').split('-')[1])
    }

    const DropHecho = (Event : React.DragEvent) => {
        Event.preventDefault()
        Props.MoverEstado(Event.dataTransfer.getData('EstadoPKID').split('-')[0],'Hecho', Event.dataTransfer.getData('EstadoPKID').split('-')[1])
    }

    const FormatearFecha = (Fecha : Date) => {
        const date = new Date(Fecha)
        return date.toLocaleDateString()
    }
    const FormatearFecha2 = (Fecha : Date) => {
        const date = new Date(Fecha);
    
        // Obtenemos los componentes de la fecha
        const dia = String(date.getDate()).padStart(2, '0'); // Agregamos cero al principio si es necesario
        const mes = String(date.getMonth() + 1).padStart(2, '0'); // Agregamos cero al principio si es necesario
        const año = date.getFullYear();
    
        // Formateamos la fecha en el formato deseado
        return `${dia}/${mes}/${año}`;
    }
    
    const actions = [
        { icon: <FileCopyIcon />, name: 'Actualizar Fecha Fin' }        
      ];

    const RenderListado = ( (Requerimiento : any, Estado : string) => {
        let Lista = Requerimiento.map((Entidad: any) => {
            return(
                <li  
                    id = {Estado + '-' + Entidad.PKID}
                    draggable
                    onDragStart = {DragStart}
                    onDragOver = {DragOver}
                    key = {Estado + '-' + Entidad.PKID}
                    //onClick={() => Props.OnResponse(Entidad)}
                    >
                    <div className="comment-main-level">                    
                        <div className="comment-box" onClick={() => Props.OnResponse(Entidad)}>
                            <div className="comment-head" >
                                <h6 className="comment-name by-author"  >
                                    {Entidad.Requisito}
                                </h6>
                                


      <SpeedDial                             
                            direction='right'                                
        ariaLabel="SpeedDial basic example"
        sx={{ position: 'absolute', bottom: 0, left: 0 }}        
        icon={<SpeedDialIcon />}  
        FabProps={{     
            size:'small',                       
            sx: {              
                backgroundColor:'#fff',
                color:'black',                  
                boxShadow:'0',                
                '&:hover': {
                    bgcolor: '#fff',
                  }                                    
            }            
          }}      
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}             
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={e => {
                e.stopPropagation();                
                Props.ActualizarFechaFin(Entidad.PKID);
              }}
              
          />
        ))}
      </SpeedDial>
      <br />
                            </div>
                            <div className="comment-content">
                                <table className="table table-bordered table-sm">
                                    <thead>
                                        <tr>
                                            <th scope="col">Horas Planificadas</th>
                                            <th scope="col">Horas Ejecutadas</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{(Math.round(Entidad.EstimacionAjustada * 100) / 100).toFixed(2)}</td>
                                            <td>{(Math.round(Entidad.HorasEjecutadas * 100) / 100).toFixed(2)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className="table table-bordered table-sm">
                                    <thead>
                                        <tr>
                                            <th scope="col">Fecha Inicio</th>
                                            <th scope="col">Fecha Fin</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{FormatearFecha2(Entidad.FechaInicio)}</td>
                                            <td>{FormatearFecha2(Entidad.FechaFin)}</td>                                           
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                           

                        </div>                             

                                <div>
                              
                                </div>                                

                    </div>
                </li>
            )
        })
        return Lista;
    })

    return(
        <div className="ContainerTrello">
            <div className="p-grid" style={{margin: '0px'}}>
                <div style={{width:'20%'}} className="p-col-12 p-md-6 p-lg-3">
                    <div className="list-wrapper">
                        <div className="list" style={{height:'82vh'}}>
                            <div className="list-header is-menu-shown">
                                <h2 className="list-header-name-assist">Pendiente</h2>
                                <div 
                                    className="open-card-composer" 
                                    onClick={() => Props.OnResponse(null)}>
                                    <i className="pi pi-plus icon-sm"></i>
                                    <span>Nuevo</span>
                                </div>
                            </div>
                            <div 
                                className="list-cards"
                                onDrop={DropPendiente}
                                onDragOver={DragOver}>
                                <ul id="lPendiente" className="comment-list">
                                    {RenderListado(Props.Pendiente.Lista,'Pendiente')}
                                </ul>
                                <div ref={RefPendiente}>
                                {((Props.Pendiente.Paginacion.NumeroPagina < Props.Pendiente.Paginacion.NumeroPaginas) || Props.Pendiente.Loading)? 
                                    <div className="circleLoad"></div> 
                                    : 
                                    <></>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{width:'20%'}} className="p-col-12 p-md-6 p-lg-3">
                    <div className="list-wrapper">
                        <div className="list" style={{height:'82vh'}}>
                            <div className="list-header is-menu-shown">
                                <h2 className="list-header-name-assist">En Curso</h2>
                            </div>
                            <div 
                                className="list-cards"
                                onDrop={DropEnCurso}
                                onDragOver={DragOver}>
                                <ul id="lEnCurso" className="comment-list">
                                    {RenderListado(Props.EnCurso.Lista,'EnCurso')}
                                </ul>
                                <div ref={RefEnCurso}>
                                {((Props.EnCurso.Paginacion.NumeroPagina < Props.EnCurso.Paginacion.NumeroPaginas) || Props.EnCurso.Loading)? 
                                <div className="circleLoad"></div> 
                                    : 
                                    <></>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{width:'20%'}} className="p-col-12 p-md-6 p-lg-3">
                    <div className="list-wrapper">
                        <div className="list" style={{height:'82vh'}}>
                            <div className="list-header is-menu-shown">
                                <h2 className="list-header-name-assist">StandBy</h2>
                            </div>
                            <div 
                                className="list-cards"
                                onDrop={DropStandBy}
                                onDragOver={DragOver}>
                                <ul id="lStandBy" className="comment-list">
                                    {RenderListado(Props.StandBy.Lista,'StandBy')}
                                </ul>
                                <div ref={RefStandBy}>
                                {((Props.StandBy.Paginacion.NumeroPagina < Props.StandBy.Paginacion.NumeroPaginas) || Props.StandBy.Loading)? 
                                <div className="circleLoad"></div> 
                                    : 
                                    <></>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div  style={{width:'20%'}} className="p-col-12 p-md-6 p-lg-3">
                    <div className="list-wrapper">
                        <div className="list" style={{height:'82vh'}}>
                            <div className="list-header is-menu-shown">
                                <h2 className="list-header-name-assist">Pruebas</h2>
                            </div>
                            <div 
                                className="list-cards"
                                onDrop={DropPruebas}
                                onDragOver={DragOver}>
                                <ul id="lEnPruebas" className="comment-list">
                                    {RenderListado(Props.Pruebas.Lista,'Pruebas')}
                                </ul>
                                <div ref={RefPruebas}>
                                {((Props.Pruebas.Paginacion.NumeroPagina < Props.Pruebas.Paginacion.NumeroPaginas) || Props.Pruebas.Loading)? 
                                    <div className="circleLoad"></div> 
                                    : 
                                    <></>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{width:'20%'}} className="p-col-12 p-md-6 p-lg-3">
                    <div className="list-wrapper">
                        <div className="list" style={{height:'82vh'}}>
                            <div className="list-header is-menu-shown">
                                <h2 className="list-header-name-assist">Hecho</h2>
                            </div>
                            <div 
                                className="list-cards"
                                onDrop={DropHecho}
                                onDragOver={DragOver}>
                                <ul id="lHecho" className="comment-list">
                                    {RenderListado(Props.Hecho.Lista,'Hecho')}
                                </ul>
                                <div ref={RefHecho}>
                                {((Props.Hecho.Paginacion.NumeroPagina < Props.Hecho.Paginacion.NumeroPaginas) || Props.Hecho.Loading)? 
                                    <div className="circleLoad"></div> 
                                    : 
                                    <></>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}