import { HTTP } from "../Http/Http"

export const PrioridadRepository = {
    Listar: async() =>{
        return await HTTP.POST('Prioridad/Listar','',{})
    },
    ListarPaginacion: async(Paginacion: any) =>{
        return await HTTP.POST('Prioridad/ListarPaginacion',Paginacion,{})
    },    
    CrearActualizar: async (Prioridad: any) => {
        return await HTTP.POST('Prioridad/CrearActualizar', Prioridad, {})
    }
}