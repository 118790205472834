import React from 'react'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { InputTextarea } from 'primereact/inputtextarea'
import { Calendar } from 'primereact/calendar'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { FileUpload } from 'primereact/fileupload';
import { Dropdown } from 'primereact/dropdown'
import { Checkbox } from 'primereact/checkbox';

interface IProps {
    ModalIncidente: boolean
    HideModal: any
    FormValues: any
    HandleChange: any
    ActualizarPaso: any
    EliminarPaso: any
    SubirArchivo: any
    BorrarArchivo: any
    DescargarArchivo: any
    CrearNuevoPaso: any
    ListaProducto: Array<object>
    ListaCategorizacion: Array<object>
    ListaEmpleado: Array<object>
    ListaTipoError: Array<object>
    ListaSeveridad: Array<object>
    OnRowReorder: any
    Errores: any
    Request: boolean
    InputHabilitado: boolean
}

export default function IncidenteModal(Props: IProps) {

    const File = React.createRef<FileUpload>()

    const BodyTemplate1 = (RowData: any) => {
        return (
            <React.Fragment>
                <div className="p-grid p-fluid ">
                    <div className="p-col-6">
                        {RowData.Correcto === 1 || RowData.Correcto === 3 ?
                            <Button icon="pi pi-check" className="p-button-rounded p-button-outlined" type="text" />
                            : <Button icon="pi pi-check" className="p-button-rounded p-button-success" type="text" />}
                    </div>
                    <div className="p-col-6">
                        {RowData.Correcto === 3 ?
                            <Button icon="pi pi-times" className="p-button-rounded p-button-danger" type="text" />
                            : <Button icon="pi pi-times" className="p-button-rounded p-button-outlined" type="text" />}
                    </div>
                </div>
            </React.Fragment>
        );
    }

    const BodyTemplate2 = (RowData: any) => {
        return (
            <React.Fragment>
                <div className="p-grid p-fluid ">
                    <div className="p-col-6">
                        <Button icon="pi pi-pencil" className="p-button-rounded p-button-outlined" type="text" onClick={() => { Props.ActualizarPaso(RowData) }} />
                    </div>
                    <div className="p-col-6">
                        <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-outlined" type="text" onClick={() => { Props.EliminarPaso(RowData) }} />
                    </div>
                </div>
            </React.Fragment>
        );
    }

    const ImageArchivo = (RowData: any) => {
        var extension = RowData.Extension
        let icono = ''
        let fondo = ''

        if (extension === 'txt') {
            icono = 'pi pi-file'
            fondo = '#000000'
        }
        else if (extension === 'pdf') {
            icono = 'pi pi-file-pdf'
            fondo = '#ff471a'
        }
        else if (extension === 'docx' || extension === 'docm' || extension === 'dotx' || extension === 'dotm' || extension === 'doc' || extension === 'dot' || extension === 'rtf') {
            icono = 'far fa-file-word'
            fondo = '#80e5ff'
        }
        else if (extension === 'xls' || extension === 'xlsx' || extension === 'xlsm' || extension === 'xltx' || extension === 'xltm') {
            icono = 'far fa-file-excel'
            fondo = '#00802b'
        }
        else if (extension === 'pptx' || extension === 'pptm' || extension === 'ppt' || extension === 'potx') {
            icono = 'far fa-file-powerpoint'
            fondo = '#ffa64d'
        }
        else if (extension === 'sql' || extension === 'js' || extension === 'vb' || extension === 'cs') {
            icono = 'fas fa-scroll'
            fondo = '#66ff66'
        }
        else if (extension === 'zip' || extension === '7z' || extension === 'rar') {
            icono = 'far fa-file-archive'
            fondo = '#3385ff'
        }
        else {
            icono = 'pi pi-question'
            fondo = '#000000'
        }

        return (
            <React.Fragment>
                <div className="p-grid p-fluid ">
                    <div className="p-col-12">
                        <i className={icono} style={{ 'fontSize': '2em', color: `${fondo}` }}></i>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    const FechaCreacion = (RowData: any) => {
        let newDate
        RowData.FechaCreacion !== '' ? newDate = new Date(RowData.FechaCreacion) : newDate = new Date()
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        return `${date}${'/'}${month < 10 ? `0${month}` : `${month}`}${'/'}${year}`
    }

    const TamañoArchivo = (RowData: any) => {
        return (<p>{(RowData.Tamagno / Math.pow(1024, 2)).toFixed(2) + 'MB'}</p>)
    }

    const Header = (
        <div className="p-d-flex p-jc-between">
            <FileUpload
                ref={File}
                mode="basic"
                name="file"
                auto
                uploadHandler={(Event) => Props.SubirArchivo(Event, File)}
                customUpload
                className="p-ml-auto"
                chooseLabel="Buscar archivo" />
        </div>
    )

    const OperacionArchivo = (Event: any) => {
        return (
            <React.Fragment>
                <div className="p-grid p-fluid ">
                    <div className="p-col-6">
                        <Button
                            type="button"
                            icon="pi pi-times"
                            style={{ width: '30px', height: '30px' }}
                            className="p-button-rounded p-button-outlined"
                            onClick={() => {
                                Props.BorrarArchivo(Event)
                            }} />
                    </div>
                    <div className="p-col-6">
                        <Button
                            type="button"
                            icon="pi pi-download"
                            style={{ width: '30px', height: '30px' }}
                            className="p-button-rounded p-button-outlined"
                            onClick={() => {
                                Props.DescargarArchivo(Event)
                            }} />
                    </div>
                </div>
            </React.Fragment>
        )
    }

    const RenderFooter = () => {
        return (
            <div className="p-d-flex footer p-ml-auto">
                <Checkbox
                    inputId="binary"
                    checked={Props.FormValues.CheckCorreo}
                    onChange={Props.HandleChange}
                    name="CheckCorreo" />
                <label style={{ color: '#4D80E4' }}>Enviar Correo Electrónico a {Props.FormValues.Empleado.Nombre}</label>
                <Button
                    type="submit"
                    label={Props.Request ? "Cargando..." : "Aceptar"}
                    style={{
                        background: '#4D80E4',
                        border: '1px solid #4D80E4'
                    }}
                    icon={Props.Request ? "" : "pi pi-check"}
                    className="p-ml-auto"
                    disabled={Props.Request}
                    autoFocus />
            </div>
        )
    }

    const abrirURL = () => {
        const { LinkOCS } = Props.FormValues;
        if (LinkOCS) {
          window.open(LinkOCS, '_blank');
        }
      };

    return (
        <Dialog
            header='Incidente'
            visible={Props.ModalIncidente}
            style={{ width: '140vh' }}
            footer={RenderFooter()}
            onHide={() => Props.HideModal(null, false)}>
            <div className="modal-body p-mt-3">
                <div className="p-grid nested-grid">
                    <div className="p-col-12 p-lg-6">
                        <div className="p-col">
                            <div className="p-grid p-fluid p-mr-1 p-ml-1">
                                <div className="p-col-12">
                                    <label className="p-d-block"
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'
                                        }}>Nombre</label>
                                    <InputText
                                        name="Incidencia"
                                        onChange={Props.HandleChange}
                                        value={Props.FormValues.Incidencia}
                                        type="text" />
                                    {Props.Errores.Incidencia && <small className="p-invalid p-d-block">{Props.Errores.Incidencia}</small>}
                                </div>
                            </div>
                        </div>
                        <div className="p-col">
                            <div className="p-grid p-fluid p-mr-1 p-ml-1">
                                <div className="p-col-12">
                                    <label className="p-d-block"
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'
                                        }}>Detalle</label>
                                    <InputTextarea
                                        name="Detalle"
                                        value={Props.FormValues.Detalle}
                                        onChange={Props.HandleChange}
                                        type="text"
                                        rows={5} />
                                    {Props.Errores.Detalle && <small className="p-invalid p-d-block">{Props.Errores.Detalle}</small>}
                                </div>
                                <div className="p-col-12">
                                    <label className="p-d-block"
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'
                                        }}>Producto</label>
                                    <Dropdown
                                        value={{
                                            code: Props.FormValues.Producto.PKID,
                                            name: Props.FormValues.Producto.Nombre,
                                            VersionProducto: Props.FormValues.Producto.VersionProducto
                                        }}
                                        options={Props.ListaProducto}
                                        onChange={Props.HandleChange}
                                        name="Producto"
                                        optionLabel="name"
                                        placeholder="Seleccione Producto" />
                                    {Props.Errores.Producto && <small className="p-invalid p-d-block">{Props.Errores.Producto}</small>}
                                </div>



                                <div className="p-col-12">
                                    <label className="p-d-block"
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'
                                        }}>Versión</label>
                                    <Dropdown
                                        value={{
                                            code: Props.FormValues.VersionProducto.PKID,
                                            name: Props.FormValues.VersionProducto.Version
                                        }}
                                        options={Props.FormValues.Producto.VersionProducto}
                                        onChange={Props.HandleChange}
                                        optionLabel="name"
                                        name="VersionProducto"
                                        placeholder="Seleccione Producto" />
                                    {Props.Errores.VersionProducto && <small className="p-invalid p-d-block">{Props.Errores.VersionProducto}</small>}
                                </div>

                                <div className="p-col">
                                    <div className="p-grid p-fluid ">
                                        <div className="p-col-12">
                                            <label
                                                className="p-d-block"
                                                style={{
                                                    color: '#4D80E4',
                                                    fontFamily: 'Sarala',
                                                    fontSize: '20px'
                                                }}
                                            >Categorización</label>
                                            <Dropdown
                                                value={{
                                                    code: Props.FormValues.Categorizacion ? Props.FormValues.Categorizacion.PKID : 0,
                                                    name: Props.FormValues.Categorizacion ? Props.FormValues.Categorizacion.Descripcion : ''
                                                }}
                                                options={Props.ListaCategorizacion}
                                                name="Categorizacion"
                                                onChange={Props.HandleChange}
                                                optionLabel="name"
                                                placeholder="Seleccione Categorización" />
                                            {Props.Errores.Categorizacion && <small className="p-invalid p-d-block">{Props.Errores.Categorizacion}</small>}
                                        </div>
                                    </div>
                                </div>


                                <div className="p-col-12">
                                    <label
                                        className="p-d-block"
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'
                                        }}
                                    >Evidencia</label>
                                    <DataTable
                                        value={Props.FormValues.Evidencias}
                                        paginator
                                        rows={5}
                                        header={Header}
                                        className="p-datatable-sm">
                                        <Column body={ImageArchivo} header="T. de archivo"></Column>
                                        <Column field="Nombre" header="Nombre"></Column>
                                        <Column body={FechaCreacion} header="Fecha creación" />
                                        <Column body={TamañoArchivo} header="Tamaño"></Column>
                                        <Column body={OperacionArchivo} header="Operación"></Column>
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-col-12 p-lg-6">
                        <div className="p-col">
                            <div className="p-grid p-fluid p-mr-1 p-ml-1">
                                <div className="p-col-12">
                                    <label className="p-d-block"
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'
                                        }}>Programador</label>
                                    <Dropdown
                                        value={{
                                            code: Props.FormValues.Empleado.PKID,
                                            name: Props.FormValues.Empleado.Nombre
                                        }}
                                        options={Props.ListaEmpleado}
                                        onChange={Props.HandleChange}
                                        name="Empleado"
                                        optionLabel="name"
                                        placeholder="Seleccione el Responsable" />
                                    {Props.Errores.Empleado && <small className="p-invalid p-d-block">{Props.Errores.Empleado}</small>}
                                </div>
                                <div className="p-col-12">
                                    <label className="p-d-block"
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'
                                        }}>
                                        Fecha Creación</label>
                                    <Calendar
                                        value={Props.FormValues.FechaCreacion ? new Date(Props.FormValues.FechaCreacion) : new Date()}
                                        name="FechaCreacion"
                                        disabled
                                        dateFormat="dd/mm/yy" />
                                    <small className="p-d-block" style={{ color: '#fff' }}>.</small>
                                </div>
                                <div className="p-col-12">
                                    <label className="p-d-block"
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'
                                        }}>Tipo de Error</label>
                                    <Dropdown
                                        value={{
                                            code: Props.FormValues.TipoError.PKID,
                                            name: Props.FormValues.TipoError.Nombre
                                        }}
                                        options={Props.ListaTipoError}
                                        onChange={Props.HandleChange}
                                        name="TipoError"
                                        optionLabel="name"
                                        placeholder="Seleccione el Tipo de Error" />
                                    {Props.Errores.TipoError && <small className="p-invalid p-d-block">{Props.Errores.TipoError}</small>}
                                </div>
                                <div className="p-col-12">
                                    <label className="p-d-block"
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'
                                        }}>
                                        Prioridad</label>
                                    <InputText
                                        name="Prioridad"
                                        onChange={Props.HandleChange}
                                        value={Props.FormValues.Prioridad}
                                        type="number" />
                                    {Props.Errores.Prioridad && <small className="p-invalid p-d-block">{Props.Errores.Prioridad}</small>}
                                </div>
                                <div className="p-col-12">
                                    <label className="p-d-block"
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'
                                        }}>Severidad</label>
                                    <Dropdown
                                        value={{
                                            code: Props.FormValues.Severidad.PKID,
                                            name: Props.FormValues.Severidad.Nombre
                                        }}
                                        options={Props.ListaSeveridad}
                                        onChange={Props.HandleChange}
                                        name="Severidad"
                                        optionLabel="name"
                                        placeholder="Seleccione la Severidad" />
                                    {Props.Errores.Severidad && <small className="p-invalid p-d-block">{Props.Errores.Severidad}</small>}
                                </div>
                                <div className="p-col-12">
                                    <div className="p-grid">
                                        <div className="p-col-12 p-lg-12">
                                            <div className="p-col">
                                                <div className="p-grid p-fluid ">
                                                    <div className="p-col-12">
                                                        <label
                                                            style={{
                                                                color: '#4D80E4',
                                                                fontFamily: 'Sarala',
                                                                fontSize: '20px'
                                                            }}
                                                        >Link OCS</label>
                                                    </div>
                                                    <div className="p-col-11">
                                                        <InputText
                                                            value={Props.FormValues.LinkOCS ? Props.FormValues.LinkOCS : ''}
                                                            onChange={Props.HandleChange}
                                                            type="text"
                                                            name="LinkOCS"
                                                            placeholder="https://"
                                                            disabled={!Props.InputHabilitado}
                                                        />
                                                    </div>
                                                    <div className="p-col-1">
                                                        <Button
                                                            type="button"
                                                            icon="pi pi-arrow-circle-right"
                                                            className="p-button-rounded p-button-outlined"
                                                        onClick={abrirURL}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-col-12">
                                    <Checkbox
                                        inputId="binary"
                                        checked={Props.FormValues.TieneOCS}
                                        onChange={Props.HandleChange}
                                        name="TieneOCS" />
                                    <label
                                        className=""
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'
                                        }}
                                    >      Tiene OCS</label>
                                    {Props.Errores.TieneOCS && <small className="p-invalid p-d-block">{Props.Errores.TieneOCS}</small>}
                                </div>
                                <div className="p-col-12">
                                    <div className="open-card-composer" onClick={(event) => Props.CrearNuevoPaso(event)}>
                                        <i className="pi pi-plus icon-sm"></i>
                                        <span>Nuevo Paso</span>
                                    </div>
                                    <label
                                        className="p-d-block"
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'
                                        }}
                                    >Pasos</label>
                                    {(Props.FormValues.IDCasoPruebaVersion > 0) ?
                                        <DataTable
                                            value={Props.FormValues.Pasos}
                                            paginator
                                            rows={5}
                                            className="p-datatable-sm">
                                            <Column field="NumeroPaso" header="Paso" style={{ width: '10%' }}></Column>
                                            <Column field="Accion" header="Acción" style={{ width: '30%' }}></Column>
                                            <Column field="ResultadoEsperado" header="Resultado Esperado" style={{ width: '40%' }}></Column>
                                            <Column body={BodyTemplate1} header="Estado" style={{ width: '20%' }}></Column>
                                        </DataTable>
                                        :
                                        <DataTable
                                            value={Props.FormValues.Pasos}
                                            paginator
                                            className="p-datatable-sm"
                                            columnResizeMode="fit"
                                            reorderableColumns
                                            onRowReorder={Props.OnRowReorder}
                                            rowsPerPageOptions={[5, 8, 10]}
                                            scrollable
                                            dataKey="id" rows={5}>
                                            <Column rowReorder style={{ width: '20px' }}></Column>
                                            <Column field="NumeroPaso" header="Paso" style={{ width: '40px' }}></Column>
                                            <Column field="Accion" header="Acción" style={{ width: '160px' }}></Column>
                                            <Column field="ResultadoEsperado" header="Resultado Esperado" style={{ width: '200px' }}></Column>
                                            <Column body={BodyTemplate2} header="Acción" style={{ width: '80px' }}></Column>
                                        </DataTable>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}
