import React, { Component } from 'react'
import { Modal } from 'react-responsive-modal'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { Calendar } from 'primereact/calendar'
// import "react-responsive-modal/styles.css";
import "../Assets/css/CustonModal.css";


export default class ModalTareo extends Component {

    recortadorTexto(texto, limite) {
        let puntosSuspensivos = "...";
        let texto2 = "" + texto;
        if (texto2.length > limite) {
            texto2 = texto2.substring(0, limite) + puntosSuspensivos;
        }
        return texto2;
    }

    calcularHorasAMostrar(segundosejecutdos) {
        let segundos = segundosejecutdos
        return Math.floor(segundos / 3600)
    }

    calcularMinutosAMostrar = (segundosejecutdos) => {
        let segundos = segundosejecutdos
        return Math.floor((segundos % 3600) / 60)
    }

    calcularSegundosAMostrar = (segundosejecutdos) => {
        let segundos = segundosejecutdos
        return Math.floor(((segundos % 3600) % 60))
    }

    templateDDL = (option, props) => {
        if (option) {
            return (
                <div className="country-item country-item-value">
                    <div>{option.name}</div>
                </div>
            )
        }
        return (
            <span className="country-item country-item-value">
                {props.placeholder}
            </span>
        )
    }

    templateItemDDL = option => {
        return (
            <div className="country-item">
                <div>{this.recortadorTexto(option.name, 45)}</div>
            </div>
        )
    }

    render() {
        let horaAMostrar = this.calcularHorasAMostrar(this.props.Tarea.HorasEjecutadas) + ":" + this.calcularMinutosAMostrar(this.props.Tarea.HorasEjecutadas) + ":" + this.calcularSegundosAMostrar(this.props.Tarea.HorasEjecutadas)
        return (
            <Modal
                open={this.props.mostrarModal}

                onClose={this.props.onClick}
                center
                classNames={{
                    overlay: 'customOverlay',
                    modal: 'customModal',
                }}
            >
                <form autoComplete="off" action="submit" onSubmit={this.props.onSubmit}>
                    <div className="headerModal">
                        <h2 style={{
                            color: '#4D80E4',
                            fontFamily: 'Sarala',
                            fontSize: '22px'
                        }}>
                            Tarea</h2>
                    </div>
                    <div className="modal-body p-mt-3" >
                        <div className="p-grid nested-grid">
                            <div className="p-col-12 p-lg-6">
                                <div className="p-col">
                                    <div className="p-grid p-fluid p-mr-1 p-ml-1">
                                        <div className="p-col-12">
                                            <label className="p-d-block"
                                                style={{
                                                    color: '#4D80E4',
                                                    fontFamily: 'Sarala',
                                                    fontSize: '20px'
                                                }}>Nombre</label>
                                            <InputText
                                                name="Nombre"
                                                onChange={this.props.onChange}
                                                value={this.props.Tarea.Nombre}
                                                type="text" />
                                            {this.props.Errores.Nombre && <small className="p-invalid p-d-block">{this.props.Errores.Nombre}</small>}
                                        </div>
                                    </div>
                                    <div className="p-grid p-fluid p-mr-1 p-ml-1">
                                        <div className="p-col-12">
                                            <label htmlFor="username" style={{
                                                color: '#4D80E4',
                                                fontFamily: 'Sarala',
                                                fontSize: '20px'
                                            }}>Descripción</label>
                                            <InputTextarea
                                                name="Descripcion"
                                                onChange={this.props.onChange}
                                                value={this.props.Tarea.Descripcion}
                                                type="text"
                                                rows={4} />
                                            {this.props.Errores.Descripcion && <small className="p-invalid p-d-block">{this.props.Errores.Descripcion}</small>}
                                        </div>
                                    </div>
                                    <div className="p-grid p-fluid p-mr-1 p-ml-1">
                                        <div className="p-col">
                                            <div className="p-grid p-fluid">
                                                <div className="p-col-12">
                                                    <label
                                                        className="p-d-block"
                                                        style={{
                                                            color: '#4D80E4',
                                                            fontFamily: 'Sarala',
                                                            fontSize: '20px'
                                                        }}
                                                    >Estado</label>
                                                    <Dropdown
                                                        value={{
                                                            code: this.props.Tarea.Estado.PKID,
                                                            name: this.props.Tarea.Estado.Descripcion
                                                        }}
                                                        options={this.props.ListaEstadoDDL}
                                                        onChange={this.props.handleChangeEstado}
                                                        optionLabel="name"
                                                        placeholder="Seleccione el Estado"
                                                        disabled={!this.props.esActualizacion}
                                                        valueTemplate={this.templateDDL}
                                                        itemTemplate={this.templateItemDDL}
                                                    />
                                                    <small className="p-d-block" style={{ color: '#fff' }}>.</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="p-col">
                                            <div className="p-grid p-fluid">
                                                <div className="p-col-12">
                                                    <label style={{
                                                        color: '#4D80E4',
                                                        fontFamily: 'Sarala',
                                                        fontSize: '20px'
                                                    }}>Horas Planificadas</label>
                                                    <InputText
                                                        name="HorasPlanificadas"
                                                        onChange={this.props.onChange}
                                                        value={this.props.Tarea.HorasPlanificadas}
                                                        type="number" />
                                                    <small className="p-d-block" style={{ color: '#fff' }}>.</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {(this.props.Tarea.Desarrollo || ((this.props.Tarea.Incidencia ? this.props.Tarea.Incidente.PKID > 0 : 0) || this.props.Tarea.EsIncidente)) ? '' :
                                        <div className="p-grid p-fluid p-mr-1 p-ml-1">
                                            <div className="p-col-12 p-md-6">
                                                <label htmlFor="username"
                                                    style={{
                                                        color: '#4D80E4',
                                                        fontFamily: 'Sarala',
                                                        fontSize: '20px'
                                                    }}
                                                >Fecha Creación</label>
                                                <Calendar
                                                    value={this.props.Tarea.FechaCreacion ? new Date(this.props.Tarea.FechaCreacion) : new Date()}
                                                    disabled
                                                    dateFormat="dd/mm/yy" />
                                            </div>
                                            <div className="p-col-12 p-md-6">
                                                <label htmlFor="username1"
                                                    className="p-d-block"
                                                    style={{
                                                        color: '#4D80E4',
                                                        fontFamily: 'Sarala',
                                                        fontSize: '20px'
                                                    }}
                                                >Responsable</label>
                                                <InputText
                                                    value={this.props.Tarea.Responsable.Empleado.Nombre}
                                                    onChange={this.props.handleChange}
                                                    type="text"
                                                    disabled />
                                            </div>
                                        </div>}
                                    {(this.props.Tarea.Desarrollo || ((this.props.Tarea.Incidencia ? this.props.Tarea.Incidente.PKID > 0 : 0) || this.props.Tarea.EsIncidente)) ?
                                        <div className="p-grid p-fluid p-mr-1 p-ml-1">
                                            <div className="p-col-12 p-md-6">
                                                <label htmlFor="username"
                                                    style={{
                                                        color: '#4D80E4',
                                                        fontFamily: 'Sarala',
                                                        fontSize: '20px'
                                                    }}
                                                >Fecha Creación</label>
                                                <Calendar
                                                    value={this.props.Tarea.FechaCreacion ? new Date(this.props.Tarea.FechaCreacion) : new Date()}
                                                    disabled
                                                    dateFormat="dd/mm/yy" />
                                            </div>
                                            <div className="p-col-12 p-md-6">
                                                <label htmlFor="username1"
                                                    className="p-d-block"
                                                    style={{
                                                        color: '#4D80E4',
                                                        fontFamily: 'Sarala',
                                                        fontSize: '20px'
                                                    }}
                                                >Responsable</label>
                                                <InputText
                                                    value={this.props.Tarea.Responsable.Empleado.Nombre}
                                                    onChange={this.props.handleChange}
                                                    type="text"
                                                    disabled />
                                            </div>
                                        </div> : null}
                                </div>
                            </div>

                            <div className="p-col-12 p-lg-6">
                                <div className="p-col">
                                    {this.props.Tarea.Desarrollo ?
                                        <div className="p-grid p-fluid p-mr-1 p-ml-1">
                                            <div className="p-col-12">
                                                <label className="p-d-block" style={{
                                                    color: '#4D80E4',
                                                    fontFamily: 'Sarala',
                                                    fontSize: '20px'
                                                }}>Proyecto</label>
                                                <Dropdown
                                                    value={{
                                                        code: this.props.Tarea.ProyectoVersion.PKID,
                                                        name: this.props.Tarea.ProyectoVersion.Nombre
                                                    }}
                                                    options={this.props.ListaProyectoDDL}
                                                    onChange={this.props.onChangeProyecto}
                                                    optionLabel="name"
                                                    filter filterBy="name"
                                                    placeholder="Seleccione Proyecto"
                                                    valueTemplate={this.templateDDL}
                                                    itemTemplate={this.templateItemDDL} />
                                                {this.props.Errores.Proyecto && <small className="p-invalid p-d-block">{this.props.Errores.Proyecto}</small>}
                                            </div>
                                        </div> : null}
                                    {this.props.Tarea.Desarrollo ?
                                        <div className="p-grid p-fluid p-mr-1 p-ml-1">
                                            <div className="p-col-12">
                                                <label className="p-d-block" style={{
                                                    color: '#4D80E4',
                                                    fontFamily: 'Sarala',
                                                    fontSize: '20px'
                                                }}>Requerimiento</label>
                                                <Dropdown
                                                    value={{
                                                        code: this.props.Tarea.RequerimientoVersion.PKID,
                                                        name: this.props.Tarea.RequerimientoVersion.Requisito
                                                    }}
                                                    options={this.props.ListaRequerimientoDDL}
                                                    onChange={this.props.onChangeRequerimiento}
                                                    optionLabel="name"
                                                    placeholder="Seleccione Requerimiento"
                                                    valueTemplate={this.templateDDL}
                                                    itemTemplate={this.templateItemDDL}
                                                />
                                                {this.props.Errores.Requerimiento && <small className="p-invalid p-d-block">{this.props.Errores.Requerimiento}</small>}
                                            </div>
                                        </div>
                                        : null}
                                    {this.props.Tarea.Desarrollo ?
                                        <div className="p-grid p-fluid p-mr-1 p-ml-1">
                                            <div className="p-col-12">
                                                <label className="p-d-block" style={{
                                                    color: '#4D80E4',
                                                    fontFamily: 'Sarala',
                                                    fontSize: '20px'
                                                }}>Item Requerimiento</label>
                                                <Dropdown
                                                    value={{
                                                        code: this.props.Tarea.ItemRequerimientoVersion.PKID,
                                                        name: this.props.Tarea.ItemRequerimientoVersion.Requisito
                                                    }}
                                                    options={this.props.ListaItemRequerimientoDDL}
                                                    onChange={this.props.onChangeItemRequerimiento}
                                                    optionLabel="name"
                                                    placeholder="Seleccione Item Requerimiento"
                                                    valueTemplate={this.templateDDL}
                                                    itemTemplate={this.templateItemDDL}
                                                />
                                                {this.props.Errores.ItemRequerimiento && <small className="p-invalid p-d-block">{this.props.Errores.ItemRequerimiento}</small>}
                                            </div>
                                        </div>
                                        : null}
                                    {!this.props.Tarea.Desarrollo && ((this.props.Tarea.Ticket ? this.props.Tarea.Ticket.PKID > 0 : 0) || !this.props.Tarea.EsIncidente) ?
                                        <div className="p-grid p-fluid p-mr-1 p-ml-1">
                                            <div className="p-col-12">
                                                <label className="p-d-block" style={{
                                                    color: '#4D80E4',
                                                    fontFamily: 'Sarala',
                                                    fontSize: '20px'
                                                }}>Area</label>
                                                <Dropdown
                                                    value={{
                                                        code: this.props.Tarea.Area ? this.props.Tarea.Area.PKID : 0,
                                                        name: this.props.Tarea.Area ? this.props.Tarea.Area.Nombre : ''
                                                    }}
                                                    options={this.props.ListaAreaDDL}
                                                    onChange={this.props.onChangeArea}
                                                    optionLabel="name"
                                                    placeholder="Seleccione Area"
                                                    valueTemplate={this.templateDDL}
                                                    itemTemplate={this.templateItemDDL}
                                                />
                                                {this.props.Errores.Area && <small className="p-invalid p-d-block">{this.props.Errores.Area}</small>}
                                            </div>
                                        </div>
                                        : null}
                                    {!this.props.Tarea.Desarrollo && ((this.props.Tarea.Ticket ? this.props.Tarea.Ticket.PKID > 0 : 0) || !this.props.Tarea.EsIncidente) ?
                                        <div className="p-grid p-fluid p-mr-1 p-ml-1">
                                            <div className="p-col-12">
                                                <label className="p-d-block" style={{
                                                    color: '#4D80E4',
                                                    fontFamily: 'Sarala',
                                                    fontSize: '20px'
                                                }}>Colaborador</label>
                                                <Dropdown
                                                    value={{
                                                        code: this.props.Tarea.Empleado ? this.props.Tarea.Empleado.PKID : 0,
                                                        name: this.props.Tarea.Empleado ? this.props.Tarea.Empleado.Nombre : ''
                                                    }}
                                                    options={this.props.ListaEmpleadoDDL}
                                                    onChange={this.props.onChangeColaborador}
                                                    optionLabel="name"
                                                    filter filterBy="name"
                                                    placeholder="Seleccione Responsable"
                                                    valueTemplate={this.templateDDL}
                                                    itemTemplate={this.templateItemDDL}
                                                />
                                                {this.props.Errores.Empleado && <small className="p-invalid p-d-block">{this.props.Errores.Empleado}</small>}
                                            </div>
                                        </div>
                                        : null}
                                    {!this.props.Tarea.Desarrollo && ((this.props.Tarea.Ticket ? this.props.Tarea.Ticket.PKID > 0 : 0) || !this.props.Tarea.EsIncidente) ?
                                        <div className="p-grid p-fluid p-mr-1 p-ml-1">
                                            <div className="p-col-12">
                                                <label className="p-d-block" style={{
                                                    color: '#4D80E4',
                                                    fontFamily: 'Sarala',
                                                    fontSize: '20px'
                                                }}>Tarea Asociada</label>
                                                <Dropdown
                                                    value={{
                                                        code: this.props.Tarea.Ticket ? this.props.Tarea.Ticket.PKID : 0,
                                                        name: this.props.Tarea.Ticket ? this.props.Tarea.Ticket.Nombre : ''
                                                    }}
                                                    options={this.props.ListaTicketDDL}
                                                    onChange={this.props.onChangeTareaAsociada}
                                                    optionLabel="name"
                                                    filter filterBy="name"
                                                    placeholder="Seleccione Ticket"
                                                    valueTemplate={this.templateDDL}
                                                    itemTemplate={this.templateItemDDL} />
                                                {this.props.Errores.Ticket && <small className="p-invalid p-d-block">{this.props.Errores.Ticket}</small>}
                                            </div>
                                        </div>
                                        : null}
                                    {!this.props.Tarea.Desarrollo && ((this.props.Tarea.Incidencia ? this.props.Tarea.Incidente.PKID > 0 : 0) || this.props.Tarea.EsIncidente) ?
                                        <div className="p-grid p-fluid p-mr-1 p-ml-1">
                                            <div className="p-col-12">
                                                <label className="p-d-block" style={{
                                                    color: '#4D80E4',
                                                    fontFamily: 'Sarala',
                                                    fontSize: '20px'
                                                }}>Incidente</label>
                                                <Dropdown
                                                    value={{
                                                        code: this.props.Tarea.Incidente.PKID,
                                                        name: this.props.Tarea.Incidente.Incidencia
                                                    }}
                                                    options={this.props.ListaIncidenteDDL}
                                                    onChange={this.props.onChangeIncidente}
                                                    optionLabel="name"
                                                    filter filterBy="name"
                                                    placeholder="Seleccione Incidente"
                                                    valueTemplate={this.templateDDL}
                                                    itemTemplate={this.templateItemDDL} />
                                                {this.props.Errores.Incidente && <small className="p-invalid p-d-block">{this.props.Errores.Incidente}</small>}
                                            </div>
                                        </div>
                                        : null}
                                    <div className="p-grid p-fluid p-mr-1 p-ml-1">
                                        <div className="p-col">
                                            <div className="p-grid p-fluid">
                                                <div className="p-col-12">
                                                    <label style={{
                                                        color: '#4D80E4',
                                                        fontFamily: 'Sarala',
                                                        fontSize: '20px'
                                                    }}>Horas Ejecutadas</label>
                                                    <InputText
                                                        value={horaAMostrar}
                                                        onChange={this.props.onChange}
                                                        name="EstimacionAjustada"
                                                        type="text"
                                                        disabled />
                                                    <small className="p-d-block" style={{ color: '#fff' }}>.</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="p-col">
                                            <div className="p-grid p-fluid">
                                                <div className="p-col-12">
                                                    <label style={{ color: '#fff' }}>.</label>
                                                    <Button
                                                        type="button"
                                                        label={this.props.cronometroEncendido ? "Detener" : "Empezar"}
                                                        style={{
                                                            background: '#4D80E4',
                                                            border: '1px solid #4D80E4'
                                                        }}
                                                        icon="fas fa-stopwatch"
                                                        disabled={!(this.props.esActualizacion && this.props.Tarea.Estado.PKID === 2 && this.props.habilitarBotonCronometro)}
                                                        onClick={this.props.pulsarCronometro} />
                                                    <small className="p-d-block" style={{ color: '#fff' }}>.</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-grid p-fluid p-mr-1 p-ml-1">
                                        <div className="p-col">
                                            <div className="p-grid p-fluid">
                                                <div className="p-col-12">
                                                    <label style={{
                                                        color: '#4D80E4',
                                                        fontFamily: 'Sarala',
                                                        fontSize: '20px'
                                                    }}>Origen Tareo</label>
                                                    <InputText
                                                        value={this.props.Tarea.OrigenTareo.Descripcion}
                                                        //onChange={this.props.onChange}
                                                        name="EstimacionAjustada"
                                                        type="text"
                                                        disabled />
                                                    <small className="p-d-block" style={{ color: '#fff' }}>.</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-d-flex footer p-ml-auto">
                        <Button
                            type="submit"
                            label={this.props.Request ? "Cargando..." : "Aceptar"}
                            style={{
                                background: '#4D80E4',
                                border: '1px solid #4D80E4'
                            }}
                            icon={this.props.Request ? "" : "pi pi-check"}
                            className="p-ml-auto"
                            disabled={this.props.Request}
                            autoFocus />
                    </div>
                </form>
            </Modal>
        )
    }
}