import React from 'react'
import IncidenteHeader from '../Components/IncidenteHeader'
import IncidenteBody from '../Components/IncidenteBody'
import IncidenteModal from '../Components/IncidenteModal'
import CasoPruebaModalPaso from '../Components/CasoPruebaModalPaso'

import { AuthHelper } from '../../Domain/Utils/AuthHelper'
import { EstadoService } from '../../Domain/Services/EstadoService'
import { EmpleadoService } from '../../Domain/Services/EmpleadoService'
import { CalidadService } from '../../Domain/Services/CalidadService'
import { CasoPruebaService } from '../../Domain/Services/CasoPruebaService'
import { VersionProductoService } from '../../Domain/Services/VersionProductoService'
import { IncidenteService} from '../../Domain/Services/IncidenteService'
import { CategorizacionService } from '../../Domain/Services/CategorizacionService'
import { IIncidente } from '../../Domain/Models/IIncidente'
import { IUsuario } from '../../Domain/Models/IUsuario'
import { IPaso } from '../../Domain/Models/IPaso'
import { IPaginacion } from '../../Domain/Models/IPaginacion'
import axios from 'axios'
import { url } from '../../Services/Axios'

interface IProps {
}

interface IState {
    ModalIncidente: boolean
    ModalPaso: boolean
    Incidente: IIncidente
    Paso: IPaso
    Pendiente: IPaginacion
    EnCurso: IPaginacion
    Hecho: IPaginacion
    ListaPendienteOriginal: IPaginacion
    ListaEnCursoOriginal: IPaginacion
    ListaHechoOriginal: IPaginacion
    ActualizoPaso: boolean
    ListaEstado: Array<object>
    ListaEmpleado: Array<object>
    ListaSeveridad: Array<object>
    ListaTipoPrueba: Array<object>
    ListaUsuarios: Array<IUsuario>
    ListaProducto: Array<object>
    TotalIncidencia: number
    Errores: any
    ErroresPaso: any
    Request: boolean
    Categorizacion: Array<object>
}

export default class Incidente extends React.Component<IProps, IState> {

    cPaginacion = {
        Lista: [],
        Paginacion: {
            NumeroPagina: 1, 
            RegistrosPagina: 5000, 
            NumeroPaginas: 0, 
            IDEstado: 1, 
            IDUsuario: parseInt(AuthHelper.GetDecoded().sub,10),
        },
        Loading: true
    }

    cIncidente = {
        PKID: 0,
        Incidencia: '',
        Detalle: '',
        IDCasoPruebaVersion : 0,
        Empleado: {
            PKID    : 0,
            Nombre  : ''
        },
        FechaCreacion: new Date(),
        TipoError: {
            PKID: 0,
            Nombre: ''
        },
        Prioridad: 0,
        Severidad: {
            PKID: 0,
            Nombre: ''
        },
        Estado: {
            PKID: 1,
            Descripcion: 'Pendiente'
        },
        Responsable: {
            PKID:parseInt(AuthHelper.GetDecoded().sub,10),
            Descripcion: AuthHelper.GetDecoded().Empleado
        },
        Producto : {
            PKID : 0,
            Nombre : '',
            VersionProducto : []
        },
        VersionProducto : {
            PKID : 0,
            Version : ''
        },
        CheckCorreo: false,
        TieneOCS: false,
        InputHabilitado: false,
        Pasos: [],
        Evidencias: [],
        Categorizacion:{
            PKID: 0,
            Descripcion: ''
        }
    } 

    cPaso = {
        PKID : 0,
        NumeroPaso : 1,
        Accion : '',
        ResultadoEsperado : '',
        Correcto : 1,
        FechaCreacion : new Date()
    }

    constructor(props : any){
        super(props)
        this.state = {
            ModalIncidente: false,
            ModalPaso: false,
            Incidente: this.cIncidente,            
            Paso: this.cPaso,
            Pendiente: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 1}},
            EnCurso: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 2}},
            Hecho: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 3}},
            ListaPendienteOriginal: { ...this.cPaginacion, Paginacion: { ...this.cPaginacion.Paginacion, IDEstado: 1 } },
            ListaEnCursoOriginal: { ...this.cPaginacion, Paginacion: { ...this.cPaginacion.Paginacion, IDEstado: 2 } },
            ListaHechoOriginal: { ...this.cPaginacion, Paginacion: { ...this.cPaginacion.Paginacion, IDEstado: 3 } },
            ActualizoPaso:false,
            ListaEstado: [],
            ListaEmpleado: [],
            ListaSeveridad: [],
            ListaTipoPrueba: [],
            ListaUsuarios: [],
            ListaProducto: [],            
            TotalIncidencia: 0,
            Errores : {},
            ErroresPaso : {},
            Request: false,
            Categorizacion: []
        }
    }

    componentDidMount(){
        this.ListarEstado()
        this.ListarEmpleado()
        this.ListarSeveridad()
        this.ListarTipoPrueba()
        this.ListarUsuario()
        this.ListarVersionProducto()
        this.ListarIncidencia('Pendiente')
        this.ListarIncidencia('EnCurso')
        this.ListarIncidencia('Hecho')
        this.ListarCategorizacion()
    }

    ListarCategorizacion(){
        CategorizacionService.Listar()
        .then( (response : any) => {
            if (response.success) {
                var aCategorizacion : Array<object> = []
                Object.keys(JSON.parse(response.data)).forEach(function(key){
                    var iCategorizacion = {
                        code: JSON.parse(response.data)[key].PKID,
                        name: JSON.parse(response.data)[key].Descripcion,
                    }
                    aCategorizacion.push(iCategorizacion)
                })
                this.setState({Categorizacion: aCategorizacion})
            }else{
                alert(response.data)
            }
        })
        .catch( (error) => {
            alert(error)
        })
    }

    ListarEstado(){
        EstadoService.Listar()
        .then( (response : any) => {
            if(response.success){
                var aEstado: Array<object> = []
                Object.keys(JSON.parse(response.data)).forEach(function(key){
                    var iEstado = {
                        code: JSON.parse(response.data)[key].PKID,
                        name: JSON.parse(response.data)[key].Descripcion,
                    }
                    aEstado.push(iEstado);
                });
                this.setState({ListaEstado : aEstado})
            }else{
                throw new Error(response.data);
            }
        })
        .catch( (error) => {
            alert(error)
        })
    }

    ListarEmpleado(){
        EmpleadoService.Listar()
        .then((response : any) => {
            if(response.success){
                var aEmpleado: Array<object> = []
                Object.keys(JSON.parse(response.data)).forEach(function(key){
                    var iEmpleado = {
                        code: JSON.parse(response.data)[key].PKID,
                        name: JSON.parse(response.data)[key].Nombre,
                    }
                    aEmpleado.push(iEmpleado);
                });
                this.setState({ListaEmpleado : aEmpleado})
            }else{
                alert(response.data)
            }
        }).catch((Error: any) => {
            alert(Error)
        })
    }

    ListarSeveridad(){
        CalidadService.ListarSeveridad()
        .then((response: any) => {
            if(response.success){
                var aSeveridad: Array<object> = []
                Object.keys(JSON.parse(response.data)).forEach(function(key){
                    var iSeveridad = {
                        code: JSON.parse(response.data)[key].PKID,
                        name: JSON.parse(response.data)[key].Nombre,
                    }
                    aSeveridad.push(iSeveridad);
                });
                this.setState({ListaSeveridad : aSeveridad})
            }else{
                alert(response.data)
            }
        })
        .catch((Error) => {
            alert(Error)
        })
    }

    ListarTipoPrueba(){
        CasoPruebaService.ListarTipoPrueba()
        .then( (response: any) => {
            if (response.success) {
                let lista: Array<object>=[]
                Object.keys(JSON.parse(response.data)).forEach(function(key){
                    lista.push({
                        code:JSON.parse(response.data)[key].PKID,
                        name:JSON.parse(response.data)[key].Nombre
                    })
                })
                this.setState({
                    ListaTipoPrueba:lista
                })
            }else{
                alert(response.data)
            }
        })
        .catch( (Error) => {
            alert(Error)
        })
    }

    ListarUsuario(){
        CalidadService.ListarUsuarios()
        .then((response: any) => {
            if (response.success) {
                this.setState({
                    ListaUsuarios : JSON.parse(response.data)
                })
            }else{
                alert(response.data)
            }
        })
        .catch((Error) => {
            alert(Error)
        })
    }

    ListarVersionProducto(){
        VersionProductoService.Listar()
        .then( (response : any) => {
            if (response.success) {
                var aProducto : Array<object> = []
                let lProducto : Array<{PKID: number, Nombre: string}> = JSON.parse(response.data)
                Object.keys(lProducto).forEach(function(k1: any){
                    let oProducto: any = lProducto[k1]
                    let aVersionProducto: Array<object> = []
                    if(oProducto.VersionProducto){
                        Object.keys(oProducto.VersionProducto).forEach(function( k2 ){
                            aVersionProducto.push({
                                code : oProducto.VersionProducto[k2].PKID,
                                name : oProducto.VersionProducto[k2].Version,
                            })
                        })
                    }
                    aProducto.push({
                        code : lProducto[k1].PKID,
                        name : lProducto[k1].Nombre,
                        VersionProducto : aVersionProducto
                    })
                })
                this.setState({ListaProducto: aProducto})
            }else{
                alert(response.data)
            }
        })
        .catch( (error) => {
            alert(error)
        })
    }

    ListarIncidencia = ( Estado : string) => {
        IncidenteService.ListarPaginacion(
            {
                'Pendiente': this.state.Pendiente.Paginacion,
                'EnCurso' : this.state.EnCurso.Paginacion,
                'Hecho' : this.state.Hecho.Paginacion
            }[Estado]
        )
        .then((response : any) => {
            if(response.success){
                let jParse = JSON.parse(response.data)
                let rIncidente: Array<IIncidente | any> = jParse.Incidente ? jParse.Incidente : []
                let rPaginacion: any = jParse.Paginacion
                switch(Estado){
                    case 'Pendiente': 
                        this.setState({Pendiente: {Lista: this.state.Pendiente.Lista.concat(rIncidente),Paginacion: rPaginacion,Loading: false}},() => this.TotalIncidente())
                        this.setState({ ListaPendienteOriginal: { Lista: this.state.ListaPendienteOriginal.Lista.concat(rIncidente), Paginacion: rPaginacion, Loading: false } }, () => this.TotalIncidente())
                        break
                    case 'EnCurso' : 
                        this.setState({EnCurso: {Lista: this.state.EnCurso.Lista.concat(rIncidente),Paginacion: rPaginacion,Loading: false}},() => this.TotalIncidente())
                        this.setState({ ListaEnCursoOriginal: { Lista: this.state.ListaEnCursoOriginal.Lista.concat(rIncidente), Paginacion: rPaginacion, Loading: false } }, () => this.TotalIncidente())
                        break 
                    case 'Hecho' : 
                        this.setState({Hecho: {Lista: this.state.Hecho.Lista.concat(rIncidente),Paginacion: rPaginacion,Loading: false}},() => this.TotalIncidente())   
                        this.setState({ ListaHechoOriginal: { Lista: this.state.ListaHechoOriginal.Lista.concat(rIncidente), Paginacion: rPaginacion, Loading: false } }, () => this.TotalIncidente())             
                        break
                    default:
                        break
                }
            }else{
                throw new Error(response.data);
            }
        })
        .catch((Error) => {
            alert(Error)
        })
    }
   
    TotalIncidente(){
        this.setState({
            TotalIncidencia : this.state.Pendiente.Lista.length + this.state.EnCurso.Lista.length + this.state.Hecho.Lista.length
        })
    }

    Paginacion = (Estado : string) => {
        if(
            (
                {
                    'Pendiente': ((this.state.Pendiente.Paginacion.NumeroPagina < this.state.Pendiente.Paginacion.NumeroPaginas) && !this.state.Pendiente.Loading),
                    'EnCurso' : ((this.state.EnCurso.Paginacion.NumeroPagina < this.state.EnCurso.Paginacion.NumeroPaginas) && !this.state.EnCurso.Loading),
                    'Hecho' : ((this.state.Hecho.Paginacion.NumeroPagina < this.state.Hecho.Paginacion.NumeroPaginas) && !this.state.Hecho.Loading)
                }[Estado]
            )){
                switch(Estado){
                    case 'Pendiente':
                        this.setState({
                            Pendiente: {
                                ...this.state.Pendiente,
                                Paginacion:{
                                    ...this.state.Pendiente.Paginacion,
                                    NumeroPagina: this.state.Pendiente.Paginacion.NumeroPagina + 1},
                                Loading: true
                            }
                        },() => this.ListarIncidencia('Pendiente'))
                        break
                    case 'EnCurso' : 
                        this.setState({
                            EnCurso: {
                                ...this.state.EnCurso
                                ,Paginacion:{
                                    ...this.state.EnCurso.Paginacion,
                                    NumeroPagina: this.state.EnCurso.Paginacion.NumeroPagina + 1},
                                Loading: true
                            }
                        },() => this.ListarIncidencia('EnCurso'))
                        break
                    case 'Hecho' : 
                        this.setState({
                            Hecho: {
                                ...this.state.Hecho,
                                Paginacion:{
                                    ...this.state.Hecho.Paginacion,
                                    NumeroPagina: this.state.Hecho.Paginacion.NumeroPagina + 1},
                                Loading: true
                            }
                        },() => this.ListarIncidencia('Hecho'))
                        break
                    default :
                        break
                }
        }
    }

    OnResponse = (Incidente: any)=>{
        if(Incidente){
            let jLista: any = this.state.ListaProducto
            Incidente = {
                ...Incidente,
                Producto : {
                    ...Incidente.Producto,
                    VersionProducto : jLista.find( (itemVersion: any) => itemVersion.code === Incidente.Producto.PKID).VersionProducto
                }
            }
        }

        this.setState({
            Incidente: Incidente ? Incidente : this.cIncidente,
            Errores: {},
            ErroresPaso: {}
        },() => this.HideVisibleModalIncidente(true))
    }

    HideVisibleModalIncidente = (Mode: boolean) => {
        this.setState({
            ModalIncidente: Mode
        })
    }

    MoverEstado = ( EstadoI : string, EstadoF : string, PKID : string) => {
        if(EstadoI !== EstadoF){
            let Obj = {'Pendiente': this.state.Pendiente.Lista,'EnCurso' : this.state.EnCurso.Lista,'Hecho' : this.state.Hecho.Lista}[EstadoI] || []
            switch(EstadoF){
                case 'Pendiente' :
                    this.setState({
                        Incidente: IncidenteService.ObtenerObjetoEstado(Obj,PKID,1)
                    },() => this.CrearActualizar())
                    break
                case 'EnCurso' :
                    this.setState({
                        Incidente: IncidenteService.ObtenerObjetoEstado(Obj,PKID,2)
                    },() => this.CrearActualizar())
                    break
                case 'Hecho' :
                    this.setState({
                        Incidente: IncidenteService.ObtenerObjetoEstado(Obj,PKID,3)
                    },() => this.CrearActualizar())
                    break
                default:
                    break
            }
        }
    }

    CrearActualizar(){
        IncidenteService.CrearActualizar(this.state.Incidente)
        .then( (response: any) => {
            if(response.success){
                //alert("Incidente ID : " + response.data)
                    this.setState({
                        Pendiente: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 1}},
                        EnCurso: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 2}},
                        Hecho: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 3}},
                        ModalIncidente:false,
                    }, () => this.componentDidMount())
                
            }else{
                alert(response.data)
            }
        }).catch( (error) => {
            alert(error)
        }).finally(() => {
            if(this.state.Incidente.CheckCorreo){
            this.EnviarCorreo();}
            this.setState({
                Request: false
            })
        })
    }

    async EnviarCorreo(){
        let EmailOrigen='', Contrasegna='', EmailDestino='', Asunto='', Mensaje=''
        let listaUser = this.state.ListaUsuarios
        EmailOrigen = ''
        Contrasegna = ''
        let idResponsable = this.state.Incidente.Empleado.PKID
       
        Object.keys(listaUser).forEach(function(Index: any){            
            if(listaUser[Index].Empleado.PKID===idResponsable){
                EmailDestino=listaUser[Index].Credencial+"@uniflex.com.pe"
            }
        });
        
        Asunto =this.state.Incidente.Incidencia

        Mensaje = "Incidencia: "+this.state.Incidente.Incidencia+"\n" + 
                    "Detalle: "+this.state.Incidente.Detalle+"\n" +
                    "Responsable: "+this.state.Incidente.Empleado.Nombre+"\n"+ 
                    "Fecha de Creación: "+this.state.Incidente.FechaCreacion+"\n"+ 
                    "Tipo de Error : "+this.state.Incidente.TipoError.Nombre+"\n"+ 
                    "Prioridad: "+this.state.Incidente.Prioridad+"\n"+ 
                    "Severidad: "+this.state.Incidente.Severidad.Nombre

        axios.post(url()+'Calidad/EnviarCorreo?EmailOrigen='+EmailOrigen+'&Contrasegna='+Contrasegna+'&EmailDestino='+EmailDestino+'&Asunto='+Asunto+'&Mensaje='+Mensaje)
        .then( (response) => {
            if (response.data.success) {                
                alert(response.data.data);
            }else{
                throw new Error(response.data.data)
            }
        })
        .catch( (error) => {            
            alert(error.message);
        })
        .finally( () => {
            this.setState({
                Incidente : this.cIncidente,                
                ModalIncidente: false,
                Request : false
            })
        })
    }

    HandleChange = (Event: any) => {
        console.log(Event.target.name)
        switch(Event.target.name){
            case 'Empleado' :
                this.setState({
                    Incidente:{
                        ...this.state.Incidente,
                        Empleado:{
                            PKID : Event.value.code,
                            Nombre : Event.value.name
                        }
                    }
                })
                break
            case 'Severidad' :
                this.setState({
                    Incidente:{
                        ...this.state.Incidente,
                        Severidad:{
                            PKID : Event.value.code,
                            Nombre : Event.value.name
                        }
                    }
                })
                break
            case 'TipoError':
                this.setState({
                    Incidente:{
                        ...this.state.Incidente,
                        TipoError : {
                            PKID : Event.value.code,
                            Nombre : Event.value.name
                        }
                    }
                })
                break
            case 'Producto':
                this.setState({
                    Incidente : {
                        ...this.state.Incidente,
                        Producto : {
                            PKID : Event.value.code,
                            Nombre : Event.value.name,
                            VersionProducto : Event.value.VersionProducto
                        }
                    },
                })
                break
            case 'VersionProducto':
                this.setState({
                    Incidente : {
                        ...this.state.Incidente,
                        VersionProducto : {
                            PKID : Event.value.code,
                            Version : Event.value.name
                        }
                    },
                })
                break
                case 'Categorizacion':
                this.setState({
                    Incidente : {
                        ...this.state.Incidente,
                        Categorizacion : {
                            PKID : Event.value.code,
                            Descripcion : Event.value.name
                        }
                    },
                })
                break
            case 'CheckCorreo':
                this.setState({
                    Incidente:{
                        ...this.state.Incidente,
                        CheckCorreo: !this.state.Incidente.CheckCorreo
                    }
                })
                break
            case 'TieneOCS':
                    this.setState({
                        Incidente: {
                            ...this.state.Incidente,
                            TieneOCS: !this.state.Incidente.TieneOCS,
                            InputHabilitado: !this.state.Incidente.TieneOCS
                        }
                    })
                break
            default :
                this.setState({
                    Incidente:{
                        ...this.state.Incidente,
                        [Event.target.name]: Event.target.value
                    }
                })
                break
        }
    }

    ActualizarPaso = (Event: any)=>{
        this.setState({
            Paso : Event,
            ErroresPaso: {}
        },() => this.HideVisibleModalPaso(true))
    }

    EliminarPaso = (RowData: any) => {
        let lPasos = this.state.Incidente.Pasos
        if(lPasos.filter( (Event: any) => { return Event.NumeroPaso === RowData.NumeroPaso}).length > 0){
            lPasos = lPasos.filter( (Event: any) => { return Event.NumeroPaso !== RowData.NumeroPaso})
        }
        var lOrdenado: Array<object> = []
        if(lPasos.length > 0){
            lPasos.forEach((Element,Index) => {
                Element = {
                    ...Element,
                    NumeroPaso : Index + 1
                }
                lOrdenado.push(Element)
            });
        }
        this.setState({
            Incidente:{
                ...this.state.Incidente,
                Pasos : lOrdenado
            },
        })
    }

    SubirArchivo = (Event: any, FileUpload: any) =>  {
        const file = Event.files[0]
        const reader: any = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
            let encoded = reader.result.toString().replace(/^data:(.*,)?/, '')
            if ((encoded.length % 4) > 0) {
                encoded += '='.repeat(4 - (encoded.length % 4));
            }
            var oArchivoAdjunto = {
                PKID : 0,
                Nombre : file.name.replace(/[.][^.]+$/, ''),
                FechaCreacion : '',
                Tamagno : file.size,//parseFloat(file.size / Math.pow(1024,2)).toFixed(2), //De Bytes a MegaBytes
                Archivo : encoded,
                Extension : file.name.slice((file.name.lastIndexOf(".") - 1 >>> 0) + 2)
            }
            this.setState({
                Incidente :{
                    ...this.state.Incidente,
                    Evidencias:[
                        ...this.state.Incidente.Evidencias,
                        oArchivoAdjunto
                    ]
                }
            })
        }
        reader.onerror = (Error: any) => {
            alert(Error)
        }
        FileUpload.current.files = ''
        FileUpload.current.state.files = ''
    }

    BorrarArchivo = (Event: any) => {
        let lArchivos = this.state.Incidente.Evidencias
        if(lArchivos.filter( (e) => { return e === Event}).length > 0){
            lArchivos = lArchivos.filter( (e) => { return e !== Event})
        }
        this.setState({
            Incidente :{
                ...this.state.Incidente,
                Evidencias : lArchivos
            }
        })
    }

    DescargarArchivo = (Event: any) => {
        let lArchivos = this.state.Incidente.Evidencias
        if(lArchivos.filter( (e) => { return e === Event}).length > 0){
            lArchivos = lArchivos.filter( (e) => { return e === Event})
            const oArchivos: any = lArchivos[0]
            IncidenteService.DescargarArchivo(oArchivos)
        }else{
            alert('Error: No se encontro el archivo indicado')
        }
    }

    CrearNuevoPaso = () => {
        this.setState({
            Paso:{
                ...this.cPaso,
                NumeroPaso : this.state.Incidente.Pasos.length + 1,
            },
            ErroresPaso: {}
        },() => this.HideVisibleModalPaso(true))
    }

    HideVisibleModalPaso = (Mode: boolean) => {
        this.setState({
            ModalPaso: Mode
        })
    }

    OnRowReorder = (Event: any) => {
        let lista= Event.value, nuevaLista=[]
        if(lista!==null && lista!==undefined){
            for(let i=0; i<lista.length; i++){
                let obj=lista[i]
                obj={
                    ...obj,
                    NumeroPaso:i+1
                }
                nuevaLista.push(obj)
            }
        }
        this.setState({
            Incidente:{
                ...this.state.Incidente,
                Pasos:nuevaLista
            }
        })
    }

    HandleSubmit = (Event: any) => {
        Event.preventDefault()
        const result = IncidenteService.ValidarSchema(this.state.Incidente);
        if(Object.keys(result).length){
            return this.setState({Errores: result});
        }else{
            this.setState({
                Request : true
            },() => this.CrearActualizar())
        }  
    }

    HandleSubmitPaso = (Event: any) => {
        Event.preventDefault()
        const result = IncidenteService.ValidarPaso(this.state.Paso)
        if(Object.keys(result).length) return this.setState({ErroresPaso : result});
        else this.CrearActualizarPaso()
    }

    CrearActualizarPaso(){
        let listaPasos=this.state.Incidente.Pasos
        if(listaPasos.filter( (e: any) => { return e.NumeroPaso === this.state.Paso.NumeroPaso}).length > 0){
            listaPasos = listaPasos.filter( (e: any) => { return e.NumeroPaso !== this.state.Paso.NumeroPaso})
        }
        listaPasos.push(this.state.Paso)
        listaPasos.sort( function(a: any, b: any) {
            return a.NumeroPaso - b.NumeroPaso
        })
        this.setState({
            Paso : this.cPaso,
            Incidente:{
                ...this.state.Incidente,
                Pasos:listaPasos
            }
        },() => this.HideVisibleModalPaso(false))
    }

    HandleChangePaso = (Event: any) => {
        this.setState({
            Paso:{
                ...this.state.Paso,
                [Event.target.name]: Event.target.value
            }
        })
    }


    HandleChangeBuscador = (e : any) => {
        const ListaPendienteOriginal=this.state.ListaPendienteOriginal.Lista
        const ListaEnCursoOriginal=this.state.ListaEnCursoOriginal.Lista
        const ListaHechoOriginal=this.state.ListaHechoOriginal.Lista

        const ListaPendienteFiltoBefore = this.state.ListaPendienteOriginal.Lista
        const ListaEnCursoFiltroBefore = this.state.ListaEnCursoOriginal.Lista
        const ListaHechoFiltroBefore = this.state.ListaHechoOriginal.Lista

        if (e.target.value=='') {

            this.setState({
                Pendiente:{
                    ...this.state.Pendiente,
                    Lista: ListaPendienteOriginal
                }
            })
            this.setState({
                EnCurso:{
                    ...this.state.EnCurso,
                    Lista: ListaEnCursoOriginal
                }
            })
            this.setState({
                Hecho:{
                    ...this.state.Hecho,
                    Lista: ListaHechoOriginal
                }
            })
          } else {

            if (this.state.Pendiente.Lista.length === 0 || this.state.EnCurso.Lista.length === 0 || this.state.Hecho.Lista.length) {
                const listaFiltradaPendiente = ListaPendienteFiltoBefore.filter((list) => list.Incidencia.toLowerCase().includes(e.target.value.toLowerCase()))
                const listaFiltradaEnCurso = ListaEnCursoFiltroBefore.filter((list) => list.Incidencia.toLowerCase().includes(e.target.value.toLowerCase()))
                const listaFiltradaHecho = ListaHechoFiltroBefore.filter((list) => list.Incidencia.toLowerCase().includes(e.target.value.toLowerCase()))
                this.setState({
                    Pendiente: {
                        ...this.state.Pendiente,
                        Lista: listaFiltradaPendiente
                    }
                })

                this.setState({
                    EnCurso: {
                        ...this.state.EnCurso,
                        Lista: listaFiltradaEnCurso
                    }
                })

                this.setState({
                    Hecho: {
                        ...this.state.Hecho,
                        Lista: listaFiltradaHecho
                    }
                })
            } else {
                const listaFiltradaPendiente=this.state.Pendiente.Lista.filter((list) => list.Incidencia.toLowerCase().includes(e.target.value.toLowerCase()))
                const listaFiltradaEnCurso=this.state.EnCurso.Lista.filter((list) => list.Incidencia.toLowerCase().includes(e.target.value.toLowerCase()))
                const listaFiltradaHecho=this.state.Hecho.Lista.filter((list) => list.Incidencia.toLowerCase().includes(e.target.value.toLowerCase()))
               
                
                this.setState({
                    Pendiente:{
                        ...this.state.Pendiente,
                        Lista: listaFiltradaPendiente
                    }
                })
                this.setState({
                    EnCurso:{
                        ...this.state.EnCurso,
                        Lista: listaFiltradaEnCurso
                    }
                })
                this.setState({
                    Hecho:{
                        ...this.state.Hecho,
                        Lista: listaFiltradaHecho
                    }
                })
              } 
            }
    }


    render(){
        return(
            <div className="container">
                <IncidenteHeader
                OnChangeBuscador={this.HandleChangeBuscador}/>
                <IncidenteBody                
                    Paginacion = {this.Paginacion}
                    Pendiente = {this.state.Pendiente}
                    EnCurso = {this.state.EnCurso}
                    Hecho = {this.state.Hecho}
                    OnResponse={this.OnResponse}
                    MoverEstado={this.MoverEstado}/>
                <form autoComplete="Off" onSubmit={this.HandleSubmit}>
                    <IncidenteModal
                        ModalIncidente= {this.state.ModalIncidente}
                        HideModal= {() => this.HideVisibleModalIncidente(false)}
                        FormValues= {this.state.Incidente}
                        HandleChange= {this.HandleChange}
                        ActualizarPaso= {this.ActualizarPaso}
                        EliminarPaso= {this.EliminarPaso}
                        SubirArchivo= {this.SubirArchivo}
                        BorrarArchivo= {this.BorrarArchivo}
                        DescargarArchivo= {this.DescargarArchivo}
                        CrearNuevoPaso= {this.CrearNuevoPaso}
                        ListaProducto= {this.state.ListaProducto}
                        ListaCategorizacion = {this.state.Categorizacion}
                        ListaEmpleado= {this.state.ListaEmpleado}
                        ListaTipoError= {this.state.ListaTipoPrueba}
                        ListaSeveridad = {this.state.ListaSeveridad}
                        OnRowReorder = {this.OnRowReorder}
                        Errores = {this.state.Errores}
                        Request= {this.state.Request}
                        InputHabilitado={this.state.Incidente.InputHabilitado} />
                </form>
                <form autoComplete="Off" onSubmit={this.HandleSubmitPaso}>
                    <CasoPruebaModalPaso
                        Modal= {this.state.ModalPaso}
                        HideModal= {() => this.HideVisibleModalPaso(false)}
                        FormValues = {this.state.Paso}
                        HandleChange = {this.HandleChangePaso}
                        Errores = {this.state.ErroresPaso}/>
                </form>
            </div>
        )
    }
}
