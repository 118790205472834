import React from 'react'
import { Button } from 'primereact/button';


interface IProps{
    ModalIngreso: any
    ModalSalida: any
    FormValues: any
}

export default function AsistenciaHeader(Props:IProps){

    return(
        <div className="p-col-12">
            <div className="CrumbBeetwen">
                <div className="p-d-flex">
                    <ul className="breadcrumb">
                        <li><a href="/Desarrollo/Inicio">Inicio</a></li>
                        <li>Asistencia</li>
                    </ul>
                    <div className="BtnFiltro p-ml-auto">
                        <div className="p-d-flex">                            
                            <div className="p-mr-2">
                                <Button 
                                    label="Registrar Asistencia"   
                                    type="button"
                                    style={{
                                        background:'#4D80E4',
                                        border: '1px solid #4D80E4'}}
                                    onClick={(Props.ModalIngreso)} 
                                    className="p-ml-auto"/>
                            </div>
                            <div className="p-mr-2">
                                <Button 
                                    label="Registrar Salida"   
                                    type="button"
                                    style={{
                                        background:'#4D80E4',
                                        border: '1px solid #4D80E4'}}
                                    onClick={(Props.ModalSalida)} 
                                    className="p-ml-auto"/>
                            </div>
                            <div className="p-mr-2">
                            <Button 
                                    id="OpenFilter"
                                    type="button" 
                                    icon="fas fa-filter" 
                                    onClick={() => 
                                    {
                                        (document.getElementById('PanelFiltro') as HTMLInputElement).classList.add('active');
                                        (document.getElementById('PopupFiltro') as HTMLInputElement).classList.add('active');
                                    }}  
                                    className="p-button-rounded p-button-text p-button-plain"/>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
    )
}