import React from 'react'
import RequerimientoHeader from '../Components/RequerimientoHeader'
import RequerimientoProyectoDetalle from '../Components/RequerimientoProyectoDetalle'
import RequerimientoBody from '../Components/RequerimientoBody'
import RequerimientoModal from '../Components/RequerimientoModal'
import { getProyecto,setProyecto, setRequerimiento } from '../../Services/LocalStorage'
import { BackLogService } from '../../Domain/Services/BackLogService'
import { AuthHelper } from '../../Domain/Utils/AuthHelper'
import { RequerimientoService} from '../../Domain/Services/RequerimientoService'
import { VersionProductoService } from '../../Domain/Services/VersionProductoService'
import { ProyectoService } from '../../Domain/Services/ProyectoService'
import { EstadoService } from '../../Domain/Services/EstadoService'
import { SolicitudService } from '../../Domain/Services/SolicitudService'
import { IImplementacionBackLog } from '../../Domain/Models/IImplementacionBackLog'
import { IBackLog } from '../../Domain/Models/IBackLog'
import { IRequerimiento } from '../../Domain/Models/IRequerimiento'
import { IPaginacion } from '../../Domain/Models/IPaginacion'
import ItemBackLog from '../../Implementacion/Pages/ItemBackLog'
import { MotivoVersionamientoService } from '../../Domain/Services/MotivoVersionamientoService'
import { ItemBackLogService } from '../../Domain/Services/ItemBackLogService'

interface IProps {
}

interface IState {
    PasProyecto: IBackLog
    Proyecto: IBackLog
    Pendiente: IPaginacion
    EnCurso: IPaginacion
    StandBy: IPaginacion
    Pruebas: IPaginacion
    Hecho: IPaginacion
    Requerimiento: IRequerimiento
    Errores: any
    ErroresB: any
    ListaEstado: Array<object>
    ListaEstadoPrincipales: Array<object>
    IDUsuario: number
    ListaSolicitudes: Array<object>
    ListaItemBackLogs: Array<object>
    ListaSolicitudesDatos: Array<object>
    ListaProducto: Array<object>
    ListaArchivosSubiendo: Array<object>
    Request: boolean
    ImplementacionBackLog: Array<object>
    ListaMotivoVersionamiento: Array<object>
}

 
export default class ItemProyecto extends React.Component<IProps, IState> {

    cProyecto = {
        PKID: 0,
        Nombre: '',
        Descripcion: '',
        HorasGerencia: 0,
        HorasEstimadas: 0,
        HorasEjecutadas: 0,
        Version: 0,
        FechaInicio: new Date(),
        FechaFin: new Date(),
        FechaCreacion: new Date(),
        Estado:{
            PKID: 0,
            Descripcion: ''
        },
        Empleado:{
            PKID:0,
            Codigo:'',
            Nombre:'',
            Area:{
                PKID:0,
                Codigo:'',
                Nombre:''
            }
        },
        Producto : {
            PKID : 0,
            Nombre : '',
            VersionProducto : []
        },
        VersionProducto : {
            PKID : 0,
            Version : ''
        },
        ImplementacionBackLog : {
            PKID : 0,
            Nombre : '',
            Version: 0,
            ImplementacionBackLogVersion : []
        },
        ImplementacionBackLogVersion : {
            PKID : 0,
            Version : 0
        },
        Objetivo:'',
        MotivoVersionamiento:{
            PKID:0,
            Descripcion:''
        },
        DetalleVersionamiento:'',
        Activo:false,
        PorcentajeAvance: 0
    }

    cRequerimiento = {
        PKID: 0,
        Requisito : '',
        Estimacion : 0,
        FactorAjuste : 1,
        EstimacionAjustada : 0,
        IDBackLog : getProyecto()? JSON.parse(getProyecto() || '{}').PKID : 0,
        FechaInicio : new Date(),
        FechaFin : new Date(),
        PorcentajeAvance : 0,
        Estado:{
            PKID: 0,
            Descripcion: ''
        },
        HorasEjecutadas : 0,
        ContextoEstandar : '',
        ProblemaEstandar : '',
        Responsable:{
            PKID: parseInt(AuthHelper.GetDecoded().sub,10),
            Nombre: AuthHelper.GetDecoded().Empleado
        },
        Solicitud:{
            PKID:0,
            Nombre:'',
            Empleado:{
                PKID:0,
                Codigo:'',
                Nombre:'',
                Area:{
                    PKID:0,
                    Codigo:'',
                    Nombre:''
                }
            }
        },
        ArchivosAdjuntos: [],
        NumeroOCS: '',
        DescripcionOCS: '',
        Descripcion:'',
        ImplementacionItemBackLog:{
            PKID:0,
            Nombre:''
        },
        ArchivosSolucionAdjuntos: []
    }

    cPaginacion = {
        Lista: [],
        Paginacion: {
            NumeroPagina: 1, 
            RegistrosPagina: 5, 
            NumeroPaginas: 0, 
            IDEstado: 1, 
            IDUsuario: parseInt(AuthHelper.GetDecoded().sub,10),
            IDBackLog: getProyecto()? JSON.parse(getProyecto() || '{}').PKID:0
        },
        Loading: true
    }

    constructor(props: any){
        super(props);
        this.state = {
            PasProyecto: this.cProyecto,
            Proyecto: this.cProyecto,
            Pendiente: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 1}},
            EnCurso: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 2}},
            StandBy: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 5}},
            Pruebas: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 4}},
            Hecho: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 3}},  
            Requerimiento: this.cRequerimiento,
            Errores: {},
            ErroresB: {},
            ListaEstado: [],
            ListaEstadoPrincipales: [],
            IDUsuario: 0,
            ListaSolicitudes: [],
            ListaItemBackLogs: [],
            ListaSolicitudesDatos: [],
            ListaProducto: [],
            ListaArchivosSubiendo:[],
            ImplementacionBackLog:[],
            Request : false,
            ListaMotivoVersionamiento:[]
        }
    }

    componentDidMount(){
        if(getProyecto()){
            this.ConsultarBackLog();
        }
        this.ListarEstado();
        this.ListarSolicitudes()
        this.ListarVersionProducto()
        this.ListarImplementacionBackLog()
        this.ListarMotivoVersionamiento()    
        this.ListarItemBackLogs()
    }

    ListarMotivoVersionamiento(){
        MotivoVersionamientoService.Listar()
        .then( (response : any) => {
            if (response.success) {
                var aMotivoVersionamiento : Array<object> = []
                Object.keys(JSON.parse(response.data)).forEach(function(key){
                    var iMotivoVersionamiento = {
                        code: JSON.parse(response.data)[key].PKID,
                        name: JSON.parse(response.data)[key].Descripcion,
                    }
                    aMotivoVersionamiento.push(iMotivoVersionamiento)
                })
                this.setState({ListaMotivoVersionamiento: aMotivoVersionamiento})
            }else{
                alert(response.data)
            }
        })
        .catch( (error) => {
            alert(error)
        })
    }

    ListarImplementacionBackLog(){
        BackLogService.ListarPaginacion(    
              this.state.Hecho.Paginacion
        )
        .then( (response : any) => {
            if (response.success) {
                var aProducto : Array<object> = []
                let jParse = JSON.parse(response.data)
                let lProducto: Array<IImplementacionBackLog | any> = jParse.BackLog ? jParse.BackLog : []                
                Object.keys(lProducto).forEach(function(k1: any){
                    let oProducto: any = lProducto[k1]
                    let aVersionProducto: Array<object> = []
                    if(oProducto.ImplementacionBackLogVersion){
                        Object.keys(oProducto.ImplementacionBackLogVersion).forEach(function( k2 ){
                            aVersionProducto.push({
                                code : oProducto.ImplementacionBackLogVersion[k2].PKID,
                                name : oProducto.ImplementacionBackLogVersion[k2].Version,
                            })
                        })
                    }
                    aProducto.push({
                        code : lProducto[k1].PKID,
                        name : lProducto[k1].Nombre,
                        VersionProducto : aVersionProducto
                    })
                })
                this.setState({ImplementacionBackLog: aProducto})
            }else{
                alert(response.data)
            }
        })
        .catch( (error) => {
            alert(error)
        })
    }

    ConsultarBackLog(){
        ProyectoService.Consultar(JSON.parse(getProyecto() || '{}'))
        .then((response: any) => {
            let PKID=JSON.parse(response.data).PKID
            let Descripcion=JSON.parse(response.data).Descripcion
            console.log("select * from BackLog WHERE PKID="+PKID)
            console.log("Descripcion : "+Descripcion)
            console.log(response.data)
            if (response.success) {
                var json = JSON.parse(response.data)
                setProyecto(response.data)
                var aVersion: Array<object> = []
                Object.keys(json.Producto.VersionProducto).forEach(function(key){
                    var iVersion = {
                        code: json.Producto.VersionProducto[key].PKID,
                        name: json.Producto.VersionProducto[key].Version,
                    }
                    aVersion.push(iVersion)
                });
                json = {
                    ...json,
                    Producto:{
                        ...json.Producto,
                        VersionProducto : aVersion
                    }
                }

                var aVersion2: Array<object> = []
                Object.keys(json.ImplementacionBackLog.ImplementacionBackLogVersion).forEach(function(key){
                    var iVersion2 = {
                        code: json.ImplementacionBackLog.ImplementacionBackLogVersion[key].PKID,
                        name: json.ImplementacionBackLog.ImplementacionBackLogVersion[key].Version,
                    }
                    aVersion2.push(iVersion2)
                });
                json = {
                    ...json,
                    ImplementacionBackLog:{
                        ...json.ImplementacionBackLog,
                        ImplementacionBackLogVersion : aVersion2
                    }
                }

                this.setState({
                    Proyecto: json,
                    PasProyecto: json
                })
            }else{
                alert(response.data.data)
            }
        })
        .catch( (error) => {
            alert(error)
        })
        .finally( () => {
            this.ListarRequerimiento('Pendiente')
            this.ListarRequerimiento('EnCurso')
            this.ListarRequerimiento('Pruebas')
            this.ListarRequerimiento('Hecho')
            this.ListarRequerimiento('StandBy')
        })
    }

    ListarRequerimiento = (Estado: string) => {
        RequerimientoService.ListarPaginacion(
            {
                'Pendiente': this.state.Pendiente.Paginacion,
                'EnCurso' : this.state.EnCurso.Paginacion,
                'Hecho' : this.state.Hecho.Paginacion,
                'Pruebas' : this.state.Pruebas.Paginacion,
                'StandBy' : this.state.StandBy.Paginacion
            }[Estado]
        )
        .then((response: any) => {
            if(response.success){
                let jParse = JSON.parse(response.data)
                let rItemBackLog: Array<IRequerimiento | any> = jParse.ItemBackLog ? jParse.ItemBackLog : []
                let rPaginacion: any = jParse.Paginacion
                switch(Estado){
                    case 'Pendiente': 
                        this.setState({Pendiente: {Lista: this.state.Pendiente.Lista.concat(rItemBackLog),Paginacion: rPaginacion,Loading: false}})
                        break
                    case 'EnCurso' : 
                        this.setState({EnCurso: {Lista: this.state.EnCurso.Lista.concat(rItemBackLog),Paginacion: rPaginacion,Loading: false}})
                        break 
                        case 'StandBy' : 
                        this.setState({StandBy: {Lista: this.state.StandBy.Lista.concat(rItemBackLog),Paginacion: rPaginacion,Loading: false}})
                        break 
                    case 'Hecho' : 
                        this.setState({Hecho: {Lista: this.state.Hecho.Lista.concat(rItemBackLog),Paginacion: rPaginacion,Loading: false}})                
                        break
                    case 'Pruebas':
                        this.setState({Pruebas: {Lista: this.state.Pruebas.Lista.concat(rItemBackLog),Paginacion: rPaginacion,Loading: false}})                
                        break
                    default:
                        break
                }
            }else{
                alert(response.data)
            }
        })
        .catch( (error) => {
            alert(error)
        })
    }

    ListarEstado(){
        EstadoService.Listar()
        .then( (response : any) => {
            if(response.success){
                var aEstado: Array<object> = [], bEstado: Array<object> = []
                Object.keys(JSON.parse(response.data)).forEach(function(key){
                    var iEstado = {
                        code: JSON.parse(response.data)[key].PKID,
                        name: JSON.parse(response.data)[key].Descripcion,
                    }
                    aEstado.push(iEstado);
                    if(iEstado.code===1 || iEstado.code===2 || iEstado.code===3){
                        bEstado.push(iEstado)
                    }
                });
                this.setState({
                    ListaEstado: aEstado,
                    ListaEstadoPrincipales: bEstado
                })
            }else{
                alert(response.data);
            }
        })
        .catch((error) => {
            alert(error)
        })
    }

    ListarSolicitudes(){
        SolicitudService.Listar()
        .then((response: any) => {
            if(response.success){
                let lTemporal: Array<object>= [], lDatos: Array<object>= []
                lTemporal.push({
                    code: 0,
                    name: "[=======(Quitar Solicitud Seleccionadada)======]",
                })
                Object.keys(JSON.parse(response.data)).forEach(function(key){
                    lTemporal.push({
                        code: JSON.parse(response.data)[key].PKID,
                        name: JSON.parse(response.data)[key].Nombre,
                    });
                    lDatos.push(JSON.parse(response.data)[key])
                });
                this.setState({
                    ListaSolicitudes: lTemporal,
                    ListaSolicitudesDatos: lDatos
                })
            }
        })
        .catch((error) => {
            alert(error)
        })
    }

    ListarItemBackLogs(){  
        var proyect = JSON.parse(getProyecto() || '{}')
        ItemBackLogService.Listar(proyect.ImplementacionBackLog.PKID)
        .then( (response : any) => {
            if(response.success){
                var aItemBackLog: Array<object> = []
                Object.keys(JSON.parse(response.data)).forEach(function(key){
                    var iItemBackLog = {
                        code: JSON.parse(response.data)[key].PKID,
                        name: JSON.parse(response.data)[key].Nombre,
                    }
                    aItemBackLog.push(iItemBackLog);                    
                });
                this.setState({
                    ListaItemBackLogs: aItemBackLog                    
                })
            }else{
                alert(response.data);
            }
        })
        .catch((error) => {
            alert(error)
        })
    }

    ListarVersionProducto(){
        VersionProductoService.Listar()
        .then( (response : any) => {
            if (response.success) {
                var aProducto : Array<object> = []
                let lProducto : Array<{PKID: number, Nombre: string}> = JSON.parse(response.data)
                Object.keys(lProducto).forEach(function(k1: any){
                    let oProducto: any = lProducto[k1]
                    let aVersionProducto: Array<object> = []
                    if(oProducto.VersionProducto){
                        Object.keys(oProducto.VersionProducto).forEach(function( k2 ){
                            aVersionProducto.push({
                                code : oProducto.VersionProducto[k2].PKID,
                                name : oProducto.VersionProducto[k2].Version,
                            })
                        })
                    }
                    aProducto.push({
                        code : lProducto[k1].PKID,
                        name : lProducto[k1].Nombre,
                        VersionProducto : aVersionProducto
                    })
                })
                this.setState({ListaProducto: aProducto})
            }else{
                alert(response.data)
            }
        })
        .catch( (error) => {
            alert(error)
        })
    }

    HandleChangeRequerimiento = (Event : React.ChangeEvent<HTMLInputElement>) => { 
        const {name, value} : any= Event.target
        switch(name){
            case 'ImplementacionItemBackLog':                
                this.setState({ 
                    Requerimiento: {
                        ...this.state.Requerimiento,
                        ImplementacionItemBackLog: {
                            PKID: value.code,
                            Nombre: value.name
                        },
                        Requisito: value.name
                    }                    
                })
                break
            case 'Estado':
                if(this.state.PasProyecto.Estado.PKID===3){
                    alert("El Estado del proyecto debe estar en curso para cambiar el estado del requerimiento")
                    return
                }
                this.setState({ 
                    Requerimiento: {
                        ...this.state.Requerimiento,
                        Estado: {
                            PKID: value.code,
                            Descripcion: value.name
                        }
                    }
                })
                break
            case 'Solicitud':
                if(value.code === 0){
                    this.setState({ 
                        Requerimiento: {
                            ...this.state.Requerimiento,
                            Solicitud: {
                                PKID: 0,
                                Nombre: ''
                            }
                        }
                    });
                    return
                }
                let Solis: any = this.state.ListaSolicitudesDatos
                let SolicitEncontrada = {
                    ...this.state.Requerimiento.Solicitud,
                    PKID: value.code,
                    Nombre: value.name,
                }
                Object.keys(Solis).forEach(function(key: any){
                    if(Solis[key].PKID === value.code){
                        SolicitEncontrada= {
                            ...SolicitEncontrada,
                            Empleado: Solis[key].Empleado
                        }
                    }
                })
                this.setState({ 
                    Requerimiento: {
                        ...this.state.Requerimiento,
                        Solicitud: SolicitEncontrada
                    }
                })
                break
            default : 
                this.setState({
                    Requerimiento: {
                        ...this.state.Requerimiento,
                        [name]: value
                    }
                })
                break
        }
    }

    HandleChangeProyecto = (Event : React.ChangeEvent<HTMLInputElement>) => { 
        console.log(Event.target.name)
        console.log(Event.target.value)
        const {name, value} : any= Event.target
        switch(name){
            case 'Estado':
                this.setState({ 
                    Proyecto: {
                        ...this.state.Proyecto,
                        Estado: {
                            PKID: value.code,
                            Descripcion: value.name
                        }
                    }
                });
                break
            case 'Producto':
                this.setState({
                    Proyecto : {
                        ...this.state.Proyecto,
                        Producto : {
                            PKID : value.code,
                            Nombre : value.name,
                            VersionProducto : value.VersionProducto
                        }
                    },
                })
                break
            case 'VersionProducto':
                this.setState({
                    Proyecto : {
                        ...this.state.Proyecto,
                        VersionProducto : {
                            PKID : value.code,
                            Version : value.name
                        }
                    },
                })
                break
                case 'ImplementacionBackLog':
                this.setState({
                    Proyecto : {
                        ...this.state.Proyecto,
                        ImplementacionBackLog : {
                            PKID : value.code,
                            Nombre : value.name,
                            Version : 1,
                            ImplementacionBackLogVersion : value.VersionProducto
                        }
                    },
                })
                break
            case 'ImplementacionBackLogVersion':
                this.setState({
                    Proyecto : {
                        ...this.state.Proyecto,
                        ImplementacionBackLogVersion : {
                            PKID : value.code,
                            Version : value.name
                        }
                    },
                })
                break

                case 'MotivoVersionamiento':
                    this.setState({
                        Proyecto : {
                            ...this.state.Proyecto,
                            MotivoVersionamiento : {
                                PKID : value.code,
                                Descripcion : value.name
                            }
                        },
                    })
                    break

                    case 'Activo':
                    this.setState({
                        Proyecto:{
                            ...this.state.Proyecto,
                            Activo: !this.state.Proyecto.Activo
                        }
                    })
                    break

            default:
                this.setState({
                    Proyecto: {
                        ...this.state.Proyecto,
                        [name]: value
                    }
                })
                break
        }
    }

    HandleResponse = (Entidad: any) => {
        if(Entidad){
            setRequerimiento(JSON.stringify(Entidad))
            window.location.href = 'ItemProyecto/ItemRequerimiento'
        }else{
            if(this.state.PasProyecto.Estado.PKID !== 3 ) this.AbrirPanelRequerimiento()
            else alert('No se puede crear nuevos requerimientos')
        }
    }

    ActualizarFechaFin  = (req: number) =>  {        
       //alert(req);11655
            this.setState({
                Request: true
            }, () => this.ActualizarFechaFinRequerimiento(req))        
    }

    ActualizarFechaFinRequerimiento(req:any){
        var req2 = this.state.Requerimiento;
        req2.PKID = req;
        RequerimientoService.ActualizarFechaFin(this.state.Requerimiento)
        .then( (response: any) => {
            if(response.success){
                this.setState({
                    Pendiente: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 1}},
                    EnCurso: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 2}},
                    StandBy: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 5}},
                    Pruebas: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 4}},
                    Hecho: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 3}},
                    Errores : {},
                    Requerimiento : this.cRequerimiento
                },()=>{
                    (document.getElementById('PanelRequerimiento') as HTMLInputElement).classList.remove('active');
                    (document.getElementById('PopupRequerimiento') as HTMLInputElement).classList.remove('active');
                    this.ListarEstado()
                    this.ListarSolicitudes()
                    this.ListarVersionProducto()
                    this.ConsultarBackLog()
                })
                alert(response.data)
            }else{
                alert(response.data)
            }
        })
        .catch( (error) => {
            alert(error)
        })
        .finally( () => {
            this.setState({Request : false})
        })
    }


    HandleSubmitRequerimiento = (Event: any) =>{
        Event.preventDefault();
        const result = RequerimientoService.ValidarSchema(this.state.Requerimiento)
        if(Object.keys(result).length){
            return this.setState({Errores: result});
        }else{
            this.setState({
                Request: true
            }, () => this.CrearActualizarRequerimiento())
        }
    };

    CrearActualizarRequerimiento(){
        RequerimientoService.CrearActualizar(this.state.Requerimiento, this.state.PasProyecto)
        .then( (response: any) => {
            console.log("CrearActualizarRequerimiento Respuesta : " +response )
            if(response.success){
                this.setState({
                    Pendiente: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 1}},
                    EnCurso: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 2}},
                    StandBy: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 5}},
                    Pruebas: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 4}},
                    Hecho: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 3}},
                    Errores : {},
                    Requerimiento : this.cRequerimiento
                },()=>{
                    (document.getElementById('PanelRequerimiento') as HTMLInputElement).classList.remove('active');
                    (document.getElementById('PopupRequerimiento') as HTMLInputElement).classList.remove('active');
                    this.ListarEstado()
                    this.ListarSolicitudes()
                    this.ListarVersionProducto()
                    this.ConsultarBackLog()
                })
            }else{
                alert(response.data)
            }
        })
        .catch( (error) => {
            alert(error)
        })
        .finally( () => {
            this.setState({Request : false})
        })
    }

    GuardarTemporal = () =>{
        const result = ProyectoService.ValidarSchema(this.state.Proyecto)
        if(Object.keys(result).length){
            return this.setState({ErroresB: result});
        }else{
            ProyectoService.GuardarTemporal(this.state.Proyecto, this.state.PasProyecto)
            .then( (response: any) => {
                alert(response.data)
                if(response.success) window.location.reload()
            })
            .catch( (error) => {
                alert(error)
            })
        }
    }

    CrearVersion = () =>{
        ProyectoService.CrearVersion(this.state.Proyecto, this.state.PasProyecto)
        .then( (response: any) => {
            if(response.success){
                alert(response.data)
                window.location.reload()
            }else{
                alert(response.data)
            }
        })
        .catch( (error) => {
            alert(error)
        })
    }

    Paginacion = (Estado: string) => {
        if(
            (
                {
                    'Pendiente': ((this.state.Pendiente.Paginacion.NumeroPagina < this.state.Pendiente.Paginacion.NumeroPaginas) && !this.state.Pendiente.Loading),
                    'EnCurso' : ((this.state.EnCurso.Paginacion.NumeroPagina < this.state.EnCurso.Paginacion.NumeroPaginas) && !this.state.EnCurso.Loading),
                    'StandBy' : ((this.state.StandBy.Paginacion.NumeroPagina < this.state.StandBy.Paginacion.NumeroPaginas) && !this.state.StandBy.Loading),
                    'Hecho' : ((this.state.Hecho.Paginacion.NumeroPagina < this.state.Hecho.Paginacion.NumeroPaginas) && !this.state.Hecho.Loading),
                    'Pruebas' : ((this.state.Pruebas.Paginacion.NumeroPagina < this.state.Pruebas.Paginacion.NumeroPaginas) && !this.state.Pruebas.Loading)
                }[Estado]
            )){
                switch(Estado){
                    case 'Pendiente':
                        this.setState({
                            Pendiente: {
                                ...this.state.Pendiente,
                                Paginacion:{
                                    ...this.state.Pendiente.Paginacion,
                                    NumeroPagina: this.state.Pendiente.Paginacion.NumeroPagina + 1},
                                Loading: true
                            }
                        },() => this.ListarRequerimiento('Pendiente'))
                        break
                        case 'EnCurso' : 
                            this.setState({
                                EnCurso: {
                                    ...this.state.EnCurso
                                    ,Paginacion:{
                                        ...this.state.EnCurso.Paginacion,
                                        NumeroPagina: this.state.EnCurso.Paginacion.NumeroPagina + 1},
                                    Loading: true
                                }
                            },() => this.ListarRequerimiento('EnCurso'))
                        break
                        case 'StandBy' : 
                            this.setState({
                                StandBy: {
                                    ...this.state.StandBy
                                    ,Paginacion:{
                                        ...this.state.StandBy.Paginacion,
                                        NumeroPagina: this.state.StandBy.Paginacion.NumeroPagina + 1},
                                    Loading: true
                                }
                            },() => this.ListarRequerimiento('StandBy'))
                        break
                        case 'Pruebas':
                            this.setState({
                                Pruebas: {
                                    ...this.state.Pruebas
                                    ,Paginacion:{
                                        ...this.state.Pruebas.Paginacion,
                                        NumeroPagina: this.state.Pruebas.Paginacion.NumeroPagina + 1},
                                    Loading: true
                                }
                            },() => this.ListarRequerimiento('Pruebas'))
                            break;
                        case 'Hecho' : 
                            this.setState({
                                Hecho: {
                                    ...this.state.Hecho,
                                    Paginacion:{
                                        ...this.state.Hecho.Paginacion,
                                        NumeroPagina: this.state.Hecho.Paginacion.NumeroPagina + 1},
                                    Loading: true
                                }
                            },() => this.ListarRequerimiento('Hecho'))
                        break
                    default :
                        break
                }
        }
    }

    SubirArchivo = (Event: any, FileUpload: any) =>  {
        const file = Event.files[0]
        const reader: any = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
            let encoded = reader.result.toString().replace(/^data:(.*,)?/, '')
            if ((encoded.length % 4) > 0) {
                encoded += '='.repeat(4 - (encoded.length % 4));
            }
            var oArchivoAdjunto = {
                PKID : 0,
                Nombre : file.name.replace(/[.][^.]+$/, ''),
                FechaCreacion : '',
                Tamagno : file.size,
                Archivo : encoded,
                Extension : file.name.slice((file.name.lastIndexOf(".") - 1 >>> 0) + 2)
            }
            this.setState({
                Requerimiento :{
                    ...this.state.Requerimiento,
                    ArchivosAdjuntos:[
                        ...this.state.Requerimiento.ArchivosAdjuntos,
                        oArchivoAdjunto
                    ]
                }
            })
        }
        reader.onerror = (Error: any) => {
            alert(Error)
        }
        FileUpload.current.files = ''
        FileUpload.current.state.files = ''
    }

    BorrarArchivo = (Event: any) => {
        let lArchivos = this.state.Requerimiento.ArchivosAdjuntos
        if(lArchivos.filter( (e) => { return e === Event}).length > 0){
            lArchivos = lArchivos.filter( (e) => { return e !== Event})
        }
        this.setState({
            Requerimiento :{
                ...this.state.Requerimiento,
                ArchivosAdjuntos : lArchivos
            }
        })
    }

    DescargarArchivo = (Event: any) => {
        let lArchivos = this.state.Requerimiento.ArchivosAdjuntos
        if(lArchivos.filter( (e) => { return e === Event}).length > 0){
            lArchivos = lArchivos.filter( (e) => { return e === Event})
            let oArchivos = lArchivos[0]
            RequerimientoService.DescargarArchivo(oArchivos)
        }else{
            alert('Error: No se encontro el archivo indicado')
        }
    }

    AbrirPanelRequerimiento = () => {
        (document.getElementById('PanelRequerimiento') as HTMLInputElement).classList.add('active');
        (document.getElementById('PopupRequerimiento') as HTMLInputElement).classList.add('active');
    }

    MoverEstado = ( EstadoI : string, EstadoF : string, PKID : string) => {
        if(EstadoI !== EstadoF){
            let Obj = {'Pendiente': this.state.Pendiente.Lista,'EnCurso' : this.state.EnCurso.Lista,
            'StandBy' : this.state.StandBy.Lista,'Hecho' : this.state.Hecho.Lista, 'Pruebas' : this.state.Pruebas.Lista}[EstadoI] || []
            switch(EstadoF){
                case 'Pendiente' :
                    this.ConsultarItemProyecto(RequerimientoService.ObtenerObjetoEstado(Obj,PKID,1),1)
                    break
                case 'EnCurso' :
                    this.ConsultarItemProyecto(RequerimientoService.ObtenerObjetoEstado(Obj,PKID,2),2)
                    break
                    case 'StandBy' :
                    this.ConsultarItemProyecto(RequerimientoService.ObtenerObjetoEstado(Obj,PKID,5),5)
                    break
                case 'Hecho' :
                    this.ConsultarItemProyecto(RequerimientoService.ObtenerObjetoEstado(Obj,PKID,3),3)
                    break
                case 'Pruebas':
                    this.ConsultarItemProyecto(RequerimientoService.ObtenerObjetoEstado(Obj,PKID,4),4)
                    break
                default:
                    break
            }
        }
    }

    ConsultarItemProyecto = (Obj: IRequerimiento,Estado: number) =>{
        RequerimientoService.Consultar(Obj)
        .then((response: any) => {
            if (response.success) {
                var oItemBackLog = JSON.parse(response.data)
                oItemBackLog = {
                    ...oItemBackLog,
                    Estado: {
                        ...oItemBackLog.Estado,
                        PKID : Estado
                    }
                }
                this.setState({
                    Requerimiento : oItemBackLog
                }, () => this.CrearActualizarRequerimiento())
            }else{
                throw new Error(response.data)
            }
        })
        .catch( (error) => {
            alert(error)
        })
    }
    
    render(){
        return(
            <div className="container">
                <RequerimientoHeader/>
                <RequerimientoProyectoDetalle
                    FormValues = {this.state.Proyecto}
                    PasFormValues = {this.state.PasProyecto}
                    ListaProducto = {this.state.ListaProducto}
                    ListaImplementacionBackLog = {this.state.ImplementacionBackLog}
                    HandleChange = {this.HandleChangeProyecto}
                    GuardarTemporal = {this.GuardarTemporal}
                    CrearVersion = {this.CrearVersion}
                    ListaMotivoVersionamiento =  {this.state.ListaMotivoVersionamiento}
                    Errores = {this.state.ErroresB}/>
                <RequerimientoBody 
                    Paginacion = {this.Paginacion}
                    Pendiente = {this.state.Pendiente}
                    EnCurso = {this.state.EnCurso}
                    StandBy = {this.state.StandBy}
                    Pruebas = {this.state.Pruebas}
                    Hecho = {this.state.Hecho}
                    ActualizarFechaFin = {this.ActualizarFechaFin}
                    OnResponse = {this.HandleResponse}
                    MoverEstado = {this.MoverEstado}/>
                <RequerimientoModal
                    Mode = {false}
                    OnSubmit = {this.HandleSubmitRequerimiento}
                    Solicitudes={this.state.ListaSolicitudes}
                    ItemBackLogs={this.state.ListaItemBackLogs}
                    HandleChange = {this.HandleChangeRequerimiento}
                    FormValues = {this.state.Requerimiento}
                    SubirArchivo = {this.SubirArchivo}
                    BorrarArchivo = {this.BorrarArchivo}
                    DescargarArchivo = {this.DescargarArchivo}
                    Errores = {this.state.Errores}
                    Request = {this.state.Request}/>
            </div>
        )
    }
}