import React from 'react'
import BackLogBody from '../../Infrastructure/Components/BackLogBody'
import BackLogModal from '../../Infrastructure/Components/BackLogModal'
import BackLogHeader from '../../Infrastructure/Components/BackLogHeader'
import { AuthHelper } from '../../Domain/Utils/AuthHelper'
import { EmpleadoService } from '../../Domain/Services/EmpleadoService'
import { BackLogService } from '../../Domain/Services/BackLogService'
import { VersionProductoService } from '../../Domain/Services/VersionProductoService'
import { EstadoService } from '../../Domain/Services/EstadoService'
import { TicketService } from '../../Domain/Services/TicketService'
import { ClienteService } from '../../Domain/Services/ClienteService'
import BackLogFiltro from '../../Infrastructure/Components/BackLogFiltro'

import { IImplementacionBackLog } from '../../Domain/Models/IImplementacionBackLog'
import { IPaginacion } from '../../Domain/Models/IPaginacion'
import { IToken } from '../../Domain/Models/IToken'
import { IStakeholder } from '../../Domain/Models/IStakeholder'
import BackLogModalStakeholder from '../../Infrastructure/Components/BackLogModalStakeholder'
import { MotivoVersionamientoService } from '../../Domain/Services/MotivoVersionamientoService'
import { IFiltro } from '../../Domain/Models/IFiltro'

interface IProps {
    Token: IToken
}

interface IState {
    Modal: boolean
    Pendiente: IPaginacion
    EnCurso: IPaginacion
    StandBy: IPaginacion
    Hecho: IPaginacion
    ListaPendienteOriginal: IPaginacion
    ListaEnCursoOriginal: IPaginacion
    ListaStandByOriginal: IPaginacion
    ListaHechoOriginal: IPaginacion
    ImplementacionBackLog: IImplementacionBackLog
    Empleado: Array<object>
    Estado: Array<object>
    Errores: object
    Request: boolean
    Filtro: IFiltro
    ListaProducto: Array<object>
    Cliente: Array<object>
    Stakeholder: IStakeholder
    ErroresStakeholder: any
    ModalStakeholder: boolean
    MotivoVersionamiento: Array<object>
}

export default class BackLog extends React.Component<IProps, IState> {

    cImplementacionBackLog = {
        PKID: 0,
        Nombre: '',
        Descripcion: '',
        LinkDocumentacionDesarrollo: '',
        Version: 0,
        FechaCreacion: new Date(),
        Empleado: {
            PKID: parseInt(AuthHelper.GetDecoded().sub, 10),
            Codigo: '',
            Nombre: '',
            Area: {
                PKID: 0,
                Codigo: '',
                Nombre: ''
            }
        },
        Producto: {
            PKID: 0,
            Nombre: '',
            VersionProducto: []
        },
        VersionProducto: {
            PKID: 0,
            Version: ''
        },
        Usuario: {
            PKID: parseInt(AuthHelper.GetDecoded().sub, 10),
            NombreEmpleado: AuthHelper.GetDecoded().Empleado
        },
        Cliente: {
            PKID: 0,
            Nombre: '',
        },
        Objetivo: '',
        Estado: {
            PKID: 1,
            Descripcion: 'Pendiente'
        },
        Stakeholders: [],
        FechaEntrega: new Date(),
        Prerequisitos: '',
        ImplementacionBackLogVersion: [],
        ArchivosAdjuntos: [],
        MotivoVersionamiento: {
            PKID: 0,
            Descripcion: ''
        },
        DetalleVersionamiento: '',
        Activo: false,
        PorcentajeAvance: 0
    }

    cFiltro = {
        PKID: 0,
        IDUsuario: parseInt(AuthHelper.GetDecoded().sub, 10),
        IDTipo: 4,
        FechaInicio: new Date("01/01/2000"),
        FechaFin: new Date("01/01/2050"),
        IDSClientes: '',
        IDSAreas: '',
        IDSProductos: '',
        IDSResponsables: '',
        IDSEstados: '',
        IDSTickets: '',
        IDSProyectos: '',
        FechaEntregaInicio: new Date("01/01/2000"),
        FechaEntregaFin: new Date("01/01/2050")
    }

    cStakeholder = {
        PKID: 0,
        Numero: 1,
        Descripcion: ""
    }

    cPaginacion = {
        Lista: [],
        Paginacion: {
            NumeroPagina: 1,
            RegistrosPagina: 50000,
            NumeroPaginas: 0,
            IDEstado: 1,
            IDUsuario: parseInt(AuthHelper.GetDecoded().sub, 10),
        },
        Loading: true
    }

    constructor(props: any) {
        super(props)
        this.state = {
            Modal: false,
            ModalStakeholder: false,
            Pendiente: { ...this.cPaginacion, Paginacion: { ...this.cPaginacion.Paginacion, IDEstado: 1 } },
            EnCurso: { ...this.cPaginacion, Paginacion: { ...this.cPaginacion.Paginacion, IDEstado: 2 } },
            StandBy: { ...this.cPaginacion, Paginacion: { ...this.cPaginacion.Paginacion, IDEstado: 5 } },
            Hecho: { ...this.cPaginacion, Paginacion: { ...this.cPaginacion.Paginacion, IDEstado: 3 } },
            ListaPendienteOriginal: { ...this.cPaginacion, Paginacion: { ...this.cPaginacion.Paginacion, IDEstado: 1 } },
            ListaEnCursoOriginal: { ...this.cPaginacion, Paginacion: { ...this.cPaginacion.Paginacion, IDEstado: 2 } },
            ListaStandByOriginal: { ...this.cPaginacion, Paginacion: { ...this.cPaginacion.Paginacion, IDEstado: 5 } },
            ListaHechoOriginal: { ...this.cPaginacion, Paginacion: { ...this.cPaginacion.Paginacion, IDEstado: 3 } },
            ImplementacionBackLog: this.cImplementacionBackLog,
            Empleado: [],
            Estado: [],
            Filtro: this.cFiltro,
            Errores: {},
            ListaProducto: [],
            Cliente: [],
            Stakeholder: this.cStakeholder,
            ErroresStakeholder: {},
            Request: false,
            MotivoVersionamiento: []
        }
    }

    componentDidMount() {
        this.ListarEstado()
        this.ListarVersionProducto()
        this.ListarCliente()
        this.ListarMotivoVersionamiento()
        this.obtenerAreaEmpleado()
        this.ObtenerFiltro()
    }

    obtenerAreaEmpleado() {
        //implantacion solo quiere ver sus empleados de su area al crear backlog
        var area = AuthHelper.GetDecoded().Area
        if (area === "Implementacion") {
            this.ListarEmpleadoImplantacion() //trae solo de implantacion
        } else {
            this.ListarEmpleadoDesarrollo()////trae todos los empleados desarrollo e implantacion
        }
        //por eso hay una diferencia entre el dasboard backlog de implantacion con el de desarrollo
        //en el de implantacion solo se pintan los empleados de su area en desarollo se pintan todos los empleados
    }

    ObtenerFiltro() {
        BackLogService.ObtenerFiltro(parseInt(AuthHelper.GetDecoded().sub, 10))
            .then((response: any) => {

                if (response.success) {
                    let IDResponsables = JSON.parse(response.data).IDSResponsables
                    this.setState({ Filtro: JSON.parse(response.data) })
                    this.setState({ Filtro: { ...this.state.Filtro, IDSResponsables: IDResponsables } })
                } else {
                    let IDResponsables = JSON.parse(response.data).IDSResponsables
                    if (IDResponsables === null) {
                        IDResponsables = "-" + AuthHelper.GetDecoded().IDEmpleado
                        this.OnChangeFiltro(IDResponsables, "IDSResponsables")
                    }
                }
            })
            .catch((error) => {
                alert(error)
            })
            .finally(() => {
                this.ListarProyecto('Pendiente')
                this.ListarProyecto('EnCurso')
                this.ListarProyecto('StandBy')
                this.ListarProyecto('Hecho')
            })
    }

    CrearActualizarFiltro() {
        BackLogService.CrearActualizarFiltro(this.state.Filtro)
            .then((response: any) => {
                if (response.success) {
                    this.setState({
                        Filtro: {
                            ...this.state.Filtro,
                            PKID: JSON.parse(response.data).PKID
                        },
                        //Pendiente: {...this.cPaginacion ,Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 1}},
                        //EnCurso: {...this.cPaginacion ,Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 2}},
                        //StandBy: {...this.cPaginacion ,Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 5}},
                        //Hecho: {...this.cPaginacion ,Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 3}},
                    }, () => {
                        //this.ListarProyecto('Pendiente')
                        //this.ListarProyecto('EnCurso')
                        //this.ListarProyecto('StandBy')
                        //this.ListarProyecto('Hecho')
                    })
                } else {
                    alert(response.data)
                }
            }).catch((error) => {
                alert(error)
            })
    }

    LimpiarFiltro = (Event: any) => {
        BackLogService.LimpiarFiltro(parseInt(AuthHelper.GetDecoded().sub, 10))
            .then((response: any) => {
                if (!response.success) alert(response.data)
                this.setState({
                    //Pendiente: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 1}},
                    //EnCurso: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 2}},
                    //StandBy: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 5}},
                    //Hecho: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 3}},
                    Modal: false,
                    ImplementacionBackLog: this.cImplementacionBackLog,
                    Filtro: { ...this.state.Filtro, IDSResponsables: '', IDSEstados: '', FechaInicio: new Date("01/01/2000"), FechaFin: new Date("01/01/2050") },
                    Errores: {}
                }, () => {
                    //this.ObtenerFiltro()
                })
            })
            .catch((error) => {
                alert(error)
            })
    }

    ClickAplicar = (Event: any) => {
        this.setState({
            Pendiente: { ...this.cPaginacion, Paginacion: { ...this.cPaginacion.Paginacion, IDEstado: 1 } },
            EnCurso: { ...this.cPaginacion, Paginacion: { ...this.cPaginacion.Paginacion, IDEstado: 2 } },
            StandBy: { ...this.cPaginacion, Paginacion: { ...this.cPaginacion.Paginacion, IDEstado: 5 } },
            Hecho: { ...this.cPaginacion, Paginacion: { ...this.cPaginacion.Paginacion, IDEstado: 3 } },
            Modal: false,
            ImplementacionBackLog: this.cImplementacionBackLog,
            Errores: {}
        }, () => {
            this.ObtenerFiltro()
        })
    }

    OnChangeFiltro = (IDSResponsables: string, filtro: string) => {
        this.setState({
            Filtro: {
                ...this.state.Filtro,
                [filtro]: IDSResponsables
            }
        }, () => this.CrearActualizarFiltro())
    }

    ListarMotivoVersionamiento() {
        MotivoVersionamientoService.Listar()
            .then((response: any) => {
                if (response.success) {
                    var aMotivoVersionamiento: Array<object> = []
                    Object.keys(JSON.parse(response.data)).forEach(function (key) {
                        var iMotivoVersionamiento = {
                            code: JSON.parse(response.data)[key].PKID,
                            name: JSON.parse(response.data)[key].Descripcion,
                        }
                        aMotivoVersionamiento.push(iMotivoVersionamiento)
                    })
                    this.setState({ MotivoVersionamiento: aMotivoVersionamiento })
                } else {
                    alert(response.data)
                }
            })
            .catch((error) => {
                alert(error)
            })
    }

    ListarCliente() {
        ClienteService.Listar()
            .then((response: any) => {
                if (response.success) {
                    var aCliente: Array<object> = []
                    Object.keys(JSON.parse(response.data)).forEach(function (key) {
                        var iCliente = {
                            code: JSON.parse(response.data)[key].PKID,
                            name: JSON.parse(response.data)[key].Nombre,
                        }
                        aCliente.push(iCliente)
                    })
                    this.setState({ Cliente: aCliente })
                } else {
                    alert(response.data)
                }
            }).catch((error) => {
                alert(error)
            })
    }

    ListarVersionProducto() {
        VersionProductoService.Listar()
            .then((response: any) => {
                if (response.success) {
                    var aProducto: Array<object> = []
                    let lProducto: Array<{ PKID: number, Nombre: string }> = JSON.parse(response.data)
                    Object.keys(lProducto).forEach(function (k1: any) {
                        let oProducto: any = lProducto[k1]
                        let aVersionProducto: Array<object> = []
                        if (oProducto.VersionProducto) {
                            Object.keys(oProducto.VersionProducto).forEach(function (k2) {
                                aVersionProducto.push({
                                    code: oProducto.VersionProducto[k2].PKID,
                                    name: oProducto.VersionProducto[k2].Version,
                                })
                            })
                        }
                        aProducto.push({
                            code: lProducto[k1].PKID,
                            name: lProducto[k1].Nombre,
                            VersionProducto: aVersionProducto
                        })
                    })
                    this.setState({ ListaProducto: aProducto })
                } else {
                    alert(response.data)
                }
            })
            .catch((error) => {
                alert(error)
            })
    }

    ListarEmpleadoDesarrollo() {
        EmpleadoService.ListarEmpleadoDesarrollo()
            .then((response: any) => {
                if (response.success) {
                    var aEmpleado: Array<object> = []
                    Object.keys(JSON.parse(response.data)).forEach(function (key) {
                        var iEmpleado = {
                            code: JSON.parse(response.data)[key].PKID,
                            name: JSON.parse(response.data)[key].Nombre,
                        }
                        aEmpleado.push(iEmpleado)
                    })
                    this.setState({ Empleado: aEmpleado })
                } else {
                    alert(response.data)
                }
            })
            .catch((error) => {
                alert(error)
            })
    }

    ListarEmpleadoImplantacion() {
        EmpleadoService.ListarEmpleadoImplantacion()
            .then((response: any) => {
                if (response.success) {
                    var aEmpleado: Array<object> = []
                    Object.keys(JSON.parse(response.data)).forEach(function (key) {
                        var iEmpleado = {
                            code: JSON.parse(response.data)[key].PKID,
                            name: JSON.parse(response.data)[key].Nombre,
                        }
                        aEmpleado.push(iEmpleado)
                    })
                    this.setState({ Empleado: aEmpleado })
                } else {
                    alert(response.data)
                }
            })
            .catch((error) => {
                alert(error)
            })
    }

    ListarEstado() {
        EstadoService.Listar()
            .then((response: any) => {
                if (response.success) {
                    var aEstado: Array<object> = []
                    Object.keys(JSON.parse(response.data)).forEach(function (key) {
                        var iEstado = {
                            code: JSON.parse(response.data)[key].PKID,
                            name: JSON.parse(response.data)[key].Descripcion,
                        }
                        aEstado.push(iEstado);
                    });
                    this.setState({ Estado: aEstado })
                } else {
                    throw new Error(response.data);
                }
            })
            .catch((error) => {
                alert(error)
            })
    }

    ListarProyecto = (Estado: string) => {
        BackLogService.ListarPaginacion(
            {
                'Pendiente': this.state.Pendiente.Paginacion,
                'EnCurso': this.state.EnCurso.Paginacion,
                'StandBy': this.state.StandBy.Paginacion,
                'Hecho': this.state.Hecho.Paginacion
            }[Estado]
        )
            .then((response: any) => {
                if (response.success) {
                    let jParse = JSON.parse(response.data)
                    let rBackLog: Array<IImplementacionBackLog | any> = jParse.BackLog ? jParse.BackLog : []
                    let rPaginacion: any = jParse.Paginacion
                    switch (Estado) {
                        case 'Pendiente':
                            this.setState({ Pendiente: { Lista: this.state.Pendiente.Lista.concat(rBackLog), Paginacion: rPaginacion, Loading: false } }, () => this.CalcularHoras(Estado))
                            this.setState({ ListaPendienteOriginal: { Lista: this.state.ListaPendienteOriginal.Lista.concat(rBackLog), Paginacion: rPaginacion, Loading: false } }, () => this.CalcularHoras(Estado))
                            break
                        case 'EnCurso':
                            this.setState({ EnCurso: { Lista: this.state.EnCurso.Lista.concat(rBackLog), Paginacion: rPaginacion, Loading: false } }, () => this.CalcularHoras(Estado))
                            this.setState({ ListaEnCursoOriginal: { Lista: this.state.ListaEnCursoOriginal.Lista.concat(rBackLog), Paginacion: rPaginacion, Loading: false } }, () => this.CalcularHoras(Estado))
                            break
                        case 'StandBy':
                            this.setState({ StandBy: { Lista: this.state.StandBy.Lista.concat(rBackLog), Paginacion: rPaginacion, Loading: false } }, () => this.CalcularHoras(Estado))
                            this.setState({ ListaStandByOriginal: { Lista: this.state.ListaStandByOriginal.Lista.concat(rBackLog), Paginacion: rPaginacion, Loading: false } }, () => this.CalcularHoras(Estado))
                            break
                        case 'Hecho':
                            this.setState({ Hecho: { Lista: this.state.Hecho.Lista.concat(rBackLog), Paginacion: rPaginacion, Loading: false } }, () => this.CalcularHoras(Estado))
                            this.setState({ ListaHechoOriginal: { Lista: this.state.ListaHechoOriginal.Lista.concat(rBackLog), Paginacion: rPaginacion, Loading: false } }, () => this.CalcularHoras(Estado))
                            break
                        default:
                            break
                    }
                } else {
                    alert(response.data)
                }
            })
    }

    CalcularHoras(Estado: string) {
        let HEj = 0, HEs = 0
        if (Estado === 'Pendiente') {
            const cPendiente = this.state.Pendiente.Lista
            Object.keys(cPendiente).forEach(function (Index: any) {
                HEj = HEj + cPendiente[Index].HorasEjecutadas
                HEs = HEs + cPendiente[Index].HorasEstimadas
            })
        } else if (Estado === 'EnCurso') {
            const cEnCurso = this.state.EnCurso.Lista
            Object.keys(cEnCurso).forEach(function (Index: any) {
                HEj = HEj + cEnCurso[Index].HorasEjecutadas
                HEs = HEs + cEnCurso[Index].HorasEstimadas
            });
        } else if (Estado === 'StandBy') {
            const cStandBy = this.state.StandBy.Lista
            Object.keys(cStandBy).forEach(function (Index: any) {
                HEj = HEj + cStandBy[Index].HorasEjecutadas
                HEs = HEs + cStandBy[Index].HorasEstimadas
            });
        } else {
            const cHecho = this.state.Hecho.Lista
            Object.keys(cHecho).forEach(function (Index: any) {
                HEj = HEj + cHecho[Index].HorasEjecutadas
                HEs = HEs + cHecho[Index].HorasEstimadas
            });
        }

    }

    HandleChange = (Event: React.ChangeEvent<HTMLInputElement>) => {
        try {
            const { name, value }: any = Event.target
            switch (name) {
                case 'Empleado':
                    this.setState({
                        ImplementacionBackLog: {
                            ...this.state.ImplementacionBackLog,
                            [name]: {
                                PKID: value.code,
                                Nombre: value.name
                            }
                        }
                    })
                    break
                case 'MotivoVersionamiento':
                    this.setState({
                        ImplementacionBackLog: {
                            ...this.state.ImplementacionBackLog,
                            MotivoVersionamiento: {
                                PKID: value.code,
                                Descripcion: value.name
                            }
                        },
                    })
                    break
                case 'Producto':
                    this.setState({
                        ImplementacionBackLog: {
                            ...this.state.ImplementacionBackLog,
                            Producto: {
                                PKID: value.code,
                                Nombre: value.name,
                                VersionProducto: value.VersionProducto
                            }
                        },
                    })
                    break
                case 'VersionProducto':
                    this.setState({
                        ImplementacionBackLog: {
                            ...this.state.ImplementacionBackLog,
                            VersionProducto: {
                                PKID: value.code,
                                Version: value.name
                            }
                        },
                    })
                    break
                case 'Cliente':
                    this.setState({
                        ImplementacionBackLog: {
                            ...this.state.ImplementacionBackLog,
                            Cliente: {
                                PKID: value.code,
                                Nombre: value.name
                            }
                        },
                    })
                    break

                case 'Activo':
                    this.setState({
                        ImplementacionBackLog: {
                            ...this.state.ImplementacionBackLog,
                            Activo: !this.state.ImplementacionBackLog.Activo
                        }
                    })
                    break

                case 'FechaCreacionInicio':
                    this.state.Filtro.FechaInicio = value
                    this.CrearActualizarFiltro()
                    break

                case 'FechaCreacionFin':
                    this.state.Filtro.FechaFin = value
                    this.CrearActualizarFiltro()
                    break

                case 'FechaEntregaInicio':
                    this.state.Filtro.FechaEntregaInicio = value
                    this.CrearActualizarFiltro()
                    break

                case 'FechaEntregaFin':
                    this.state.Filtro.FechaEntregaFin = value
                    this.CrearActualizarFiltro()
                    break

                default:
                    this.setState({
                        ImplementacionBackLog: {
                            ...this.state.ImplementacionBackLog,
                            [name]: value
                        }
                    })
                    break
            }
        } catch (error) {
            alert(error + "\n use block de notas para formatear su texto y quite saltos de linea")
        }
    }

    HandleResponse = (ImplementacionBackLog: IImplementacionBackLog, Mode: boolean) => {
        this.setState({
            ImplementacionBackLog: ImplementacionBackLog ? ImplementacionBackLog : this.cImplementacionBackLog,
            Modal: Mode,
            Errores: {}
        })
    }

    HandleSubmit = (Event: any) => {
        Event.preventDefault()
        const result = BackLogService.ValidarSchema(this.state.ImplementacionBackLog)
        if (Object.keys(result).length) {
            return this.setState({ Errores: result })
        } else {
            this.setState({
                Request: true
            }, () => this.CrearActualizarBackLog())
        }
    };

    CrearActualizarBackLog() {
        BackLogService.CrearActualizar(this.state.ImplementacionBackLog)
            .then((response: any) => {
                if (response.success) {
                    let jParse = { PKID: response.success, Descripcion: response.data }
                    if (jParse.PKID) {
                        this.setState({
                            Pendiente: { ...this.cPaginacion, Paginacion: { ...this.cPaginacion.Paginacion, IDEstado: 1 } },
                            EnCurso: { ...this.cPaginacion, Paginacion: { ...this.cPaginacion.Paginacion, IDEstado: 2 } },
                            StandBy: { ...this.cPaginacion, Paginacion: { ...this.cPaginacion.Paginacion, IDEstado: 5 } },
                            Hecho: { ...this.cPaginacion, Paginacion: { ...this.cPaginacion.Paginacion, IDEstado: 3 } },
                            Modal: false,
                            ImplementacionBackLog: this.cImplementacionBackLog,
                            Errores: {}
                        }, () => {
                            this.ListarProyecto('Pendiente')
                            this.ListarProyecto('EnCurso')
                            this.ListarProyecto('StandBy')
                            this.ListarProyecto('Hecho')
                        })
                    } else {
                        alert(jParse.Descripcion)
                    }
                } else {
                    alert(response.data)
                }
            })
            .catch((error) => {
                alert(error)
            })
            .finally(() => {
                this.setState({
                    Request: false
                })
            })
    }

    Paginacion = (Estado: string) => {
        if (
            (
                {
                    'Pendiente': ((this.state.Pendiente.Paginacion.NumeroPagina < this.state.Pendiente.Paginacion.NumeroPaginas) && !this.state.Pendiente.Loading),
                    'EnCurso': ((this.state.EnCurso.Paginacion.NumeroPagina < this.state.EnCurso.Paginacion.NumeroPaginas) && !this.state.EnCurso.Loading),
                    'StandBy': ((this.state.StandBy.Paginacion.NumeroPagina < this.state.StandBy.Paginacion.NumeroPaginas) && !this.state.StandBy.Loading),
                    'Hecho': ((this.state.Hecho.Paginacion.NumeroPagina < this.state.Hecho.Paginacion.NumeroPaginas) && !this.state.Hecho.Loading)
                }[Estado]
            )) {
            switch (Estado) {
                case 'Pendiente':
                    this.setState({
                        Pendiente: {
                            ...this.state.Pendiente,
                            Paginacion: {
                                ...this.state.Pendiente.Paginacion,
                                NumeroPagina: this.state.Pendiente.Paginacion.NumeroPagina + 1
                            },
                            Loading: true
                        }
                    }, () => this.ListarProyecto('Pendiente'))
                    break
                case 'EnCurso':
                    this.setState({
                        EnCurso: {
                            ...this.state.EnCurso
                            , Paginacion: {
                                ...this.state.EnCurso.Paginacion,
                                NumeroPagina: this.state.EnCurso.Paginacion.NumeroPagina + 1
                            },
                            Loading: true
                        }
                    }, () => this.ListarProyecto('EnCurso'))
                    break
                case 'StandBy':
                    this.setState({
                        StandBy: {
                            ...this.state.StandBy
                            , Paginacion: {
                                ...this.state.StandBy.Paginacion,
                                NumeroPagina: this.state.StandBy.Paginacion.NumeroPagina + 1
                            },
                            Loading: true
                        }
                    }, () => this.ListarProyecto('StandBy'))
                    break
                case 'Hecho':
                    this.setState({
                        Hecho: {
                            ...this.state.Hecho,
                            Paginacion: {
                                ...this.state.Hecho.Paginacion,
                                NumeroPagina: this.state.Hecho.Paginacion.NumeroPagina + 1
                            },
                            Loading: true
                        }
                    }, () => this.ListarProyecto('Hecho'))
                    break
                default:
                    break
            }
        }
    }

    MoverEstado = (EstadoI: string, EstadoF: string, PKID: string) => {
        if (EstadoI !== EstadoF) {
            let Obj = { 'Pendiente': this.state.Pendiente.Lista, 'EnCurso': this.state.EnCurso.Lista, 'StandBy': this.state.StandBy.Lista, 'Hecho': this.state.Hecho.Lista }[EstadoI] || []
            switch (EstadoF) {
                case 'Pendiente':
                    this.setState({
                        ImplementacionBackLog: BackLogService.ObtenerObjetoEstado(Obj, PKID, 1)
                    }, () => this.CrearActualizarBackLog())
                    break
                case 'EnCurso':
                    this.setState({
                        ImplementacionBackLog: BackLogService.ObtenerObjetoEstado(Obj, PKID, 2)
                    }, () => this.CrearActualizarBackLog())
                    break
                case 'StandBy':
                    this.setState({
                        ImplementacionBackLog: BackLogService.ObtenerObjetoEstado(Obj, PKID, 5)
                    }, () => this.CrearActualizarBackLog())
                    break
                case 'Hecho':
                    this.setState({
                        ImplementacionBackLog: BackLogService.ObtenerObjetoEstado(Obj, PKID, 3)
                    }, () => this.CrearActualizarBackLog())
                    break
                default:
                    break
            }
        }
    }

    HandleSubmitStakeholder = (Event: any) => {
        Event.preventDefault()
        const result = BackLogService.ValidarStakeholder(this.state.Stakeholder)
        if (Object.keys(result).length) return this.setState({ ErroresStakeholder: result });
        else this.CrearActualizarStakeholder()
    }

    CrearActualizarStakeholder() {
        let listaStakeholders = this.state.ImplementacionBackLog.Stakeholders
        if (listaStakeholders.filter((e: any) => { return e.Numero === this.state.Stakeholder.Numero }).length > 0) {
            listaStakeholders = listaStakeholders.filter((e: any) => { return e.Numero !== this.state.Stakeholder.Numero })
        }
        listaStakeholders.push(this.state.Stakeholder)
        listaStakeholders.sort(function (a: any, b: any) {
            return a.Numero - b.Numero
        })
        this.setState({
            Stakeholder: this.cStakeholder,
            ImplementacionBackLog: {
                ...this.state.ImplementacionBackLog,
                Stakeholders: listaStakeholders
            }
        }, () => this.HideVisibleModalStakeholder(false))
    }

    HideVisibleModalStakeholder = (Mode: boolean) => {
        this.setState({
            ModalStakeholder: Mode
        })
    }

    HandleChangeStakeholder = (Event: any) => {
        this.setState({
            Stakeholder: {
                ...this.state.Stakeholder,
                [Event.target.name]: Event.target.value
            }
        })
    }

    CrearNuevoStakeholder = () => {
        this.setState({
            Stakeholder: {
                ...this.cStakeholder,
                Numero: this.state.ImplementacionBackLog.Stakeholders.length + 1,
            },
            ErroresStakeholder: {}
        }, () => this.HideVisibleModalStakeholder(true))
    }

    ActualizarStakeholder = (Event: any) => {
        this.setState({
            Stakeholder: Event,
            ErroresStakeholder: {}
        }, () => this.HideVisibleModalStakeholder(true))
    }

    EliminarStakeholder = (RowData: any) => {
        let lStakeholders = this.state.ImplementacionBackLog.Stakeholders
        if (lStakeholders.filter((Event: any) => { return Event.Numero === RowData.Numero }).length > 0) {
            lStakeholders = lStakeholders.filter((Event: any) => { return Event.Numero !== RowData.Numero })
        }
        var lOrdenado: Array<object> = []
        if (lStakeholders.length > 0) {
            lStakeholders.forEach((Element, Index) => {
                Element = {
                    ...Element,
                    Numero: Index + 1
                }
                lOrdenado.push(Element)
            });
        }
        this.setState({
            ImplementacionBackLog: {
                ...this.state.ImplementacionBackLog,
                Stakeholders: lOrdenado
            },
        })
    }

    OnRowReorder = (Event: any) => {
        let lista = Event.value, nuevaLista = []
        if (lista !== null && lista !== undefined) {
            for (let i = 0; i < lista.length; i++) {
                let obj = lista[i]
                obj = {
                    ...obj,
                    Numero: i + 1
                }
                nuevaLista.push(obj)
            }
        }
        this.setState({
            ImplementacionBackLog: {
                ...this.state.ImplementacionBackLog,
                Stakeholders: nuevaLista
            }
        })
    }

    SubirArchivo = (Event: any, FileUpload: any) => {
        const file = Event.files[0]
        const reader: any = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
            let encoded = reader.result.toString().replace(/^data:(.*,)?/, '')
            if ((encoded.length % 4) > 0) {
                encoded += '='.repeat(4 - (encoded.length % 4));
            }
            var oArchivoAdjunto = {
                PKID: 0,
                Nombre: file.name.replace(/[.][^.]+$/, ''),
                FechaCreacion: '',
                Tamagno: file.size,//parseFloat(file.size / Math.pow(1024,2)).toFixed(2), //De Bytes a MegaBytes
                Archivo: encoded,
                Extension: file.name.slice((file.name.lastIndexOf(".") - 1 >>> 0) + 2)
            }
            this.setState({
                ImplementacionBackLog: {
                    ...this.state.ImplementacionBackLog,
                    ArchivosAdjuntos: [
                        ...this.state.ImplementacionBackLog.ArchivosAdjuntos,
                        oArchivoAdjunto
                    ]
                }
            })
        }
        reader.onerror = (Error: any) => {
            alert(Error)
        }
        FileUpload.current.files = ''
        FileUpload.current.state.files = ''
    }

    BorrarArchivo = (Event: any) => {
        let lArchivos = this.state.ImplementacionBackLog.ArchivosAdjuntos
        if (lArchivos.filter((e) => { return e === Event }).length > 0) {
            lArchivos = lArchivos.filter((e) => { return e !== Event })
        }
        this.setState({
            ImplementacionBackLog: {
                ...this.state.ImplementacionBackLog,
                ArchivosAdjuntos: lArchivos
            }
        })
    }

    DescargarArchivo = (Event: any) => {
        let lArchivos = this.state.ImplementacionBackLog.ArchivosAdjuntos
        if (lArchivos.filter((e) => { return e === Event }).length > 0) {
            lArchivos = lArchivos.filter((e) => { return e === Event })
            const oArchivos: any = lArchivos[0]
            TicketService.DescargarArchivo(oArchivos)
        } else {
            alert('Error: No se encontro el archivo indicado')
        }
    }

    HandleChangeBuscador = (e: any) => {
        const ListaPendienteOriginal = this.state.ListaPendienteOriginal.Lista
        const ListaEnCursoOriginal = this.state.ListaEnCursoOriginal.Lista
        const ListaHechoOriginal = this.state.ListaHechoOriginal.Lista
        const ListaStandByOriginal = this.state.ListaStandByOriginal.Lista

        const ListaPendienteFiltoBefore = this.state.ListaPendienteOriginal.Lista
        const ListaEnCursoFiltroBefore = this.state.ListaEnCursoOriginal.Lista
        const ListaHechoFiltroBefore = this.state.ListaHechoOriginal.Lista
        const ListaStandByFiltroBefore = this.state.ListaStandByOriginal.Lista

        if (e.target.value.length===0) {

            this.setState({
                Pendiente: {
                    ...this.state.Pendiente,
                    Lista: ListaPendienteOriginal
                }
            })
            this.setState({
                EnCurso: {
                    ...this.state.EnCurso,
                    Lista: ListaEnCursoOriginal
                }
            })
            this.setState({
                Hecho: {
                    ...this.state.Hecho,
                    Lista: ListaHechoOriginal
                }
            })
            this.setState({
                StandBy: {
                    ...this.state.StandBy,
                    Lista: ListaStandByOriginal
                }
            })

        } else {
            const listaFiltradaPendiente = ListaPendienteFiltoBefore.filter((list) => list.Nombre.toLowerCase().includes(e.target.value.toLowerCase()))
            const listaFiltradaEnCurso = ListaEnCursoFiltroBefore.filter((list) => list.Nombre.toLowerCase().includes(e.target.value.toLowerCase()))
            const listaFiltradaHecho = ListaHechoFiltroBefore.filter((list) => list.Nombre.toLowerCase().includes(e.target.value.toLowerCase()))
            const listaFiltradaStandBy = ListaStandByFiltroBefore.filter((list) => list.Nombre.toLowerCase().includes(e.target.value.toLowerCase()))

            this.setState({
                Pendiente: {
                    ...this.state.Pendiente,
                    Lista: listaFiltradaPendiente
                }
            })
            this.setState({
                EnCurso: {
                    ...this.state.EnCurso,
                    Lista: listaFiltradaEnCurso
                }
            })
            this.setState({
                Hecho: {
                    ...this.state.Hecho,
                    Lista: listaFiltradaHecho
                }
            })
            this.setState({
                StandBy: {
                    ...this.state.StandBy,
                    Lista: listaFiltradaStandBy
                }
            })
        }
    }

    render() {
        return (
            <div className="container">
                <BackLogHeader
                    OnChangeBuscador={this.HandleChangeBuscador} />
                <BackLogBody
                    Paginacion={this.Paginacion}
                    Pendiente={this.state.Pendiente}
                    EnCurso={this.state.EnCurso}
                    StandBy={this.state.StandBy}
                    Hecho={this.state.Hecho}
                    OnResponse={this.HandleResponse}
                    MoverEstado={this.MoverEstado} />
                <form autoComplete="off" onSubmit={this.HandleSubmit}>
                    <BackLogModal
                        Modal={this.state.Modal}
                        HideModal={this.HandleResponse}
                        HandleChange={this.HandleChange}
                        BackLog={this.state.ImplementacionBackLog}
                        ListaEmpleado={this.state.Empleado}
                        ListaEstado={this.state.Estado}
                        ListaProducto={this.state.ListaProducto}
                        ListaCliente={this.state.Cliente}
                        ListaMotivoVersionamiento={this.state.MotivoVersionamiento}
                        Errores={this.state.Errores}
                        OnRowReorder={this.OnRowReorder}
                        CrearNuevoStakeholder={this.CrearNuevoStakeholder}
                        ActualizarStakeholder={this.ActualizarStakeholder}
                        EliminarStakeholder={this.EliminarStakeholder}
                        SubirArchivo={this.SubirArchivo}
                        BorrarArchivo={this.BorrarArchivo}
                        DescargarArchivo={this.DescargarArchivo}
                        Request={this.state.Request} />
                </form>
                <form autoComplete="Off" onSubmit={this.HandleSubmitStakeholder}>
                    <BackLogModalStakeholder
                        Modal={this.state.ModalStakeholder}
                        HideModal={() => this.HideVisibleModalStakeholder(false)}
                        FormValues={this.state.Stakeholder}
                        HandleChange={this.HandleChangeStakeholder}
                        Errores={this.state.ErroresStakeholder} />
                </form>
                <BackLogFiltro
                    HandleChange={this.HandleChange}
                    Responsables={this.state.Empleado}
                    ClickResetear={this.LimpiarFiltro}
                    ClickAplicar={this.ClickAplicar}
                    onChangeFiltro={this.OnChangeFiltro}
                    Filtro={this.state.Filtro} />
            </div>
        )
    }
}