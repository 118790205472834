import React,{Component} from 'react'
import { Modal } from 'react-responsive-modal'
import { InputText } from 'primereact/inputtext'
import { Calendar } from 'primereact/calendar'
import { Button } from 'primereact/button'
import { Checkbox } from 'primereact/checkbox'

export default class ModalSprint extends Component{
    render(){
        const es = {
            firstDayOfWeek: 1,
            dayNames: ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"],
            dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
            dayNamesMin: ["D", "L", "M", "M", "J", "V", "S"],
            monthNames: ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"],
            monthNamesShort: ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"],
            today: "Hoy",
            clear: "Claro"
        }
        return(
            <Modal
                    open={this.props.mostrarModal}
                    onClose={this.props.onClick}
                    center>
                    <form autoComplete="off" action="submit" onSubmit={this.props.onSubmit}>
                        <div className="headerModal">
                            {this.props.mode? 
                                <h2 style={{
                                    color: '#4D80E4',
                                    fontFamily: 'Sarala',
                                    fontSize: '22px'}}>
                                        Editar Sprint</h2>
                                :
                                <h2 style={{
                                    color: '#4D80E4',
                                    fontFamily: 'Sarala',
                                    fontSize: '22px'}}>
                                        Crear Sprint</h2>}
                        </div>
                        <div className="modal-body p-mt-3" style={{width:'50vw'}}>
                            <div className="p-grid nested-grid">
                                <div className="p-col-12 p-lg-7">
                                    <div className="p-col">
                                        <div className="p-grid p-fluid ">
                                            <div className="p-col-12">
                                                <label 
                                                    className="p-d-block"
                                                    style={{
                                                        color: '#4D80E4',
                                                        fontFamily: 'Sarala',
                                                        fontSize: '20px'}}
                                                >Codigo</label>
                                                <InputText 
                                                    value={this.props.SprintBackLog.Codigo}
                                                    onChange={this.props.handleOnChangeModalSprint}
                                                    name="Codigo"
                                                    type="text"/>
                                                {this.props.Errores.Codigo && <small className="p-invalid p-d-block">{this.props.Errores.Codigo}</small>}        
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-col">
                                        <div className="p-grid p-fluid ">
                                            <div className="p-col-12">
                                                <label 
                                                    className="p-d-block"
                                                    style={{
                                                        color: '#4D80E4',
                                                        fontFamily: 'Sarala',
                                                        fontSize: '20px'}}
                                                >Nombre</label>
                                                <InputText 
                                                    value={this.props.SprintBackLog.Nombre}
                                                    onChange={this.props.handleOnChangeModalSprint}
                                                    name="Nombre"
                                                    type="text"/>
                                                {this.props.Errores.Nombre && <small className="p-invalid p-d-block">{this.props.Errores.Nombre}</small>}        
                                            </div>
                                        </div>
                                    </div>

                                   
                                    <div  style={{                                                     width:'20%',
                                 display:'inline-block' ,
                                 color: '#4D80E4',
                                 fontFamily: 'Sarala',
                                 fontSize: '20px'
                                }}>
                                       
                                                Abierto</div>
                                                <Checkbox       
                                     inputId="Abierto"  
                    checked={this.props.SprintBackLog.Abierto} 
                    onChange={this.props.handleOnChangeModalSprint}
                    style={{
                    width:'7%' ,
                    display:'inline-block' ,   
                    float:'left',
                    }}
                    name="Abierto" />
                                        
                                                {this.props.Errores.Abierto && <small className="p-invalid p-d-block">{this.props.Errores.Abierto}</small>}        
                                      

                                </div>
                                <div className="p-col-12 p-lg-5">
                                    <div className="p-col">
                                        <div className="p-grid p-fluid ">
                                            <div className="p-col-12">
                                                <label 
                                                    htmlFor="username1" 
                                                    className="p-d-block"
                                                    style={{
                                                        color: '#4D80E4',
                                                        fontFamily: 'Sarala',
                                                        fontSize: '20px'}}
                                                >Fecha Inicio
                                                </label>
                                                <Calendar 
                                                    value={this.props.SprintBackLog.FechaInicio? new Date(this.props.SprintBackLog.FechaInicio):new Date()} 
                                                    onChange={this.props.handleOnChangeModalSprint}
                                                    name="FechaInicio"
                                                    locale={es} 
                                                    dateFormat="dd/mm/yy"/>
                                                {this.props.Errores.FechaInicio && <small className="p-invalid p-d-block">{this.props.Errores.FechaInicio}</small>}        
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-col">
                                        <div className="p-grid p-fluid ">
                                            <div className="p-col-12">
                                                <label 
                                                    htmlFor="username1" 
                                                    className="p-d-block"
                                                    style={{
                                                        color: '#4D80E4',
                                                        fontFamily: 'Sarala',
                                                        fontSize: '20px'}}
                                                >Fecha Fin
                                                </label>
                                                <Calendar 
                                                    value={this.props.SprintBackLog.FechaFin? new Date(this.props.SprintBackLog.FechaFin):new Date()} 
                                                    onChange={this.props.handleOnChangeModalSprint}
                                                    name="FechaFin"
                                                    locale={es} 
                                                    dateFormat="dd/mm/yy"/> 
                                               {this.props.Errores.FechaFin && <small className="p-invalid p-d-block">{this.props.Errores.FechaFin}</small>}        
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        </div>
                        <div className="p-d-flex footer p-ml-auto">
                            <Button 
                                type="submit"
                                label="Guardar" 
                                style={{
                                    background:'#4D80E4',
                                    border: '1px solid #4D80E4'}}
                                icon="pi pi-check" 
                                disabled={this.props.Estado? (this.props.PKID === 3):false}
                                onClick={this.props.onSubmit}
                                className="p-ml-auto"
                                autoFocus />
                        </div>
                    </form>
            </Modal>
        )
    }
}