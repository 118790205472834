import React, {Component} from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'

class CuerpoCasoPruebaVersion extends Component{

    accionesBodyTemplate=(rowData)=>{
        return(
            <React.Fragment>
                <div className="p-grid p-fluid ">
                    <div className="p-col-12">
                    <Button 
                        icon="pi pi-pencil" 
                        className="p-button-rounded p-button-outlined" 
                        type="text" 
                        onClick={() => {this.props.ActualizarIncidente(rowData)}}/>
                    </div>
                </div>
            </React.Fragment>
        )
    }
    actionBodyTemplate=(rowData)=>{
        return (
            <React.Fragment>
                <div className="p-grid p-fluid ">
                    <div className="p-col-6">
                        {rowData.Correcto===1|| rowData.Correcto===3?
                            <Button 
                                icon="pi pi-check" 
                                className="p-button-rounded p-button-outlined" 
                                type="text" 
                                onClick={() => {this.props.PasoCorrecto(rowData)}} />
                            :
                            <Button 
                                icon="pi pi-check" 
                                className="p-button-rounded p-button-success" 
                                type="text" 
                                onClick={() => {this.props.PasoCorrecto(rowData)}} />}
                    </div>
                    <div className="p-col-6">
                        {rowData.Correcto===3?
                            <Button 
                                icon="pi pi-times" 
                                className="p-button-rounded p-button-danger" 
                                type="text" 
                                onClick={() => {this.props.PasoFallo(rowData)}} />
                            :
                            <Button 
                                icon="pi pi-times" 
                                className="p-button-rounded p-button-outlined" 
                                type="text" 
                                onClick={() => {this.props.PasoFallo(rowData)}} />}
                    </div>
                </div>
            </React.Fragment>
        );
    }
    itemTemplate = (item) => {
        return (
            <div className="product-item">
                <div className="product-list-detail">
                <h5 className="p-mb-2">{item.NumeroPaso}</h5>
                    <h5 className="p-mb-2">{item.Accion}</h5>
                    <i className="pi pi-tag product-category-icon"></i>
                    <span className="product-category">{item.ResultadoEsperado}</span>
                </div>
            </div>
        );
    }
    render(){
        return(
            <div className="p-grid bodyTrello" style={{margin:'0px'}}>
                <div className="p-col-12 p-md-6 p-lg-6">
                    <div className="list-wrapper">
                        <div className="list p-shadow-3">
                            <div className="list-header is-menu-shown">
                                <h2 className="list-header-name-assist lbls">Pasos</h2>
                            </div>
                            <div 
                                id="card_id" className="list-cards u-fancy-scrollbar u-clearfix js-list-cards js-sortable ui-sortable">
                                <div className="comment-container">
                                    <DataTable  value={this.props.ListaPaso} ef={(el) => this.dt = el}
                                        paginator
                                        className="p-datatable-sm"
                                        columnResizeMode="fit"
                                        reorderableColumns 
                                        onRowReorder={this.props.onRowReorder}
                                        rowsPerPageOptions={[6,8,10]}
                                        dataKey="id" rows={6}>
                                        <Column  rowReorder style={{width:'5%'}}></Column>
                                        <Column field="NumeroPaso" header="Paso" style={{width:'10%'}}></Column>
                                        <Column field="Accion" header="Acción" style={{width:'30%'}}></Column>
                                        <Column field="ResultadoEsperado" header="Resultado Esperado" style={{width:'35%'}}></Column>
                                        <Column body={this.actionBodyTemplate}  header="Estado" style={{width:'20%'}}></Column>
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-col-12 p-md-6 p-lg-6">
                    <div className="list-wrapper">
                        <div className="list p-shadow-3">
                            <div className="list-header is-menu-shown">
                                <h2 className="list-header-name-assist lbls">Incidencias</h2>
                                <div 
                                    className="open-card-composer" 
                                    onClick={(e) => this.props.CrearNuevoIncidente(e)}>
                                    <i className="pi pi-plus icon-sm"></i>
                                    <span>Nuevo</span>
                                </div>
                            </div>
                            <div 
                                id="card_id" className="list-cards u-fancy-scrollbar u-clearfix js-list-cards js-sortable ui-sortable">
                                <div className="comment-container">
                                    <DataTable  value={this.props.ListaIncidente} ef={(el) => this.dt = el}
                                        selectionMode="single"
                                        paginator
                                        className="p-datatable-sm"
                                        columnResizeMode="fit"
                                        dataKey="id" rows={6}>
                                        <Column field="Incidencia" header="Incidencia" style={{width:'20%'}}></Column>
                                        <Column field="Estado.Descripcion" header="Estado" style={{width:'20%'}}></Column>
                                        <Column field="Prioridad" header="Prioridad" style={{width:'20%'}}></Column>
                                        <Column field="Severidad.Nombre" header="Severidad" style={{width:'20%'}}></Column>
                                        <Column body={this.accionesBodyTemplate} header="Acción"></Column>
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default CuerpoCasoPruebaVersion;