import React from 'react'
import ProductoBody from '../Components/ProductoBody'
import ProductoVersionModal from '../Components/ProductoVersionModal'
import ProductoModal from '../Components/ProductoModal'
import { VersionProductoService } from '../../Domain/Services/VersionProductoService'

import { IProducto } from '../../Domain/Models/IProducto'
import { IVersionProducto } from '../../Domain/Models/IVersionProducto'

interface IProps {
}

interface IState {
    Paginado: any
    Registros: Array<object>
    ModalProducto: boolean
    Producto : IProducto
    ModalVersionProducto: boolean
    VersionProducto: IVersionProducto
    ListaCliente: Array<object>
    ListaProducto: Array<object>
    ErrorProducto: any
    ErrorVersionProducto: any
    Request: boolean
}

export default class Producto extends React.Component<IProps, IState> {

    cPaginado = {
        NumeroPagina : 1,
        RegistrosPagina : 10,
        NumeroPaginas : 0,
        fil_Nombre : '',
        fil_Modulo : '',
        fil_Responsable : ''
    }

    cRegistro = [
        { RegistrosPagina: 10 },
        { RegistrosPagina: 20 },
        { RegistrosPagina: 30 }
    ]

    cProducto = {
        PKID : 0,
        Nombre : '',
        UltimaVersion : '',
        VersionProducto : []
    }

    cVersionProducto = {
        PKID : 0,
        Version : '',
        Orden : 1
    }

    constructor(props: any){
        super(props)
        this.state = {
            Paginado : this.cPaginado,
            Registros : this.cRegistro,
            ModalProducto : false,
            Producto : this.cProducto,
            ModalVersionProducto: false,
            VersionProducto: this.cVersionProducto,
            ListaCliente : [],
            ListaProducto : [],
            ErrorProducto : {},
            ErrorVersionProducto : {},
            Request: false
        }
    }

    componentDidMount(){
        this.ListarPaginacion()
    }

    ListarPaginacion(){
        VersionProductoService.ListarPaginacion(this.state.Paginado)
        .then((response: any) => {
            if(response.success){
                this.setState({
                    Paginado: JSON.parse(response.data).Paginacion,
                    ListaProducto: JSON.parse(response.data).Producto
                })
            }
        })
        .catch((Error: any) => {
            alert(Error)
        })
    }

    OnResponse = (Producto: any) => {
        if(Producto){
            this.setState({ 
                Producto : Producto,
                ErrorProducto: {}
            },() => this.HideVisibleModalProducto(true))
        }else{
            this.setState({ 
                Producto : this.cProducto,
                ErrorProducto: {}
            },() => this.HideVisibleModalProducto(true))
        }
    }

    HideVisibleModalProducto = (Mode: boolean) => {
        this.setState({
            ModalProducto: Mode
        })
    }

    HandleChangeProducto = (Event: any) =>{
        this.setState({
            Producto: {
                ...this.state.Producto,
                [Event.target.name]: Event.target.value
            }
        })
    }

    HandleSubmitProducto = (Event: any) =>{
        Event.preventDefault()
        const result = VersionProductoService.ValidarProducto(this.state.Producto);
        if(Object.keys(result).length){
            return this.setState({ErrorProducto : result});
        }else{
            this.setState({
                Request: true
            }, () => this.CrearActualizarProducto())
        }
    }

    CrearActualizarProducto(){
        VersionProductoService.CrearActualizar(this.state.Producto)
        .then((response: any) => {
            if(response.success){
                var json = JSON.parse(response.data)
                if(json.PKID){
                    this.setState({
                        ModalProducto : !this.state.ModalProducto,
                        Producto : this.cProducto,
                        Paginado: this.cPaginado
                    },() => this.ListarPaginacion())
                }
            }else{
                alert(response.data)
            }
        })
        .catch((Error) => {
            alert(Error)
        })
        .finally(() => {
            this.setState({
                Request: false
            })
        })
    }

    HandleSelectRow = (VersionProducto: IVersionProducto) => {
        this.setState({ 
            VersionProducto : VersionProducto,
            ErrorVersionProducto : {}
        },() => this.HideVisibleModalVersionProducto(true))
    }

    CrearVersionProducto = () =>{
        this.setState({ 
            VersionProducto : {
                PKID : 0,
                Version : '',
                Orden : this.state.Producto.VersionProducto? 
                    this.state.Producto.VersionProducto[0].Orden + 1 
                    : 
                    1
            },
            ErrorVersionProducto : {}
        },() => this.HideVisibleModalVersionProducto(true))
    }

    HideVisibleModalVersionProducto = (Mode: boolean) => {
        this.setState({
            ModalVersionProducto: Mode
        })
    }

    HandleSubmitVersionProducto = (Event: any) => {
        Event.preventDefault()
        const result = VersionProductoService.ValidarVersionProducto(this.state.VersionProducto);
        if(Object.keys(result).length){
            return this.setState({ErrorVersionProducto : result});
        }else{
            var arr = []
            if(this.state.VersionProducto.PKID > 0){
                arr = this.state.Producto.VersionProducto.filter( (e) => {
                    return e.PKID !== this.state.VersionProducto.PKID
                })
                arr = arr.concat(this.state.VersionProducto)
            }else{
                arr = this.state.Producto.VersionProducto? this.state.Producto.VersionProducto : []
                arr = arr.concat([this.state.VersionProducto])
            }
            this.setState({
                Producto: {
                    ...this.state.Producto,
                    VersionProducto : this.OrdenarArray(arr)}
            },() => this.setState({ModalVersionProducto : !this.state.ModalVersionProducto}))
        }
    }

    OrdenarArray = (Array: Array<any>) => {
        return Array.sort(function (a, b) {
            if (a.Orden > b.Orden) return -1
            if (a.Orden < b.Orden) return 1
            return 0;
        })
    }

    HandleChangeVersionProducto = (Event: any) => {
        this.setState({
            VersionProducto: {
                ...this.state.VersionProducto,
                [Event.target.name]: Event.target.value
            }
        })
    }

    OnRegistroChange = (Event: any) =>{
        this.setState({
            Paginado : {
                ...this.state.Paginado,
                NumeroPagina: 1,
                RegistrosPagina: Event.value.RegistrosPagina,
        }},() =>{ this.ListarPaginacion() })
    }

    HandlePreviousLL = (Event: any) =>{
        this.setState({
            Paginado : this.cPaginado
        },() =>{ this.ListarPaginacion() })
    }

    HandlePrevious = (Event: any) =>{
        this.setState({Paginado : {
            ...this.state.Paginado,
            NumeroPagina: this.state.Paginado.NumeroPagina - 1,
        }},() =>{ this.ListarPaginacion() })
    }

    HandleFollowing = () => {
        this.setState({Paginado : {
            ...this.state.Paginado,
            NumeroPagina: this.state.Paginado.NumeroPagina + 1,
        }},() =>{ this.ListarPaginacion() })
    }

    HandleFollowingLL = () => {
        this.setState({Paginado : {
            NumeroPagina: this.state.Paginado.NumeroPaginas,
            RegistrosPagina: this.state.Paginado.RegistrosPagina,
            NumeroPaginas: this.state.Paginado.NumeroPaginas,
            fil_Nombre : this.state.Paginado.fil_Nombre
        }},() =>{ this.ListarPaginacion() })
    }

    render(){
        return(
            <div className="container">
                <ProductoBody
                    ListaProducto={this.state.ListaProducto}
                    OnResponse={this.OnResponse}
                    Paginado={this.state.Paginado}
                    Registros= {this.state.Registros}
                    OnRegistroChange = {this.OnRegistroChange}
                    HandlePreviousLL = {this.HandlePreviousLL}
                    HandlePrevious = {this.HandlePrevious}
                    HandleFollowing = {this.HandleFollowing}
                    HandleFollowingLL = {this.HandleFollowingLL}/>
                    <form autoComplete="Off" onSubmit={this.HandleSubmitProducto}>
                        <ProductoModal
                            Modal = {this.state.ModalProducto}
                            FormValues = {this.state.Producto}
                            CrearVersionProducto = {this.CrearVersionProducto}
                            HideModal = {this.HideVisibleModalProducto}
                            HandleChange = {this.HandleChangeProducto}
                            ErrorProducto = {this.state.ErrorProducto}
                            HandleSelectRow={this.HandleSelectRow}
                            Request={this.state.Request}/>
                    </form>
                    <form autoComplete="Off" onSubmit={this.HandleSubmitVersionProducto}>
                        <ProductoVersionModal
                            Modal = {this.state.ModalVersionProducto}
                            HideModal = {() => this.HideVisibleModalVersionProducto(false)}
                            Producto = {this.state.Producto}
                            FormValues = {this.state.VersionProducto}
                            HandleChange = {this.HandleChangeVersionProducto}
                            ErrorVersionProducto = {this.state.ErrorVersionProducto}/>
                    </form>
            </div>
        )
    }
}