import React, { Component } from 'react'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown'
import { Button } from 'primereact/button'
import { AuthHelper } from '../../Domain/Utils/AuthHelper'
import '../Assets/css/Sprint.css'

export default class PanelSprint extends Component{
    render(){
        return(
            <div id="PanelSprint" className="OverlaySprint">
                <div id="PopupSprint" className="PopupSprint">
                    <div className="HeaderPopup">
                        <div className="p-d-flex p-jc-between">
                            <div className="Title">Sprint</div>
                            <div className="btn-cerrar-popupsprint" onClick={() => 
                            {
                                document.getElementById('PanelSprint').classList.remove('active')
                                document.getElementById('PopupSprint').classList.remove('active')
                            }}
                            ><i className="fas fa-times"></i></div>
                        </div>
                    </div>
                    <form autoComplete="off" className="BodySprint">
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-6">   
                                <div className="p-grid p-fluid">
                                    <div className="p-col">
                                        <label 
                                            style={{
                                                color: '#4D80E4',
                                                fontFamily: 'Sarala',
                                                fontSize: '19px'}}>
                                        Horas Planificadas</label>
                                        <InputText 
                                            value={parseFloat(Math.round(this.props.Sprint.HorasPlanificadas* 100) / 100).toFixed(2)}
                                            name="HorasPlanificadas"
                                            type="number" 
                                            disabled
                                            placeholder="0.00"/>
                                    </div>
                                </div>
                            </div> 
                            <div className="p-col-12 p-lg-6">   
                                <div className="p-grid p-fluid">
                                    <div className="p-col">
                                    <label 
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '19px'}}>
                                    Horas Ejecutadas</label>
                                    <InputText 
                                        value={parseFloat(Math.round(this.props.Sprint.HorasEjecutadas * 100) / 100).toFixed(2)}
                                        name="HorasEjecutadas"
                                        type="number"
                                        disabled
                                        placeholder="0.00"/>
                                    </div>
                                </div>
                            </div> 
                            <div className="p-col-12">
                                <div className="p-grid p-fluid">
                                    <div className="p-col">
                                        <label 
                                            style={{
                                                color: '#4D80E4',
                                                fontFamily: 'Sarala',
                                                fontSize: '19px'}}>
                                        Sprint</label>
                                        <Dropdown 
                                            value={{
                                                code: this.props.Sprint.PKID,
                                                name: this.props.Sprint.Nombre}}
                                            options={this.props.ListaSprintDDL}
                                            onChange={this.props.handleOnChangeSprint}
                                            optionLabel="name"
                                            placeholder="Seleccione Sprint"/>
                                    </div>
                                </div>
                            </div>
                            <div className="p-col-12">
                                <div className="p-d-flex p-p-1">
                                    <Button 
                                        type="button" 
                                        label="Nuevo"
                                        onClick={this.props.nuevoSprint}
                                        disabled = {AuthHelper.GetDecoded().Administrador != 'True'}
                                        className="p-button-success p-mr-2" />
                                    <Button 
                                        type="button" 
                                        label="Editar"
                                        onClick={this.props.editarSprint}
                                        disabled = {AuthHelper.GetDecoded().Administrador != 'True'}
                                        className="p-button-secondary"/>
                                    <Button 
                                        type="button" 
                                        label="Eliminar"
                                        onClick={this.props.eliminarSprint}
                                        disabled = {AuthHelper.GetDecoded().Administrador != 'True'}
                                        className="p-ml-auto p-button-danger"/>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}