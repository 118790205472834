import { BackLogRepository } from '../../Infrastructure/Repositories/BackLogRepository'
import { IImplementacionBackLog } from '../Models/IImplementacionBackLog'
import { IPlanPrueba } from '../Models/IPlanPrueba'

interface IErroresBackLog{
    Nombre?: string
    Descripcion?: string   
    LinkDocumentacionDesarrollo?: string 
    Empleado?: string  
    Producto?: string  
    VersionProducto?: string  
    Objetivo?: string 
    Cliente?: string  
    MotivoVersionamiento? : string
    DetalleVersionamiento? : string    
}

const ValidarSchema = (ImplementacionBackLog: IImplementacionBackLog) => {
    const errores: IErroresBackLog ={}
    if(!ImplementacionBackLog.Nombre) errores.Nombre  = 'Nombre del backlog es requerido'
    if(!ImplementacionBackLog.Descripcion) errores.Descripcion = 'Descripción del backlog es requerido'  
    if(!ImplementacionBackLog.DetalleVersionamiento) errores.DetalleVersionamiento = 'Detalle de Versionamiento del backlog es requerido'       
    if(!ImplementacionBackLog.LinkDocumentacionDesarrollo) errores.LinkDocumentacionDesarrollo = 'Link Documentación Desarrollo del backlog es requerido'  
    if(ImplementacionBackLog.Empleado){
        if(ImplementacionBackLog.Empleado.PKID == 0){
            errores.Empleado = 'Responsable Origen del backlog es requerido'   
        }
    }else{
        errores.Empleado = 'Responsable Origen del backlog es requerido'
    } 
    if(ImplementacionBackLog.Producto){
        if(ImplementacionBackLog.Producto.PKID == 0){
            errores.Producto = 'Producto del backlog es requerido'   
        }
    }else{
        errores.Producto = 'Producto del backlog es requerido'
    }    
    if(ImplementacionBackLog.VersionProducto){
        if(ImplementacionBackLog.VersionProducto.PKID == 0){
            errores.VersionProducto = 'Versión Producto del backlog es requerido'   
        }
    }else{
        errores.VersionProducto = 'Versión Producto del backlog es requerido'
    }
    if(!ImplementacionBackLog.Objetivo) errores.Objetivo = 'Objetivo del backlog es requerido'   
    if(ImplementacionBackLog.Cliente){
        if(ImplementacionBackLog.Cliente.PKID == 0){
            errores.Cliente = 'Cliente del backlog es requerido'   
        }
    }else{
        errores.Cliente = 'Cliente del backlog es requerido'
    }     
    if(ImplementacionBackLog.MotivoVersionamiento){
        if(ImplementacionBackLog.MotivoVersionamiento.PKID == 0){
            errores.MotivoVersionamiento = 'Motivo de Versionamiento del backlog es requerido'   
        }
    }else{
        errores.MotivoVersionamiento = 'Motivo de Versionamiento del backlog es requerido'
    }     
    return errores;
}

const ValidarStakeholder = (Stakeholder: any) => {
    const Errores: {Descripcion?: string} = {}
    if(!Stakeholder.Descripcion) Errores.Descripcion = 'Descripción es requerida'    
    return Errores
}

const ValidarDescargarWord = (ImplementacionBackLogVersion: any) => {
    const Errores: {BackLogVersion?: string} = {}
    if(ImplementacionBackLogVersion){
        if(ImplementacionBackLogVersion.PKID == 0){
            Errores.BackLogVersion = 'Versión del backlog es requerida'
        }
    }else{
        Errores.BackLogVersion = 'Versión del backlog es requerida'
    }
    return Errores
}

const ObtenerObjetoEstado = (Array: Array<IImplementacionBackLog>, PKID: string, IDEstado: number) => {
    let aObj: Array<IImplementacionBackLog> =  Array.filter( (Obj : IImplementacionBackLog) =>{
        return Obj.PKID === parseInt(PKID,10) 
    })
    let Obj: IImplementacionBackLog = aObj[0] 
    return {
        ...Obj,
        Estado:{
            ...Obj.Estado,
            PKID: IDEstado
        },
        ImplementacionBackLogVersion:[]
    }
}

const CrearVersion = (BackLog: IImplementacionBackLog, PasBackLog: IImplementacionBackLog) => {
   
                return BackLogRepository.CrearVersion(BackLog)
            
}

const GuardarTemporal = (BackLog: IImplementacionBackLog, PasBackLog: IImplementacionBackLog) => {
    
                return BackLogRepository.CrearActualizar(BackLog)
           
}

export const BackLogService = {
    ValidarSchema,
    ValidarStakeholder,
    ValidarDescargarWord,
    ObtenerObjetoEstado,
    Consultar: (BackLog: any) => {
        return BackLogRepository.Consultar(BackLog)
    },
    ConsultarEnPrueba: (PlanPruebas: IPlanPrueba) => {
        return BackLogRepository.ConsultarEnPrueba(PlanPruebas)
    },
    ObtenerFiltro: (IDUsuario: number) => {
        return BackLogRepository.ObtenerFiltro(IDUsuario)
    },
    Listar: () => {
        return BackLogRepository.Listar()
    },
    ListarPaginacion: (Paginacion: any) => {
        return BackLogRepository.ListarPaginacion(Paginacion)
    },
    CrearActualizar: (BackLog: IImplementacionBackLog) => {
        return BackLogRepository.CrearActualizar(BackLog)
    },
    GuardarTemporal,
    CrearActualizarFiltro: (Filtro: any) => {
        return BackLogRepository.CrearActualizarFiltro(Filtro)
    },
    LimpiarFiltro: (IDUsuario : number) => {
        return BackLogRepository.LimpiarFiltro(IDUsuario)
    },
    CrearVersion, 
    ListarCalidad: () => {
        return BackLogRepository.ListarCalidad()
    },
    DescargarWord: (IDImplementacionBackLogVersion : Number) => {
        return BackLogRepository.DescargarWord(IDImplementacionBackLogVersion)
    },
}