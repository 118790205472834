import React, { Component } from 'react'
import { Button } from 'primereact/button'

import '../Assets/css/Direccionamiento.css'

export default class HeaderTareo extends Component{

    render(){
        return(
            <div className="p-col-12">
                <div className="CrumbBeetwen">
                    <div className="p-d-flex">
                        <ul className="breadcrumb">
                            <li><a href="/Desarrollo/Inicio">Inicio</a></li>
                            <li>Tareo</li>
                        </ul>
                        <div className="BtnFiltro p-ml-auto">
                            <div className="p-d-flex">
                                <div className="p-mr-2">
                                    <Button 
                                        label="Sprint" 
                                        className="p-button-rounded p-button-info p-mx-auto"
                                        onClick={ () =>{
                                            document.getElementById('PanelSprint').classList.add('active')
                                            document.getElementById('PopupSprint').classList.add('active')
                                        }}/>
                                </div>
                                <div className="p-mr-2">
                                    <Button 
                                        id="OpenFilter"
                                        type="button" 
                                        icon="fas fa-filter" 
                                        onClick={() => 
                                        {
                                            document.getElementById('PanelFiltro').classList.add('active')
                                            document.getElementById('PopupFiltro').classList.add('active')
                                        }}  
                                        className="p-button-rounded p-button-text p-button-plain"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )                
    }
}