import React from 'react'
import { Dropdown } from 'primereact/dropdown'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { Checkbox } from 'primereact/checkbox';

interface IProps{
    OnResponse: any
    AplicarFiltros: any
    ListaCasoPrueba: Array<object>
    Paginado: any
    Registros: Array<object>
    OnChangeFilter: any
    OnRegistroChange: any
    HandlePreviousLL: any
    HandlePrevious: any
    HandleFollowing: any
    HandleFollowingLL: any
}

export default function CasoPruebaBody(Props: IProps){

    const actionBodyTemplate = (Event: any) => {
        return(
            <p>{Event.FechaCreacion? new Date(Event.FechaCreacion).getDate() + "/" + (new Date(Event.FechaCreacion).getMonth() + 1) + "/" + new Date(Event.FechaCreacion).getFullYear():''}</p>
        )
    }
    
    const header = (
        <div className="p-d-flex p-jc-between">
            <h4>Casos de prueba</h4>
            <div style={{textAlign:"right"}}>
            <Button         
                style={{marginRight:"10px"}}        
                label="Aplicar Filtros" 
                onClick={() => Props.AplicarFiltros(null)} 
                />
            <Button 
                label="Agregar Caso de Prueba" 
                onClick={() => Props.OnResponse(null)} 
                autoFocus/>
            </div>           
        </div>
    )

    const FilterCodigo = (
        <InputText  
            className="p-column-filter"
            name="fil_Codigo"
            value={Props.Paginado.fil_Codigo} 
            onChange={(e) => Props.OnChangeFilter(e)}/>)

    const FilterNombre = (
        <InputText  
            className="p-column-filter"
            name="fil_Nombre"
            value={Props.Paginado.fil_Nombre} 
            onChange={(e) => Props.OnChangeFilter(e)}/>)

    const FilterProducto = (
        <InputText  
            className="p-column-filter"
            name="fil_Producto"
            value={Props.Paginado.fil_Producto} 
            onChange={(e) => Props.OnChangeFilter(e)}/>)

    const FilterVersion = (
        <InputText  
            className="p-column-filter"
            name="fil_Version"
            value={Props.Paginado.fil_Version} 
            onChange={(e) => Props.OnChangeFilter(e)}/>)
    
    const FilterModulo = (
        <InputText  
            className="p-column-filter"
            name="fil_Modulo"
            value={Props.Paginado.fil_Modulo} 
            onChange={(e) => Props.OnChangeFilter(e)}/>)
    
    const FilterResponsable = (
        <InputText  
            className="p-column-filter"
            name="fil_Responsable"
            value={Props.Paginado.fil_Responsable} 
            onChange={(e) => Props.OnChangeFilter(e)}/>)    

            /*
            const automatizadoBodyTemplate = (rowData) => {
                return <span>{rowData.Automatizado ? 'Sí' : 'No'}</span>;
            };
            */
            const BodyTemplate2 = (RowData: any) => {
                return (
                    <React.Fragment>

                        <span>{RowData.Automatizado ? '✔️' : '❌'}</span>
                        
                    </React.Fragment>
                );
            }
       
    return(
        
        <div className="p-grid bodyTrello" >
            <div className="p-col-12">
                <DataTable  
                    value={Props.ListaCasoPrueba} 
                    onSelectionChange={(Event) => Props.OnResponse(Event.value)}
                    selectionMode="single"
                    rowHover
                    className="p-datatable-sm"
                    columnResizeMode="fit"
                    dataKey="id" 
                    header={header}>
                        <Column field="Codigo" header="Codigo"  filter filterElement={FilterCodigo} ></Column>
                        <Column field="Nombre" header="Nombre"  filter filterElement={FilterNombre}  style={{ wordBreak: "break-all" }}></Column>
                        <Column field="Producto.Nombre" header="Producto" filter filterElement={FilterProducto}  style={{ wordBreak: "break-all" }}></Column>
                        <Column field="VersionProducto.Version" header="Version Producto" filter filterElement={FilterVersion} ></Column>
                        <Column field="Modulo.Nombre" header="Modulo" sortField="Modulo.Nombre" filter filterElement={FilterModulo}  style={{ wordBreak: "break-all" }}></Column>
                        <Column field="TipoPrueba.Nombre" header="Tipo Prueba"  style={{ wordBreak: "break-all" }}></Column>
                        <Column body={actionBodyTemplate} header="Fecha de Creación" ></Column>
                        <Column field="Empleado.Nombre" header="Responsable"  filter filterElement={FilterResponsable}  style={{ wordBreak: "break-all" }}></Column>
                        <Column field="Version" header="Version"></Column>   
 
                        <Column field="Automatizado" header="Automatizado" body={BodyTemplate2} style={{ wordBreak: "break-all" }}></Column>          
                </DataTable>
                <div className="p-paginator p-component p-paginator-bottom">
                    {Props.Paginado.NumeroPagina > 1 ? 
                        <>
                            <button type="button" className="p-paginator-first p-paginator-element p-link" onClick={Props.HandlePreviousLL}>
                                <span className="p-paginator-icon pi pi-angle-double-left"></span>
                            </button>
                            <button type="button" className="p-paginator-prev p-paginator-element p-link" onClick={Props.HandlePrevious}>
                                <span className="p-paginator-icon pi pi-angle-left"></span>
                            </button>
                        </>
                        :
                        <>
                            <button type="button" className="p-paginator-first p-paginator-element p-link p-disabled" disabled>
                                <span className="p-paginator-icon pi pi-angle-double-left"></span>
                            </button>
                            <button type="button" className="p-paginator-prev p-paginator-element p-link p-disabled" disabled>
                                <span className="p-paginator-icon pi pi-angle-left"></span>
                            </button>
                        </>
                    }
                    <span className="p-paginator-pages">
                        <button type="button" className="p-paginator-page p-paginator-element p-link p-highlight">{Props.Paginado.NumeroPagina}</button>
                    </span>
                    {Props.Paginado.NumeroPagina < Props.Paginado.NumeroPaginas ? 
                        <>
                            <button type="button" className="p-paginator-next p-paginator-element p-link" onClick={Props.HandleFollowing}>
                                <span className="p-paginator-icon pi pi-angle-right"></span>
                            </button>
                            <button type="button" className="p-paginator-last p-paginator-element p-link" onClick={Props.HandleFollowingLL}>
                                <span className="p-paginator-icon pi pi-angle-double-right"></span>
                            </button>
                        </>
                        :
                        <>
                            <button type="button" className="p-paginator-next p-paginator-element p-link p-disabled" disabled>
                                <span className="p-paginator-icon pi pi-angle-right"></span>
                            </button>
                            <button type="button" className="p-paginator-last p-paginator-element p-link p-disabled" disabled>
                                <span className="p-paginator-icon pi pi-angle-double-right"></span>
                            </button>
                        </>
                    }
                    <Dropdown 
                        value = {{RegistrosPagina : Props.Paginado.RegistrosPagina}}
                        options = {Props.Registros} 
                        onChange = {Props.OnRegistroChange} 
                        optionLabel = "RegistrosPagina"/>
                </div>
            </div>
        </div>
    )
}