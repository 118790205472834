import React from 'react'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import { Calendar } from 'primereact/calendar'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { Local } from './LocaleSettings'
import { Editor } from 'primereact/editor'
import { Checkbox } from 'primereact/checkbox'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { FileUpload } from 'primereact/fileupload';



interface IProps {
    Modal: boolean
    HideModal: any
    HandleChange: any
    FormValues: any
    ListaCliente: Array<object>
    ListaArea: Array<object>
    ListaProducto: Array<object>
    ListaCategorizacion: Array<object>
    ListaCausante: Array<object>
    ListaEmpleado: Array<object>
    ListaEstado: Array<object>
    Errores: any
    Request: boolean
    SubirArchivo: any
    BorrarArchivo: any
    DescargarArchivo: any
    InputHabilitado: boolean
}

export default function ModalTicket(Props: IProps) {

    const File = React.createRef<FileUpload>()

    const ImageArchivo = (RowData: any) => {
        var extension = RowData.Extension
        let icono = ''
        let fondo = ''

        if (extension === 'txt') {
            icono = 'pi pi-file'
            fondo = '#000000'
        }
        else if (extension === 'pdf') {
            icono = 'pi pi-file-pdf'
            fondo = '#ff471a'
        }
        else if (extension === 'docx' || extension === 'docm' || extension === 'dotx' || extension === 'dotm' || extension === 'doc' || extension === 'dot' || extension === 'rtf') {
            icono = 'far fa-file-word'
            fondo = '#80e5ff'
        }
        else if (extension === 'xls' || extension === 'xlsx' || extension === 'xlsm' || extension === 'xltx' || extension === 'xltm') {
            icono = 'far fa-file-excel'
            fondo = '#00802b'
        }
        else if (extension === 'pptx' || extension === 'pptm' || extension === 'ppt' || extension === 'potx') {
            icono = 'far fa-file-powerpoint'
            fondo = '#ffa64d'
        }
        else if (extension === 'sql' || extension === 'js' || extension === 'vb' || extension === 'cs') {
            icono = 'fas fa-scroll'
            fondo = '#66ff66'
        }
        else if (extension === 'zip' || extension === '7z' || extension === 'rar') {
            icono = 'far fa-file-archive'
            fondo = '#3385ff'
        }
        else {
            icono = 'pi pi-question'
            fondo = '#000000'
        }

        return (
            <React.Fragment>
                <div className="p-grid p-fluid ">
                    <div className="p-col-12">
                        <i className={icono} style={{ 'fontSize': '2em', color: `${fondo}` }}></i>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    const TamañoArchivo = (RowData: any) => {
        return (<p>{(RowData.Tamagno / Math.pow(1024, 2)).toFixed(2) + 'MB'}</p>)
    }

    const OperacionArchivo = (Event: any) => {
        return (
            <React.Fragment>
                <div className="p-grid p-fluid ">
                    <div className="p-col-6">
                        <Button
                            type="button"
                            icon="pi pi-times"
                            style={{ width: '30px', height: '30px' }}
                            className="p-button-rounded p-button-outlined"
                            onClick={() => {
                                Props.BorrarArchivo(Event)
                            }} />
                    </div>
                    <div className="p-col-6">
                        <Button
                            type="button"
                            icon="pi pi-download"
                            style={{ width: '30px', height: '30px' }}
                            className="p-button-rounded p-button-outlined"
                            onClick={() => {
                                Props.DescargarArchivo(Event)
                            }} />
                    </div>
                </div>
            </React.Fragment>
        )
    }

    const FechaCreacion = (RowData: any) => {
        let newDate
        RowData.FechaCreacion !== '' ? newDate = new Date(RowData.FechaCreacion) : newDate = new Date()
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        return `${date}${'/'}${month < 10 ? `0${month}` : `${month}`}${'/'}${year}`
    }

    const Header = (
        <div className="p-d-flex p-jc-between">
            <FileUpload
                ref={File}
                mode="basic"
                name="file"
                auto
                uploadHandler={(Event) => Props.SubirArchivo(Event, File)}
                customUpload
                className="p-ml-auto"
                chooseLabel="Buscar archivo" />
        </div>
    )

    const header = (
        <>
            <span className="ql-formats">
                <button className="ql-bold" aria-label="Bold"></button>
                <button className="ql-italic" aria-label="Italic"></button>
                <button className="ql-underline" aria-label="Underline"></button>
            </span>
            <span className="ql-formats">
                <button type="button" className="ql-list" value="ordered" aria-label="Ordered List">
                    <svg viewBox="0 0 18 18">
                        <line className="ql-stroke" x1="7" x2="15" y1="4" y2="4"></line>
                        <line className="ql-stroke" x1="7" x2="15" y1="9" y2="9"></line>
                        <line className="ql-stroke" x1="7" x2="15" y1="14" y2="14"></line>
                        <line className="ql-stroke ql-thin" x1="2.5" x2="4.5" y1="5.5" y2="5.5"></line>
                        <path className="ql-fill" d="M3.5,6A0.5,0.5,0,0,1,3,5.5V3.085l-0.276.138A0.5,0.5,0,0,1,2.053,3c-0.124-.247-0.023-0.324.224-0.447l1-.5A0.5,0.5,0,0,1,4,2.5v3A0.5,0.5,0,0,1,3.5,6Z"></path>
                        <path className="ql-stroke ql-thin" d="M4.5,10.5h-2c0-.234,1.85-1.076,1.85-2.234A0.959,0.959,0,0,0,2.5,8.156"></path>
                        <path className="ql-stroke ql-thin" d="M2.5,14.846a0.959,0.959,0,0,0,1.85-.109A0.7,0.7,0,0,0,3.75,14a0.688,0.688,0,0,0,.6-0.736,0.959,0.959,0,0,0-1.85-.109"></path>
                    </svg>
                </button>
                <button type="button" className="ql-list" value="bullet" aria-label="Unordered List">
                    <svg viewBox="0 0 18 18">
                        <line className="ql-stroke" x1="6" x2="15" y1="4" y2="4"></line>
                        <line className="ql-stroke" x1="6" x2="15" y1="9" y2="9"></line>
                        <line className="ql-stroke" x1="6" x2="15" y1="14" y2="14"></line>
                        <line className="ql-stroke" x1="3" x2="3" y1="4" y2="4"></line>
                        <line className="ql-stroke" x1="3" x2="3" y1="9" y2="9"></line>
                        <line className="ql-stroke" x1="3" x2="3" y1="14" y2="14"></line>
                    </svg>
                </button>
            </span>
        </>
    );

    const EditorDescripcion = (Event: any) => {
        Props.HandleChange({
            target: {
                name: "Descripcion",
                value: Event.htmlValue ? Event.htmlValue.replace('<span ', '<div ').replace('</span>', '</div>') : ''
            }
        })
    }

    const RenderFooter = () => {
        return (
            <Button
                type="submit"
                label={Props.Request ? "Cargando..." : "Aceptar"}
                style={{
                    background: '#4D80E4',
                    border: '1px solid #4D80E4'
                }}
                icon={Props.Request ? "" : "pi pi-check"}
                className="p-ml-auto"
                disabled={Props.Request}
                autoFocus />
        )
    }
    const abrirURL = () => {
        const { LinkOCS } = Props.FormValues;
        if (LinkOCS) {
          window.open(LinkOCS, '_blank');
        }
      };

    return (
        <Dialog
            header='Ticket'
            visible={Props.Modal}
            style={{ width: '110vh' }}
            footer={RenderFooter()}
            onHide={() => Props.HideModal(null, false)}>
            <div className="p-grid nested-grid" style={{ overflow: 'visible' }}>
                <div className="p-col-12 p-lg-8">
                    <div className="p-col">
                        <div className="p-grid p-fluid ">
                            <div className="p-col-12">
                                <label
                                    className="p-d-block"
                                    style={{
                                        color: '#4D80E4',
                                        fontFamily: 'Sarala',
                                        fontSize: '20px'
                                    }}
                                >Nombre</label>
                                <InputText
                                    value={Props.FormValues.Nombre}
                                    onChange={Props.HandleChange}
                                    name="Nombre"
                                    type="text" />
                                {Props.Errores.Nombre && <small className="p-invalid p-d-block">{Props.Errores.Nombre}</small>}
                            </div>
                        </div>
                    </div>
                    <div className="p-col">
                        <div className="p-grid p-fluid ">
                            <div className="p-col-12">
                                <label
                                    className="p-d-block"
                                    style={{
                                        color: '#4D80E4',
                                        fontFamily: 'Sarala',
                                        fontSize: '20px'
                                    }}
                                >Descripcion</label>
                                <Editor
                                    style={{ height: '120px' }}
                                    value={Props.FormValues.Descripcion ? Props.FormValues.Descripcion : ''}
                                    onTextChange={EditorDescripcion}
                                    headerTemplate={header} />
                                {Props.Errores.Descripcion && <small className="p-invalid p-d-block">{Props.Errores.Descripcion}</small>}
                            </div>
                        </div>
                    </div>
                    <div className="p-grid p-fluid">
                        <div className="p-col-12 p-md-6">
                            <label
                                style={{
                                    color: '#4D80E4',
                                    fontFamily: 'Sarala',
                                    fontSize: '20px'
                                }}
                            >Fecha Creacion</label>
                            <Calendar
                                value={Props.FormValues.FechaCreacion ? new Date(Props.FormValues.FechaCreacion) : new Date()}
                                onChange={Props.HandleChange}
                                showTime
                                showSeconds
                                disabled
                                name="FechaCreacion"
                                locale={Local}
                                dateFormat="dd/mm/yy" />
                        </div>
                        <div className="p-col-12 p-md-6">
                            <label
                                className="p-d-block"
                                style={{
                                    color: '#4D80E4',
                                    fontFamily: 'Sarala',
                                    fontSize: '20px'
                                }}
                            >Responsable</label>
                            <InputText
                                value={Props.FormValues.Responsable.Empleado.Nombre}
                                onChange={Props.HandleChange}
                                type="text"
                                disabled />
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-12">
                            <div className="p-col">
                                <div className="p-grid p-fluid ">
                                    <div className="p-col-12">
                                        <label
                                            style={{
                                                color: '#4D80E4',
                                                fontFamily: 'Sarala',
                                                fontSize: '20px'
                                            }}
                                        >Link OCS</label>
                                    </div>
                                    <div className="p-col-11">
                                        <InputText
                                            value={Props.FormValues.LinkOCS ? Props.FormValues.LinkOCS : ''}
                                            onChange={Props.HandleChange}
                                            type="text"
                                            name="LinkOCS"
                                            placeholder="https://"
                                            disabled={!Props.InputHabilitado}
                                        />
                                    </div>
                                    <div className="p-col-1">
                                        <Button
                                            type="button"
                                            icon="pi pi-arrow-circle-right"
                                            className="p-button-rounded p-button-outlined"
                                            onClick={abrirURL}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-col">
                        <div className="p-grid p-fluid ">
                            <div className="p-col-12">
                                <label
                                    className="p-d-block"
                                    style={{
                                        color: '#4D80E4',
                                        fontFamily: 'Sarala',
                                        fontSize: '20px'
                                    }}
                                >Evidencia</label>
                                <DataTable
                                    value={Props.FormValues.Evidencias}
                                    paginator
                                    rows={5}
                                    header={Header}
                                    className="p-datatable-sm">
                                    <Column body={ImageArchivo} header="T. de archivo"></Column>
                                    <Column field="Nombre" header="Nombre"></Column>
                                    <Column body={FechaCreacion} header="Fecha creación" />
                                    <Column body={TamañoArchivo} header="Tamaño"></Column>
                                    <Column body={OperacionArchivo} header="Operación"></Column>
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-col-12 p-lg-4">
                    <div className="p-col">
                        <div className="p-grid p-fluid ">
                            <div className="p-col-12">
                                <label
                                    className="p-d-block"
                                    style={{
                                        color: '#4D80E4',
                                        fontFamily: 'Sarala',
                                        fontSize: '20px'
                                    }}
                                >Cliente</label>
                                <Dropdown
                                    value={{
                                        code: Props.FormValues.Cliente.PKID,
                                        name: Props.FormValues.Cliente.Nombre
                                    }}
                                    options={Props.ListaCliente}
                                    onChange={Props.HandleChange}
                                    name="Cliente"
                                    optionLabel="name"
                                    placeholder="Seleccione Cliente" />
                                {Props.Errores.Cliente && <small className="p-invalid p-d-block">{Props.Errores.Cliente}</small>}
                            </div>
                        </div>
                    </div>
                    <div className="p-col">
                        <div className="p-grid p-fluid ">
                            <div className="p-col-12">
                                <label
                                    className="p-d-block"
                                    style={{
                                        color: '#4D80E4',
                                        fontFamily: 'Sarala',
                                        fontSize: '20px'
                                    }}
                                >Area de apoyo</label>
                                <Dropdown
                                    value={{
                                        code: Props.FormValues.Area.PKID,
                                        name: Props.FormValues.Area.Nombre
                                    }}
                                    options={Props.ListaArea}
                                    name="Area"
                                    onChange={Props.HandleChange}
                                    optionLabel="name"
                                    placeholder="Seleccione el area de apoyo" />
                                {Props.Errores.Area && <small className="p-invalid p-d-block">{Props.Errores.Area}</small>}
                            </div>
                        </div>
                    </div>
                    <div className="p-col">
                        <div className="p-grid p-fluid ">
                            <div className="p-col-12">
                                <label
                                    className="p-d-block"
                                    style={{
                                        color: '#4D80E4',
                                        fontFamily: 'Sarala',
                                        fontSize: '20px'
                                    }}
                                >Producto</label>
                                <Dropdown
                                    value={{
                                        code: Props.FormValues.Producto.PKID,
                                        name: Props.FormValues.Producto.Nombre
                                    }}
                                    options={Props.ListaProducto}
                                    name="Producto"
                                    onChange={Props.HandleChange}
                                    optionLabel="name"
                                    placeholder="Seleccione Producto" />
                                {Props.Errores.Producto && <small className="p-invalid p-d-block">{Props.Errores.Producto}</small>}
                            </div>
                        </div>
                    </div>
                    <div className="p-col">
                        <div className="p-grid p-fluid ">
                            <div className="p-col-12">
                                <label
                                    className="p-d-block"
                                    style={{
                                        color: '#4D80E4',
                                        fontFamily: 'Sarala',
                                        fontSize: '20px'
                                    }}
                                >Empleado Origen</label>
                                <Dropdown
                                    value={{
                                        code: Props.FormValues.EmpleadoOrigen.PKID,
                                        name: Props.FormValues.EmpleadoOrigen.Empleado.Nombre
                                    }}
                                    options={Props.ListaEmpleado}
                                    onChange={Props.HandleChange}
                                    name="Empleado"
                                    optionLabel="name"
                                    placeholder="Seleccione el Empleado" />
                                {Props.Errores.EmpleadoOrigen && <small className="p-invalid p-d-block">{Props.Errores.EmpleadoOrigen}</small>}
                            </div>
                        </div>
                    </div>
                    <div className="p-col">
                        <div className="p-grid p-fluid ">
                            <div className="p-col-12">
                                <label
                                    className="p-d-block"
                                    style={{
                                        color: '#4D80E4',
                                        fontFamily: 'Sarala',
                                        fontSize: '20px'
                                    }}
                                >Categorización</label>
                                <Dropdown
                                    value={{
                                        code: Props.FormValues.Categorizacion ? Props.FormValues.Categorizacion.PKID : 0,
                                        name: Props.FormValues.Categorizacion ? Props.FormValues.Categorizacion.Descripcion : ''
                                    }}
                                    options={Props.ListaCategorizacion}
                                    name="Categorizacion"
                                    onChange={Props.HandleChange}
                                    optionLabel="name"
                                    placeholder="Seleccione Categorización" />
                                {Props.Errores.Categorizacion && <small className="p-invalid p-d-block">{Props.Errores.Categorizacion}</small>}
                            </div>
                        </div>
                    </div>
                    <div className="p-col">
                        <div className="p-grid p-fluid ">
                            <div className="p-col-12">
                                <label
                                    className="p-d-block"
                                    style={{
                                        color: '#4D80E4',
                                        fontFamily: 'Sarala',
                                        fontSize: '20px'
                                    }}
                                >Causante</label>
                                <Dropdown
                                    style={{
                                        overflow: 'visible'
                                    }}
                                    value={{
                                        code: Props.FormValues.Causante ? Props.FormValues.Causante.PKID : 0,
                                        name: Props.FormValues.Causante ? Props.FormValues.Causante.Nombre : ''
                                    }}
                                    options={Props.ListaCausante}
                                    name="Causante"
                                    onChange={Props.HandleChange}
                                    optionLabel="name"
                                    placeholder="Seleccione el causante" />
                                {Props.Errores.Causante && <small className="p-invalid p-d-block">{Props.Errores.Causante}</small>}
                            </div>
                        </div>
                    </div>
                    <div className="p-col">
                        <div className="p-grid p-fluid ">
                            <div className="p-col-6">
                                <Checkbox
                                    inputId="binary"        
                                    checked={Props.FormValues.EstadoFijo}
                                    onChange={Props.HandleChange}
                                    name="EstadoFijo" />
                                <label
                                    className=""
                                    style={{
                                        color: '#4D80E4',
                                        fontFamily: 'Sarala',
                                        fontSize: '20px'
                                    }}
                                >      Estado Fijo</label>
                                {Props.Errores.EstadoFijo && <small className="p-invalid p-d-block">{Props.Errores.EstadoFijo}</small>}
                            </div>
                            <div className="p-col-6">
                                <Checkbox
                                    inputId="binary"
                                    checked={Props.FormValues.TieneOCS}
                                    onChange={Props.HandleChange}
                                    name="TieneOCS" />
                                <label
                                    className=""
                                    style={{
                                        color: '#4D80E4',
                                        fontFamily: 'Sarala',
                                        fontSize: '20px'
                                    }}
                                >      Tiene OCS</label>
                                {Props.Errores.TieneOCS && <small className="p-invalid p-d-block">{Props.Errores.TieneOCS}</small>}
                            </div>
                        </div>
                    </div>
                    {Props.FormValues.PKID > 0 ?
                        <div className="p-col">
                            <div className="p-grid p-fluid ">
                                <div className="p-col-12">
                                    <label
                                        className="p-d-block"
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'
                                        }}
                                    >Estado</label>
                                    <Dropdown
                                        value={{
                                            code: Props.FormValues.Estado.PKID,
                                            name: Props.FormValues.Estado.Descripcion
                                        }}
                                        options={Props.ListaEstado}
                                        onChange={Props.HandleChange}
                                        name="Estado"
                                        optionLabel="name"
                                        placeholder="Seleccione el Estado" />
                                    {Props.Errores.Estado && <small className="p-invalid p-d-block">{Props.Errores.Estado}</small>}
                                </div>
                            </div>
                        </div>
                        :
                        <></>
                    }
                </div>
            </div>
            <div className="p-grid nested-grid" style={{ overflow: 'visible' }}>
                <div className="list p-shadow-3">
                    <div className="p-col-12 p-lg-12">
                        <div className="p-col">
                            <div className="p-grid p-fluid ">
                                <div className="p-col-12">
                                    <label
                                        className="p-d-block"
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'
                                        }}
                                    >Tareas</label>
                                    <DataTable
                                        value={Props.FormValues.Tareas}
                                        rows={5}
                                        paginator
                                        reorderableColumns
                                        //onRowReorder={Props.RowReorder}                             
                                        rowsPerPageOptions={[5, 8, 10]}
                                        className="p-datatable-sm"
                                        columnResizeMode="fit">
                                        {/** 
                                    <Column rowReorder style={{ width: '5%', cursor: 'grab' }}></Column>*/}
                                        <Column field="Nombre" header="Nombre" style={{ width: '40%', wordBreak: "break-all" }} ></Column>
                                        <Column field="Empleado" header="Empleado" style={{ width: '24%', left: 'true' }}></Column>
                                        <Column field="Sprint" header="Sprint" style={{ width: '30%' }}></Column>
                                        <Column field="HorasEjecutadas" header="Horas" style={{ width: '6%', left: 'true' }}></Column>
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}