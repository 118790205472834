import React from 'react'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'

interface IProps{
    Modal: boolean
    FormValues: any    
    HideModal: any
    HandleChange: any
    ErrorMotivoVersionamiento: any
    HandleSelectRow: any
    Request: boolean
}

export default function MotivoVersionamientoModal(Props : IProps){

    const header = (
        <div className="p-d-flex p-ml-auto">
            
        </div>
    )

    const RenderFooter = () =>{
        return (
            <Button 
                type="submit"
                label={Props.Request ? "Cargando..." : "Aceptar"}
                style={{
                    background:'#4D80E4',
                    border: '1px solid #4D80E4'}}
                icon={Props.Request ? "" : "pi pi-check" } 
                className="p-ml-auto"
                disabled={Props.Request}
                autoFocus/>
        )
    }

    return(
        <Dialog
            header='Motivo de Versionamiento'
            visible={Props.Modal}
            style={{ width: '110vh' }}
            footer={RenderFooter()} 
            onHide={Props.HideModal}>
                <div className="p-grid nested-grid">
                    <div className= {Props.FormValues.PKID > 0 ? "p-col-12 p-lg-6" : "p-col-12"}>
                        <div className="p-col">
                            <div className="p-grid p-fluid ">
                                <div className="p-col-12">
                                    <label 
                                        className="p-d-block"
                                        style={{
                                            color : '#4D80E4',
                                            fontFamily : 'Sarala',
                                            fontSize : '20px'}}>
                                    Descripción</label>
                                    <InputText 
                                        value={Props.FormValues.Descripcion}
                                        onChange={Props.HandleChange}
                                        name="Descripcion"
                                        type="text"/>
                                    {Props.ErrorMotivoVersionamiento.Descripcion && <small className="p-invalid p-d-block">{Props.ErrorMotivoVersionamiento.Descripcion}</small>}
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
               
        </Dialog>
    )
}