import React from 'react'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { Calendar } from 'primereact/calendar'

interface IProps{
    Modal: boolean
    HideModal: any
    HandleChange: any
    FormValues: any
    ListaTickets: Array<object>
    ListaClientes: Array<object>
    ListaEmpleados: Array<object>
    ListaProductos: Array<object>
    Errores: any
    Request: boolean
}

export default function SolicitudModal(Props: IProps){
        
    const RenderFooter = () =>{
        return (
            <Button 
                type="submit"
                label={Props.Request ? "Cargando..." : "Aceptar"}
                style={{
                    background:'#4D80E4',
                    border: '1px solid #4D80E4'}}
                icon={Props.Request ? "" : "pi pi-check" } 
                className="p-ml-auto"
                disabled={Props.Request}
                autoFocus/>
        )
    }

    return(
        <Dialog
            header='Solicitud'
            visible={Props.Modal}
            style={{ width: '110vh' }}
            footer={RenderFooter()} 
            onHide={() => Props.HideModal(null, false)}>
                <div className="p-grid nested-grid">
                    <div className="p-col-12 p-lg-6">
                        <div className="p-col">
                            <div className="p-grid p-fluid p-mr-1 p-ml-1">
                                <div className="p-col-12">
                                    <label className="p-d-block" 
                                        style={{
                                            color : '#4D80E4',
                                            fontFamily : 'Sarala',
                                            fontSize : '20px'}}>
                                    Ticket Asociado</label>
                                    <Dropdown 
                                        value={{
                                            code: Props.FormValues.TicketAsociado? Props.FormValues.TicketAsociado.PKID : 0,
                                            name: Props.FormValues.TicketAsociado? Props.FormValues.TicketAsociado.Nombre : ''}}
                                        options = {Props.ListaTickets}
                                        onChange = {Props.HandleChange}
                                        optionLabel = "name"
                                        filter showClear filterBy="name"
                                        name = "Ticket"
                                        placeholder = "Seleccione Ticket"/>
                                </div>
                                <div className="p-col-12">
                                    <div className="p-grid nested-grid">
                                        <div className="p-col-6">
                                            <label className="p-d-block" 
                                                style={{
                                                    color : '#4D80E4',
                                                    fontFamily : 'Sarala',
                                                    fontSize : '20px'}}>
                                            Numero</label>
                                            <InputText 
                                                name="Numero"
                                                disabled
                                                value={Props.FormValues.Numero}
                                                type="text" />
                                        </div>
                                        <div className="p-col-6">
                                            <label className="p-d-block" 
                                                style={{
                                                    color : '#4D80E4',
                                                    fontFamily : 'Sarala',
                                                    fontSize : '20px'}}>
                                            Fecha de Creación</label>
                                            <Calendar 
                                                value={new Date(Props.FormValues.FechaCreacion)}                                                        
                                                onChange={Props.HandleChange}
                                                disabled
                                                name="FechaCreacion"
                                                dateFormat="dd/mm/yy"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-col-12">
                                    <label className="p-d-block" 
                                        style={{
                                            color : '#4D80E4',
                                            fontFamily : 'Sarala',
                                            fontSize : '20px'}}>
                                    Nombre</label>
                                    <InputText
                                        name="Nombre"
                                        onChange={Props.HandleChange}
                                        value={Props.FormValues.Nombre}
                                        type="text"/>
                                    {Props.Errores.Nombre && <small className="p-invalid p-d-block">{Props.Errores.Nombre}</small>}
                                </div>
                                <div className="p-col-12">
                                    <label className="p-d-block" 
                                        style={{
                                            color : '#4D80E4',
                                            fontFamily : 'Sarala',
                                            fontSize : '20px'}}>
                                    Detalle</label>
                                    <InputTextarea
                                        name="Detalle"
                                        onChange={Props.HandleChange}
                                        value={Props.FormValues.Detalle}
                                        type="text" 
                                        rows={5} />
                                    {Props.Errores.Detalle && <small className="p-invalid p-d-block">{Props.Errores.Detalle}</small>}
                                </div>
                                <div className="p-col-12">
                                    <label className="p-d-block" 
                                        style={{
                                            color : '#4D80E4',
                                            fontFamily : 'Sarala',
                                            fontSize : '20px'}}>
                                    Cliente</label>
                                    <Dropdown 
                                        value={{
                                            code: Props.FormValues.Cliente.PKID,
                                            name: Props.FormValues.Cliente.Nombre}}
                                        options = {Props.ListaClientes}
                                        onChange = {Props.HandleChange}
                                        //disabled = {Props.FormValues.TicketAsociado ? (Props.FormValues.TicketAsociado.PKID > 0 ? false : true) : true}
                                        name = "Cliente"
                                        optionLabel = "name"
                                        placeholder = "Seleccione Empresa"/>
                                    {Props.Errores.Cliente && <small className="p-invalid p-d-block">{Props.Errores.Cliente}</small>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-col-12 p-lg-6">
                        <div className="p-col">
                            <div className="p-grid p-fluid p-mr-1 p-ml-1">
                                <div className="p-col-12">
                                    <label className="p-d-block" 
                                        style={{
                                            color : '#4D80E4',
                                            fontFamily : 'Sarala',
                                            fontSize : '20px'}}>
                                    Nombre Solicitante</label>
                                    <InputText
                                        name="NombreSolicitante"
                                        onChange={Props.HandleChange}
                                        value={Props.FormValues.NombreSolicitante}
                                        type="text"/>
                                </div>
                                <div className="p-col-12">
                                    <label className="p-d-block" 
                                        style={{
                                            color : '#4D80E4',
                                            fontFamily : 'Sarala',
                                            fontSize : '20px'}}>
                                    Cargo Solicitante</label>
                                    <InputText
                                        name = "CargoSolicitante"
                                        onChange = {Props.HandleChange}
                                        value = {Props.FormValues.CargoSolicitante}
                                        type = "text" />
                                </div>
                                <div className="p-col-12">
                                    <label className="p-d-block" 
                                        style={{
                                            color : '#4D80E4',
                                            fontFamily : 'Sarala',
                                            fontSize : '20px'}}>
                                    Area Solicitante</label>
                                    <InputText
                                        name = "AreaSolicitante"
                                        onChange = {Props.HandleChange}
                                        value = {Props.FormValues.AreaSolicitante}
                                        type = "text" />
                                </div>
                                <div className="p-col-12">
                                    <label className="p-d-block" 
                                        style={{
                                            color : '#4D80E4',
                                            fontFamily : 'Sarala',
                                            fontSize : '20px'}}>
                                    Consultor</label>
                                    <Dropdown 
                                        value={{
                                            code: Props.FormValues.Empleado.PKID,
                                            name: Props.FormValues.Empleado.Nombre}}
                                        options = {Props.ListaEmpleados}
                                        onChange = {Props.HandleChange}
                                        //disabled = {Props.FormValues.TicketAsociado ? (Props.FormValues.TicketAsociado.PKID > 0 ? false : true) : true}
                                        name = "Empleado"
                                        optionLabel = "name"
                                        placeholder = "Seleccione Consultor"/>
                                    {Props.Errores.Empleado && <small className="p-invalid p-d-block">{Props.Errores.Empleado}</small>}
                                </div>
                                <div className="p-col-12">
                                    <label className="p-d-block" 
                                        style={{
                                                color : '#4D80E4',
                                                fontFamily : 'Sarala',
                                                fontSize : '20px'}}>
                                        Tipo Solicitud</label>
                                        <InputText
                                            name="TipoSolicitud"
                                            disabled
                                            onChange = {Props.HandleChange}
                                            value = {Props.FormValues.TipoSolicitud.Nombre}
                                            type = "text" />
                                    </div>
                                    <div className="p-col-12 p-lg-6">
                                        <label className="p-d-block" 
                                            style={{
                                                color: '#4D80E4',
                                                fontFamily : 'Sarala',
                                                fontSize : '20px'}}>
                                        Producto</label>
                                <Dropdown 
                                    value = {{
                                        code: Props.FormValues.Producto.PKID,
                                        name: Props.FormValues.Producto.Nombre,
                                        VersionProducto : Props.FormValues.Producto.VersionProducto}}
                                    options = {Props.ListaProductos}
                                    onChange = {Props.HandleChange}
                                    optionLabel = "name"
                                    name = "Producto"
                                    placeholder = "Seleccione Producto"/>
                                {Props.Errores.Producto && <small className="p-invalid p-d-block">{Props.Errores.Producto}</small>}
                            </div>
                            <div className="p-col-12 p-lg-6">
                                <label className="p-d-block" 
                                    style={{
                                        color: '#4D80E4',
                                        fontFamily : 'Sarala',
                                        fontSize : '20px'}}>Versión</label>
                                <Dropdown 
                                    value={{
                                        code: Props.FormValues.VersionProducto.PKID,
                                        name: Props.FormValues.VersionProducto.Version}}
                                    options={Props.FormValues.Producto.VersionProducto}
                                    onChange={Props.HandleChange}
                                    optionLabel="name"
                                    name = "VersionProducto"
                                    placeholder="Seleccione Versión"/>
                                {Props.Errores.VersionProducto && <small className="p-invalid p-d-block">{Props.Errores.VersionProducto}</small>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}