import React,{ useEffect,useCallback } from 'react'
import {setProyecto} from '../../Services/LocalStorage'
import useNearScreen from './useNearScreen'
import debounce from 'just-debounce-it'
import '../Assets/Css/DTProyecto.css'

interface IProps {
    Paginacion: any
    Pendiente: any
    EnCurso: any
    StandBy:any
    Hecho: any
    OnResponse: any
    MoverEstado: any
}

export default function ProyectoBody(Props : IProps){

    const RefPendiente = React.createRef<HTMLInputElement>()
    const RefEnCurso = React.createRef<HTMLInputElement>()
    const RefStandBy = React.createRef<HTMLInputElement>()
    const RefHecho = React.createRef<HTMLInputElement>()

    const NSPendiente = useNearScreen({
        externalRef :  !Props.Pendiente.Loading && RefPendiente,
        once: false
    }).isNearScreen

    const NSEnCurso = useNearScreen({
        externalRef : !Props.EnCurso.Loading && RefEnCurso,
        once: false
    }).isNearScreen

    const NSStandBy = useNearScreen({
        externalRef : !Props.StandBy.Loading && RefStandBy,
        once: false
    }).isNearScreen

    const NSHecho = useNearScreen({
        externalRef : !Props.Hecho.Loading && RefHecho,
        once: false
    }).isNearScreen

    const DebounceNextPage = useCallback((Estado : string) => {
        debounce(Props.Paginacion(Estado),5000)
    },[])

    useEffect(function (){
        if(NSPendiente) DebounceNextPage('Pendiente')
        if(NSEnCurso) DebounceNextPage('EnCurso')
        if(NSStandBy) DebounceNextPage('StandBy')
        if(NSHecho) DebounceNextPage('Hecho')
    })

    const OnRowSelect = (entidad: any) => {
        setProyecto(JSON.stringify(entidad))
        window.location.href = 'Proyecto/ItemProyecto'
    }

    const DragStart = (Event : any) => {
        Event.dataTransfer.setData('EstadoPKID',Event.target.id)
    }

    const DragOver = (Event : React.SyntheticEvent) => {
        Event.preventDefault()
        Event.stopPropagation()
    }

    const DropPendiente = (Event : React.DragEvent) => {
        Event.preventDefault()
        Props.MoverEstado(Event.dataTransfer.getData('EstadoPKID').split('-')[0],'Pendiente', Event.dataTransfer.getData('EstadoPKID').split('-')[1])
    }

    const DropEnCurso = (Event : React.DragEvent) => {
        Event.preventDefault()
        Props.MoverEstado(Event.dataTransfer.getData('EstadoPKID').split('-')[0],'EnCurso', Event.dataTransfer.getData('EstadoPKID').split('-')[1])
    }

    const DropStandBy = (Event : React.DragEvent) => {
        Event.preventDefault()
        Props.MoverEstado(Event.dataTransfer.getData('EstadoPKID').split('-')[0],'StandBy', Event.dataTransfer.getData('EstadoPKID').split('-')[1])
    }

    const DropHecho = (Event : React.DragEvent) => {
        Event.preventDefault()
        Props.MoverEstado(Event.dataTransfer.getData('EstadoPKID').split('-')[0],'Hecho', Event.dataTransfer.getData('EstadoPKID').split('-')[1])
    }

    const RenderListado = ( (Proyecto: any, Estado: string) => {
        let Lista = Proyecto.map((Entidad : any) => {
            return(
                <li  
                    id = {Estado + '-' + Entidad.PKID}
                    draggable
                    onDragStart = {DragStart}
                    onDragOver = {DragOver}
                    key = {Estado + '-' + Entidad.PKID}
                    onClick={()=> OnRowSelect(Entidad)}>
                    <div className="comment-main-level">
                        <div className="comment-box">
                            <div className="comment-head">
                                <h6 className="comment-name by-author">
                                    {Entidad.Nombre}
                                </h6>
                            </div>
                            <div className="comment-content">
                                <div>
                                    <table className="table table-bordered table-sm">
                                        <thead>
                                            <tr>
                                                <th scope="col">Horas Gerencia</th>
                                                <th scope="col">Horas Estimadas</th>
                                                <th scope="col">Horas Ejecutadas</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{parseFloat(Entidad.HorasGerencia).toFixed(2)}</td>
                                                <td>{parseFloat(Entidad.HorasEstimadas).toFixed(2)}</td>
                                                <td>{parseFloat(Entidad.HorasEjecutadas).toFixed(2)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            )
        })
        return Lista
    })

    return (
        <div className="ContainerTrello">
            <div className="p-grid" style={{margin:'0px'}} >
                <div className="p-col-12 p-md-6 p-lg-3">
                    <div className="list-wrapper">
                        <div className="list" style={{height:'82vh'}}>
                            <div className="list-header is-menu-shown">
                                <h2 className="list-header-name-assist">Pendiente</h2>
                                <div 
                                    className="open-card-composer" 
                                    onClick={() => Props.OnResponse(null, true)}>
                                    <i className="pi pi-plus icon-sm"></i>
                                    <span>Agregar BackLog</span>                                
                                </div>
                            </div>
                            <div 
                                className="list-cards"
                                onDrop={DropPendiente}
                                onDragOver={DragOver}>
                                <ul id="lPendiente" className="comment-list">
                                    {RenderListado(Props.Pendiente.Lista,'Pendiente')}
                                </ul>
                                <div ref={RefPendiente}>
                                    {((Props.Pendiente.Paginacion.NumeroPagina < Props.Pendiente.Paginacion.NumeroPaginas) || Props.Pendiente.Loading)? 
                                        <div className="circleLoad"></div> 
                                        : 
                                        <></>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-col-12 p-md-6 p-lg-3">
                    <div className="list-wrapper">
                        <div className="list" style={{height:'82vh'}}>
                            <div className="list-header is-menu-shown">
                                <h2 className="list-header-name-assist">En Curso</h2>
                            </div>
                            <div 
                                className="list-cards"
                                onDrop={DropEnCurso}
                                onDragOver={DragOver}>
                                <ul id="lEnCurso" className="comment-list">
                                    {RenderListado(Props.EnCurso.Lista,'EnCurso')}
                                </ul>
                                <div ref={RefEnCurso}>
                                    {((Props.EnCurso.Paginacion.NumeroPagina < Props.EnCurso.Paginacion.NumeroPaginas) || Props.EnCurso.Loading)? 
                                        <div className="circleLoad"></div> 
                                        : 
                                        <></>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="p-col-12 p-md-6 p-lg-3">
                    <div className="list-wrapper">
                        <div className="list" style={{height:'82vh'}}>
                            <div className="list-header is-menu-shown">
                                <h2 className="list-header-name-assist">StandBy</h2>
                            </div>
                            <div 
                                className="list-cards"
                                onDrop={DropStandBy}
                                onDragOver={DragOver}>
                                <ul id="lStandBy" className="comment-list">
                                    {RenderListado(Props.StandBy.Lista,'StandBy')}
                                </ul>
                                <div ref={RefStandBy}>
                                    {((Props.StandBy.Paginacion.NumeroPagina < Props.StandBy.Paginacion.NumeroPaginas) || Props.StandBy.Loading)? 
                                        <div className="circleLoad"></div> 
                                        : 
                                        <></>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="p-col-12 p-md-6 p-lg-3">
                    <div className="list-wrapper">
                        <div className="list" style={{height:'82vh'}}>
                            <div className="list-header is-menu-shown">
                                <h2 className="list-header-name-assist">Hecho</h2>
                            </div>
                            <div 
                                className="list-cards"
                                onDrop={DropHecho}
                                onDragOver={DragOver}>
                                <ul id="lHecho" className="comment-list">
                                    {RenderListado(Props.Hecho.Lista,'Hecho')}
                                </ul>
                                <div ref={RefHecho}>
                                    {((Props.Hecho.Paginacion.NumeroPagina < Props.Hecho.Paginacion.NumeroPaginas) || Props.Hecho.Loading)? 
                                        <div className="circleLoad"></div> 
                                        : 
                                        <></>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}