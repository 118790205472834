import React from 'react'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'

interface IProps{
    HandleChange: any
    FormValues: any
    ActualizarItemPlanDePrueba: any
    Errores: any
    Request: boolean
    OnChangeBuscador:any
}

export default function ItemPlanDePruebaHeader(Props: IProps){

    return(
        <div className="HeaderItemPlanDePrueba">
            <div className="p-col-12">
                <div className="CrumbBeetwen">
                    <div className="p-d-flex">
                        <ul className="breadcrumb">
                            <li><a href="/Desarrollo/Inicio">Inicio</a></li>
                            <li><a href="/Desarrollo/Calidad">Calidad</a></li>
                            <li><a href="/Desarrollo/Calidad/PlanDePrueba">Plan de prueba</a></li>
                            <li>Item plan de prueba</li>
                        </ul>
                        <div className="BtnFiltro p-ml-auto">
                            <div className="p-d-flex">
                            <div className="p-mr-2">
                                <label
                                    style={{
                                        color: '#4D80E4',
                                        fontFamily: 'Sarala',
                                        fontSize: '20px'
                                    }}
                                >Buscador </label>
                                <InputText
                                    //value={Props.FormBuscador.Buscador}
                                    onChange={Props.OnChangeBuscador}
                                    name="Buscador"
                                    type="Text"
                                />
                            </div>
                                <div className="p-mr-2">
                                    <Button 
                                        label="Detalle" 
                                        className="p-button-rounded p-button-info p-mx-auto"
                                        onClick={ () =>{
                                            (document.getElementById('PanelSprint') as HTMLInputElement).classList.add('active');
                                            (document.getElementById('PopupSprint') as HTMLInputElement).classList.add('active');
                                        }}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="PanelSprint" className="OverlaySprint">
                <div id="PopupSprint" className="PopupSprint">
                    <div className="HeaderPopup">
                        <div className="p-d-flex p-jc-between">
                            <div className="Title">Item Plan de Prueba</div>
                            <div className="btn-cerrar-popupsprint" onClick={() => 
                            {
                                (document.getElementById('PanelSprint') as HTMLInputElement).classList.remove('active');
                                (document.getElementById('PopupSprint') as HTMLInputElement).classList.remove('active');
                            }}
                            ><i className="fas fa-times"></i></div>
                        </div>
                    </div>
                    <form autoComplete="off" className="BodySprint">
                        <div className="p-grid">
                            <div className="p-col-12">
                                <div className="p-grid p-fluid">
                                    <div className="p-col">
                                        <label 
                                            style={{
                                                color: '#4D80E4',
                                                fontFamily: 'Sarala',
                                                fontSize: '19px'}}>
                                        Nombre</label>
                                        <InputText 
                                            name="Nombre"
                                            onChange={Props.HandleChange}
                                            value={Props.FormValues.Nombre}
                                            type="text" />
                                        {Props.Errores.Nombre && <small className="p-invalid p-d-block">{Props.Errores.Nombre}</small>}
                                    </div>
                                </div>
                            </div>
                            { Props.FormValues.Requerimiento !== null && Props.FormValues.Requerimiento !== undefined ?
                            <div className="p-col-12">
                                <div className="p-grid p-fluid">
                                    <div className="p-col">
                                        <label 
                                            style={{
                                                color: '#4D80E4',
                                                fontFamily: 'Sarala',
                                                fontSize: '19px'}}>
                                        Nombre del Requerimiento</label>
                                        <InputText 
                                            name="Requerimiento"
                                            value={Props.FormValues.Requerimiento.Requisito}
                                            onChange={Props.HandleChange}
                                            type="text"/>
                                        {Props.Errores.Requerimiento && <small className="p-invalid p-d-block">{Props.Errores.Requerimiento}</small>}
                                    </div>
                                </div>
                            </div> : null }
                            <div className="p-col-12">
                                <div className="p-col-12">
                                    <div className="p-d-flex footer p-ml-auto">
                                        <Button 
                                            type="button"
                                            label={Props.Request ? "Cargando..." : "Actualizar"}
                                            style={{
                                                background:'#4D80E4',
                                                border: '1px solid #4D80E4'
                                            }}
                                            icon={Props.Request ? "" : "pi pi-check" } 
                                            onClick={(e) => Props.ActualizarItemPlanDePrueba(e)}
                                            className="p-ml-auto"
                                            disabled={Props.Request}
                                            autoFocus/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}