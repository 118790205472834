import React from 'react'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { InputTextarea } from 'primereact/inputtextarea'

interface IProps{
    Modal: boolean
    HideModal: any
    FormValues: any
    HandleChange: any
    Errores: any
}

export default function CasoPruebaModalPaso(Props: IProps){

    const RenderFooter = () =>{
        return (
            <Button 
                type="submit"
                label="Aceptar" 
                style={{
                    background:'#4D80E4',
                    border: '1px solid #4D80E4'}}
                icon="pi pi-check" 
                className="p-ml-auto"
                autoFocus />
        )
    }

    return(
        <Dialog
            header='Paso'
            visible={Props.Modal}
            style={{ width: '110vh' }}
            footer={RenderFooter()} 
            onHide={() => Props.HideModal(null, false)}>
                <div className="p-grid nested-grid">
                    <div className="p-col-12 p-lg-3">
                        <div className="p-col">
                            <div className="p-grid p-fluid">
                                <div className="p-col-12">
                                    <label className="p-d-block" 
                                        style={{
                                            color : '#4D80E4',
                                            fontFamily : 'Sarala',
                                            fontSize : '20px'}}>
                                    Numero</label>
                                    <InputText
                                        name="NumeroPaso"    
                                        disabled                                    
                                        value={Props.FormValues.NumeroPaso}                                        
                                        type="text" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-col-12 p-lg-9">
                        <div className="p-col">
                            <div className="p-grid p-fluid">
                                <div className="p-col-12">
                                    <label className="p-d-block" 
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'}}>
                                    Acción</label>
                                    <InputText
                                        name="Accion"
                                        onChange={Props.HandleChange}
                                        value={Props.FormValues.Accion}
                                        type="text" />
                                    {Props.Errores.Accion && <small className="p-invalid p-d-block">{Props.Errores.Accion}</small>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-col-12">
                        <div className="p-col">
                            <div className="p-grid p-fluid">
                                <div className="p-col-12">
                                    <label className="p-d-block" 
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'}}>
                                    Resultado Esperado</label>
                                    <InputTextarea
                                        name="ResultadoEsperado"
                                        onChange={Props.HandleChange}
                                        value={Props.FormValues.ResultadoEsperado}
                                        type="text"
                                        rows={4} />
                                    {Props.Errores.ResultadoEsperado && <small className="p-invalid p-d-block">{Props.Errores.ResultadoEsperado}</small>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </Dialog>
    )
}
