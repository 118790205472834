import React from 'react'
import PrioridadBody from '../Components/PrioridadBody'
import PrioridadModal from '../Components/PrioridadModal'
import { PrioridadService } from '../../Domain/Services/PrioridadService'

import { IPrioridad } from '../../Domain/Models/IPrioridad'

interface IProps {
}

interface IState {
    Paginado: any
    Registros: Array<object>
    ModalPrioridad: boolean
    Prioridad : IPrioridad    
    ListaCliente: Array<object>
    ListaPrioridad: Array<object>
    ErrorPrioridad: any    
    Request: boolean
}

export default class Prioridad extends React.Component<IProps, IState> {

    cPaginado = {
        NumeroPagina : 1,
        RegistrosPagina : 10,
        NumeroPaginas : 0,
        fil_Nombre : '',
        fil_Modulo : '',
        fil_Responsable : ''
    }

    cRegistro = [
        { RegistrosPagina: 10 },
        { RegistrosPagina: 20 },
        { RegistrosPagina: 30 }
    ]

    cPrioridad = {
        PKID : 0,
        Descripcion : ''       
    }

    

    constructor(props: any){
        super(props)
        this.state = {
            Paginado : this.cPaginado,
            Registros : this.cRegistro,
            ModalPrioridad : false,
            Prioridad : this.cPrioridad,  
            ListaCliente : [],
            ListaPrioridad : [],
            ErrorPrioridad : {},            
            Request: false
        }
    }

    componentDidMount(){
        this.ListarPaginacion()
    }

    ListarPaginacion(){
        PrioridadService.ListarPaginacion(this.state.Paginado)
        .then((response: any) => {
            if(response.success){
                this.setState({
                    Paginado: JSON.parse(response.data).Paginacion,
                    ListaPrioridad: JSON.parse(response.data).Prioridad
                })
            }
        })
        .catch((Error: any) => {
            alert(Error)
        })
    }

    OnResponse = (Prioridad: any) => {
        if(Prioridad){
            this.setState({ 
                Prioridad : Prioridad,
                ErrorPrioridad: {}
            },() => this.HideVisibleModalPrioridad(true))
        }else{
            this.setState({ 
                Prioridad : this.cPrioridad,
                ErrorPrioridad: {}
            },() => this.HideVisibleModalPrioridad(true))
        }
    }

    HideVisibleModalPrioridad = (Mode: boolean) => {
        this.setState({
            ModalPrioridad: Mode
        })
    }

    HandleChangePrioridad = (Event: any) =>{
        this.setState({
            Prioridad: {
                ...this.state.Prioridad,
                [Event.target.name]: Event.target.value
            }
        })
    }

    HandleSubmitPrioridad = (Event: any) =>{
        Event.preventDefault()
        const result = PrioridadService.ValidarPrioridad(this.state.Prioridad);
        if(Object.keys(result).length){
            return this.setState({ErrorPrioridad : result});
        }else{
            this.setState({
                Request: true
            }, () => this.CrearActualizarPrioridad())
        }
    }

    CrearActualizarPrioridad(){
        PrioridadService.CrearActualizar(this.state.Prioridad)
        .then((response: any) => {
            if(response.success){
                var json = JSON.parse(response.data)
                if(json.PKID){
                    this.setState({
                        ModalPrioridad : !this.state.ModalPrioridad,
                        Prioridad : this.cPrioridad,
                        Paginado: this.cPaginado
                    },() => this.ListarPaginacion())
                }
            }else{
                alert(response.data)
            }
        })
        .catch((Error) => {
            alert(Error)
        })
        .finally(() => {
            this.setState({
                Request: false
            })
        })
    }

    HandleSelectRow = (Prioridad: IPrioridad) => {
        this.setState({ 
            Prioridad : Prioridad,
            ErrorPrioridad : {}
        },() => this.HideVisibleModalPrioridad(true))
    }

    

  

   

    OrdenarArray = (Array: Array<any>) => {
        return Array.sort(function (a, b) {
            if (a.Orden > b.Orden) return -1
            if (a.Orden < b.Orden) return 1
            return 0;
        })
    }

    

    OnRegistroChange = (Event: any) =>{
        this.setState({
            Paginado : {
                ...this.state.Paginado,
                NumeroPagina: 1,
                RegistrosPagina: Event.value.RegistrosPagina,
        }},() =>{ this.ListarPaginacion() })
    }

    HandlePreviousLL = (Event: any) =>{
        this.setState({
            Paginado : this.cPaginado
        },() =>{ this.ListarPaginacion() })
    }

    HandlePrevious = (Event: any) =>{
        this.setState({Paginado : {
            ...this.state.Paginado,
            NumeroPagina: this.state.Paginado.NumeroPagina - 1,
        }},() =>{ this.ListarPaginacion() })
    }

    HandleFollowing = () => {
        this.setState({Paginado : {
            ...this.state.Paginado,
            NumeroPagina: this.state.Paginado.NumeroPagina + 1,
        }},() =>{ this.ListarPaginacion() })
    }

    HandleFollowingLL = () => {
        this.setState({Paginado : {
            NumeroPagina: this.state.Paginado.NumeroPaginas,
            RegistrosPagina: this.state.Paginado.RegistrosPagina,
            NumeroPaginas: this.state.Paginado.NumeroPaginas,
            fil_Nombre : this.state.Paginado.fil_Nombre
        }},() =>{ this.ListarPaginacion() })
    }

    render(){
        return(
            <div className="container">
                <PrioridadBody
                    ListaPrioridad={this.state.ListaPrioridad}
                    OnResponse={this.OnResponse}
                    Paginado={this.state.Paginado}
                    Registros= {this.state.Registros}
                    OnRegistroChange = {this.OnRegistroChange}
                    HandlePreviousLL = {this.HandlePreviousLL}
                    HandlePrevious = {this.HandlePrevious}
                    HandleFollowing = {this.HandleFollowing}
                    HandleFollowingLL = {this.HandleFollowingLL}/>
                    <form autoComplete="Off" onSubmit={this.HandleSubmitPrioridad}>
                        <PrioridadModal
                            Modal = {this.state.ModalPrioridad}
                            FormValues = {this.state.Prioridad}                            
                            HideModal = {this.HideVisibleModalPrioridad}
                            HandleChange = {this.HandleChangePrioridad}
                            ErrorPrioridad = {this.state.ErrorPrioridad}
                            HandleSelectRow={this.HandleSelectRow}
                            Request={this.state.Request}/>
                    </form>
                    
            </div>
        )
    }
}