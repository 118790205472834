import React from 'react'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { Calendar } from 'primereact/calendar'
import { Dropdown } from 'primereact/dropdown'

interface IProps{
    Modal: boolean
    HandleChange: any
    HideModal: any
    FormValues: any
    ListaRequerimientos: Array<object>
    Errores: any
    Request: boolean
}

export default function ItemPlanDePruebasModal(Props: IProps){

    const RenderFooter = () =>{
        return (
            <Button 
                type="submit"
                label={Props.Request ? "Cargando..." : "Aceptar"}
                style={{
                    background:'#4D80E4',
                    border: '1px solid #4D80E4'}}
                icon={Props.Request ? "" : "pi pi-check" } 
                className="p-ml-auto"
                disabled={Props.Request}
                autoFocus/>
        )
    }

    return(
        <Dialog
            header='Item Plan de Pruebas'
            visible={Props.Modal}
            style={{ width: '110vh' }}
            footer={RenderFooter()}
            onHide={Props.HideModal}>
                <div className="p-grid nested-grid">
                    <div className="p-col-12">
                        <div className="p-grid p-fluid p-mr-1 p-ml-1">
                            <div className="p-col-12">
                                <label className="p-d-block" 
                                    style={{
                                        color: '#4D80E4',
                                        fontFamily: 'Sarala',
                                        fontSize: '20px'}}>
                                Nombre</label>
                                <InputText
                                    name="Nombre"
                                    onChange={Props.HandleChange}
                                    value={Props.FormValues.Nombre}
                                    type="text" />
                                {Props.Errores.Nombre && <small className="p-invalid p-d-block">{Props.Errores.Nombre}</small>}
                            </div>
                        </div>
                    </div>
                    <div className="p-col-12">
                        <div className="p-grid p-fluid p-mr-1 p-ml-1">
                            <div className="p-col-12">
                                <label className="p-d-block" 
                                    style={{
                                        color : '#4D80E4',
                                        fontFamily : 'Sarala',
                                        fontSize : '20px'}}>
                                Fecha de Creacion</label>
                                <Calendar 
                                    value={new Date(Props.FormValues.FechaCreacion)}                                                       
                                    disabled
                                    dateFormat="dd/mm/yy"/>
                            </div>
                        </div>
                    </div>
                    <div className="p-col-12">
                        <div className="p-grid p-fluid p-mr-1 p-ml-1">
                            <div className="p-col-12">
                                <label 
                                    className="p-d-block" 
                                    style={{
                                        color : '#4D80E4',
                                        fontFamily : 'Sarala',
                                        fontSize : '20px'}}>Requerimiento</label>
                                {
                                    (Props.FormValues.Requerimiento!==null && Props.FormValues.Requerimiento!==undefined) ?
                                        <Dropdown 
                                            value = {{
                                                code: Props.FormValues.Requerimiento.PKID,
                                                name: Props.FormValues.Requerimiento.Requisito}}
                                            options = {Props.ListaRequerimientos}
                                            onChange = {Props.HandleChange}
                                            optionLabel = "name"
                                            name = "Requerimiento"
                                            placeholder = "Seleccione Requerimiento Asociado"/>
                                    :
                                    <div></div>
                                }
                                {Props.Errores.Requerimiento && <small className="p-invalid p-d-block">{Props.Errores.Requerimiento}</small>}
                            </div>
                        </div>
                    </div>
                </div>
        </Dialog>
    )
}