import { HTTP } from '../Http/Http'

export const SolicitudRepository = {
    Listar: async() => {
        return await HTTP.POST('Solicitud/ListarSolicitudes','',{})
    },
    ListarPaginacion: async(Paginacion: any) =>{
        return await HTTP.POST('Solicitud/ListarPaginacion',Paginacion,{})
    },
    CrearActualizar: async(Solicitud: any) => {
        return await HTTP.POST('Solicitud/CrearActualizar', Solicitud, {})
    }
}