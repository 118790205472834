import React, {useState} from 'react'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { Calendar } from 'primereact/calendar'
import { Dropdown } from 'primereact/dropdown'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'

interface IProps{
    Modal: boolean
    HideModal: any
    ItemPlanDePrueba: any
    ProyectoReferencia: any
    ProductoReferencia: any
    VersionProductoReferencia: any
    HandleChange: any
    PlanPruebaReferencia: any
    ItemsSeleccionados: any
    SeleccionarCasoPrueba: any
    ListaProducto: Array<object>
    ListaProyecto: Array<object>
    ListaPlanesPrueba: Array<object>
    ListaCasoPruebaRegresion: Array<object>
    Errores: any
    Request: boolean
}

export default function ItemPlanDePruebasRegresionModal(Props: IProps){

    const [ Filtro, setFiltro ] = useState(null)

    const header = (
        <div 
            className="table-header" 
            style={{
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'space-between'}}>
            <div style={{width:'15%'}}></div>
            <span className="p-input-icon-left" style={{width:'30%'}}>
                <i className="pi pi-search" />
                <InputText 
                    type="search" 
                    onInput={(Event: any) => setFiltro(Event.target.value)} 
                    placeholder="Buscar..." />
            </span>
        </div>
    )

    const RenderFooter = () =>{
        return (
            <Button 
                type="submit"
                label={Props.Request ? "Cargando..." : "Aceptar"}
                style={{
                    background:'#4D80E4',
                    border: '1px solid #4D80E4'}}
                icon={Props.Request ? "" : "pi pi-check" } 
                className="p-ml-auto"
                disabled={Props.Request}
                autoFocus/>
        )
    }
    
    return(
        <Dialog
            header='Item Plan de Pruebas De Regresión'
            visible={Props.Modal}
            style={{ width: '110vh' }}
            footer={RenderFooter()} 
            onHide={Props.HideModal}>
                <div className="p-grid nested-grid">
                    <div className="p-col-12">
                        <div className="p-grid p-fluid p-mr-1 p-ml-1">
                            <div className="p-col-6">
                                <label className="p-d-block" 
                                    style={{
                                        color: '#4D80E4',
                                        fontFamily: 'Sarala',
                                        fontSize: '20px'}}>
                                Producto</label>
                                <Dropdown 
                                    value = {{
                                        code: Props.ProductoReferencia.PKID,
                                        name: Props.ProductoReferencia.Nombre,
                                        VersionProducto: Props.ProductoReferencia.VersionProducto
                                    }}
                                    options = {Props.ListaProducto}
                                    onChange = {Props.HandleChange}
                                    optionLabel = "name"
                                    name = "Producto"
                                    placeholder = "Seleccione Producto"/>
                            </div>
                            <div className="p-col-6">
                                <label className="p-d-block" 
                                    style={{
                                        color: '#4D80E4',
                                        fontFamily: 'Sarala',
                                        fontSize: '20px'}}>
                                Versión</label>
                                <Dropdown 
                                    value={{
                                        code: Props.VersionProductoReferencia.PKID,
                                        name: Props.VersionProductoReferencia.Version
                                    }}
                                    options={Props.ProductoReferencia.VersionProducto}
                                    onChange={Props.HandleChange}
                                    name="VersionProducto"
                                    optionLabel="name"
                                    placeholder="Seleccione Versión del producto"/>
                            </div>
                        </div>
                    </div>
                    <div className="p-col-12">
                        <div className="p-grid p-fluid p-mr-1 p-ml-1">
                            <div className="p-col-6">
                                <label className="p-d-block" 
                                    style={{
                                        color: '#4D80E4',
                                        fontFamily: 'Sarala',
                                        fontSize: '20px'}}>
                                    Proyecto</label>
                                <Dropdown 
                                    value={{
                                        code: Props.ProyectoReferencia.PKID,
                                        name: Props.ProyectoReferencia.Nombre}}
                                    options={Props.ListaProyecto}
                                    onChange={Props.HandleChange}
                                    name="Proyecto"
                                    optionLabel="name"
                                    placeholder="Seleccione Proyecto de Referencia"/>
                            </div>
                            <div className="p-col-6">
                                <label className="p-d-block" 
                                    style={{
                                        color : '#4D80E4',
                                        fontFamily : 'Sarala',
                                        fontSize : '20px'}}>
                                Plan de Prueba</label>
                                <Dropdown 
                                    value={{
                                        code: Props.PlanPruebaReferencia.PKID,
                                        name: Props.PlanPruebaReferencia.Nombre}}
                                    options={Props.ListaPlanesPrueba}
                                    onChange={Props.HandleChange}
                                    name="PlanPrueba"
                                    optionLabel="name"
                                    placeholder="Seleccione Plan de Prueba de Referencia"/>
                            </div>
                        </div>
                    </div>
                    <div className="p-col-12">
                        <div className="p-grid p-fluid p-mr-1 p-ml-1">
                            <div className="p-col-12">
                                <DataTable  
                                    value={Props.ListaCasoPruebaRegresion} 
                                    rowHover 
                                    selection={Props.ItemsSeleccionados}
                                    onSelectionChange={(Event: any) =>{Props.SeleccionarCasoPrueba(Event.value)}}
                                    header={header}
                                    globalFilter={Filtro}
                                    dataKey="PKID" 
                                    rows={10}
                                    paginator
                                    rowsPerPageOptions={[10,25,50]}>
                                        <Column selectionMode="multiple" style={{width:'3em'}}/>
                                        <Column field="Nombre" header="Nombre"></Column>
                                        <Column field="Modulo.Nombre" header="Modulo"></Column>
                                        <Column field="TipoPrueba.Nombre" header="Tipo Prueba"></Column>
                                        <Column field="FechaCreacion" header="Fecha de Creación"></Column>
                                        <Column field="Empleado.Nombre" header="Responsable"></Column>
                                        <Column field="Version" header="Version"></Column>
                                </DataTable>
                            </div>
                        </div>
                    </div>
                    <div className="p-col-12" style={{border:'1px solid #4D80E4'}}>
                        <div className="p-grid nested-grid">
                            <div className="p-col-12 p-lg-6">
                                <div className="p-grid p-fluid p-mr-1 p-ml-1">
                                    <div className="p-col-12">
                                        <label className="p-d-block" 
                                            style={{
                                                color : '#4D80E4',
                                                fontFamily : 'Sarala',
                                                fontSize : '20px'}}>Nombre</label>
                                        <InputText 
                                            name="Nombre"
                                            onChange={Props.HandleChange}
                                            value={Props.ItemPlanDePrueba.Nombre}
                                            type="text" />
                                        {Props.Errores.Nombre && <small className="p-invalid p-d-block">{Props.Errores.Nombre}</small>}
                                    </div>
                                </div>
                            </div>
                            <div className="p-col-12 p-lg-6">
                                <div className="p-grid p-fluid p-mr-1 p-ml-1">
                                    <div className="p-col-12">
                                        <label className="p-d-block" 
                                            style={{
                                                color : '#4D80E4',
                                                fontFamily : 'Sarala',
                                                fontSize : '20px'}}>
                                        Fecha de Creacion</label>
                                        <Calendar 
                                            value={new Date(Props.ItemPlanDePrueba.FechaCreacion)}                                                       
                                            disabled
                                            name="FechaCreacion"
                                            dateFormat="dd/mm/yy"/>
                                    </div>
                                </div>
                            </div>
                            <div className="p-col-12">
                                <div className="p-grid p-fluid p-mr-1 p-ml-1">
                                    <div className="p-col-12">
                                        <DataTable  
                                            value={Props.ItemPlanDePrueba.ListaCasoPrueba} 
                                            dataKey="id" 
                                            rows={10}
                                            paginator>
                                                <Column field="Nombre" header="Nombre"></Column>
                                                <Column field="Modulo.Nombre" header="Modulo"></Column>
                                                <Column field="TipoPrueba.Nombre" header="Tipo Prueba"></Column>
                                                <Column field="FechaCreacion" header="Fecha de Creación"></Column>
                                                <Column field="Empleado.Nombre" header="Responsable"></Column>
                                                <Column field="Version" header="Version"></Column>
                                        </DataTable>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </Dialog>
    )
}
