import React from 'react'
import { UsuarioService } from '../../Domain/Services/UsuarioService'
import { EmpleadoService } from '../../Domain/Services/EmpleadoService' 
import DTUsuarios from '../Components/Desarrollo.Seguridad.Usuario.DataTable'
import ModalUsuario from '../Components/Desarrollo.Seguridad.Usuario.Modal'
import { AuthHelper } from '../../Domain/Utils/AuthHelper'
import { IToken } from '../../Domain/Models/IToken'
import UsuarioHeader from '../Components/UsuarioHeader'

interface IProps {
    Token: IToken
}

interface IListaUsuario {
    PKID: Number,
    Credencial: String,
    Empleado: {
        PKID: Number,
        Codigo: String,
        Nombre: String,
        Area: {
            PKID: Number,
            Codigo: String,
            Nombre: String,
        },
    },
    IsNuevo: Boolean,
    Administrador: Boolean,
    AdministradorArea: Boolean,
}

interface IState {
    Modal: boolean
    ListaUsuario: IListaUsuario[]
    ListaUsuarioOriginal: IListaUsuario[]
    Usuario: any
    ListaEmpleado: Array<object>
    Errores: any
}

export default class SeguridadUsuario  extends React.Component<IProps, IState> {

    Validate = (values : any) =>{
        const errores : any = {}
        if(!values.Credencial){
            errores.Credencial  = 'Usuario es requerido';
        }
        if(!values.Empleado.PKID){
            errores.Empleado = 'Empleado es requerido'
        }
        return errores;
    }

    cUsuario = {
        PKID : 0,
        Credencial : '',
        IsNuevo : false,
        Administrador : false,
        AdministradorArea : false,
        Empleado : {
            PKID : 0,
            Nombre : '',
            Codigo : '',
            Area : {
                PKID : 0,
                Nombre : '',
                Codigo : ''
            },
            CorreoElectronico: ''
        }
    }

    constructor(Props: any){
        super(Props)
        this.state={
            Modal: false,
            ListaUsuario: [],
            ListaUsuarioOriginal: [],
            Usuario: this.cUsuario,
            ListaEmpleado: [],
            Errores: {}
        }
    }

    componentDidMount(){
        this.ListarUsuarios()
        this.ListarEmpleado()
    }

    ListarUsuarios(){
        UsuarioService.Listar(parseInt(AuthHelper.GetDecoded().sub,10))
        .then((response : any) => {
            console.log(response.data)
            if(response.success){
                this.setState({ListaUsuario: JSON.parse(response.data)})
                this.setState({ListaUsuarioOriginal: JSON.parse(response.data)})
            }else{
                alert(response.data)
            }
        }).catch((error) => {
            alert(error)
        })
    }

    ListarEmpleado(){
        EmpleadoService.Listar()
        .then((response : any) => {
            if(response.success){
                var aEmpleado : any = []
                Object.keys(JSON.parse(response.data)).forEach(function(key){
                    var iEmpleado : any = {
                        code: JSON.parse(response.data)[key].PKID,
                        name: JSON.parse(response.data)[key].Nombre,
                    }
                    aEmpleado.push(iEmpleado);
                });
                this.setState({ListaEmpleado: aEmpleado})
            }else{
                alert(response.data)
            }
        }).catch((error) => {
            alert(error)
        })
    }

    HandleChange = (e : any) => {
        switch(e.target.name){
            case 'Empleado' :
                this.setState({
                    Usuario:{
                        ...this.state.Usuario,
                        Empleado:{
                            ...this.state.Usuario.Empleado,
                            PKID : e.target.value.code,
                            Nombre : e.target.value.name
                        }
                    }
                })
                break
            case 'AdministradorArea':
                this.setState({
                    Usuario:{
                        ...this.state.Usuario,
                        [e.target.name]: !this.state.Usuario.AdministradorArea
                    }
                })
                break
            case 'Administrador': 
                this.setState({
                    Usuario:{
                        ...this.state.Usuario,
                        [e.target.name]: !this.state.Usuario.Administrador
                    }
                })
                break
            default :
                this.setState({
                    Usuario:{
                        ...this.state.Usuario,
                        [e.target.name]: e.target.value
                    }
                })
                break
        }
    }

    HandleSubmit = (e : any) => {
        e.preventDefault();
        const result = this.Validate(this.state.Usuario);
        if(Object.keys(result).length){
            return this.setState({Errores: result})
        }else{
            this.CrearActualizarUsuario()
        }
    }

    CrearActualizarUsuario(){
        UsuarioService.CrearActualizar(this.state.Usuario)
        .then( (response : any) => {
            alert(response.data)
            if(response.success){
                this.setState({
                    Modal: false,
                    Usuario: this.cUsuario,
                },() => this.componentDidMount())
            }
        }).catch( (error) => {
            alert(error)
        })
    }

    HandleChangeBuscador = (e: any) => {

        const ListaUsuarioOriginal = this.state.ListaUsuarioOriginal
        const ListaUsuarioFiltroBefore = this.state.ListaUsuarioOriginal

        if (e.target.value.length===0) {
            this.setState({
                ListaUsuario : ListaUsuarioOriginal
            })

        } else {
                const listaFiltrada = ListaUsuarioFiltroBefore.filter((lista) => lista.Empleado.Nombre.toLowerCase().includes(e.target.value.toLowerCase()))

                this.setState({
                    ListaUsuario : listaFiltrada
                })
        }
    }

    render(){
        return(
            <div>
                <UsuarioHeader
                    OnChangeBuscador={this.HandleChangeBuscador}
                />
                <DTUsuarios
                    ListaUsuarios = {this.state.ListaUsuario}
                    openModal = {() => this.setState({Modal: true})}
                    handleSelectRow = { (e:any) =>{
                        this.setState({
                            Usuario: e,
                            Modal: true
                        })
                    }}/>
                <ModalUsuario
                    usuario = {this.state.Usuario}
                    openModal = {this.state.Modal}
                    closeModal = {() => {
                        this.setState({
                            Modal: false,
                            Usuario: this.cUsuario,
                            Errores: {}
                        })
                    }}
                    listaEmpleado = {this.state.ListaEmpleado}
                    handleChange = {this.HandleChange}
                    onSubmit = {this.HandleSubmit}
                    Errores = {this.state.Errores}/>
            </div>
        )
    }
}