import { HTTP } from "../Http/Http"

export const CategorizacionRepository = {
    Listar: async() =>{
        return await HTTP.POST('Categorizacion/Listar','',{})
    },
    ListarPaginacion: async(Paginacion: any) =>{
        return await HTTP.POST('Categorizacion/ListarPaginacion',Paginacion,{})
    },    
    CrearActualizar: async (Categorizacion: any) => {
        return await HTTP.POST('Categorizacion/CrearActualizar', Categorizacion, {})
    }
}