import { HTTP } from "../Http/Http"

export const UsuarioRepository = {
    Auntenticar: async (body : any) => {
        return await HTTP.POST('Login/Autenticar',body,{})
    },
    CambioContraseña: async (body : any) => {
        return await HTTP.POST('Login/CambioContraseña',body,{})
    },
    Listar: async (IDUsuario : number) => {
        return await HTTP.POST('Login/ListarUsuarios?IDUsuario='+IDUsuario,'',{})
    },
    CrearActualizar: async (body : any) => {
        return await HTTP.POST('Login/CrearActualizarUsuario',body,{})
    },
    Consultar: async (IDUsuario: number) => {
        return await HTTP.GETS('Login/Consultar?IDUsuario=',IDUsuario)
    },
    RegistrarAsistencia: async (IDUsuario : number) => {
        return await HTTP.POST('Login/RegistrarAsistencia?IDUsuario='+IDUsuario,'',{})
    },
    RegistrarSalida: async (IDUsuario : number) => {
        return await HTTP.POST('Login/RegistrarSalida?IDUsuario='+IDUsuario,'',{})
    }
}