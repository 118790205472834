import React, {useState} from 'react'
import { Button } from 'primereact/button'
import { TabMenu } from 'primereact/tabmenu'
import RequerimientoModalSolucion from './RequerimientoModalSolucion'
import RequerimientoModalDetalle from './RequerimientoModalDetalle'

import '../Assets/Css/PanelRequerimiento.css'

interface IProps{
    Mode: boolean
    OnSubmit: any
    Solicitudes: any
    HandleChange: any
    FormValues: any
    SubirArchivo: any
    BorrarArchivo: any
    DescargarArchivo: any
    Errores: any
    Request: boolean
    ItemBackLogs: any
}

export default function RequerimientoModal(Props: IProps){


    const Items = [
        {label: 'Requerimiento', icon: 'pi pi-fw pi-pencil'},
        {label: 'Solución', icon: 'pi pi-fw pi-file'}
    ]

    const [ActiveItem, setActiveItem] = useState(Items[0])

    const ImageArchivo = (RowData : any) => {
        var extension = RowData.Extension
        let icono=''
        let fondo=''

        if(extension === 'txt'){
            icono = 'pi pi-file'
            fondo = '#000000'
        } 
        else if(extension === 'pdf'){
            icono = 'pi pi-file-pdf'
            fondo = '#ff471a'
        } 
        else if(extension==='docx' || extension==='docm' || extension==='dotx' || extension==='dotm' || extension==='doc' || extension==='dot' || extension==='rtf'){
            icono = 'far fa-file-word'
            fondo = '#80e5ff'
        } 
        else if(extension==='xls' || extension==='xlsx' || extension==='xlsm' || extension==='xltx' || extension==='xltm'){
            icono = 'far fa-file-excel'
            fondo = '#00802b'
        } 
        else if(extension==='pptx' || extension==='pptm' || extension==='ppt' || extension==='potx'){
            icono = 'far fa-file-powerpoint'
            fondo = '#ffa64d'
        } 
        else if(extension==='sql' || extension==='js' || extension==='vb' || extension==='cs'){
            icono = 'fas fa-scroll'
            fondo = '#66ff66'
        } 
        else if(extension==='zip' || extension==='7z' || extension==='rar'){
            icono = 'far fa-file-archive'
            fondo = '#3385ff'
        }
        else{
            icono = 'pi pi-question'
            fondo = '#000000'
        }

        return(
            <React.Fragment>
                <div className="p-grid p-fluid ">
                    <div className="p-col-12">
                            <i className={icono} style={{'fontSize': '2em', color:`${fondo}`}}></i>
                        </div>
                </div>
            </React.Fragment>
        )
    }

    const FechaCreacion = (RowData : any) => {
        let newDate
        RowData.FechaCreacion !== '' ? newDate = new Date(RowData.FechaCreacion) : newDate = new Date() 
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        return `${date}${'/'}${month<10?`0${month}`:`${month}`}${'/'}${year}`
    }

    const TamañoArchivo = (RowData : any) => {
        return(<p>{(RowData.Tamagno / Math.pow(1024,2)).toFixed(2) + 'MB'}</p>)
    }

    return(
        <div id="PanelRequerimiento" className={Props.Mode ? 'PanelRequerimientoI':'PanelRequerimientoD' }>
            <div id="PopupRequerimiento" className={Props.Mode ? 'PopupRequerimientoI' : 'PopupRequerimientoD'}>
                <div className="p-d-flex p-jc-between">
                    <p style={{
                        color: '#4D80E4',
                        fontFamily: 'Sarala',
                        fontSize: '22px'}}>Requerimiento</p>
                    <i className="fas fa-times btn-cerrar-requerimiento" onClick={() => 
                    {
                        (document.getElementById('PanelRequerimiento') as HTMLInputElement).classList.remove('active');
                        (document.getElementById('PopupRequerimiento') as HTMLInputElement).classList.remove('active');
                    }}></i>
                </div>
                <form autoComplete="off" onSubmit={Props.OnSubmit}>
                    {Props.Mode?
                        <TabMenu 
                            model={Items} 
                            activeItem={ActiveItem} 
                            onTabChange={(e) => setActiveItem(e.value)} />
                        :
                        ''}
                    {ActiveItem.label==='Requerimiento'?
                        <RequerimientoModalDetalle
                            ImageArchivo = {ImageArchivo}
                            Solicitudes = {Props.Solicitudes}
                            ItemBackLogs = {Props.ItemBackLogs}
                            HandleChange = {Props.HandleChange}
                            FormValues = {Props.FormValues}
                            MostrarTareas = {true}
                            SubirArchivo = {Props.SubirArchivo}
                            Errores = {Props.Errores}
                            TamañoArchivo = {TamañoArchivo}
                            FechaCreacion = {FechaCreacion}
                            BorrarArchivo = {Props.BorrarArchivo}
                            DescargarArchivo = {Props.DescargarArchivo}/>
                        :
                        <RequerimientoModalSolucion
                            ImageArchivo = {ImageArchivo}
                            FormValues = {Props.FormValues}
                            HandleChange = {Props.HandleChange}
                            TamañoArchivo = {TamañoArchivo}
                            FechaCreacion = {FechaCreacion}
                            SubirArchivo = {Props.SubirArchivo}
                            BorrarArchivo = {Props.BorrarArchivo}
                            DescargarArchivo = {Props.DescargarArchivo}/>
                    }
                    <div className="p-d-flex footer">
                        <Button 
                            label={Props.Request ? "Cargando..." : "Aceptar"}
                            style={{
                                background:'#4D80E4',
                                border: '1px solid #4D80E4'}}
                            icon={Props.Request ? "" : "pi pi-check" }
                            className="p-ml-auto"
                            disabled={Props.Request}
                            autoFocus/>
                    </div>
                </form>
            </div>
        </div>
    )
}