import React from 'react'
import { BrowserRouter, Switch, Route} from "react-router-dom"
import Login from "./Infrastructure/Pages/Login"
import Desarrollo from './Infrastructure/Routes/Desarrollo'
import Implementacion from './Infrastructure/Routes/Implementacion'
import { AuthHelper } from './Domain/Utils/AuthHelper'

interface IProps {
}

interface IState {
}

export default class App extends React.Component<IProps, IState> {

    render(){

       

        return(
            <BrowserRouter  basename="">
                <Switch>
                    <Route exact path="/" component={Login}/>  
                  
                    <Route path="/Desarrollo" component={Desarrollo}/>  
                    {/** 
                     * 
                     * <Route path="/Desarrollo" component={Implementacion}/>
                     * 
                    */}
                    
                   
                </Switch>
            </BrowserRouter>
        )

       
    }
}