import React from 'react'
import { Dialog } from 'primereact/dialog'
import { Editor } from 'primereact/editor'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { Calendar } from 'primereact/calendar'

interface IProps{
    Modal: boolean
    HideModal: any
    HandleChange: any
    FormValues: any
    Errores: any
    Request: boolean
}

export default function CalidadModal(Props: IProps){

    const Header = (
        <>
            <span className="ql-formats">
                <button className="ql-bold" aria-label="Bold"></button>
                <button className="ql-italic" aria-label="Italic"></button>
                <button className="ql-underline" aria-label="Underline"></button>
            </span>
            <span className="ql-formats">
                <button type="button" className="ql-list" value="ordered" aria-label="Ordered List">
                    <svg viewBox="0 0 18 18"> 
                        <line className="ql-stroke" x1="7" x2="15" y1="4" y2="4"></line> 
                        <line className="ql-stroke" x1="7" x2="15" y1="9" y2="9"></line> 
                        <line className="ql-stroke" x1="7" x2="15" y1="14" y2="14"></line> 
                        <line className="ql-stroke ql-thin" x1="2.5" x2="4.5" y1="5.5" y2="5.5"></line> 
                        <path className="ql-fill" d="M3.5,6A0.5,0.5,0,0,1,3,5.5V3.085l-0.276.138A0.5,0.5,0,0,1,2.053,3c-0.124-.247-0.023-0.324.224-0.447l1-.5A0.5,0.5,0,0,1,4,2.5v3A0.5,0.5,0,0,1,3.5,6Z"></path> 
                        <path className="ql-stroke ql-thin" d="M4.5,10.5h-2c0-.234,1.85-1.076,1.85-2.234A0.959,0.959,0,0,0,2.5,8.156"></path> 
                        <path className="ql-stroke ql-thin" d="M2.5,14.846a0.959,0.959,0,0,0,1.85-.109A0.7,0.7,0,0,0,3.75,14a0.688,0.688,0,0,0,.6-0.736,0.959,0.959,0,0,0-1.85-.109"></path> 
                    </svg>
                </button>
                <button type="button" className="ql-list" value="bullet" aria-label="Unordered List">
                    <svg viewBox="0 0 18 18"> 
                        <line className="ql-stroke" x1="6" x2="15" y1="4" y2="4"></line> 
                        <line className="ql-stroke" x1="6" x2="15" y1="9" y2="9"></line> 
                        <line className="ql-stroke" x1="6" x2="15" y1="14" y2="14"></line> 
                        <line className="ql-stroke" x1="3" x2="3" y1="4" y2="4"></line> 
                        <line className="ql-stroke" x1="3" x2="3" y1="9" y2="9"></line> 
                        <line className="ql-stroke" x1="3" x2="3" y1="14" y2="14"></line> 
                    </svg>
                </button>
            </span>
        </>
    )

    const EditorDescripcion = (Event: any) => {
        Props.HandleChange({
            target:{
                name:"Descripcion",
                value:Event.htmlValue
            }
        })
    }

    const RenderFooter = () =>{
        return (
            <Button 
                type="submit"
                label={Props.Request ? "Cargando..." : "Aceptar"}
                style={{
                    background:'#4D80E4',
                    border: '1px solid #4D80E4'}}
                icon={Props.Request ? "" : "pi pi-check" } 
                className="p-ml-auto"
                disabled={Props.Request}
                autoFocus/>
        )
    }

    return(
        <Dialog
            header='Plan de Pruebas'
            visible={Props.Modal}
            style={{ width: '110vh' }} 
            footer={RenderFooter()} 
            onHide={Props.HideModal}>
                <div className="modal-body p-mt-3">
                    <div className="p-grid nested-grid">
                        <div className="p-col-12 p-lg-6">
                            <div className="p-col">
                                <div className="p-grid p-fluid p-mr-1 p-ml-1">
                                    <div className="p-col-12">
                                        <label className="p-d-block" 
                                            style={{
                                                color: '#4D80E4',
                                                fontFamily: 'Sarala',
                                                fontSize: '20px'}}>Nombre</label>
                                        <InputText 
                                            name="Nombre"
                                            onChange={Props.HandleChange}
                                            value={Props.FormValues.Nombre}
                                            type="text" />
                                        {Props.Errores.Nombre && <small className="p-invalid p-d-block">{Props.Errores.Nombre}</small>}        
                                    </div>
                                </div>
                                <div className="p-grid p-fluid p-mr-1 p-ml-1">
                                    <div className="p-col-12">
                                        <label className="p-d-block" 
                                            style={{
                                                color: '#4D80E4',
                                                fontFamily: 'Sarala',
                                                fontSize: '20px'}}>Fecha Creacion</label>
                                        <Calendar 
                                            value={Props.FormValues.FechaCreacion? new Date(Props.FormValues.FechaCreacion) : new Date()}                                                        
                                            disabled
                                            dateFormat="dd/mm/yy"/>
                                        <small className="p-d-block" style={{color:'#fff'}}>.</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12 p-lg-6">
                            <div className="p-col">
                                <div className="p-grid nested-grid" style={{border:'1px solid #4D80E4'}}>
                                    {(Props.FormValues.Proyecto!==null && Props.FormValues.Proyecto!==undefined)?
                                    <div className='p-col-12'>
                                        <div className='p-grid'>
                                            <div className="p-col-12">
                                            <div className='p-grid'>
                                                <div className="p-col-12">
                                                    <div className="p-grid p-fluid p-mr-1 p-ml-1">
                                                        <div className="p-col-12">
                                                            <label className="p-d-block" 
                                                                style={{
                                                                    color: '#4D80E4',
                                                                    fontFamily: 'Sarala',
                                                                    fontSize: '20px'}}>Nombre Proyecto</label>
                                                            <InputText 
                                                                name="NombreProyecto"
                                                                disabled
                                                                value={Props.FormValues.Proyecto.Nombre}
                                                                type="text" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="p-col-6">
                                                    <div className="p-grid p-fluid p-mr-1 p-ml-1">
                                                        <div className="p-col-12">
                                                            <label className="p-d-block" 
                                                                style={{
                                                                    color: '#4D80E4',
                                                                    fontFamily: 'Sarala',
                                                                    fontSize: '20px'}}>Fecha Inicio</label>
                                                            <Calendar 
                                                                value={Props.FormValues.Proyecto.FechaInicio? new Date(Props.FormValues.Proyecto.FechaInicio) : new Date()}                                                       
                                                                onChange={Props.HandleChange}
                                                                disabled
                                                                name="FechaInicio"
                                                                dateFormat="dd/mm/yy"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="p-col-6">
                                                    <div className="p-grid p-fluid p-mr-1 p-ml-1">
                                                        <div className="p-col-12">
                                                            <label className="p-d-block" 
                                                                style={{
                                                                    color: '#4D80E4',
                                                                    fontFamily: 'Sarala',
                                                                    fontSize: '20px'}}>Fecha Fin</label>
                                                            <Calendar 
                                                                value={Props.FormValues.Proyecto.FechaFin? new Date(Props.FormValues.Proyecto.FechaFin) : new Date()}                                                        
                                                                disabled
                                                                name="FechaFin"
                                                                dateFormat="dd/mm/yy"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                    :null}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-grid nested-grid">
                        <div className="p-col-12">
                            <div className="p-col">
                                <div className="p-grid p-fluid p-mr-1 p-ml-1">
                                    <div className="p-col-12">
                                        <label className="p-d-block" 
                                            style={{
                                                color : '#4D80E4',
                                                fontFamily : 'Sarala',
                                                fontSize: '20px'}}>Descripcion</label>
                                        <Editor 
                                            style={{height:'120px'}} 
                                            value={Props.FormValues.Descripcion ? Props.FormValues.Descripcion:''} 
                                            onTextChange={EditorDescripcion}
                                            headerTemplate={Header}/>
                                        {Props.Errores.Descripcion && <small className="p-invalid p-d-block">{Props.Errores.Descripcion}</small>}        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </Dialog>
    )
}
