import { PlanPruebaRepository } from "../../Infrastructure/Repositories/PlanPruebaRepository"
import { IPlanPrueba } from "../Models/IPlanPrueba"

const ValidarSchema = (PlanPrueba: IPlanPrueba) => {
    const Errores: {Nombre?: string, Descripcion?: string} = {}
    if(!PlanPrueba.Nombre) Errores.Nombre = 'Nombre es requerido'
    if(!PlanPrueba.Descripcion) Errores.Descripcion = 'Descripcion es requerido'
    return Errores
}

const ObtenerObjetoEstado = (Array: Array<IPlanPrueba>, PKID: string, IDEstado: number) => {
    let aObj: Array<IPlanPrueba> =  Array.filter( (Obj : IPlanPrueba) =>{
        return Obj.PKID === parseInt(PKID,10) 
    })
    let Obj: IPlanPrueba = aObj[0] 
    return {
        ...Obj,
        Estado:{
            ...Obj.Estado,
            PKID: IDEstado
        }
    }
}

export const PlanPruebaService = {
    ValidarSchema,
    ObtenerObjetoEstado,
    Listar: () => {
        return PlanPruebaRepository.Listar()
    },
    ListarPaginacion: (Paginacion: any) => {
        return PlanPruebaRepository.ListarPaginacion(Paginacion)
    },
    CrearActualizar: (PlanPrueba: IPlanPrueba) => {
        return PlanPruebaRepository.CrearActualizar(PlanPrueba)
    },
    Consultar: (PlanPrueba: IPlanPrueba) => {
        return PlanPruebaRepository.Consultar(PlanPrueba)
    }
}