import React from 'react'
import RequerimientoHeader from '../../Infrastructure/Components/RequerimientoHeader'
import { getBackLog, getProyecto, setRequerimiento } from '../../Services/LocalStorage'
import ItemBackLogDetalle from '../../Infrastructure/Components/ItemBackLogDetalle'
import  { useState, useEffect, useRef } from 'react';
import { AuthHelper } from '../../Domain/Utils/AuthHelper'
import { ItemBackLogService} from '../../Domain/Services/ItemBackLogService'
import { VersionProductoService } from '../../Domain/Services/VersionProductoService'
import { ProyectoService } from '../../Domain/Services/ProyectoService'
import { EstadoService } from '../../Domain/Services/EstadoService'
import { SolicitudService } from '../../Domain/Services/SolicitudService'
import BackLogModal from '../../Infrastructure/Components/BackLogModal'
import { TicketService } from '../../Domain/Services/TicketService'
import { PrioridadService } from '../../Domain/Services/PrioridadService'
import { BackLogService } from '../../Domain/Services/BackLogService'
import { ClienteService } from '../../Domain/Services/ClienteService'
import { FileUpload } from 'primereact/fileupload';
import { IBackLog } from '../../Domain/Models/IBackLog'
import { IImplementacionItemBackLog } from '../../Domain/Models/IImplementacionItemBackLog'
import { IPaginacion } from '../../Domain/Models/IPaginacion'
import ItemBackLogHeader from '../../Infrastructure/Components/ItemBackLogHeader'
import { IImplementacionBackLog } from '../../Domain/Models/IImplementacionBackLog'
import { IImplementacionBackLogVersion } from '../../Domain/Models/IImplementacionBackLogVersion'
import ItemBackLogBody from '../../Infrastructure/Components/ItemBackLogBody'
import ItemBackLogModal from '../../Infrastructure/Components/ItemBackLogModal'
import { IStakeholder } from '../../Domain/Models/IStakeholder'
import BackLogModalStakeholder from '../../Infrastructure/Components/BackLogModalStakeholder'
import BackLogModalDescargarWord from '../../Infrastructure/Components/BackLogModalDescargarWord'
import { MotivoVersionamientoService } from '../../Domain/Services/MotivoVersionamientoService'
import { jsPDF } from "jspdf";
import { IVersionProducto } from '../../Domain/Models/IVersionProducto';



interface IProps {
    
}

interface IState {    
    
    ImplementacionBackLog: IImplementacionBackLog
    ImplementacionBackLogVersion: IImplementacionBackLogVersion
    Pendiente: IPaginacion
    EnCurso: IPaginacion
    Pruebas: IPaginacion
    StandBy: IPaginacion
    Hecho: IPaginacion
    ImplementacionItemBackLog: IImplementacionItemBackLog
    Errores: any
    ErroresB: any
    ListaEmpleado: Array<object>
    ListaPrioridad: Array<object>
    ListaEstado: Array<object>
    ListaEstadoPrincipales: Array<object>
    IDUsuario: number
    ListaSolicitudes: Array<object>
    ListaSolicitudesDatos: Array<object>
    ListaProducto: Array<object>
    ListaArchivosSubiendo: Array<object>
    Cliente: Array<object>
    Request: boolean
    Stakeholder: IStakeholder
    ErroresStakeholder: any
    ModalStakeholder: boolean
    MotivoVersionamiento: Array<object>
    ModalDescargarWord: boolean
    ErroresDescargarWord: any
    ListaImplementacionBackLogVersion: Array<object>
    
}

 
export default class ItemBackLog extends React.Component<IProps, IState> {

    cImplementacionBackLog = {
        PKID: 0,
        Nombre: '',
        Descripcion: '',
        LinkDocumentacionDesarrollo: '',
        Version: 0,
        FechaCreacion: new Date(),
        Empleado:{
            PKID:  parseInt(AuthHelper.GetDecoded().sub,10),
            Codigo: '',
            Nombre: '',
            Area:{
                PKID: 0,
                Codigo: '',
                Nombre: ''
            }
        },        
        Estado:{
            PKID: 0,
            Descripcion: ''
        },
        Producto : {
            PKID : 0,
            Nombre : '',
            VersionProducto : []
        },
        VersionProducto : {
            PKID : 0,
            Version : ''
        },   
        Usuario:{
            PKID:  parseInt(AuthHelper.GetDecoded().sub,10),            
            NombreEmpleado: AuthHelper.GetDecoded().Empleado             
        },
        Cliente :{
            PKID : 0,
            Nombre : '',
        },
        Objetivo: '',
        Stakeholders:[],
        FechaEntrega : new Date(),
        Prerequisitos: '',
        ImplementacionBackLogVersion:[]    ,
        ArchivosAdjuntos : []    ,
        MotivoVersionamiento : {
            PKID : 0,
            Descripcion : ''
        },
        DetalleVersionamiento : '',
        Activo : false
    }

    cImplementacionBackLogVersion = {
        PKID: 0,
        Nombre: '',
        Descripcion: '',
        LinkDocumentacionDesarrollo: '',
        Version: 0,
        FechaCreacion: new Date(),
        Empleado:{
            PKID:  parseInt(AuthHelper.GetDecoded().sub,10),
            Codigo: '',
            Nombre: '',
            Area:{
                PKID: 0,
                Codigo: '',
                Nombre: ''
            }
        },        
        Estado:{
            PKID: 0,
            Descripcion: ''
        },
        Producto : {
            PKID : 0,
            Nombre : '',
            VersionProducto : []
        },
        VersionProducto : {
            PKID : 0,
            Version : ''
        },   
        Usuario:{
            PKID:  parseInt(AuthHelper.GetDecoded().sub,10),            
            NombreEmpleado: AuthHelper.GetDecoded().Empleado             
        },
        Cliente :{
            PKID : 0,
            Nombre : '',
        },
        Objetivo: '',
        Stakeholders:[],
        FechaEntrega : new Date(),
        Prerequisitos: '',       
        ArchivosAdjuntos : []    ,
        MotivoVersionamiento : {
            PKID : 0,
            Descripcion : ''
        },
        DetalleVersionamiento : '',
        Activo : false,
        IDBackLog: 0,
        ItemBackLogVersion:[]
    }

    cImplementacionItemBackLog = {
        PKID: 0,
        
        IDBackLog : getBackLog()? JSON.parse(getBackLog() || '{}').PKID : 0,
       
        Estado:{
            PKID: 1,
            Descripcion: 'Pendiente'
        },
        
        Prioridad:{
            PKID: 0,
            Descripcion: ''
        },
        
        
        Descripcion: '',

        Nombre:'',

        Orden:1
       
    }

    cStakeholder = {
        PKID : 0,
        Numero : 1,
        Descripcion : ""
    }

    cPaginacion = {
        Lista: [],
        Paginacion: {
            NumeroPagina: 1, 
            RegistrosPagina: 5, 
            NumeroPaginas: 0, 
            IDEstado: 1, 
            IDUsuario: parseInt(AuthHelper.GetDecoded().sub,10),
            IDBackLog: getBackLog()? JSON.parse(getBackLog() || '{}').PKID:0
        },
        Loading: true
    }

    constructor(props: any){
        super(props);
        this.state = {     
                 
            ImplementacionBackLog: this.cImplementacionBackLog,
            ImplementacionBackLogVersion: this.cImplementacionBackLogVersion,
            ModalStakeholder: false,
            ModalDescargarWord: false,
            Pendiente: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 1}},
            EnCurso: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 2}},
            Pruebas: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 4}},
            StandBy: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 5}},
            Hecho: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 3}},
            ImplementacionItemBackLog: this.cImplementacionItemBackLog,
            Errores: {},
            ErroresB: {},
            ListaEmpleado: [],
            ListaPrioridad: [],
            ListaEstado: [],
            ListaEstadoPrincipales: [],
            IDUsuario: 0,
            ListaSolicitudes: [],
            ListaSolicitudesDatos: [],
            ListaProducto: [],
            ListaArchivosSubiendo:[],
            Cliente: [],
            Stakeholder: this.cStakeholder,
            ErroresStakeholder : {},
            ErroresDescargarWord : {},
            Request : false,
            MotivoVersionamiento:[],
            ListaImplementacionBackLogVersion:[]
            
        }
    }

    componentDidMount(){
        if(getBackLog()){
            this.ConsultarBackLog();
        }
        this.ListarEmpleado()
        this.ListarPrioridad()
        this.ListarEstado()
        this.ListarVersionProducto()
        this.ListarCliente()
        this.ListarMotivoVersionamiento()
    }

    ListarMotivoVersionamiento(){
        MotivoVersionamientoService.Listar()
        .then( (response : any) => {
            if (response.success) {
                var aMotivoVersionamiento : Array<object> = []
                Object.keys(JSON.parse(response.data)).forEach(function(key){
                    var iMotivoVersionamiento = {
                        code: JSON.parse(response.data)[key].PKID,
                        name: JSON.parse(response.data)[key].Descripcion,
                    }
                    aMotivoVersionamiento.push(iMotivoVersionamiento)
                })
                this.setState({MotivoVersionamiento: aMotivoVersionamiento})
            }else{
                alert(response.data)
            }
        })
        .catch( (error) => {
            alert(error)
        })
    }

    ListarPrioridad(){
        PrioridadService.Listar()
        .then( (response : any) => {
            if (response.success) {
                var aPrioridad : Array<object> = []
                Object.keys(JSON.parse(response.data)).forEach(function(key){
                    var iPrioridad = {
                        code: JSON.parse(response.data)[key].PKID,
                        name: JSON.parse(response.data)[key].Descripcion,
                    }
                    aPrioridad.push(iPrioridad)
                })
                this.setState({ListaPrioridad: aPrioridad})
            }else{
                alert(response.data)
            }
        })
        .catch( (error) => {
            alert(error)
        })
    }

    ListarCliente(){
        ClienteService.Listar()
        .then( (response : any) => {
            if(response.success) {
                var aCliente : Array<object> = []
                Object.keys(JSON.parse(response.data)).forEach(function(key){
                    var iCliente = {
                        code: JSON.parse(response.data)[key].PKID,
                        name: JSON.parse(response.data)[key].Nombre,
                    }
                    aCliente.push(iCliente)
                })
                this.setState({Cliente: aCliente})
            }else{
                alert(response.data)
            }
        }).catch( (error) => {
            alert(error)
        })
    }

    ConsultarBackLog(){
            BackLogService.Consultar(JSON.parse(getBackLog() || '{}'))
            .then((response: any) => {
                try {
                    if (response.success) {
                        var json = JSON.parse(response.data)                
                        var aVersion: Array<object> = []
                        Object.keys(json.Producto.VersionProducto).forEach(function(key){
                            var iVersion = {
                                code: json.Producto.VersionProducto[key].PKID,
                                name: json.Producto.VersionProducto[key].Version,
                            }
                            aVersion.push(iVersion)
                        });
                        json = {
                            ...json,
                            Producto:{
                                ...json.Producto,
                                VersionProducto : aVersion
                            }
                        }
                        this.setState({
                            ImplementacionBackLog: json                    
                        })
                    }else{
                        alert(response.data.data)
                    }
                } catch (error) {
                    alert(error + "\n use block de notas para formatear su texto y quite saltos de linea")
                }

            })
            .catch( (error) => {
                alert(error)
            })
            .finally( () => {            
                this.ListarItemBackLog('Pendiente')
                this.ListarItemBackLog('EnCurso')
                this.ListarItemBackLog('StandBy')
                this.ListarItemBackLog('Pruebas')
                this.ListarItemBackLog('Hecho')
            })
    }

    

    ConsultarBackLog2(){
        BackLogService.Consultar(JSON.parse(getBackLog() || '{}'))
        .then((response: any) => {
            if (response.success) {
                var json = JSON.parse(response.data)                        
                
                this.setState({
                    ImplementacionBackLog: json                    
                })

                var aImplementacionBackLogVersion : Array<object> = []
                Object.keys(json.ImplementacionBackLogVersion).forEach(function(key){                    
                var iImplementacionBackLogVersion = {
                    code: json.ImplementacionBackLogVersion[key].PKID,
                    name: json.ImplementacionBackLogVersion[key].Version
                }
                aImplementacionBackLogVersion.push(iImplementacionBackLogVersion)
            })
            this.setState({ListaImplementacionBackLogVersion: aImplementacionBackLogVersion})

            }else{
                alert(response.data.data)
            }
        })
        .catch( (error) => {
            alert(error)
        })
        .finally( () => {
            
        })
    }

    ListarItemBackLog = (Estado: string) => {
        ItemBackLogService.ListarPaginacion(
            {
                'Pendiente': this.state.Pendiente.Paginacion,
                'EnCurso' : this.state.EnCurso.Paginacion,
                'Hecho' : this.state.Hecho.Paginacion,
                'Pruebas' : this.state.Pruebas.Paginacion,
                'StandBy' : this.state.StandBy.Paginacion
            }[Estado]
        )
        .then((response: any) => {
            if(response.success){
                let jParse = JSON.parse(response.data)
                let rItemBackLog: Array<IImplementacionItemBackLog | any> = jParse.ItemBackLog ? jParse.ItemBackLog : []
                let rPaginacion: any = jParse.Paginacion
                switch(Estado){
                    case 'Pendiente': 
                        this.setState({Pendiente: {Lista: this.state.Pendiente.Lista.concat(rItemBackLog),Paginacion: rPaginacion,Loading: false}})
                        break
                    case 'EnCurso' : 
                        this.setState({EnCurso: {Lista: this.state.EnCurso.Lista.concat(rItemBackLog),Paginacion: rPaginacion,Loading: false}})
                        break 
                    case 'Hecho' : 
                        this.setState({Hecho: {Lista: this.state.Hecho.Lista.concat(rItemBackLog),Paginacion: rPaginacion,Loading: false}})                
                        break
                    case 'Pruebas':
                        this.setState({Pruebas: {Lista: this.state.Pruebas.Lista.concat(rItemBackLog),Paginacion: rPaginacion,Loading: false}})                
                        break
                        case 'StandBy':
                        this.setState({StandBy: {Lista: this.state.StandBy.Lista.concat(rItemBackLog),Paginacion: rPaginacion,Loading: false}})                
                        break
                    default:
                        break
                }
            }else{
                alert(response.data)
            }
        })
        .catch( (error) => {
            alert(error)
        })
    }

    ListarEmpleado(){
        TicketService.Causante_ObtenerTodos()
        .then( (response : any) => {
            if (response.success) {
                var aEmpleado : Array<object> = []
                Object.keys(JSON.parse(response.data)).forEach(function(key){
                    var iEmpleado = {
                        code: JSON.parse(response.data)[key].PKID,
                        name: JSON.parse(response.data)[key].Nombre,
                    }
                    aEmpleado.push(iEmpleado)
                })
                this.setState({ListaEmpleado: aEmpleado})
            }else{
                alert(response.data)
            }
        })
        .catch( (error) => {
            alert(error)
        })
    }

    ListarEstado(){
        EstadoService.Listar()
        .then( (response : any) => {
            if(response.success){
                var aEstado: Array<object> = [], bEstado: Array<object> = []
                Object.keys(JSON.parse(response.data)).forEach(function(key){
                    var iEstado = {
                        code: JSON.parse(response.data)[key].PKID,
                        name: JSON.parse(response.data)[key].Descripcion,
                    }
                    aEstado.push(iEstado);
                    if(iEstado.code===1 || iEstado.code===2 || iEstado.code===3){
                        bEstado.push(iEstado)
                    }
                });
                this.setState({
                    ListaEstado: aEstado,
                    ListaEstadoPrincipales: bEstado
                })
            }else{
                alert(response.data);
            }
        })
        .catch((error) => {
            alert(error)
        })
    }

    ListarSolicitudes(){
        SolicitudService.Listar()
        .then((response: any) => {
            if(response.success){
                let lTemporal: Array<object>= [], lDatos: Array<object>= []
                lTemporal.push({
                    code: 0,
                    name: "[=======(Quitar Solicitud Seleccionadada)======]",
                })
                Object.keys(JSON.parse(response.data)).forEach(function(key){
                    lTemporal.push({
                        code: JSON.parse(response.data)[key].PKID,
                        name: JSON.parse(response.data)[key].Nombre,
                    });
                    lDatos.push(JSON.parse(response.data)[key])
                });
                this.setState({
                    ListaSolicitudes: lTemporal,
                    ListaSolicitudesDatos: lDatos
                })
            }
        })
        .catch((error) => {
            alert(error)
        })
    }

    ListarVersionProducto(){
        VersionProductoService.Listar()
        .then( (response : any) => {
            if (response.success) {
                var aProducto : Array<object> = []
                let lProducto : Array<{PKID: string, Nombre: string}> = JSON.parse(response.data)
                Object.keys(lProducto).forEach(function(k1: any){
                    let oProducto: any = lProducto[k1]
                    let aVersionProducto: Array<object> = []
                    if(oProducto.VersionProducto){
                        Object.keys(oProducto.VersionProducto).forEach(function( k2 ){
                            aVersionProducto.push({
                                code : oProducto.VersionProducto[k2].PKID,
                                name : oProducto.VersionProducto[k2].Version,
                            })
                        })
                    }
                    aProducto.push({
                        code : lProducto[k1].PKID+'',
                        name : lProducto[k1].Nombre,
                        VersionProducto : aVersionProducto
                    })
                })
                this.setState({ListaProducto: aProducto})
            }else{
                alert(response.data)
            }
        })
        .catch( (error) => {
            alert(error)
        })
    }

    HandleChangeImplementacionItemBackLog = (Event : React.ChangeEvent<HTMLInputElement>) => { 
        try {
            const {name, value} : any= Event.target
            switch(name){
                case 'Estado':                
                    this.setState({ 
                        ImplementacionItemBackLog: {
                            ...this.state.ImplementacionItemBackLog,
                            Estado: {
                                PKID: value.code,
                                Descripcion: value.name
                            }
                        }
                    })
                    break
                    case 'Prioridad':
                        this.setState({
                            ImplementacionItemBackLog: {
                                ...this.state.ImplementacionItemBackLog, 
                                Prioridad: {
                                    PKID: value.code,
                                    Descripcion : value.name
                                }
                            }
                        })
                        break      
                default : 
                    this.setState({
                        ImplementacionItemBackLog: {
                            ...this.state.ImplementacionItemBackLog,
                            [name]: value
                        }
                    })
                    break
            }  
        } catch (error) {
            alert(error + "\n use block de notas para formatear su texto y quite saltos de linea")
        }

        
    }

    HandleChangeImplementacionBackLog = (Event : React.ChangeEvent<HTMLInputElement>) => { 
        const {name, value} : any= Event.target
        switch(name){
            case 'Estado':
                this.setState({ 
                    ImplementacionBackLog: {
                        ...this.state.ImplementacionBackLog,
                        Estado: {
                            PKID: value.code,
                            Descripcion: value.name
                        }
                    }
                });
                break       
                
            default:
                this.setState({
                    ImplementacionBackLog: {
                        ...this.state.ImplementacionBackLog,
                        [name]: value
                    }
                })
                break
        }
    }

   
    HandleResponse = (Entidad: any) => {
        if(Entidad){
            //console.log("select * from Implementacion.ItemBackLog WHERE PKID="+Entidad.PKID)
            //console.log("Descripcion : "+Entidad.Descripcion)
            console.log(Entidad)
            this.setState({
                ImplementacionItemBackLog: {
                ...this.state.ImplementacionItemBackLog,
                PKID: Entidad.PKID,
                Nombre: Entidad.Nombre,
                Orden: Entidad.Orden,
                Descripcion: Entidad.Descripcion,
                Estado:{
                    PKID:Entidad.Estado.PKID,
                    Descripcion:Entidad.Estado.Descripcion
                },
                Prioridad:{
                    PKID:Entidad.Prioridad.PKID,
                    Descripcion:Entidad.Prioridad.Descripcion
                },
                IDBackLog:Entidad.IDBackLog
            }
            })
            if(this.state.ImplementacionBackLog.Estado.PKID !== 3 ) this.AbrirPanelRequerimiento()
            else alert('No se puede crear nuevos Items BackLog')
        }else{
            this.setState({
                ImplementacionItemBackLog: {
                ...this.state.ImplementacionItemBackLog,
                PKID: 0,
        
        IDBackLog : getBackLog()? JSON.parse(getBackLog() || '{}').PKID : 0,
       
        Estado:{
            PKID: 1,
            Descripcion: 'Pendiente'
        },
        
        Prioridad:{
            PKID: 0,
            Descripcion: ''
        },

        Nombre:'',

        Orden:1,        
        
        Descripcion: ''
            }
            })
            if(this.state.ImplementacionBackLog.Estado.PKID !== 3 ) this.AbrirPanelRequerimiento()
            else alert('No se puede crear nuevos Items BackLog')
        }
    }

    AbrirPanelRequerimiento = () => {
        (document.getElementById('PanelRequerimiento') as HTMLInputElement).classList.add('active');
        (document.getElementById('PopupRequerimiento') as HTMLInputElement).classList.add('active');
    }

   

    GuardarTemporal = () =>{

        if (AuthHelper.GetDecoded().Area != 'Desarrollo' && this.state.ImplementacionBackLog.Estado.PKID != 1){
            alert("Solo Desarrollo puede editar");
        }else{

        const result = BackLogService.ValidarSchema(this.state.ImplementacionBackLog)
        if(Object.keys(result).length){
            return this.setState({ErroresB: result});
        }else{
            BackLogService.CrearActualizar(this.state.ImplementacionBackLog)
            .then( (response: any) => {
                alert(response.data)
                if(response.success) window.location.reload()
            })
            .catch( (error) => {
                alert(error)
            })
        }

    }

    }

    CrearVersion = () =>{

        if (AuthHelper.GetDecoded().Area != 'Desarrollo' && this.state.ImplementacionBackLog.Estado.PKID != 1){
            alert("Solo Desarrollo puede editar");
        }else{

        BackLogService.CrearVersion(this.state.ImplementacionBackLog, this.state.ImplementacionBackLog)
        .then( (response: any) => {
            if(response.success){
                alert(response.data)
                window.location.reload()
            }else{
                alert(response.data)
            }
        })
        .catch( (error) => {
            alert(error)
        })

    }

    }

    Paginacion = (Estado: string) => {
        if(
            (
                {
                    'Pendiente': ((this.state.Pendiente.Paginacion.NumeroPagina < this.state.Pendiente.Paginacion.NumeroPaginas) && !this.state.Pendiente.Loading),
                    'EnCurso' : ((this.state.EnCurso.Paginacion.NumeroPagina < this.state.EnCurso.Paginacion.NumeroPaginas) && !this.state.EnCurso.Loading),
                    'Hecho' : ((this.state.Hecho.Paginacion.NumeroPagina < this.state.Hecho.Paginacion.NumeroPaginas) && !this.state.Hecho.Loading),
                    'Pruebas' : ((this.state.Pruebas.Paginacion.NumeroPagina < this.state.Pruebas.Paginacion.NumeroPaginas) && !this.state.Pruebas.Loading),
                    'StandBy' : ((this.state.StandBy.Paginacion.NumeroPagina < this.state.StandBy.Paginacion.NumeroPaginas) && !this.state.StandBy.Loading)
                }[Estado]
            )){
                switch(Estado){
                    case 'Pendiente':
                        this.setState({
                            Pendiente: {
                                ...this.state.Pendiente,
                                Paginacion:{
                                    ...this.state.Pendiente.Paginacion,
                                    NumeroPagina: this.state.Pendiente.Paginacion.NumeroPagina + 1},
                                Loading: true
                            }
                        },() => this.ListarItemBackLog('Pendiente'))
                        break
                        case 'EnCurso' : 
                            this.setState({
                                EnCurso: {
                                    ...this.state.EnCurso
                                    ,Paginacion:{
                                        ...this.state.EnCurso.Paginacion,
                                        NumeroPagina: this.state.EnCurso.Paginacion.NumeroPagina + 1},
                                    Loading: true
                                }
                            },() => this.ListarItemBackLog('EnCurso'))
                        break
                        case 'Pruebas':
                            this.setState({
                                Pruebas: {
                                    ...this.state.Pruebas
                                    ,Paginacion:{
                                        ...this.state.Pruebas.Paginacion,
                                        NumeroPagina: this.state.Pruebas.Paginacion.NumeroPagina + 1},
                                    Loading: true
                                }
                            },() => this.ListarItemBackLog('Pruebas'))
                            break;
                            case 'StandBy':
                                this.setState({
                                    StandBy: {
                                        ...this.state.StandBy
                                        ,Paginacion:{
                                            ...this.state.StandBy.Paginacion,
                                            NumeroPagina: this.state.StandBy.Paginacion.NumeroPagina + 1},
                                        Loading: true
                                    }
                                },() => this.ListarItemBackLog('StandBy'))
                                break;
                        case 'Hecho' : 
                            this.setState({
                                Hecho: {
                                    ...this.state.Hecho,
                                    Paginacion:{
                                        ...this.state.Hecho.Paginacion,
                                        NumeroPagina: this.state.Hecho.Paginacion.NumeroPagina + 1},
                                    Loading: true
                                }
                            },() => this.ListarItemBackLog('Hecho'))
                        break
                    default :
                        break
                }
        }
    }

  
   


    

    HandleChange = (Event : React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} : any= Event.target
        switch(name){   
            case 'Empleado':
                this.setState({
                    ImplementacionBackLog: {
                        ...this.state.ImplementacionBackLog, 
                        [name]: {
                            PKID: value.code,
                            Nombre: value.name
                        }
                    }
                })
                break         
            default :
                this.setState({
                    ImplementacionBackLog: {
                        ...this.state.ImplementacionBackLog,
                        [name]: value
                    }
                })
                break
        }
    }
    
    HandleChangeBackLog = (Event : React.ChangeEvent<HTMLInputElement>) => { 
        const {name, value} : any= Event.target
        switch(name){
            case 'Estado':
                this.setState({ 
                    ImplementacionBackLog: {
                        ...this.state.ImplementacionBackLog,
                        Estado: {
                            PKID: value.code,
                            Descripcion: value.name
                        }
                    }
                });
                break

                case 'Empleado':
                this.setState({ 
                    ImplementacionBackLog: {
                        ...this.state.ImplementacionBackLog,
                        Empleado: {
                            PKID: value.code,
                            Codigo:"",
                            Nombre: value.name,
                            Area:{
                                PKID: 0,
                                Codigo:"",
                                Nombre: "",
                            }
                        }
                    }
                });
                break

                case 'Producto':
                    this.setState({
                        ImplementacionBackLog : {
                            ...this.state.ImplementacionBackLog,
                            Producto : {
                                PKID : value.code,
                                Nombre : value.name,
                                VersionProducto : value.VersionProducto
                            }
                        },
                    })                    
                    break

                case 'VersionProducto':
                    this.setState({
                        ImplementacionBackLog : {
                            ...this.state.ImplementacionBackLog,
                            VersionProducto : {
                                PKID : value.code,
                                Version : value.name
                            }
                        },
                    })
                    break

                    case 'Cliente':
                    this.setState({
                        ImplementacionBackLog : {
                            ...this.state.ImplementacionBackLog,
                            Cliente : {
                                PKID : value.code,
                                Nombre : value.name
                            }
                        },
                    })
                    break 
                    
                    case 'MotivoVersionamiento':
                        this.setState({
                            ImplementacionBackLog : {
                                ...this.state.ImplementacionBackLog,
                                MotivoVersionamiento : {
                                    PKID : value.code,
                                    Descripcion : value.name
                                }
                            },
                        })
                        break

                        case 'Activo':
                            this.setState({
                                ImplementacionBackLog:{
                                    ...this.state.ImplementacionBackLog,
                                    Activo: !this.state.ImplementacionBackLog.Activo
                                }
                            })
                            break
            
            default:
                this.setState({
                    ImplementacionBackLog: {
                        ...this.state.ImplementacionBackLog,
                        [name]: value
                    }
                })
                break
        }
    }

    MoverEstado = ( EstadoI : string, EstadoF : string, PKID : string) => {
        if(EstadoI !== EstadoF){
            let Obj = {'Pendiente': this.state.Pendiente.Lista,'EnCurso' : this.state.EnCurso.Lista,'Hecho' : this.state.Hecho.Lista, 'Pruebas' : this.state.Pruebas.Lista, 'StandBy' : this.state.StandBy.Lista}[EstadoI] || []
            switch(EstadoF){
                case 'Pendiente' :
                    this.ConsultarItemBackLog(ItemBackLogService.ObtenerObjetoEstado(Obj,PKID,1),1)
                    break
                case 'EnCurso' :
                    this.ConsultarItemBackLog(ItemBackLogService.ObtenerObjetoEstado(Obj,PKID,2),2)
                    break
                case 'Hecho' :
                    this.ConsultarItemBackLog(ItemBackLogService.ObtenerObjetoEstado(Obj,PKID,3),3)
                    break
                case 'Pruebas':
                    this.ConsultarItemBackLog(ItemBackLogService.ObtenerObjetoEstado(Obj,PKID,4),4)
                    break
                    case 'StandBy':
                        this.ConsultarItemBackLog(ItemBackLogService.ObtenerObjetoEstado(Obj,PKID,5),5)
                        break
                default:
                    break
            }
        }
    }

    ConsultarItemBackLog = (Obj: IImplementacionItemBackLog,Estado: number) =>{
        ItemBackLogService.Consultar(Obj)
        .then((response: any) => {
            if (response.success) {
                var oImplementacionItemBackLog = JSON.parse(response.data)
                oImplementacionItemBackLog = {
                    ...oImplementacionItemBackLog,
                    Estado: {
                        ...oImplementacionItemBackLog.Estado,
                        PKID : Estado
                    }
                }
                this.setState({
                    ImplementacionItemBackLog : oImplementacionItemBackLog
                }, () => this.CrearActualizarItemBackLog())
            }else{
                throw new Error(response.data)
            }
        })
        .catch( (error) => {
            alert(error)
        })
    }

    CrearActualizarItemBackLog(){
        ItemBackLogService.CrearActualizar(this.state.ImplementacionItemBackLog, this.state.ImplementacionBackLog)
        .then( (response: any) => {
            if(response.success){
                this.setState({
                    Pendiente: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 1}},
                    EnCurso: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 2}},
                    Pruebas: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 4}},
                    StandBy: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 5}},
                    Hecho: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 3}},
                    Errores : {},
                    ImplementacionItemBackLog : this.cImplementacionItemBackLog
                },()=>{
                    (document.getElementById('PanelRequerimiento') as HTMLInputElement).classList.remove('active');
                    (document.getElementById('PopupRequerimiento') as HTMLInputElement).classList.remove('active');
                    this.ListarEstado()                   
                    this.ConsultarBackLog()
                })
            }else{
                alert(response.data.data)
            }
        })
        .catch( (error) => {
            alert(error)
        })
        .finally( () => {
            this.setState({Request : false})
        })
    }

    HandleSubmitItemBackLog = (Event: any) =>{
        Event.preventDefault();
        const result = ItemBackLogService.ValidarSchema(this.state.ImplementacionItemBackLog)
        if(Object.keys(result).length){
            return this.setState({Errores: result});
        }else{
            this.setState({
                Request: true
            }, () => this.CrearActualizarItemBackLog())
        }
    };

    HandleSubmitStakeholder = (Event: any) => {
        Event.preventDefault()
        const result =  BackLogService.ValidarStakeholder(this.state.Stakeholder)
        if(Object.keys(result).length) return this.setState({ErroresStakeholder : result});
        else this.CrearActualizarStakeholder()
    }

    CrearActualizarStakeholder(){
        let listaStakeholders=this.state.ImplementacionBackLog.Stakeholders
        if(listaStakeholders.filter( (e: any) => { return e.Numero === this.state.Stakeholder.Numero}).length > 0){
            listaStakeholders = listaStakeholders.filter( (e: any) => { return e.Numero !== this.state.Stakeholder.Numero})
        }
        listaStakeholders.push(this.state.Stakeholder)
        listaStakeholders.sort( function(a: any, b: any) {
            return a.Numero - b.Numero
        })
        this.setState({
            Stakeholder : this.cStakeholder,
            ImplementacionBackLog:{
                ...this.state.ImplementacionBackLog,
                Stakeholders:listaStakeholders
            }
        },() => this.HideVisibleModalStakeholder(false))
    }

    HideVisibleModalStakeholder = (Mode: boolean) => {
        this.setState({
            ModalStakeholder: Mode
        })
    }

    HideVisibleModalDescargarWord = (Mode: boolean) => {
        this.setState({
            ModalDescargarWord: Mode
        })
    }

    HandleChangeStakeholder = (Event: any) => {
        this.setState({
            Stakeholder:{
                ...this.state.Stakeholder,
                [Event.target.name]: Event.target.value
            }
        })
    }

    CrearNuevoStakeholder = () => {
        this.setState({
            Stakeholder:{
                ...this.cStakeholder,
                Numero : this.state.ImplementacionBackLog.Stakeholders.length + 1,
            },
            ErroresStakeholder: {}
        },() => this.HideVisibleModalStakeholder(true))
    }

    ActualizarStakeholder = (Event: any)=>{
        this.setState({
            Stakeholder : Event,
            ErroresStakeholder: {}
        },() => this.HideVisibleModalStakeholder(true))
    }

    EliminarStakeholder = (RowData: any) => {
        let lStakeholders = this.state.ImplementacionBackLog.Stakeholders
        if(lStakeholders.filter( (Event: any) => { return Event.Numero === RowData.Numero}).length > 0){
            lStakeholders = lStakeholders.filter( (Event: any) => { return Event.Numero !== RowData.Numero})
        }
        var lOrdenado: Array<object> = []
        if(lStakeholders.length > 0){
            lStakeholders.forEach((Element,Index) => {
                Element = {
                    ...Element,
                    Numero : Index + 1
                }
                lOrdenado.push(Element)
            });
        }
        this.setState({
            ImplementacionBackLog:{
                ...this.state.ImplementacionBackLog,
                Stakeholders : lOrdenado
            },
        })
    }

    OnRowReorder = (Event: any) => {
        let lista= Event.value, nuevaLista=[]
        if(lista!==null && lista!==undefined){
            for(let i=0; i<lista.length; i++){
                let obj=lista[i]
                obj={
                    ...obj,
                    Numero:i+1
                }
                nuevaLista.push(obj)
            }
        }
        this.setState({
            ImplementacionBackLog:{
                ...this.state.ImplementacionBackLog,
                Stakeholders:nuevaLista
            }
        })
    }

    SubirArchivo = (Event: any, FileUpload: any) =>  {
        const file = Event.files[0]
        const reader: any = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
            let encoded = reader.result.toString().replace(/^data:(.*,)?/, '')
            if ((encoded.length % 4) > 0) {
                encoded += '='.repeat(4 - (encoded.length % 4));
            }
            var oArchivoAdjunto = {
                PKID : 0,
                Nombre : file.name.replace(/[.][^.]+$/, ''),
                FechaCreacion : '',
                Tamagno : file.size,//parseFloat(file.size / Math.pow(1024,2)).toFixed(2), //De Bytes a MegaBytes
                Archivo : encoded,
                Extension : file.name.slice((file.name.lastIndexOf(".") - 1 >>> 0) + 2)
            }
            this.setState({
                ImplementacionBackLog :{
                    ...this.state.ImplementacionBackLog,
                    ArchivosAdjuntos:[
                        ...this.state.ImplementacionBackLog.ArchivosAdjuntos,
                        oArchivoAdjunto
                    ]
                }
            })
        }
        reader.onerror = (Error: any) => {
            alert(Error)
        }
        FileUpload.current.files = ''
        FileUpload.current.state.files = ''
    }

    BorrarArchivo = (Event: any) => {
        let lArchivos = this.state.ImplementacionBackLog.ArchivosAdjuntos
        if(lArchivos.filter( (e) => { return e === Event}).length > 0){
            lArchivos = lArchivos.filter( (e) => { return e !== Event})
        }
        this.setState({
            ImplementacionBackLog :{
                ...this.state.ImplementacionBackLog,
                ArchivosAdjuntos : lArchivos
            }
        })
    }

    DescargarArchivo = (Event: any) => {
        let lArchivos = this.state.ImplementacionBackLog.ArchivosAdjuntos
        if(lArchivos.filter( (e) => { return e === Event}).length > 0){
            lArchivos = lArchivos.filter( (e) => { return e === Event})
            const oArchivos: any = lArchivos[0]
            TicketService.DescargarArchivo(oArchivos)
        }else{
            alert('Error: No se encontro el archivo indicado')
        }
    }

    DescargarWord = (Event : any) => {  
        if(getBackLog()){
            this.ConsultarBackLog2();
        }            
        this.HideVisibleModalDescargarWord(true)       
}

HandleSubmitDescargarWord = (Event: any) => {
    Event.preventDefault()
    const result =  BackLogService.ValidarDescargarWord(this.state.ImplementacionBackLogVersion)
    if(Object.keys(result).length){ return this.setState({ErroresDescargarWord : result});}
    else {BackLogService.DescargarWord(this.state.ImplementacionBackLogVersion.PKID)
    .then((data : any) => {                        
        let BackLogVersion = JSON.parse(data.data)[0]
    
            const doc = new jsPDF();
            doc.setFontSize(22);
            doc.text(BackLogVersion.Nombre, doc.internal.pageSize.getWidth()/2, 10, { align: "center" });            
            doc.setFontSize(16);
            doc.text('Descripcion : ' + BackLogVersion.Descripcion, 10, 20);
            doc.text('Version : ' + BackLogVersion.Version, 10, 30);
            doc.text('FechaCreacion : ' + BackLogVersion.FechaCreacion, 10, 40);
            doc.text('Empleado : ' + BackLogVersion.Empleado.Nombre, 10, 50);
            doc.text('Estado : ' + BackLogVersion.Estado.Descripcion, 10, 60);
            doc.text('Producto : ' + BackLogVersion.Producto.Nombre, 10, 70);
            doc.text('VersionProducto : ' + BackLogVersion.VersionProducto.Version, 10, 80);
            doc.text('Cliente : ' + BackLogVersion.Cliente.Nombre, 10, 90);
            doc.text('Objetivo : ' + BackLogVersion.Objetivo, 10, 100);
            doc.text('FechaEntrega : ' + BackLogVersion.FechaEntrega, 10, 110);
            doc.text('Prerequisitos : ' + BackLogVersion.Prerequisitos, 10, 120);
            doc.text('LinkDocumentacionDesarrollo : ' + BackLogVersion.LinkDocumentacionDesarrollo, 10, 130);
            doc.text('MotivoVersionamiento : ' + BackLogVersion.MotivoVersionamiento.Descripcion, 10, 140);
            doc.text('DetalleVersionamiento : ' + BackLogVersion.DetalleVersionamiento, 10, 150);           
            doc.addPage();
            doc.setFontSize(22);
            doc.text('ItemBackLogVersion', doc.internal.pageSize.getWidth()/2, 10, { align: "center" });            
            doc.setFontSize(16);
            for(var i = 0; i < BackLogVersion.ItemBackLogVersion.length; i ++) {    
            doc.text('Nombre : ' + BackLogVersion.ItemBackLogVersion[i].Nombre, 10, 20+i*50);
            doc.text('Descripcion : ' + BackLogVersion.ItemBackLogVersion[i].Descripcion, 10, 30+i*50);
            doc.text('Orden : ' + BackLogVersion.ItemBackLogVersion[i].Orden, 10, 40+i*50);
            doc.text('Estado : ' + BackLogVersion.ItemBackLogVersion[i].Estado.Descripcion, 10, 50+i*50);
            doc.text('Prioridad : ' + BackLogVersion.ItemBackLogVersion[i].Prioridad.Descripcion, 10, 60+i*50);
            }  
            doc.addPage();
            doc.setFontSize(22);
            doc.text('Stakeholders', doc.internal.pageSize.getWidth()/2, 10, { align: "center" });            
            doc.setFontSize(16);
            for(var i = 0; i < BackLogVersion.Stakeholders.length; i ++) {    
            doc.text(BackLogVersion.Stakeholders[i].Numero+' Descripcion : '+ BackLogVersion.Stakeholders[i].Descripcion, 10, 20+i*10);           
            }  
            doc.addPage();
            doc.setFontSize(22);
            doc.text('ArchivosAdjuntos', doc.internal.pageSize.getWidth()/2, 10, { align: "center" });            
            doc.setFontSize(16);
            for(var i = 0; i < BackLogVersion.ArchivosAdjuntos.length; i ++) {    
                doc.text('Nombre : ' + BackLogVersion.ArchivosAdjuntos[i].Nombre, 10, 20+i*30);
                doc.text('Link : ' + BackLogVersion.ItemBackLogVersion[i].Link, 10, 30+i*30);
                doc.text('FechaCreacion : ' + BackLogVersion.ItemBackLogVersion[i].FechaCreacion, 10, 40+i*30);  
            }  
            doc.save("BackLogVersion.pdf");
    })    
    this.HideVisibleModalDescargarWord(false)
} 
}

HandleChangeDescargarWord = (Event: any) => {
    this.setState({
        ImplementacionBackLogVersion:{
            ...this.state.ImplementacionBackLogVersion,
            PKID: Event.target.value.code,
            Version: Event.target.value.name
        }
    })
}

    render(){
       return(
            <div className="container">
                <ItemBackLogHeader
                DescargarWord = {this.DescargarWord}
                />
               
                         <ItemBackLogDetalle
                    FormValues = {this.state.ImplementacionBackLog}
                    PasFormValues = {this.state.ImplementacionBackLog}
                    ListaEmpleado = {this.state.ListaEmpleado}
                    ListaEstado = {this.state.ListaEstado}
                    ListaProducto = {this.state.ListaProducto}
                    ListaCliente = {this.state.Cliente}                    
                    ListaMotivoVersionamiento = {this.state.MotivoVersionamiento}
                    HandleChange = {this.HandleChangeBackLog}
                    GuardarTemporal = {this.GuardarTemporal}
                    CrearVersion = {this.CrearVersion}
                    OnRowReorder = {this.OnRowReorder}
                    CrearNuevoStakeholder= {this.CrearNuevoStakeholder}
                    ActualizarStakeholder= {this.ActualizarStakeholder}
                    EliminarStakeholder= {this.EliminarStakeholder}
                    SubirArchivo= {this.SubirArchivo}
                        BorrarArchivo= {this.BorrarArchivo}
                        DescargarArchivo= {this.DescargarArchivo}
                    Errores = {this.state.ErroresB}/>

<ItemBackLogBody 
                    Paginacion = {this.Paginacion}
                    Pendiente = {this.state.Pendiente}
                    EnCurso = {this.state.EnCurso}
                    Pruebas = {this.state.Pruebas}
                    StandBy = {this.state.StandBy}
                    Hecho = {this.state.Hecho}
                    OnResponse = {this.HandleResponse}
                    MoverEstado = {this.MoverEstado}/>
                     <ItemBackLogModal
                    Mode = {false}
                    OnSubmit = {this.HandleSubmitItemBackLog}
                    Solicitudes={this.state.ListaSolicitudes}
                    HandleChange = {this.HandleChangeImplementacionItemBackLog}
                    FormValues = {this.state.ImplementacionItemBackLog}
                    ListaPrioridad = {this.state.ListaPrioridad}
                    ListaEstado = {this.state.ListaEstado}
                    Errores = {this.state.Errores}
                    Request = {this.state.Request}/>
                      <form autoComplete="Off" onSubmit={this.HandleSubmitStakeholder}>
                    <BackLogModalStakeholder
                        Modal= {this.state.ModalStakeholder}
                        HideModal= {() => this.HideVisibleModalStakeholder(false)}
                        FormValues = {this.state.Stakeholder}
                        HandleChange = {this.HandleChangeStakeholder}
                        Errores = {this.state.ErroresStakeholder}/>                        
                </form>
                <form autoComplete="Off" onSubmit={this.HandleSubmitDescargarWord}>
                    <BackLogModalDescargarWord
                        Modal= {this.state.ModalDescargarWord}
                        HideModal= {() => this.HideVisibleModalDescargarWord(false)}
                        FormValues = {this.state.ImplementacionBackLog}
                        ImplementacionBackLogVersion = {this.state.ImplementacionBackLogVersion}
                        HandleChange = {this.HandleChangeDescargarWord}   
                        ListaImplementacionBackLogVersion = {this.state.ListaImplementacionBackLogVersion}                     
                        Errores = {this.state.ErroresDescargarWord}/>                        
                </form>
            </div>
        )
    }
}