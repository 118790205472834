import React from 'react'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Editor } from 'primereact/editor'
import { Checkbox } from 'primereact/checkbox'

interface IProps{
    ActualizarPaso: any
    EliminarPaso: any
    HandleChange: any
    Modal: boolean
    HideModal: any
    FormValues: any
    CrearNuevoPaso: any
    EliminarCasoPrueba: any
    ListaModulo: Array<object>
    ListaTipoPrueba: Array<object>
    ListaProducto: Array<object>
    Errores: any
    Request: boolean
    RowReorder: any
}

export default function CasoPruebaModal(Props: IProps){

    const RecortadorTexto = (Texto: string, Liminte: number) =>{
        if (Texto.length > Liminte) {
            Texto = Texto.substring(0, Liminte) + "...";
        }
        return Texto;
    }

    const TemplateModuloSeleccionado = (Option: any, Props: any) => {
        if (Option) {
            return (
                <div className="country-item country-item-value">
                    <div style={{width:'50px'}}>{Option.name}</div>
                </div>
            );
        }
        return (
            <span  className="country-item country-item-value">
                {Props.placeholder}
            </span>
        )
    }

    const TemplateOpcionModulo = (Option: any) => {
        return (
            <div className="country-item">
                <div>{RecortadorTexto(Option.name, 40)}</div>
            </div>
        );
    }

    const ActionBodyTemplate = (RowData: any) => {
        return (
            <React.Fragment>
                <div className="p-grid p-fluid ">
                    <div className="p-col-6">
                        <Button 
                            icon="pi pi-pencil" 
                            className="p-button-rounded p-button-outlined" 
                            type="button" 
                            onClick={()=>{ Props.ActualizarPaso(RowData) }} />
                    </div>
                    <div className="p-col-6">
                        <Button 
                            icon="pi pi-trash" 
                            className="p-button-rounded p-button-danger p-button-outlined" 
                            type="button" 
                            onClick={()=>{ Props.EliminarPaso(RowData) }} />
                    </div>
                </div>
            </React.Fragment>
        )
    }

    const EditorDescripcion = (Event: any) => {
        Props.HandleChange({
            target:{
                name: "Descripcion",
                value: Event.htmlValue?Event.htmlValue.replace('<span ','<div ').replace('</span>','</div>'):''
            }
        })
    }

    const Header = (
        <>
            <span className="ql-formats">
                <button className="ql-bold" aria-label="Bold"></button>
                <button className="ql-italic" aria-label="Italic"></button>
                <button className="ql-underline" aria-label="Underline"></button>
            </span>
            <span className="ql-formats">
                <button type="button" className="ql-list" value="ordered" aria-label="Ordered List">
                    <svg viewBox="0 0 18 18"> 
                        <line className="ql-stroke" x1="7" x2="15" y1="4" y2="4"></line> 
                        <line className="ql-stroke" x1="7" x2="15" y1="9" y2="9"></line> 
                        <line className="ql-stroke" x1="7" x2="15" y1="14" y2="14"></line> 
                        <line className="ql-stroke ql-thin" x1="2.5" x2="4.5" y1="5.5" y2="5.5"></line> 
                        <path className="ql-fill" d="M3.5,6A0.5,0.5,0,0,1,3,5.5V3.085l-0.276.138A0.5,0.5,0,0,1,2.053,3c-0.124-.247-0.023-0.324.224-0.447l1-.5A0.5,0.5,0,0,1,4,2.5v3A0.5,0.5,0,0,1,3.5,6Z"></path> 
                        <path className="ql-stroke ql-thin" d="M4.5,10.5h-2c0-.234,1.85-1.076,1.85-2.234A0.959,0.959,0,0,0,2.5,8.156"></path> 
                        <path className="ql-stroke ql-thin" d="M2.5,14.846a0.959,0.959,0,0,0,1.85-.109A0.7,0.7,0,0,0,3.75,14a0.688,0.688,0,0,0,.6-0.736,0.959,0.959,0,0,0-1.85-.109"></path> 
                    </svg>
                </button>
                <button type="button" className="ql-list" value="bullet" aria-label="Unordered List">
                    <svg viewBox="0 0 18 18"> 
                        <line className="ql-stroke" x1="6" x2="15" y1="4" y2="4"></line> 
                        <line className="ql-stroke" x1="6" x2="15" y1="9" y2="9"></line> 
                        <line className="ql-stroke" x1="6" x2="15" y1="14" y2="14"></line> 
                        <line className="ql-stroke" x1="3" x2="3" y1="4" y2="4"></line> 
                        <line className="ql-stroke" x1="3" x2="3" y1="9" y2="9"></line> 
                        <line className="ql-stroke" x1="3" x2="3" y1="14" y2="14"></line> 
                    </svg>
                </button>
            </span>
        </>
    )

    const RenderFooter = () =>{
        return (
            <div className="p-d-flex footer p-ml-auto">
                {Props.FormValues.PKID > 0 ? 
                    <Button 
                        type="button"
                        label={Props.Request ? "Cargando..." : "Eliminar"} 
                        onClick={() => { Props.EliminarCasoPrueba(Props.FormValues) }}
                        disabled={Props.Request}
                        className="p-button-danger"/>
                    : 
                    <></>}
                <Button 
                    type="submit"
                    label={Props.Request ? "Cargando..." : "Aceptar"}
                    style={{
                        background:'#4D80E4',
                        border: '1px solid #4D80E4'}}
                    icon={Props.Request ? "" : "pi pi-check" }
                    className="p-ml-auto"
                    disabled={Props.Request}
                    autoFocus />
            </div>
        )
    }

    return(
        <Dialog
            header='Caso Prueba'
            visible={Props.Modal}
            style={{ width: '110vh' }}
            footer={RenderFooter()} 
            onHide={() => Props.HideModal(null, false)}>
                <div className="p-grid nested-grid">
                    <div className="p-col-12 p-lg-6">
                        <div className="p-grid p-fluid">
                            <div className="p-col-12">
                                <label className="p-d-block" 
                                    style={{
                                        color: '#4D80E4',
                                        fontFamily: 'Sarala',
                                        fontSize: '20px'}}>
                                Codigo</label>
                                <InputText
                                    name="Codigo"
                                    onChange={Props.HandleChange}
                                    value={Props.FormValues.Codigo}
                                    type="text" />
                            {Props.Errores.Codigo && <small className="p-invalid p-d-block">{Props.Errores.Codigo}</small>}
                            </div>
                            <div className="p-col-12">
                                <label className="p-d-block" 
                                    style={{
                                        color: '#4D80E4',
                                        fontFamily: 'Sarala',
                                        fontSize: '20px'}}>
                                Nombre</label>
                                <InputText
                                    name="Nombre"
                                    onChange={Props.HandleChange}
                                    value={Props.FormValues.Nombre}
                                    type="text" />
                            {Props.Errores.Nombre && <small className="p-invalid p-d-block">{Props.Errores.Nombre}</small>}
                            </div>
                            <div className="p-col-12">
                                <label className="p-d-block" 
                                    style={{
                                        color : '#4D80E4',
                                        fontFamily : 'Sarala',
                                        fontSize : '20px'}}>
                                    Descripcion</label>
                                <Editor 
                                    style={{height:'120px'}} 
                                    value={Props.FormValues.Descripcion ? Props.FormValues.Descripcion:''} 
                                    onTextChange={EditorDescripcion}
                                    headerTemplate={Header}/>
                                {Props.Errores.Descripcion && <small className="p-invalid p-d-block">{Props.Errores.Descripcion}</small>}
                            </div>
                            <div className="p-col-12 p-lg-12">
                                <Checkbox       
                                    onChange={Props.HandleChange}
                                    inputId="binary"  
                                    checked={Props.FormValues.Automatizado}
                                    name="Automatizado" />
                                     <label 
                                        className=""
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'}}
                                        >      Automatizado</label>
                                    {Props.Errores.Automatizado && <small className="p-invalid p-d-block">{Props.Errores.Automatizado}</small>}    
                            </div>
                        </div>
                    </div>
                    <div className="p-col-12 p-lg-6">
                        <div className="p-grid p-fluid">
                            <div className="p-col-12">
                                <label className="p-d-block" 
                                    style={{
                                        color : '#4D80E4',
                                        fontFamily : 'Sarala',
                                        fontSize : '20px'}}>
                                Modulo</label>
                                <Dropdown 
                                    value = {{
                                        code: Props.FormValues.Modulo.PKID,
                                        name: Props.FormValues.Modulo.Nombre}}
                                    options = {Props.ListaModulo}
                                    onChange = {Props.HandleChange}
                                    optionLabel = "name" 
                                    name = "Modulo"
                                    filter filterBy = "name" 
                                    placeholder = "Seleccione el Modulo"
                                    valueTemplate = {TemplateModuloSeleccionado} 
                                    itemTemplate = {TemplateOpcionModulo} />
                                {Props.Errores.Modulo && <small className="p-invalid p-d-block">{Props.Errores.Modulo}</small>}
                            </div>
                            <div className="p-col-12">
                                <label className="p-d-block" 
                                    style={{
                                        color : '#4D80E4',
                                        fontFamily : 'Sarala',
                                        fontSize : '20px'}}>
                                Responsable</label>
                                <InputText
                                    name="Empleado"
                                    disabled
                                    onChange={Props.HandleChange}
                                    value={Props.FormValues.Empleado.Nombre}
                                    type="text" />
                            </div>
                            <div className="p-col-12">
                                <label className="p-d-block" 
                                    style={{
                                        color : '#4D80E4',
                                        fontFamily : 'Sarala',
                                        fontSize : '20px'}}>
                                Tipo de Prueba</label>
                                <Dropdown 
                                    value = {{
                                        code : Props.FormValues.TipoPrueba.PKID,
                                        name : Props.FormValues.TipoPrueba.Nombre}}
                                    options = {Props.ListaTipoPrueba}
                                    onChange = {Props.HandleChange}
                                    name = "TipoPrueba"
                                    optionLabel = "name"
                                    placeholder = "Seleccione Tipo de Prueba"/>
                                {Props.Errores.TipoPrueba && <small className="p-invalid p-d-block">{Props.Errores.TipoPrueba}</small>}
                            </div>
                            <div className="p-col-12 p-lg-6">
                                <label className="p-d-block" 
                                    style={{
                                        color: '#4D80E4',
                                        fontFamily : 'Sarala',
                                        fontSize : '20px'}}>
                                Producto</label>
                                <Dropdown 
                                    value = {{
                                        code: Props.FormValues.Producto.PKID,
                                        name: Props.FormValues.Producto.Nombre,
                                        VersionProducto : Props.FormValues.Producto.VersionProducto}}
                                    options = {Props.ListaProducto}
                                    onChange = {Props.HandleChange}
                                    optionLabel = "name"
                                    name = "Producto"
                                    placeholder = "Seleccione Producto"/>
                                {Props.Errores.Producto && <small className="p-invalid p-d-block">{Props.Errores.Producto}</small>}
                            </div>
                            <div className="p-col-12 p-lg-6">
                                <label className="p-d-block" 
                                    style={{
                                        color: '#4D80E4',
                                        fontFamily : 'Sarala',
                                        fontSize : '20px'}}>
                                Versión</label>
                                <Dropdown 
                                    value={{
                                        code: Props.FormValues.VersionProducto.PKID,
                                        name: Props.FormValues.VersionProducto.Version}}
                                    options={Props.FormValues.Producto.VersionProducto}
                                    onChange={Props.HandleChange}
                                    optionLabel="name"
                                    name = "VersionProducto"
                                    placeholder="Seleccione Versión"/>
                                {Props.Errores.VersionProducto && <small className="p-invalid p-d-block">{Props.Errores.VersionProducto}</small>}
                            </div>
                        </div>
                    </div>
                    <div className="p-col-12">
                        <div className="p-col">
                            <div className="open-card-composer" style={{width:'15%'}} onClick={(event) => Props.CrearNuevoPaso(event)}>
                                <i className="pi pi-plus icon-sm"></i>
                                <span>Nuevo Paso</span>
                            </div>
                            <DataTable                                  
                                value={Props.FormValues.Pasos}
                                rows={5}
                                paginator
                                reorderableColumns  
                                onRowReorder={Props.RowReorder}                             
                                rowsPerPageOptions={[5,8,10]}
                                className="p-datatable-sm"
                                columnResizeMode="fit">
                                <Column  rowReorder style={{ width: '5%', cursor: 'grab' }}></Column>
                                <Column field="NumeroPaso" header="Paso" style={{width:'20%'}}></Column>
                                <Column field="Accion" header="Accion" style={{width:'30%', center:'true'}}></Column>
                                <Column field="ResultadoEsperado" header="Resultado Esperado" style={{width:'35%'}}></Column>
                                <Column body={ActionBodyTemplate}  header="Accion" style={{width:'10%'}}></Column>
                                
                            </DataTable>
                        </div>
                    </div>
                </div>
        </Dialog>
    )
}
