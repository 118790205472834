import React from 'react'
import { InputText } from 'primereact/inputtext'
import { Calendar } from 'primereact/calendar'
import { Button } from 'primereact/button'
import { Editor } from 'primereact/editor'

//import '../Assets/css/Sprint.css'

interface IProps{
    FormValues: any
    HandleChange: any
    ActualizarPlanDePrueba: any
    Errores: any
    Request: boolean
}

export default function PlanDePruebasPanel(Props: IProps){

    const Header = (
        <>
            <span className="ql-formats">
                <button className="ql-bold" aria-label="Bold"></button>
                <button className="ql-italic" aria-label="Italic"></button>
                <button className="ql-underline" aria-label="Underline"></button>
            </span>
            <span className="ql-formats">
                <button type="button" className="ql-list" value="ordered" aria-label="Ordered List">
                    <svg viewBox="0 0 18 18"> 
                        <line className="ql-stroke" x1="7" x2="15" y1="4" y2="4"></line> 
                        <line className="ql-stroke" x1="7" x2="15" y1="9" y2="9"></line> 
                        <line className="ql-stroke" x1="7" x2="15" y1="14" y2="14"></line> 
                        <line className="ql-stroke ql-thin" x1="2.5" x2="4.5" y1="5.5" y2="5.5"></line> 
                        <path className="ql-fill" d="M3.5,6A0.5,0.5,0,0,1,3,5.5V3.085l-0.276.138A0.5,0.5,0,0,1,2.053,3c-0.124-.247-0.023-0.324.224-0.447l1-.5A0.5,0.5,0,0,1,4,2.5v3A0.5,0.5,0,0,1,3.5,6Z"></path> 
                        <path className="ql-stroke ql-thin" d="M4.5,10.5h-2c0-.234,1.85-1.076,1.85-2.234A0.959,0.959,0,0,0,2.5,8.156"></path> 
                        <path className="ql-stroke ql-thin" d="M2.5,14.846a0.959,0.959,0,0,0,1.85-.109A0.7,0.7,0,0,0,3.75,14a0.688,0.688,0,0,0,.6-0.736,0.959,0.959,0,0,0-1.85-.109"></path> 
                    </svg>
                </button>
                <button type="button" className="ql-list" value="bullet" aria-label="Unordered List">
                    <svg viewBox="0 0 18 18"> 
                        <line className="ql-stroke" x1="6" x2="15" y1="4" y2="4"></line> 
                        <line className="ql-stroke" x1="6" x2="15" y1="9" y2="9"></line> 
                        <line className="ql-stroke" x1="6" x2="15" y1="14" y2="14"></line> 
                        <line className="ql-stroke" x1="3" x2="3" y1="4" y2="4"></line> 
                        <line className="ql-stroke" x1="3" x2="3" y1="9" y2="9"></line> 
                        <line className="ql-stroke" x1="3" x2="3" y1="14" y2="14"></line> 
                    </svg>
                </button>
            </span>
        </>
    )

    const EditorDescripcion = (Event: any) => {
        Props.HandleChange({
            target:{
                name: "Descripcion",
                value: Event.htmlValue?Event.htmlValue.replace('<span ','<div ').replace('</span>','</div>'):''
            }
        })
    }
        
    return(
        <div id="PanelSprint" className="OverlaySprint">
            <div id="PopupSprint" className="PopupSprint">
                <div className="HeaderPopup">
                    <div className="p-d-flex p-jc-between">
                        <div className="Title">Plan de pruebas</div>
                        <div className="btn-cerrar-popupsprint" onClick={() => 
                        {
                            (document.getElementById('PanelSprint') as HTMLInputElement).classList.remove('active');
                            (document.getElementById('PopupSprint') as HTMLInputElement).classList.remove('active');
                        }}
                        ><i className="fas fa-times"></i></div>
                    </div>
                </div>
                <form autoComplete="off" className="BodySprint">
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="p-grid p-fluid">
                                <div className="p-col">
                                    <label 
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '19px'}}>
                                    Plan de Prueba</label>
                                    <InputText
                                    name="Nombre"
                                    value={Props.FormValues.Nombre}
                                    onChange={Props.HandleChange}
                                    type="text" />
                                    {Props.Errores.Nombre && <small className="p-invalid p-d-block">{Props.Errores.Nombre}</small>}
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12">
                            <div className="p-grid p-fluid">
                                <div className="p-col">
                                    <label className="p-d-block" 
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '19px'}}>
                                    Descripcion</label>
                                    <Editor 
                                        style={{height:'120px'}} 
                                        value={Props.FormValues.Descripcion ? Props.FormValues.Descripcion:''} 
                                        onTextChange={EditorDescripcion}
                                        headerTemplate={Header}/>
                                    {Props.Errores.Descripcion && <small className="p-invalid p-d-block">{Props.Errores.Descripcion}</small>}
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12">
                            <div className="p-col" style={{border:'1px solid #4D80E4'}}>
                                <div className="p-col-12">
                                    <div className="p-grid p-fluid">
                                        <div className="p-col">
                                            <label className="p-d-block" 
                                                style={{
                                                    color: '#4D80E4',
                                                    fontFamily: 'Sarala',
                                                    fontSize: '19px'}}>
                                            Nombre del Proyecto</label>
                                            <InputText 
                                                name="Nombre"
                                                value={Props.FormValues.Proyecto.Nombre}
                                                type="text" 
                                                disabled/>
                                        </div>
                                    </div>
                                    <div className="p-col-12">
                                        <div className="p-grid p-fluid">
                                            <div className="p-col-12 p-lg-6">
                                                <label className="p-d-block" 
                                                    style={{
                                                        color: '#4D80E4',
                                                        fontFamily: 'Sarala',
                                                        fontSize: '20px'}}>
                                                Fecha Inicio</label>
                                                <Calendar 
                                                    value={Props.FormValues.Proyecto.FechaInicio? new Date(Props.FormValues.Proyecto.FechaInicio): new Date()}
                                                    onChange={Props.HandleChange}
                                                    disabled
                                                    name="FechaInicio"
                                                    dateFormat="dd/mm/yy"/>
                                            </div>
                                            <div className="p-col-12 p-lg-6">
                                                    <label className="p-d-block" 
                                                        style={{
                                                            color: '#4D80E4',
                                                            fontFamily: 'Sarala',
                                                            fontSize: '20px'}}>Fecha Fin</label>
                                                    <Calendar 
                                                        value={Props.FormValues.Proyecto.FechaFin? new Date(Props.FormValues.Proyecto.FechaFin): new Date()}
                                                        onChange={Props.HandleChange}
                                                        disabled
                                                        name="FechaFin"
                                                        dateFormat="dd/mm/yy"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12">
                            <div className="p-d-flex footer p-ml-auto">
                                <Button 
                                    type="button"
                                    label={Props.Request ? "Cargando..." : "Actualizar"}
                                    style={{
                                        background:'#4D80E4',
                                        border: '1px solid #4D80E4'}}
                                    icon={Props.Request ? "" : "pi pi-check" } 
                                    onClick={(e) => Props.ActualizarPlanDePrueba(e)}
                                    className="p-ml-auto"
                                    disabled={Props.Request}/>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}