import React from 'react'
import ProyectoHeader from '../Components/ProyectoHeader'
import ProyectoBody from '../Components/ProyectoBody'
import ProyectoModal from '../Components/ProyectoModal'
import ProyectoFiltro from '../Components/ProyectoFiltro'
import { BackLogService } from '../../Domain/Services/BackLogService'
import { EmpleadoService } from '../../Domain/Services/EmpleadoService'
import { ProyectoService } from '../../Domain/Services/ProyectoService'
import { VersionProductoService } from '../../Domain/Services/VersionProductoService'
import { EstadoService } from '../../Domain/Services/EstadoService'
import { AuthHelper } from '../../Domain/Utils/AuthHelper'
import { IBackLog } from '../../Domain/Models/IBackLog'
import { IImplementacionBackLog } from '../../Domain/Models/IImplementacionBackLog'
import { IFiltro } from '../../Domain/Models/IFiltro'
import { IPaginacion } from '../../Domain/Models/IPaginacion'
import { IToken } from '../../Domain/Models/IToken'
import { MotivoVersionamientoService } from '../../Domain/Services/MotivoVersionamientoService'

interface IProps {
    Token: IToken
}

interface IState {
    Modal: boolean
    Pendiente: IPaginacion
    EnCurso: IPaginacion
    StandBy: IPaginacion
    Hecho: IPaginacion
    ListaPendienteOriginal: IPaginacion
    ListaEnCursoOriginal: IPaginacion
    ListaStandByOriginal: IPaginacion
    ListaHechoOriginal: IPaginacion
    HorasEstimadas: number
    HorasEjecutadas: number
    BackLog: IBackLog
    Filtro: IFiltro
    Producto: Array<object>
    ImplementacionBackLog: Array<object>
    Empleado: Array<object>
    Estado: Array<object>
    Errores: object
    Request: boolean
    ListaMotivoVersionamiento: Array<object>
}

export default class Proyecto extends React.Component<IProps, IState> {
    
    cBackLog = {
        PKID: 0,
        Nombre: '',
        Descripcion: '',
        Version: 0,
        FechaCreacion: new Date(),
        Empleado:{
            PKID:  parseInt(AuthHelper.GetDecoded().sub,10),
            Codigo: '',
            Nombre: '',
            Area:{
                PKID: 0,
                Codigo: '',
                Nombre: ''
            }
        },
        FechaFin: new Date(),
        HorasGerencia: 0,
        HorasEstimadas: 0,
        HorasEjecutadas: 0,
        FechaInicio: new Date(),
        Estado:{
            PKID: 0,
            Descripcion: ''
        },
        Producto : {
            PKID : 0,
            Nombre : '',
            VersionProducto : []
        },
        VersionProducto : {
            PKID : 0,
            Version : ''
        },
        ImplementacionBackLog : {
            PKID : 0,
            Nombre : '',
            Version: 0,
            ImplementacionBackLogVersion : []
        },
        ImplementacionBackLogVersion : {
            PKID : 0,
            Version : 0
        },
        Objetivo:'',
        MotivoVersionamiento:{
            PKID:0,
            Descripcion:''
        },
        DetalleVersionamiento:'',
        Activo:false,
        PorcentajeAvance: 0
    }

    cFiltro = {
        PKID:0,
        IDUsuario: parseInt(AuthHelper.GetDecoded().sub,10),
        IDTipo:2,
        FechaInicio:new Date(),
        FechaFin:new Date(),
        IDSClientes:'',
        IDSAreas:'',
        IDSProductos:'',
        IDSResponsables:'',
        IDSEstados:'',
        IDSTickets:'',
        IDSProyectos:'',
        FechaEntregaInicio:new Date("01/01/2000"),
        FechaEntregaFin:new Date("01/01/2050")
    }

    cPaginacion = {
        Lista: [],
        Paginacion: {
            NumeroPagina: 1, 
            RegistrosPagina: 50000, 
            NumeroPaginas: 0, 
            IDEstado: 1, 
            IDUsuario: parseInt(AuthHelper.GetDecoded().sub,10),
        },
        Loading: true
    }

    constructor(props: any){
        super(props)
        this.state = {
            Modal: false,
            Pendiente: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 1}},
            EnCurso: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 2}},
            StandBy: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 5}},
            Hecho: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 3}},
            ListaPendienteOriginal: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 1}},
            ListaEnCursoOriginal: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 2}},
            ListaStandByOriginal: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 5}},     
            ListaHechoOriginal: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 3}},   
            HorasEstimadas: 0,
            HorasEjecutadas: 0,
            BackLog: this.cBackLog,
            Filtro: this.cFiltro,
            Producto: [],
            ImplementacionBackLog:[],
            Empleado: [],
            Estado: [],
            Errores:{},
            Request : false,
            ListaMotivoVersionamiento:[]
        }
    }

    componentDidMount(){
        this.ListarEmpleado()
        this.ListarEstado()
        this.ListarProducto()
        this.ListarImplementacionBackLog()
        this.ListarMotivoVersionamiento()    
        this.ObtenerFiltro() 
    }

    ListarMotivoVersionamiento(){
        MotivoVersionamientoService.Listar()
        .then( (response : any) => {
            if (response.success) {
                var aMotivoVersionamiento : Array<object> = []
                Object.keys(JSON.parse(response.data)).forEach(function(key){
                    var iMotivoVersionamiento = {
                        code: JSON.parse(response.data)[key].PKID,
                        name: JSON.parse(response.data)[key].Descripcion,
                    }
                    aMotivoVersionamiento.push(iMotivoVersionamiento)
                })
                this.setState({ListaMotivoVersionamiento: aMotivoVersionamiento})
            }else{
                alert(response.data)
            }
        })
        .catch( (error) => {
            alert(error)
        })
    }

    ListarEmpleado(){
        EmpleadoService.Listar()
        .then((response : any) => {
            if(response.success){
                var x = AuthHelper.GetDecoded()
                var aEmpleado: Array<object> = []
                Object.keys(JSON.parse(response.data)).forEach(function(key){
                    
                    if(AuthHelper.GetDecoded().Area === 'Desarrollo'){
                        if(JSON.parse(response.data)[key].Area.Nombre == 'Desarrollo'){
                            var iEmpleado = {
                                code: JSON.parse(response.data)[key].PKID,
                                name: JSON.parse(response.data)[key].Nombre,
                            }
                            aEmpleado.push(iEmpleado);
                        }
                    }else{
                        if(JSON.parse(response.data)[key].Area.Nombre == 'Implementacion'){
                            var iEmpleado = {
                                code: JSON.parse(response.data)[key].PKID,
                                name: JSON.parse(response.data)[key].Nombre,
                            }
                            aEmpleado.push(iEmpleado);
                        }
                    }
                });
                this.setState({Empleado : aEmpleado})
            }else{
                alert(response.data)
            }
        }).catch((error) => {
            alert(error)
        })
    }

    ListarEstado(){
        EstadoService.Listar()
        .then( (response : any) => {
            if(response.success){
                var aEstado: Array<object> = []
                Object.keys(JSON.parse(response.data)).forEach(function(key){
                    var iEstado = {
                        code: JSON.parse(response.data)[key].PKID,
                        name: JSON.parse(response.data)[key].Descripcion,
                    }
                    aEstado.push(iEstado);
                });
                this.setState({Estado : aEstado})
            }else{
                throw new Error(response.data);
            }
        })
        .catch( (error) => {
            alert(error)
        })
    }

    ObtenerFiltro(){
        ProyectoService.ObtenerFiltro(parseInt(AuthHelper.GetDecoded().sub,10))
        .then( (response : any) => {
            if(response.success){
                this.setState({Filtro: JSON.parse(response.data)})
            }else{
                let IDSResponsables=JSON.parse(response.data).IDSResponsables
                let IDSEstados='1-2-3-5'
                if(IDSResponsables===null){
                    IDSResponsables="-"+AuthHelper.GetDecoded().IDEmpleado
                    this.OnChangeFiltro(IDSResponsables,"IDSResponsables")
                    this.OnChangeFiltro(IDSEstados,"IDSEstados")
                    this.setState({
                        Filtro: {
                            ...this.state.Filtro,
                            IDSResponsables: IDSResponsables
                        }
                    })
                    this.setState({
                        Filtro: {
                            ...this.state.Filtro,
                            IDSEstados: IDSEstados
                        }
                    })
                }

                //this.setState({Filtro: this.cFiltro})
            }
        })
        .catch( (error) => {
            alert(error)
        })
        .finally( () => {
            this.ListarProyecto('Pendiente')
            this.ListarProyecto('EnCurso')
            this.ListarProyecto('StandBy')
            this.ListarProyecto('Hecho')
        })
    }

    ObtenerFiltro2(){
        ProyectoService.ObtenerFiltro(parseInt(AuthHelper.GetDecoded().sub,10))
        .then( (response : any) => {
            if(response.success){
                this.setState({Filtro: JSON.parse(response.data)})
            }else{
                this.setState({Filtro: this.cFiltro})
            }
        })
        .catch( (error) => {
            alert(error)
        })
        .finally( () => {
            
        })
    }

    ListarProducto(){
        VersionProductoService.Listar()
        .then( (response : any) => {
            if (response.success) {
                var aProducto : Array<object> = []
                let lProducto : Array<{PKID: number, Nombre: string}> = JSON.parse(response.data)
                Object.keys(lProducto).forEach(function(k1: any){
                    let oProducto: any = lProducto[k1]
                    let aVersionProducto: Array<object> = []
                    if(oProducto.VersionProducto){
                        Object.keys(oProducto.VersionProducto).forEach(function( k2 ){
                            aVersionProducto.push({
                                code : oProducto.VersionProducto[k2].PKID,
                                name : oProducto.VersionProducto[k2].Version,
                            })
                        })
                    }
                    aProducto.push({
                        code : lProducto[k1].PKID,
                        name : lProducto[k1].Nombre,
                        VersionProducto : aVersionProducto
                    })
                })
                this.setState({Producto: aProducto})
            }else{
                alert(response.data)
            }
        })
        .catch( (error) => {
            alert(error)
        })
    }

    ListarImplementacionBackLog(){
        BackLogService.ListarPaginacion(    
              this.state.Hecho.Paginacion
        )
        .then( (response : any) => {
            if (response.success) {
                var aProducto : Array<object> = []
                let jParse = JSON.parse(response.data)
                let lProducto: Array<IImplementacionBackLog | any> = jParse.BackLog ? jParse.BackLog : []                
                Object.keys(lProducto).forEach(function(k1: any){
                    let oProducto: any = lProducto[k1]
                    let aVersionProducto: Array<object> = []
                    if(oProducto.ImplementacionBackLogVersion){
                        Object.keys(oProducto.ImplementacionBackLogVersion).forEach(function( k2 ){
                            aVersionProducto.push({
                                code : oProducto.ImplementacionBackLogVersion[k2].PKID,
                                name : oProducto.ImplementacionBackLogVersion[k2].Version,
                            })
                        })
                    }
                    aProducto.push({
                        code : lProducto[k1].PKID,
                        name : lProducto[k1].Nombre,
                        VersionProducto : aVersionProducto
                    })
                })
                this.setState({ImplementacionBackLog: aProducto})
            }else{
                alert(response.data)
            }
            
        })
        .catch( (error) => {
            alert(error)
        })
    }

    ListarProyecto = (Estado: string) => {
        ProyectoService.ListarPaginacion(
            {
                'Pendiente': this.state.Pendiente.Paginacion,
                'EnCurso' : this.state.EnCurso.Paginacion,
                'StandBy' : this.state.StandBy.Paginacion,
                'Hecho' : this.state.Hecho.Paginacion
            }[Estado]
        )
        .then((response: any) => {
            if(response.success){
                let jParse = JSON.parse(response.data)
                let rBackLog: Array<IBackLog | any> = jParse.BackLog ? jParse.BackLog : []
                let rPaginacion: any = jParse.Paginacion
                switch(Estado){
                    case 'Pendiente': 
                        this.setState({Pendiente: {Lista: this.state.Pendiente.Lista.concat(rBackLog),Paginacion: rPaginacion,Loading: false}},() => this.CalcularHoras(Estado))
                        this.setState({ListaPendienteOriginal: {Lista: this.state.ListaPendienteOriginal.Lista.concat(rBackLog),Paginacion: rPaginacion,Loading: false}},() => this.CalcularHoras(Estado))  
                        break
                    case 'EnCurso' : 
                        this.setState({EnCurso: {Lista: this.state.EnCurso.Lista.concat(rBackLog),Paginacion: rPaginacion,Loading: false}},() => this.CalcularHoras(Estado))
                        this.setState({ListaEnCursoOriginal: {Lista: this.state.ListaEnCursoOriginal.Lista.concat(rBackLog),Paginacion: rPaginacion,Loading: false}},() => this.CalcularHoras(Estado))
                        break 
                        case 'StandBy' : 
                        this.setState({StandBy: {Lista: this.state.StandBy.Lista.concat(rBackLog),Paginacion: rPaginacion,Loading: false}},() => this.CalcularHoras(Estado))
                        this.setState({ListaStandByOriginal: {Lista: this.state.ListaStandByOriginal.Lista.concat(rBackLog),Paginacion: rPaginacion,Loading: false}},() => this.CalcularHoras(Estado))
                        break 
                    case 'Hecho' : 
                        this.setState({Hecho: {Lista: this.state.Hecho.Lista.concat(rBackLog),Paginacion: rPaginacion,Loading: false}},() => this.CalcularHoras(Estado))  
                        this.setState({ListaHechoOriginal: {Lista: this.state.ListaHechoOriginal.Lista.concat(rBackLog),Paginacion: rPaginacion,Loading: false}},() => this.CalcularHoras(Estado))                
                        break
                    default:
                        break
                }
            }else{
                alert(response.data)
            }
        })
    }

    CalcularHoras(Estado: string){
        let HEj = 0, HEs = 0
        if(Estado === 'Pendiente'){
            const cPendiente = this.state.Pendiente.Lista
            Object.keys(cPendiente).forEach(function(Index: any){
                HEj = HEj + cPendiente[Index].HorasEjecutadas
                HEs = HEs + cPendiente[Index].HorasEstimadas
            })
        }else if(Estado === 'EnCurso'){
            const cEnCurso = this.state.EnCurso.Lista
            Object.keys(cEnCurso).forEach(function(Index: any){
                HEj = HEj + cEnCurso[Index].HorasEjecutadas
                HEs = HEs + cEnCurso[Index].HorasEstimadas
            });
        }else if(Estado === 'StandBy'){
            const cStandBy = this.state.StandBy.Lista
            Object.keys(cStandBy).forEach(function(Index: any){
                HEj = HEj + cStandBy[Index].HorasEjecutadas
                HEs = HEs + cStandBy[Index].HorasEstimadas
            });
        }else{
            const cHecho = this.state.Hecho.Lista
            Object.keys(cHecho).forEach(function(Index: any){
                HEj = HEj + cHecho[Index].HorasEjecutadas
                HEs = HEs + cHecho[Index].HorasEstimadas
            });
        }
        this.setState({
            HorasEjecutadas : this.state.HorasEjecutadas + HEj,
            HorasEstimadas : this.state.HorasEstimadas + HEs
        })
    }
    
    HandleChange = (Event : React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} : any= Event.target
        switch(name){
            case 'Activo':
                    this.setState({
                        BackLog:{
                            ...this.state.BackLog,
                            Activo: !this.state.BackLog.Activo
                        }
                    })
                    break
            case 'Producto':
                this.setState({
                    BackLog : {
                        ...this.state.BackLog,
                        Producto : {
                            PKID : value.code,
                            Nombre : value.name,
                            VersionProducto : value.VersionProducto
                        }
                    },
                })
                break
                case 'ImplementacionBackLog':
                this.setState({
                    BackLog : {
                        ...this.state.BackLog,
                        ImplementacionBackLog : {
                            PKID : value.code,
                            Nombre : value.name,
                            Version:1,
                            ImplementacionBackLogVersion : value.VersionProducto
                        }
                    },
                })
                break
            case 'VersionProducto':
                this.setState({
                    BackLog : {
                        ...this.state.BackLog,
                        VersionProducto : {
                            PKID : value.code,
                            Version : value.name
                        }
                    },
                })
                break

                case 'MotivoVersionamiento':
                this.setState({
                    BackLog : {
                        ...this.state.BackLog,
                        MotivoVersionamiento : {
                            PKID : value.code,
                            Descripcion : value.name
                        }
                    },
                })
                break
                case 'ImplementacionBackLogVersion':
                    this.setState({
                        BackLog : {
                            ...this.state.BackLog,
                            ImplementacionBackLogVersion : {
                                PKID : value.code,
                                Version : value.name
                            }
                        },
                    })
                    break
            default :
                this.setState({
                    BackLog: {
                        ...this.state.BackLog,
                        [name]: value
                    }
                })
                break
        }
    }

    HandleResponse = (BackLog : IBackLog, Mode: boolean) => {
        this.setState({
            BackLog: BackLog ? BackLog : this.cBackLog,
            Modal: Mode,
            Errores: {}
        })
    }

    HandleSubmit = (Event: any) =>{
        Event.preventDefault()
        const result = ProyectoService.ValidarSchema(this.state.BackLog)
        if(Object.keys(result).length){
            return this.setState({Errores: result})
        }else{
            if(this.state.BackLog.FechaInicio > this.state.BackLog.FechaFin){
                alert("Error: La fecha final del proyecto debe ser mayor que la fecha inicial.")
            }else{
                this.setState({
                    Request : true
                }, () => this.CrearActualizarBackLog())
            }
        }
    };

    CrearActualizarBackLog(){
        ProyectoService.CrearActualizar(this.state.BackLog)
        .then( (response: any) => {
            if(response.success){
                //let jParse = JSON.parse(response.data)
                if(response.data){
                    this.setState({
                        Pendiente: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 1}},
                        EnCurso: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 2}},
                        StandBy: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 5}},
                        Hecho: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 3}},
                        Modal: false,
                        BackLog: this.cBackLog,
                        Errores: {}
                    },() => {
                        this.ListarProyecto('Pendiente')
                        this.ListarProyecto('EnCurso')
                        this.ListarProyecto('StandBy')
                        this.ListarProyecto('Hecho')
                    })
                }else{
                    //alert(jParse.Descripcion)
                    alert(response.data)
                }
            }else{
                alert(response.data)
            }
        })
        .catch( (error) => {
            alert(error)
        })
        .finally( () => {
            this.setState({
                Request: false
            })
        })
    }

    CrearActualizarFiltro(){
        ProyectoService.CrearActualizarFiltro(this.state.Filtro)
        .then( (response : any) => {
            if(response.success){
                this.setState({
                    Filtro: {
                        ...this.state.Filtro,
                        PKID: JSON.parse(response.data).PKID
                    }                    
                },() => {
                    //this.ListarProyecto('Pendiente')
                    //this.ListarProyecto('EnCurso')
                    //this.ListarProyecto('StandBy')
                    //this.ListarProyecto('Hecho')
                })
            }else{
                alert(response.data)
            }
        }).catch( (error) => {
            alert(error)
        })
    }

    LimpiarFiltro = (Event : any) => {
        ProyectoService.LimpiarFiltro(parseInt(AuthHelper.GetDecoded().sub,10))
        .then( (response : any) => {
            if(!response.success) alert(response.data)
            this.setState({                
                Modal: false,
                BackLog: this.cBackLog,
                Errores: {}
            },() => {
                this.ObtenerFiltro2()
            })
        })
        .catch( (error) => {
            alert(error)
        })
    }

    ClickAplicar = (Event : any) => {        
        window.location.reload()
        this.ObtenerFiltro()
    }

    OnChangeFiltro = (Checks : string, Nombre:string) => {
        this.setState({
            Filtro: {
                ...this.state.Filtro,
                [Nombre]: Checks
            }
        },() => this.CrearActualizarFiltro())
    }

    Paginacion = (Estado: string) => {
        if(
            (
                {
                    'Pendiente': ((this.state.Pendiente.Paginacion.NumeroPagina < this.state.Pendiente.Paginacion.NumeroPaginas) && !this.state.Pendiente.Loading),
                    'EnCurso' : ((this.state.EnCurso.Paginacion.NumeroPagina < this.state.EnCurso.Paginacion.NumeroPaginas) && !this.state.EnCurso.Loading),
                    'StandBy' : ((this.state.StandBy.Paginacion.NumeroPagina < this.state.StandBy.Paginacion.NumeroPaginas) && !this.state.StandBy.Loading),
                    'Hecho' : ((this.state.Hecho.Paginacion.NumeroPagina < this.state.Hecho.Paginacion.NumeroPaginas) && !this.state.Hecho.Loading)
                }[Estado]
            )){
                switch(Estado){
                    case 'Pendiente':
                        this.setState({
                            Pendiente: {
                                ...this.state.Pendiente,
                                Paginacion:{
                                    ...this.state.Pendiente.Paginacion,
                                    NumeroPagina: this.state.Pendiente.Paginacion.NumeroPagina + 1},
                                Loading: true
                            }
                        },() => this.ListarProyecto('Pendiente'))
                        break
                    case 'EnCurso' : 
                        this.setState({
                            EnCurso: {
                                ...this.state.EnCurso
                                ,Paginacion:{
                                    ...this.state.EnCurso.Paginacion,
                                    NumeroPagina: this.state.EnCurso.Paginacion.NumeroPagina + 1},
                                Loading: true
                            }
                        },() => this.ListarProyecto('EnCurso'))
                        break
                        case 'StandBy' : 
                        this.setState({
                            StandBy: {
                                ...this.state.StandBy
                                ,Paginacion:{
                                    ...this.state.StandBy.Paginacion,
                                    NumeroPagina: this.state.StandBy.Paginacion.NumeroPagina + 1},
                                Loading: true
                            }
                        },() => this.ListarProyecto('StandBy'))
                        break
                    case 'Hecho' : 
                        this.setState({
                            Hecho: {
                                ...this.state.Hecho,
                                Paginacion:{
                                    ...this.state.Hecho.Paginacion,
                                    NumeroPagina: this.state.Hecho.Paginacion.NumeroPagina + 1},
                                Loading: true
                            }
                        },() => this.ListarProyecto('Hecho'))
                        break
                    default :
                        break
                }
        }
    }

    MoverEstado = ( EstadoI : string, EstadoF : string, PKID : string) => {
        if(EstadoI !== EstadoF){
            let Obj = {'Pendiente': this.state.Pendiente.Lista,'EnCurso' : this.state.EnCurso.Lista,'StandBy' : this.state.StandBy.Lista,'Hecho' : this.state.Hecho.Lista}[EstadoI] || []
            switch(EstadoF){
                case 'Pendiente' :
                    this.setState({
                        BackLog: ProyectoService.ObtenerObjetoEstado(Obj,PKID,1)
                    },() => this.CrearActualizarBackLog())
                    break
                case 'EnCurso' :
                    this.setState({
                        BackLog: ProyectoService.ObtenerObjetoEstado(Obj,PKID,2)
                    },() => this.CrearActualizarBackLog())
                    break
                    case 'StandBy' :
                        this.setState({
                            BackLog: ProyectoService.ObtenerObjetoEstado(Obj,PKID,5)
                        },() => this.CrearActualizarBackLog())
                        break
                case 'Hecho' :
                    this.setState({
                        BackLog: ProyectoService.ObtenerObjetoEstado(Obj,PKID,3)
                    },() => this.CrearActualizarBackLog())
                    break
                default:
                    break
            }
        }
    }

    HandleChangeBuscador = (e : any) => {
        const ListaPendienteOriginal=this.state.ListaPendienteOriginal.Lista
        const ListaEnCursoOriginal=this.state.ListaEnCursoOriginal.Lista
        const ListaHechoOriginal=this.state.ListaHechoOriginal.Lista
        const ListaStandByOriginal=this.state.ListaStandByOriginal.Lista

        const ListaPendienteFiltoBefore = this.state.ListaPendienteOriginal.Lista
        const ListaEnCursoFiltroBefore = this.state.ListaEnCursoOriginal.Lista
        const ListaHechoFiltroBefore = this.state.ListaHechoOriginal.Lista
        const ListaStandByFiltroBefore=this.state.ListaStandByOriginal.Lista

        if (e.target.value=='') {

            this.setState({
                Pendiente:{
                    ...this.state.Pendiente,
                    Lista: ListaPendienteOriginal
                }
            })
            this.setState({
                EnCurso:{
                    ...this.state.EnCurso,
                    Lista: ListaEnCursoOriginal
                }
            })
            this.setState({
                Hecho:{
                    ...this.state.Hecho,
                    Lista: ListaHechoOriginal
                }
            })
            this.setState({
                StandBy:{
                    ...this.state.StandBy,
                    Lista: ListaStandByOriginal
                }
            })
          } else {

            if (this.state.Pendiente.Lista.length === 0 || this.state.EnCurso.Lista.length === 0 || this.state.Hecho.Lista.length || this.state.StandBy.Lista.length) {
                const listaFiltradaPendiente = ListaPendienteFiltoBefore.filter((list) => list.Nombre.toLowerCase().includes(e.target.value.toLowerCase()))
                const listaFiltradaEnCurso = ListaEnCursoFiltroBefore.filter((list) => list.Nombre.toLowerCase().includes(e.target.value.toLowerCase()))
                const listaFiltradaHecho = ListaHechoFiltroBefore.filter((list) => list.Nombre.toLowerCase().includes(e.target.value.toLowerCase()))
                const listaFiltradaStandBy=ListaStandByFiltroBefore.filter((list) => list.Descripcion.toLowerCase().includes(e.target.value.toLowerCase()))
                this.setState({
                    Pendiente: {
                        ...this.state.Pendiente,
                        Lista: listaFiltradaPendiente
                    }
                })

                this.setState({
                    EnCurso: {
                        ...this.state.EnCurso,
                        Lista: listaFiltradaEnCurso
                    }
                })

                this.setState({
                    Hecho: {
                        ...this.state.Hecho,
                        Lista: listaFiltradaHecho
                    }
                })
                this.setState({
                    StandBy:{
                        ...this.state.StandBy,
                        Lista: listaFiltradaStandBy
                    }
                })
            } else {
                const listaFiltradaPendiente=this.state.Pendiente.Lista.filter((list) => list.Descripcion.toLowerCase().includes(e.target.value.toLowerCase()))
                const listaFiltradaEnCurso=this.state.EnCurso.Lista.filter((list) => list.Descripcion.toLowerCase().includes(e.target.value.toLowerCase()))
                const listaFiltradaHecho=this.state.Hecho.Lista.filter((list) => list.Descripcion.toLowerCase().includes(e.target.value.toLowerCase()))
                const listaFiltradaStandBy=this.state.StandBy.Lista.filter((list) => list.Descripcion.toLowerCase().includes(e.target.value.toLowerCase()))
                
                this.setState({
                    Pendiente:{
                        ...this.state.Pendiente,
                        Lista: listaFiltradaPendiente
                    }
                })
                this.setState({
                    EnCurso:{
                        ...this.state.EnCurso,
                        Lista: listaFiltradaEnCurso
                    }
                })
                this.setState({
                    Hecho:{
                        ...this.state.Hecho,
                        Lista: listaFiltradaHecho
                    }
                })
                this.setState({
                    StandBy:{
                        ...this.state.StandBy,
                        Lista: listaFiltradaStandBy
                    }
                })
              } 
            }
    }

    render(){
        return(
            <div className="container">
                <ProyectoHeader
                    HorasEstimadas = {this.state.HorasEstimadas}
                    HorasEjecutadas = {this.state.HorasEjecutadas}
                    OnChangeBuscador = {this.HandleChangeBuscador}/>
                    <ProyectoBody 
                    Paginacion = {this.Paginacion}
                    Pendiente = {this.state.Pendiente}
                    EnCurso = {this.state.EnCurso}
                    StandBy = {this.state.StandBy}
                    Hecho = {this.state.Hecho}
                    OnResponse = {this.HandleResponse}
                    MoverEstado = {this.MoverEstado}/>
                <form autoComplete="off" onSubmit={this.HandleSubmit}>
                    <ProyectoModal
                        Modal = {this.state.Modal}
                        HideModal = {this.HandleResponse}
                        HandleChange = {this.HandleChange}
                        BackLog = {this.state.BackLog}
                        ListaProducto = {this.state.Producto}
                        ListaImplementacionBackLog = {this.state.ImplementacionBackLog}
                        ListaMotivoVersionamiento =  {this.state.ListaMotivoVersionamiento}
                        Errores = {this.state.Errores}
                        Request = {this.state.Request}/>
                </form>
                <ProyectoFiltro 
                    Responsables = {this.state.Empleado}
                    Estados = {this.state.Estado}
                    ClickResetear = {this.LimpiarFiltro}
                    ClickAplicar = {this.ClickAplicar}
                    onChangeFiltro = {this.OnChangeFiltro}
                    Filtro = {this.state.Filtro}/>
            </div>
        )
    }
}