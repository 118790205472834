import React from 'react'
import DocumentacionBody from '../Components/DocumentacionBody'
import DocumentacionModal from '../Components/DocumentacionModal'

import { DocumentacionService} from '../../Domain/Services/DocumentacionService'

import { IDocumento } from '../../Domain/Models/IDocumento'

interface IPaginado{
    NumeroPagina: number
    RegistrosPagina: number
    NumeroPaginas: number
    fil_Nombre: string
}

interface IProps {
}

interface IState {
    Modal: boolean
    Documento: IDocumento
    Paginado: IPaginado
    ListaDocumento: Array<object>
    Errores: any
    Request: boolean
}

export default class Documentacion extends React.Component<IProps, IState> {

    cDocumento = {
        PKID : 0,
        Nombre : '',
        Link : '',
        FechaCreacion : new Date()
    }

    cPaginado = {
        NumeroPagina : 1,
        RegistrosPagina : 10,
        NumeroPaginas : 0,
        fil_Nombre : ''
    }

    Registros = [
        { RegistrosPagina: 10 },
        { RegistrosPagina: 20 },
        { RegistrosPagina: 30 }
    ]

    constructor(props: any){
        super(props)
        this.state = {
            Modal : false,
            Documento : this.cDocumento,
            Paginado: this.cPaginado,
            ListaDocumento : [],
            Errores : {},
            Request : false
        }
    }

    componentDidMount(){
        this.ListarPaginacion()
    }

    ListarPaginacion(){
        DocumentacionService.ListarPaginacion(this.state.Paginado)
        .then((response: any) => {
            if(response.success){
                this.setState({
                    Paginado: JSON.parse(response.data).Paginacion,
                    ListaDocumento : JSON.parse(response.data).Documento
                })
            }
        })
        .catch((Error) => {
            alert(Error)
        })
    }
    
    HandleChange = (Event: any) => {
        this.setState({
            Documento: {
                ...this.state.Documento,
                [Event.target.name]: Event.target.value
            }
        })
    }

    HandleSubmit = (Event: any) => {
        Event.preventDefault()
        const result = DocumentacionService.ValidarSchema(this.state.Documento);
        if(Object.keys(result).length){
            this.setState({Errores : result})
        }else{
            this.setState({
                Request : true
            },() => this.CrearActualizar())
        }
    }

    CrearActualizar(){
        DocumentacionService.CrearActualizar(this.state.Documento)
        .then( (response: any) => {
            alert(response.data)
            if(response.success){
                this.setState({
                    Modal :false,
                },() => {
                    this.ListarPaginacion()
                })
            }
        })
        .catch( (Error) => {
            alert(Error)
        })
        .finally( () => {
            this.setState({
                Request : false
            })
        })
    }

    HideVisibleModal = (Mode: boolean) => {
        this.setState({
            Modal: Mode
        })
    }

    OnRegistroChange = (Event: any) =>{
        this.setState({
            Paginado : {
                ...this.state.Paginado,
                NumeroPagina: 1,
                RegistrosPagina: Event.value.RegistrosPagina,
        }},() =>{ this.ListarPaginacion() })
    }

    HandlePreviousLL = (Event: any) =>{
        this.setState({
            Paginado : this.cPaginado
        },() =>{ this.ListarPaginacion() })
    }

    HandlePrevious = (Event: any) =>{
        this.setState({Paginado : {
            ...this.state.Paginado,
            NumeroPagina: this.state.Paginado.NumeroPagina - 1,
        }},() =>{ this.ListarPaginacion() })
    }

    HandleFollowing = () => {
        this.setState({Paginado : {
            ...this.state.Paginado,
            NumeroPagina: this.state.Paginado.NumeroPagina + 1,
        }},() =>{ this.ListarPaginacion() })
    }

    HandleFollowingLL = () => {
        this.setState({Paginado : {
            NumeroPagina: this.state.Paginado.NumeroPaginas,
            RegistrosPagina: this.state.Paginado.RegistrosPagina,
            NumeroPaginas: this.state.Paginado.NumeroPaginas,
            fil_Nombre : this.state.Paginado.fil_Nombre
        }},() =>{ this.ListarPaginacion() })
    }

    OnResponse = (Documento: any) => {
        if(Documento){
            this.setState({ 
                Documento : Documento,
                Errores: {}
            },() => this.HideVisibleModal(true))
        }else{
            this.setState({ 
                Documento : this.cDocumento,
                Errores: {}
            },() => this.HideVisibleModal(true))
        }
    }

    render(){
        return(
            <div className="container">
                <DocumentacionBody
                    NuevoDocumento = {() => this.OnResponse(null)}
                    ListaDocumento={this.state.ListaDocumento}
                    EditarDocumento={this.OnResponse}
                    AbrirURL={(Obj: {Link: string}) => window.open(Obj.Link)}
                    Paginado={this.state.Paginado}
                    Registros= {this.Registros}
                    OnRegistroChange = {this.OnRegistroChange}
                    HandlePreviousLL = {this.HandlePreviousLL}
                    HandlePrevious = {this.HandlePrevious}
                    HandleFollowing = {this.HandleFollowing}
                    HandleFollowingLL = {this.HandleFollowingLL}/>
                    <form autoComplete="Off" onSubmit={this.HandleSubmit}>
                        <DocumentacionModal
                            Modal = {this.state.Modal}
                            HideModal = {() => this.HideVisibleModal(false)}
                            HandleChange = {this.HandleChange}
                            FormValues = {this.state.Documento}
                            Errores = {this.state.Errores}
                            Request = {this.state.Request}/>
                    </form>
            </div>
        )
    }
}