import React from 'react'
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown'
import { Button } from 'primereact/button'

import '../Assets/Css/Filtro.css'

export default function TicketFiltro(props : any){

    const getClientes = () => {
        if(props.Clientes!==null){
            if(Array.isArray(props.Clientes)){
                let Clientes = props.Clientes
                let clienteSelecccionado = null
                let checksBd = props.Filtro.IDSClientes.split('-')
                Object.keys(checksBd).forEach(function(key){
                    if(checksBd[key]!==''){
                        let itemCheck=null
                        Object.keys(Clientes).forEach(function(key2){
                            if(Clientes[key2].code === parseInt(checksBd[key])){
                                itemCheck = Clientes[key2]
                            }
                        })
                        if(itemCheck!=null){
                            clienteSelecccionado=itemCheck
                        }
                    }
                })
                return clienteSelecccionado
            }else{
                return 0
            }
        }else{
            return 0
        }
    }

    const getAreas = () => {
        if(props.Areas!==null){
            if(Array.isArray(props.Areas)){
                let Areas = props.Areas
                let lAreas : any = []
                let checksBd = props.Filtro.IDSAreas.split('-')
                Object.keys(checksBd).forEach(function(key){
                    if(checksBd[key]!==''){
                        let itemCheck=null
                        Object.keys(Areas).forEach(function(key2){
                            if(Areas[key2].code===parseInt(checksBd[key])){
                                itemCheck=Areas[key2]
                            }
                        })
                        if(itemCheck!=null){
                            lAreas.push(itemCheck)
                        }
                    }
                })
                return lAreas
            }else{
                return []
            }
        }else{
            return []
        }
    }

    const getProductos = () => {
        if(props.Productos!==null){
            if(Array.isArray(props.Productos)){
                let Productos = props.Productos
                let lProductos : any = []
                let checksBd = props.Filtro.IDSProductos.split('-')
                Object.keys(checksBd).forEach(function(key){
                    if(checksBd[key]!==''){
                        let itemCheck=null
                        Object.keys(Productos).forEach(function(key2){
                            if(Productos[key2].code===parseInt(checksBd[key])){
                                itemCheck=Productos[key2]
                            }
                        })
                        if(itemCheck!=null){
                            lProductos.push(itemCheck)
                        }
                    }
                });
                return lProductos
            }else{
                return []
            }
        }else{
            return []
        }
    }

    const getResponsables = () => {
        if(props.Responsables!==null){
            if(Array.isArray(props.Responsables)){
                let Responsables = props.Responsables
                let lResponsables : any = []
                let checksBd = props.Filtro.IDSResponsables.split('-')
                Object.keys(checksBd).forEach(function(key){
                    if(checksBd[key]!==''){
                        let itemCheck=null
                        Object.keys(Responsables).forEach(function(key2){
                            if(Responsables[key2].code===parseInt(checksBd[key])){
                                itemCheck=Responsables[key2]
                            }
                        })
                        if(itemCheck!=null){
                            lResponsables.push(itemCheck)
                        }
                    }
                })
                return lResponsables
            }else{
                return []
            }
        }else{
            return []
        }
    }

    const getEstados = () => {
        if(props.Estados!==null){
            if(Array.isArray(props.Estados)){
                let Estados = props.Estados
                let lEstados : any = []
                let checksBd=props.Filtro.IDSEstados.split('-')
                Object.keys(checksBd).forEach(function(key){
                    if(checksBd[key]!==''){
                        let itemCheck=null
                        Object.keys(Estados).forEach(function(key2){
                            if(Estados[key2].code===parseInt(checksBd[key])){
                                itemCheck=Estados[key2]
                            }
                        })
                        if(itemCheck!=null){
                            lEstados.push(itemCheck)
                        }
                    }
                })
                return lEstados
            }else{
                return []
            }
        }else{
            return []
        }
    }

    const onAreaChange = (e : any) => {
        let AreasSeleccionadas = getAreas()
        
        if (e.checked) {
            AreasSeleccionadas.push(e.value)
        }else{
            for (let i = 0; i < AreasSeleccionadas.length; i++) {
                const temporal = AreasSeleccionadas[i]
                if (temporal.code === e.value.code) {
                    AreasSeleccionadas.splice(i, 1)
                    break;
                }
            }
        }

        let itemsChecks=''
        Object.keys(AreasSeleccionadas).forEach(function(key){
            var it = AreasSeleccionadas[key]
            itemsChecks=itemsChecks+'-'+it.code
        })
        props.onChangeFiltro(itemsChecks,"IDSAreas")
    }

    const onProductoChange = (e : any) => {
        let ProductosSeleccionados = getProductos()

        if (e.checked) {
            ProductosSeleccionados.push(e.value)
        }else{
            for (let i = 0; i < ProductosSeleccionados.length; i++) {
                const temporal = ProductosSeleccionados[i]
                if (temporal.code === e.value.code) {
                    ProductosSeleccionados.splice(i, 1)
                    break;
                }
            }
        }

        let itemsChecks=''
        Object.keys(ProductosSeleccionados).forEach(function(key){
            var it = ProductosSeleccionados[key]
            itemsChecks=itemsChecks+'-'+it.code
        });
        props.onChangeFiltro(itemsChecks,"IDSProductos")
    }

    const onResponsableChange = (e : any) => {
        let Responsablesleccionados = getResponsables()
        if (e.checked) {
            Responsablesleccionados.push(e.value)
        }else{
            for (let i = 0; i < Responsablesleccionados.length; i++) {
                const temporal = Responsablesleccionados[i]
                if (temporal.code === e.value.code) {
                    Responsablesleccionados.splice(i, 1)
                    break;
                }
            }
        }

        let itemsChecks=''
        Object.keys(Responsablesleccionados).forEach(function(key){
            var it = Responsablesleccionados[key]
            itemsChecks=itemsChecks+'-'+it.code
        })
        props.onChangeFiltro(itemsChecks,"IDSResponsables")
    }

    const onEstadoChange = (e : any) => {
        let EstadosSeleccionados = getEstados()

        if (e.checked) {
            EstadosSeleccionados.push(e.value)
        }else{
            for (let i = 0; i < EstadosSeleccionados.length; i++) {
                const temporal = EstadosSeleccionados[i]
                if (temporal.code === e.value.code) {
                    EstadosSeleccionados.splice(i, 1)
                    break;
                }
            }
        }

        let itemsChecks=''
        Object.keys(EstadosSeleccionados).forEach(function(key){
            var it = EstadosSeleccionados[key]
            itemsChecks=itemsChecks+'-'+it.code
        })
        props.onChangeFiltro(itemsChecks,"IDSEstados")
        //CerrarFiltro()
    }
    
    const onClienteChange = (e : any) =>{
        props.onClienteChange(e)
        //CerrarFiltro()
    }

    const ClickResetear=(e : any)=>{
        props.ClickResetear(e)
        //CerrarFiltro()
    }

    const AplicarFiltro = (e:any) =>{
        props.AplicarFiltro(e)
        CerrarFiltro()
    }

    const eliminarFiltro = (e:any) =>{
        props.eliminarFiltro(e)
        CerrarFiltro()
    }

    const CerrarFiltro = () => {
        (document.getElementById('PanelFiltro') as HTMLInputElement).classList.remove('active');
        (document.getElementById('PopupFiltro') as HTMLInputElement).classList.remove('active');
    }

    const TemplateClienteSeleccionado = (option : any, props : any) => {
        if (option) {
            return (
                <div className="country-item country-item-value">
                    <div>{option.name}</div>
                </div>
            );
        }
        return (
            <span  className="country-item country-item-value">
                {props.placeholder}
            </span>
        )
    }

    const TemplateOpcionCliente = (option : any) => {
        return (
            <div className="country-item">
                <div>{RecortadorTexto(option.name, 18)}</div>
            </div>
        );
    }

    const RecortadorTexto = (texto : string, limite : number) => {
        var puntosSuspensivos = "...";
        var texto2=""+texto;
        if (texto2.length > limite) {
            texto2 = texto2.substring(0, limite) + puntosSuspensivos;
        }
        return texto2;
    }

    return(
        <div id="PanelFiltro" className="Slide-Filtro">
            <div className="PopupFiltro" id="PopupFiltro">
                 <div className="HeaderPopup">
                    <div className="p-d-flex p-jc-between">
                        <div className="Title">Filtro</div>
                        <div className="btn-cerrar-popup" onClick={(e) => CerrarFiltro()}
                        ><i className="fas fa-times"></i></div>
                        </div>
                </div>
                <div className="BodyFiltro">
                    <div className="card">
                        <h3>Cliente</h3>
                        <Dropdown 
                            value={getClientes()}
                            options={props.Clientes}
                            onChange={onClienteChange}
                            optionLabel="name" 
                            filter showClear filterBy="name" 
                            placeholder="Seleccione Cliente"
                            valueTemplate={TemplateClienteSeleccionado} 
                            itemTemplate={TemplateOpcionCliente} />
                    </div>
                    <div className="card">
                        <h3>Area</h3>
                        {
                            props.Areas.map((entidad : any) => {
                                return (
                                    <div key={entidad.code} className="p-field-checkbox">
                                        <Checkbox 
                                            name = "category" 
                                            value = {entidad} 
                                            onChange = {onAreaChange} 
                                            checked = {getAreas().some(
                                                (item : any) => item.code === entidad.code
                                            )} 
                                            disabled = {entidad.code === 'R'} />
                                        <label htmlFor={entidad.code}>{entidad.name}</label>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="card">
                        <h3>Producto</h3>
                        {
                            props.Productos.map((entidad : any) => {
                                return (
                                    <div key={entidad.code} className="p-field-checkbox">
                                        <Checkbox 
                                            name="category" 
                                            value={entidad} 
                                            onChange={onProductoChange} 
                                            checked={getProductos().some(
                                                (item : any) => item.code === entidad.code
                                            )}
                                            disabled={entidad.code === 'R'} />
                                        <label htmlFor={entidad.code}>{entidad.name}</label>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="card">
                        <h3>Responsable</h3>
                        {
                            props.Responsables.map((entidad : any) => {
                                return (
                                    <div key={entidad.code} className="p-field-checkbox">
                                        <Checkbox 
                                            name="category" 
                                            value={entidad} 
                                            onChange={onResponsableChange} 
                                            checked={getResponsables().some(
                                                (item : any) => item.code === entidad.code
                                            )} 
                                            disabled={entidad.code === 'R'} />
                                        <label htmlFor={entidad.code}>{entidad.name}</label>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="card">
                        <h3>Estado</h3>
                        {
                            props.Estados.map((entidad : any) => {
                                return (
                                    <div key={entidad.code} className="p-field-checkbox">
                                        <Checkbox 
                                            name="category" 
                                            value={entidad} 
                                            onChange={onEstadoChange} 
                                            checked={getEstados().some(
                                                (item : any) => item.code === entidad.code
                                            )} 
                                            disabled={entidad.code === 'R'} />
                                        <label htmlFor={entidad.code}>{entidad.name}</label>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="card">
                            <Button 
                                label="Aplicar" 
                                style={{
                                    marginRight:'5px',
                                    background:'#4D80E4',
                                    border: '1px solid #4D80E4'}}
                                icon="pi pi-check" 
                                onClick={AplicarFiltro}
                                className="p-ml-auto"
                                autoFocus />
                                 <Button 
                                label="Borrar" 
                                style={{
                                    background:'#4D80E4',
                                    border: '1px solid #4D80E4'}}
                                icon="pi pi-check" 
                                onClick={eliminarFiltro}
                                className="p-ml-auto"
                                autoFocus />
                        </div>
                </div>
            </div>
        </div>
    )
}