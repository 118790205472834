import { AreaRepository } from '../../Infrastructure/Repositories/AreaRepository'
import { AreaDTO } from '../Models/AreaDTO'

export const AreaService = {
    Listar: () => {
        return AreaRepository.Listar()
    },
    CrearActualizar: (body : AreaDTO) => {
        return AreaRepository.CrearActualizar(body)
    }
}