import React from 'react'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import { Calendar } from 'primereact/calendar'
import { Dropdown } from 'primereact/dropdown'
import { Button } from 'primereact/button'
import { Editor } from 'primereact/editor'
import { Local } from './LocaleSettings'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import '../Assets/Css/PanelProyecto.css'
import { FileUpload } from 'primereact/fileupload';
import { Checkbox } from 'primereact/checkbox'

interface IProps{
    HandleChange: any
    PasFormValues: any
    FormValues: any
    Errores: any
    CrearVersion: any
    GuardarTemporal: any
    ListaEmpleado: Array<object>
    ListaEstado: Array<object>
    ListaProducto: Array<object>
    ListaCliente: Array<object>    
    ListaMotivoVersionamiento: Array<object>
    CrearNuevoStakeholder: any
    ActualizarStakeholder: any
    EliminarStakeholder: any
    OnRowReorder: any
    SubirArchivo: any
    BorrarArchivo: any
    DescargarArchivo: any
}

export default function ItemBackLogDetalle(Props : IProps){

    const File = React.createRef<FileUpload>()

    const ListaProducto = [
        { code: '23', name: 'FlexMessenger' }       
    ];

    const ImageArchivo = (RowData: any) => {
        var extension = RowData.Extension
        let icono=''
        let fondo=''

        if(extension === 'txt'){
            icono = 'pi pi-file'
            fondo = '#000000'
        } 
        else if(extension === 'pdf'){
            icono = 'pi pi-file-pdf'
            fondo = '#ff471a'
        } 
        else if(extension==='docx' || extension==='docm' || extension==='dotx' || extension==='dotm' || extension==='doc' || extension==='dot' || extension==='rtf'){
            icono = 'far fa-file-word'
            fondo = '#80e5ff'
        } 
        else if(extension==='xls' || extension==='xlsx' || extension==='xlsm' || extension==='xltx' || extension==='xltm'){
            icono = 'far fa-file-excel'
            fondo = '#00802b'
        } 
        else if(extension==='pptx' || extension==='pptm' || extension==='ppt' || extension==='potx'){
            icono = 'far fa-file-powerpoint'
            fondo = '#ffa64d'
        } 
        else if(extension==='sql' || extension==='js' || extension==='vb' || extension==='cs'){
            icono = 'fas fa-scroll'
            fondo = '#66ff66'
        } 
        else if(extension==='zip' || extension==='7z' || extension==='rar'){
            icono = 'far fa-file-archive'
            fondo = '#3385ff'
        }
        else{
            icono = 'pi pi-question'
            fondo = '#000000'
        }

        return(
            <React.Fragment>
                <div className="p-grid p-fluid ">
                    <div className="p-col-12">
                            <i className={icono} style={{'fontSize': '2em', color:`${fondo}`}}></i>
                        </div>
                </div>
            </React.Fragment>
        )
    }

    const TamañoArchivo = (RowData: any) => {
        return(<p>{(RowData.Tamagno / Math.pow(1024,2)).toFixed(2) + 'MB'}</p>)
    }

    const OperacionArchivo =(Event: any) => {
        return(
            <React.Fragment>
                <div className="p-grid p-fluid ">
                    <div className="p-col-6">
                        <Button 
                            type="button"
                            icon="pi pi-times" 
                            style={{width:'30px', height:'30px'}}
                            className="p-button-rounded p-button-outlined"
                            onClick={ () => { 
                                Props.BorrarArchivo(Event)
                            }}/>
                    </div>
                    <div className="p-col-6">
                        <Button 
                            type="button"
                            icon="pi pi-download" 
                            style={{width:'30px', height:'30px'}}
                            className="p-button-rounded p-button-outlined"
                            onClick={ () => { 
                                Props.DescargarArchivo(Event)
                            }}/>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    const FechaCreacion = (RowData: any) => {
        let newDate
        RowData.FechaCreacion !== '' ? newDate = new Date(RowData.FechaCreacion) : newDate = new Date() 
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        return `${date}${'/'}${month<10?`0${month}`:`${month}`}${'/'}${year}`
    }

    const BodyTemplate2 = (RowData: any) => {
        return (
            <React.Fragment>
                <div className="p-grid p-fluid ">
                    <div className="p-col-6">
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-outlined" type="text" onClick={(e)=>{ e.preventDefault(); Props.ActualizarStakeholder(RowData) }} />
                    </div>
                    <div className="p-col-6">
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-outlined" type="text" onClick={()=>{ Props.EliminarStakeholder(RowData) }} />
                    </div>
                </div>
            </React.Fragment>
        );
    }

    const header = (
        <>
            <span className="ql-formats">
                <button className="ql-bold" aria-label="Bold"></button>
                <button className="ql-italic" aria-label="Italic"></button>
                <button className="ql-underline" aria-label="Underline"></button>
            </span>
            <span className="ql-formats">
                <button type="button" className="ql-list" value="ordered" aria-label="Ordered List">
                    <svg viewBox="0 0 18 18"> 
                        <line className="ql-stroke" x1="7" x2="15" y1="4" y2="4"></line> 
                        <line className="ql-stroke" x1="7" x2="15" y1="9" y2="9"></line> 
                        <line className="ql-stroke" x1="7" x2="15" y1="14" y2="14"></line> 
                        <line className="ql-stroke ql-thin" x1="2.5" x2="4.5" y1="5.5" y2="5.5"></line> 
                        <path className="ql-fill" d="M3.5,6A0.5,0.5,0,0,1,3,5.5V3.085l-0.276.138A0.5,0.5,0,0,1,2.053,3c-0.124-.247-0.023-0.324.224-0.447l1-.5A0.5,0.5,0,0,1,4,2.5v3A0.5,0.5,0,0,1,3.5,6Z"></path> 
                        <path className="ql-stroke ql-thin" d="M4.5,10.5h-2c0-.234,1.85-1.076,1.85-2.234A0.959,0.959,0,0,0,2.5,8.156"></path> 
                        <path className="ql-stroke ql-thin" d="M2.5,14.846a0.959,0.959,0,0,0,1.85-.109A0.7,0.7,0,0,0,3.75,14a0.688,0.688,0,0,0,.6-0.736,0.959,0.959,0,0,0-1.85-.109"></path> 
                    </svg>
                </button>
                <button type="button" className="ql-list" value="bullet" aria-label="Unordered List">
                    <svg viewBox="0 0 18 18"> 
                        <line className="ql-stroke" x1="6" x2="15" y1="4" y2="4"></line> 
                        <line className="ql-stroke" x1="6" x2="15" y1="9" y2="9"></line> 
                        <line className="ql-stroke" x1="6" x2="15" y1="14" y2="14"></line> 
                        <line className="ql-stroke" x1="3" x2="3" y1="4" y2="4"></line> 
                        <line className="ql-stroke" x1="3" x2="3" y1="9" y2="9"></line> 
                        <line className="ql-stroke" x1="3" x2="3" y1="14" y2="14"></line> 
                    </svg>
                </button>
            </span>
        </>
    );

    const EditorDescripcion = (Event: any) => {
        Props.HandleChange({
            target:{
                name: "Descripcion",
                value: Event.htmlValue?Event.htmlValue.replace('<span ','<div ').replace('</span>','</div>'):''
            }
        })
    }

    const FormatearFecha = (Fecha : Date) => {
        const date = new Date(Fecha)
        return date.toLocaleDateString()
    }

    const Header = (
        <div className="p-d-flex p-jc-between">
            <FileUpload 
                ref={File}
                mode="basic" 
                name="file" 
                auto 
                uploadHandler={(Event) => Props.SubirArchivo(Event,File)}
                customUpload
                className="p-ml-auto"
                chooseLabel="Buscar archivo"/>
        </div>
    )

    return(
        <div id="PanelProyecto" className="overlay">
            <div className="popup" id="Popup">
                <div className="btn-cerrar-popup" onClick={() => 
                {
                    (document.getElementById('PanelProyecto') as HTMLInputElement).classList.remove('active');
                    (document.getElementById('Popup') as HTMLInputElement).classList.remove('active');
                }}
                ><i className="fas fa-times"></i></div>
                <form autoComplete="off">
                    <div className="p-grid">
                    <div className="p-col-12 p-lg-4">
                            <div className="p-grid p-fluid">
                                <div className="p-col">
                                    <label 
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '19px'}}>
                                    Nombre</label>
                                    <InputText 
                                        value={Props.FormValues.Nombre}
                                        onChange={Props.HandleChange}
                                        name="Nombre"
                                        disabled={Props.PasFormValues.Estado? (Props.PasFormValues.Estado.PKID === 3):false}
                                        type="text" 
                                        placeholder="Nombre"/>
                                    {Props.Errores.Nombre && <small className="p-invalid p-d-block">{Props.Errores.Nombre}</small>}
                                </div>
                            </div>
                            <div className="p-grid p-fluid">
                                <div className="p-col">
                                    <label 
                                        style={{
                                            color : '#4D80E4',
                                            fontFamily : 'Sarala',
                                            fontSize : '19px'}}>
                                    Descripción</label>
                                          <Editor 
                                    style={{height:'120px'}} 
                                    value={Props.FormValues.Descripcion ? Props.FormValues.Descripcion:''}                                     
                                    onTextChange={EditorDescripcion}
                                    headerTemplate={header}/>                                  
                                    {Props.Errores.Descripcion && <small className="p-invalid p-d-block">{Props.Errores.Descripcion}</small>}
                                </div>
                            </div>
                            <div className="p-col">
                            <div className="p-grid p-fluid ">
                                <div className="p-col-12">
                                    <label 
                                        className="p-d-block"
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'}}
                                    >Link Documentación Desarrollo</label>
                                     <InputText 
                                        value={Props.FormValues.LinkDocumentacionDesarrollo}
                                        onChange={Props.HandleChange}
                                        name="LinkDocumentacionDesarrollo"
                                        type="text" />                      
                                    {Props.Errores.LinkDocumentacionDesarrollo && <small className="p-invalid p-d-block">{Props.Errores.LinkDocumentacionDesarrollo}</small>} 
                                </div>
                            </div>
                        </div> 
                            <div className="p-col">  
                            <div className="p-col">  
                            <div className="p-grid p-fluid">
                                <div className="p-col-12">
                                    <label 
                                        style={{
                                            color : '#4D80E4',
                                            fontFamily : 'Sarala',
                                            fontSize : '19px'}}>
                                    Responsable de creación</label>
                                    <InputText 
                        value={Props.FormValues.Usuario?Props.FormValues.Usuario.NombreEmpleado:""}
                                       disabled
                                       name="Responsable"
                                       type="text" 
                                       placeholder="Responsable"/>
                                </div>
                            </div>
                        </div>  
                        </div>  
                        <div className="p-col">
                            <div className="p-grid p-fluid ">
                                <div className="p-col-12">
                                    <label 
                                        className="p-d-block"
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'}}
                                        >Cliente Beneficiado</label>
                                    <Dropdown 
                                        value = {{
                                            code: Props.FormValues.Cliente?Props.FormValues.Cliente.PKID:0,
                                            name: Props.FormValues.Cliente?Props.FormValues.Cliente.Nombre:""}}
                                        options = {Props.ListaCliente} 
                                        onChange = {Props.HandleChange} 
                                        name = "Cliente"
                                        optionLabel = "name" 
                                        placeholder = "Seleccione Cliente"/>
                                    {Props.Errores.Cliente && <small className="p-invalid p-d-block">{Props.Errores.Cliente}</small>}
                                </div>
                            </div>
                        </div>

                        <div className="p-col">
                            <div className="p-grid p-fluid ">
                                <div className="p-col-12">
                                    <label 
                                        className="p-d-block"
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'}}
                                        >Motivo de Versionamiento</label>
                                    <Dropdown 
                                        value={{
                                            code: Props.FormValues.MotivoVersionamiento.PKID,
                                            name: Props.FormValues.MotivoVersionamiento.Descripcion}}
                                        options={Props.ListaMotivoVersionamiento}
                                        name = "MotivoVersionamiento"
                                        onChange={Props.HandleChange}
                                        optionLabel="name"
                                        placeholder="Seleccione Motivo de Versionamiento"/>
                                    {Props.Errores.MotivoVersionamiento && <small className="p-invalid p-d-block">{Props.Errores.MotivoVersionamiento}</small>}        
                                </div>
                            </div>
                        </div>

                        <div className="p-col">
                            <div className="p-grid p-fluid ">
                                <div className="p-col-12">
                                    <label 
                                        className="p-d-block"
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'}}
                                    >Detalle de Versionamiento</label>
                                    <InputTextarea 
                                        value={Props.FormValues.DetalleVersionamiento}
                                        onChange={Props.HandleChange}
                                        name="DetalleVersionamiento"
                                        type="text" 
                                        rows={5} 
                                        cols={10}/>                              
                                    {Props.Errores.DetalleVersionamiento && <small className="p-invalid p-d-block">{Props.Errores.DetalleVersionamiento}</small>} 
                                </div>
                            </div>
                        </div> 

                        <div className="p-col">
                            <div className="p-grid p-fluid ">
                                <div className="p-col-12">                                
                                     <Checkbox       
                                     inputId="binary"  
                    checked={Props.FormValues.Activo} 
                    onChange={Props.HandleChange}
                    name="Activo" />
                      <label 
                                        className=""
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'}}
                                        >      Activo</label>
                                    {Props.Errores.Activo && <small className="p-invalid p-d-block">{Props.Errores.Activo}</small>}        
                                </div>
                            </div>
                        </div>


       




                        </div>
                       
                        <div className="p-col-12 p-lg-4">
                    <div className="p-col">
                            <div className="p-grid p-fluid ">
                                <div className="p-col-12 ">
                                <label 
                                        className="p-d-block"
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'}}
                                        >Responsable Origen</label>
                                    <Dropdown 
                                    style={{
                                        overflow: 'visible'
                                    }}
                                        value={{
                                            code: Props.PasFormValues.Empleado?Props.PasFormValues.Empleado.PKID:0,
                                            name: Props.PasFormValues.Empleado?Props.PasFormValues.Empleado.Nombre:''}}
                                        options={Props.ListaEmpleado}
                                        name = "Empleado"
                                        onChange={Props.HandleChange}
                                        optionLabel="name"
                                        placeholder="Seleccione el Responsable"/>
                                    {Props.Errores.Empleado && <small className="p-invalid p-d-block">{Props.Errores.Empleado}</small>}                                    
                                </div>                                
                            </div>
                        </div>
                        <div className="p-col">
                            <div className="p-grid p-fluid ">
                                <div className="p-col-12 ">
                                <label 
                                    style={{
                                        color: '#4D80E4',
                                        fontFamily: 'Sarala',
                                        fontSize: '20px'}}
                                >Fecha de creación</label>                                
                                    <InputText 
                                    value={Props.PasFormValues.FechaCreacion}
                                    onChange={Props.HandleChange}
                                    type="text"
                                    name="FechaCreacion"
                                    disabled/>                                         
                                </div>                                
                            </div>
                        </div>
                        <div className="p-col">
                            <div className="p-grid p-fluid ">
                                <div className="p-col-12 ">
                                    <label 
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'}}
                                    >Versión</label>
                                    <InputText 
                                    value={Props.PasFormValues.Version}
                                    onChange={Props.HandleChange}
                                    type="text"
                                    name="Version"
                                    disabled/>                                    
                                </div>                                
                            </div>
                        </div>

                        <div className="p-col">
                            <div className="p-grid p-fluid ">
                                <div className="p-col-12">
                                    <label 
                                        className="p-d-block"
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'}}
                                    >Objetivo</label>
                                    <InputTextarea 
                                        value={Props.FormValues.Objetivo}
                                        onChange={Props.HandleChange}
                                        name="Objetivo"
                                        type="text" 
                                        rows={5} 
                                        cols={10}/>
                                    {Props.Errores.Objetivo && <small className="p-invalid p-d-block">{Props.Errores.Objetivo}</small>} 
                                </div>
                            </div>
                        </div>

                        {Props.PasFormValues.PKID > 0 ?
                            <div className="p-col">
                                <div className="p-grid p-fluid ">
                                    <div className="p-col-12">
                                        <label 
                                            className="p-d-block"
                                            style={{
                                                color: '#4D80E4',
                                                fontFamily: 'Sarala',
                                                fontSize: '20px'}}
                                        >Estado</label>
                                        <Dropdown 
                                            value = {{
                                                code: Props.PasFormValues.Estado.PKID,
                                                name: Props.PasFormValues.Estado.Descripcion}}
                                            options = {Props.ListaEstado}
                                            onChange = {Props.HandleChange}
                                            name = "Estado"
                                            optionLabel = "name"
                                            placeholder = "Seleccione el Estado"/>
                                        {Props.Errores.Estado && <small className="p-invalid p-d-block">{Props.Errores.Estado}</small>}        
                                    </div>
                                </div>
                            </div>
                            :
                            <></>
                        }

<div className="p-col-12">
                                        <div className="open-card-composer"  onClick={(event) => Props.CrearNuevoStakeholder(event)}>
                                            <i className="pi pi-plus icon-sm"></i>
                                            <span>Nuevo Stakeholder</span>
                                        </div>
                                        <label 
                                            className="p-d-block"
                                            style={{
                                                color: '#4D80E4',
                                                fontFamily: 'Sarala',
                                                fontSize: '20px'}}
                                            >Stakeholders</label>
                                       
                                       <DataTable  
                                            value={Props.FormValues.Stakeholders} 
                                            paginator
                                            className="p-datatable-sm"
                                            columnResizeMode="fit"
                                            reorderableColumns 
                                            onRowReorder={Props.OnRowReorder}
                                            rowsPerPageOptions={[5,8,10]}
                                            scrollable 
                                            dataKey="id" rows={5}>
                                            <Column  rowReorder style={{ width: '20px' }}></Column>
                                            <Column field="Numero" header="Nro" style={{ width: '40px' }}></Column>                                            
                                            <Column field="Descripcion" header="Descripción" style={{ width: '200px' }}></Column>
                                            <Column body={BodyTemplate2}  header="Acción" style={{ width: '80px' }}></Column>
                                        </DataTable>
                                        
                                    </div>

                                   
                        
                    </div> 

                                 <div className="p-col-12 p-lg-4">
                   
                        
                                 <div className="p-col"> 
                            <div className="p-grid p-fluid">
                                <div className="p-col-12">
                                    <label 
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily : 'Sarala',
                                            fontSize : '19px'}}>
                                    Producto</label>
                                    <Dropdown 
                                        value = {{
                                            code: Props.FormValues.Producto.PKID+'',
                                            name: Props.FormValues.Producto.Nombre,
                                            VersionProducto : Props.FormValues.Producto.VersionProducto
                                            }}
                                        options = {Props.ListaProducto}                                       
                                        optionLabel = "name"                                       
                                        name = "Producto"
                                        onChange={Props.HandleChange}
                                        placeholder = "Seleccione Producto"/>
                                    {Props.Errores.Producto && <small className="p-invalid p-d-block">{Props.Errores.Producto}</small>}
                                </div>
                            </div>              
                        </div>    
                        <div className="p-col"> 
                            <div className="p-grid p-fluid">
                                <div className="p-col-12">
                                    <label 
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily : 'Sarala',
                                            fontSize : '19px'}}>
                                    Versión del Producto</label>
                                    <Dropdown 
                                        value={{
                                            code: Props.FormValues.VersionProducto.PKID,
                                            name: Props.FormValues.VersionProducto.Version}}
                                        options={Props.FormValues.Producto.VersionProducto} 
                                        optionLabel="name"                                        
                                        name = "VersionProducto"
                                        onChange={Props.HandleChange}
                                        placeholder="Seleccione Versión"/>
                                    {Props.Errores.VersionProducto && <small className="p-invalid p-d-block">{Props.Errores.VersionProducto}</small>}
                                </div>
                            </div>
                        </div> 

                        <div className="p-col">
                            <div className="p-grid p-fluid ">
                                <div className="p-col-12 ">
                                <label 
                                    style={{
                                        color: '#4D80E4',
                                        fontFamily: 'Sarala',
                                        fontSize: '20px'}}
                                >Fecha de Entrega</label>
                                <Calendar 
                                    value={new Date(Props.FormValues.FechaEntrega)}  
                                    onChange={Props.HandleChange}
                                    showTime 
                                    showSeconds
                                    name="FechaEntrega"
                                    locale={Local} 
                                    dateFormat="dd/mm/yy"
                                    />                                   
                                </div>                                
                            </div>
                        </div>

                        <div className="p-col">
                            <div className="p-grid p-fluid ">
                                <div className="p-col-12">
                                    <label 
                                        className="p-d-block"
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'}}
                                    >Pre-requisitos</label>
                                    <InputTextarea 
                                        value={Props.FormValues.Prerequisitos}
                                        onChange={Props.HandleChange}
                                        name="Prerequisitos"
                                        type="text" 
                                        rows={5} 
                                        cols={10}/>
                                    {Props.Errores.Prerequisitos && <small className="p-invalid p-d-block">{Props.Errores.Prerequisitos}</small>} 
                                </div>
                            </div>
                        </div>
                       

                       



                                    <div className="p-col">
                            <div className="p-grid p-fluid ">
                        <div className="p-col-12">
                                    <label 
                                        className="p-d-block"
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'}}
                                        >Archivos adjuntos</label>
                                    <DataTable 
                                        value={Props.FormValues.ArchivosAdjuntos}
                                        paginator
                                        rows={5}
                                        header={Header}
                                        className="p-datatable-sm">
                                            <Column body={ImageArchivo} header="T. de archivo"></Column>
                                            <Column field="Nombre" header="Nombre"></Column>
                                            <Column body={FechaCreacion} header="Fecha creación"/>
                                            <Column body={TamañoArchivo} header="Tamaño"></Column>
                                            <Column body={OperacionArchivo} header="Operación"></Column>
                                    </DataTable>
                                </div>
                                </div>
                        </div>
                        
                    </div>   
                            
                    </div>
                    <div className="p-d-flex footer p-jc-between">
                        <Button 
                            type = "button"
                            label = "Crear versión" 
                            onClick = {Props.CrearVersion}
                            className = "p-button-danger"/>
                        <Button 
                            type = "button"
                            label = "Guardar temporal"
                            style = {{
                                background:'#4D80E4',
                                border: '1px solid #4D80E4'}}
                            onClick = {Props.GuardarTemporal}
                            className = "p-ml-auto"/>
                    </div>
                </form>
            </div>
        </div>
    )
}