import React,{useEffect,useCallback, useRef,useState}  from 'react'
import { Checkbox } from 'primereact/checkbox'
import { Button } from 'primereact/button'
import { Calendar } from 'primereact/calendar'
import { Local } from './LocaleSettings'

export default function BackLogFiltro(props : any){

    const GetResponsables = () => {
        if(props.Responsables !== null){
            if(Array.isArray(props.Responsables)){
                
                let Responsables = props.Responsables
                let lResponsables: Array<object> = []
                let checksBd = props.Filtro.IDSResponsables.split('-')
                Object.keys(checksBd).forEach(function(key){
                    if(checksBd[key]!==''){
                        let itemCheck=null
                        Object.keys(Responsables).forEach(function(key2){
                            if(Responsables[key2].code === parseInt(checksBd[key])){
                                itemCheck=Responsables[key2]
                            }
                        })
                        if(itemCheck!=null){
                            lResponsables.push(itemCheck)
                        }
                    }
                })
                return lResponsables
            }else{
                return []
            }
        }else{
            return []
        }
    }

    const GetActivos = () => {
                let lActivos: Array<String> = []
                let checksBd = props.Filtro.IDSEstados.split('-')
                Object.keys(checksBd).forEach(function(key){
                    if(checksBd[key]!==''){
                        let itemCheck=null                        
                            if(0==parseInt(checksBd[key])){
                                itemCheck="0"
                            }     
                            if(1==parseInt(checksBd[key])){
                                itemCheck="1"
                            }                     
                        if(itemCheck!=null){
                            lActivos.push(itemCheck)
                        }
                    }
                })
                return lActivos                   
    }
    
    const OnResponsableChange = (e: any) => {
        let Responsablesleccionados = GetResponsables()
        if (e.checked) {
            Responsablesleccionados.push(e.value)
        }else{
            for (let i = 0; i < Responsablesleccionados.length; i++) {
                const temporal: any = Responsablesleccionados[i]
                if (temporal.code === e.value.code) {
                    Responsablesleccionados.splice(i, 1)
                    break;
                }
            }
        }

        let itemsChecks=''
        Object.keys(Responsablesleccionados).forEach(function(key: any){
            var it: any = Responsablesleccionados[key]
            itemsChecks=itemsChecks+'-'+it.code
        })
        props.onChangeFiltro(itemsChecks,"IDSResponsables")
        //CerrarFiltro()
    }

    const OnActivoChange=(e: any)=>{
        let ActivosSeleccionados = GetActivos()

        if (e.checked) {
            ActivosSeleccionados.push(e.value)
        }

        else {
            for (let i = 0; i < ActivosSeleccionados.length; i++) {
                const temporal: any = ActivosSeleccionados[i]
                if (temporal == e.value) {
                    ActivosSeleccionados.splice(i, 1)
                    break;
                }
            }
        }

        let itemsChecks=''
        Object.keys(ActivosSeleccionados).forEach(function(key: any){
            var it: any = ActivosSeleccionados[key]
            itemsChecks = itemsChecks+'-'+it
        });
        props.onChangeFiltro(itemsChecks,"IDSEstados")
        //CerrarFiltro()
    }

    const ClickResetear=(e: any)=>{
        props.ClickResetear(e)
        //CerrarFiltro()
    }

    const ClickAplicar=(e: any)=>{
        props.ClickAplicar(e)
        CerrarFiltro()
    }

    const CerrarFiltro = () => {
        (document.getElementById('PanelFiltro') as HTMLInputElement).classList.remove('active');
        (document.getElementById('PopupFiltro') as HTMLInputElement).classList.remove('active');
    }



    return(
        <div id="PanelFiltro" className="Slide-Filtro">
            <div className="PopupFiltro" id="PopupFiltro">
                <div className="HeaderPopup">
                    <div className="p-d-flex p-jc-between">
                        <div className="Title">Filtro</div>
                        <div className="btn-cerrar-popup" onClick={(e) => CerrarFiltro()}
                        ><i className="fas fa-times"></i></div>
                    </div>
                </div>
                <div className="BodyFiltro">

                <div className="card">
                        <h3>Fecha de creación</h3>
                        <label style={{marginRight:"5px"}}>Desde</label> <Calendar 
                                    value={undefined} 
                                    onChange={props.HandleChange}                                    
                                    name="FechaCreacionInicio"
                                    locale={Local} 
                                    dateFormat="dd/mm/yy"
                                    />    
                        <br></br><br></br>
                        <label style={{marginRight:"5px"}}>Hasta</label> <Calendar 
                                    //value={new Date(props.Filtro.FechaFin)} 
                                    onChange={props.HandleChange}                                   
                                    name="FechaCreacionFin"
                                    locale={Local} 
                                    dateFormat="dd/mm/yy"
                                    />           
                    </div>

                    <div className="card">
                        <h3>Fecha de entrega</h3>
                        <label style={{marginRight:"5px"}}>Desde</label> <Calendar 
                                    //value={new Date(props.Filtro.FechaInicio)} 
                                    onChange={props.HandleChange}                                    
                                    name="FechaEntregaInicio"
                                    locale={Local} 
                                    dateFormat="dd/mm/yy"
                                    />    
                        <br></br><br></br>
                        <label style={{marginRight:"5px"}}>Hasta</label> <Calendar 
                                    //value={new Date(props.Filtro.FechaFin)} 
                                    onChange={props.HandleChange}                                   
                                    name="FechaEntregaFin"
                                    locale={Local} 
                                    dateFormat="dd/mm/yy"
                                    />           
                    </div>

                    <div className="card">
                        <h3>Responsable</h3>
                        {
                            props.Responsables.map((entidad: any) => {
                                return (
                                    <div 
                                        key={entidad.code} 
                                        className="p-field-checkbox">
                                            <Checkbox 
                                                name="category" 
                                                value={entidad} 
                                                onChange={OnResponsableChange} 
                                                checked={
                                                    GetResponsables().some(
                                                        (item: any) => item.code === entidad.code
                                                    )} 
                                                disabled={entidad.code === 'R'} />
                                            <label htmlFor={entidad.code}>{entidad.name}</label>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="card">
                        <h3>Activo</h3>                                                    
                                    <div className="p-field-checkbox">
                                        <Checkbox 
                                            name="category" 
                                            value="1" 
                                            onChange={OnActivoChange} 
                                            checked={
                                                GetActivos().some(
                                                    (item: any) => item == "1"
                                                )} 
                                           />
                                        <label>Activo</label>
                                    </div>     
                                    <div className="p-field-checkbox">
                                    <Checkbox 
                                        name="category" 
                                        value="0" 
                                        onChange={OnActivoChange} 
                                        checked={
                                                GetActivos().some(
                                                    (item: any) => item == "0"
                                                )} 
                                       />
                                    <label>Inactivo</label>
                                </div>  
                    </div>
                    <div className="card">
                        <Button 
                            label="Aplicar" 
                            style={{
                                background:'#4D80E4',
                                border: '1px solid #4D80E4',
                                marginRight:'5px'
                            }}
                            icon="pi pi-check" 
                            onClick={ClickAplicar}
                            className="p-ml-auto"
                            autoFocus />
                            
                            <Button 
                            label="Borrar" 
                            style={{
                                background:'#4D80E4',
                                border: '1px solid #4D80E4'}}
                            icon="pi pi-check" 
                            onClick={ClickResetear}
                            className="p-ml-auto"
                            autoFocus />
                    </div>
                   
                </div>
            </div>
        </div>
    )
}
