import React, {useEffect ,useCallback} from 'react'
import { Button } from 'primereact/button'
import useNearScreen from './useNearScreen'
import debounce from 'just-debounce-it'
import { Checkbox } from 'pretty-checkbox-react'

interface IProps {
    Paginacion: any
    Pendiente: any
    EnCurso: any
    Hecho: any
    OnResponse: any
    EditarCasoPrueba: any
    EliminarCasoPruebaVersion: any
    MoverEstado: any
    ListaCheckHabilitado: any
    HandleChangeCheckItemPlanDePrueba:any
}

export default function ItemPlanDePruebaBody(Props : IProps){

    const RefPendiente = React.createRef<HTMLInputElement>()
    const RefEnCurso = React.createRef<HTMLInputElement>()
    const RefHecho = React.createRef<HTMLInputElement>()

    const NSPendiente = useNearScreen({
        externalRef :  !Props.Pendiente.Loading && RefPendiente,
        once: false
    }).isNearScreen

    const NSEnCurso = useNearScreen({
        externalRef : !Props.EnCurso.Loading && RefEnCurso,
        once: false
    }).isNearScreen

    const NSHecho = useNearScreen({
        externalRef : !Props.Hecho.Loading && RefHecho,
        once: false
    }).isNearScreen

    const DebounceNextPage = useCallback((Estado : string) => {
        debounce(Props.Paginacion(Estado),5000)
    }, [])

    useEffect(function (){
        if(NSPendiente) DebounceNextPage('Pendiente')
        if(NSEnCurso) DebounceNextPage('EnCurso')
        if(NSHecho) DebounceNextPage('Hecho')
    })

    const DragStart = (Event : any) => {
        Event.dataTransfer.setData('EstadoPKID', Event.target.id)
    }

    const DragOver = (Event : React.SyntheticEvent) => {
        Event.preventDefault()
        Event.stopPropagation()
    }

    const DropPendiente = (Event : React.DragEvent) => {
            Event.preventDefault()
            Props.MoverEstado(Event.dataTransfer.getData('EstadoPKID').split('-')[0],'Pendiente', Event.dataTransfer.getData('EstadoPKID').split('-')[1])
    }

    const DropEnCurso = (Event : React.DragEvent) => {
            Event.preventDefault()
            Props.MoverEstado(Event.dataTransfer.getData('EstadoPKID').split('-')[0],'EnCurso', Event.dataTransfer.getData('EstadoPKID').split('-')[1])
    }

    const DropHecho = (Event : React.DragEvent) => {
        Event.preventDefault()
        Props.MoverEstado(Event.dataTransfer.getData('EstadoPKID').split('-')[0],'Hecho', Event.dataTransfer.getData('EstadoPKID').split('-')[1])
    }

    const BodyTemplate = (Entidad: any) => {
        return(
            <React.Fragment>
                <div className="p-grid p-fluid ">
                    <div className="p-col-6">
                    <Button 
                        icon="pi pi-pencil" 
                        className="p-button-rounded p-button-outlined" 
                        type="text" 
                        onClick={() => { Props.EditarCasoPrueba(Entidad) }} />
                    </div>
                    <div className="p-col-6">
                    <Button 
                        icon="pi pi-trash" 
                        className="p-button-rounded p-button-danger p-button-outlined" 
                        type="text" 
                        onClick={() => { Props.EliminarCasoPruebaVersion(Entidad) }} />
                    </div>
                </div>
            </React.Fragment>
        )
    }

    const RenderListado = (ItemsPlanDePruebas: any, Estado : string) => {
        let Lista = ItemsPlanDePruebas.map((Entidad: any) => {
            return(
                <li
                    id = {Estado + '-' + Entidad.PKID}
                    draggable
                    onDragStart = {DragStart}
                    onDragOver = {DragOver}
                    key = {Estado + '-' + Entidad.PKID}>
                    <div className="comment-main-level">
                        <div className="comment-box">
                            {/** 
                            <div className="estrella" >
                                <Checkbox          
                                    checked={Entidad.ListaCheckHabilitado}
                                    onChange={(event) => Props.HandleChangeCheckItemPlanDePrueba(event, Entidad.PKID)}
                                    name="chkMove"/>  
                            </div>
                            */}
                            <div className="comment-head">
                                <h6 className="comment-name by-author" >
                                    {Entidad.Nombre}
                                </h6>
                            </div>
                            <div className="comment-content">
                                <div>
                                    <table className="table table-bordered table-sm">
                                        <thead>
                                            <tr>
                                                <th scope="col">Modulo</th>
                                                <th scope="col">Tipo de Prueba</th>
                                                <th scope="col">Accion</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td >{Entidad.Modulo.Nombre}</td>
                                                <td >{Entidad.TipoPrueba.Nombre}</td>
                                                <td >{BodyTemplate(Entidad)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            )
        })
        return Lista;
    }

    return(
        <div className="ContainerTrello">
            <div className="p-grid" style={{margin: '0px'}}>
                <div className="p-col-12 p-md-6 p-lg-4">
                    <div className="list-wrapper">
                        <div className="list" style={{height:'82vh'}}>
                            <div className="list-header">
                                <h2 className="list-header-name-assist">Pendiente</h2>
                                <div className="open-card-composer" onClick={(Event) => Props.OnResponse(Event)}>
                                    <i className="pi pi-plus icon-sm"></i>
                                    <span>Nuevo</span>
                                </div>
                            </div>
                            <div 
                                className="list-cards"
                                onDrop={DropPendiente}
                                onDragOver={DragOver}>
                                <ul id="lPendiente" className="comment-list">
                                    {RenderListado(Props.Pendiente.Lista,'Pendiente')}
                                </ul>
                                <div ref={RefPendiente}>
                                    {((Props.Pendiente.Paginacion.NumeroPagina < Props.Pendiente.Paginacion.NumeroPaginas) || Props.Pendiente.Loading)? 
                                        <div className="circleLoad"></div> 
                                        : 
                                        <></>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-col-12 p-md-6 p-lg-4">
                    <div className="list-wrapper">
                        <div className="list" style={{height:'82vh'}}>
                            <div className="list-header is-menu-shown">
                                <h2 className="list-header-name-assist">En Curso</h2>
                            </div>
                            <div 
                                className="list-cards"
                                onDrop={DropEnCurso}
                                onDragOver={DragOver}>
                                <ul id="lEnCurso" className="comment-list">
                                    {RenderListado(Props.EnCurso.Lista,'EnCurso')}
                                </ul>
                                <div ref={RefEnCurso}>
                                    {((Props.EnCurso.Paginacion.NumeroPagina < Props.EnCurso.Paginacion.NumeroPaginas) || Props.EnCurso.Loading)? 
                                        <div className="circleLoad"></div> 
                                        : 
                                        <></>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-col-12 p-md-6 p-lg-4">
                    <div className="list-wrapper">
                        <div className="list" style={{height:'82vh'}}>
                            <div className="list-header is-menu-shown">
                                <h2 className="list-header-name-assist">Hecho</h2>
                            </div>
                            <div 
                                className="list-cards"
                                onDrop={DropHecho}
                                onDragOver={DragOver}>
                                <ul id="lHecho" className="comment-list">
                                    {RenderListado(Props.Hecho.Lista,'Hecho')}
                                </ul>
                                <div ref={RefHecho}>
                                    {((Props.Hecho.Paginacion.NumeroPagina < Props.Hecho.Paginacion.NumeroPaginas) || Props.Hecho.Loading)? 
                                        <div className="circleLoad"></div> 
                                        : 
                                        <></>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}