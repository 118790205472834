import { HTTP } from "../Http/Http"

export const TicketRepository = {
    ObtenerFiltro: async (IDUsuario : number) => {
        return await HTTP.POST('Ticket/ObtenerFiltroTicket?IDUsuario='+IDUsuario,'',{})
    },
    ListarPaginacion: async (Paginacion : any) => {
        return await HTTP.POST('Ticket/ListarTicketD',Paginacion,{})
    },
    CrearActualizar: async (Ticket : any) => {
        return await HTTP.POST('Ticket/CrearActualizar',Ticket,{})
    },
    CrearActualizarFiltro: async (Filtro : any) => {
        return await HTTP.POST('Ticket/CrearActualizarFiltro',Filtro,{})
    },
    LimpiarFiltro: async (IDUsuario : number) => {
        return await HTTP.POST('Ticket/LimpiarFiltro?IDUsuario='+IDUsuario+'&IDTipo='+1,'',{})
    },
    Estado_ObtenerTodos: async() => {
        return await HTTP.POST('Ticket/Estado_ObtenerTodos','',{})
    },
    Empleado_ObtenerTodos: async() => {
        return await HTTP.POST('Ticket/Empleado_ObtenerTodos','',{})
    },
    Causante_ObtenerTodos: async() => {
        return await HTTP.POST('Ticket/Causante_ObtenerTodos','',{})
    },
    ListarEstado: async (Estado: any) => {
        return await HTTP.POST('Ticket/ListarEstado',Estado,{})
    },
    Consultar: async (ID: any) => {
        return await HTTP.POST('Ticket/Consultar',ID,{})
    }
}