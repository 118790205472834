import { IncidenteRepository } from "../../Infrastructure/Repositories/IncidenteRepository"
import { IIncidente } from "../Models/IIncidente"

const ValidarSchema = (Incidente: IIncidente) => {
    const Errores: {
        Incidencia?: string
        Detalle?: string
        Empleado?: string
        TipoError?: string
        Prioridad?: string
        Severidad?: string
        Producto?: string
        VersionProducto?: string
        Categorizacion?: string
    } = {}
    if(!Incidente.Incidencia) Errores.Incidencia = 'Incidencia es requerido'
    if(!Incidente.Detalle) Errores.Detalle = 'Detalle es requerido'
    if(!Incidente.Empleado.PKID) Errores.Empleado = 'Empleado es requerido'
    if(!Incidente.TipoError.PKID) Errores.TipoError = 'Tipo de error es requerido'
    if(Incidente.Prioridad < 0) Errores.Prioridad = 'Prioridad es requerido'
    if(!Incidente.Severidad.PKID) Errores.Severidad = 'Severidad es requerido'
    if(!Incidente.Producto.PKID) Errores.Producto = 'Producto es requerido'
    if(!Incidente.VersionProducto.PKID) Errores.VersionProducto = 'Version producto es requerido'
    if(!Incidente.Categorizacion) Errores.Categorizacion = 'Categorización es requerida'
    return Errores
}

const ObtenerObjetoEstado = (Array: Array<IIncidente>, PKID: string, IDEstado: number) => {
    let aObj: Array<IIncidente> =  Array.filter( (Obj : IIncidente) =>{
        return Obj.PKID === parseInt(PKID,10) 
    })
    let Obj: IIncidente = aObj[0] 
    return {
        ...Obj,
        Estado:{
            ...Obj.Estado,
            PKID: IDEstado
        }
    }
}

const DescargarArchivo = (Objeto:  {Archivo: string, Nombre: string, Extension: string}) => {
    const byteCharacters = atob(Objeto.Archivo)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers);
    const url = window.URL.createObjectURL(new Blob([byteArray]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', Objeto.Nombre + '.' + Objeto.Extension);
    document.body.appendChild(link);
    link.click();
}

const ValidarPaso = (Paso: any) => {
    const Errores: {Accion?: string, ResultadoEsperado?: string} = {}
    if(!Paso.Accion) Errores.Accion = 'Accion es requerido'
    if(!Paso.ResultadoEsperado) Errores.ResultadoEsperado = 'Resultado esperado es requerido'
    return Errores
}

export const IncidenteService = {
    ValidarSchema,
    ValidarPaso,
    ObtenerObjetoEstado,
    ListarPaginacion: (Paginacion : any) => {
        return IncidenteRepository.ListarPaginacion(Paginacion)
    },
    CrearActualizar: (Incidente : IIncidente) => {
        return IncidenteRepository.CrearActualizar(Incidente)
    },
    DescargarArchivo,
}