import React from 'react'
import TicketHeader from '../Components/TicketHeader'
import TicketBody from '../Components/TicketBody'
import TicketModal from '../Components/TicketModal'
import TicketFiltro from '../Components/TicketFiltro'

import { AuthHelper } from '../../Domain/Utils/AuthHelper'
import { ClienteService } from '../../Domain/Services/ClienteService'
import { AreaService } from '../../Domain/Services/AreaService'
import { TicketService } from '../../Domain/Services/TicketService'
import { VersionProductoService } from '../../Domain/Services/VersionProductoService'
import { CategorizacionService } from '../../Domain/Services/CategorizacionService'

import { IPaginacion } from '../../Domain/Models/IPaginacion'
import { ITicket } from '../../Domain/Models/ITicket'
import { IFiltro } from '../../Domain/Models/IFiltro'
import { ThemeContext } from '@emotion/react'

interface IProps {
}

interface IFormBuscador {
    Buscador: string
}

interface IState {
    Modal: boolean
    TotalTickets: number
    Pendiente: IPaginacion
    EnCurso: IPaginacion
    Hecho: IPaginacion
    ListaPendienteOriginal: IPaginacion
    ListaEnCursoOriginal: IPaginacion
    ListaHechoOriginal: IPaginacion
    Ticket: ITicket
    Filtro: IFiltro
    Cliente: Array<object>
    Area: Array<object>
    Producto: Array<object>
    Empleado: Array<object>
    Estado: Array<object>
    Errores: object
    Request: boolean
    Categorizacion: Array<object>
    Causante: Array<object>
    FormBuscador: IFormBuscador

}

export default class Ticket extends React.Component<IProps, IState> {

    cPaginacion = {
        Lista: [],
        Paginacion: {
            NumeroPagina: 1,
            RegistrosPagina: 50000,
            NumeroPaginas: 0,
            IDEstado: 1,
            IDUsuario: parseInt(AuthHelper.GetDecoded().sub, 10),
        },
        Loading: true
    }

    cTicket = {
        PKID: 0,
        Nombre: '',
        Descripcion: '',
        FechaCreacion: '',
        Codigo: 0,
        Version: 0,
        LinkOCS:  '',
        TieneOCS:false,
        InputHabilitado: false,
        Cliente: {
            PKID: 0,
            Nombre: '',
        },
        Area: {
            PKID: 0,
            Nombre: '',
        },
        Responsable: {
            PKID: parseInt(AuthHelper.GetDecoded().sub, 10),
            Administrador: false,
            Empleado: {
                PKID: 0,
                Nombre: AuthHelper.GetDecoded().Empleado
            }
        },
        Producto: {
            PKID: 0,
            Nombre: ''
        },
        EmpleadoOrigen: {
            PKID: parseInt(AuthHelper.GetDecoded().sub, 10),
            Empleado: {
                PKID: 0,
                Nombre: AuthHelper.GetDecoded().Empleado
            }
        },
        Estado: {
            PKID: 0,
            Descripcion: ''
        },
        Categorizacion: {
            PKID: 0,
            Descripcion: ''
        },
        Causante: {
            PKID: 0,
            Nombre: ''
        },
        Tareas: [],
        Observacion: '',
        EstadoFijo: false,
        Evidencias: []
    }

    cFiltro = {
        PKID: 0,
        IDUsuario: parseInt(AuthHelper.GetDecoded().sub, 10),
        IDTipo: 1,
        FechaInicio: new Date(),
        FechaFin: new Date(),
        IDSClientes: '',
        IDSAreas: '',
        IDSProductos: '',
        IDSResponsables: '',
        IDSEstados: '',
        IDSTickets: '',
        IDSProyectos: '',
        FechaEntregaInicio: new Date("01/01/2000"),
        FechaEntregaFin: new Date("01/01/2050")
    }

    cBuscador = {
        Buscador: '',
    }

    constructor(props: any) {
        super(props)
        this.state = {
            Modal: false,
            TotalTickets: 0,
            Pendiente: { ...this.cPaginacion, Paginacion: { ...this.cPaginacion.Paginacion, IDEstado: 1 } },
            EnCurso: { ...this.cPaginacion, Paginacion: { ...this.cPaginacion.Paginacion, IDEstado: 2 } },
            Hecho: { ...this.cPaginacion, Paginacion: { ...this.cPaginacion.Paginacion, IDEstado: 3 } },
            ListaPendienteOriginal: { ...this.cPaginacion, Paginacion: { ...this.cPaginacion.Paginacion, IDEstado: 1 } },
            ListaEnCursoOriginal: { ...this.cPaginacion, Paginacion: { ...this.cPaginacion.Paginacion, IDEstado: 2 } },
            ListaHechoOriginal: { ...this.cPaginacion, Paginacion: { ...this.cPaginacion.Paginacion, IDEstado: 3 } },
            Ticket: this.cTicket,
            Filtro: this.cFiltro,
            Cliente: [],
            Area: [],
            Producto: [],
            Empleado: [],
            Estado: [],
            Errores: {},
            Request: false,
            Categorizacion: [],
            Causante: [],
            FormBuscador: this.cBuscador,
        }
    }

    componentDidMount() {
        this.ListarCliente()
        this.ListarArea()
        this.ListarProducto()
        this.ListarEmpleado()
        this.ListarEstado()
        this.ListarCategorizacion()
        this.ListarCausante()
        this.obtenerFiltro()
    }

    ListarCliente() {
        ClienteService.Listar()
            .then((response: any) => {
                if (response.success) {
                    var aCliente: Array<object> = []
                    Object.keys(JSON.parse(response.data)).forEach(function (key) {
                        var iCliente = {
                            code: JSON.parse(response.data)[key].PKID,
                            name: JSON.parse(response.data)[key].Nombre,
                        }
                        aCliente.push(iCliente)
                    })
                    this.setState({ Cliente: aCliente })
                } else {
                    alert(response.data)
                }
            }).catch((error) => {
                alert(error)
            })
    }

    ListarArea() {
        AreaService.Listar()
            .then((response: any) => {
                if (response.success) {
                    var aArea: Array<object> = []
                    Object.keys(JSON.parse(response.data)).forEach(function (key) {
                        var iArea = {
                            code: JSON.parse(response.data)[key].PKID,
                            name: JSON.parse(response.data)[key].Nombre,
                        }
                        aArea.push(iArea)
                    })
                    this.setState({ Area: aArea })
                } else {
                    alert(response.data)
                }
            })
            .catch((error) => {
                alert(error)
            })
    }

    ListarProducto() {
        VersionProductoService.Listar()
            .then((response: any) => {
                if (response.success) {
                    var aProducto: Array<object> = []
                    Object.keys(JSON.parse(response.data)).forEach(function (key) {
                        var iProducto = {
                            code: JSON.parse(response.data)[key].PKID,
                            name: JSON.parse(response.data)[key].Nombre,
                        }
                        aProducto.push(iProducto)
                    })
                    this.setState({ Producto: aProducto })
                } else {
                    alert(response.data)
                }
            })
            .catch((error) => {
                alert(error)
            })
    }

    ListarCategorizacion() {
        CategorizacionService.Listar()
            .then((response: any) => {
                if (response.success) {
                    var aCategorizacion: Array<object> = []
                    Object.keys(JSON.parse(response.data)).forEach(function (key) {
                        var iCategorizacion = {
                            code: JSON.parse(response.data)[key].PKID,
                            name: JSON.parse(response.data)[key].Descripcion,
                        }
                        aCategorizacion.push(iCategorizacion)
                    })
                    this.setState({ Categorizacion: aCategorizacion })
                } else {
                    alert(response.data)
                }
            })
            .catch((error) => {
                alert(error)
            })
    }

    ListarEmpleado() {
        TicketService.Empleado_ObtenerTodos()
            .then((response: any) => {
                if (response.success) {
                    var aEmpleado: Array<object> = []
                    Object.keys(JSON.parse(response.data)).forEach(function (key) {
                        var iEmpleado = {
                            code: JSON.parse(response.data)[key].PKID,
                            name: JSON.parse(response.data)[key].Empleado.Nombre,
                        }
                        aEmpleado.push(iEmpleado)
                    })
                    this.setState({ Empleado: aEmpleado })
                } else {
                    alert(response.data)
                }
            })
            .catch((error) => {
                alert(error)
            })
    }

    ListarCausante() {
        TicketService.Causante_ObtenerTodos()
            .then((response: any) => {
                if (response.success) {
                    var aCausante: Array<object> = []
                    Object.keys(JSON.parse(response.data)).forEach(function (key) {
                        var iCausante = {
                            code: JSON.parse(response.data)[key].PKID,
                            name: JSON.parse(response.data)[key].Nombre,
                        }
                        aCausante.push(iCausante)
                    })
                    this.setState({ Causante: aCausante })
                } else {
                    alert(response.data)
                }
            })
            .catch((error) => {
                alert(error)
            })
    }


    ListarEstado() {
        TicketService.Estado_ObtenerTodos()
            .then((response: any) => {
                if (response.success) {
                    var aEstado: Array<object> = []
                    Object.keys(JSON.parse(response.data)).forEach(function (key) {
                        var iEstado = {
                            code: JSON.parse(response.data)[key].PKID,
                            name: JSON.parse(response.data)[key].Descripcion,
                        }
                        aEstado.push(iEstado)
                    })
                    this.setState({ Estado: aEstado })
                } else {
                    alert(response.data)
                }
            })
            .catch((error) => {
                alert(error)
            })
    }

    SubirArchivo = (Event: any, FileUpload: any) => {
        const file = Event.files[0]
        const reader: any = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
            let encoded = reader.result.toString().replace(/^data:(.*,)?/, '')
            if ((encoded.length % 4) > 0) {
                encoded += '='.repeat(4 - (encoded.length % 4));
            }
            var oArchivoAdjunto = {
                PKID: 0,
                Nombre: file.name.replace(/[.][^.]+$/, ''),
                FechaCreacion: '',
                Tamagno: file.size,//parseFloat(file.size / Math.pow(1024,2)).toFixed(2), //De Bytes a MegaBytes
                Archivo: encoded,
                Extension: file.name.slice((file.name.lastIndexOf(".") - 1 >>> 0) + 2)
            }
            this.setState({
                Ticket: {
                    ...this.state.Ticket,
                    Evidencias: [
                        ...this.state.Ticket.Evidencias,
                        oArchivoAdjunto
                    ]
                }
            })
        }
        reader.onerror = (Error: any) => {
            alert(Error)
        }
        FileUpload.current.files = ''
        FileUpload.current.state.files = ''
    }

    BorrarArchivo = (Event: any) => {
        let lArchivos = this.state.Ticket.Evidencias
        if (lArchivos.filter((e) => { return e === Event }).length > 0) {
            lArchivos = lArchivos.filter((e) => { return e !== Event })
        }
        this.setState({
            Ticket: {
                ...this.state.Ticket,
                Evidencias: lArchivos
            }
        })
    }

    DescargarArchivo = (Event: any) => {
        let lArchivos = this.state.Ticket.Evidencias
        if (lArchivos.filter((e) => { return e === Event }).length > 0) {
            lArchivos = lArchivos.filter((e) => { return e === Event })
            const oArchivos: any = lArchivos[0]
            TicketService.DescargarArchivo(oArchivos)
        } else {
            alert('Error: No se encontro el archivo indicado')
        }
    }

    obtenerFiltro() {
        TicketService.ObtenerFiltro(parseInt(AuthHelper.GetDecoded().sub, 10))
            .then((response: any) => {
                if (response.success) {
                    this.setState({ Filtro: JSON.parse(response.data) })
                } else {
                    this.setState({ Filtro: this.cFiltro })
                }
            })
            .catch((error) => {
                alert(error)
            })
            .finally(() => {
                this.ListarTicket('Pendiente')
                this.ListarTicket('EnCurso')
                this.ListarTicket('Hecho')
            })
    }

    ListarTicket = (Estado: string) => {

        TicketService.ListarPaginacion(
            {
                'Pendiente': this.state.Pendiente.Paginacion,
                'EnCurso': this.state.EnCurso.Paginacion,
                'Hecho': this.state.Hecho.Paginacion
            }[Estado]
        )
            .then((response: any) => {
                if (response.success) {
                    let jParse = JSON.parse(response.data)
                    let rTicket: Array<ITicket | any> = jParse.Ticket ? jParse.Ticket : []
                    let rTotalTicket: Array<ITicket | any> = jParse.Ticket ? jParse.Ticket : []
                    let rPaginacion: any = jParse.Paginacion

                    switch (Estado) {
                        case 'Pendiente':
                            this.setState({ Pendiente: { Lista: this.state.Pendiente.Lista.concat(rTicket), Paginacion: rPaginacion, Loading: false } }, () => this.TotalTickets())
                            this.setState({ ListaPendienteOriginal: { Lista: this.state.ListaPendienteOriginal.Lista.concat(rTicket), Paginacion: rPaginacion, Loading: false } }, () => this.TotalTickets())
                            break
                        case 'EnCurso':
                            this.setState({ EnCurso: { Lista: this.state.EnCurso.Lista.concat(rTicket), Paginacion: rPaginacion, Loading: false } }, () => this.TotalTickets())
                            this.setState({ ListaEnCursoOriginal: { Lista: this.state.ListaEnCursoOriginal.Lista.concat(rTicket), Paginacion: rPaginacion, Loading: false } }, () => this.TotalTickets())
                            break
                        case 'Hecho':
                            this.setState({ Hecho: { Lista: this.state.Hecho.Lista.concat(rTicket), Paginacion: rPaginacion, Loading: false } }, () => this.TotalTickets())
                            this.setState({ ListaHechoOriginal: { Lista: this.state.ListaHechoOriginal.Lista.concat(rTicket), Paginacion: rPaginacion, Loading: false } }, () => this.TotalTickets())
                            break
                        default:
                            break
                    }

                } else {
                    alert(response.data)
                }
            })
    }

    TotalTickets() {
        this.setState({
            TotalTickets: this.state.Pendiente.Lista.length + this.state.EnCurso.Lista.length + this.state.Hecho.Lista.length
        })
    }

    HandleResponse = (Ticket: ITicket, Mode: boolean) => {
        this.setState({
            Ticket: Ticket ? Ticket : this.cTicket,
            Modal: Mode,
            Errores: {}
        })
    }

    HandleChange = (Event: any) => {
        console.log(Event.target.name)
        switch (Event.target.name) {
            case 'Area': case 'Cliente': case 'Producto':
                this.setState({
                    Ticket: {
                        ...this.state.Ticket,
                        [Event.target.name]: {
                            PKID: Event.value.code,
                            Nombre: Event.value.name
                        }
                    }
                })
                break
            case 'Categorizacion':
                this.setState({
                    Ticket: {
                        ...this.state.Ticket,
                        Categorizacion: {
                            PKID: Event.value.code,
                            Descripcion: Event.value.name
                        }
                    },
                })
                break
            case 'Causante':
                this.setState({
                    Ticket: {
                        ...this.state.Ticket,
                        Causante: {
                            PKID: Event.value.code,
                            Nombre: Event.value.name
                        }
                    },
                })
                break
            case 'Empleado':
                this.setState({
                    Ticket: {
                        ...this.state.Ticket,
                        EmpleadoOrigen: {
                            PKID: Event.value.code,
                            Empleado: {
                                ...this.state.Ticket.EmpleadoOrigen.Empleado,
                                Nombre: Event.value.name
                            }
                        }
                    }
                })
                break
            case 'Estado':
                this.setState({
                    Ticket: {
                        ...this.state.Ticket,
                        Estado: {
                            PKID: Event.value.code,
                            Descripcion: Event.value.name
                        }
                    }
                })
                break
            case 'EstadoFijo':
                this.setState({
                    Ticket: {
                        ...this.state.Ticket,
                        EstadoFijo: !this.state.Ticket.EstadoFijo
                    }
                })
                break
            case 'TieneOCS':
                    this.setState({
                        Ticket: {
                            ...this.state.Ticket,
                            TieneOCS: !this.state.Ticket.TieneOCS,
                            InputHabilitado: !this.state.Ticket.TieneOCS
                        }
                    })
                    break
            default:
                this.setState({
                    Ticket: {
                        ...this.state.Ticket,
                        [Event.target.name]: Event.target.value
                    }
                })
                break
        }

    }

    HandleSubmitTicket = (Event: React.ChangeEvent<HTMLFormElement>) => {
        Event.preventDefault()
        const result = TicketService.ValidarSchema(this.state.Ticket)
        if (Object.keys(result).length) {
            return this.setState({ Errores: result })
        } else {
            this.setState({
                Request: true
            }, () => {
                this.CrearActualizarTicket()
            })
        }
    }

    CrearActualizarTicket = () => {
        TicketService.CrearActualizar(this.state.Ticket)
            .then((response: any) => {
                if (response.success) {
                    if (JSON.parse(response.data).PKID) {
                        this.setState({
                            Pendiente: { ...this.cPaginacion, Paginacion: { ...this.cPaginacion.Paginacion, IDEstado: 1 } },
                            EnCurso: { ...this.cPaginacion, Paginacion: { ...this.cPaginacion.Paginacion, IDEstado: 2 } },
                            Hecho: { ...this.cPaginacion, Paginacion: { ...this.cPaginacion.Paginacion, IDEstado: 3 } },
                            Modal: false,
                            Ticket: this.cTicket,
                            Errores: {}
                        }, () => {
                            this.ListarTicket('Pendiente')
                            this.ListarTicket('EnCurso')
                            this.ListarTicket('Hecho')
                        })
                    } else {
                        alert(JSON.parse(response.data).Descripcion)
                    }
                } else {
                    alert(response.data)
                }
            })
            .catch((error) => {
                alert(error)
            })
            .finally(() => {
                this.setState({
                    Request: false
                })
            })
    }

    CrearActualizarFiltro() {
        TicketService.CrearActualizarFiltro(this.state.Filtro)
            .then((response: any) => {
                if (response.success) {
                    this.setState({
                        Filtro: {
                            ...this.state.Filtro,
                            PKID: JSON.parse(response.data).PKID
                        },
                        //Pendiente: {...this.cPaginacion ,Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 1}},
                        //EnCurso: {...this.cPaginacion ,Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 2}},
                        //Hecho: {...this.cPaginacion ,Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 3}},
                    }, () => {
                        //this.ListarTicket('Pendiente')
                        //this.ListarTicket('EnCurso')
                        //this.ListarTicket('Hecho')
                    })
                } else {
                    alert(response.data)
                }
            }).catch((error) => {
                alert(error)
            })
    }

    LimpiarFiltro = (Event: any) => {
        TicketService.LimpiarFiltro(parseInt(AuthHelper.GetDecoded().sub, 10))
            .then((response: any) => {
                if (!response.success) alert(response.data)
                this.setState({
                    Pendiente: { ...this.cPaginacion, Paginacion: { ...this.cPaginacion.Paginacion, IDEstado: 1 } },
                    EnCurso: { ...this.cPaginacion, Paginacion: { ...this.cPaginacion.Paginacion, IDEstado: 2 } },
                    Hecho: { ...this.cPaginacion, Paginacion: { ...this.cPaginacion.Paginacion, IDEstado: 3 } },
                    Modal: false,
                    Ticket: this.cTicket,
                    Errores: {}
                }, () => {
                    this.obtenerFiltro()
                })
            })
            .catch((error) => {
                alert(error)
            })
    }

    OnChangeFiltro = (Checks: string, Nombre: string) => {
        this.setState({
            ...this.state,
            Filtro: {
                ...this.state.Filtro,
                [Nombre]: Checks
            },
        }, () => this.CrearActualizarFiltro())
    }

    OnChangeCliente = (e: any) => {
        if (e.value === null) {
            this.setState({
                Filtro: {
                    ...this.state.Filtro,
                    IDSClientes: ''
                }
            }, () => this.CrearActualizarFiltro())
        } else {
            this.setState({
                Filtro: {
                    ...this.state.Filtro,
                    IDSClientes: "-" + e.value.code
                }
            }, () => this.CrearActualizarFiltro())
        }
    }

    Paginacion = (Estado: string) => {
        if (
            (
                {
                    'Pendiente': ((this.state.Pendiente.Paginacion.NumeroPagina < this.state.Pendiente.Paginacion.NumeroPaginas) && !this.state.Pendiente.Loading),
                    'EnCurso': ((this.state.EnCurso.Paginacion.NumeroPagina < this.state.EnCurso.Paginacion.NumeroPaginas) && !this.state.EnCurso.Loading),
                    'Hecho': ((this.state.Hecho.Paginacion.NumeroPagina < this.state.Hecho.Paginacion.NumeroPaginas) && !this.state.Hecho.Loading)
                }[Estado]
            )) {
            switch (Estado) {
                case 'Pendiente':
                    this.setState({
                        Pendiente: {
                            ...this.state.Pendiente,
                            Paginacion: {
                                ...this.state.Pendiente.Paginacion,
                                NumeroPagina: this.state.Pendiente.Paginacion.NumeroPagina + 1
                            },
                            Loading: true
                        }
                    }, () => this.ListarTicket('Pendiente'))
                    break
                case 'EnCurso':
                    this.setState({
                        EnCurso: {
                            ...this.state.EnCurso
                            , Paginacion: {
                                ...this.state.EnCurso.Paginacion,
                                NumeroPagina: this.state.EnCurso.Paginacion.NumeroPagina + 1
                            },
                            Loading: true
                        }
                    }, () => this.ListarTicket('EnCurso'))
                    break
                case 'Hecho':
                    this.setState({
                        Hecho: {
                            ...this.state.Hecho,
                            Paginacion: {
                                ...this.state.Hecho.Paginacion,
                                NumeroPagina: this.state.Hecho.Paginacion.NumeroPagina + 1
                            },
                            Loading: true
                        }
                    }, () => this.ListarTicket('Hecho'))
                    break
                default:
                    break
            }
        }
    }


    MoverEstado = (EstadoI: string, EstadoF: string, PKID: string) => {
        if (EstadoI !== EstadoF) {
            let Obj = { 'Pendiente': this.state.Pendiente.Lista, 'EnCurso': this.state.EnCurso.Lista, 'Hecho': this.state.Hecho.Lista }[EstadoI] || []
            switch (EstadoF) {
                case 'Pendiente':
                    this.setState({
                        Ticket: TicketService.ObtenerObjetoEstado(Obj, PKID, 1)
                    }, () => this.CrearActualizarTicket())
                    break
                case 'EnCurso':
                    this.setState({
                        Ticket: TicketService.ObtenerObjetoEstado(Obj, PKID, 2)
                    }, () => this.CrearActualizarTicket())
                    break
                case 'Hecho':
                    this.setState({
                        Ticket: TicketService.ObtenerObjetoEstado(Obj, PKID, 3)
                    }, () => this.CrearActualizarTicket())
                    break
                default:
                    break
            }
        }
    }

    AplicarFiltro = (Event: any) => {
        window.location.reload()
        this.obtenerFiltro()
    }

    HandleChangeBuscador = (e: any) => {
        const ListaPendienteOriginal = this.state.ListaPendienteOriginal.Lista
        const ListaEnCursoOriginal = this.state.ListaEnCursoOriginal.Lista
        const ListaHechoOriginal = this.state.ListaHechoOriginal.Lista

        const ListaPendienteFiltoBefore = this.state.ListaPendienteOriginal.Lista
        const ListaEnCursoFiltroBefore = this.state.ListaEnCursoOriginal.Lista
        const ListaHechoFiltroBefore = this.state.ListaHechoOriginal.Lista

        if (e.target.value.length===0) {

            this.setState({
                Pendiente: {
                    ...this.state.Pendiente,
                    Lista: ListaPendienteOriginal
                }
            })
            this.setState({
                EnCurso: {
                    ...this.state.EnCurso,
                    Lista: ListaEnCursoOriginal
                }
            })
            this.setState({
                Hecho: {
                    ...this.state.Hecho,
                    Lista: ListaHechoOriginal
                }
            })
        } else {

                const listaFiltradaPendiente = ListaPendienteFiltoBefore.filter((list) => list.Nombre.toLowerCase().includes(e.target.value.toLowerCase()))
                const listaFiltradaEnCurso = ListaEnCursoFiltroBefore.filter((list) => list.Nombre.toLowerCase().includes(e.target.value.toLowerCase()))
                const listaFiltradaHecho = ListaHechoFiltroBefore.filter((list) => list.Nombre.toLowerCase().includes(e.target.value.toLowerCase()))

                this.setState({
                    Pendiente: {
                        ...this.state.Pendiente,
                        Lista: listaFiltradaPendiente
                    }
                })

                this.setState({
                    EnCurso: {
                        ...this.state.EnCurso,
                        Lista: listaFiltradaEnCurso
                    }
                })

                this.setState({
                    Hecho: {
                        ...this.state.Hecho,
                        Lista: listaFiltradaHecho
                    }
                })
        }
    }


    render() {
        return (
            <div className="container">
                <TicketHeader TotalTicket={this.state.TotalTickets}
                    OnChangeBuscador={this.HandleChangeBuscador}
                />
                <TicketBody
                    Paginacion={this.Paginacion}
                    Pendiente={this.state.Pendiente}
                    EnCurso={this.state.EnCurso}
                    Hecho={this.state.Hecho}
                    OnResponse={this.HandleResponse}
                    MoverEstado={this.MoverEstado} />
                <form autoComplete="off" onSubmit={this.HandleSubmitTicket}>
                    <TicketModal
                        Modal={this.state.Modal}
                        HideModal={this.HandleResponse}
                        HandleChange={this.HandleChange}
                        FormValues={this.state.Ticket}
                        ListaCliente={this.state.Cliente}
                        ListaArea={this.state.Area}
                        ListaProducto={this.state.Producto}
                        ListaCategorizacion={this.state.Categorizacion}
                        ListaCausante={this.state.Causante}
                        ListaEmpleado={this.state.Empleado}
                        ListaEstado={this.state.Estado}
                        SubirArchivo={this.SubirArchivo}
                        BorrarArchivo={this.BorrarArchivo}
                        DescargarArchivo={this.DescargarArchivo}
                        Errores={this.state.Errores}
                        Request={this.state.Request}
                        InputHabilitado={this.state.Ticket.InputHabilitado} />
                </form>
                <TicketFiltro
                    Filtro={this.state.Filtro}
                    Clientes={this.state.Cliente}
                    Areas={this.state.Area}
                    Productos={this.state.Producto}
                    Responsables={this.state.Empleado}
                    Estados={this.state.Estado}
                    eliminarFiltro={this.LimpiarFiltro}
                    onClienteChange={this.OnChangeCliente}
                    AplicarFiltro={this.AplicarFiltro}
                    onChangeFiltro={this.OnChangeFiltro} />
            </div>
        )
    }
}