import React from 'react';
import { Button } from 'primereact/button'

export default function ItemRequerimientoHeader(){
    return (
        <div className="p-col-12">
            <div className="CrumbBeetwen">
                <div className="p-d-flex">
                    <ul className="breadcrumb">
                        <li><a href="/Desarrollo/Inicio">Inicio</a></li>
                        <li><a href="/Desarrollo/Proyecto">Proyecto</a></li>
                        <li><a href="/Desarrollo/Proyecto/ItemProyecto">Requerimiento</a></li>
                        <li>Item Requerimiento</li>
                    </ul>
                    <div className="BtnFiltro p-ml-auto">
                        <div className="p-d-flex">
                            <div className="p-mr-2">
                                <Button 
                                    label="Requerimiento" 
                                    className="p-button-rounded p-button-info p-mx-auto"
                                    onClick={ () =>{
                                        (document.getElementById('PanelRequerimiento') as HTMLInputElement).classList.add('active');
                                        (document.getElementById('PopupRequerimiento') as HTMLInputElement).classList.add('active');
                                    }}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}