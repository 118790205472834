import { HTTP } from "../Http/Http"

export const CasoPruebaRepository = {
    Listar: async (FiltroNombreCasoPrueba:String) => {
        return await HTTP.POST('CasoPrueba/Listar?Filtro='+FiltroNombreCasoPrueba,'',{})
    },
    ListarPaginacion: async (Paginacion: any) => {
        return await HTTP.POST('CasoPrueba/ListarPaginacion',Paginacion,{})
    },
    ListarTipoPrueba: async () => {
        return await HTTP.POST('CasoPrueba/ListarTipoPrueba', '',{})
    },
    CrearActualizar: async (CasoPrueba: any, Paginado: any) => {
        return await HTTP.POST('CasoPrueba/CrearActualizar', {CasoPrueba:CasoPrueba,Paginado:Paginado},{})
    },
    Eliminar: async (CasoPrueba: any) => {
        return await HTTP.POST('CasoPrueba/Eliminar',CasoPrueba,{})
    },
    ListarVersion: async (PlanPrueba: any) => {
        return await HTTP.POST('CasoPrueba/ListarVersion',PlanPrueba,{})
    },
    ListarFiltro: async (Filtro: string) => {
        return await HTTP.POST('CasoPrueba/ListarFiltroDDL?Filtro='+Filtro,'',{})
    },
    ListarVersionPaginacion: async (Paginacion: any) => {
        return await HTTP.POST('CasoPrueba/ListarVersionPaginacion',Paginacion,{})
    },
    CrearActualizarVersion: async (LCasoPruebaVersion: any) => {
        return await HTTP.POST('CasoPrueba/CrearActualizarVersion',LCasoPruebaVersion,{})
    },
}