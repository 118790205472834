import React from 'react'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { InputTextarea } from 'primereact/inputtextarea'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'

interface IProps{
    Modal: boolean
    HideModal: any
    FormValues: any
    HandleChange: any
    Errores: any
    FiltroNombreCasoPrueba : any       
    OnChangeBuscador: any
    OnRowCheckSelect: any
    ListaCasoPruebaSeleccionado: any
}

export default function ListaCasoPruebaModal(Props: IProps){

    
   
 

    const OnRowCheckSelect = (v: any) => {        
        Props.OnRowCheckSelect(v)
    }

    const RenderFooter = () =>{
        return (
            <Button   
                onClick={() => Props.HideModal(null, false)}
                label={"Cerrar"}
                style={{
                    background:'#4D80E4',
                    border: '1px solid #4D80E4'}}                
                className="p-ml-auto"                
                autoFocus />
        )
    }

    return(
        <Dialog
            header='Lista de casos de prueba'
            footer={RenderFooter()} 
            visible={Props.Modal}
            style={{ width: '110vh' }}            
            onHide={() => Props.HideModal(null, false)}>
                <div className="p-grid nested-grid">     
                    <div className="p-col-12 ">
                        <div className="p-col">
                            <div className="p-grid p-fluid">
                                <div className="p-col-9">
                                    <label className="p-d-block" 
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'}}>
                                    Buscar Caso de Prueba Por Nombre</label>
                                    <InputText
                                        //name="FiltroNombreCasoPrueba"
                                        //onChange={Props.HandleChange}
                                        //value={Props.FiltroNombreCasoPrueba}
                                         onChange={Props.OnChangeBuscador}
                                        type="text" />
                                    {Props.Errores.FiltroNombreCasoPrueba && <small className="p-invalid p-d-block">                                       
                                        {Props.Errores.FiltroNombreCasoPrueba}</small>}
                                        </div>
                                        <div className="p-col-3" >
                                            <div style={{paddingTop:"25px"}}></div>
                                        
                                </div>
                            </div>
                        </div>
                    </div>
                    <DataTable 
                                value={Props.FormValues}
                                rows={5}                                
                                paginator
                                className="p-datatable-sm"
                                selection={Props.ListaCasoPruebaSeleccionado}
                                onSelectionChange={(e) => OnRowCheckSelect(e.value)}
                                >      
                                    <Column selectionMode="multiple"  header="" style={{width:'10%', center:'true'}}></Column>  
                                    <Column field="Nombre" header="Caso de Prueba" style={{width:'60%', center:'true'}}></Column>  
                                    <Column field="Producto.Nombre" header="Producto" style={{width:'20%', center:'true'}}></Column>    
                                    <Column field="VersionProducto.Version" header="Version del Producto" style={{width:'20%', center:'true'}}></Column>                                    
                                    
                            </DataTable>
                </div>
        </Dialog>
    )
}
