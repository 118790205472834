import React from 'react'
import { InputText } from 'primereact/inputtext'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown';

import '../Assets/Css/Inicio.css'

interface IProps{
    ListaAsistencia: any

    Paginado: any
    Registros: any
    OnRegistroChange: any
    HandlePreviousLL: any
    HandlePrevious: any
    HandleFollowing: any
    HandleFollowingLL: any

}


export default function ReporteAsistenciaBody(Props: IProps){

    const Acciones = (Documento: any) => {
        return (
            <React.Fragment>
                <div className="p-grid p-fluid ">
                    <div className="p-col-2">
                        <Button 
                            type="button"
                            icon="pi pi-pencil" 
                            className="p-button-rounded p-button-outlined"
                            //onClick={ (Event) => Props.EditarDocumento(Documento)}/>
                            />
                    </div>
                    {/* 
                    <div className="p-col-2">
                        <Button 
                            type="button"
                            icon="pi pi-arrow-circle-right" 
                            className="p-button-rounded p-button-outlined" 
                            //onClick={ (Event) =>{Props.AbrirURL(Documento)}}/>
                            />
                    </div>
                    */}
                </div>
            </React.Fragment>
        );
    }

    const Icono = (Props: any) => {
        return (
            <React.Fragment>
                <div className="p-grid p-fluid ">
                    <div className="p-col-2">
                        <img className='profile-image' alt='icon' src={Props.Icono}/>
                    </div>
                </div>
            </React.Fragment>
        );
    }



    return(
        <div className="ContainerTrello">
            <div className="p-col-12 p-md-12 p-lg-12 p-xl-12">
                <div className="list-wrapper">
                <div className="datatable-filter-demo">
            <DataTable 
                value={Props.ListaAsistencia} 
                className="p-datatable-customers p-datatable-sm p-datatable-gridlines"
                rowsPerPageOptions={[100,200,300]}
                >
                    <Column field="PKID" header="ID" style={{width:'10%'}}/>
                    <Column field="Colaborador" header="Colaborador" style={{width:'70%'}}/>
                    {/* 
                    <Column field="Permiso" header="Permiso" style={{width:'70%'}}/>
                    <Column field="Motivo" header="Motivo" style={{width:'70%'}}/>
                    */}
                    <Column field="HoraIngreso" header="Fecha y Hora Ingreso" style={{width:'70%'}}/>
                    <Column field="HoraSalida" header="Fecha y Hora Salida" style={{width:'70%'}}/>
                    {/* 
                    <Column body={Icono} header="Accion" style={{width:'20%'}}/>
                    */}
                    {/* 
                    <Column field="Anulado" header="Anulado" style={{width:'70%'}}/>
                    <Column body={Acciones} header="Accion" style={{width:'20%'}}/>
                */}
                </DataTable>

                <div className="p-paginator p-component p-paginator-bottom">
                        {Props.Paginado.NumeroPagina > 1 ? 
                            <>
                                <button type="button" className="p-paginator-first p-paginator-element p-link" onClick={Props.HandlePreviousLL}>
                                    <span className="p-paginator-icon pi pi-angle-double-left"></span>
                                </button>
                                <button type="button" className="p-paginator-prev p-paginator-element p-link" onClick={Props.HandlePrevious}>
                                    <span className="p-paginator-icon pi pi-angle-left"></span>
                                </button>
                            </>
                            :
                            <>
                                <button type="button" className="p-paginator-first p-paginator-element p-link p-disabled" disabled>
                                    <span className="p-paginator-icon pi pi-angle-double-left"></span>
                                </button>
                                <button type="button" className="p-paginator-prev p-paginator-element p-link p-disabled" disabled>
                                    <span className="p-paginator-icon pi pi-angle-left"></span>
                                </button>
                            </>
                        }
                        <span className="p-paginator-pages">
                            <button type="button" className="p-paginator-page p-paginator-element p-link p-highlight">{Props.Paginado.NumeroPagina}</button>
                        </span>
                        {Props.Paginado.NumeroPagina < Props.Paginado.NumeroPaginas ? 
                            <>
                                <button type="button" className="p-paginator-next p-paginator-element p-link" onClick={Props.HandleFollowing}>
                                    <span className="p-paginator-icon pi pi-angle-right"></span>
                                </button>
                                <button type="button" className="p-paginator-last p-paginator-element p-link" onClick={Props.HandleFollowingLL}>
                                    <span className="p-paginator-icon pi pi-angle-double-right"></span>
                                </button>
                            </>
                            :
                            <>
                                <button type="button" className="p-paginator-next p-paginator-element p-link p-disabled" disabled>
                                    <span className="p-paginator-icon pi pi-angle-right"></span>
                                </button>
                                <button type="button" className="p-paginator-last p-paginator-element p-link p-disabled" disabled>
                                    <span className="p-paginator-icon pi pi-angle-double-right"></span>
                                </button>
                            </>
                        }
                <Dropdown 
                    value={{RegistrosPagina : Props.Paginado.RegistrosPagina}}
                    options={Props.Registros} 
                    onChange={Props.OnRegistroChange} 
                    optionLabel="RegistrosPagina"/>
            </div>

        </div>




                  
                </div>
            </div>
            
        </div>
    )
}