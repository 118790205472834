import React from 'react'
import { InputText } from 'primereact/inputtext'

interface IProps{
    OnChangeBuscador: any

}

export default function IncidenteHeader(Props : IProps){

    return(
        <div className="p-col-12">
            <div className="CrumbBeetwen">
                <div className="p-d-flex">
                    <ul className="breadcrumb">
                        <li><a href="/Desarrollo/Inicio">Inicio</a></li>
                        <li>Incidente</li>
                    </ul>
                   <div className="BtnFiltro p-ml-auto">
                        <div className="p-d-flex">

                            <div className="p-mr-2">
                                <label 
                                    style={{
                                        color: '#4D80E4',
                                        fontFamily: 'Sarala',
                                        fontSize: '20px'}}
                                >Buscador </label>
                                <InputText 
                                    //value={Props.FormBuscador.Buscador}
                                    onChange={Props.OnChangeBuscador}
                                    name="Buscador"
                                    type="Text" 
                                    />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}