import React from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button'

export default function DTUsuarios(props : any){

    const IsNuevo = (rowData : any) => {
        return (
            <div className="p-field-checkbox">
                <Checkbox 
                    inputId="binary" 
                    checked={rowData.IsNuevo}
                    disabled/>
            </div>
        )
    }

    const Administrador = (rowData : any) => {
        return (
            <div className="p-field-checkbox">
                <Checkbox 
                    inputId="binary" 
                    checked={rowData.Administrador}
                    disabled/>
            </div>
        )
    }

    const AdministradorArea = (rowData : any) => {
        return (
            <div className="p-field-checkbox">
                <Checkbox 
                    inputId="binary" 
                    checked={rowData.AdministradorArea}
                    disabled/>
            </div>
        )
    }

    const header = (
        <div className="p-d-flex p-jc-between">
            <h4>Usuarios</h4>
            <Button 
                label="Agregar Usuario" 
                onClick={props.openModal} 
                autoFocus/>
        </div>
    );

    return(
        <div className="datatable-responsive-demo">
            <DataTable 
                value={props.ListaUsuarios} 
                className="p-datatable-responsive-demo" 
                onSelectionChange={e => props.handleSelectRow(e.value)} 
                selectionMode="single"
                paginator 
                rows={8} 
                header={header}>
                <Column field="Empleado.Nombre" header="Nombre"/>
                <Column field="Credencial" header="Usuario"/>
                <Column field="IsNuevo" body={IsNuevo} header="Nuevo"/>
                <Column field="Administrador" body={Administrador} header="Adm."/>
                <Column field="Empleado.Area.Nombre" header="Area"/>
                <Column field="Usuario.AdministradorArea" body={AdministradorArea} header="Adm. Area"/>
            </DataTable>
        </div>
    )
}