import { CasoPruebaRepository } from '../../Infrastructure/Repositories/CasoPruebaRepository'
import { ICasoPruebaVersion } from '../Models/ICasoPruebaVersion'
import { ICasoPrueba } from '../../Domain/Models/ICasoPrueba'
import { IPlanPrueba } from '../Models/IPlanPrueba'
import { IPaginacion } from '../Models/IPaginacion'

const ValidarSchema = (CasoPrueba: ICasoPrueba) => {
    const Errores: { Codigo?: string,Nombre?: string, Descripcion?: string, Modulo?: string, TipoPrueba?: string, Producto?: string, VersionProducto?: string} = {}
    if(!CasoPrueba.Codigo) Errores.Codigo = 'Codigo es requerido'
    if(!CasoPrueba.Nombre) Errores.Nombre = 'Nombre es requerido'
    if(!CasoPrueba.Descripcion) Errores.Descripcion = 'Descripcion es requerido'
    if(!CasoPrueba.Modulo.PKID) Errores.Modulo = 'Modulo es requerido'
    if(!CasoPrueba.TipoPrueba.PKID) Errores.TipoPrueba = 'Tipo prueba es requerido'
    if(!CasoPrueba.Producto.PKID) Errores.Producto = 'Producto es requerido'
    if(!CasoPrueba.VersionProducto.PKID) Errores.VersionProducto = 'Versión es requerido'
    return Errores
} 

const ValidarSchemaVersion = (CasoPruebaVersion: ICasoPruebaVersion) => {
    const Errores: { CasoPrueba?: string, Nombre?: string, Descripcion?: string, Modulo?: string, TipoPrueba?: string,} = {}
    if(!CasoPruebaVersion.IDCasoPrueba) Errores.CasoPrueba = 'Caso de prueba es requerido'
    if(!CasoPruebaVersion.Nombre) Errores.Nombre = 'Nombre es requerido'
    if(!CasoPruebaVersion.Descripcion) Errores.Descripcion = 'Descripcion es requerido'
    if(!CasoPruebaVersion.Modulo.PKID) Errores.Modulo = 'Modulo es requerido'
    if(!CasoPruebaVersion.TipoPrueba.PKID) Errores.TipoPrueba = 'Tipo de prueba es requerido'
    return Errores
}

const ValidarPaso = (Paso: any) => {
    const Errores: {Accion?: string, ResultadoEsperado?: string} = {}
    if(!Paso.Accion) Errores.Accion = 'Accion es requerido'
    if(!Paso.ResultadoEsperado) Errores.ResultadoEsperado = 'Resultado esperado es requerido'
    return Errores
}

const ObtenerObjetoEstado = (Array: Array<ICasoPruebaVersion>, PKID: string, IDEstado: number) => {
    let aObj: Array<ICasoPruebaVersion> =  Array.filter( (Obj : ICasoPruebaVersion) =>{
        return Obj.PKID === parseInt(PKID,10) 
    })
    let Obj: ICasoPruebaVersion = aObj[0] 
    return {
        ...Obj,
        Estado:{
            ...Obj.Estado,
            PKID: IDEstado
        }
    }
}

export const CasoPruebaService = {
    ValidarSchema,
    ValidarSchemaVersion,
    ObtenerObjetoEstado,
    Listar: (FiltroNombreCasoPrueba : String) => {
        return CasoPruebaRepository.Listar(FiltroNombreCasoPrueba)
    },
    ListarPaginacion: (Paginacion: any) => {
        return CasoPruebaRepository.ListarPaginacion(Paginacion)
    },
    ListarTipoPrueba: () => {
        return CasoPruebaRepository.ListarTipoPrueba()
    },
    CrearActualizar: (CasoPrueba: ICasoPrueba, Paginado: any) => {
        return CasoPruebaRepository.CrearActualizar(CasoPrueba, Paginado)
    },
    Eliminar: (CasoPrueba: ICasoPrueba) => {
        return CasoPruebaRepository.Eliminar(CasoPrueba)
    },
    ValidarPaso,
    ListarVersion: (PlanPrueba: IPlanPrueba) => {
        return CasoPruebaRepository.ListarVersion(PlanPrueba)
    },
    ListarFiltro: (Filtro: string) => {
        return CasoPruebaRepository.ListarFiltro(Filtro)
    },
    ListarVersionPaginacion: (Paginacion: any) => {
        return CasoPruebaRepository.ListarVersionPaginacion(Paginacion)
    },
    CrearActualizarVersion: (LCasoPruebaVersion: Array<object>) => {
        return CasoPruebaRepository.CrearActualizarVersion(LCasoPruebaVersion)
    }
}