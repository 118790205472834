import { ItemBackLogRepository } from '../../Infrastructure/Repositories/ItemBackLogRepository'
import { IImplementacionItemBackLog } from '../Models/IImplementacionItemBackLog'
import { IImplementacionBackLog } from '../Models/IImplementacionBackLog'

interface IErroresImplementacionItemBackLog{
    ImplementacionItemBackLog?: string
    Descripcion?: string   
}

const ValidarSchema = (ImplementacionItemBackLog: IImplementacionItemBackLog) => {
    const errores: IErroresImplementacionItemBackLog ={}       
    if(!ImplementacionItemBackLog.Descripcion) errores.Descripcion = 'Descripcion es requerido'
    return errores;
}

const ObtenerObjetoEstado = (Array: Array<IImplementacionItemBackLog>, PKID: string, IDEstado: number) => {
    let aObj: Array<IImplementacionItemBackLog> =  Array.filter( (Obj : IImplementacionItemBackLog) =>{
        return Obj.PKID === parseInt(PKID,10) 
    })
    let Obj: IImplementacionItemBackLog = aObj[0] 
    return {
        ...Obj,
        Estado:{
            ...Obj.Estado,
            PKID: IDEstado
        }
    }
}

const DescargarArchivo = (Objeto: {Archivo: string, Nombre: string, Extension: string}) => {
    const byteCharacters = atob(Objeto.Archivo)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers);
    const url = window.URL.createObjectURL(new Blob([byteArray]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', Objeto.Nombre + '.' + Objeto.Extension);
    document.body.appendChild(link);
    link.click();
}

const ListarPaginacion = (Paginacion: any) => {
    return ItemBackLogRepository.ListarPaginacion(Paginacion)
}

const Listar = (IDImplementacionBackLog: any) => {
    return ItemBackLogRepository.Listar(IDImplementacionBackLog)
}

const CrearActualizar = (ImplementacionItemBackLog: IImplementacionItemBackLog, ImplementacionBackLog: IImplementacionBackLog) => {  
       
            return ItemBackLogRepository.CrearActualizar(ImplementacionItemBackLog)      
    
}

const Consultar = (ImplementacionItemBackLog: IImplementacionItemBackLog) => {
    return ItemBackLogRepository.Consultar(ImplementacionItemBackLog)
}

export const ItemBackLogService = {
    ValidarSchema,
    ObtenerObjetoEstado,
    DescargarArchivo,
    ListarPaginacion,
    Consultar,
    CrearActualizar,
    Listar
}