import React, { Component } from 'react'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown'
import { Button } from 'primereact/button'

import '../Assets/css/Sprint.css'

export default class ModalTicketCategorizacion extends Component{
    render(){
        return(
            <div id="ModalTicketCategorizacion" className="OverlaySprint">
                <div id="PopupSprint2" className="PopupSprint2">
                    <div className="HeaderPopup">
                        <div className="p-d-flex p-jc-between">
                            <div className="Title">Ticket - {this.props.Ticket.Nombre}</div>
                            <div className="btn-cerrar-popupsprint" onClick={() => 
                            {
                                document.getElementById('ModalTicketCategorizacion').classList.remove('active')
                                document.getElementById('PopupSprint2').classList.remove('active')
                            }}
                            ><i className="fas fa-times"></i></div>
                        </div>
                    </div>
                    <form autoComplete="off" className="BodySprint">
                        <div className="p-grid">
                            <div className="p-col-12">   
                                <div className="p-grid p-fluid">
                                    <div className="p-col">
                                        <label 
                                            style={{
                                                color: '#4D80E4',
                                                fontFamily: 'Sarala',
                                                fontSize: '19px'}}>
                                        ¿Le gustaría mover el ticket asociado de en curso a hecho también?</label>
                                        
                                    </div>
                                </div>
                            </div> 

                            
                           
                            <div className="p-col-12">
                                <div className="p-grid p-fluid">
                                    <div className="p-col">
                                        <label 
                                            style={{
                                                color: '#4D80E4',
                                                fontFamily: 'Sarala',
                                                fontSize: '19px'}}>
                                        Categorización</label>
                                        <Dropdown 
                                            value={{
                                                code: this.props.Ticket.Categorizacion.PKID,
                                                name: this.props.Ticket.Categorizacion.Descripcion
                                               
                                            }}
                                            className="combo"  
                                            options={this.props.ListaCategorizacion}
                                            onChange={this.props.handleOnChangeCategorizacion}
                                            optionLabel="name"
                                            placeholder="Seleccione Categorización"/>
                                    </div>
                                </div>
                            </div>
                            <br></br><br></br><br></br>
                            <div className="p-col-12">
                                <div className="Centrar">
                                    <Button 
                                        type="button" 
                                        label="Sí"
                                        onClick={this.props.moverTicket}
                                        className="p-button-success p-mr-2" />
                                    <Button 
                                        type="button" 
                                        label="No"
                                        onClick={this.props.noMoverTicket}
                                        className="p-button-secondary"/>
                                   
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}