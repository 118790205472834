import { HTTP } from "../Http/Http"

export const RequerimientoRepository = {
    ListarPaginacion: async (Paginacion: any) => {
        return await HTTP.POST('ItemBackLog/ListarPaginacion',Paginacion,{})
    },
    CrearActualizar: async (Requerimiento: any) => {
        return await HTTP.POST('Proyecto/CrearActualizarItemBackLog',Requerimiento,{})
    },
    Consultar: async (Requerimiento: any) => {
        return await HTTP.POST('ItemBackLog/Consultar',Requerimiento,{})
    },
    ActualizarFechaFin: async (Requerimiento: any) => {
        return await HTTP.POST('Proyecto/ActualizarFechaFinItemBackLog',Requerimiento,{})
    }
}