import React from 'react'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { Calendar } from 'primereact/calendar'

interface IProps {
    ModalIngreso: boolean
    HideModal: any
    OnChangeRegistrarAsistencia: any
    Fecha: any
    Request: boolean
}

export default function  ModalRegistrarAsistencia(Props: IProps) {

    const RenderFooter = () => {
        return(
            <Button 
                label = { Props.Request ? "Cargando..." : "Aceptar" } 
                style = {{
                    background : '#4D80E4',
                    border : '1px solid #4D80E4'}}
                icon = { Props.Request ? "" : "pi pi-check" }
                className = "p-ml-auto"
                disabled = { Props.Request }
                autoFocus />
        )
    }
    
    return(
        <Dialog
            header='Registrar Asistencia - Hora de Ingreso'
            visible={Props.ModalIngreso}
            style={{ width: '90vh' }}
            footer={RenderFooter()} 
            onHide={Props.HideModal}>
                <div className="modal-body p-mt-3">
                    <div className="p-grid nested-grid">
                        <div className="p-col-12">
                            {/* 
                            <div className="p-grid p-fluid ">
                                <div className="p-col">
                                    <label 
                                        className="p-d-block"
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'}}
                                    >Fecha y Hora</label>
                                    <Calendar 
                                        value={Props.Fecha}                                                         
                                        disabled
                                        showTime 
                                        showSeconds
                                        dateFormat="dd/mm/yy"/> 
                                </div>
                            </div>
                             */}
                        </div>
                    </div>
                </div> 
        </Dialog>
    )
}