import React from 'react'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'

interface IProps {
    TotalTicket: number
    OnChangeBuscador: any
    //FormBuscador: any
}


export default function TicketHeader(Props: IProps) {

    return (
        <div className="p-col-12">
            <div className="CrumbBeetwen">
                <div className="p-d-flex">
                    <ul className="breadcrumb">
                        <li><a href="/Desarrollo/Inicio">Inicio</a></li>
                        <li>Ticket</li>
                    </ul>
                    <div className="BtnFiltro p-ml-auto">
                        <div className="p-d-flex">
                            <div className="p-mr-2">
                                <label
                                    style={{
                                        color: '#4D80E4',
                                        fontFamily: 'Sarala',
                                        fontSize: '20px'
                                    }}
                                >Buscador </label>
                                <InputText
                                    //value={Props.FormBuscador.Buscador}
                                    onChange={Props.OnChangeBuscador}
                                    name="Buscador"
                                    type="Text"
                                />
                            </div>
                            <div className="p-mr-2">
                                <Button
                                    id="OpenFilter"
                                    type="button"
                                    icon="fas fa-filter"
                                    onClick={() => {
                                        (document.getElementById('PanelFiltro') as HTMLInputElement).classList.add('active');
                                        (document.getElementById('PopupFiltro') as HTMLInputElement).classList.add('active');
                                    }}
                                    className="p-button-rounded p-button-text p-button-plain" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="PanelSprint" className="OverlaySprint">
                <div id="PopupSprint" className="PopupSprint">
                    <div className="HeaderPopup">
                        <div className="p-d-flex p-jc-between">
                            <div className="Title">Ticket</div>
                            <div className="btn-cerrar-popupsprint" onClick={() => {
                                (document.getElementById('PanelSprint') as HTMLInputElement).classList.remove('active');
                                (document.getElementById('PopupSprint') as HTMLInputElement).classList.remove('active');
                            }}
                            ><i className="fas fa-times"></i></div>
                        </div>
                    </div>
                    <form autoComplete="off" className="BodySprint">
                        <div className="p-grid">
                            <div className="p-col-12">
                                <div className="p-grid p-fluid">
                                    <div className="p-col">
                                        <label
                                            style={{
                                                color: '#4D80E4',
                                                fontFamily: 'Sarala',
                                                fontSize: '19px'
                                            }}>
                                            Número de Tickets</label>
                                        <InputText
                                            value={Props.TotalTicket}
                                            name="NumTicket"
                                            type="number"
                                            disabled
                                            placeholder="0" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}