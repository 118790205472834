import { TicketRepository } from '../../Infrastructure/Repositories/TicketRepository'
import { ITicket } from '../Models/ITicket'

interface IErroresTicket{
    Nombre?: string
    Descripcion?: string
    Cliente?: string
    Area?: string
    Producto?: string
    EmpleadoOrigen?: string
    Categorizacion?: string
    Causante?: string
}

const ValidarSchema = (Ticket:  ITicket) => {
    const errores : IErroresTicket = {}
    if(!Ticket.Nombre) errores.Nombre  = 'Nombre del ticket es requerido'
    if(!Ticket.Descripcion) errores.Descripcion = 'Descripcion del ticket es requerido'
    if(!Ticket.Cliente.PKID) errores.Cliente = 'Cliente es requerido'
    if(!Ticket.Area.PKID) errores.Area = 'Area es requerido'
    if(!Ticket.Producto.PKID) errores.Producto = 'Producto es requerido'
    if(!Ticket.EmpleadoOrigen.PKID) errores.EmpleadoOrigen = 'Empleado origen es requerido'
    if(!Ticket.Categorizacion.PKID) errores.Categorizacion = 'Categorización es requerida'
    if(!Ticket.Causante.PKID) errores.Causante = 'Causante es requerido'
    return errores
}

const ObtenerObjetoEstado = (Array: Array<ITicket>, PKID: string, IDEstado: number) => {
    let aObj: Array<ITicket> =  Array.filter( (Obj : ITicket) =>{
        return Obj.PKID === parseInt(PKID,10) 
    })
    let Obj: ITicket = aObj[0] 
    return {
        ...Obj,
        Estado:{
            ...Obj.Estado,
            PKID: IDEstado
        }
    }
}

const DescargarArchivo = (Objeto:  {Archivo: string, Nombre: string, Extension: string}) => {
    const byteCharacters = atob(Objeto.Archivo)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers);
    const url = window.URL.createObjectURL(new Blob([byteArray]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', Objeto.Nombre + '.' + Objeto.Extension);
    document.body.appendChild(link);
    link.click();
}

export const TicketService = {
    ValidarSchema,
    ObtenerObjetoEstado,
    ObtenerFiltro: (IDUsuario : number) => {
        return TicketRepository.ObtenerFiltro(IDUsuario)
    },
    ListarPaginacion: (Paginacion : any) => {
        return TicketRepository.ListarPaginacion(Paginacion)
    },
    CrearActualizar: (Ticket : ITicket) => {
        return TicketRepository.CrearActualizar(Ticket)
    },
    CrearActualizarFiltro: (Filtro : any) => {
        return TicketRepository.CrearActualizarFiltro(Filtro)
    },
    LimpiarFiltro: (IDUsuario : number) => {
        return TicketRepository.LimpiarFiltro(IDUsuario)
    },
    Estado_ObtenerTodos: () => {
        return TicketRepository.Estado_ObtenerTodos()
    },
    Empleado_ObtenerTodos: () => {
        return TicketRepository.Empleado_ObtenerTodos()
    },
    Causante_ObtenerTodos: () => {
        return TicketRepository.Causante_ObtenerTodos()
    },
    ListarEstado: (Estado: {PKID: number}) => {
        return TicketRepository.ListarEstado(Estado)
    },
    Consultar: (ID: {PKID: number}) => {
        return TicketRepository.Consultar(ID)
    },
    DescargarArchivo,
}