import React from 'react'
import { AreaService } from '../../Domain/Services/AreaService'
import DTAreas from '../Components/Desarrollo.Seguridad.Area.DataTable'
import ModalArea from '../Components/Desarrollo.Seguridad.Area.Modal'
import AreaHeader from '../Components/AreaHeader'

interface IProps {
}

interface IArea {
    PKID: Number,
    Codigo: String,
    Nombre: String
}

interface IState {
    Modal: boolean
    ListaArea: IArea[]
    ListaAreaOriginal: IArea[]
    Area: any
    Errores: any
}

export default class SeguridadArea extends React.Component<IProps, IState> {

    ValidarArea = (values:any) =>{
        const errores : any = {}
        if(!values.Codigo){
            errores.Codigo  = 'Codigo es requerido';
        }
        if(!values.Nombre){
            errores.Nombre = 'Nombre es requerido'
        }
        return errores;
    }

    cArea = {
        PKID : 0,
        Codigo : '',
        Nombre : ''
    }

    constructor(Props: any){
        super(Props)
        this.state = {
            Modal: false,
            ListaArea: [],
            ListaAreaOriginal: [],
            Area: this.cArea,
            Errores: {}
        }
    }

    componentDidMount(){
        this.ListarArea()
    }

    ListarArea(){
        AreaService.Listar()
        .then( (response : any) => {
            if(response.success){
                this.setState({
                    ListaArea: JSON.parse(response.data)
                })
                this.setState({
                    ListaAreaOriginal: JSON.parse(response.data)
                })
            }else{
                alert(response.data)
            }
        }).catch( (error) => {
            alert(error)
        })
    }

    HandleChange = (Event: any) => {
        this.setState({
            Area:{
                ...this.state.Area,
                [Event.target.name]: Event.target.value
            }
        })
    }

    HandleSubmit = (Event : any) => {
        Event.preventDefault()
        const result = this.ValidarArea(this.state.Area)
        if(Object.keys(result).length){
            return this.setState({Errores: result})
        }else{
            this.CrearActualizar()
        }
    }

    CrearActualizar(){
        AreaService.CrearActualizar(this.state.Area)
        .then( (response : any) => {
            alert(response.data)
            if(response.success){
                this.setState({
                    Modal: false,
                    Area: this.cArea
                }, () => this.ListarArea())
            }
        }).catch( (error) => {
            alert(error)
        })
    }

    HandleChangeBuscador = (e: any) => {

        const ListaAreaOriginal = this.state.ListaAreaOriginal
        const ListaAreaFiltroBefore = this.state.ListaAreaOriginal

        if (e.target.value.length===0) {
            this.setState({
                ListaArea : ListaAreaOriginal
            })

        } else {
                const listaFiltrada = ListaAreaFiltroBefore.filter((lista) => lista.Nombre.toLowerCase().includes(e.target.value.toLowerCase()))

                this.setState({
                    ListaArea : listaFiltrada
                })
        }
    }

    render(){
        return(
            <div className="container">
                <AreaHeader
                    OnChangeBuscador={this.HandleChangeBuscador}
                />
                <DTAreas
                    ListaArea = {this.state.ListaArea}
                    openModal = {() => this.setState({Modal: true})}
                    handleSelectRow = { (Event : any) =>{
                        this.setState({
                            Area: Event,
                            Modal: true
                        })
                    }}/>
                <ModalArea
                    openModal = {this.state.Modal}
                    closeModal = {() => {
                        this.setState({
                            Errores: {},
                            Area: this.cArea,
                            Modal: false
                        })
                    }}
                    handleChange = {this.HandleChange}
                    area = {this.state.Area}
                    onSubmit = {this.HandleSubmit}
                    Errores = {this.state.Errores}/>
            </div>
        )
    }
}