import { HTTP } from "../Http/Http"

export const BackLogRepository = {
    Consultar: async (ImplementacionBackLog: any) => {
        return await HTTP.POST('Proyecto/ConsultarImplementacionBackLog',ImplementacionBackLog,{})
    },
    ConsultarEnPrueba: async (PlanPrueba: any) => {
        return await HTTP.POST('Proyecto/ConsultarBackLogEnPrueba',PlanPrueba,{})
    },
    ObtenerFiltro: async (IDUsuario : number) => {
        return await HTTP.POST('Proyecto/ObtenerFiltroImplementacionBackLog?IDUsuario='+IDUsuario,'',{})
    },
    Listar: async () => {
        return await HTTP.POST('Proyecto/ListarD','',{})
    },
    ListarPaginacion: async (Paginacion : any) => {
        return await HTTP.POST('Proyecto/ListarImplementacionBackLog',Paginacion,{})
    },
    CrearActualizar: async (ImplementacionBackLog: any) => {
        return await HTTP.POST('Proyecto/CrearActualizarImplementacionBackLog', ImplementacionBackLog, {})
    },
    CrearActualizarFiltro: async (Filtro: any) => {
        return await HTTP.POST('Proyecto/CrearActualizarFiltroImplementacionBackLog', Filtro, {})
    },
    LimpiarFiltro: async (IDUsuario : number) => {
        return await HTTP.POST('Proyecto/LimpiarFiltroImplementacionBackLog?IDUsuario='+IDUsuario+'&IDTipo='+4,'',{})
    },
    CrearVersion: async (ImplementacionBackLog: any) => {
        return await HTTP.POST('Proyecto/CreateVersionImplementacionBackLog', ImplementacionBackLog, {})
    },
    ListarCalidad: async () => {
        return await HTTP.POST('Proyecto/ListarCalidad', '', {})
    },
    DescargarWord: async (IDImplementacionBackLogVersion : Number) => {
        return await HTTP.POST('Proyecto/DescargarWordImplementacionBackLogVersion?IDImplementacionBackLogVersion='+IDImplementacionBackLogVersion, '', {})
    }
}
