import { HTTP } from "../Http/Http"

export const ProyectoRepository = {
    Consultar: async (BackLog: any) => {
        return await HTTP.POST('Proyecto/ConsultarD',BackLog,{})
    },
    ConsultarEnPrueba: async (PlanPrueba: any) => {
        return await HTTP.POST('Proyecto/ConsultarBackLogEnPrueba',PlanPrueba,{})
    },
    ObtenerFiltro: async (IDUsuario : number) => {
        return await HTTP.POST('Proyecto/ObtenerFiltroProyecto?IDUsuario='+IDUsuario,'',{})
    },
    Listar: async () => {
        return await HTTP.POST('Proyecto/ListarD','',{})
    },
    ListarPaginacion: async (Paginacion : any) => {
        return await HTTP.POST('Proyecto/ListarPaguinadoD',Paginacion,{})
    },
    CrearActualizar: async (BackLog: any) => {
        return await HTTP.POST('Proyecto/CrearActualizarD', BackLog, {})
    },
    CrearActualizarFiltro: async (Filtro: any) => {
        return await HTTP.POST('Proyecto/CrearActualizarFiltro', Filtro, {})
    },
    LimpiarFiltro: async (IDUsuario : number) => {
        return await HTTP.POST('Proyecto/LimpiarFiltro?IDUsuario='+IDUsuario+'&IDTipo='+2,'',{})
    },
    CrearVersion: async (BackLog: any) => {
        return await HTTP.POST('Proyecto/CreateVersion', BackLog, {})
    },
    ListarCalidad: async () => {
        return await HTTP.POST('Proyecto/ListarCalidad', '', {})
    },

    ListarProyectosImplantacion: async (Paginado : any) => {
        return await HTTP.POST('Proyecto/ListarProyectoImplantacion',Paginado,{})
    }
}
