import axios from 'axios';
import { getToken, EliminarTodo } from './LocalStorage';

export function url() {
    return 'https://api.trellium.uniflex.com.pe/api/'
    //return 'http://localhost:6140/api/'
}

export async function initAxiosInterceptors(){
    axios.interceptors.request.use(
        function(config){
            const token = getToken();
            if(token){
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        },
        function(error){
            return Promise.reject(error);
        }
    )
    axios.interceptors.response.use(
        function(response){
            return response;
        },
        async function (error){
            const originalRequest = error.config;
            if (error.response.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true;
                if(getToken()){
                    alert("Debe iniciar sesión nuevamente.")
                    EliminarTodo();
                    window.location.reload(true);
                }
            }
            return Promise.reject(error);
        }
    )
}
