import React, {Component} from 'react'
import HeaderTareo from '../Components/HeaderTareo'
import PanelSprint from '../Components/PanelSprint'
import ModalTicketCategorizacion from '../Components/ModalTicketCategorizacion'
import ModalSprint from '../Components/ModalSprint'
import CuerpoTareo from '../Components/CuerpoTareo'
import ModalTareo from '../Components/ModalTareo'
import { url } from '../../Services/Axios'
import { Toast } from 'primereact/toast'
import { getDecode} from '../../Services/LocalStorage'
import FiltroTareo from '../Components/FiltroTareo'
import axios from 'axios'

var x="";
const cSprintBackLog = {
    PKID:0,
    Codigo:'',
    Nombre:'',
    FechaInicio: new Date(),
    FechaFin: new Date(),
    HorasPlanificadas:0,
    HorasEjecutadas:0,
    Abierto:false
}

const cTicket = {
    PKID:0,
    Nombre : '',
    Categorizacion:{
        PKID: 0,
        Descripcion: ''
    },
    Estado:{
        PKID: 0,
        Descripcion: ''
    }
}

const cFiltro = {
    PKID:0,
    IDUsuario: getDecode()? getDecode().sub:0,
    IDTipo:3,
    FechaInicio:'',
    FechaFin:'',
    IDSClientes:'',
    IDSAreas:'',
    IDSProductos:'',
    IDSResponsables:'',
    IDSEstados:'',
    IDSTickets:'',
    IDSProyectos:'',
    IDSIncidentes:'',
}

const cItemSprintBackLog = {
    PKID : 0,
    IDSprintBackLog : 0,
    Nombre : '',
    Descripcion : '',
    HorasPlanificadas : 0.0,
    HorasEjecutadas : 0,
    Desarrollo : false,
    EsIncidente : false,
    ProyectoVersion : {
        PKID : 0,
        Nombre : ''
    },
    RequerimientoVersion : {
        PKID : 0,
        Requisito : ''
    },
    ItemRequerimientoVersion : {
        PKID : 0,
        Requisito : ''
    },
    Area : {
        PKID : 0,
        Nombre : ''
    },
    Empleado : {
        PKID : 0,
        Nombre : ''
    },
    Ticket : {
        PKID : 0,
        Nombre : '',
        Categorizacion:{
            PKID: 0,
            Descripcion: ''
        },
        Estado:{
            PKID: 0,
            Descripcion: ''
        }
    },
    Responsable : {
        PKID : getDecode() ? getDecode().sub : 0,
        Empleado : {
            PKID : 0,
            Nombre: getDecode()? getDecode().Empleado : ''
        }
    },
    OrigenTareo : {
        Descripcion : '',
        IDOrigen: 0
    },
    IncluirHorasDesarrollo : false,
    FechaCreacion : '',
    Estado : {
        PKID : 1,
        Descripcion : 'Pendiente'
    },
    Incidente : {
        PKID : 0,
        Incidencia : ""
    },
    Ejecucion : false,
}

const validarSprint = (sprint) => {
    const errores = {}
    if(sprint.Codigo === '') errores.Codigo  = 'Codigo es requerido'
    if(sprint.Nombre ==='') errores.Nombre  = 'Nombre es requerido'
    if(sprint.FechaInicio ==='') errores.FechaInicio  = 'FechaInicio es requerido'
    if(sprint.FechaFin === '') errores.FechaFin  = 'FechaFin es requerido'
    if(sprint.FechaInicio >= sprint.FechaFin) errores.FechaFin  = 'La FechaFin debe ser mayor a FechaInicio'
    return errores
}

const validarTarea = (tarea) => {
    const errores = {}
    if(tarea.Nombre === '') errores.Nombre  = 'Nombre es requerido'
    if(tarea.Descripcion === '') errores.Descripcion  = 'Descripcion es requerido'
    if(tarea.Desarrollo){
        if(tarea.ProyectoVersion.PKID === 0) errores.Proyecto  = 'Proyecto es requerido'
        if(tarea.RequerimientoVersion.PKID === 0) errores.Requerimiento  = 'Requerimiento es requerido'
        if(tarea.ItemRequerimientoVersion.PKID === 0) errores.ItemRequerimiento = 'Item requerimiento es requerido'
    }
    if(!tarea.Desarrollo && !tarea.EsIncidente){
        if(tarea.Ticket.PKID ===0) errores.Ticket = 'Ticket es requerido'
        if(tarea.Area.PKID === 0) errores.Area  = 'Area es requerido'
        if(tarea.Empleado.PKID === 0) errores.Empleado  = 'Empleado es requerido'
    }
    if(tarea.EsIncidente) if(tarea.Incidente.PKID === 0) errores.Incidente = 'Incidente es requerido'
    return errores
}


class Tareo extends Component{
    constructor(){
        super()
        this.escFunction = this.escFunction.bind(this);
        this.state = {
            Filtro : cFiltro,
            ListaProyectoDDL : [],
            ListaRequerimientoDDL : [],
            ListaItemRequerimientoDDL : [],
            ListaAreaDDL : [],
            ListaEmpleadoDDL : [],
            ListaTicketDDL : [],
            ListaIncidenteDDL : [],
            ListaEstadoDDL : [],
            //Pendiente
            Pendiente : [],
            LoadingP : true,
            PaginacionP : { NumeroPagina: 1, RegistrosPagina: 5, NumeroPaginas: 0, IDEstado: 1, IDUsuario: getDecode().sub, IDSprintBackLog: 0 },
            //EnCurso
            EnCurso: [],
            LoadingEC: true,
            PaginacionEC: { NumeroPagina: 1, RegistrosPagina: 5, NumeroPaginas: 0, IDEstado: 2, IDUsuario: getDecode().sub, IDSprintBackLog: 0 },
            //Hecho
            Hecho: [],
            LoadingH: true,
            PaginacionH: { NumeroPagina: 1, RegistrosPagina: 5, NumeroPaginas: 0, IDEstado: 3, IDUsuario: getDecode().sub, IDSprintBackLog: 0 },
            //Sprint
            modalSprintAbierto : false,
            SprintBackLog : cSprintBackLog,
            SprintBackLogEdicion : cSprintBackLog,
            ListaSprintDDL : [],
            //Tarea
            habilitarBotonCronometro : false,
            modalTareaAbierto : false,
            modalTareaActualizar : false,
            ItemSprintBackLog : cItemSprintBackLog,
            errores:{},
            ListaEmpleadoDesarrolloDDL : [],
            Request : false,
            Usuario : {
                PKID : getDecode().sub
            },
            //Ticket
            ListaCategorizacion : [],
            Ticket : cTicket,
        }
    }

    escFunction(event){
        /*
        if (event.key === "Escape") {
            this.setState({Pendiente : []})
            this.setState({EnCurso : []})
            this.setState({Hecho : []})            
        }
        */
      }

    componentDidMount(){
        this.componentDM()
        setInterval(()=>{
            if(this.state.cronometroEncendido){
                this.actualizarCronometro()
            }
        },1000)
        document.addEventListener("keydown", this.escFunction, false);        
    }

    componentDM(){
        this.obtenerListaSprintDDL()
        this.obtenerPKIDUltimoSprintAbierto()
        this.getProyectoDLL()
        this.obtenerListaAreaDDL()
        this.obtenerListaEmpleadoDDL()
        this.obtenerListaTicketDDL()
        this.obtenerListaIncidenteDDL()
        this.obtenerListaEstadoPrincipalesDDL()
        this.obtenerListaCategorizacion()
    }

    actualizarTareosEnCurso(){
        this.obtenerPKIDUltimoSprintAbierto()
    }

    componentWillUnmount(){
        document.removeEventListener("keydown", this.escFunction, false);
      }

    actualizarCronometro(){
        this.setState({
            ...this.state,
            ItemSprintBackLog:{
                ...this.state.ItemSprintBackLog,
                HorasEjecutadas:this.state.ItemSprintBackLog.HorasEjecutadas+1
            }
        })
    }

    async obtenerListaSprintDDL(){
        axios.post(url()+'Tareo/ListarSprintDDL?IDUsuario='+getDecode().sub)
        .then( (response) => {
            if (response.data.success) {
                let lista = [], listaAPI=JSON.parse(response.data.data)
                Object.keys(listaAPI).forEach(function(key){
                    lista.push({
                        code: listaAPI[key].PKID,
                        name: listaAPI[key].Descripcion,
                    });
                });
                this.setState({ListaSprintDDL : lista})
            }else{
                throw new Error(response.data.data)
            }
        })
        .catch( (error) => {
            alert(error)
        })
    }

    async obtenerListaCategorizacion(){
        axios.post(url()+'Categorizacion/Listar')
        .then( (response) => {
            if (response.data.success) {
                let lista = [], listaAPI=JSON.parse(response.data.data)
                Object.keys(listaAPI).forEach(function(key){
                    lista.push({
                        code: listaAPI[key].PKID,
                        name: listaAPI[key].Descripcion,
                    });
                });
                this.setState({ListaCategorizacion : lista})
            }else{
                throw new Error(response.data.data)
            }
        })
        .catch( (error) => {
            alert(error)
        })
    }

    //Sprint
    async obtenerPKIDUltimoSprintAbierto(){
        axios.post(url()+'Tareo/ObtenerPKIDUltimoSprintAbierto?IDUsuario='+getDecode().sub)
        .then( (response) => {
            if(response.data.success){
                this.setState({
                    SprintBackLog:{
                        ...this.state.SprintBackLog,
                        PKID:JSON.parse(response.data.data)[0].PKID
                    }},
                    ()=>this.obtenerFiltro())
            }else{
                throw new Error(response.data.data)
            }
        })
        .catch( (error) => {
            alert(error)
        })
    }

    async obtenerFiltro(){
        axios.post(url()+'Tareo/ObtenerFiltro?IDUsuario='+getDecode().sub)
        .then( (response) => {
            if(response.data.success){
                this.setState({
                    Filtro : JSON.parse(response.data.data)
                })
            }
        })
        .catch( (error) => {
            alert(error)
        })
        .finally( () => {
            this.obtenerSprint(this.state.SprintBackLog.PKID, getDecode().sub)
        })
    }

    async obtenerSprint(IDSprint, IDUsuario){
        axios.post(url()+'Tareo/ObtenerSprint?IDSprint='+IDSprint+'&IDUsuario='+IDUsuario)
        .then( (response) => {
            if (response.data.success) {
                this.setState({
                    SprintBackLog: JSON.parse(response.data.data),
                    Pendiente: [],
                    PaginacionP: {...this.state.PaginacionP, NumeroPagina: 1, RegistrosPagina: 5, NumeroPaginas: 0, IDSprintBackLog : JSON.parse(response.data.data).PKID},
                    LoadingP : true,
                    EnCurso: [],
                    PaginacionEC: {...this.state.PaginacionEC, NumeroPagina: 1, RegistrosPagina: 5, NumeroPaginas: 0, IDSprintBackLog : JSON.parse(response.data.data).PKID },
                    LoadingEC : true,
                    Hecho: [],
                    PaginacionH: {...this.state.PaginacionH, NumeroPagina: 1, RegistrosPagina: 5, NumeroPaginas: 0 , IDSprintBackLog : JSON.parse(response.data.data).PKID},
                    LoadingH : true,
                }, ()=> {
                    this.obtnerListaTareas('Pendiente','PaginacionP','LoadingP')
                    this.obtnerListaTareas('EnCurso','PaginacionEC','LoadingEC')
                    this.obtnerListaTareas('Hecho','PaginacionH','LoadingH')
                })
            }else{
                this.toast.show({severity:'info', summary: 'No Existe Sprint con ese PKID', detail:response.data.data, life: 3000});
            }
        })
        .catch( (error) => {
            alert(error)
        })
        .finally( () => {
            this.OcultarSprint()
        })
    }

    async obtenerSprint2(IDSprint, IDUsuario){
        axios.post(url()+'Tareo/ObtenerSprint?IDSprint='+IDSprint+'&IDUsuario='+IDUsuario)
        .then( (response) => {
            if (response.data.success) {
                this.setState({
                    SprintBackLog: JSON.parse(response.data.data),
                    //Pendiente: [],
                    //PaginacionP: {...this.state.PaginacionP, NumeroPagina: 1, RegistrosPagina: 5, 
                    //NumeroPaginas: 0, IDSprintBackLog : JSON.parse(response.data.data).PKID},
                    //LoadingP : true,
                    //EnCurso: [],
                    //PaginacionEC: {...this.state.PaginacionEC, NumeroPagina: 1, RegistrosPagina: 5, NumeroPaginas: 0, IDSprintBackLog : JSON.parse(response.data.data).PKID },
                    //LoadingEC : true,
                    //Hecho: [],
                    //PaginacionH: {...this.state.PaginacionH, NumeroPagina: 1, RegistrosPagina: 5, //NumeroPaginas: 0 , IDSprintBackLog : JSON.parse(response.data.data).PKID},
                    //LoadingH : true,
                }, ()=> {
                    
                })
            }else{
                this.toast.show({severity:'info', summary: 'No Existe Sprint con ese PKID', detail:response.data.data, life: 3000});
            }
        })
        .catch( (error) => {
            alert(error)
        })
        .finally( () => {
            this.OcultarSprint()
        })
    }


    async obtnerListaTareas(Estado, Paginacion, Loading){
        axios.post(url()+'Tareo/ListarTareas',
            {
                'Pendiente': this.state.PaginacionP,
                'EnCurso' : this.state.PaginacionEC,
                'Hecho' : this.state.PaginacionH
            }[Estado]
        ).then((response) => {
            if(response.data.success){
                let json = ''
                switch(Estado){
                    case 'Pendiente': 
                        json = this.state.Pendiente.concat(JSON.parse(response.data.data).ItemSprintBackLog ? JSON.parse(response.data.data).ItemSprintBackLog : [])
                        break
                    case 'EnCurso' : 
                        json = this.state.EnCurso.concat(JSON.parse(response.data.data).ItemSprintBackLog ? JSON.parse(response.data.data).ItemSprintBackLog : []) 
                        break 
                    case 'Hecho' : 
                        json = this.state.Hecho.concat(JSON.parse(response.data.data).ItemSprintBackLog ? JSON.parse(response.data.data).ItemSprintBackLog : [])
                        break
                    default : break
                }this.setState({
                    [Estado] : json,
                    [Paginacion] : JSON.parse(response.data.data).Paginacion
                }, () => {
                    this.setState({
                        [Loading] : false})
                });
            }else{
                console.log(response.data)
            }
        }).catch((error) => {
            alert(error)
        })
    }

    handleOnChangeModalSprint=(event)=>{
        switch(event.target.name){
            case 'Abierto': 
                this.setState({
                    SprintBackLogEdicion: {
                        ...this.state.SprintBackLogEdicion, 
                        Abierto: event.target.checked                        
                    }
                })
                break
                
            default:
                this.setState({
                    SprintBackLogEdicion:{
                        ...this.state.SprintBackLogEdicion,
                        [event.target.name]: event.target.value
                    }
                })
                break
        }


        
    }

    handleOnChangeSprint = selectedOption => {
        this.setState({ 
            SprintBackLog:{
                ...this.state.SprintBackLog,
                PKID:selectedOption.value.code,
                Nombre:selectedOption.value.name
            }
        }, () => {
            this.obtenerFiltro()
        })
    }



    
    handleOnChangeCategorizacion = selectedOption => {
        this.setState({ 
            Ticket:{
                ...this.state.Ticket,
                  
                                    
                    Categorizacion:{
                        PKID: selectedOption.value.code,
                        Descripcion: selectedOption.value.name
                    }
                             
            }
        }, () => {
            //this.obtenerFiltro()
        })
    }

    handleOnSubmitSprint = e => {
        e.preventDefault()
        const{errores,...sinErrores} = this.state.SprintBackLogEdicion
        const result = validarSprint(sinErrores);
        if(Object.keys(result).length){
            return this.setState({errores: result});
        }else{
            this.crearActualizarSprint()
        }
    }

    async crearActualizarSprint(){
        axios.post(url()+'Tareo/CrearActualizarSprint?IDUsuario='+getDecode().sub,this.state.SprintBackLogEdicion)
        .then( (response) => {
            if (response.data.success) {
                this.toast.show({severity:'info', summary: 'Guardadao correctamente', detail : response.data.data, life: 3000})
            }else{
                this.toast.show({severity : 'error', summary : 'Error al Guardar', detail : response.data.data, life: 1500})
            }
        })
        .catch( (error) => {
            alert(error)
        }).finally( () => {
            this.setState({
                SprintBackLogEdicion : cSprintBackLog,
                modalSprintAbierto:false
            }, () => {
                this.OcultarSprint()
                this.obtenerListaSprintDDL()
                this.obtenerPKIDUltimoSprintAbierto()
            })
        })
    }

    async eliminarSprint(){
        axios.post(url()+'Tareo/EliminarSprint?IDSprintBackLog='+this.state.SprintBackLog.PKID)
        .then( (response) => {
            if (response.data.success) {
                this.toast.show({severity:'info', summary: 'Sprint Eliminado', detail : response.data.data, life: 3000})
            }else{
                this.toast.show({severity:'info', summary: 'No se pudo Eliminar el Sprint', detail : response.data.data, life: 3000})
            }
        })
        .catch( (error) => {
            throw new Error(error.message);
        })
        .finally( () => {
            this.obtenerListaSprintDDL()
            this.obtenerPKIDUltimoSprintAbierto()
        })
    }
    //Fin Sprint

    //Tareo
    async getProyectoDLL(){
        axios.post(url()+'Proyecto/ListarDLL?IDUsuario='+getDecode().sub)
        .then( (response) => {
            if (response.data.success) {
                let lista = [], listaAPI=JSON.parse(response.data.data)
                Object.keys(listaAPI).forEach(function(key){
                    lista.push({
                        code: listaAPI[key].PKID,
                        name: listaAPI[key].Nombre,
                    })
                })
                this.setState({ListaProyectoDDL : lista},
                    () => {
                        this.AgregarProyectoReferenciado()})
            }else{
                throw new Error(response.data.data)
            }
        })
        .catch( (error) => {
            this.toast.show({
                severity:'error', 
                summary: 'Listar proyecto', 
                detail: error.message, 
                life: 1500})
        })
    }

    AgregarProyectoReferenciado(){
        if(this.state.ItemSprintBackLog.ProyectoVersion.PKID > 0){
            let lista = this.state.ListaProyectoDDL
            let obj={
                code:this.state.ItemSprintBackLog.ProyectoVersion.PKID,
                name:this.state.ItemSprintBackLog.ProyectoVersion.Nombre
            }
            if(Array.isArray(lista)){
                if(lista.length<=0){
                    lista.push(obj)
                }else{
                    let encontrado=false
                    for(let i = 0; i < lista.length; i++) {
                        if (lista[i].code === obj.code){
                            encontrado=true
                            break
                        } 
                    }
                    if(!encontrado) lista.unshift(obj)
                }
            }else{
                lista=[]
                lista.push(obj)
            }
            this.setState({ ListaProyectoDDL : lista})
            this.getRequerimientoDLL();
        }
    }

    async getRequerimientoDLL(){
        axios.get(url()+'ItemBackLog/ListarDLL',{
            params: {
                IDBackLogVersion : this.state.ItemSprintBackLog.ProyectoVersion.PKID
            }
        })
        .then( (response) => {
            if (response.data.success) {
                let lista = [], listaAPI=JSON.parse(response.data.data)
                Object.keys(listaAPI).forEach(function(key){
                    lista.push({
                        code: listaAPI[key].PKID,
                        name: listaAPI[key].Requisito,
                    })
                })
                this.setState({ListaRequerimientoDDL : lista},
                    () => {
                        this.AgregarRequerimientoReferenciado()
                    })
            }else{
                throw new Error(response.data.data)
            }
        })
        .catch( (error) => {
            this.toast.show({
                severity:'error', 
                summary: 'Listar requerimientos', 
                detail: error.message, 
                life: 1500})
        })
    }

    AgregarRequerimientoReferenciado(){
        if(this.state.ItemSprintBackLog.RequerimientoVersion.PKID>0){
            let lista = this.state.ListaRequerimientoDDL
            let obj={
                code:this.state.ItemSprintBackLog.RequerimientoVersion.PKID,
                name:this.state.ItemSprintBackLog.RequerimientoVersion.Requisito
            }
            if(Array.isArray(lista)){
                if(lista.length<=0){
                    lista.push(obj)
                }else{
                    let encontrado=false
                    for(let i = 0; i < lista.length; i++) {
                        if (lista[i].code === obj.code){
                            encontrado=true
                            break
                        } 
                    }
                    if(!encontrado) lista.unshift(obj)
                }
            }else{
                lista=[]
                lista.push(obj)
            }
            this.setState({ ListaRequerimientoDDL : lista})
            this.getItemRequerimientoDLL()
        }
    }

    async getItemRequerimientoDLL(){
        axios.get(url()+'ItemRequerimiento/ListarDLL',{
            params: {
                IDItemBackLogVersion : this.state.ItemSprintBackLog.RequerimientoVersion.PKID
            }
        })
        .then( (response) => {
            if (response.data.success) {
                let lItemRequerimiento = []
                Object.keys(JSON.parse(response.data.data)).forEach(function(key){
                    lItemRequerimiento.push({
                        code: JSON.parse(response.data.data)[key].PKID,
                        name: JSON.parse(response.data.data)[key].Requisito,
                    })
                })
                this.setState({ListaItemRequerimientoDDL : lItemRequerimiento},
                    () => {
                        this.AgregarItemRequerimientoReferenciado()
                    })
            }else{
                throw new Error(response.data.data)
            }
        })
        .catch( (error) => {
            this.toast.show({
                severity:'error', 
                summary: 'Listar item requerimientos', 
                detail: error.message, 
                life: 1500})
        })
    }

    AgregarItemRequerimientoReferenciado(){
        if(this.state.ItemSprintBackLog.ItemRequerimientoVersion.PKID>0){
            let lista = this.state.ListaItemRequerimientoDDL
            let obj={
                code:this.state.ItemSprintBackLog.ItemRequerimientoVersion.PKID,
                name:this.state.ItemSprintBackLog.ItemRequerimientoVersion.Requisito
            }
            if(Array.isArray(lista)){
                if(lista.length<=0){
                    lista.push(obj)
                }else{
                    let encontrado=false
                    for(let i = 0; i < lista.length; i++) {
                        if (lista[i].code === obj.code){
                            encontrado=true
                            break
                        } 
                    }
                    if(!encontrado) lista.unshift(obj)
                }
            }else{
                lista=[]
                lista.push(obj)
            }
            this.setState({ ListaItemRequerimientoDDL : lista})
        }
    }


    async obtenerListaAreaDDL(){
        axios.post(url()+'Area/ListarAreas')
        .then( (response) => {
            if (response.data.success) {
                let lista = [], listaAPI=JSON.parse(response.data.data)
                Object.keys(listaAPI).forEach(function(key){
                    lista.push({
                        code: listaAPI[key].PKID,
                        name: listaAPI[key].Nombre,
                    })
                })
                this.setState({ListaAreaDDL : lista})
            }else{
                alert(response.data.data)
            }
        }).catch( (error) => {
            alert(error)
        })
    }

    async obtenerListaEmpleadoDDL(){
        axios.post(url()+'Empleado/ListarEmpleados')
        .then( (response) => {
            if (response.data.success) {
                let lista = [], lista2 = [], listaAPI=JSON.parse(response.data.data)
                Object.keys(listaAPI).forEach(function(key){
                    lista.push({
                        code: listaAPI[key].PKID,
                        name: listaAPI[key].Nombre,
                    })
                    if(listaAPI[key].Area.Codigo === '06')
                        lista2.push({
                            code: listaAPI[key].PKID,
                            name: listaAPI[key].Nombre,
                        })
                })
                this.setState({
                    ListaEmpleadoDDL : lista,
                    ListaEmpleadoDesarrolloDDL : lista2})
            }else{
                this.setState({ListaRequerimientoDDL : []},
                    () => {
                        this.toast.show({
                            severity: 'warn', 
                            summary: 'Empleados', 
                            detail: 'No se encontraron empleados.', 
                            life: 3000});
                    })
            }
        })
        .catch( (error) => {
            alert(error)
        })
    }

    async obtenerListaTicketDDL(){
        axios.post(url()+'Tareo/ListarTicketDDL',this.state.Usuario)
        .then( (response) => {
            if (response.data.success) {
                let lista = [], listaAPI = JSON.parse(response.data.data)
                Object.keys(listaAPI).forEach(function(key){
                    lista.push({
                        code: listaAPI[key].PKID,
                        name: listaAPI[key].Descripcion,
                    })
                })
                this.setState({ListaTicketDDL : lista})
            }else{
                this.setState({ListaTicketDDL : []},
                    () => {
                        this.toast.show({
                            severity: 'warn', 
                            summary: 'Ticket', 
                            detail: 'No se encontraron tickets.', 
                            life: 3000});
                    })
            }
        })
        .catch( (error) => {
            alert(error)
        })
    }

    async obtenerListaIncidenteDDL(){
        axios.post(url()+'Tareo/ListarIncidenteDDL?IDUsuario='+getDecode().sub)
        .then((response) => {
            if (response.data.success) {
                let lista = [], listaAPI=JSON.parse(response.data.data)
                Object.keys(listaAPI).forEach(function(key){
                    lista.push({
                        code: listaAPI[key].PKID,
                        name: listaAPI[key].Descripcion,
                    })
                })
                this.setState({ListaIncidenteDDL : lista})
            }else{
                this.setState({ListaIncidenteDDL : []})
            }
            //console.log("ListarIncidente",this.state.ListaIncidenteDDL) PARA VER EL BOTON ORIGEN
            //console.log("gg",getDecode()) PARA VER EL BOTON ORIGEN
        })
        .catch( (error) => {
            alert(error)
        })
    }

    async obtenerListaEstadoPrincipalesDDL(){
        axios.post(url()+'Tareo/ListarEstadoPrincipalesDDL')
        .then( (response) => {
            if (response.data.success) {
                let lista = [], listaAPI=JSON.parse(response.data.data)
                Object.keys(listaAPI).forEach(function(key){
                    lista.push({
                        code: listaAPI[key].PKID,
                        name: listaAPI[key].Descripcion,
                    })
                })
                this.setState({ListaEstadoDDL : lista})
            }else{
                this.setState({ListaEstadoDDL : []},
                    () => {
                        this.toast.show({
                            severity: 'warn', 
                            summary: 'Estados', 
                            detail: 'No se encontraron estados.', 
                            life: 3000});
                    })
            }
        })
        .catch( (error) => {
            alert(error)
        })
    }

    pulsarCronometro = e => {
        e.preventDefault()
        if(!this.state.SprintBackLog.Abierto){
            this.toast.show({
                severity:'error', 
                summary: 'Sprint Cerrado', 
                detail:"El Sprint debe estar abierto para poder trabajar en el", life: 3000});
            return
        }
        var mes = parseInt(new Date().getMonth()+1).toString().length == 1 ? "0"+ parseInt(new Date().getMonth()+1).toString() : parseInt(new Date().getMonth()+1).toString();        
        var day = new Date().getDate().toString().length == 1 ? "0"+ new Date().getDate().toString() : new Date().getDate().toString();        
        var currentDate = new Date().getFullYear() + "-"+ mes + "-"+ day;           
        if((currentDate<this.state.SprintBackLog.FechaInicio.split('T')[0]) || (currentDate>this.state.SprintBackLog.FechaFin.split('T')[0])){
            this.toast.show({
                severity:'error', 
                summary: 'Sprint Cerrado', 
                detail:"No se puede tarear porque no corresponde al rango de fechas del sprint", life: 3000});
            return
        }
        if(!this.state.cronometroEncendido){
            this.empezarCronometro()
        }else{
            this.DetenerCronometro()
        }
    }

    //Reducir a 1 
    async empezarCronometro(){
        try{
            const { data } = await axios.post(url()+'Tareo/EmpezarCronometro?IDItemSprintBackLog='+this.state.ItemSprintBackLog.PKID+'&IDResponsable='+getDecode().sub);
            if (data.success) {
                this.setState({
                    ...this.state,
                    cronometroEncendido:true,
                    habilitarBotonCronometro:true
                })
                this.actualizarTareosEnCurso()
            }else{
                this.toast.show({severity:'error', summary: 'No se pudo iniciar el cronometro', detail:data.data, life: 1500});
                this.setState({
                    ...this.state,
                    habilitarBotonCronometro:true
                })
            }
        }catch(error){
            this.toast.show({severity:'error', summary: 'Error al conectar con el servidor', detail:'No se puede Iniciar cronometro', life: 1500})
            console.log(error)
        }
    }

    async DetenerCronometro(){
        try{
            const { data } = await axios.post(url()+'Tareo/DetenerCronometro?IDItemSprintBackLog='+this.state.ItemSprintBackLog.PKID+'&IDResponsable='+getDecode().sub);
            if (data.success) {
                this.setState({
                    ...this.state,
                    cronometroEncendido:false,
                    habilitarBotonCronometro:true
                })
                this.actualizarTareosEnCurso()
            }else{
                this.toast.show({severity:'error', summary: 'No se pudo detener el cronometro', detail:data.data, life: 1500});
                this.setState({
                    ...this.state,
                    habilitarBotonCronometro:true
                })
            }
        }catch(error){
            this.toast.show({severity:'error', summary: 'Error al conectar con el servidor', detail:'No se puede Detener El cronometro', life: 1500})
            console.log(error)
        }
    }
    //Fin reducir

    handleOnChangeModal=(event)=>{
        if(event.target.value < 0) return
        if(event.target.value > 0){
            let numero=event.target.value.toString().split('.')
            if(numero.length === 2){
                if(numero[1].length > 2) return
            }
        }
        this.setState({
            ItemSprintBackLog:{
                ...this.state.ItemSprintBackLog,
                [event.target.name]: event.target.value
            }
        })
    }

    handleOnChangeProyecto = (event) => {
        this.setState({
            ItemSprintBackLog:{
                ...this.state.ItemSprintBackLog,
                ProyectoVersion:{
                    PKID: event.value.code,
                    Nombre: event.value.name
                },
                RequerimientoVersion:{
                    PKID : 0,
                    Requisito : ''
                },
                ItemRequerimientoVersion:{
                    PKID : 0,
                    Requisito : ''
                }
            },
            ListaRequerimientoDDL : [],
            ListaItemRequerimientoDDL : [],
        }, () => {
            if(this.state.ItemSprintBackLog.ProyectoVersion.PKID>0){
                this.getRequerimientoDLL()
            }
        })
    }

    handleOnChangeRequerimiento=(event)=>{
        this.setState({
            ItemSprintBackLog:{
                ...this.state.ItemSprintBackLog,
                RequerimientoVersion:{
                    PKID : event.value.code,
                    Requisito : event.value.name
                },
                ItemRequerimientoVersion:{
                    PKID : 0,
                    Requisito : ''
                }
            },
            ListaItemRequerimientoDDL : [],
        }, () => {
            if(this.state.ItemSprintBackLog.RequerimientoVersion.PKID > 0){
                this.getItemRequerimientoDLL()
            }
        })
    }

    handleOnChangeItemRequerimiento = (e) => {
        this.setState({
            ItemSprintBackLog:{
                ...this.state.ItemSprintBackLog,
                ItemRequerimientoVersion:{
                    PKID : e.value.code,
                    Requisito : e.value.name
                }
            }
        })
    }

    handleOnChangeArea = (event) => {
        this.setState({
            ItemSprintBackLog:{
                ...this.state.ItemSprintBackLog,
                Area:{
                    PKID : event.value.code,
                    Nombre : event.value.name
                }
            }
        })
    }

    handleOnChangeColaborador = (event) => {
        this.setState({
            ItemSprintBackLog:{
                ...this.state.ItemSprintBackLog,
                Empleado:{
                    PKID : event.value.code,
                    Nombre : event.value.name
                }
            },
        })
    }

    handleOnChangeTareaAsociada = (event) => {
        this.setState({
            ItemSprintBackLog:{
                ...this.state.ItemSprintBackLog,
                Ticket:{
                    PKID : event.value.code,
                    Nombre : event.value.name
                }
            }
        })
    }
    handleOnChangeIncidente = (event) => {
        this.setState({
            ItemSprintBackLog:{
                ...this.state.ItemSprintBackLog,
                Incidente:{
                    ...this.state.ItemSprintBackLog.Incidente,
                    PKID : event.value.code,
                    Incidencia : event.value.name
                }
            }
        })
    }
    handleChangeEstado = (event) => {
        this.setState({ 
            ItemSprintBackLog : {
                ...this.state.ItemSprintBackLog,
                Estado : {
                    PKID : event.value.code,
                    Descripcion : event.value.name
                }
            }
        });
    }

    handleOnSubmit = e => {
        e.preventDefault()
        if(!this.state.SprintBackLog.Abierto){
            this.toast.show({severity:'error', summary: 'Sprint Cerrado', detail:"El Sprint debe estar abierto para poder trabajar en el", life: 3000});
            return
        }
        const{errores,...sinErrores} = this.state.ItemSprintBackLog
        const result = validarTarea(sinErrores);
        if(Object.keys(result).length){
            return this.setState({errores: result});
        }else{
            this.setState({
                Request : true
            }, () => this.crearActualizarTarea())
        }
    }

    async crearActualizarTarea(){
        axios.post(url()+'Tareo/CrearActualizarTarea',this.state.ItemSprintBackLog)
        .then( (response) => {   
            if(response.data.success){
                this.toast.show({severity:'info', summary: 'Crear o actualizar tarea', detail: response.data.data, life: 3000})
                this.setState({
                    Pendiente: [],
                    PaginacionP: {...this.state.PaginacionP, NumeroPagina: 1, RegistrosPagina: 5, NumeroPaginas: 0},
                    LoadingP : true,
                    EnCurso: [],
                    PaginacionEC: {...this.state.PaginacionEC, NumeroPagina: 1, RegistrosPagina: 5, NumeroPaginas: 0 },
                    LoadingEC : true,
                    Hecho: [],
                    PaginacionH: {...this.state.PaginacionH, NumeroPagina: 1, RegistrosPagina: 5, NumeroPaginas: 0 },
                    LoadingH : true,
                    modalTareaAbierto:false,
                    ItemSprintBackLog : cItemSprintBackLog
                },() => {
                    this.componentDM()
                })
            }else{
                throw new Error(response.data.data)
            }
        })
        .catch( (error) => {
            this.toast.show({
                severity:'error', 
                summary: 'Crear o actualizar tarea', 
                detail: error.message, 
                life: 1500})
        })
        .finally( () => {
            this.setState({Request : false})
            this.OcultarModalTicketCategorizacion()
        })
    }

    agregarTarea = e => {
        if(!this.state.SprintBackLog.Abierto){
            this.toast.show({severity:'warn', summary: 'Sprint Cerrado', detail:"El Sprint debe estar abierto para poder trabajar en el", life: 3000});
            return
        }
        switch(e.item.name){
            case 'Requisito' : {
                this.setState({
                    habilitarBotonCronometro:false,
                    modalTareaActualizar:false,
                    ItemSprintBackLog:{
                        ...this.state.ItemSprintBackLog,
                        Desarrollo:true,
                        IDSprintBackLog:this.state.SprintBackLog.PKID,
                        EsIncidente : false
                    }
                },() => this.setState({modalTareaAbierto: true}))
                break
            }
            case 'Ticket' : {
                this.setState({
                    habilitarBotonCronometro : false,
                    modalTareaActualizar : false,
                    ItemSprintBackLog : {
                        ...this.state.ItemSprintBackLog,
                        Desarrollo : false,
                        IDSprintBackLog : this.state.SprintBackLog.PKID,
                        EsIncidente : false
                    }
                },() => this.setState({modalTareaAbierto: true}))
                break
            }
            case 'Incidente' : {
                this.setState({
                    habilitarBotonCronometro : false,
                    modalTareaActualizar : false,
                    ItemSprintBackLog : {
                        ...this.state.ItemSprintBackLog,
                        Desarrollo : false,
                        IDSprintBackLog : this.state.SprintBackLog.PKID,
                        EsIncidente : true
                    }
                },() => this.setState({modalTareaAbierto: true}))
                break
            }
            default : break
        }
    }

    copiarTarea = event=> {
        axios.post(url()+'Tareo/CopiarTarea',event)
        .then( (response) => {   
            if(response.data.success){
                this.toast.show({severity:'info', summary: 'Crear o actualizar tarea', detail: response.data.data, life: 3000})
                this.setState({
                    Pendiente: [],
                    PaginacionP: {...this.state.PaginacionP, NumeroPagina: 1, RegistrosPagina: 5, NumeroPaginas: 0},
                    LoadingP : true,
                    EnCurso: [],
                    PaginacionEC: {...this.state.PaginacionEC, NumeroPagina: 1, RegistrosPagina: 5, NumeroPaginas: 0 },
                    LoadingEC : true,
                    Hecho: [],
                    PaginacionH: {...this.state.PaginacionH, NumeroPagina: 1, RegistrosPagina: 5, NumeroPaginas: 0 },
                    LoadingH : true,
                    modalTareaAbierto:false,
                    ItemSprintBackLog : cItemSprintBackLog
                },() => {
                    this.componentDM()
                })
            }else{
                throw new Error(response.data.data)
            }
        })
        .catch( (error) => {
            this.toast.show({
                severity:'error', 
                summary: 'Crear o actualizar tarea', 
                detail: error.message, 
                life: 1500})
        })
        .finally( () => {
            this.setState({Request : false})
            this.OcultarModalTicketCategorizacion()
        })
    }

    editarTarea = event=> {        
        if(event.Desarrollo){
            event={
                ...event,
                Area:{
                    PKID:0,
                },
                Empleado:{
                    PKID:0,
                },
                Ticket:{
                    PKID:0,
                },
                Incidente:{
                    PKID:0
                },
                EsIncidente:false
            }
        }else{
            if(event.Ticket!==null && event.Ticket!==undefined){
                event={
                    ...event,
                    ProyectoVersion:{
                        PKID:0,
                    },
                    RequerimientoVersion:{
                        PKID:0,
                    },
                    Incidente:{
                        PKID:0
                    },
                    EsIncidente:false
                }
            }else if(event.Incidente!==null && event.Incidente!==undefined){
                event={
                    ...event,
                    ProyectoVersion:{
                        PKID:0,
                    },
                    RequerimientoVersion:{
                        PKID:0,
                    },
                    Area:{
                        PKID:0,
                    },
                    Empleado:{
                        PKID:0,
                    },
                    Ticket:{
                        PKID:0,
                    },
                    EsIncidente:true
                }
                
            }else{
                this.toast.show({severity:'error', summary: 'No se puede abrir la tarea', detail:"No existe el Proyecto, Requerimiento, Ticket o Incidente asociado", life: 3000});
                return
            }
        }
        this.setState({
            modalTareaAbierto : true,
            habilitarBotonTarea : true,
            habilitarBotonCronometro : true,
            modalTareaActualizar : true,
            ItemSprintBackLog : event
        },()=>{
            if(event.Desarrollo){
                this.getProyectoDLL()
            }else{
                if(event.Ticket.PKID>0){
                    this.obtenerListaTicketDDL()
                    this.obtenerListaAreaDDL()
                    this.obtenerListaEmpleadoDDL()
                }else if(event.Incidente.PKID>0){
                    this.obtenerListaIncidenteDDL()
                }
            }
        })
        this.obtenerEstadoDeEjecucion(event)
    }

    async obtenerEstadoDeEjecucion(event){
        try{
            const { data } = await axios.post(url()+'Tareo/ObtenerEstadoEjecucionTarea?IDItemSprintBackLog='+event.PKID);
            if(data.success){
                if(data.data==='1'){
                    this.setState({
                        ...this.state,
                        cronometroEncendido:true
                    },()=>this.obtenerSegundosEjecutados(event))
                }else{
                    this.setState({
                        ...this.state,
                        cronometroEncendido:false
                    },()=>this.obtenerSegundosEjecutados(event))
                }
            }else{
                this.toast.show({severity:'error', summary: 'Error Estado Ejecucion', detail:data.data, life: 1500});
            }
        }catch(error){
            this.toast.show({severity:'error', summary: 'Error al conectar con el servidor', detail:'No se puede obtener el estado de ejecución de la Tarea', life: 1500})
            console.log(error)
        }
    }

    async obtenerSegundosEjecutados(evento){
        try{
            const { data } = await axios.post(url()+'Tareo/ObtenerSegundosEjecutadosTarea?IDItemSprintBackLog='+evento.PKID);
            if (data.success) {
                this.setState({
                    ...this.state,
                    ItemSprintBackLog:{
                        ...this.state.ItemSprintBackLog,
                        HorasEjecutadas:data.data
                    }
                })
            }else{
                this.toast.show({severity:'error', summary: 'Error Obtencion de tiempo ejecutado de la tarea', detail:data.data, life: 1500});
            }
        }catch(error){
            this.toast.show({severity:'error', summary: 'Error al conectar con el servidor', detail:'No se puede obtener el tiempo que se ha ejecutado la Tarea', life: 1500})
            console.log(error)
        }
    }

    //Fin Tareo
    Paginacion = (Estado,Paginacion,Loading) => {
        if(
            (
                {
                    'Pendiente': ((this.state.PaginacionP.NumeroPagina < this.state.PaginacionP.NumeroPaginas) && !this.state.LoadingP),
                    'EnCurso' : ((this.state.PaginacionEC.NumeroPagina < this.state.PaginacionEC.NumeroPaginas) && !this.state.LoadingEC),
                    'Hecho' : ((this.state.PaginacionH.NumeroPagina < this.state.PaginacionH.NumeroPaginas) && !this.state.LoadingH)
                }[Estado]
            )){
                let jsonPaginacion = ''
                switch(Estado){
                    case 'Pendiente':
                        jsonPaginacion = {...this.state.PaginacionP,NumeroPagina: this.state.PaginacionP.NumeroPagina + 1}
                        break
                    case 'EnCurso' : 
                        jsonPaginacion = {...this.state.PaginacionEC,NumeroPagina: this.state.PaginacionEC.NumeroPagina + 1}
                        break
                    case 'Hecho' : 
                        jsonPaginacion = {...this.state.PaginacionH,NumeroPagina: this.state.PaginacionH.NumeroPagina + 1}
                        break
                    default : break
                }
                this.setState({
                    [Loading] : true,
                    [Paginacion]: jsonPaginacion 
                },
                () => {
                    this.obtnerListaTareas(Estado,Paginacion,Loading)
                })
        }
    }

    //Filtro
    onChangeFiltro=(checks, nombre)=>{
        this.setState({
            ...this.state,
            Filtro:{
                ...this.state.Filtro,
                [nombre]: checks
            },
        }, ()=>this.crearActualizarFiltro())
    }

    onProyectoChange = (event) => {
        if(event.value===null){
            this.setState({
                Filtro:{
                    ...this.state.Filtro,
                    IDSProyectos : ""
                }
            }, ()=>this.crearActualizarFiltro())
        }else{
            let cl = "-"+event.value.code
            this.setState({
                Filtro:{
                    ...this.state.Filtro,
                    IDSTickets : '',
                    IDSProyectos : cl
                }
            }, ()=>this.crearActualizarFiltro())
        }
    }

    onTicketChange = (event) => {
        if(event.value === null){
            this.setState({
                Filtro:{
                    ...this.state.Filtro,
                    IDSTickets : ''
                }
            }, ()=>this.crearActualizarFiltro())
        }else{
            let cl = "-"+event.value.code
            this.setState({
                Filtro:{
                    ...this.state.Filtro,
                    IDSProyectos : '',
                    IDSTickets : cl
                }
            }, ()=>this.crearActualizarFiltro())
        }
    }

    async crearActualizarFiltro(){
        try{
            const {data}=await axios.post(url()+'Tareo/CrearActualizarFiltro',this.state.Filtro)
            if(data.success){
                if(JSON.parse(data.data).PKID>0){
                    this.setState({
                        ...this.state,
                        Filtro:{
                            ...this.state.Filtro,
                            PKID: JSON.parse(data.data).PKID
                        }
                    })
                }
                this.obtenerSprint2(this.state.SprintBackLog.PKID, getDecode().sub)
            }else{
                this.setState({Filtro : cFiltro})
                this.obtenerSprint2(this.state.SprintBackLog.PKID, 0)
                this.toast.show({severity:'error', summary: 'Error al Guardar Filtro', detail:data.data, life: 1500})
            }
        }catch(error){
            this.setState({Filtro : cFiltro})
            this.obtenerSprint2(this.state.SprintBackLog.PKID, 0)
            this.toast.show({severity:'error', summary: 'Error al conectar con el servidor', detail:'No se puede crear o actualizar el filtro', life: 1500})
        }
    }

    async limpiarFiltro(){
        try{
            const {data}=await axios.post(url()+'Tareo/LimpiarFiltro?IDUsuario='+getDecode().sub+'&IDTipo='+3)
            if(data.success){
                //this.componentDM()
            }else{
                this.toast.show({severity:'error', summary: 'Error al Limpiar Filtro', detail:data.data, life: 1500})
            }
        }catch(error){
            this.toast.show({severity:'error', summary: 'Error al conectar con el servidor', detail:'No se puede limpiar el filtro', life: 1500})
            console.log(error)
        }
        this.setState({Filtro : cFiltro})
        this.obtenerSprint2(this.state.SprintBackLog.PKID, 0)
    }

    async AplicarFiltro(){
        this.obtenerFiltro()
    }
    //FinFiltro

    //EstadoInicial, EstadoFinal, PKID
    MoverEstado = ( EstadoI, EstadoF, PKID)=>{
        if(EstadoI !== EstadoF){
            let Obj
            switch(EstadoF){
                case 'Pendiente' :
                    Obj = this.ObtenerObjeto({'Pendiente': this.state.Pendiente,'EnCurso' : this.state.EnCurso,'Hecho' : this.state.Hecho}[EstadoI], PKID)[0]
                    Obj = {
                        ...Obj,
                        Estado:{
                            ...Obj.Estado,
                            PKID: 1
                        }
                    }
                    this.setState({ ItemSprintBackLog : Obj },() => this.crearActualizarTarea())
                    break
                case 'EnCurso' :
                    Obj = this.ObtenerObjeto({'Pendiente': this.state.Pendiente,'EnCurso' : this.state.EnCurso,'Hecho' : this.state.Hecho}[EstadoI], PKID)[0]
                    Obj = {
                        ...Obj,
                        Estado:{
                            ...Obj.Estado,
                            PKID: 2
                        }
                    }

                   

                    this.setState({ ItemSprintBackLog : Obj },() => this.crearActualizarTarea())
                    break
                case 'Hecho' :
                    Obj = this.ObtenerObjeto({'Pendiente': this.state.Pendiente,'EnCurso' : this.state.EnCurso,'Hecho' : this.state.Hecho}[EstadoI], PKID)[0]
                    Obj = {
                        ...Obj,
                        Estado:{
                            ...Obj.Estado,
                            PKID: 3
                        }
                    }

                    if ( Obj.Ticket ) {
                        if (! Obj.Ticket.EstadoFijo ){
                            this.setState({ ItemSprintBackLog : Obj })
                            this.setState({ Ticket : Obj.Ticket }) 
                            document.getElementById('ModalTicketCategorizacion').classList.add('active')
                            document.getElementById('PopupSprint2').classList.add('active')
                        } else {
                            this.setState({ ItemSprintBackLog : Obj },() => this.crearActualizarTarea())          
                        }
                    } else {
                            this.setState({ ItemSprintBackLog : Obj },() => this.crearActualizarTarea())
                        }                    
                    break
                default:
                    break
            }
        }
    }

    ObtenerObjeto(Array, PKID){
        return Array.filter( (e) =>{
            return e.PKID === parseInt(PKID,10) 
        })
    }

    OcultarSprint = () =>{
        document.getElementById('PanelSprint').classList.remove('active')
        document.getElementById('PopupSprint').classList.remove('active')
    }

    OcultarModalTicketCategorizacion = () =>{
        document.getElementById('ModalTicketCategorizacion').classList.remove('active')
        document.getElementById('PopupSprint2').classList.remove('active')
    }

    render(){
        return(
            <div>
                <Toast ref={(el) => this.toast = el} />
                <PanelSprint
                    Sprint={this.state.SprintBackLog}
                    ListaSprintDDL={this.state.ListaSprintDDL}
                    nuevoSprint={() => {
                        this.setState({
                            modalSprintAbierto : true,
                        })
                    }}
                    editarSprint={() => {
                        this.setState({
                            SprintBackLogEdicion:this.state.SprintBackLog,
                            modalSprintAbierto : true,
                        })
                    }}
                    eliminarSprint={()=>this.eliminarSprint()}
                    handleOnChangeSprint={this.handleOnChangeSprint}/>
                <HeaderTareo />
                <ModalSprint
                    mostrarModal = {this.state.modalSprintAbierto}
                    SprintBackLog = {this.state.SprintBackLogEdicion}
                    onClick={() => {
                        this.setState({
                            modalSprintAbierto : false,
                            SprintBackLogEdicion : cSprintBackLog
                        })
                    }}
                    handleOnChangeModalSprint = {this.handleOnChangeModalSprint}
                    onSubmit = {this.handleOnSubmitSprint}
                    Errores = {this.state.errores}/>
                <CuerpoTareo
                    Paginacion = {this.Paginacion}
                    //Pendiente
                    ListaTareasPendientes = {this.state.Pendiente}
                    PaginacionP = {this.state.PaginacionP}
                    LoadingP = {this.state.LoadingP}
                    //EnCurso
                    ListaTareasEnCurso = {this.state.EnCurso} 
                    PaginacionEC = {this.state.PaginacionEC}
                    LoadingEC = {this.state.LoadingEC}
                    //Hecho
                    ListaTareasHecho = {this.state.Hecho} 
                    PaginacionH = {this.state.PaginacionH}
                    LoadingH = {this.state.LoadingH}
                    //Métodos
                    agregarTarea = {this.agregarTarea}
                    editarTarea = {this.editarTarea}
                    copiarTarea = {this.copiarTarea}
                    MoverEstado = {this.MoverEstado}/>
                <ModalTareo 
                    //Objetos 
                    mostrarModal={this.state.modalTareaAbierto}
                    esActualizacion={this.state.modalTareaActualizar}
                    ListaProyectoDDL={this.state.ListaProyectoDDL}
                    ListaRequerimientoDDL={this.state.ListaRequerimientoDDL}
                    ListaAreaDDL={this.state.ListaAreaDDL}
                    ListaEmpleadoDDL={this.state.ListaEmpleadoDDL}
                    ListaTicketDDL={this.state.ListaTicketDDL}
                    ListaIncidenteDDL={this.state.ListaIncidenteDDL}
                    ListaEstadoDDL={this.state.ListaEstadoDDL}
                    ListaItemRequerimientoDDL = {this.state.ListaItemRequerimientoDDL}
                    Tarea={this.state.ItemSprintBackLog}
                    habilitarBotonCronometro={this.state.habilitarBotonCronometro}
                    //Métodos
                    onClick={() =>{
                        this.setState({
                            modalTareaAbierto : false,
                            errores:{},
                            ItemSprintBackLog : cItemSprintBackLog
                        },() => {
                            
                            //this.componentDM()
                            /**
                             * esto actualizaba el modal cuando se cerraba con escape o x se quito para que actualize
                             * ahora para que haya una actualizacion necesariamente tiene que hacer click en el boton aceptar del modal
                             */
                        })
                    }}
                    pulsarCronometro={this.pulsarCronometro} 
                    onChange={this.handleOnChangeModal} 
                    onChangeProyecto={this.handleOnChangeProyecto} 
                    onChangeRequerimiento={this.handleOnChangeRequerimiento}
                    onChangeItemRequerimiento={this.handleOnChangeItemRequerimiento}
                    onChangeArea={this.handleOnChangeArea} 
                    onChangeColaborador={this.handleOnChangeColaborador}
                    onChangeTareaAsociada={this.handleOnChangeTareaAsociada}
                    onChangeIncidente={this.handleOnChangeIncidente} 
                    handleChangeEstado={this.handleChangeEstado}
                    onSubmit={this.handleOnSubmit}
                    cronometroEncendido={this.state.cronometroEncendido}
                    Errores = {this.state.errores}
                    Request = {this.state.Request}/>
                <FiltroTareo
                    Filtro = {this.state.Filtro}
                    ListaProyectoDDL = {this.state.ListaProyectoDDL}
                    ListaTicketDDL = {this.state.ListaTicketDDL}
                    ListaEmpleadoDDL = {this.state.ListaEmpleadoDesarrolloDDL}
                    ListaEstadoDDL = {this.state.ListaEstadoDDL}
                    onProyectoChange = {this.onProyectoChange}
                    onTicketChange = {this.onTicketChange}
                    eliminarFiltro = {(e)=>this.limpiarFiltro()}
                    AplicarFiltro = {(e)=>this.AplicarFiltro()}
                    onChangeFiltro = {this.onChangeFiltro}/>
                <ModalTicketCategorizacion
                    Ticket={this.state.Ticket}
                    ListaCategorizacion={this.state.ListaCategorizacion}
                    moverTicket={() => {         
                        var oTicket = this.state.Ticket
                        var oItemSprintBackLog = this.state.ItemSprintBackLog
                        oItemSprintBackLog.Ticket.Categorizacion = oTicket.Categorizacion
                        oItemSprintBackLog.Ticket.Estado = {PKID:3,Descripcion:'Hecho'}
                        
                        this.crearActualizarTarea()
                    }  
                }
                    noMoverTicket={() => {
                        var oTicket = this.state.Ticket
                        var oItemSprintBackLog = this.state.ItemSprintBackLog
                        oItemSprintBackLog.Ticket.Categorizacion = oTicket.Categorizacion
                        oItemSprintBackLog.Ticket.Estado = oTicket.Estado
                        
                        this.crearActualizarTarea()
                    }    }                   
                    handleOnChangeCategorizacion={this.handleOnChangeCategorizacion}/>
            </div>
        );
    }
}

export default Tareo;