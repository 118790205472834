import React from 'react'
import PanelPlanDePruebas from '../Components/PlanDePruebasPanel'
import PlanDePruebasHeader from '../Components/PlanDePruebasHeader'
import PlanDePruebasBody from '../Components/PlanDePruebasBody'
import ItemPlanDePruebasModal from '../Components/ItemPlanDePruebasModal'
import ItemPlanDePruebasRegresionModal from '../Components/ItemPlanDePruebasRegresionModal'
import { getPlanPrueba, setItemPlanPrueba } from '../../Services/LocalStorage'

import { AuthHelper } from '../../Domain/Utils/AuthHelper'
import { PlanPruebaService } from '../../Domain/Services/PlanPruebaService'
import { ProyectoService } from '../../Domain/Services/ProyectoService'
import { VersionProductoService } from '../../Domain/Services/VersionProductoService'
import { CasoPruebaService } from '../../Domain/Services/CasoPruebaService'
import { ItemPlanPruebaService } from '../../Domain/Services/ItemPlanPruebaService'

import { IPlanPrueba } from '../../Domain/Models/IPlanPrueba'
import { IPaginacion } from '../../Domain/Models/IPaginacion'
import { IItemPlanDePrueba } from '../../Domain/Models/IItemPlanDePrueba'

interface IProps {
}

interface IState {
    Modal: boolean
    ModalRegresion: boolean
    Pendiente: IPaginacion
    EnCurso: IPaginacion
    Hecho: IPaginacion
    PlanDePruebas: IPlanPrueba
    ItemPlanDePrueba: IItemPlanDePrueba
    ErrorPlanDePrueba: any
    ErrorItemPlanDePrueba: any
    Requerimientos: Array<object>
    ListaProyectos: Array<object>
    ListaPlanesPrueba: Array<object>
    ListaProducto: Array<object>
    ListaCasosPruebaVersion: Array<object>
    ProductoReferencia: {PKID: number, Nombre: string, VersionProducto: Array<object>}
    VersionProductoReferencia: {PKID: number, Version: string}
    ProyectoReferencia: {PKID: number, Nombre: string}
    PlanPruebaReferencia: {PKID: number, Nombre: string}
    ListaCasoPruebaRegresion: Array<object>
    Request: boolean
}

export default class PlanDePrueba extends React.Component<IProps, IState> {

    cPlanPruebas = {
        PKID: 0,
        Nombre: '',
        Descripcion: '',
        FechaCreacion: new Date(),
        Proyecto: {
            Nombre: '',
            Descripcion: '',
            FechaInicio: '',
            FechaFin: ''
        },
        Empleado: {
            PKID: parseInt(AuthHelper.GetDecoded().sub,10),
            Nombre: AuthHelper.GetDecoded().Empleado
        },
        Estado: {
            PKID: 1,
            Descripcion: 'Pendiente'
        }
    }

    cPaginacion = {
        Lista: [],
        Paginacion: {
            NumeroPagina: 1, 
            RegistrosPagina: 5, 
            NumeroPaginas: 0, 
            IDEstado: 1, 
            IDUsuario: parseInt(AuthHelper.GetDecoded().sub,10),
            IDPlanPrueba: getPlanPrueba() ? JSON.parse(getPlanPrueba() || '{}').PKID : 0
        },
        Loading: true
    }

    cItemPlanDePrueba = {
        PKID: 0,
        IDPlanDePrueba: getPlanPrueba() ? JSON.parse(getPlanPrueba() || '{}').PKID : 0,
        Nombre: '',
        FechaCreacion: new Date(),
        Requerimiento: {
            PKID : 0,
            Requisito : '',
            Estimacion : 0,
            FactorAjuste : 1,
            EstimacionAjustada : 0,
            IDBackLog : 0,
            FechaInicio : new Date(),
            FechaFin : new Date(),
            PorcentajeAvance : 0,
            Estado:{
                PKID: 0,
                Descripcion: ''
            },
            HorasEjecutadas : 0,
            ContextoEstandar : '',
            ProblemaEstandar : '',
            Responsable:{
                PKID:0,
                Nombre:''
            },
            Solicitud:{
                PKID:0,
                Nombre:'',
                Empleado:{
                    PKID:0,
                    Codigo:'',
                    Nombre:'',
                    Area:{
                        PKID:0,
                        Codigo:'',
                        Nombre:''
                    }
                }
            },            
            ArchivosAdjuntos:[],
            NumeroOCS: '',
            DescripcionOCS: '',
            Descripcion:'',
            ImplementacionItemBackLog:{
                PKID:0,
                Nombre:''
            },
            ArchivosSolucionAdjuntos: []
        },
        Empleado:{
            PKID: parseInt(AuthHelper.GetDecoded().sub,10),
            Nombre: AuthHelper.GetDecoded().Empleado
        },
        Estado: {
            PKID: 1,
            Descripcion: 'Pendiente'
        },
        EsDeRegresion: false,
        ListaCasoPrueba: [],
        ListaCheckHabilitado: [],
    }
    
    constructor(Props: any){
        super(Props)
        this.state={
            Modal: false,
            ModalRegresion:false,
            Pendiente: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 1}},
            EnCurso: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 2}},
            Hecho: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 3}},
            PlanDePruebas: getPlanPrueba() ? JSON.parse(getPlanPrueba() || '{}') : this.cPlanPruebas,
            ItemPlanDePrueba: this.cItemPlanDePrueba,
            ErrorPlanDePrueba: {},
            ErrorItemPlanDePrueba: {},
            Requerimientos: [],
            ListaProyectos: [],
            ListaPlanesPrueba: [],
            ListaProducto: [],
            ListaCasosPruebaVersion: [],
            ProductoReferencia:{
                PKID: 0,
                Nombre: '',
                VersionProducto : []
            },
            VersionProductoReferencia:{
                PKID: 0,
                Version: ''
            },
            ProyectoReferencia:{
                PKID: 0,
                Nombre: ''
            },
            PlanPruebaReferencia:{
                PKID: 0,
                Nombre: ''
            },
            ListaCasoPruebaRegresion: [],
            Request: false
        }
    }
    
    componentDidMount(){
        if(getPlanPrueba()){
            this.ConsultarPlanPrueba();
        }else{
            alert('Error al obtener el plan de pruebas')
        }
    }

    ConsultarBackLog(){
        ProyectoService.ConsultarEnPrueba(JSON.parse(getPlanPrueba() || '{}').Proyecto)
        .then( (response: any) => {
            if(response.success){
                let jLista: Array<object> = []
                Object.keys(JSON.parse(response.data)[0].ItemBackLog).forEach(function(key){
                    jLista.push({
                        code: JSON.parse(response.data)[0].ItemBackLog[key].PKID,
                        name: JSON.parse(response.data)[0].ItemBackLog[key].Requisito
                    })
                });
                this.setState({
                    Requerimientos: jLista,
                    PlanDePruebas: {
                        ...this.state.PlanDePruebas,
                        Proyecto: JSON.parse(response.data)[0]
                    }
                })
                setTimeout(this.ActualizarDescripcion, 1000);
            }else{
                alert(response.data)
            }
        })
        .catch((Error) => {
            alert(Error)
        })
    }

    ActualizarDescripcion = () => {
        this.setState({
            PlanDePruebas:{
                ...this.state.PlanDePruebas,
                Descripcion: this.state.PlanDePruebas.Descripcion + " "
            }
        }) 
    }

    ListarProyectos(){
        ProyectoService.Listar()
        .then( (response: any) => {
            if(response.success){
                let jLista: Array<object> = []
                Object.keys(JSON.parse(response.data)).forEach(function(key){
                    jLista.push({
                        code:JSON.parse(response.data)[key].PKID,
                        name:JSON.parse(response.data)[key].Nombre
                    })
                })
                this.setState({
                    ListaProyectos: jLista
                })
            }else{
                alert(response.data)
            }
        })
        .catch( (Error) => {
            alert(Error)
        })
    }

    ListarPlanesDePruebas(){
        PlanPruebaService.Listar()
        .then( (response: any) => {
            if(response.success){
                let jLista: Array<object> = []
                Object.keys(JSON.parse(response.data)).forEach(function(key){
                    jLista.push({
                        code: JSON.parse(response.data)[key].PKID,
                        name: JSON.parse(response.data)[key].Nombre
                    })
                })
                this.setState({
                    ListaPlanesPrueba: jLista
                })
            }else{
                alert(response.data)
            }
        })
        .catch( (Error) => {
            alert(Error)
        })
    }

    ListarProducto(){
        VersionProductoService.Listar()
        .then( (response : any) => {
            if (response.success) {
                var aProducto : Array<object> = []
                let lProducto : Array<{PKID: number, Nombre: string}> = JSON.parse(response.data)
                Object.keys(lProducto).forEach(function(k1: any){
                    let oProducto: any = lProducto[k1]
                    let aVersionProducto: Array<object> = []
                    if(oProducto.VersionProducto){
                        Object.keys(oProducto.VersionProducto).forEach(function( k2 ){
                            aVersionProducto.push({
                                code : oProducto.VersionProducto[k2].PKID,
                                name : oProducto.VersionProducto[k2].Version,
                            })
                        })
                    }
                    aProducto.push({
                        code : lProducto[k1].PKID,
                        name : lProducto[k1].Nombre,
                        VersionProducto : aVersionProducto
                    })
                })
                this.setState({ListaProducto: aProducto})
            }else{
                alert(response.data)
            }
        })
        .catch( (error) => {
            alert(error)
        })
    }

    ListarCasosDePruebaPorProyecto(){
        CasoPruebaService.ListarVersion(this.state.PlanDePruebas)
        .then( (response: any) => {
            if(response.success){
                this.setState({
                    ListaCasosPruebaVersion : JSON.parse(response.data)
                })
            }else{
                alert(response.data)
            }
        })
        .catch( (Error) => {
            alert(Error)
        })
    }

    ListarItemPlanPrueba = (Estado: string) => {
        ItemPlanPruebaService.ListarPaginacion(
            {
                'Pendiente': this.state.Pendiente.Paginacion,
                'EnCurso' : this.state.EnCurso.Paginacion,
                'Hecho' : this.state.Hecho.Paginacion
            }[Estado]
        )
        .then((response: any) => {
            if(response.success){
                let jParse = JSON.parse(response.data)
                let rItemPlanDePruebas: Array<IItemPlanDePrueba | any> = jParse.ItemPlanDePruebas ? jParse.ItemPlanDePruebas : []
                let rPaginacion: any = jParse.Paginacion
                switch(Estado){
                    case 'Pendiente': 
                        this.setState({Pendiente: {Lista: this.state.Pendiente.Lista.concat(rItemPlanDePruebas),Paginacion: rPaginacion,Loading: false}})
                        break
                    case 'EnCurso' : 
                        this.setState({EnCurso: {Lista: this.state.EnCurso.Lista.concat(rItemPlanDePruebas),Paginacion: rPaginacion,Loading: false}})
                        break 
                    case 'Hecho' : 
                        this.setState({Hecho: {Lista: this.state.Hecho.Lista.concat(rItemPlanDePruebas),Paginacion: rPaginacion,Loading: false}})                
                        break
                    default:
                        break
                }
            }else{
                alert(response.data)
            }
        })
    }

    /*
    handleOnChangePlanPrueba=(event)=>{
        this.setState({
            ...this.state,
            clickCrear:true,
            PlanDePruebas:{
                ...this.state.PlanDePruebas,
                [event.target.name]: event.target.value
            }
        })
    }
    */

    HandleChangePlanDePruebas = (Event: any) => {
        this.setState({
            PlanDePruebas:{
                ...this.state.PlanDePruebas,
                [Event.target.name]: Event.target.value
            }
        })
    }

    
    ActualizarPlanDePrueba = (Event: any) => {
        Event.preventDefault()
        const result = PlanPruebaService.ValidarSchema(this.state.PlanDePruebas)
        if(Object.keys(result).length){
            return this.setState({ErrorPlanDePrueba: result})
        }else{
            this.setState({
                Request : true
            }, () => this.CrearActualizarPlanPrueba())
        }
    }

    CrearActualizarPlanPrueba(){
        PlanPruebaService.CrearActualizar(this.state.PlanDePruebas)
        .then( (response: any) => {
            if(response.success){
                this.setState({
                    Pendiente: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 1}},
                    EnCurso: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 2}},
                    Hecho: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 3}},
                    ItemPlanDePrueba : this.cItemPlanDePrueba,
                    ErrorPlanDePrueba : {}
                },() => {
                    (document.getElementById('PanelSprint') as HTMLInputElement).classList.remove('active');
                    (document.getElementById('PopupSprint') as HTMLInputElement).classList.remove('active');
                    this.ConsultarPlanPrueba()
                })
            }else{
                alert(response.data)
            }
        })
        .catch( (error) => {
            alert(error)
        })
        .finally(() => {
            this.setState({Request : false})
        })
    }

    ConsultarPlanPrueba(){
        PlanPruebaService.Consultar(this.state.PlanDePruebas)
        .then( (response: any) => {
            if(response.success){
                this.setState({
                    PlanDePruebas : JSON.parse(response.data)
                },() => {
                    this.ListarItemPlanPrueba('Pendiente')
                    this.ListarItemPlanPrueba('EnCurso')
                    this.ListarItemPlanPrueba('Hecho')
                    this.ConsultarBackLog()
                    this.ListarProyectos()
                    this.ListarPlanesDePruebas()
                    this.ListarProducto()
                    this.ListarCasosDePruebaPorProyecto()
                })
            }else{
                alert(response.data)
            }
        })
        .catch( (Error) => {
            alert(Error)
        })
    }

    Paginacion = (Estado : string) => {
        if(
            (
                {
                    'Pendiente': ((this.state.Pendiente.Paginacion.NumeroPagina < this.state.Pendiente.Paginacion.NumeroPaginas) && !this.state.Pendiente.Loading),
                    'EnCurso' : ((this.state.EnCurso.Paginacion.NumeroPagina < this.state.EnCurso.Paginacion.NumeroPaginas) && !this.state.EnCurso.Loading),
                    'Hecho' : ((this.state.Hecho.Paginacion.NumeroPagina < this.state.Hecho.Paginacion.NumeroPaginas) && !this.state.Hecho.Loading)
                }[Estado]
            )){
                switch(Estado){
                    case 'Pendiente':
                        this.setState({
                            Pendiente: {
                                ...this.state.Pendiente,
                                Paginacion:{
                                    ...this.state.Pendiente.Paginacion,
                                    NumeroPagina: this.state.Pendiente.Paginacion.NumeroPagina + 1},
                                Loading: true
                            }
                        },() => this.ListarItemPlanPrueba('Pendiente'))
                        break
                    case 'EnCurso' : 
                        this.setState({
                            EnCurso: {
                                ...this.state.EnCurso
                                ,Paginacion:{
                                    ...this.state.EnCurso.Paginacion,
                                    NumeroPagina: this.state.EnCurso.Paginacion.NumeroPagina + 1},
                                Loading: true
                            }
                        },() => this.ListarItemPlanPrueba('EnCurso'))
                        break
                    case 'Hecho' : 
                        this.setState({
                            Hecho: {
                                ...this.state.Hecho,
                                Paginacion:{
                                    ...this.state.Hecho.Paginacion,
                                    NumeroPagina: this.state.Hecho.Paginacion.NumeroPagina + 1},
                                Loading: true
                            }
                        },() => this.ListarItemPlanPrueba('Hecho'))
                        break
                    default :
                        break
                }
        }
    }

    NuevoItemPlanDePruebasRegresion = (Event: any) => {
        this.setState({
            ModalRegresion : true,
            ItemPlanDePrueba:{
                ...this.state.ItemPlanDePrueba,
                Nombre:'Item plan de pruebas de regresión',
                EsDeRegresion : true
            },
            ProductoReferencia:{
                PKID: 0,
                Nombre: '',
                VersionProducto : []
            },
            VersionProductoReferencia:{
                PKID: 0,
                Version: ''
            },
            ProyectoReferencia:{
                PKID: 0,
                Nombre: ''
            },
            PlanPruebaReferencia:{
                PKID: 0,
                Nombre: ''
            },
            ListaCasoPruebaRegresion:[]
        })
    }

    OnResponse = (Event: any) => {
        if(Event){
            setItemPlanPrueba(JSON.stringify(Event))
            window.location.href = 'PlanDePrueba/ItemPlanDePrueba'
        }else{
            this.setState({ Modal : true })
        }
    }

    MoverEstado = ( EstadoI : string, EstadoF : string, PKID : string) => {
        if(EstadoI !== EstadoF){
            let Obj = {'Pendiente': this.state.Pendiente.Lista,'EnCurso' : this.state.EnCurso.Lista,'Hecho' : this.state.Hecho.Lista}[EstadoI] || []
            switch(EstadoF){
                case 'Pendiente' :
                    this.setState({
                        ItemPlanDePrueba : ItemPlanPruebaService.ObtenerObjetoEstado(Obj,PKID,1)
                    },() => this.CrearActualizarItemPlanPrueba())
                    break
                case 'EnCurso' :
                    this.setState({
                        ItemPlanDePrueba: ItemPlanPruebaService.ObtenerObjetoEstado(Obj,PKID,2)
                    },() => this.CrearActualizarItemPlanPrueba())
                    break
                case 'Hecho' :
                    this.setState({
                        ItemPlanDePrueba: ItemPlanPruebaService.ObtenerObjetoEstado(Obj,PKID,3)
                    },() => this.CrearActualizarItemPlanPrueba())
                    break
                default:
                    break
            }
        }
    }

    CrearActualizarItemPlanPrueba(){
        ItemPlanPruebaService.CrearActualizar(this.state.ItemPlanDePrueba)
        .then( (response: any) => {
            if(response.success){
                this.setState({
                    Pendiente: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 1}},
                    EnCurso: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 2}},
                    Hecho: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 3}},
                    Modal : false,
                    ModalRegresion : false,
                    ErrorItemPlanDePrueba : {}
                },() => {
                    this.ConsultarPlanPrueba()
                })
            }else{
                alert(response.data)
            }
        })
        .catch( (error) => {
            alert(error)
        })
        .finally( () => {
            this.setState({ Request : false})
        })
    }

    HandleChangeItemPlanPrueba = (Event: any) => {
        console.log(Event.target.name)
        console.log(Event.target.value)
        switch(Event.target.name){
            case 'Requerimiento':
                let obj = this.state.PlanDePruebas.Proyecto.ItemBackLog
                let req: any = null
                Object.keys(obj).forEach(function(key){
                    if(obj[key].PKID === Event.value.code){
                        req = obj[key]
                    }
                })
                if(this.state.ItemPlanDePrueba.Nombre==='' && req!==null){
                    this.setState({
                        ItemPlanDePrueba:{
                            ...this.state.ItemPlanDePrueba,
                            Nombre: "Prueba - "+req.Requisito,
                            Requerimiento: req
                        }
                    })
                }else{
                    this.setState({
                        ItemPlanDePrueba:{
                            ...this.state.ItemPlanDePrueba,
                            Requerimiento: req
                        }
                    })
                }
                break
            case 'Producto':
                this.setState({
                    ProductoReferencia:{
                        PKID: Event.value.code,
                        Nombre: Event.value.name,
                        VersionProducto : Event.value.VersionProducto
                    }
                },() => {
                    //this.ListarCasoPruebaFiltro()
                })
                break
            case 'VersionProducto':
                this.setState({
                    VersionProductoReferencia:{
                        PKID: Event.value.code,
                        Version: Event.value.name
                    }
                },() => {
                    //this.ListarCasoPruebaFiltro()
                })
                break
            case 'Proyecto':
                this.setState({
                    ProyectoReferencia:{
                        PKID: Event.value.code,
                        Nombre: Event.value.name
                    }
                },()=>this.ListarCasoPruebaFiltro())
                break
            case 'PlanPrueba':
                this.setState({
                    PlanPruebaReferencia:{
                        PKID: Event.value.code,
                        Nombre: Event.value.name
                    }
                },()=>this.ListarCasoPruebaFiltro())
                break
            default : 
                this.setState({
                    ItemPlanDePrueba:{
                        ...this.state.ItemPlanDePrueba,
                        [Event.target.name]: Event.target.value
                    }
                })
                break
        }
    }

    ListarCasoPruebaFiltro(){
        let Filtro={
            IDProyecto:this.state.ProyectoReferencia.PKID,
            IDPlanPrueba:this.state.PlanPruebaReferencia.PKID
        }
        CasoPruebaService.ListarFiltro(JSON.stringify(Filtro))
        .then( (response: any) => {
            if(response.success){
                let lista = []
                Object.keys(JSON.parse(response.data)).forEach(function(key){
                    lista.push(JSON.parse(response.data)[key])
                })
                this.setState({
                    ListaCasoPruebaRegresion: JSON.parse(response.data),
                })
            }else{
                alert('No se encontraron casos de prueba con estas caracteristicas')
            }
        })
        .catch( (error) => {
            alert(error)
        })
    }

    HandleSubmit = (Event: any) => {
        Event.preventDefault()
        const result = ItemPlanPruebaService.ValidarSchema(this.state.ItemPlanDePrueba)
        if(Object.keys(result).length){
            return this.setState({ ErrorItemPlanDePrueba : result });
        }else{
            this.setState({
                Request : true
            }, () => this.CrearActualizarItemPlanPrueba())
        }
    }

    render(){
        return(
            <div className="container">
                <PlanDePruebasHeader/>
                <PanelPlanDePruebas
                    FormValues= {this.state.PlanDePruebas}
                    HandleChange= {this.HandleChangePlanDePruebas}
                    ActualizarPlanDePrueba= {this.ActualizarPlanDePrueba}
                    Errores= {this.state.ErrorPlanDePrueba}
                    Request= {this.state.Request}/>
                <PlanDePruebasBody
                    Paginacion= {this.Paginacion}
                    Pendiente= {this.state.Pendiente}
                    EnCurso= {this.state.EnCurso}
                    Hecho= {this.state.Hecho}   
                    NuevoItemPlanDePruebasRegresion= {this.NuevoItemPlanDePruebasRegresion}
                    OnResponse= {this.OnResponse}
                    ListaCasosPruebaVersion= {this.state.ListaCasosPruebaVersion}
                    MoverEstado= {this.MoverEstado}/>
                <form autoComplete="Off" onSubmit={this.HandleSubmit}>
                    <ItemPlanDePruebasModal
                        Modal = {this.state.Modal}
                        HandleChange = {this.HandleChangeItemPlanPrueba}
                        HideModal = {() => {
                            this.setState({ 
                                Modal : false,
                                ItemPlanDePrueba : this.cItemPlanDePrueba,
                                ErrorItemPlanDePrueba : {}
                            })
                        }}
                        FormValues = {this.state.ItemPlanDePrueba}
                        ListaRequerimientos = {this.state.Requerimientos}
                        Errores = {this.state.ErrorItemPlanDePrueba}
                        Request = {this.state.Request}/>
                </form>
                <form autoComplete="Off" onSubmit={this.HandleSubmit}>
                    <ItemPlanDePruebasRegresionModal
                        Modal = {this.state.ModalRegresion}
                        HideModal = {() => {
                            this.setState({ 
                                ModalRegresion : false,
                                ItemPlanDePrueba : this.cItemPlanDePrueba,
                                ErrorItemPlanDePrueba : {}
                            })
                        }}
                        ItemPlanDePrueba = {this.state.ItemPlanDePrueba}
                        ProyectoReferencia = {this.state.ProyectoReferencia}
                        ProductoReferencia = {this.state.ProductoReferencia}
                        VersionProductoReferencia = {this.state.VersionProductoReferencia}
                        HandleChange = {this.HandleChangeItemPlanPrueba}
                        PlanPruebaReferencia = {this.state.PlanPruebaReferencia}
                        ItemsSeleccionados={this.state.ItemPlanDePrueba.ListaCasoPrueba}
                        SeleccionarCasoPrueba={(Event: any) => {
                            this.setState({
                                ItemPlanDePrueba: {
                                    ...this.state.ItemPlanDePrueba,
                                    ListaCasoPrueba: Event
                                }
                            })
                        }}
                        ListaProducto = {this.state.ListaProducto}
                        ListaProyecto = {this.state.ListaProyectos}
                        ListaPlanesPrueba = {this.state.ListaPlanesPrueba}
                        ListaCasoPruebaRegresion={this.state.ListaCasoPruebaRegresion}                    
                        Errores = {this.state.ErrorItemPlanDePrueba}
                        Request = {this.state.Request}/>
                </form>
            </div>
        )
    }
}