import React from 'react'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'

interface IProps{
    Modal: boolean
    HideModal: any
    HandleChange: any
    Producto: any
    FormValues: any
    ErrorVersionProducto: any
}

export default function ProductoVersionModal(Props: IProps){

    const RenderFooter = () => (
        <div className="p-d-flex p-ml-auto">
            <Button 
                type="submit"
                label = "Nueva Version Producto" 
                autoFocus
                className = "p-ml-auto"/>
        </div>
    )

    return(
        <Dialog
            header='Versión del producto'
            visible={Props.Modal}
            style={{ width: '110vh' }}
            footer={RenderFooter()} 
            onHide={Props.HideModal}>
                <div className="modal-body p-mt-3">
                    <div className="p-grid nested-grid">
                        <div className="p-col-12">
                            <div className="p-col">
                                <div className="p-grid p-fluid ">
                                    <div className="p-col-12">
                                        <label 
                                            className="p-d-block"
                                            style={{
                                                color : '#4D80E4',
                                                fontFamily : 'Sarala',
                                                fontSize : '20px'}}>
                                        Producto</label>
                                        <InputText 
                                            value={Props.Producto.Nombre}
                                            onChange={Props.HandleChange}
                                            disabled
                                            name="Nombre"
                                            type="text"/>
                                        {Props.ErrorVersionProducto.Producto && <small className="p-invalid p-d-block">{Props.ErrorVersionProducto.Producto}</small>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12 p-lg-6">
                            <div className="p-col">
                                <div className="p-grid p-fluid ">
                                    <div className="p-col-12">
                                        <label 
                                            className="p-d-block"
                                            style={{
                                                color: '#4D80E4',
                                                fontFamily: 'Sarala',
                                                fontSize: '20px'}}
                                        >Version</label>
                                        <InputText 
                                            value={Props.FormValues.Version}
                                            onChange={Props.HandleChange}
                                            name="Version"
                                            type="text"/>
                                        {Props.ErrorVersionProducto.Version && <small className="p-invalid p-d-block">{Props.ErrorVersionProducto.Version}</small>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12 p-lg-6">
                            <div className="p-col">
                                <div className="p-grid p-fluid ">
                                    <div className="p-col-12">
                                        <label 
                                            className="p-d-block"
                                            style={{
                                                color: '#4D80E4',
                                                fontFamily: 'Sarala',
                                                fontSize: '20px'}}
                                        >Orden</label>
                                        <InputText 
                                            value = {Props.FormValues.Orden}
                                            onChange = {Props.HandleChange}
                                            name = "Orden"
                                            type = "number"
                                            min = "1"/>
                                        {Props.ErrorVersionProducto.Orden && <small className="p-invalid p-d-block">{Props.ErrorVersionProducto.Orden}</small>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </Dialog>
    )
}
                    
