import { HTTP } from "../Http/Http"

export const VersionProductoRepository = {
    ListarPaginacion: async(Paginacion: any) =>{
        return await HTTP.POST('VersionProducto/ListarPaginacionProducto',Paginacion,{})
    },
    Listar: async () => {
        return await HTTP.POST('VersionProducto/Listar','',{})
    },
    CrearActualizar: async (Producto: any) => {
        return await HTTP.POST('VersionProducto/CrearActualizar', Producto, {})
    }
}