import { CalidadRepository } from '../../Infrastructure/Repositories/CalidadRepository'

export const CalidadService = {
    ListarModulo: () => {
        return CalidadRepository.ListarModulo()
    },
    ListarSeveridad: () => {
        return CalidadRepository.ListarSeveridad()
    },
    ListarUsuarios: () => {
        return CalidadRepository.ListarUsuarios()
    },
    ListarCasoPruebaDLL: (IDRequerimiento: number) => {
        return CalidadRepository.ListarCasoPruebaDLL(IDRequerimiento)
    }
}