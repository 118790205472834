import React from 'react'
import ReporteAsistenciaHeader from '../Components/ReporteAsistenciaHeader'
import ReporteAsistenciaBody from '../Components/ReporteAsistenciaBody'

import { IUsuario } from '../../Domain/Models/IUsuario'
import { IToken } from '../../Domain/Models/IToken'

import { UsuarioService } from '../../Domain/Services/UsuarioService'
import { EliminarTodo} from '../../Services/LocalStorage'
import { AuthHelper } from '../../Domain/Utils/AuthHelper'
import ModalRegistrarAsistencia from '../Components/ModalRegistrarAsistencia'
import ModalRegistrarSalida from '../Components/ModalRegistrarSalida'
import { IRegistrarAsistencia } from '../../Domain/Models/IRegistrarAsistencia'
import AsistenciaFiltro from '../Components/AsistenciaFiltro'
import { IFiltro } from '../../Domain/Models/IFiltro'
import { EmpleadoService } from '../../Domain/Services/EmpleadoService'
import { AsistenciaService } from '../../Domain/Services/AsistenciaService'
import { IPaginacionAsistencia } from '../../Domain/Models/IPaginacionAsistencia'
import useStore from '../../Domain/Utils/Store'


interface IProps {
    Token: IToken
}

interface IPaginado{
    NumeroPagina: number
    RegistrosPagina: number
    NumeroPaginas: number
    FechaInicio: Date
    FechaFin: Date
    IDUsuario: number
    
}

interface ListaAsistencia {
    Colaborador: String
}

interface IState {
    RegistrarAsistencia:IRegistrarAsistencia
    ModalIngreso: boolean
    ModalSalida: boolean
    Request: boolean
    Usuario: IUsuario
    Filtro: IFiltro
    Empleado: Array<object>
    ListaAsistencia: ListaAsistencia[]
    ListaAsistenciaOriginal: ListaAsistencia[]
    Paginado: IPaginado
 
}


export default class ReporteAsistencia  extends React.Component<IProps, IState> {

    cFiltro = {
        PKID:0,
        IDUsuario: parseInt(AuthHelper.GetDecoded().sub,10),
        IDTipo:6,
        FechaInicio:new Date(),
        FechaFin:new Date(),
        IDSClientes:'',
        IDSAreas:'',
        IDSProductos:'',
        IDSResponsables:'',
        IDSEstados:'',
        IDSTickets:'',
        IDSProyectos:'',
        FechaEntregaInicio:new Date("01/01/2000"),
        FechaEntregaFin:new Date("01/01/2050")
    }

    cRegistrarAsistencia = {
        IDUsuario: parseInt(AuthHelper.GetDecoded().sub,10),
        Fecha:new Date()
    } 

    cUsuario = {
        PKID: 0,
        Credencial: '',
        IsNuevo: false,
        Administrador: false,
        AdministradorArea: false,
        Empleado:{
            PKID: 0,
            Nombre: '',
            Codigo: '',
            Area:{
                PKID: 0,
                Codigo: '',
                Nombre: ''
            },
            CorreoElectronico: ''
        }
    }

    cPaginado = {
        NumeroPagina : 1,
        RegistrosPagina : 100,
        NumeroPaginas : 0,
        FechaInicio:new Date(),
        FechaFin : new Date(),
        IDUsuario: parseInt(AuthHelper.GetDecoded().sub,10),
    }


    Registros = [
        { RegistrosPagina: 100 },
        { RegistrosPagina: 200 },
        { RegistrosPagina: 300 }
    ]

    constructor(Props: any){
        super(Props);
        this.state = {
            RegistrarAsistencia : this.cRegistrarAsistencia,
            ModalIngreso: false,
            ModalSalida: false,
            Request: false,
            Usuario: this.cUsuario,
            Filtro: this.cFiltro,
            Empleado: [],
            ListaAsistencia : [],
            ListaAsistenciaOriginal : [],
            Paginado: this.cPaginado,
            
        }
    }

    componentDidMount(){
        this.ConsultarUsuario()
        this.ListarEmpleado()
        this.ListarAsistencia()
        this.ObtenerFiltro()
    }
    
    ConsultarUsuario(){

        UsuarioService.Consultar(parseInt(AuthHelper.GetDecoded().sub,10))
        .then( (response: any) => {
            if (response.success) {
                this.setState({Usuario : JSON.parse(response.data)})      
                this.setState({
                    RegistrarAsistencia:{
                        ...this.state.RegistrarAsistencia,
                        IDUsuario: JSON.parse(response.data).PKID
                    }
                })
                this.setState({
                    Filtro:{
                        ...this.state.Filtro,
                        IDUsuario: JSON.parse(response.data).PKID
                    }
                })
            }else{
                throw new Error(response.data)
            }
        })
        .catch( (Error) => {
            alert(Error)
        })
    }
    ListarEmpleado(){
        EmpleadoService.Listar()
        .then((response : any) => {
            if(response.success){
                var x = AuthHelper.GetDecoded()
                var aEmpleado: Array<object> = []
                Object.keys(JSON.parse(response.data)).forEach(function(key){
                    /*
                    if(AuthHelper.GetDecoded().Area === 'Desarrollo'){
                        if(JSON.parse(response.data)[key].Area.Nombre == 'Desarrollo'){
                            var iEmpleado = {
                                code: JSON.parse(response.data)[key].PKID,
                                name: JSON.parse(response.data)[key].Nombre,
                            }
                            aEmpleado.push(iEmpleado);
                        }
                    }else{
                        if(JSON.parse(response.data)[key].Area.Nombre == 'Implementacion'){
                            var iEmpleado = {
                                code: JSON.parse(response.data)[key].PKID,
                                name: JSON.parse(response.data)[key].Nombre,
                            }
                            aEmpleado.push(iEmpleado);
                        }
                    }
                    */
                    var iEmpleado = {
                        code: JSON.parse(response.data)[key].PKID,
                        name: JSON.parse(response.data)[key].Nombre,
                    }
                    aEmpleado.push(iEmpleado);

                });
                this.setState({Empleado : aEmpleado})
            }else{
                alert(response.data)
            }
        }).catch((error) => {
            alert(error)
        })
    }
    ListarAsistencia(){
        AsistenciaService.ListarAsistencia(this.state.Paginado)
        .then((response: any) => {
            if(response.success){
                this.setState({
                    ListaAsistencia : JSON.parse(response.data).Asistencia
                })
                this.setState({
                    ListaAsistenciaOriginal : JSON.parse(response.data).Asistencia
                })
                this.setState({
                    Paginado : {
                        ...this.state.Paginado,
                        NumeroPagina: JSON.parse(response.data).Paginacion.NumeroPagina,
                        RegistrosPagina: JSON.parse(response.data).Paginacion.RegistrosPagina,
                        NumeroPaginas: JSON.parse(response.data).Paginacion.NumeroPaginas,
                }})
            }
        })
        .catch((Error) => {
            alert(Error)
        })
    }
    ObtenerFiltro(){
        AsistenciaService.ObtenerFiltro(parseInt(AuthHelper.GetDecoded().sub,10))
        .then((response : any) => {

            if(response.success){ 
                let IDResponsables=JSON.parse(response.data).IDSResponsables
                this.setState({Filtro: JSON.parse(response.data)})   
                this.setState({Filtro: {...this.state.Filtro,IDSResponsables:IDResponsables}})
            }else{
                let IDResponsables=JSON.parse(response.data).IDSResponsables
                if(IDResponsables===null){
                    IDResponsables="-"+AuthHelper.GetDecoded().IDEmpleado
                    this.OnChangeFiltro(IDResponsables,"IDSResponsables")
                }
            }
        })
        .catch( (error) => {
            alert(error)
        })
        .finally( () => {
            this.ListarAsistencia()
        })
    }

    OcultarModalIngreso = () => {
        this.setState({
            RegistrarAsistencia: this.cRegistrarAsistencia,
            ModalIngreso: false
        })
        this.ListarAsistencia()
    }

    OcultarModalSalida = () => {
        this.setState({
            RegistrarAsistencia: this.cRegistrarAsistencia,
            ModalSalida: false
        })
        this.ListarAsistencia()
    }

    HandleChangeAsistenciaIngreso = (Event: any) => {
        this.setState({
            RegistrarAsistencia:{
                ...this.state.RegistrarAsistencia,
                [Event.target.name]: Event.target.value
            }
        })
    }

    HandleChangeAsistenciaSalida = (Event: any) => {
        this.setState({
            RegistrarAsistencia:{
                ...this.state.RegistrarAsistencia,
                [Event.target.name]: Event.target.value
            }
        })
    }

    OnSubmitRegistrarIngreso = (Event : React.ChangeEvent<HTMLFormElement>) => {
        Event.preventDefault()
        this.setState({
            Request: true
        }, () => {
            console.log(parseInt(AuthHelper.GetDecoded().sub,10))
            console.log(this.state.RegistrarAsistencia.IDUsuario)
            UsuarioService.RegistrarAsistencia(parseInt(AuthHelper.GetDecoded().sub,10))
            .then((res : any) => {
                alert(JSON.parse(res.data).Mensaje)
                this.OcultarModalIngreso()
            })
            
            .catch((error) => {
                alert(error)
            })
            .finally( () => {
                this.setState({Request: false})
            })
        })
    }

    OnSubmitRegistrarSalida = (Event : React.ChangeEvent<HTMLFormElement>) => {
        Event.preventDefault()
        this.setState({
            Request: true
        }, () => {
            UsuarioService.RegistrarSalida(this.state.RegistrarAsistencia.IDUsuario)
            .then((res : any) => {
                alert(JSON.parse(res.data).Mensaje)
                this.OcultarModalSalida()
            })
            .catch((error) => {
                alert(error)
            })
            .finally( () => {
                this.setState({Request: false})
            })
        })
    }


    //filtro
    CrearActualizarFiltro(){
        AsistenciaService.CrearActualizarFiltro(this.state.Filtro)
        .then( (response : any) => {
            if(response.success){
                this.setState({
                    Filtro: {
                        ...this.state.Filtro,
                        PKID: JSON.parse(response.data).PKID
                    },
                    //Pendiente: {...this.cPaginacion ,Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 1}},
                },() => {
                    //this.ListarAsistencia()
                })
            }else{
                alert(response.data)
            }
        }).catch( (error) => {
            alert(error)
        })
        
    }

    LimpiarFiltro = (Event : any) => {
        AsistenciaService.LimpiarFiltro(parseInt(AuthHelper.GetDecoded().sub,10))
        .then( (response : any) => {
            if(!response.success) alert(response.data)
            this.setState({
                Filtro: {...this.state.Filtro,IDSResponsables:'',IDSEstados:'',FechaInicio:new Date(),FechaFin:new Date()},                
            },() => {
                this.ObtenerFiltro()
            })
        })
        .catch( (error) => {
            alert(error)
        })
    
    }

    OnChangeFechas=(Fechas : string ,filtro:string)=>{  
        this.setState({
            Paginado: {
                ...this.state.Paginado,
                [filtro]: Fechas
            }
        })
    }
    
    ClickAplicar = (Event : any) => { 
        this.ObtenerFiltro()
    }

    OnChangeFiltro=(IDSResponsables : string ,filtro:string)=>{  
        this.setState({
            Filtro: {
                ...this.state.Filtro,
                [filtro]: IDSResponsables
            }
        },() => this.CrearActualizarFiltro())
    }

  //FinFiltro
    /*DATE TABLE */
    OnRegistroChange = (Event: any) =>{
        this.setState({
            Paginado : {
                ...this.state.Paginado,
                NumeroPagina: 1,
                RegistrosPagina: Event.value.RegistrosPagina,
        }},() =>{ this.ListarAsistencia() })
    }

    HandlePreviousLL = (Event: any) =>{
        this.setState({
            Paginado : this.cPaginado
        },() =>{ this.ListarAsistencia() })
    }

    HandlePrevious = (Event: any) =>{
        this.setState({Paginado : {
            ...this.state.Paginado,
            NumeroPagina: this.state.Paginado.NumeroPagina - 1,
        }},() =>{ this.ListarAsistencia() })
    }

    HandleFollowing = () => {
        this.setState({Paginado : {
            ...this.state.Paginado,
            NumeroPagina: this.state.Paginado.NumeroPagina + 1,
        }},() =>{ this.ListarAsistencia() })
    }

    HandleFollowingLL = () => {
        this.setState({Paginado : {
            NumeroPagina: this.state.Paginado.NumeroPaginas,
            RegistrosPagina: this.state.Paginado.RegistrosPagina,
            NumeroPaginas: this.state.Paginado.NumeroPaginas,
            FechaInicio : this.state.Paginado.FechaInicio,
            FechaFin : this.state.Paginado.FechaFin,
            IDUsuario : this.state.Paginado.IDUsuario
        }},() =>{ this.ListarAsistencia() })
    }
    HandleChangeBuscador = (e: any) => {
     
        const ListaAsistenciaOriginal = this.state.ListaAsistenciaOriginal
        const ListaAsistenciaFiltoBefore = this.state.ListaAsistenciaOriginal

        if (e.target.value.length===0) {
            this.setState({
                ListaAsistencia : ListaAsistenciaOriginal
            })

        } else {

                const listaFiltrada = ListaAsistenciaFiltoBefore.filter((lista) => lista.Colaborador.toLowerCase().includes(e.target.value.toLowerCase()))

                this.setState({
                    ListaAsistencia : listaFiltrada
                })
        }
        
    }

    render(){
        return(
            <div>
                <ReporteAsistenciaHeader
                    OnChangeBuscador={this.HandleChangeBuscador}
                    />
                <ReporteAsistenciaBody
                Paginado={this.state.Paginado}
                Registros= {this.Registros}
                ListaAsistencia={this.state.ListaAsistencia}
                OnRegistroChange = {this.OnRegistroChange}
                HandlePreviousLL = {this.HandlePreviousLL}
                HandlePrevious = {this.HandlePrevious}
                HandleFollowing = {this.HandleFollowing}
                HandleFollowingLL = {this.HandleFollowingLL}/>
                <form autoComplete="off" onSubmit={this.OnSubmitRegistrarIngreso}>
                    <ModalRegistrarAsistencia
                        ModalIngreso = {this.state.ModalIngreso}
                        HideModal = {this.OcultarModalIngreso}
                        OnChangeRegistrarAsistencia = {this.HandleChangeAsistenciaIngreso}
                        //Fecha = {this.state.RegistrarAsistencia.Fecha}
                        Fecha = {new Date()}
                        Request = {this.state.Request}/>
                </form>
                <form autoComplete="off" onSubmit={this.OnSubmitRegistrarSalida}>
                    <ModalRegistrarSalida
                        ModalSalida = {this.state.ModalSalida}
                        HideModal = {this.OcultarModalSalida}
                        OnChangeRegistrarSalida = {this.HandleChangeAsistenciaSalida}
                        Fecha = {new Date()}
                        Request = {this.state.Request}/>
                </form>
                <AsistenciaFiltro 
                    Responsables = {this.state.Empleado}   
                    Paginado = {this.state.Paginado}                   
                    ClickResetear = {this.LimpiarFiltro}
                    ClickAplicar = {this.ClickAplicar}
                    onChangeFiltro = {this.OnChangeFiltro}
                    OnChangeFechas = {this.OnChangeFechas}
                    Filtro = {this.state.Filtro}/>

            </div>
        )
    }
}