import { HTTP } from "../Http/Http"

export const CalidadRepository = {
    ListarModulo: async () => {
        return await HTTP.POST('Calidad/ListarModulo','',{})
    },
    ListarSeveridad: async () => {
        return await HTTP.POST('Calidad/Severidad_ObtenerTodos','',{})
    },
    ListarUsuarios: async () => {
        return await HTTP.POST('Calidad/ListarUsuarios','',{})
    },
    ListarCasoPruebaDLL: async (IDRequerimiento: number) => {
        return await HTTP.POST('Calidad/ListarCasoPruebaXVersionProductoDDL?IDItemBackLogVersion='+IDRequerimiento,'',{})
    }
}