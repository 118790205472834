import React, {Component} from 'react'
import HeaderCasoPruebaVersion from '../Components/HeaderCasoPruebaVersion'
import CuerpoCasoPruebaVersion from '../Components/CuerpoCasoPruebaVersion'
import ModalIncidencia from '../../Infrastructure/Components/IncidenteModal'
import { Toast } from 'primereact/toast'
import { getDecode, getCasoPruebaVersion } from '../../Services/LocalStorage'
import { initAxiosInterceptors, url } from '../../Services/Axios'
import axios from 'axios'
import { CategorizacionService } from '../../Domain/Services/CategorizacionService'
import CasoPruebaModalPaso from '../../Infrastructure/Components/CasoPruebaModalPaso'
import { IncidenteService} from '../../Domain/Services/IncidenteService'

initAxiosInterceptors();

const cPaso = {
    PKID : 0,
    NumeroPaso : 1,
    Accion : '',
    ResultadoEsperado : '',
    Correcto : 1,
    FechaCreacion : new Date()
}

const cIncidente = {
    PKID: 0,
    Incidencia: '',
    Detalle: '',
    IDCasoPruebaVersion: getCasoPruebaVersion() ? JSON.parse(getCasoPruebaVersion()).PKID : 0,
    Empleado: {
        PKID: getDecode() ?  getDecode().sub : 0,
        Nombre: getDecode() ?  getDecode().Empleado : ''
    },
    FechaCreacion: new Date(),
    TipoError: {
        PKID: 0,
        Nombre: ''
    },
    Prioridad: 0,
    Severidad: {
        PKID: 0,
        Nombre: ''
    },
    Estado: {
        PKID: 1,
        Descripcion: 'Pendiente'
    },
    Responsable: {
        PKID: getDecode() ?  getDecode().sub : 0,
        Descripcion: getDecode() ?  getDecode().Empleado : ''
    },
    Producto : {
        PKID : 0,
        Nombre : '',
        VersionProducto : []
    },
    VersionProducto : {
        PKID : 0,
        Version : ''
    },
    Pasos: getCasoPruebaVersion() ? JSON.parse(getCasoPruebaVersion()).Pasos : [],
    Evidencias: [],
    Categorizacion:{
        PKID: 0,
        Descripcion: ''
    },
    CheckCorreo: false
}

const ValidarIncidencia = values => {
    const errores = {}
    if(!values.Incidencia) errores.Incidencia = 'Incidencia es requerido'
    if(!values.Detalle) errores.Detalle = 'Detalle es requerido'
    if(!values.Empleado.PKID) errores.Empleado = 'Empleado es requerido'
    if(!values.TipoError.PKID) errores.TipoError = 'Tipo de error es requerido'
    if(!values.Prioridad<0) errores.Prioridad = 'Prioridad es requerido'
    if(!values.Severidad.PKID) errores.Severidad = 'Severidad es requerido'
    if(!values.Producto.PKID) errores.Producto = 'Producto es requerido'
    if(!values.VersionProducto.PKID) errores.VersionProducto = 'Version producto es requerido'
    return errores
}

class CasoPruebaVersion extends Component{

    nuevoIncidente=false
    abrio=false
    constructor(){
        super()
        this.state={
            mostrarModalIncidente:false,
            ModalIncidente:false,
            ModalPaso: false,
            correoCalidad:'',
            contrasegnaCalidad:'',
            CasoPruebaVersion:{
                PKID:0,
                IDCasoPrueba:0,
                IDItemPlanPrueba:0,
                Nombre:'',
                Descripcion:'',
                Modulo:'',
                Version:'0',
                FechaCreacion:new Date(),
                Empleado:{
                    PKID:getDecode().sub,
                    Nombre:getDecode().Empleado
                },
                TipoPrueba:{
                    PKID:0,
                    Nombre:''
                },
                Producto:{
                    PKID:0,
                    Nombre:''
                },
                Pasos:[],
                Incidentes:[]
            },
            Paso:{
                PKID:0,
                NumeroPaso:1,
                Accion:"",
                ResultadoEsperado:"",
                Correcto:1
            },
            Incidente: cIncidente,
            ListaPasoDDL:[],
            ListaIncidenteDDL:[],
            ListaEstado:[],
            ListaEmpleado:[],
            ListaSeveridad:[],
            ListaTipoError:[],
            ListaUsuarios:[],
            ListaProducto : [],
            ErrorIncidencia : {},
            Request : false,           
            ErroresPaso : {},
            Paso: cPaso
        }
    }

    componentDidMount(){
        if(getCasoPruebaVersion()){
            this.actualizar()
        }else{
            this.toast.show({
                severity:'error', 
                summary: 'Caso de prueba versión', 
                detail: 'No se tienen asignado ningun caso de prueba', 
                life: 1500})
        }
        
    }

    async actualizar(){
        axios.post(url()+'CasoPrueba/ConsultarVersion',JSON.parse(getCasoPruebaVersion()))
        .then( (response) => {
            if(response.data.success){
                this.setState({ CasoPruebaVersion: JSON.parse(response.data.data)},
                    ()=>{
                    this.getListaIncidentes()
                    this.getListEstado()
                    this.getListEmpleado()
                    this.getListSeveridad()
                    this.getListTipoError()
                    this.getListUsuario()
                    this.getListVersionProducto()
                    this.ListarCategorizacion()
                })
            }else{
                throw new Error(response.data.data)
            }
        })
        .catch( (error) => {
            this.toast.show({
                severity: 'error', 
                summary: 'Consultar caso de prueba versión', 
                detail: error.message,
                life: 1500})
        })
    }

    ListarCategorizacion(){
        CategorizacionService.Listar()
        .then( (response) => {
            if (response.success) {
                var aCategorizacion = []
                Object.keys(JSON.parse(response.data)).forEach(function(key){
                    var iCategorizacion = {
                        code: JSON.parse(response.data)[key].PKID,
                        name: JSON.parse(response.data)[key].Descripcion,
                    }
                    aCategorizacion.push(iCategorizacion)
                })
                this.setState({Categorizacion: aCategorizacion})
            }else{
                alert(response.data)
            }
        })
        .catch( (error) => {
            alert(error)
        })
    }

    actualizarDescripcion2=()=>{
        this.setState({
            ...this.state,
            CasoPruebaVersion:{
                ...this.state.CasoPruebaVersion,
                Descripcion:this.state.CasoPruebaVersion.Descripcion+" "
            }
        }) 
    }

    async getListVersionProducto(){
        axios.post(url()+'VersionProducto/Listar')
        .then( (response) => {
            if (response.data.success) {
                let aProducto = []
                let lProducto = JSON.parse(response.data.data)
                Object.keys(lProducto).forEach(function( k1 ){
                    let oProducto = lProducto[k1]
                    let aVersionProducto = []
                    if(oProducto.VersionProducto){
                        Object.keys(oProducto.VersionProducto).forEach(function( k2 ){
                            aVersionProducto.push({
                                code : oProducto.VersionProducto[k2].PKID,
                                name : oProducto.VersionProducto[k2].Version,
                            })
                        })
                    }
                    aProducto.push({
                        code : lProducto[k1].PKID,
                        name : lProducto[k1].Nombre,
                        VersionProducto : aVersionProducto
                    })
                })
                this.setState({
                    ListaProducto : aProducto
                })
            }else{
                alert(response.data.data)
            }
        })
        .catch( (error) => {
            alert(error)
        })
    }

    async getListaIncidentes(){
        axios.post(url()+'Incidencia/Listar?IDCasoPruebaVersion='+this.state.CasoPruebaVersion.PKID)
        .then( (response) => {
            if (response.data.success) {
                let lista=[], lista2=[]
                console.log(JSON.parse(response.data.data))
                Object.keys(JSON.parse(response.data.data)).forEach(function(key){
                    lista.push(JSON.parse(response.data.data)[key])
                    lista2.push({
                        code:JSON.parse(response.data.data)[key].PKID,
                        name:JSON.parse(response.data.data)[key].Nombre
                    })
    
                })
                this.setState({
                    CasoPruebaVersion:{
                        ...this.state.CasoPruebaVersion,
                        Incidentes:lista
                    },
                    ListaIncidenteDDL:lista2
                })
            }
        })
        .catch( (error) => {
            this.toast.show({
                severity:'error', 
                summary: 'Lista de incidentes', 
                detail: error.message, 
                life: 1500})
        })
        .finally( () => {
            setTimeout(this.actualizarDescripcion2(), 10000)
        })
    }

    async getListEstado(){
        const { data } = await axios.post(url()+'Ticket/Estado_ObtenerTodos');
        if (data.success) {
            var aEstado = []
            Object.keys(JSON.parse(data.data)).forEach(function(key){
                var iEstado = {
                    code: JSON.parse(data.data)[key].PKID,
                    name: JSON.parse(data.data)[key].Descripcion,
                }
                aEstado.push(iEstado);
            });
            this.setState({
                ...this.state,
                ListaEstado : aEstado})
        }else{
            this.toast.show({severity:'info', summary: 'No hay Estados', detail:data.data, life: 1500});
        }
    }

    async getListEmpleado(){
        axios.post(url()+'Empleado/ListarEmpleados')
        .then( (response) => {
            if (response.data.success) {
                let lista = [], listaAPI=JSON.parse(response.data.data)
                Object.keys(listaAPI).forEach(function(key){
                    if(listaAPI[key].Area.Codigo === '06')
                        lista.push({
                            code: listaAPI[key].PKID,
                            name: listaAPI[key].Nombre,
                        })
                })
                this.setState({ListaEmpleado : lista})
            }else{
                throw new Error(response.data.data)
            }
        })
        .catch( (error) => {
            this.toast.show({
                severity:'error', 
                summary: 'Lista de empleados', 
                detail: error.message, 
                life: 1500})
        })
    }

    async getListSeveridad(){
        const { data } = await axios.post(url()+'Calidad/Severidad_ObtenerTodos');
        if (data.success) {
            var aEstado = []
            Object.keys(JSON.parse(data.data)).forEach(function(key){
                var iEstado = {
                    code: JSON.parse(data.data)[key].PKID,
                    name: JSON.parse(data.data)[key].Nombre,
                }
                aEstado.push(iEstado);
            });
            this.setState({
                ...this.state,
                ListaSeveridad : aEstado})
        }else{
            this.toast.show({severity:'info', summary: 'No hay Severidades', detail:data.data, life: 1500});
        }
    }
    async getListTipoError(){
        axios.post(url()+'CasoPrueba/ListarTipoPrueba')
        .then( (response) => {
            if (response.data.success) {
                let lista=[]
                Object.keys(JSON.parse(response.data.data)).forEach(function(key){
                    lista.push({
                        code:JSON.parse(response.data.data)[key].PKID,
                        name:JSON.parse(response.data.data)[key].Nombre
                    })
                })
                this.setState({
                    ListaTipoError:lista
                })
            }else{
                throw new Error(response.data.data)
            }
        })
        .catch( (error) => {
            this.toast.show({
                severity:'error', 
                summary: 'Lista de tipos de caso de pruebas', 
                detail: error.message, 
                life: 1500})
        })
    }

    async getListUsuario(){
        const { data } = await axios.post(url()+'Calidad/ListarUsuarios');
        if (data.success) {
            this.setState({
                ...this.state,
                ListaUsuarios : JSON.parse(data.data)
            })
        }else{
            this.toast.show({severity:'info', summary: 'No hay Severidades', detail:data.data, life: 1500});
        }
    }

    async crearActualizarPaso(){
        const { data } = await axios.post(url()+'Calidad/CrearActualizarPaso',this.state.Paso);
        if (data.success) {
            //this.toast.show({severity:'info', summary: 'Guardado correctamente', detail:'', life: 3000})
        }else{
            this.toast.show({severity:'error', summary: 'Error al Guardar', detail:data.data, life: 1500})
        }
        this.actualizar()
    }

    async actualizarOrdenPasos(lista){
        const { data } = await axios.post(url()+'Calidad/ActualizarOrdenPasos?ListaPasos='+JSON.stringify(lista));
        if (data.success) {
            //this.toast.show({severity:'info', summary: 'Guardado correctamente', detail:'', life: 3000})
        }else{
            this.toast.show({severity:'error', summary: 'Error al Actualizar', detail:data.data, life: 1500})
        }
        this.actualizar()
    }

    async CrearActualizarIncidente(){
        axios.post(url()+'Incidencia/CrearActualizar',this.state.Incidente)
        .then( (response) => {
            if (response.data.success) {
                this.setState({
                    Incidente:{
                        ...this.state.Incidente,
                        PKID: parseInt(response.data.data, 10)
                    }
                })
            }else{
                throw new Error(response.data.data)
            }
        })
        .catch( (error) => {
            this.toast.show({
                severity:'error', 
                summary: 'Crear o actualizar item plan de prueba', 
                detail: error.message, 
                life: 1500})
        })
        .finally( () => {
            this.SubirArchivos(this.state.Incidente.Evidencias,3)
            if(this.state.Incidente.CheckCorreo){
            this.EnviarCorreo()}else{this.setState({
                Incidente : cIncidente,
                mostrarModalIncidente : false,
                ModalIncidente: false,
                Request : false
            },() => this.actualizar())}
        })
    }

    SubirArchivos=(archivos, m)=>{
        for(let i=0; i<archivos.length;i++){
            this.SubirArchivo(archivos[i], i+1, m)
        }
    }

    SubirArchivo2 = (Event, FileUpload) =>  {
        const file = Event.files[0]
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
            let encoded = reader.result.toString().replace(/^data:(.*,)?/, '')
            if ((encoded.length % 4) > 0) {
                encoded += '='.repeat(4 - (encoded.length % 4));
            }
            var oArchivoAdjunto = {
                PKID : 0,
                Nombre : file.name.replace(/[.][^.]+$/, ''),
                FechaCreacion : '',
                Tamagno : file.size,//parseFloat(file.size / Math.pow(1024,2)).toFixed(2), //De Bytes a MegaBytes
                Archivo : encoded,
                Extension : file.name.slice((file.name.lastIndexOf(".") - 1 >>> 0) + 2)
            }
            this.setState({
                Incidente :{
                    ...this.state.Incidente,
                    Evidencias:[
                        ...this.state.Incidente.Evidencias,
                        oArchivoAdjunto
                    ]
                }
            })
        }
        reader.onerror = (Error) => {
            alert(Error)
        }
        FileUpload.current.files = ''
        FileUpload.current.state.files = ''
    }

    BorrarArchivo = (Event) => {
        let lArchivos = this.state.Incidente.Evidencias
        if(lArchivos.filter( (e) => { return e === Event}).length > 0){
            lArchivos = lArchivos.filter( (e) => { return e !== Event})
        }
        this.setState({
            Incidente :{
                ...this.state.Incidente,
                Evidencias : lArchivos
            }
        })
    }

    DescargarArchivo = (Event) => {
        let lArchivos = this.state.Incidente.Evidencias
        if(lArchivos.filter( (e) => { return e === Event}).length > 0){
            lArchivos = lArchivos.filter( (e) => { return e === Event})
            const oArchivos = lArchivos[0]
            IncidenteService.DescargarArchivo(oArchivos)
        }else{
            alert('Error: No se encontro el archivo indicado')
        }
    }

    async SubirArchivo(archivo, i, m){
        const formData = new FormData();
        formData.append("archivo",archivo)
        formData.append("Usuario",getDecode().sub)
        formData.append("Referencia",this.state.Incidente.PKID)
        formData.append("Modulo", m)
        axios.post(url()+'Calidad/SubirArchivo',formData,{'content-type': 'multipart/form-data'})
        .then( (response) => {
            if(response.data.success){
            }else{
                throw new Error(response.data.data)
            }
        })
        .catch( (error) => {
            this.toast.show({
                severity:'error', 
                summary: 'Envio de archivos', 
                detail: error.message, 
                life: 1500})
        })
    }

    async EnviarCorreo(){
        let EmailOrigen='', Contrasegna='', EmailDestino='', Asunto='', Mensaje=''
        let listaUser = this.state.ListaUsuarios
        EmailOrigen = this.state.correoCalidad
        Contrasegna = this.state.contrasegnaCalidad
        let idResponsable = this.state.Incidente.Empleado.PKID

        Object.keys(listaUser).forEach(function(key){
            if(listaUser[key].Empleado.PKID===idResponsable){
                EmailDestino=listaUser[key].Credencial+"@uniflex.com.pe"
            }
        })

        Asunto =this.state.Incidente.Incidencia

        Mensaje = "Incidencia: "+this.state.Incidente.Incidencia+"\n" + 
                    "Detalle: "+this.state.Incidente.Detalle+"\n" +
                    "Responsable: "+this.state.Incidente.Empleado.Nombre+"\n"+ 
                    "Fecha de Creación: "+this.state.Incidente.FechaCreacion+"\n"+ 
                    "Tipo de Error : "+this.state.Incidente.TipoError.Nombre+"\n"+ 
                    "Prioridad: "+this.state.Incidente.Prioridad+"\n"+ 
                    "Severidad: "+this.state.Incidente.Severidad.Nombre

        axios.post(url()+'Calidad/EnviarCorreo?EmailOrigen='+EmailOrigen+'&Contrasegna='+Contrasegna+'&EmailDestino='+EmailDestino+'&Asunto='+Asunto+'&Mensaje='+Mensaje)
        .then( (response) => {
            if (response.data.success) {
                this.toast.show({
                    severity:'info', 
                    summary: 'Enviar correo', 
                    detail: 'Se pudo enviar el correo exitosamente', 
                    life: 3000})
            }else{
                throw new Error(response.data.data)
            }
        })
        .catch( (error) => {
            this.toast.show({
                severity:'error', 
                summary: 'Enviar correo', 
                detail: error.message, 
                life: 1500})
        })
        .finally( () => {
            this.setState({
                Incidente : cIncidente,
                mostrarModalIncidente : false,
                ModalIncidente: false,
                Request : false
            },() => this.actualizar())
        })
    }

    ActualizarPaso=(event)=>{
        this.setState({
            ...this.state,
            Paso:event,
        },()=>this.crearActualizarPaso())
    }
    
    PasoCorrecto = (event) => {
        if(event.Correcto !== 2){
            event={
                ...event,
                Correcto: 2
            }
        }else{
            event={
                ...event,
                Correcto: 1
            }
        }
        console.log(event)
        this.abrio = true
        this.ActualizarPaso(event)
    }

    PasoFallo = (event) => {
        if(event.Correcto!==3){
            event={
                ...event,
                Correcto:3
            }
            this.nuevoIncidente=true
        }else{
            event={
                ...event,
                Correcto:1
            }
        }
        console.log(event)
        this.abrio=true
        this.ActualizarPaso(event)
    }

    ActualizarIncidente = (e) => {
        e = {
            ...e,
            Producto : {
                ...e.Producto,
                VersionProducto : this.state.ListaProducto.find( itemVersion => itemVersion.code === e.Producto.PKID).VersionProducto
            }
        }
        this.setState({
            Incidente : e,
            mostrarModalIncidente: true,
            ModalIncidente: true
        })
    }

    HandleChangePaso = (Event) => {
        this.setState({
            Paso:{
                ...this.state.Paso,
                [Event.target.name]: Event.target.value
            }
        })
    }

    OnChangeModalIncidente = (e) => {
        switch(e.target.name){
            case 'Empleado':
                this.setState({
                    Incidente:{
                        ...this.state.Incidente,
                        Empleado:{
                            PKID: e.value.code,
                            Nombre: e.value.name
                        }
                    }
                })
                break
            case 'Severidad':
                this.setState({
                    Incidente:{
                        ...this.state.Incidente,
                        Severidad:{
                            PKID: e.value.code,
                            Nombre: e.value.name
                        }
                    }
                })
                break
            case 'TipoError':
                this.setState({
                    Incidente:{
                        ...this.state.Incidente,
                        TipoError:{
                            PKID: e.value.code,
                            Nombre: e.value.name
                        }
                    }
                })
                break
            case 'Producto':
                this.setState({
                    Incidente : {
                        ...this.state.Incidente,
                        Producto : {
                            PKID : e.value.code,
                            Nombre : e.value.name,
                            VersionProducto : e.value.VersionProducto
                        }
                    }
                })
                break
            case 'VersionProducto':
                this.setState({
                    Incidente : {
                        ...this.state.Incidente,
                        VersionProducto : {
                            PKID : e.value.code,
                            Version : e.value.name
                        }
                    }
                })
                break
            default:
                this.setState({
                    Incidente:{
                        ...this.state.Incidente,
                        [e.target.name]: e.target.value
                    }
                })
                break
        }
    }

    handleOnSubmitIncidencia=async e=>{
        e.preventDefault()
        const{errores,...sinErrores} = this.state.Incidente
        const result = ValidarIncidencia(sinErrores)
        if(Object.keys(result).length){
            return this.setState({ErrorIncidencia: result})
        }else{
            this.setState({
                Request : true
            }, () => this.CrearActualizarIncidente())
        }
    }

    OnChangeCasoPruebaVersion = (e) => {
        this.setState({
            CasoPruebaVersion:{
                ...this.state.CasoPruebaVersion,
                [e.target.name]: e.target.value
            }
        })
    }

    SubirArchivosIncidencia = (lEvidencias) => {
        let levidencias=this.state.Incidente.Evidencias
        if(levidencias===null || levidencias===undefined){
            levidencias=[]
        }
        Object.keys(lEvidencias.files).forEach(function(key){
            levidencias.push(lEvidencias.files[key])
        })
        this.setState({
            ...this.state,
            Incidente:{
                ...this.state.Incidente,
                Evidencias: levidencias
            }
        })
    }

    HandleSubmitPaso = (Event) => {
        Event.preventDefault()
        const result = IncidenteService.ValidarPaso(this.state.Paso)
        if(Object.keys(result).length) return this.setState({ErroresPaso : result});
        else this.CrearActualizarPaso()
    }
    CrearActualizarPaso(){
        let listaPasos=this.state.Incidente.Pasos
        if(listaPasos.filter( (e) => { return e.NumeroPaso === this.state.Paso.NumeroPaso}).length > 0){
            listaPasos = listaPasos.filter( (e) => { return e.NumeroPaso !== this.state.Paso.NumeroPaso})
        }
        listaPasos.push(this.state.Paso)
        listaPasos.sort( function(a, b) {
            return a.NumeroPaso - b.NumeroPaso
        })
        this.setState({
            Paso : cPaso,
            Incidente:{
                ...this.state.Incidente,
                Pasos:listaPasos
            }
        },() => this.HideVisibleModalPaso(false))
    }
    HandleChange = (Event) => {
        switch(Event.target.name){
            case 'Empleado' :
                this.setState({
                    Incidente:{
                        ...this.state.Incidente,
                        Empleado:{
                            PKID : Event.value.code,
                            Nombre : Event.value.name
                        }
                    }
                })
                break
            case 'Severidad' :
                this.setState({
                    Incidente:{
                        ...this.state.Incidente,
                        Severidad:{
                            PKID : Event.value.code,
                            Nombre : Event.value.name
                        }
                    }
                })
                break
            case 'TipoError':
                this.setState({
                    Incidente:{
                        ...this.state.Incidente,
                        TipoError : {
                            PKID : Event.value.code,
                            Nombre : Event.value.name
                        }
                    }
                })
                break
            case 'Producto':
                this.setState({
                    Incidente : {
                        ...this.state.Incidente,
                        Producto : {
                            PKID : Event.value.code,
                            Nombre : Event.value.name,
                            VersionProducto : Event.value.VersionProducto
                        }
                    },
                })
                break
            case 'VersionProducto':
                this.setState({
                    Incidente : {
                        ...this.state.Incidente,
                        VersionProducto : {
                            PKID : Event.value.code,
                            Version : Event.value.name
                        }
                    },
                })
                break
                case 'Categorizacion':
                this.setState({
                    Incidente : {
                        ...this.state.Incidente,
                        Categorizacion : {
                            PKID : Event.value.code,
                            Descripcion : Event.value.name
                        }
                    },
                })
                break
            case 'CheckCorreo':
                this.setState({
                    Incidente:{
                        ...this.state.Incidente,
                        CheckCorreo: !this.state.Incidente.CheckCorreo
                    }
                })
                break
            default :
                this.setState({
                    Incidente:{
                        ...this.state.Incidente,
                        [Event.target.name]: Event.target.value
                    }
                })
                break
        }
    }

    CrearNuevoPaso = () => {        
        this.setState({
            Paso:{
                ...this.cPaso,
                NumeroPaso : this.state.Incidente.Pasos.length + 1,
            },
            ErroresPaso: {}
        },() => 
        this.setState({
            ModalPaso : true
        })
        )
    }

    HideVisibleModalPaso = (Mode) => {
        this.setState({
            ModalPaso: Mode
        })
    }

    onRowReorder=(event)=>{
        let lista=event.value, nuevaLista=[]
        if(lista!==null && lista !== undefined){
            for(let i=0; i<lista.length; i++){
                let obj=lista[i]
                obj={
                    ...obj,
                    NumeroPaso:i+1
                }
                nuevaLista.push(obj)
            }
        }
        this.actualizarOrdenPasos(nuevaLista)
    }

    render(){
        return(
            <>
                <Toast ref={(el) => this.toast = el} />
                <HeaderCasoPruebaVersion
                    CasoPruebaVersion={this.state.CasoPruebaVersion}
                    OnChange={this.OnChangeCasoPruebaVersion}/>
                <CuerpoCasoPruebaVersion
                    CrearNuevoIncidente={() => { 
                        this.setState({
                            mostrarModalIncidente : true,
                            ModalIncidente: true
                        })                        
                    }}
                    OnResponse={this.OnResponse}
                    PasoCorrecto={this.PasoCorrecto}
                    PasoFallo={this.PasoFallo}
                    ActualizarIncidente={this.ActualizarIncidente}
                    ListaPaso={this.state.CasoPruebaVersion.Pasos}
                    ListaIncidente={this.state.CasoPruebaVersion.Incidentes}
                    onRowReorder={this.onRowReorder}/>
                <form autoComplete="Off" onSubmit={this.handleOnSubmitIncidencia}>
                <ModalIncidencia
                    ModalIncidente= {this.state.ModalIncidente}
                    mostrarModal = {this.state.mostrarModalIncidente}
                    HideModal= {() => this.setState({
                        ModalIncidente: false
                    })}
                    HandleChange= {this.HandleChange}
                        ActualizarPaso= {this.ActualizarPaso}
                        EliminarPaso= {this.EliminarPaso}
                        SubirArchivo= {this.SubirArchivo2}
                        BorrarArchivo= {this.BorrarArchivo}
                        DescargarArchivo= {this.DescargarArchivo}
                        CrearNuevoPaso= {this.CrearNuevoPaso}
                        ListaProducto= {this.state.ListaProducto}
                        ListaCategorizacion = {this.state.Categorizacion} 
                        OnRowReorder = {this.OnRowReorder}
                    SubirArchivosIncidencia = {this.SubirArchivosIncidencia}
                    Incidente = {this.state.Incidente}
                    onChange = {this.OnChangeModalIncidente}
                    onClick = {() => {
                        this.setState({
                            mostrarModalIncidente : false,
                            ModalIncidente : false,
                            Incidente : cIncidente})
                    }}
                    onSubmit = {this.handleOnSubmitIncidencia}
                    ListaVersionProducto = {this.state.ListaProducto}
                    ListaEstado = {this.state.ListaEstado}
                    ListaEmpleado = {this.state.ListaEmpleado}
                    ListaTipoError = {this.state.ListaTipoError}
                    ListaSeveridad = {this.state.ListaSeveridad}
                    Errores = {this.state.ErrorIncidencia}
                    Request = {this.state.Request}
                    FormValues= {this.state.Incidente}
                    />
                </form>
                <form autoComplete="Off" onSubmit={this.HandleSubmitPaso}>
                    <CasoPruebaModalPaso
                        Modal= {this.state.ModalPaso}
                        HideModal= {() => this.HideVisibleModalPaso(false)}
                        FormValues = {this.state.Paso}
                        HandleChange = {this.HandleChangePaso}
                        Errores = {this.state.ErroresPaso}/>
                </form>
            </>
        )
    }
}
export default CasoPruebaVersion;