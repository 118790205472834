import React from 'react'
import { EmpleadoService } from '../../Domain/Services/EmpleadoService'
import { AreaService } from '../../Domain/Services/AreaService'
import DTEmpleados from '../Components/Desarrollo.Seguridad.Empleado.DataTable'
import ModalEmpleado from '../Components/Desarrollo.Seguridad.Empleado.Modal'
import EmpleadoHeader from '../Components/EmpleadoHeader'

interface IProps {
}

interface IEmpleado {
        PKID: Number,
        Codigo: String,
        Nombre: String,
        Area: {
            PKID: Number,
            Codigo: String,
            Nombre: String,
        },
        CorreoElectronico: String,

}

interface IState {
    Modal: boolean
    ListaEmpleado: IEmpleado[]
    ListaEmpleadoOriginal: IEmpleado[]
    ListaArea: Array<object>
    Empleado: any
    Errores: any
}

export default class SeguridadEmpleado extends React.Component<IProps,IState>{

    ValidarEmpleado = (values:any) =>{
        const errores : any ={}
        if(!values.Nombre) errores.Nombre = 'Nombre es requerido'
        if(!values.Area.PKID) errores.Area = 'Area es requerido'
        if(!values.CorreoElectronico) errores.CorreoElectronico  = 'CorreoElectronico es requerido'
        return errores;
    }

    cEmpleado = {
        PKID : 0,
        Codigo : '',
        Nombre : '',
        Area : {
            PKID : 0,
            Codigo : '',
            Nombre : ''
        },
        CorreoElectronico: ''
    }

    constructor(Props: any){
        super(Props)
        this.state = {
            Modal: false,
            ListaEmpleado: [],
            ListaEmpleadoOriginal: [],
            ListaArea: [],
            Empleado: this.cEmpleado,
            Errores: {}
        }
    }

    componentDidMount(){
        this.ListarEmpleado()
        this.ListarArea()
    }

    ListarEmpleado(){
        EmpleadoService.Listar()
        .then((response : any) => {
            if(response.success){
                this.setState({
                    ListaEmpleado: JSON.parse(response.data)
                })
                this.setState({
                    ListaEmpleadoOriginal: JSON.parse(response.data)
                })
            }else{
                alert(response.data)
            }
        }).catch((error) => {
            alert(error)
        })

    }

    ListarArea(){
        AreaService.Listar()
        .then( (response : any) => {
            if(response.success){
                var aArea : any = []
                Object.keys(JSON.parse(response.data)).forEach(function(key){
                    var iArea = {
                        code: JSON.parse(response.data)[key].PKID,
                        name: JSON.parse(response.data)[key].Nombre,
                    }
                    aArea.push(iArea);
                });
                this.setState({
                    ListaArea: aArea
                })
            }else{
                alert(response.data)
            }
        }).catch( (error) => {
            alert(error)
        })
    }

    HandleChange = (Event : any) => {
        switch(Event.target.name){
            case 'Area' :
                this.setState({
                    Empleado:{
                        ...this.state.Empleado,
                        Area:{
                            ...this.state.Empleado.Area,
                            PKID : Event.target.value.code,
                            Nombre : Event.target.value.name
                        }
                    }
                })
                break
            default :
                this.setState({
                    Empleado:{
                        ...this.state.Empleado,
                        [Event.target.name]: Event.target.value
                    }
                })
                break
        }
    }

    HandleSubmit = (Event : any) => {
        Event.preventDefault()
        const result = this.ValidarEmpleado(this.state.Empleado)
        if(Object.keys(result).length){
            return this.setState({Errores: result})
        }else{
            if(this.ValidarExpresion(this.state.Empleado.CorreoElectronico))
                this.CrearActualizar()
            else
                alert("No es un correo valido")
        }
    }

    ValidarExpresion = (CorreoElectronico : string) => {
        var Band = false
        var regex = new RegExp(/^([a-z0-9_\.-]+)@uniflex\.com.pe$/)
        if(regex.test(CorreoElectronico)) Band = !Band
        return Band
    }

    CrearActualizar(){
        EmpleadoService.CrearActualizar(this.state.Empleado)
        .then( (response : any) => {
            alert(response.data)
            if(response.success){
                this.setState({
                    Modal: false,
                    Empleado: this.cEmpleado
                },() => this.componentDidMount())
            }
        })
        .catch( (error) => {
            alert(error)
        })
    }

    HandleChangeBuscador = (e: any) => {

        const ListaEmpleadoOriginal = this.state.ListaEmpleadoOriginal
        const ListaEmpleadoFiltroBefore = this.state.ListaEmpleadoOriginal

        if (e.target.value.length===0) {
            this.setState({
                ListaEmpleado : ListaEmpleadoOriginal
            })

        } else {
                const listaFiltrada = ListaEmpleadoFiltroBefore.filter((lista) => lista.Nombre.toLowerCase().includes(e.target.value.toLowerCase()))

                this.setState({
                    ListaEmpleado : listaFiltrada
                })
        }
    }

    render(){
        return(
            <div className="container">
                <EmpleadoHeader
                    OnChangeBuscador={this.HandleChangeBuscador}
                />
                <DTEmpleados
                    ListaEmpleado= {this.state.ListaEmpleado}
                    openModal= {() => this.setState({Modal: true})}
                    handleSelectRow= { (e : any) =>{
                        this.setState({
                            Empleado: e,
                            Modal: true
                        })
                    }}/>
                <ModalEmpleado
                    openModal= {this.state.Modal}
                    closeModal = {() => {
                        this.setState({
                            Errores: {},
                            Empleado: this.cEmpleado,
                            Modal: false
                        })
                    }}
                    listaArea = {this.state.ListaArea}
                    handleChange = {this.HandleChange}
                    empleado = {this.state.Empleado}
                    onSubmit = {this.HandleSubmit}
                    Errores = {this.state.Errores}/>
            </div>
        )
    }
}