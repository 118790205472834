import React from 'react'
import CategorizacionBody from '../Components/CategorizacionBody'
import CategorizacionModal from '../Components/CategorizacionModal'
import { CategorizacionService } from '../../Domain/Services/CategorizacionService'

import { ICategorizacion } from '../../Domain/Models/ICategorizacion'

interface IProps {
}

interface IState {
    Paginado: any
    Registros: Array<object>
    ModalCategorizacion: boolean
    Categorizacion : ICategorizacion    
    ListaCliente: Array<object>
    ListaCategorizacion: Array<object>
    ErrorCategorizacion: any    
    Request: boolean
}

export default class Categorizacion extends React.Component<IProps, IState> {

    cPaginado = {
        NumeroPagina : 1,
        RegistrosPagina : 10,
        NumeroPaginas : 0,
        fil_Nombre : '',
        fil_Modulo : '',
        fil_Responsable : ''
    }

    cRegistro = [
        { RegistrosPagina: 10 },
        { RegistrosPagina: 20 },
        { RegistrosPagina: 30 }
    ]

    cCategorizacion = {
        PKID : 0,
        Descripcion : ''       
    }

    

    constructor(props: any){
        super(props)
        this.state = {
            Paginado : this.cPaginado,
            Registros : this.cRegistro,
            ModalCategorizacion : false,
            Categorizacion : this.cCategorizacion,  
            ListaCliente : [],
            ListaCategorizacion : [],
            ErrorCategorizacion : {},            
            Request: false
        }
    }

    componentDidMount(){
        this.ListarPaginacion()
    }

    ListarPaginacion(){
        CategorizacionService.ListarPaginacion(this.state.Paginado)
        .then((response: any) => {
            if(response.success){
                this.setState({
                    Paginado: JSON.parse(response.data).Paginacion,
                    ListaCategorizacion: JSON.parse(response.data).Categorizacion
                })
            }
        })
        .catch((Error: any) => {
            alert(Error)
        })
    }

    OnResponse = (Categorizacion: any) => {
        if(Categorizacion){
            this.setState({ 
                Categorizacion : Categorizacion,
                ErrorCategorizacion: {}
            },() => this.HideVisibleModalCategorizacion(true))
        }else{
            this.setState({ 
                Categorizacion : this.cCategorizacion,
                ErrorCategorizacion: {}
            },() => this.HideVisibleModalCategorizacion(true))
        }
    }

    HideVisibleModalCategorizacion = (Mode: boolean) => {
        this.setState({
            ModalCategorizacion: Mode
        })
    }

    HandleChangeCategorizacion = (Event: any) =>{
        this.setState({
            Categorizacion: {
                ...this.state.Categorizacion,
                [Event.target.name]: Event.target.value
            }
        })
    }

    HandleSubmitCategorizacion = (Event: any) =>{
        Event.preventDefault()
        const result = CategorizacionService.ValidarCategorizacion(this.state.Categorizacion);
        if(Object.keys(result).length){
            return this.setState({ErrorCategorizacion : result});
        }else{
            this.setState({
                Request: true
            }, () => this.CrearActualizarCategorizacion())
        }
    }

    CrearActualizarCategorizacion(){
        CategorizacionService.CrearActualizar(this.state.Categorizacion)
        .then((response: any) => {
            if(response.success){
                var json = JSON.parse(response.data)
                if(json.PKID){
                    this.setState({
                        ModalCategorizacion : !this.state.ModalCategorizacion,
                        Categorizacion : this.cCategorizacion,
                        Paginado: this.cPaginado
                    },() => this.ListarPaginacion())
                }
            }else{
                alert(response.data)
            }
        })
        .catch((Error) => {
            alert(Error)
        })
        .finally(() => {
            this.setState({
                Request: false
            })
        })
    }

    HandleSelectRow = (Categorizacion: ICategorizacion) => {
        this.setState({ 
            Categorizacion : Categorizacion,
            ErrorCategorizacion : {}
        },() => this.HideVisibleModalCategorizacion(true))
    }

    

  

   

    OrdenarArray = (Array: Array<any>) => {
        return Array.sort(function (a, b) {
            if (a.Orden > b.Orden) return -1
            if (a.Orden < b.Orden) return 1
            return 0;
        })
    }

    

    OnRegistroChange = (Event: any) =>{
        this.setState({
            Paginado : {
                ...this.state.Paginado,
                NumeroPagina: 1,
                RegistrosPagina: Event.value.RegistrosPagina,
        }},() =>{ this.ListarPaginacion() })
    }

    HandlePreviousLL = (Event: any) =>{
        this.setState({
            Paginado : this.cPaginado
        },() =>{ this.ListarPaginacion() })
    }

    HandlePrevious = (Event: any) =>{
        this.setState({Paginado : {
            ...this.state.Paginado,
            NumeroPagina: this.state.Paginado.NumeroPagina - 1,
        }},() =>{ this.ListarPaginacion() })
    }

    HandleFollowing = () => {
        this.setState({Paginado : {
            ...this.state.Paginado,
            NumeroPagina: this.state.Paginado.NumeroPagina + 1,
        }},() =>{ this.ListarPaginacion() })
    }

    HandleFollowingLL = () => {
        this.setState({Paginado : {
            NumeroPagina: this.state.Paginado.NumeroPaginas,
            RegistrosPagina: this.state.Paginado.RegistrosPagina,
            NumeroPaginas: this.state.Paginado.NumeroPaginas,
            fil_Nombre : this.state.Paginado.fil_Nombre
        }},() =>{ this.ListarPaginacion() })
    }

    render(){
        return(
            <div className="container">
                <CategorizacionBody
                    ListaCategorizacion={this.state.ListaCategorizacion}
                    OnResponse={this.OnResponse}
                    Paginado={this.state.Paginado}
                    Registros= {this.state.Registros}
                    OnRegistroChange = {this.OnRegistroChange}
                    HandlePreviousLL = {this.HandlePreviousLL}
                    HandlePrevious = {this.HandlePrevious}
                    HandleFollowing = {this.HandleFollowing}
                    HandleFollowingLL = {this.HandleFollowingLL}/>
                    <form autoComplete="Off" onSubmit={this.HandleSubmitCategorizacion}>
                        <CategorizacionModal
                            Modal = {this.state.ModalCategorizacion}
                            FormValues = {this.state.Categorizacion}                            
                            HideModal = {this.HideVisibleModalCategorizacion}
                            HandleChange = {this.HandleChangeCategorizacion}
                            ErrorCategorizacion = {this.state.ErrorCategorizacion}
                            HandleSelectRow={this.HandleSelectRow}
                            Request={this.state.Request}/>
                    </form>
                    
            </div>
        )
    }
}