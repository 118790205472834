import { HTTP } from "../Http/Http"

export const ItemBackLogRepository = {
    ListarPaginacion: async (Paginacion: any) => {
        return await HTTP.POST('ItemBackLog/ListarImplementacionItemBackLog',Paginacion,{})
    },
    Listar: async (IDImplementacionBackLog: any) => {
        return await HTTP.POST('ItemBackLog/ListarImplementacionItemBackLogByID?IDImplementacionBackLog='+IDImplementacionBackLog,'',{})       
    },
    CrearActualizar: async (ImplementacionItemBackLog: any) => {
        return await HTTP.POST('Proyecto/CrearActualizarImplementacionItemBackLog',ImplementacionItemBackLog,{})
    },
    Consultar: async (ImplementacionItemBackLog: any) => {
        return await HTTP.POST('ItemBackLog/ConsultarImplementacionItemBackLog',ImplementacionItemBackLog,{})
    }
}