import React from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';

interface IProps{
    ListaPrioridad: any
    OnResponse: any
    Paginado: any
    Registros: any
    OnRegistroChange: any
    HandlePreviousLL: any
    HandlePrevious: any
    HandleFollowing: any
    HandleFollowingLL: any
}

export default function PrioridadBody(Props: IProps){

    const Header = (
        <div className="p-d-flex p-jc-between">
            <h4>Prioridades</h4>
            <Button 
                label="Agregar Prioridad" 
                onClick={() => Props.OnResponse(null)} 
                autoFocus/>
        </div>
    )

    return(
        <div className="datatable-filter-demo">
            <DataTable 
                value={Props.ListaPrioridad} 
                onSelectionChange={(Event) => Props.OnResponse(Event.value)}
                header={Header}
                selectionMode="single">
                    <Column field="Orden" header=""  style={{width:'10%'}}/>
                    <Column field="Descripcion" header="Descripción" sortable filter filterMatchMode="contains" filterPlaceholder="Buscar Prioridad..." style={{width:'60%'}}/>                   
            </DataTable>
            <div className="p-paginator p-component p-paginator-bottom">
                        {Props.Paginado.NumeroPagina > 1 ? 
                            <>
                                <button type="button" className="p-paginator-first p-paginator-element p-link" onClick={Props.HandlePreviousLL}>
                                    <span className="p-paginator-icon pi pi-angle-double-left"></span>
                                </button>
                                <button type="button" className="p-paginator-prev p-paginator-element p-link" onClick={Props.HandlePrevious}>
                                    <span className="p-paginator-icon pi pi-angle-left"></span>
                                </button>
                            </>
                            :
                            <>
                                <button type="button" className="p-paginator-first p-paginator-element p-link p-disabled" disabled>
                                    <span className="p-paginator-icon pi pi-angle-double-left"></span>
                                </button>
                                <button type="button" className="p-paginator-prev p-paginator-element p-link p-disabled" disabled>
                                    <span className="p-paginator-icon pi pi-angle-left"></span>
                                </button>
                            </>
                        }
                        <span className="p-paginator-pages">
                            <button type="button" className="p-paginator-page p-paginator-element p-link p-highlight">{Props.Paginado.NumeroPagina}</button>
                        </span>
                        {Props.Paginado.NumeroPagina < Props.Paginado.NumeroPaginas ? 
                            <>
                                <button type="button" className="p-paginator-next p-paginator-element p-link" onClick={Props.HandleFollowing}>
                                    <span className="p-paginator-icon pi pi-angle-right"></span>
                                </button>
                                <button type="button" className="p-paginator-last p-paginator-element p-link" onClick={Props.HandleFollowingLL}>
                                    <span className="p-paginator-icon pi pi-angle-double-right"></span>
                                </button>
                            </>
                            :
                            <>
                                <button type="button" className="p-paginator-next p-paginator-element p-link p-disabled" disabled>
                                    <span className="p-paginator-icon pi pi-angle-right"></span>
                                </button>
                                <button type="button" className="p-paginator-last p-paginator-element p-link p-disabled" disabled>
                                    <span className="p-paginator-icon pi pi-angle-double-right"></span>
                                </button>
                            </>
                        }
                <Dropdown 
                    value={{RegistrosPagina : Props.Paginado.RegistrosPagina}}
                    options={Props.Registros} 
                    onChange={Props.OnRegistroChange} 
                    optionLabel="RegistrosPagina"/>
            </div>
        </div>
     )
}
