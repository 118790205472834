import React from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown';

interface IProps{
    ListaDocumento: any
    EditarDocumento: any
    NuevoDocumento: any
    AbrirURL: any
    Paginado: any
    Registros: any
    OnRegistroChange: any
    HandlePreviousLL: any
    HandlePrevious: any
    HandleFollowing: any
    HandleFollowingLL: any
}

export default function DocumentacionBody(Props: IProps){

    const Acciones = (Documento: any) => {
        return (
            <React.Fragment>
                <div className="p-grid p-fluid ">
                    <div className="p-col-2">
                        <Button 
                            type="button"
                            icon="pi pi-pencil" 
                            className="p-button-rounded p-button-outlined"
                            onClick={ (Event) => Props.EditarDocumento(Documento)}/>
                    </div>
                    <div className="p-col-2">
                        <Button 
                            type="button"
                            icon="pi pi-arrow-circle-right" 
                            className="p-button-rounded p-button-outlined" 
                            onClick={ (Event) =>{Props.AbrirURL(Documento)}}/>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    const Header = (
        <div className="p-d-flex p-jc-between">
            <h4>Documentación</h4>
            <Button 
                label="Agregar Documentación" 
                onClick={Props.NuevoDocumento} 
                autoFocus/>
        </div>
    )

    return(
        <div className="datatable-filter-demo">
            <DataTable 
                value={Props.ListaDocumento} 
                className="p-datatable-customers p-datatable-sm p-datatable-gridlines"
                rowsPerPageOptions={[10,15,25]}
                header={Header}>
                    <Column field="PKID" header="ID" style={{width:'10%'}}/>
                    <Column field="Nombre" header="Nombre" style={{width:'70%'}}/>
                    <Column body={Acciones} header="Accion" style={{width:'20%'}}/>
                </DataTable>

                <div className="p-paginator p-component p-paginator-bottom">
                        {Props.Paginado.NumeroPagina > 1 ? 
                            <>
                                <button type="button" className="p-paginator-first p-paginator-element p-link" onClick={Props.HandlePreviousLL}>
                                    <span className="p-paginator-icon pi pi-angle-double-left"></span>
                                </button>
                                <button type="button" className="p-paginator-prev p-paginator-element p-link" onClick={Props.HandlePrevious}>
                                    <span className="p-paginator-icon pi pi-angle-left"></span>
                                </button>
                            </>
                            :
                            <>
                                <button type="button" className="p-paginator-first p-paginator-element p-link p-disabled" disabled>
                                    <span className="p-paginator-icon pi pi-angle-double-left"></span>
                                </button>
                                <button type="button" className="p-paginator-prev p-paginator-element p-link p-disabled" disabled>
                                    <span className="p-paginator-icon pi pi-angle-left"></span>
                                </button>
                            </>
                        }
                        <span className="p-paginator-pages">
                            <button type="button" className="p-paginator-page p-paginator-element p-link p-highlight">{Props.Paginado.NumeroPagina}</button>
                        </span>
                        {Props.Paginado.NumeroPagina < Props.Paginado.NumeroPaginas ? 
                            <>
                                <button type="button" className="p-paginator-next p-paginator-element p-link" onClick={Props.HandleFollowing}>
                                    <span className="p-paginator-icon pi pi-angle-right"></span>
                                </button>
                                <button type="button" className="p-paginator-last p-paginator-element p-link" onClick={Props.HandleFollowingLL}>
                                    <span className="p-paginator-icon pi pi-angle-double-right"></span>
                                </button>
                            </>
                            :
                            <>
                                <button type="button" className="p-paginator-next p-paginator-element p-link p-disabled" disabled>
                                    <span className="p-paginator-icon pi pi-angle-right"></span>
                                </button>
                                <button type="button" className="p-paginator-last p-paginator-element p-link p-disabled" disabled>
                                    <span className="p-paginator-icon pi pi-angle-double-right"></span>
                                </button>
                            </>
                        }
                <Dropdown 
                    value={{RegistrosPagina : Props.Paginado.RegistrosPagina}}
                    options={Props.Registros} 
                    onChange={Props.OnRegistroChange} 
                    optionLabel="RegistrosPagina"/>
            </div>

        </div>
    )
}