import { MotivoVersionamientoRepository } from '../../Infrastructure/Repositories/MotivoVersionamientoRepository'
import { IMotivoVersionamiento } from '../Models/IMotivoVersionamiento'


interface IErroresMotivoVersionamiento{
    Descripcion?: string
}


const ValidarMotivoVersionamiento = (MotivoVersionamiento: IMotivoVersionamiento) => {
    const errores: IErroresMotivoVersionamiento ={}
    if(!MotivoVersionamiento.Descripcion) errores.Descripcion  = 'Descripción del Motivo de Versionamiento es requerido'
    return errores
}


export const MotivoVersionamientoService = {
    ValidarMotivoVersionamiento,   
    Listar: () => {
        return MotivoVersionamientoRepository.Listar()
    }, 
    ListarPaginacion: (Paginacion: any) => {
        return MotivoVersionamientoRepository.ListarPaginacion(Paginacion)
    },   
    CrearActualizar: (MotivoVersionamiento: IMotivoVersionamiento) => {
        return MotivoVersionamientoRepository.CrearActualizar(MotivoVersionamiento)
    }
}