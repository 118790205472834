import React from 'react'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'

interface IProps{
    Modal: boolean
    HideModal: any
    HandleChange: any
    FormValues: any
    Errores: any
    Request: boolean
}

export default function DocumentacionModal(Props: IProps){

    const RenderFooter = () => (
        <div className="p-d-flex p-ml-auto">
            <Button 
                type="submit"
                label={Props.Request ? "Cargando..." : "Aceptar"}
                style={{
                    background:'#4D80E4',
                    border: '1px solid #4D80E4'}}
                icon={Props.Request ? "" : "pi pi-check" }
                className="p-ml-auto"
                disabled={Props.Request}
                autoFocus />
        </div>
    )

    return(
        <Dialog
            header='Documento'
            visible={Props.Modal}
            style={{ width: '110vh' }}
            footer={RenderFooter()} 
            onHide={Props.HideModal}>
                <div className="p-grid nested-grid">
                    <div className="p-col-12">
                        <div className="p-grid p-fluid ">
                            <div className="p-col-12">
                                <label 
                                    className="p-d-block"
                                    style={{
                                        color: '#4D80E4',
                                        fontFamily: 'Sarala',
                                        fontSize: '20px'}}>
                                Nombre</label>
                                <InputText 
                                    value={Props.FormValues.Nombre}
                                    onChange={Props.HandleChange}
                                    name="Nombre"
                                    type="text"/>
                                {Props.Errores.Nombre && <small className="p-invalid p-d-block">{Props.Errores.Nombre}</small>}
                            </div>
                        </div>
                    </div>
                    <div className="p-col-12">
                        <div className="p-grid p-fluid ">
                            <div className="p-col-12">
                                <label 
                                    className="p-d-block"
                                    style={{
                                        color: '#4D80E4',
                                        fontFamily: 'Sarala',
                                        fontSize: '20px'}}
                                >Link</label>
                                <InputText 
                                    value={Props.FormValues.Link}
                                    onChange={Props.HandleChange}
                                    name="Link"
                                    placeholder="https://"
                                    type="text"/>
                                {Props.Errores.Link && <small className="p-invalid p-d-block">{Props.Errores.Link}</small>}
                            </div>
                        </div>
                    </div>
                </div>
        </Dialog>
    )
}