import React from 'react'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import { Button } from 'primereact/button'
import { Calendar } from 'primereact/calendar'
import { Dropdown } from 'primereact/dropdown'
import { Local } from '../../Infrastructure/Components/LocaleSettings'
import { Checkbox } from 'primereact/checkbox'
import { Column } from 'primereact/column'
import { Editor } from 'primereact/editor'

interface IProps{
    Modal: boolean
    HideModal: any
    HandleChange: any
    BackLog: any
    ListaProducto: Array<object>
    ListaMotivoVersionamiento:Array<object>
    ListaImplementacionBackLog: Array<object>
    Errores: any
    Request: boolean
}

export default function ProyectoModal(Props : IProps){

    const RenderFooter = () =>{
        return (
            <Button 
                type="submit"
                label={Props.Request ? "Cargando..." : "Aceptar"}
                style={{
                    background:'#4D80E4',
                    border: '1px solid #4D80E4'}}
                icon={Props.Request ? "" : "pi pi-check" } 
                className="p-ml-auto"
                disabled={Props.Request}
                autoFocus/>
        )
    }

    const EditorDescripcion = (Event: any) => {
        Props.HandleChange({
            target:{
                name: "Descripcion",
                value: Event.htmlValue?Event.htmlValue.replace('<span ','<div ').replace('</span>','</div>'):''
            }
        })
    }
        
    return(
        <Dialog
            header="Crear Proyecto"
            visible={Props.Modal}
            style={{ width: '150vh' }} 
            footer={RenderFooter()} 
            onHide={() => Props.HideModal(null, false)}>
                <div className="p-grid nested-grid">
                    <div className="p-col-12 p-lg-6">
                        <div className="p-col">
                            <div className="p-grid p-fluid ">
                                <div className="p-col-12">
                                    <label 
                                        className="p-d-block"
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'}}
                                    >Nombre</label>
                                    <InputText 
                                        value={Props.BackLog.Nombre}
                                        onChange={Props.HandleChange}
                                        name="Nombre"
                                        type="text" />
                                    {Props.Errores.Nombre && <small className="p-invalid p-d-block">{Props.Errores.Nombre}</small>}        
                                </div>
                            </div>
                        </div>
                        <div className="p-col">
                            <div className="p-grid p-fluid ">
                                <div className="p-col-12">
                                    <label 
                                        className="p-d-block"
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'}}
                                    >Descripcion</label>
                                    

                                    <Editor 
                                   style={{height:'120px'}} 
                                    value={Props.BackLog.Descripcion}
                                    onTextChange={EditorDescripcion}
                                    
                                    />
                                    {Props.Errores.Descripcion && <small className="p-invalid p-d-block">{Props.Errores.Descripcion}</small>} 
                                </div>
                            </div>
                        </div>
                        <div className="p-grid p-fluid p-mr-1 p-ml-1">
                            <div className="p-col-12 p-md-6">
                                <label 
                                    className="p-d-block"
                                    style={{
                                        color: '#4D80E4',
                                        fontFamily: 'Sarala',
                                        fontSize: '20px'}}
                                >Version</label>
                                <InputText 
                                    value={Props.BackLog.Version}
                                    onChange={Props.HandleChange}
                                    type="text"
                                    name="Version"
                                    disabled/>
                            </div>
                            <div className="p-col-12 p-md-6">
                                <label 
                                    style={{
                                        color: '#4D80E4',
                                        fontFamily: 'Sarala',
                                        fontSize: '20px'}}
                                >Fecha Creacion</label>
                                <Calendar 
                                    value={Props.BackLog.FechaCreacion} 
                                    onChange={Props.HandleChange}
                                    showTime 
                                    showSeconds
                                    name="FechaCreacion"
                                    locale={Local} 
                                    dateFormat="dd/mm/yy"
                                    disabled/>
                            </div>

                            

                            <div className="p-col-12 p-md-6">
                                    <label 
                                        className="p-d-block"
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'}}
                                        >Motivo de Versionamiento</label>
                                <Dropdown
                                        value={{
                                            code: Props.BackLog.MotivoVersionamiento.PKID,
                                            name: Props.BackLog.MotivoVersionamiento.Descripcion}}
                                        options={Props.ListaMotivoVersionamiento}
                                        name = "MotivoVersionamiento"
                                        onChange={Props.HandleChange}
                                        optionLabel="name"
                                        placeholder="Seleccione Motivo de Versionamiento"/>
                                    {Props.Errores.MotivoVersionamiento && <small className="p-invalid p-d-block">{Props.Errores.MotivoVersionamiento}</small>}        
                                </div>

                                
                             

                                <div className="p-col-12 p-lg-12">
                                    <label 
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'}}
                                    >Detalle de Versionamiento</label>
                                    <InputTextarea 
                                        value={Props.BackLog.DetalleVersionamiento}
                                        onChange={Props.HandleChange}
                                        name="DetalleVersionamiento"
                                        type="text" 
                                        rows={5} 
                                        cols={10}/>
                                    {Props.Errores.DetalleVersionamiento && <small className="p-invalid p-d-block">{Props.Errores.DetalleVersionamiento}</small>}
                                </div>
                           
                                

                        </div>
                    </div>
                    <div className="p-col-12 p-lg-6">
                        <div className="p-col">
                            <div className="p-grid p-fluid ">
                                <div className="p-col-12 p-lg-6">
                                    <label 
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'}}
                                    >Fecha Inicio</label>
                                    <Calendar 
                                        value={Props.BackLog.FechaInicio} 
                                        onChange={Props.HandleChange} 
                                        name="FechaInicio"
                                        locale={Local} 
                                        dateFormat="dd/mm/yy"/>
                                    {Props.Errores.FechaInicio && <small className="p-invalid p-d-block">{Props.Errores.FechaInicio}</small>}  
                                </div>
                                <div className="p-col-12 p-lg-6">
                                    <label 
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'}}
                                    >Fecha Fin</label>
                                    <Calendar 
                                        value={Props.BackLog.FechaFin} 
                                        onChange={Props.HandleChange} 
                                        name="FechaFin"
                                        locale={Local} 
                                        dateFormat="dd/mm/yy" />
                                    {Props.Errores.FechaFin && <small className="p-invalid p-d-block">{Props.Errores.FechaFin}</small>}  
                                </div>
                            </div>
                        </div>
                        <div className="p-col">
                            <div className="p-grid p-fluid ">
                                <div className="p-col-12 p-lg-6">
                                    <label 
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'}}
                                    >Horas Gerencia</label>
                                    <InputText 
                                        value={Props.BackLog.HorasGerencia}
                                        onChange={Props.HandleChange}
                                        type="number"
                                        name="HorasGerencia"
                                        min="0"/>
                                    {Props.Errores.HorasGerencia && <small className="p-invalid p-d-block">{Props.Errores.HorasGerencia}</small>}  
                                </div>
                                <div className="p-col-12 p-lg-6">
                                    <label 
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'}}
                                    >Horas Estimadas</label>
                                    <InputText 
                                        value={Props.BackLog.HorasEstimadas}
                                        onChange={Props.HandleChange}
                                        type="number"
                                        name="HorasEstimadas"
                                        min="0"/>
                                    {Props.Errores.HorasEstimadas && <small className="p-invalid p-d-block">{Props.Errores.HorasEstimadas}</small>}  
                                </div>
                            </div>
                        </div>
                        <div className="p-col">
                            <div className="p-grid p-fluid ">
                                <div className="p-col-12 p-lg-6">
                                    <label 
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'}}
                                    >Producto</label>
                                    <Dropdown 
                                        value = {{
                                            code: Props.BackLog.Producto.PKID,
                                            name: Props.BackLog.Producto.Nombre,
                                            VersionProducto : Props.BackLog.Producto.VersionProducto}}
                                        options = {Props.ListaProducto}
                                        onChange = {Props.HandleChange}
                                        optionLabel = "name"
                                        name = "Producto"
                                        placeholder = "Seleccione Producto"/>
                                    {Props.Errores.Producto && <small className="p-invalid p-d-block">{Props.Errores.Producto}</small>}
                                </div>
                                <div className="p-col-12 p-lg-6">
                                    <label 
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'}}
                                    >Versión</label>
                                     <Dropdown 
                                        value={{
                                            code: Props.BackLog.VersionProducto.PKID,
                                            name: Props.BackLog.VersionProducto.Version}}
                                        options={Props.BackLog.Producto.VersionProducto}
                                        onChange={Props.HandleChange}
                                        optionLabel="name"
                                        name = "VersionProducto"
                                        placeholder="Seleccione Versión"/>
                                    {Props.Errores.VersionProducto && <small className="p-invalid p-d-block">{Props.Errores.VersionProducto}</small>}
                                </div>
                            </div>
                        </div>
                        <div className="p-col">
                            <div className="p-grid p-fluid ">
                                <div className="p-col-12 p-lg-6">
                                    <label 
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'}}
                                    >BackLog</label>
                                    <Dropdown 
                                        value = {{
                                            code: Props.BackLog.ImplementacionBackLog?Props.BackLog.ImplementacionBackLog.PKID:0,
                                            name: Props.BackLog.ImplementacionBackLog?Props.BackLog.ImplementacionBackLog.Nombre:'',
                                            VersionProducto : Props.BackLog.ImplementacionBackLog?Props.BackLog.ImplementacionBackLog.ImplementacionBackLogVersion:[]}}
                                        options = {Props.ListaImplementacionBackLog}
                                        onChange = {Props.HandleChange}
                                        optionLabel = "name"
                                        name = "ImplementacionBackLog"
                                        placeholder = "Seleccione el BackLog"/>
                                    {Props.Errores.ImplementacionBackLog && <small className="p-invalid p-d-block">{Props.Errores.ImplementacionBackLog}</small>}
                                </div>
                                <div className="p-col-12 p-lg-6">
                                    <label 
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'}}
                                    >BackLogVersión</label>
                                      <Dropdown 
                                        value={{
                                            code: Props.BackLog.ImplementacionBackLogVersion?Props.BackLog.ImplementacionBackLogVersion.PKID:0,
                                            name: Props.BackLog.ImplementacionBackLogVersion?Props.BackLog.ImplementacionBackLogVersion.Version:0}}
                                        options={Props.BackLog.ImplementacionBackLog?Props.BackLog.ImplementacionBackLog.ImplementacionBackLogVersion:[]}
                                        onChange={Props.HandleChange}
                                        optionLabel="name"
                                        name = "ImplementacionBackLogVersion"
                                        placeholder="Seleccione Versión"/>
                                    {Props.Errores.ImplementacionBackLogVersion && <small className="p-invalid p-d-block">{Props.Errores.ImplementacionBackLogVersion}</small>}
                                </div>
                                <div className="p-col-12 p-lg-12">
                                    <label 
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'}}
                                    >Objetivo</label>
                                    <InputTextarea 
                                        value={Props.BackLog.Objetivo}
                                        onChange={Props.HandleChange}
                                        name="Objetivo"
                                        type="text" 
                                        rows={5} 
                                        cols={10}/>
                                    {Props.Errores.Objetivo && <small className="p-invalid p-d-block">{Props.Errores.Objetivo}</small>}
                                </div>
                                
                                <div className="p-col-12 p-lg-12">
                                <Checkbox       
                                     inputId="binary"  
                                        checked={Props.BackLog.Activo} 
                                     onChange={Props.HandleChange}
                                     name="Activo" />
                                     <label 
                                        className=""
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'}}
                                        >      Activo</label>
                                    {Props.Errores.Activo && <small className="p-invalid p-d-block">{Props.Errores.Activo}</small>}    
                                </div>
                               
                            </div>
                        </div>
                    </div> 
                </div>
        </Dialog>
   )
}