import React from 'react'
import ItemRequerimientoBody from '../Components/ItemRequerimientoBody'
import ItemRequerimientoHeader from '../Components/ItemRequerimientoHeader'
import ItemRequerimientoModal from '../Components/ItemRequerimientoModal'
import { RequerimientoService } from '../../Domain/Services/RequerimientoService'
import { EstadoService } from '../../Domain/Services/EstadoService'
import { SolicitudService } from '../../Domain/Services/SolicitudService'
import { ItemRequerimientoService} from '../../Domain/Services/ItemRequerimientoService'
import RequerimientoModal from '../Components/RequerimientoModal'
import { getRequerimiento ,getProyecto, setRequerimiento } from '../../Services/LocalStorage'
import { AuthHelper } from '../../Domain/Utils/AuthHelper'
import { IPaginacion } from '../../Domain/Models/IPaginacion'
import { IRequerimiento } from '../../Domain/Models/IRequerimiento'
import { IITemRequerimiento } from '../../Domain/Models/IITemRequerimiento'
import { IToken } from '../../Domain/Models/IToken'
import { ItemBackLogService } from '../../Domain/Services/ItemBackLogService'

import 'react-responsive-modal/styles.css';

interface IProps {
    Token: IToken
}

interface IState{
    Pendiente: IPaginacion
    EnCurso: IPaginacion
    Pruebas: IPaginacion
    Hecho: IPaginacion
    Requerimiento: IRequerimiento
    ItemRequerimiento: IITemRequerimiento
    ListaEstado: Array<object>
    ListaSolicitudes: Array<object>
    ListaItemBackLogs: Array<object>
    ListaSolicitudesDatos: Array<object>
    Errores: any
    Request: boolean
    Mode: boolean
    ErroresItemRequerimiento: any
    MostrarTareas: boolean,
    open: false 
}

export default class ItemRequerimiento extends React.Component<IProps, IState> {

    cRequerimiento = {
        PKID: 0,
        Requisito : '',
        Estimacion : 0,
        FactorAjuste : 1,
        EstimacionAjustada : 0,
        IDBackLog : getProyecto()? JSON.parse(getProyecto() || '{}').PKID : 0,
        FechaInicio : new Date(),
        FechaFin : new Date(),
        PorcentajeAvance : 0,
        Estado:{
            PKID: 0,
            Descripcion: ''
        },
        HorasEjecutadas : 0,
        ContextoEstandar : '',
        ProblemaEstandar : '',
        Responsable:{
            PKID: parseInt(AuthHelper.GetDecoded().sub,10),
            Nombre: AuthHelper.GetDecoded().Empleado
        },
        Solicitud:{
            PKID:0,
            Nombre:'',
            Empleado:{
                PKID:0,
                Codigo:'',
                Nombre:'',
                Area:{
                    PKID:0,
                    Codigo:'',
                    Nombre:''
                }
            }
        },
        ArchivosAdjuntos: [],
        NumeroOCS: '',
        DescripcionOCS: '',
        Descripcion:'',
        ImplementacionItemBackLog:{
            PKID:0,
            Nombre:''
        },
        ArchivosSolucionAdjuntos: []
    }

    cItemRequerimiento = {
        PKID: 0,
        Requisito : '',
        Descripcion : '',
        Estimacion : 0,
        FactorAjuste : 1,
        EstimacionAjustada : 0,
        IDItemBackLog : getRequerimiento()? JSON.parse(getRequerimiento() || '{}').PKID:0,
        FechaInicio : new Date(),
        FechaFin : new Date(),
        PorcentajeAvance : 0,
        Estado:{
            PKID: 0,
            Descripcion: ''
        },
        HorasEjecutadas : 0,
        ContextoEstandar : '',
        ProblemaEstandar : '',
        Responsable:{
            PKID: parseInt(AuthHelper.GetDecoded().sub,10),
            Nombre: AuthHelper.GetDecoded().Empleado
        },
        Solicitud:{
            PKID:0,
            Nombre:'',
            Empleado:{
                PKID:0,
                Codigo:'',
                Nombre:'',
                Area:{
                    PKID:0,
                    Codigo:'',
                    Nombre:''
                }
            }
        },
        ArchivosAdjuntos: [],
        NumeroOCS: '',
        DescripcionOCS: '',
        ArchivosSolucionAdjuntos: [],
        LinkOCS: ''
    }

    cPaginacion = {
        Lista: [],
        Paginacion: {
            NumeroPagina: 1, 
            RegistrosPagina: 5, 
            NumeroPaginas: 0, 
            IDEstado: 1, 
            IDUsuario: parseInt(AuthHelper.GetDecoded().sub,10),
            IDItemBackLog: getRequerimiento()? JSON.parse(getRequerimiento() || '{}').PKID:0
        },
        Loading: true
    }

    constructor(props: any){
        super(props)
        this.state = {
            Pendiente: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 1}},
            EnCurso: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 2}},
            Pruebas: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 4}},
            Hecho: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 3}},
            Requerimiento: this.cRequerimiento,
            ItemRequerimiento : this.cItemRequerimiento,
            ListaEstado: [],
            ListaSolicitudes: [],
            ListaItemBackLogs:[],
            ListaSolicitudesDatos:[],
            Errores: {},
            Request: false,
            Mode: false,
            ErroresItemRequerimiento: {},
            MostrarTareas : false,
            open: false,
            
        }

    }

    componentDidMount(){
        if(getRequerimiento()){
            this.ConsultarRequerimiento()
        }else{
            alert('No se encuentra asignado ningun requerimiento')
        }
    }

    ConsultarRequerimiento(){
        RequerimientoService.Consultar(JSON.parse(getRequerimiento() || '{}'))
        .then((response: any) => {
            if (response.success) {
                    let ID=JSON.parse(response.data).PKID
                    let ContextoEstandar=JSON.parse(response.data).ContextoEstandar
                    let ProblemaEstandar=JSON.parse(response.data).ProblemaEstandar
                    let Descripcion=JSON.parse(response.data).Descripcion
                    console.log("SELECT * FROM ItemBackLog WHERE PKID="+ID)
                    console.log("ContextoEstandar: "+ContextoEstandar)
                    console.log("ProblemaEstandar "+ProblemaEstandar)
                    console.log("Descripcion "+Descripcion)
                    console.log(response.data)
				    setRequerimiento(response.data)
                    this.setState({Requerimiento : JSON.parse(response.data)})
            }else{
                throw Error(response.data)
            }
        })

        .catch((error) => {
            alert(error + "\n use block de notas para formatear su texto y quite saltos de linea")
        })
        .finally(() => {
            this.ListarEstado()
            this.ListarSolicitudes()
            this.ListarItemBackLogs()
            this.ListarItemRequerimiento('Pendiente')
            this.ListarItemRequerimiento('EnCurso')
            this.ListarItemRequerimiento('Pruebas')
            this.ListarItemRequerimiento('Hecho')
        })
        
    }

    ListarEstado(){
        EstadoService.Listar()
        .then( (response : any) => {
            if(response.success){
                var aEstado: Array<object> = []
                Object.keys(JSON.parse(response.data)).forEach(function(key){
                    var iEstado = {
                        code: JSON.parse(response.data)[key].PKID,
                        name: JSON.parse(response.data)[key].Descripcion,
                    }
                    aEstado.push(iEstado)
                });
                this.setState({
                    ListaEstado: aEstado,
                })
            }else{
                alert(response.data);
            }
        })
        .catch((error) => {
            alert(error)
        })
    }

    ListarItemBackLogs(){

        ItemBackLogService.Listar( getProyecto()? JSON.parse(getProyecto() || '{}').ImplementacionBackLog.PKID : 0)
        .then( (response : any) => {
            if(response.success){
                var aItemBackLog: Array<object> = []
                Object.keys(JSON.parse(response.data)).forEach(function(key){
                    var iItemBackLog = {
                        code: JSON.parse(response.data)[key].PKID,
                        name: JSON.parse(response.data)[key].Nombre,
                    }
                    aItemBackLog.push(iItemBackLog);                    
                });
                this.setState({
                    ListaItemBackLogs: aItemBackLog                    
                })
            }else{
                alert(response.data);
            }
        })
        .catch((error) => {
            alert(error)
        })
    }

    ListarSolicitudes(){
        SolicitudService.Listar()
        .then((response: any) => {
            if(response.success){
                let lTemporal: Array<object>= [], lDatos: Array<object>= []
                lTemporal.push({
                    code: 0,
                    name: "[=======(Quitar Solicitud Seleccionadada)======]",
                })
                Object.keys(JSON.parse(response.data)).forEach(function(key){
                    lTemporal.push({
                        code: JSON.parse(response.data)[key].PKID,
                        name: JSON.parse(response.data)[key].Nombre,
                    });
                    lDatos.push(JSON.parse(response.data)[key])
                });
                this.setState({
                    ListaSolicitudes: lTemporal,
                    ListaSolicitudesDatos: lDatos
                })
            }
        })
        .catch((error) => {
            alert(error)
        })
    }

    ListarItemRequerimiento(Estado: string){
        ItemRequerimientoService.ListarPaginacion(
            {
                'Pendiente': this.state.Pendiente.Paginacion,
                'EnCurso' : this.state.EnCurso.Paginacion,
                'Hecho' : this.state.Hecho.Paginacion,
                'Pruebas' : this.state.Pruebas.Paginacion
            }[Estado]
        )
        .then((response: any) => {
            if(response.success){
                let jParse = JSON.parse(response.data)
                let rItemRequerimiento: Array<IITemRequerimiento | any> = jParse.ItemRequerimiento ? jParse.ItemRequerimiento : []
                let rPaginacion: any = jParse.Paginacion
                switch(Estado){
                    case 'Pendiente': 
                        this.setState({Pendiente: {Lista: this.state.Pendiente.Lista.concat(rItemRequerimiento),Paginacion: rPaginacion,Loading: false}})
                        break
                    case 'EnCurso' : 
                        this.setState({EnCurso: {Lista: this.state.EnCurso.Lista.concat(rItemRequerimiento),Paginacion: rPaginacion,Loading: false}})
                        break 
                    case 'Hecho' : 
                        this.setState({Hecho: {Lista: this.state.Hecho.Lista.concat(rItemRequerimiento),Paginacion: rPaginacion,Loading: false}})                
                        break
                    case 'Pruebas':
                        this.setState({Pruebas: {Lista: this.state.Pruebas.Lista.concat(rItemRequerimiento),Paginacion: rPaginacion,Loading: false}})                
                        break
                    default:
                        break
                }
            }else{
                alert(response.data)
            }
        })
        .catch( (error) => {
            alert(error)
        })
    }

    HandleChangeRequerimiento = (Event: React.ChangeEvent<HTMLInputElement>) => { 
        const {name, value} : any= Event.target
        switch(name){
            case 'ImplementacionItemBackLog':
                this.setState({ 
                    Requerimiento: {
                        ...this.state.Requerimiento,
                        ImplementacionItemBackLog: {
                            PKID: value.code,
                            Nombre: value.name
                        },
                        Requisito: value.name
                    }
                })
                break
            case 'Estado':
                this.setState({ 
                    Requerimiento: {
                        ...this.state.Requerimiento,
                        Estado: {
                            PKID: value.code,
                            Descripcion: value.name
                        }
                    }
                })
                break
            case 'Solicitud':
                if(value.code === 0){
                    this.setState({ 
                        Requerimiento: {
                            ...this.state.Requerimiento,
                            Solicitud: {
                                PKID: 0,
                                Nombre: ''
                            }
                        }
                    });
                    return
                }
        
                let solis: any = this.state.ListaSolicitudesDatos
                let solicitEncontrada = {
                    ...this.state.Requerimiento.Solicitud,
                    PKID: value.code,
                    Nombre: value.name,
                }
        
                Object.keys(solis).forEach(function(key: any){
                    if(solis[key].PKID === value.code){
                        solicitEncontrada= {
                            ...solicitEncontrada,
                            Empleado: solis[key].Empleado
                        }
                    }
                })
        
                this.setState({ 
                    Requerimiento: {
                        ...this.state.Requerimiento,
                        Solicitud: solicitEncontrada
                    }
                })
                break
            default : 
                this.setState({
                    Requerimiento: {
                        ...this.state.Requerimiento,
                        [name]: value
                    }
                })
                break
        }
    }

    Paginacion = (Estado: string) => {
        if(
            (
                {
                    'Pendiente': ((this.state.Pendiente.Paginacion.NumeroPagina < this.state.Pendiente.Paginacion.NumeroPaginas) && !this.state.Pendiente.Loading),
                    'EnCurso' : ((this.state.EnCurso.Paginacion.NumeroPagina < this.state.EnCurso.Paginacion.NumeroPaginas) && !this.state.EnCurso.Loading),
                    'Hecho' : ((this.state.Hecho.Paginacion.NumeroPagina < this.state.Hecho.Paginacion.NumeroPaginas) && !this.state.Hecho.Loading),
                    'Pruebas' : ((this.state.Pruebas.Paginacion.NumeroPagina < this.state.Pruebas.Paginacion.NumeroPaginas) && !this.state.Pruebas.Loading)
                }[Estado]
            )){
                switch(Estado){
                    case 'Pendiente':
                        this.setState({
                            Pendiente: {
                                ...this.state.Pendiente,
                                Paginacion:{
                                    ...this.state.Pendiente.Paginacion,
                                    NumeroPagina: this.state.Pendiente.Paginacion.NumeroPagina + 1},
                                Loading: true
                            }
                        },() => this.ListarItemRequerimiento('Pendiente'))
                        break
                        case 'EnCurso' : 
                            this.setState({
                                EnCurso: {
                                    ...this.state.EnCurso
                                    ,Paginacion:{
                                        ...this.state.EnCurso.Paginacion,
                                        NumeroPagina: this.state.EnCurso.Paginacion.NumeroPagina + 1},
                                    Loading: true
                                }
                            },() => this.ListarItemRequerimiento('EnCurso'))
                        break
                        case 'Pruebas':
                            this.setState({
                                Pruebas: {
                                    ...this.state.Pruebas
                                    ,Paginacion:{
                                        ...this.state.Pruebas.Paginacion,
                                        NumeroPagina: this.state.Pruebas.Paginacion.NumeroPagina + 1},
                                    Loading: true
                                }
                            },() => this.ListarItemRequerimiento('Pruebas'))
                            break;
                        case 'Hecho' : 
                            this.setState({
                                Hecho: {
                                    ...this.state.Hecho,
                                    Paginacion:{
                                        ...this.state.Hecho.Paginacion,
                                        NumeroPagina: this.state.Hecho.Paginacion.NumeroPagina + 1},
                                    Loading: true
                                }
                            },() => this.ListarItemRequerimiento('Hecho'))
                        break
                    default :
                        break
                }
        }
    }

    HandleResponse = (Entidad: any) => {
        if(Entidad){
            /*
            console.log("SELECT * FROM ItemRequerimiento WHERE PKID="+Entidad.PKID)
            console.log("Descripcion : "+Entidad.Descripcion)
            console.log("ContextoEstandar : "+Entidad.ContextoEstandar)
            console.log("ProblemaEstandar : "+Entidad.ProblemaEstandar)
            */
            console.log(Entidad)
            this.setState({
                Mode : true,
                MostrarTareas : true,
                ItemRequerimiento : Entidad,
                ErroresItemRequerimiento: {}
            },() => {
                (document.getElementById('PanelItemRequerimiento') as HTMLInputElement).classList.add('active');
                (document.getElementById('PopupItemRequerimiento') as HTMLInputElement).classList.add('active');
            })
        }else{
            if(JSON.parse(getRequerimiento() || '{}').Estado.PKID !== 3){
                this.setState({
                    Mode : false,
                    ItemRequerimiento: this.cItemRequerimiento,
                    ErroresItemRequerimiento: {}
                },() => {
                    (document.getElementById('PanelItemRequerimiento') as HTMLInputElement).classList.add('active');
                    (document.getElementById('PopupItemRequerimiento') as HTMLInputElement).classList.add('active');
                })
            }else{
                alert('No se puede crear nuevos items requerimientos')
            }
        }
    }

    SubirArchivo = (Event: any, FileUpload: any, Name: string) =>  {
        const file = Event.files[0]
        const reader: any = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
            let encoded = reader.result.toString().replace(/^data:(.*,)?/, '')
            if ((encoded.length % 4) > 0) {
                encoded += '='.repeat(4 - (encoded.length % 4));
            }
            var oArchivoAdjunto = {
                PKID : 0,
                Nombre : file.name.replace(/[.][^.]+$/, ''),
                FechaCreacion : '',
                Tamagno : file.size,
                Archivo : encoded,
                Extension : file.name.slice((file.name.lastIndexOf(".") - 1 >>> 0) + 2)
            }
            switch(Name){
                case 'ArchivosAdjuntos':
                    this.setState({
                        Requerimiento :{
                            ...this.state.Requerimiento,
                            ArchivosAdjuntos:[
                                ...this.state.Requerimiento.ArchivosAdjuntos,
                                oArchivoAdjunto
                            ]
                        }
                    })
                    break
                case 'ArchivosSolucionAdjuntos':
                    this.setState({
                        Requerimiento :{
                            ...this.state.Requerimiento,
                            ArchivosSolucionAdjuntos:[
                                ...this.state.Requerimiento.ArchivosSolucionAdjuntos,
                                oArchivoAdjunto
                            ]
                        }
                    })
                    break
                default:
                    break
            }
            
        }
        reader.onerror = (Error: any) => {
            alert(Error)
        }
        FileUpload.current.files = ''
        FileUpload.current.state.files = ''
    }

    ItemRequerimientoSubirArchivo = (Event: any, FileUpload: any, Name: string) =>  {
        const file = Event.files[0]
        const reader: any = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
            let encoded = reader.result.toString().replace(/^data:(.*,)?/, '')
            if ((encoded.length % 4) > 0) {
                encoded += '='.repeat(4 - (encoded.length % 4));
            }
            var oArchivoAdjunto = {
                PKID : 0,
                Nombre : file.name.replace(/[.][^.]+$/, ''),
                FechaCreacion : '',
                Tamagno : file.size,//parseFloat(file.size / Math.pow(1024,2)).toFixed(2), //De Bytes a MegaBytes
                Archivo : encoded,
                Extension : file.name.slice((file.name.lastIndexOf(".") - 1 >>> 0) + 2)
            }
            switch(Name){
                case 'ArchivosAdjuntos':
                    this.setState({
                        ItemRequerimiento :{
                            ...this.state.ItemRequerimiento,
                            ArchivosAdjuntos:[
                                ...this.state.ItemRequerimiento.ArchivosAdjuntos,
                                oArchivoAdjunto
                            ]
                        }
                    })
                    break
                case 'ArchivosSolucionAdjuntos':
                    this.setState({
                        ItemRequerimiento :{
                            ...this.state.ItemRequerimiento,
                            ArchivosSolucionAdjuntos:[
                                ...this.state.ItemRequerimiento.ArchivosSolucionAdjuntos,
                                oArchivoAdjunto
                            ]
                        }
                    })
                    break
            }
            
        }
        reader.onerror = (Error: any) => {
            alert(Error)
        }
        FileUpload.current.files = ''
        FileUpload.current.state.files = ''
    }

    BorrarArchivo = (Event: any, Name: string) => {
        let lArchivos: any = {
            'ArchivosAdjuntos': this.state.Requerimiento.ArchivosAdjuntos,
            'ArchivosSolucionAdjuntos': this.state.Requerimiento.ArchivosSolucionAdjuntos
        }[Name]
        if(lArchivos.filter( (e: any) => { return e === Event}).length > 0){
            lArchivos = lArchivos.filter( (e: any) => { return e !== Event})
        }
        this.setState({
            Requerimiento :{
                ...this.state.Requerimiento,
                [Name] : lArchivos
            }
        })
    }

    ItemRequerimientoBorrarArchivo = (Event: any, Name: string) => {
        let lArchivos: any = {
            'ArchivosAdjuntos': this.state.ItemRequerimiento.ArchivosAdjuntos,
            'ArchivosSolucionAdjuntos': this.state.ItemRequerimiento.ArchivosSolucionAdjuntos
        }[Name]
        if(lArchivos.filter( (e: any) => { return e === Event}).length > 0){
            lArchivos = lArchivos.filter( (e: any) => { return e !== Event})
        }
        this.setState({
            ItemRequerimiento :{
                ...this.state.ItemRequerimiento,
                [Name] : lArchivos
            }
        })
    }
    
    DescargarArchivo = (Event: any, Name: string) => {
        let lArchivos: any = {
            'ArchivosAdjuntos': this.state.Requerimiento.ArchivosAdjuntos,
            'ArchivosSolucionAdjuntos': this.state.Requerimiento.ArchivosSolucionAdjuntos
        }[Name]
        if(lArchivos.filter( (e: any) => { return e === Event}).length > 0){
            lArchivos = lArchivos.filter( (e: any) => { return e === Event})
            const oArchivos = lArchivos[0]
            RequerimientoService.DescargarArchivo(oArchivos)
        }else{
            alert('Error: No se encontro el archivo indicado')
        }
    }

    ItemRequerimientoDescargarArchivo = (Event: any, Name: string) => {
        let lArchivos: any = {
            'ArchivosAdjuntos': this.state.ItemRequerimiento.ArchivosAdjuntos,
            'ArchivosSolucionAdjuntos': this.state.ItemRequerimiento.ArchivosSolucionAdjuntos
        }[Name]
        if(lArchivos.filter( (e: any) => { return e === Event}).length > 0){
            lArchivos = lArchivos.filter( (e: any) => { return e === Event})
            const oArchivos = lArchivos[0]
            RequerimientoService.DescargarArchivo(oArchivos)
        }else{
            alert('Error: No se encontro el archivo indicado')
        }
    }

    HandleSubmitRequerimiento = (Event: any) => {
    
		console.log("Requerimiento.FechaInicio : "+this.state.Requerimiento.FechaInicio)
        console.log("Requerimiento.FechaFin : "+this.state.Requerimiento.FechaFin)

        console.log("Requerimiento.FechaInicio.getHours() : "+this.state.ItemRequerimiento.FechaInicio.getHours())
        console.log("Requerimiento.FechaFin.getHours() : "+this.state.ItemRequerimiento.FechaFin.getHours())

        console.log("Objeto Requerimiento : "+ this.state.Requerimiento)
        console.log("Objeto ItemRequerimiento: "+ this.state.ItemRequerimiento)
        
        Event.preventDefault()
        const result = RequerimientoService.ValidarSchema(this.state.Requerimiento)
        if(Object.keys(result).length){
            return this.setState({Errores: result});
        }else{
            this.setState({
                Request: true
            }, () => this.CrearActualizarRequerimiento())
        }
    }

    CrearActualizarRequerimiento(){
        RequerimientoService.CrearActualizar(this.state.Requerimiento, JSON.parse(getProyecto() || '{}'))
        .then( (response: any) => {
            console.log("CrearActualizarRequerimiento desde la clase itemRequerimiento respuesta  :"+response)
            if(response.success){
                this.setState({
                    Pendiente: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 1}},
                    EnCurso: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 2}},
                    Pruebas: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 4}},
                    Hecho: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 3}},
                    Errores : {},
                },()=>{
                    (document.getElementById('PanelRequerimiento') as HTMLInputElement).classList.remove('active');
                    (document.getElementById('PopupRequerimiento') as HTMLInputElement).classList.remove('active');
                    this.ConsultarRequerimiento()
                })
            }else{
                alert(response.data.data)
            }
        })
        .catch( (error) => {
            alert(error)
        })
        .finally( () => {
            this.setState({Request : false})
        })
    }

    MoverEstado = ( EstadoI : string, EstadoF : string, PKID : string) => {
        if(EstadoI !== EstadoF){
            let oLista = {'Pendiente': this.state.Pendiente.Lista,'EnCurso' : this.state.EnCurso.Lista,'Hecho' : this.state.Hecho.Lista, 'Pruebas' : this.state.Pruebas.Lista}[EstadoI] || []
            switch(EstadoF){
                case 'Pendiente' :
                    this.setState({
                        ItemRequerimiento: ItemRequerimientoService.ObtenerObjetoEstado(oLista,PKID,1)
                    },() => this.CrearActualizarItemRequerimiento())
                    break
                case 'EnCurso' :
                    this.setState({
                        ItemRequerimiento: ItemRequerimientoService.ObtenerObjetoEstado(oLista,PKID,2)
                    },() => this.CrearActualizarItemRequerimiento())
                    break
                case 'Hecho' :
                    this.setState({
                        ItemRequerimiento: ItemRequerimientoService.ObtenerObjetoEstado(oLista,PKID,3)
                    },() => this.CrearActualizarItemRequerimiento())
                    break
                case 'Pruebas' :
                    this.setState({
                        ItemRequerimiento: ItemRequerimientoService.ObtenerObjetoEstado(oLista,PKID,4)
                    },() => this.CrearActualizarItemRequerimiento())
                    break
                default:
                    break
            }
        }
    }

    HandleSubmitItemRequerimiento = (Event: any) => {
        Event.preventDefault();
        const result = ItemRequerimientoService.ValidarSchema(this.state.ItemRequerimiento)
        if(Object.keys(result).length){
            return this.setState({ErroresItemRequerimiento: result});
        }else{
            this.setState({
                Request : true
            }, () => this.CrearActualizarItemRequerimiento())
        }
    }

    CrearActualizarItemRequerimiento(){
        ItemRequerimientoService.CrearActualizar(this.state.ItemRequerimiento, JSON.parse(getRequerimiento() || '{}'))
        .then( (response: any) => {
            if(response.success){ 
                this.setState({
                    Pendiente: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 1}},
                    EnCurso: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 2}},
                    Pruebas: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 4}},
                    Hecho: {...this.cPaginacion, Paginacion:{...this.cPaginacion.Paginacion,IDEstado: 3}},
                    ItemRequerimiento: this.cItemRequerimiento,
                    ErroresItemRequerimiento : {}
                },()=>{
                    (document.getElementById('PanelItemRequerimiento') as HTMLInputElement).classList.remove('active');
                    (document.getElementById('PopupItemRequerimiento') as HTMLInputElement).classList.remove('active');
                    this.ConsultarRequerimiento()
                })
            }else{
                alert(response.data)
            }
        })
        .catch( (error) => {
            alert(error)
        })
        .finally( () => {
            this.setState({Request : false})
        })
    }
    
    HandleChangeItemRequerimiento = (Event: React.ChangeEvent<HTMLInputElement>) => { 
        const {name, value} : any= Event.target
        switch(name){
            case 'Estado':
                this.setState({ 
                    ItemRequerimiento: {
                        ...this.state.ItemRequerimiento,
                        Estado: {
                            PKID: value.code,
                            Descripcion: value.name
                        }
                    }
                })
                break
            case 'Solicitud':
                if(value.code === 0){
                    this.setState({ 
                        ItemRequerimiento: {
                            ...this.state.ItemRequerimiento,
                            Solicitud: {
                                PKID: 0,
                                Nombre: ''
                            }
                        }
                    });
                    return
                }
        
                let solis: any = this.state.ListaSolicitudesDatos
                let solicitEncontrada = {
                    ...this.state.ItemRequerimiento.Solicitud,
                    PKID: value.code,
                    Nombre: value.name,
                }
        
                Object.keys(solis).forEach(function(key: any){
                    if(solis[key].PKID === value.code){
                        solicitEncontrada= {
                            ...solicitEncontrada,
                            Empleado: solis[key].Empleado
                        }
                    }
                })
        
                this.setState({ 
                    ItemRequerimiento: {
                        ...this.state.ItemRequerimiento,
                        Solicitud: solicitEncontrada
                    }
                })
                break
            default : 
                this.setState({
                    ItemRequerimiento: {
                        ...this.state.ItemRequerimiento,
                        [name]: value
                    }
                })
                break
        }
    }

    render(){
        return(
            <div className="container">
                <ItemRequerimientoHeader/>
                <ItemRequerimientoBody 
                    Paginacion = {this.Paginacion}
                    Pendiente = {this.state.Pendiente}
                    EnCurso = {this.state.EnCurso}
                    Pruebas = {this.state.Pruebas}
                    Hecho = {this.state.Hecho}
                    OnResponse = {this.HandleResponse}
                    MoverEstado = {this.MoverEstado}/>
                <RequerimientoModal
                    Mode = {true}
                    OnSubmit = {this.HandleSubmitRequerimiento}
                    Solicitudes={this.state.ListaSolicitudes}
                    ItemBackLogs={this.state.ListaItemBackLogs}
                    HandleChange = {this.HandleChangeRequerimiento}
                    FormValues = {this.state.Requerimiento}
                    Errores = {this.state.Errores}
                    Request = {this.state.Request}
                    SubirArchivo = {this.SubirArchivo}
                    BorrarArchivo = {this.BorrarArchivo}
                    DescargarArchivo = {this.DescargarArchivo}/>
                <ItemRequerimientoModal
                    MostrarTareas = {this.state.MostrarTareas}
                    Mode = {this.state.Mode}
                    OnSubmit = {this.HandleSubmitItemRequerimiento}
                    Solicitudes={this.state.ListaSolicitudes}
                    ItemBackLogs={this.state.ListaItemBackLogs}
                    HandleChange = {this.HandleChangeItemRequerimiento}                    
                    FormValues = {this.state.ItemRequerimiento}
                    SubirArchivo = {this.ItemRequerimientoSubirArchivo}
                    BorrarArchivo = {this.ItemRequerimientoBorrarArchivo}
                    DescargarArchivo = {this.ItemRequerimientoDescargarArchivo}
                    Errores = {this.state.ErroresItemRequerimiento}
                    Request = {this.state.Request}/>
            </div>
            
        )
    }
}