import React  from 'react'
import { InputText } from 'primereact/inputtext'
import { Calendar } from 'primereact/calendar'
import { InputTextarea } from 'primereact/inputtextarea'
import { Dropdown } from 'primereact/dropdown'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { FileUpload } from 'primereact/fileupload'
import { Editor } from 'primereact/editor'
import { Button } from 'primereact/button'
import { Local } from './LocaleSettings'

interface IProps{
    HandleChange: any
    SubirArchivo: any
    BorrarArchivo: any
    DescargarArchivo: any
    FormValues: any
    Errores: any
    Solicitudes: any
    MostrarTareas: any
    ImageArchivo: any
    FechaCreacion: any
    TamañoArchivo: any
    ItemBackLogs: any
}

export default function TabRequerimiento(Props: IProps){

    const File = React.createRef<FileUpload>()

    const header = (
        <>
            <span className="ql-formats">
                <button className="ql-bold" aria-label="Bold"></button>
                <button className="ql-italic" aria-label="Italic"></button>
                <button className="ql-underline" aria-label="Underline"></button>
            </span>
            <span className="ql-formats">
                <button type="button" className="ql-list" value="ordered" aria-label="Ordered List">
                    <svg viewBox="0 0 18 18"> 
                        <line className="ql-stroke" x1="7" x2="15" y1="4" y2="4"></line> 
                        <line className="ql-stroke" x1="7" x2="15" y1="9" y2="9"></line> 
                        <line className="ql-stroke" x1="7" x2="15" y1="14" y2="14"></line> 
                        <line className="ql-stroke ql-thin" x1="2.5" x2="4.5" y1="5.5" y2="5.5"></line> 
                        <path className="ql-fill" d="M3.5,6A0.5,0.5,0,0,1,3,5.5V3.085l-0.276.138A0.5,0.5,0,0,1,2.053,3c-0.124-.247-0.023-0.324.224-0.447l1-.5A0.5,0.5,0,0,1,4,2.5v3A0.5,0.5,0,0,1,3.5,6Z"></path> 
                        <path className="ql-stroke ql-thin" d="M4.5,10.5h-2c0-.234,1.85-1.076,1.85-2.234A0.959,0.959,0,0,0,2.5,8.156"></path> 
                        <path className="ql-stroke ql-thin" d="M2.5,14.846a0.959,0.959,0,0,0,1.85-.109A0.7,0.7,0,0,0,3.75,14a0.688,0.688,0,0,0,.6-0.736,0.959,0.959,0,0,0-1.85-.109"></path> 
                    </svg>
                </button>
                <button type="button" className="ql-list" value="bullet" aria-label="Unordered List">
                    <svg viewBox="0 0 18 18"> 
                        <line className="ql-stroke" x1="6" x2="15" y1="4" y2="4"></line> 
                        <line className="ql-stroke" x1="6" x2="15" y1="9" y2="9"></line> 
                        <line className="ql-stroke" x1="6" x2="15" y1="14" y2="14"></line> 
                        <line className="ql-stroke" x1="3" x2="3" y1="4" y2="4"></line> 
                        <line className="ql-stroke" x1="3" x2="3" y1="9" y2="9"></line> 
                        <line className="ql-stroke" x1="3" x2="3" y1="14" y2="14"></line> 
                    </svg>
                </button>
            </span>
        </>
    );

    const EditorContextoEstandar = (Event: any) => {
        Props.HandleChange({
            target:{
                name: "ContextoEstandar",
                value: Event.htmlValue?Event.htmlValue.replace('<span ','<div ').replace('</span>','</div>'):''
            }
        })
    }

    const EditorProblemaEstandar = (Event: any) => {
        Props.HandleChange({
            target:{
                name:"ProblemaEstandar",
                value: Event.htmlValue
            }
        })
    }

    const EditorDescripcion = (Event: any) => {
        Props.HandleChange({
            target:{
                name: "Descripcion",
                value: Event.htmlValue?Event.htmlValue.replace('<span ','<div ').replace('</span>','</div>'):''
            }
        })
    }

    const Header = (
        <div className="p-d-flex p-jc-between">
            <FileUpload 
                ref={File}
                mode="basic" 
                name="ArchivosAdjuntos" 
                auto 
                uploadHandler={(Event) => Props.SubirArchivo(Event, File, 'ArchivosAdjuntos')}
                customUpload
                className="p-ml-auto"
                chooseLabel="Buscar archivo"/>
        </div>
    )

    const ConvertirHoras = (Event: any) => {
        return(
            (Math.round(Event.Horas * 100) / 100).toFixed(2)
        )
    }

    const OperacionArchivo = (Event : any) => {
        return(
            <React.Fragment>
                <div className="p-grid p-fluid ">
                    <div className="p-col-6">
                        <Button 
                            type="button"
                            icon="pi pi-times" 
                            style={{width:'30px', height:'30px'}}
                            className="p-button-rounded p-button-outlined"
                            onClick={ () => { 
                                Props.BorrarArchivo(Event, 'ArchivosAdjuntos')
                            }}/>
                    </div>
                    <div className="p-col-6">
                        <Button 
                            type="button"
                            icon="pi pi-download" 
                            style={{width:'30px', height:'30px'}}
                            className="p-button-rounded p-button-outlined"
                            onClick={ () => { 
                                Props.DescargarArchivo(Event, 'ArchivosAdjuntos')
                            }}/>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    return(
        <div className="p-grid nested-grid">
            <div className="p-col-12 p-lg-7">
                <div className="p-col">
                    <div className="p-grid p-fluid ">
                        <div className="p-col-12">
                            <label 
                                style={{
                                    color: '#4D80E4',
                                    fontFamily: 'Sarala',
                                    fontSize: '20px'}}
                                >Requisito</label>
                            <InputTextarea 
                                value={(Props.FormValues !== null && Props.FormValues !== undefined)?((Props.FormValues.Requisito !== null && Props.FormValues.Requisito !== undefined)? Props.FormValues.Requisito:''):''}
                                onChange={Props.HandleChange}
                                name="Requisito"
                                type="text" 
                                rows={3} 
                                cols={10}/>
                            {Props.Errores.Requisito && <small className="p-invalid p-d-block">{Props.Errores.Requisito}</small>}
                        </div>
                    </div>
                </div>
                <div className="p-col">
                    <div className="p-grid p-fluid ">
                        <div className="p-col-12">
                            <label 
                                style={{
                                    color: '#4D80E4',
                                    fontFamily: 'Sarala',
                                    fontSize: '20px'}}
                                >Descripción</label>
                                 <Editor 
                                    style={{height:'120px'}} 
                                    value={Props.FormValues.Descripcion ? Props.FormValues.Descripcion:''} 
                                    onTextChange={EditorDescripcion}
                                    headerTemplate={header}/>        
                            {Props.Errores.Descripcion && <small className="p-invalid p-d-block">{Props.Errores.Descripcion}</small>}
                        </div>
                    </div>
                </div>
                <div className="p-col">
                    <div className="p-grid p-fluid">
                        <div className="p-col-12 p-md-6">
                            <label 
                                style={{
                                    color: '#4D80E4',
                                    fontFamily: 'Sarala',
                                    fontSize: '20px'}}
                            >Horas Estimadas</label>
                            <InputText 
                                value={Props.FormValues.Estimacion ? Props.FormValues.Estimacion:0}
                                onChange={Props.HandleChange}
                                name="Estimacion"
                                type="number" 
                                min="0"/>
                            {Props.Errores.HorasEstimadas && <small className="p-invalid p-d-block">{Props.Errores.HorasEstimadas}</small>}
                        </div>
                        <div className="p-col-12 p-md-6">
                            <label 
                                style={{
                                    color: '#4D80E4',
                                    fontFamily: 'Sarala',
                                    fontSize: '20px'}}
                            >Factor Ajuste</label>
                            <InputText 
                                value={Props.FormValues.FactorAjuste ? Props.FormValues.FactorAjuste : ''}
                                onChange={Props.HandleChange}
                                name="FactorAjuste"
                                type="number" 
                                min="1"/>
                            {Props.Errores.FactorAjuste && <small className="p-invalid p-d-block">{Props.Errores.FactorAjuste}</small>}
                        </div>
                    </div>
                </div>
                <div className="p-col">
                    <div className="p-grid p-fluid">
                        <div className="p-col-12 p-md-6">
                            <label 
                                style={{
                                    color: '#4D80E4',
                                    fontFamily: 'Sarala',
                                    fontSize: '20px'}}
                            >Horas Planificadas</label>
                            <InputText 
                                value={(Props.FormValues.Estimacion>=0 && Props.FormValues.FactorAjuste>=0)?(Props.FormValues.EstimacionAjustada = Props.FormValues.Estimacion * Props.FormValues.FactorAjuste):''}
                                onChange={Props.HandleChange}
                                name="EstimacionAjustada"
                                disabled
                                type="number"/>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <label 
                                style={{
                                    color: '#4D80E4',
                                    fontFamily: 'Sarala',
                                    fontSize: '20px'}}
                            >Horas Ejecutadas</label>
                            <InputText 
                                value={(Props.FormValues.HorasEjecutadas>=0)? (Math.round(Props.FormValues.HorasEjecutadas * 100) / 100).toFixed(2):''}
                                onChange={Props.HandleChange}
                                name="HorasEjecutadas"
                                disabled
                                type="number"/>
                        </div>
                    </div>
                </div>
                <div className="p-col">
                    <div className="p-grid p-fluid">
                        <div className="p-col-12 p-md-6">
                            <label 
                                style={{
                                    color: '#4D80E4',
                                    fontFamily: 'Sarala',
                                    fontSize: '20px'}}>
                            Fecha Inicio</label>
                            <Calendar 
                                value={Props.FormValues.FechaInicio? new Date(Props.FormValues.FechaInicio): new Date()}                                                        
                                onChange={Props.HandleChange}
                                name="FechaInicio"
                                locale={Local} 
                                dateFormat="dd/mm/yy"/>
                            {Props.Errores.FechaInicio && <small className="p-invalid p-d-block">{Props.Errores.FechaInicio}</small>}
                        </div>
                        <div className="p-col-12 p-md-6">
                            <label 
                                style={{
                                    color: '#4D80E4',
                                    fontFamily: 'Sarala',
                                    fontSize: '20px'}}>
                            Fecha Fin</label>
                            <Calendar 
                                value={Props.FormValues.FechaFin? new Date(Props.FormValues.FechaFin) : new Date()}                                                        
                                onChange={Props.HandleChange}
                                name="FechaFin"
                                locale={Local} 
                                dateFormat="dd/mm/yy"/>
                            {Props.Errores.FechaFin && <small className="p-invalid p-d-block">{Props.Errores.FechaFin}</small>}
                        </div>
                    </div>
                </div>
                <div className="p-col">
                    <div className="p-grid p-fluid">
                        <div className="p-col-12">
                            <label 
                                style={{
                                    color: '#4D80E4',
                                    fontFamily: 'Sarala',
                                    fontSize: '20px'}}>
                            Solicitud</label>
                            <Dropdown 
                                value={{
                                    code: Props.FormValues.Solicitud? Props.FormValues.Solicitud.PKID : '',
                                    name: Props.FormValues.Solicitud? Props.FormValues.Solicitud.Nombre : ''}}
                                options={Props.Solicitudes}
                                onChange={Props.HandleChange}
                                optionLabel="name"
                                name="Solicitud"
                                placeholder="Seleccione la Solicitud"/>
                        </div>
                    </div>
                </div>
                <div className="p-col">
                    <div className="p-grid p-fluid">
                        <div className="p-col-12">
                            <label 
                                style={{
                                    color: '#4D80E4',
                                    fontFamily: 'Sarala',
                                    fontSize: '20px'}}>
                            ItemBackLog</label>
                            <Dropdown 
                                value={{
                                    code: Props.FormValues.ImplementacionItemBackLog? Props.FormValues.ImplementacionItemBackLog.PKID : '',
                                    name: Props.FormValues.ImplementacionItemBackLog? Props.FormValues.ImplementacionItemBackLog.Nombre : ''}}
                                options={Props.ItemBackLogs}
                                onChange={Props.HandleChange}
                                optionLabel="name"
                                name="ImplementacionItemBackLog"
                                placeholder="Seleccione el ItemBackLog"/>
                        </div>
                    </div>
                </div>
                    <div className="p-col">
                        <div className="p-grid p-fluid">
                            <div className="p-col-12 p-md-6">
                                <label 
                                    style={{
                                        color: '#4D80E4',
                                        fontFamily: 'Sarala',
                                        fontSize: '20px'}}
                                >Responsable</label>
                                <InputText 
                                    value={Props.FormValues?(Props.FormValues.Responsable?Props.FormValues.Responsable.Nombre:''):''}
                                    name="Responsable"
                                    disabled
                                    type="text"/>
                            </div>
                            <div className="p-col-12 p-md-6">
                                <label 
                                    style={{
                                        color: '#4D80E4',
                                        fontFamily: 'Sarala',
                                        fontSize: '20px'}}
                                >Colaborador</label>
                                <InputText 
                                    value={Props.FormValues.Solicitud?(Props.FormValues.Solicitud.Empleado?Props.FormValues.Solicitud.Empleado.Nombre:''):''}
                                    name="Colaborador"
                                    disabled
                                    type="text"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-col-12 p-lg-5">
                    <div className="p-col">
                        <div className="p-grid p-fluid ">
                            <div className="p-col-12">
                                <label 
                                    style={{
                                        color: '#4D80E4',
                                        fontFamily: 'Sarala',
                                        fontSize: '20px'}}
                                >Contexto Estandar</label>
                                <Editor 
                                    style={{height:'120px'}} 
                                    value={Props.FormValues.ContextoEstandar ? Props.FormValues.ContextoEstandar:''} 
                                    onTextChange={EditorContextoEstandar}
                                    headerTemplate={header}/>
                            </div>
                        </div>
                    <div className="p-grid p-fluid ">
                        <div className="p-col-12">
                            <label 
                                style={{
                                    color: '#4D80E4',
                                    fontFamily: 'Sarala',
                                    fontSize: '20px'}}
                            >Problema Estandar</label>
                            <Editor 
                                style={{height:'120px'}} 
                                value={Props.FormValues.ProblemaEstandar ? Props.FormValues.ProblemaEstandar:''} 
                                onTextChange={EditorProblemaEstandar}
                                headerTemplate={header}/>
                        </div>
                    </div>
                </div>
            </div>
            {Props.MostrarTareas ? 
                <div>
                    <div className="p-grid p-fluid">
                        <div className="p-col-12">
                            <DataTable 
                                value={Props.FormValues.TareaEjecutada}
                                paginator
                                rows={5}
                                className="p-datatable-sm"
                                resizableColumns 
                                columnResizeMode="fit">
                                <Column field="Nombre" header="Nombre" style={{width:'40%'}}></Column>
                                <Column field="Empleado" header="Empleado" style={{width:'20%'}}></Column>
                                <Column field="Sprint" header="Sprint" style={{width:'25%'}}></Column>
                                <Column body={ConvertirHoras} header="Horas" style={{width:'15%'}}></Column>
                            </DataTable>
                        </div>
                    </div>
                </div>
            :
            <></>
            }
            <div className="p-col-12">
                <div className="p-grid p-fluid">
                    <div className="p-col-12">
                        <DataTable 
                            value={Props.FormValues.ArchivosAdjuntos}
                            paginator
                            rows={5}
                            header={Header}
                            className="p-datatable-sm">
                            <Column body={Props.ImageArchivo} header="T. de archivo"></Column>
                            <Column field="Nombre" header="Nombre"></Column>
                            <Column body={Props.FechaCreacion} header="Fecha creación"/>
                            <Column body={Props.TamañoArchivo} header="Tamaño"></Column>
                            <Column body={OperacionArchivo} header="Operación"></Column>
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
    )
}