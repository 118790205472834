import React from 'react'
import InicioHeader from '../Components/InicioHeader'
import InicioBody from '../Components/InicioBody'
import ModalCambioContraseña from '../Components/ModalCambioContraseña'

import { ICambioContraseña } from '../../Domain/Models/ICambioContraseña'
import { IUsuario } from '../../Domain/Models/IUsuario'
import { IToken } from '../../Domain/Models/IToken'

import { UsuarioService } from '../../Domain/Services/UsuarioService'
import { EliminarTodo} from '../../Services/LocalStorage'
import { AuthHelper } from '../../Domain/Utils/AuthHelper'
import ModalCambioFoto from '../Components/ModalCambioFoto'
import { EmpleadoDTO } from '../../Domain/Models/EmpleadoDTO'
import { EmpleadoService } from '../../Domain/Services/EmpleadoService'


interface IProps {
    Token: IToken
}

interface IState {
    CambioContraseña: ICambioContraseña
    Modal: boolean
    ModalFoto:boolean
    Request: boolean
    Usuario: IUsuario
    Empleado: EmpleadoDTO
    base64Image : string
}

export default class Inicio  extends React.Component<IProps, IState> {

    cCambioContraseña = {
        IDUsuario: parseInt(AuthHelper.GetDecoded().sub,10),
        AntiguaContrasena: '',
        NuevaContrasena: '',
        RepetirNuevaContrasena: ''
    } 

    cUsuario = {
        PKID: 0,
        Credencial: '',
        IsNuevo: false,
        Administrador: false,
        AdministradorArea: false,
        Empleado:{
            PKID: 0,
            Nombre: '',
            Codigo: '',
            Area:{
                PKID: 0,
                Codigo: '',
                Nombre: ''
            },
            CorreoElectronico: ''
        }
    }

    cEmpleado = {
        PKID: 0,
        Nombre: '',
        Codigo: '',
        Area:{
            PKID: 0,
            Codigo: '',
            Nombre: ''
        },
        CorreoElectronico: '',
        Foto: ''
    }

    constructor(Props: any){
        super(Props);
        this.state = {
            CambioContraseña : this.cCambioContraseña,
            Modal: false,
            ModalFoto: false,
            Request: false,
            Usuario: this.cUsuario,
            Empleado: this.cEmpleado,
            base64Image : ''
        }
    }

    componentDidMount(){
        this.ConsultarUsuario()
    }
    
    ConsultarUsuario(){
        UsuarioService.Consultar(parseInt(AuthHelper.GetDecoded().sub,10))
        .then( (response: any) => {
            if (response.success) {
                this.setState({Usuario : JSON.parse(response.data)})
                this.setState({Empleado : JSON.parse(response.data).Empleado})
            }else{
                throw new Error(response.data)
            }
        })
        .catch( (Error) => {
            alert(Error)
        })
    }

    LimpiarContraseña = () => {
        this.setState({
            CambioContraseña: this.cCambioContraseña,
            Modal: false
        })
    }

    LimpiarFoto = () => {
        this.setState({
            ModalFoto: false
        })
    }


    HandleChangeContrasena = (Event: any) => {
        this.setState({
            CambioContraseña:{
                ...this.state.CambioContraseña,
                [Event.target.name]: Event.target.value
            }
        })
    }

    HandleChangeFoto = (Foto: String) => {
        console.log("fito:", Foto);
        let strFoto = "Foto"
        this.setState({
            Empleado:{
                ...this.state.Empleado,
                [strFoto]: Foto
            }
        })
        console.log("fito:", this.state.Empleado);
    }


    FileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        
        if (file) {
          const reader = new FileReader();
    
          reader.onload = (e) => {
            const contentBase64 = btoa(e.target?.result as string);
            this.setState({ base64Image: contentBase64 });
            this.HandleChangeFoto(contentBase64)

            console.log("Imagen convertida a base64:", e);
          };
    
          reader.readAsBinaryString(file);

        }
        

      };

      CambioFoto = (Event : React.ChangeEvent<HTMLFormElement>) => {
        Event.preventDefault()
        this.setState({
            Request: true
        }, () => {
            EmpleadoService.CrearActualizar(this.state.Empleado)
            .then((data : any) => {
                if(data.success){
                    alert(data.data)
                }else{
                    alert(data.data)
                }
            })
            .catch((error) => {
                alert(error)
            })
            .finally( () => {
                this.setState({Request: false})
                this.setState({ModalFoto: false})
            })
        })
    }


    CambioContraseña = (Event : React.ChangeEvent<HTMLFormElement>) => {
        Event.preventDefault()
        this.setState({
            Request: true
        }, () => {
            UsuarioService.CambiarContraseña(this.state.CambioContraseña)
            .then((data : any) => {
                if(data.success){
                    alert(data.data)
                    EliminarTodo()
                    window.location.reload()
                }else{
                    alert(data.data)
                }
            })
            .catch((error) => {
                alert(error)
            })
            .finally( () => {
                this.setState({Request: false})
            })
        })
    }

    render(){
        return(
            <div>
                <InicioHeader/>
                <InicioBody
                    FormValues={this.state.Usuario}
                    base64Image={this.state.base64Image}
                    Modal={() => this.setState({Modal: true})}
                    ModalFoto={() => this.setState({ModalFoto: true})}
                    convertbase64 = {this.FileChange}
                    Empleado={this.state.Empleado}/>
                <form autoComplete="off" onSubmit={this.CambioContraseña}>
                    <ModalCambioContraseña
                        Modal = {this.state.Modal}
                        HideModal = {this.LimpiarContraseña}
                        OnChangeContraseña = {this.HandleChangeContrasena}
                        CambioContraseña = {this.state.CambioContraseña}
                        Request = {this.state.Request}/>
                </form>
                <form autoComplete="off" onSubmit={this.CambioFoto}>
                    <ModalCambioFoto
                        convertbase64 = {this.FileChange}
                        Empleado={this.state.Empleado}
                        base64Image={this.state.base64Image}
                        Modal = {this.state.ModalFoto}
                        HideModal = {this.LimpiarFoto}
                        CambioFoto = {this.state.Empleado}
                        Request = {this.state.Request}/>
                </form>
            </div>
        )
    }
}