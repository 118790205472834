import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker'
import './index.css'
import './Infrastructure/Assets/Css/ListTrello.css'
import 'primereact/resources/themes/saga-blue/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'

ReactDOM.render(
    <App />
  ,
  document.getElementById('root')
);

serviceWorker.unregister();
