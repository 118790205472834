import React from 'react'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import { Calendar } from 'primereact/calendar'
import { Dropdown } from 'primereact/dropdown'
import { Button } from 'primereact/button'
import { Local } from './LocaleSettings'
import '../Assets/Css/PanelProyecto.css'
import { Editor } from 'primereact/editor'
import { Checkbox } from 'primereact/checkbox'

interface IProps{
    HandleChange: any
    PasFormValues: any
    FormValues: any
    Errores: any
    CrearVersion: any
    GuardarTemporal: any
    ListaProducto: Array<object>
    ListaImplementacionBackLog: Array<object>
    ListaMotivoVersionamiento: Array<object>
}

export default function RequerimientoProyectoDetalle(Props : IProps){

    const EditorDescripcion = (Event: any) => {
        Props.HandleChange({
            target:{
                name: "Descripcion",
                value: Event.htmlValue?Event.htmlValue.replace('<span ','<div ').replace('</span>','</div>'):''
            }
        })
    }

    return(
        <div id="PanelProyecto" className="overlay">
            <div className="popup" id="Popup">
                <div className="btn-cerrar-popup" onClick={() => 
                {
                    (document.getElementById('PanelProyecto') as HTMLInputElement).classList.remove('active');
                    (document.getElementById('Popup') as HTMLInputElement).classList.remove('active');
                }}
                ><i className="fas fa-times"></i></div>
                <form autoComplete="off">
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="p-grid p-fluid">
                                <div className="p-col">
                                    <label 
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '19px'}}>
                                    Nombre</label>
                                    <InputText 
                                        value={Props.FormValues.Nombre}
                                        onChange={Props.HandleChange}
                                        name="Nombre"
                                        disabled={Props.PasFormValues.Estado? (Props.PasFormValues.Estado.PKID === 3):false}
                                        type="text" 
                                        placeholder="Nombre"/>
                                    {Props.Errores.Nombre && <small className="p-invalid p-d-block">{Props.Errores.Nombre}</small>}
                                </div>
                            </div>
                            <div className="p-grid p-fluid">
                                <div className="p-col">
                                    <label 
                                        style={{
                                            color : '#4D80E4',
                                            fontFamily : 'Sarala',
                                            fontSize : '19px'}}>
                                    Descripcion</label>
                                   
                                        <Editor 
                                   style={{height:'120px'}} 
                                    value={Props.FormValues.Descripcion}
                                    onTextChange={EditorDescripcion}
                                    
                                    />
                                    {Props.Errores.Descripcion && <small className="p-invalid p-d-block">{Props.Errores.Descripcion}</small>}
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12">
                            <div className="p-grid p-fluid">
                                <div className="p-col">
                                    <label 
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '19px'}}>
                                    Horas</label>
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12 p-md-6 p-lg-4">
                            <div className="p-grid p-fluid">
                                <div className="p-col">
                                    <label 
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily : 'Sarala',
                                            fontSize : '19px'}}>
                                    Gerencia</label>
                                    <InputText 
                                        value={(Math.round(Props.FormValues.HorasGerencia * 100) / 100).toFixed(3)}
                                        onChange={Props.HandleChange}
                                        disabled={Props.PasFormValues.Estado? (Props.PasFormValues.Estado.PKID === 3):false}
                                        name="HorasGerencia"
                                        type="number" 
                                        min="0"
                                        step='0.00'
                                        placeholder="Horas Gerencia"/>
                                    {Props.Errores.HorasGerencia && <small className="p-invalid p-d-block">{Props.Errores.HorasGerencia}</small>}
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12 p-md-6 p-lg-4">    
                            <div className="p-grid p-fluid">
                                <div className="p-col">
                                    <label 
                                        style={{
                                            color : '#4D80E4',
                                            fontFamily : 'Sarala',
                                            fontSize : '19px'}}>
                                    Estimadas</label>
                                    <InputText 
                                        value={(Math.round(Props.FormValues.HorasEstimadas * 100) / 100).toFixed(3)}
                                        onChange={Props.HandleChange}
                                        disabled={Props.PasFormValues.Estado? (Props.PasFormValues.Estado.PKID === 3):false}
                                        name="HorasEstimadas"
                                        type="number" 
                                        min="0"
                                        placeholder="Horas Estimadas"/>
                                    {Props.Errores.HorasEstimadas && <small className="p-invalid p-d-block">{Props.Errores.HorasEstimadas}</small>}
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12 p-md-6 p-lg-4">   
                            <div className="p-grid p-fluid">
                                <div className="p-col">
                                    <label 
                                        style={{
                                            color : '#4D80E4',
                                            fontFamily : 'Sarala',
                                            fontSize : '19px'}}>
                                    Ejecutadas</label>
                                    <InputText 
                                        value={(Props.FormValues.HorasEjecutadas).toFixed(3)}
                                        onChange={Props.HandleChange}
                                        disabled
                                        name="HorasEjecutadas"
                                        type="number" 
                                        placeholder="Horas Ejecutadas"/>
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12 p-md-6 p-lg-4"> 
                            <div className="p-grid p-fluid">
                                <div className="p-col">
                                    <label 
                                        style={{
                                            color : '#4D80E4',
                                            fontFamily : 'Sarala',
                                            fontSize : '19px'}}>
                                    Fecha inicio</label>
                                    <Calendar 
                                        value={new Date(Props.FormValues.FechaInicio)} 
                                        onChange={Props.HandleChange}
                                        name="FechaInicio"
                                        disabled={Props.PasFormValues.Estado? (Props.PasFormValues.Estado.PKID === 3):false}
                                        locale={Local} 
                                        dateFormat="dd/mm/yy"/>
                                    {Props.Errores.FechaInicio && <small className="p-invalid p-d-block">{Props.Errores.FechaInicio}</small>}
                                </div>
                            </div>
                        </div> 
                        <div className="p-col-12 p-md-6 p-lg-4"> 
                            <div className="p-grid p-fluid">
                                <div className="p-col">
                                    <label 
                                        style={{
                                            color : '#4D80E4',
                                            fontFamily : 'Sarala',
                                            fontSize : '19px'}}>
                                    Fecha fin</label>
                                    <Calendar 
                                        value={new Date(Props.FormValues.FechaFin)} 
                                        onChange={Props.HandleChange}
                                        name="FechaFin"
                                        disabled={Props.PasFormValues.Estado? (Props.PasFormValues.Estado.PKID === 3):false}
                                        locale={Local} 
                                        dateFormat="dd/mm/yy"/>
                                    {Props.Errores.FechaFin && <small className="p-invalid p-d-block">{Props.Errores.FechaFin}</small>}
                                </div>
                            </div>
                        </div> 
                        <div className="p-col-12 p-md-6 p-lg-4"> 
                            <div className="p-grid p-fluid">
                                <div className="p-col">
                                    <label 
                                        style={{
                                            color : '#4D80E4',
                                            fontFamily : 'Sarala',
                                            fontSize : '19px'}}>
                                    Version</label>
                                    <InputText 
                                        value={Props.FormValues.Version}
                                        onChange={Props.HandleChange}
                                        disabled
                                        name="Version"
                                        type="number" 
                                        placeholder="Version"/>
                                </div>
                            </div>
                        </div>  
                        <div className="p-col-12 p-md-6 p-lg-4">  
                            <div className="p-grid p-fluid">
                                <div className="p-col">
                                    <label 
                                        style={{
                                            color : '#4D80E4',
                                            fontFamily : 'Sarala',
                                            fontSize : '19px'}}>
                                    Responsable</label>
                                    <InputText 
                                       value={Props.FormValues.Empleado.Nombre}
                                       disabled
                                       name="Responsable"
                                       type="text" 
                                       placeholder="Responsable"/>
                                </div>
                            </div>
                        </div>  
                        <div className="p-col-12 p-md-6 p-lg-4"> 
                            <div className="p-grid p-fluid">
                                <div className="p-col">
                                    <label 
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily : 'Sarala',
                                            fontSize : '19px'}}>
                                    Producto</label>
                                    <Dropdown 
                                        value = {{
                                            code: Props.FormValues.Producto.PKID,
                                            name: Props.FormValues.Producto.Nombre,
                                            VersionProducto : Props.FormValues.Producto.VersionProducto}}
                                        options = {Props.ListaProducto}
                                        onChange = {Props.HandleChange}
                                        optionLabel = "name"
                                        disabled={Props.PasFormValues.Estado? (Props.PasFormValues.Estado.PKID === 3):false}
                                        name = "Producto"
                                        placeholder = "Seleccione Producto"/>
                                    {Props.Errores.Producto && <small className="p-invalid p-d-block">{Props.Errores.Producto}</small>}
                                </div>
                            </div>              
                        </div>    
                        <div className="p-col-12 p-md-6 p-lg-4"> 
                            <div className="p-grid p-fluid">
                                <div className="p-col">
                                    <label 
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily : 'Sarala',
                                            fontSize : '19px'}}>
                                    Version del Producto</label>
                                    <Dropdown 
                                        value={{
                                            code: Props.FormValues.VersionProducto.PKID,
                                            name: Props.FormValues.VersionProducto.Version}}
                                        options={Props.FormValues.Producto.VersionProducto}
                                        onChange={Props.HandleChange}
                                        optionLabel="name"
                                        disabled={Props.PasFormValues.Estado? (Props.PasFormValues.Estado.PKID === 3):false}
                                        name = "VersionProducto"
                                        placeholder="Seleccione Versión"/>
                                    {Props.Errores.VersionProducto && <small className="p-invalid p-d-block">{Props.Errores.VersionProducto}</small>}
                                </div>
                            </div>
                        </div>   

                        <div className="p-col-12 p-md-6 p-lg-4"> 
                            <div className="p-grid p-fluid">
                                <div className="p-col">
                                    <label 
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily : 'Sarala',
                                            fontSize : '19px'}}>
                                    BackLog</label>
                                    <Dropdown 
                                        value = {{
                                            code: Props.FormValues.ImplementacionBackLog?Props.FormValues.ImplementacionBackLog.PKID:0,
                                            name: Props.FormValues.ImplementacionBackLog?Props.FormValues.ImplementacionBackLog.Nombre:'',
                                            VersionProducto : Props.FormValues.ImplementacionBackLog?Props.FormValues.ImplementacionBackLog.ImplementacionBackLogVersion:[]}}
                                        options = {Props.ListaImplementacionBackLog}
                                        onChange = {Props.HandleChange}
                                        optionLabel = "name"
                                        name = "ImplementacionBackLog"
                                        placeholder = "Seleccione el BackLog"/>
                                    {Props.Errores.BackLog && <small className="p-invalid p-d-block">{Props.Errores.BackLog}</small>}
                                </div>
                            </div>              
                        </div>    
                        <div className="p-col-12 p-md-6 p-lg-4"> 
                            <div className="p-grid p-fluid">
                                <div className="p-col">
                                    <label 
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily : 'Sarala',
                                            fontSize : '19px'}}>
                                   BackLogVersión</label>
                                   <Dropdown 
                                        value={{
                                            code: Props.FormValues.ImplementacionBackLogVersion?Props.FormValues.ImplementacionBackLogVersion.PKID:0,
                                            name: Props.FormValues.ImplementacionBackLogVersion?Props.FormValues.ImplementacionBackLogVersion.Version:0}}
                                        options={Props.FormValues.ImplementacionBackLog?Props.FormValues.ImplementacionBackLog.ImplementacionBackLogVersion:[]}
                                        onChange={Props.HandleChange}
                                        optionLabel="name"
                                        name = "ImplementacionBackLogVersion"
                                        placeholder="Seleccione Versión"/>
                                    {Props.Errores.BackLogVersion && <small className="p-invalid p-d-block">{Props.Errores.BackLogVersion}</small>}
                                </div>
                            </div>
                        </div> 

                        <div className="p-col-12 p-md-6 p-lg-4"> 
                        <div className="p-grid p-fluid">
                                <div className="p-col">
                                    <label 
                                        className="p-d-block"
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'}}
                                        >Motivo de Versionamiento</label>
                                <Dropdown
                                        value={{
                                            code: Props.FormValues.MotivoVersionamiento.PKID,
                                            name: Props.FormValues.MotivoVersionamiento.Descripcion}}
                                        options={Props.ListaMotivoVersionamiento}
                                        name = "MotivoVersionamiento"
                                        onChange={Props.HandleChange}
                                        optionLabel="name"
                                        placeholder="Seleccione Motivo de Versionamiento"/>
                                    {Props.Errores.MotivoVersionamiento && <small className="p-invalid p-d-block">{Props.Errores.MotivoVersionamiento}</small>}        
                                </div>
                                </div>
                                </div>
                             

                                <div className="p-col-12 p-md-6 p-lg-4"> 
                                <div className="p-grid p-fluid">
                                <div className="p-col">
                                    <label 
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'}}
                                    >Detalle de Versionamiento</label>
                                    <InputTextarea 
                                        value={Props.FormValues.DetalleVersionamiento}
                                        onChange={Props.HandleChange}
                                        name="DetalleVersionamiento"
                                        type="text" 
                                        rows={5} 
                                        cols={10}/>
                                    {Props.Errores.DetalleVersionamiento && <small className="p-invalid p-d-block">{Props.Errores.DetalleVersionamiento}</small>}
                                </div>
                                </div>
                                </div>

                               
                                <div className="p-col-12 p-md-6 p-lg-4"> 
                                <div className="p-grid p-fluid">
                                <div className="p-col">
                                    <label 
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'}}
                                    >Objetivo</label>
                                    <InputTextarea 
                                        value={Props.FormValues.Objetivo}
                                        onChange={Props.HandleChange}
                                        name="Objetivo"
                                        type="text" 
                                        rows={5} 
                                        cols={10}/>
                                    {Props.Errores.Objetivo && <small className="p-invalid p-d-block">{Props.Errores.Objetivo}</small>}
                                </div>
                                </div>
                                </div>
                                
                                <div className="p-col-12 p-md-6 p-lg-4"> 
                                <div className="p-grid p-fluid">
                                <div className="p-col">
                                    <label 
                                        style={{
                                            color : '#4D80E4',
                                            fontFamily : 'Sarala',
                                            fontSize : '19px'}}>
                                    Porcentaje de Avance</label>
                                    <InputText 
                                        value={Props.FormValues.PorcentajeAvance}
                                        onChange={Props.HandleChange}
                                        name="PorcentajeAvance"
                                        type="number" 
                                        min="0"
                                        placeholder="Porcentaje de Avance"/>
                                </div>
                            </div>
                                <div className="p-grid p-fluid">
                                <div className="p-col" style={{margin:'20px'}}>              
                                <Checkbox       
                                     inputId="binary"  
                                        checked={Props.FormValues.Activo} 
                                     onChange={Props.HandleChange}
                                     name="Activo" />
                                     <label 
                                        className=""
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'}}
                                        >      Activo</label>
                                    {Props.Errores.Activo && <small className="p-invalid p-d-block">{Props.Errores.Activo}</small>}    
                                </div>
                                </div>
                                </div>
                               
                            
                    </div>
                    <div className="p-d-flex footer p-jc-between">
                        <Button 
                            type = "button"
                            label = "Crear versión" 
                            onClick = {Props.CrearVersion}
                            className = "p-button-danger"/>
                        <Button 
                            type = "button"
                            label = "Guardar temporal"
                            style = {{
                                background:'#4D80E4',
                                border: '1px solid #4D80E4'}}
                            onClick = {Props.GuardarTemporal}
                            className = "p-ml-auto"/>
                    </div>
                </form>
            </div>
        </div>
    )
}