import { RequerimientoRepository } from '../../Infrastructure/Repositories/RequerimientoRepository'
import { IRequerimiento } from '../Models/IRequerimiento'
import { IBackLog } from '../Models/IBackLog'
import ItemRequerimiento from '../../Infrastructure/Pages/ItemRequerimiento'

interface IErroresRequerimiento{
    Requisito?: string
    Estimacion?: string
    FactorAjuste?: string
    FechaFin?: string
    FechaInicio?: string
    Descripcion?: string
}

const ValidarSchema = (Requerimiento: IRequerimiento) => {
    const errores: IErroresRequerimiento ={}
    if(!Requerimiento.Requisito) errores.Requisito  = 'Requisito es requerido'
    if(!Requerimiento.Descripcion) errores.Descripcion  = 'Descripción es requerido'
    if(!Requerimiento.Estimacion) if(Requerimiento.Estimacion !== 0) errores.Estimacion = 'Horas estimadas es requerido'
    if(!Requerimiento.FactorAjuste) errores.FactorAjuste = 'Factor ajuste es requerido'
    if(!Requerimiento.FechaFin) errores.FechaFin = 'Fecha de finalizacion es requerido'
    if(!Requerimiento.FechaInicio) errores.FechaInicio = 'Fecha de inicio es requerido'
    return errores;
}

const ObtenerObjetoEstado = (Array: Array<IRequerimiento>, PKID: string, IDEstado: number) => {
    let aObj: Array<IRequerimiento> =  Array.filter( (Obj : IRequerimiento) =>{
        return Obj.PKID === parseInt(PKID,10) 
    })
    let Obj: IRequerimiento = aObj[0] 
    return {
        ...Obj,
        Estado:{
            ...Obj.Estado,
            PKID: IDEstado
        }
    }
}

const DescargarArchivo = (Objeto: {Archivo: string, Nombre: string, Extension: string}) => {
    const byteCharacters = atob(Objeto.Archivo)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers);
    const url = window.URL.createObjectURL(new Blob([byteArray]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', Objeto.Nombre + '.' + Objeto.Extension);
    document.body.appendChild(link);
    link.click();
}

const ListarPaginacion = (Paginacion: any) => {
    return RequerimientoRepository.ListarPaginacion(Paginacion)
}

const CrearActualizar = (Requerimiento: IRequerimiento, PasProyecto: IBackLog) => {

    const proyecto_fechaInicio = new Date(PasProyecto.FechaInicio);
    const requerimiento_fechaInicio = new Date(Requerimiento.FechaInicio);
    const proyecto_fechaFin = new Date(PasProyecto.FechaFin);
    const requerimiento_fechaFin= new Date(Requerimiento.FechaFin);

    proyecto_fechaInicio.setHours(0,0,0,0);
    requerimiento_fechaInicio.setHours(0,0,0,0);
    proyecto_fechaFin.setHours(0,0,0,0);
    requerimiento_fechaFin.setHours(0,0,0,0);
    console.log("------TRANSFORMO LAS FECHAS PARA COMPARARLAS -------------")
    console.log("proyecto_fechaInicio : "+proyecto_fechaInicio)
    console.log("requerimiento_fechaInicio : "+requerimiento_fechaInicio)
    console.log("proyecto_fechaFin : "+proyecto_fechaFin)
    console.log("requerimiento_fechaFin : "+requerimiento_fechaFin)

    if(requerimiento_fechaInicio.getTime() < proyecto_fechaInicio.getTime()  ||  requerimiento_fechaFin.getTime() > proyecto_fechaFin.getTime()) {
        return Promise.reject("La fecha del requerimiento no esta dentro de la fecha del proyecto..")
    }else{
        if(requerimiento_fechaInicio.getTime() > requerimiento_fechaFin.getTime()){
            return Promise.reject("La fecha final del requerimiento debe ser mayor que la fecha inicial.")
        }else{
            return RequerimientoRepository.CrearActualizar(Requerimiento)
        }
    }
}

const ActualizarFechaFin = (Requerimiento: IRequerimiento) => {   
            return RequerimientoRepository.ActualizarFechaFin(Requerimiento)
}

const Consultar = (Requerimiento: IRequerimiento) => {
    return RequerimientoRepository.Consultar(Requerimiento)
}

export const RequerimientoService = {
    ValidarSchema,
    ObtenerObjetoEstado,
    DescargarArchivo,
    ListarPaginacion,
    Consultar,
    CrearActualizar,
    ActualizarFechaFin
}