import { HTTP } from '../Http/Http'

export const ItemRequerimientoRepository = {
    ListarPaginacion: async (Paginacion: any) => {
        return await HTTP.POST('ItemRequerimiento/ListarPaginacion',Paginacion,{})
    },
    CrearActualizar: async (ItemRequerimiento: any) => {
        return await HTTP.POST('ItemRequerimiento/CrearActualizar',ItemRequerimiento,{})
    },
}

