import React from 'react';
import { Button } from 'primereact/button'
import '../Assets/Css/PanelProyecto.css'
import { AuthHelper } from '../../Domain/Utils/AuthHelper'

export default function ItemBackLogHeader(props : any){

    const DescargarWord=(e: any)=>{
        props.DescargarWord(e)       
    }

    const ClickUrl=(e: any)=>{
        window.location.href="/" + AuthHelper.GetDecoded().Area + "/BackLog"
    }


    

    return (
        <div className="p-col-12">
            <div className="CrumbBeetwen">
                <div className="p-d-flex">
                    <ul className="breadcrumb">
                        <li>Inicio</li>
                        <li><a style={{cursor: 'pointer'}}  onClick={ClickUrl} >BackLog</a></li>
                        <li>ItemBackLog</li>
                    </ul>
                    <div className="BtnFiltro p-ml-auto">
                        <div className="p-d-flex">
                        <div className="p-mr-2">
                                <Button 
                                    label="BackLog" 
                                    className="p-button-rounded p-button-info p-mx-auto"
                                    onClick={ () =>{
                                        (document.getElementById('PanelProyecto') as HTMLInputElement).classList.add('active');
                                        (document.getElementById('Popup') as HTMLInputElement).classList.add('active');
                                    }}/>
                            </div>
                            <div className="p-mr-2">
                                <Button 
                                    label="Descargar BackLog" 
                                    className="p-button-rounded p-button-info p-mx-auto"
                                    onClick={DescargarWord}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
