import React from 'react'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'

interface IProps{
    Modal: boolean
    HideModal: any
    FormValues: any
    HandleChange: any
    Errores: any
    ImplementacionBackLogVersion:any
    ListaImplementacionBackLogVersion:any
}

export default function BackLogModalDescargarWord(Props: IProps){

    const RenderFooter = () =>{
        return (
            <Button 
                type="submit"
                label="Aceptar" 
                style={{
                    background:'#4D80E4',
                    border: '1px solid #4D80E4'}}
                icon="pi pi-check" 
                className="p-ml-auto"
                autoFocus />
        )
    }

    return(
        <Dialog
            header= {'BackLog - ' + Props.FormValues.Nombre} 
            visible={Props.Modal}
            style={{ width: '80vh' }}
            footer={RenderFooter()} 
            onHide={() => Props.HideModal(null, false)}>
                <div className="p-grid nested-grid">
                <div className="p-col-12 p-lg-6">
                                    <label 
                                        style={{                                            
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px',
                                            marginRight:'5px'
                                        }}
                                    >Versión                                                           </label>
                                      <Dropdown 
                                        value={{
                                            code: Props.ImplementacionBackLogVersion?Props.ImplementacionBackLogVersion.PKID:0,
                                            name: Props.ImplementacionBackLogVersion?Props.ImplementacionBackLogVersion.Version:0}}
                                        options={Props.ListaImplementacionBackLogVersion?Props.ListaImplementacionBackLogVersion:[]}
                                        onChange={Props.HandleChange}
                                        optionLabel="name"
                                        name = "ImplementacionBackLogVersion"
                                        placeholder="Seleccione Versión"/>
                                    {Props.Errores.BackLogVersion && <small className="p-invalid p-d-block">{Props.Errores.BackLogVersion}</small>}
                                </div>
                                
                    
                </div>
        </Dialog>
    )
}
