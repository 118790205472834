import jwt from 'jwt-decode'

const TOKEN_KEY = 'TOKEN-UN1FL3X'
const REFRESH_TOKEN_KEY = 'REFRESH-TOKEN-UN1FL3X'
const PROYECTO = 'PROYECTO-UN1FL3X'
const REQUERIMIENTO = 'REQUERIMIENTO-UN1FL3X'
const PLAN_PRUEBA = 'PLAN-PRUEBA-UN1FL3X'
const ITEM_PLAN_PRUEBA = 'ITEM-PLAN-PRUEBA-UN1FL3X'
const CASO_PRUEBA_VERSION = 'CASO-PRUEBA-VERSION-UN1FL3X'
const BACKLOG = 'BACKLOG-UN1FL3X'

export function getToken(){
    return localStorage.getItem(TOKEN_KEY);
}

export function getRefreshToken(){
    return localStorage.getItem(REFRESH_TOKEN_KEY);
}

export function deleteToken(){
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
}

export function getDecode(){
    if(getToken())
        return jwt(getToken());
    else
        return null
}

//BackLog
export function setBackLog(backLog){
    localStorage.setItem(BACKLOG,backLog);
}

export function getBackLog(){
    return localStorage.getItem(BACKLOG);
}

export function deleteBackLog(){
    localStorage.removeItem(BACKLOG);
}
//Fin BackLog

//Proyecto
export function setProyecto(proyecto){
    localStorage.setItem(PROYECTO,proyecto);
}

export function getProyecto(){
    return localStorage.getItem(PROYECTO);
}

export function deleteProyecto(){
    localStorage.removeItem(PROYECTO);
}
//Fin Proyecto

//Requerimiento
export function setRequerimiento(requerimiento){
    localStorage.setItem(REQUERIMIENTO,requerimiento);
}

export function getRequerimiento(){
    return localStorage.getItem(REQUERIMIENTO);
}

export function deleteRequerimiento(){
    localStorage.removeItem(REQUERIMIENTO);
}
//Fin Requerimiento

//Plan de Prueba
export function setPlanPrueba(planPrueba){
    localStorage.setItem(PLAN_PRUEBA,planPrueba);
}
export function getPlanPrueba(){
    return localStorage.getItem(PLAN_PRUEBA);
}
export function deletePlanPrueba(){
    localStorage.removeItem(PLAN_PRUEBA);
}
export function setItemPlanPrueba(ItemplanPrueba){
    localStorage.setItem(ITEM_PLAN_PRUEBA,ItemplanPrueba);
}
export function getItemPlanPrueba(){
    return localStorage.getItem(ITEM_PLAN_PRUEBA);
}
export function deleteItemPlanPrueba(){
    localStorage.removeItem(ITEM_PLAN_PRUEBA);
}
export function setCasoPruebaVersion(CasoPruebaVersion){
    localStorage.setItem(CASO_PRUEBA_VERSION,CasoPruebaVersion);
}
export function getCasoPruebaVersion(){
    return localStorage.getItem(CASO_PRUEBA_VERSION);
}
export function deleteCasoPruebaVersion(){
    localStorage.removeItem(CASO_PRUEBA_VERSION);
}

//Fin Plan de Prueba

export function EliminarTodo(){
    localStorage.removeItem(TOKEN_KEY)
    localStorage.removeItem(REFRESH_TOKEN_KEY)
    localStorage.removeItem(PROYECTO)
    localStorage.removeItem(REQUERIMIENTO)
    localStorage.removeItem(PLAN_PRUEBA)
    localStorage.removeItem(ITEM_PLAN_PRUEBA)
    localStorage.removeItem(CASO_PRUEBA_VERSION)
}