import React,{Component}  from 'react'
import { Checkbox } from 'primereact/checkbox'
import { Dropdown } from 'primereact/dropdown'
import { Button } from 'primereact/button'

import '../Assets/css/Filtro.css'
//import "../Assets/css/FiltroTareo.css";

export default class FiltroTareo extends Component{

    getProyecto = () => {
        try {
            if(this.props.ListaProyectoDDL!==null){
                if(Array.isArray(this.props.ListaProyectoDDL)){
                    let Proyectos = this.props.ListaProyectoDDL
                    let proyectoSelecccionado = null
                    let checksBd=this.props.Filtro.IDSProyectos.split('-')
                    Object.keys(checksBd).forEach(function(key){
                        if(checksBd[key]!==''){
                            let itemCheck=null
                            Object.keys(Proyectos).forEach(function(key2){
                                if(Proyectos[key2].code===parseInt(checksBd[key])){
                                    itemCheck=Proyectos[key2]
                                }
                            })
                            if(itemCheck!=null){
                                proyectoSelecccionado=itemCheck
                            }
                        }
                    })
                    return proyectoSelecccionado
                }else{
                    return 0 
                }
            }else{
                return 0
            }
        } catch (error) {
            console.log(error)
        }

    }

    getTicket = () => {
        if(this.props.ListaTicketDDL!==null){
            if(Array.isArray(this.props.ListaTicketDDL)){
                let Tickets = this.props.ListaTicketDDL
                let ticketSelecccionado = null
                let checksBd = this.props.Filtro.IDSTickets.split('-')
                Object.keys(checksBd).forEach(function(key){
                    if(checksBd[key] !== ''){
                        let itemCheck = null
                        Object.keys(Tickets).forEach(function(key2){
                            if(Tickets[key2].code===parseInt(checksBd[key])){
                                itemCheck=Tickets[key2]
                            }
                        })
                        if(itemCheck!=null){
                            ticketSelecccionado=itemCheck
                        }
                    }
                })
                return ticketSelecccionado
            }else{
                return 0
            }
        }else{
            return 0
        }
    }

    getResponsables = () => {
        if(this.props.ListaEmpleadoDDL!==null){
            if(Array.isArray(this.props.ListaEmpleadoDDL)){
                let Responsables = this.props.ListaEmpleadoDDL
                let lResponsables = []
                let checksBd=this.props.Filtro.IDSResponsables.split('-')
                Object.keys(checksBd).forEach(function(key){
                    if(checksBd[key]!==''){
                        let itemCheck=null
                        Object.keys(Responsables).forEach(function(key2){
                            if(Responsables[key2].code===parseInt(checksBd[key])){
                                itemCheck=Responsables[key2]
                            }
                        })
                        if(itemCheck!=null){
                            lResponsables.push(itemCheck)
                        }
                    }
                })
                return lResponsables
            }else{
                return []
            }
        }else{
            return []
        }
    }

    getEstados = () => {
        if(this.props.ListaEstadoDDL!==null){
            if(Array.isArray(this.props.ListaEstadoDDL)){
                let Estados = this.props.ListaEstadoDDL
                let lEstados = []
                let checksBd = this.props.Filtro.IDSEstados.split('-')
                Object.keys(checksBd).forEach(function(key){
                    if(checksBd[key] !== ''){
                        let itemCheck=null
                        Object.keys(Estados).forEach(function(key2){
                            if(Estados[key2].code===parseInt(checksBd[key])){
                                itemCheck=Estados[key2]
                            }
                        })
                        if(itemCheck!=null){
                            lEstados.push(itemCheck)
                        }
                    }
                })
                return lEstados
            }else{
                return []
            }
        }else{
            return []
        }
    }

    recortadorTexto=(texto, limite)=>{
        let puntosSuspensivos = "..."
        let texto2=""+texto
        if (texto2.length > limite) {
            texto2 = texto2.substring(0, limite) + puntosSuspensivos
        }
        return texto2;
    }

    templateDDL=(option, props)=>{
        if (option) {
            return (
                <div className="country-item country-item-value">
                    <div>{option.name}</div>
                </div>
            )
        }
        return (
            <span  className="country-item country-item-value">
                {props.placeholder}
            </span>
        )
    }

    templateItemDDL = option => {
        return (
            <div className="country-item">
                <div>{this.recortadorTexto(option.name, 46)}</div>
            </div>
        )
    }

    eliminarFiltro = e =>{
        this.props.eliminarFiltro(e)
        //this.CerrarFiltro()
    }

    AplicarFiltro = e =>{
        this.props.AplicarFiltro(e)
        this.CerrarFiltro()
    }

    CerrarFiltro = () => {
        document.getElementById('PanelFiltro').classList.remove('active')
        document.getElementById('PopupFiltro').classList.remove('active')
    }
    //#endregion

    onProyectoChange=(e)=>{
        this.props.onProyectoChange(e)
        //this.CerrarFiltro()
    }

    onTicketChange=(e)=>{
        this.props.onTicketChange(e)
        //this.CerrarFiltro()
    }

    onResponsableChange=(e)=>{
        let Responsablesleccionados = this.getResponsables()

        if (e.checked) {
            Responsablesleccionados.push(e.value)
        }else{
            for (let i = 0; i < Responsablesleccionados.length; i++) {
                const temporal = Responsablesleccionados[i]
                if (temporal.code === e.value.code) {
                    Responsablesleccionados.splice(i, 1)
                    break;
                }
            }
        }

        let itemsChecks=''
        Object.keys(Responsablesleccionados).forEach(function(key){
            var it = Responsablesleccionados[key]
            itemsChecks=itemsChecks+'-'+it.code
        })
        this.props.onChangeFiltro(itemsChecks,"IDSResponsables")
        //this.CerrarFiltro()
    }

    onEstadoChange=(e)=>{
        let EstadosSeleccionados = this.getEstados()

        if (e.checked) {
            EstadosSeleccionados.push(e.value)
        }else{
            for (let i = 0; i < EstadosSeleccionados.length; i++) {
                const temporal = EstadosSeleccionados[i]
                if (temporal.code === e.value.code) {
                    EstadosSeleccionados.splice(i, 1)
                    break;
                }
            }
        }
            
        let itemsChecks=''
        Object.keys(EstadosSeleccionados).forEach(function(key){
            var it = EstadosSeleccionados[key]
            itemsChecks=itemsChecks+'-'+it.code
        })
        this.props.onChangeFiltro(itemsChecks,"IDSEstados")
        //this.CerrarFiltro()
    }

    render(){
        return(
            <div id="PanelFiltro" className="Slide-Filtro">
                <div className="PopupFiltro" id="PopupFiltro">
                    <div className="HeaderPopup">
                        <div className="p-d-flex p-jc-between">
                            <div className="Title">Filtro</div>
                            <div className="btn-cerrar-popup" onClick={(e) => this.CerrarFiltro()}
                            ><i className="fas fa-times"></i></div>
                        </div>
                    </div>
                    <div className="BodyFiltro">
                    <div className="p-col-12 p-lg-12">
                        <div className="p-grid p-fluid">
                            <div className="p-col-12">
                            <h3>Proyecto</h3>
                            <Dropdown 
                                value={this.getProyecto()}
                                options={this.props.ListaProyectoDDL}
                                onChange={this.onProyectoChange}
                                optionLabel="name"
                                filter showClear filterBy="name"
                                placeholder="Seleccione Proyecto"
                                valueTemplate={this.templateDDL} 
                                itemTemplate={this.templateItemDDL} 
                            />
                        </div>
                        </div>

                        </div>

                        <div className="p-col-12 p-lg-12">
                        <div className="p-grid p-fluid">
                            <div className="p-col-12">
                            <h3>Ticket</h3>
                            <Dropdown 
                                value={this.getTicket()}
                                options={this.props.ListaTicketDDL}
                                onChange={this.onTicketChange}
                                optionLabel="name"
                                filter showClear filterBy="name" 
                                placeholder="Seleccione Ticket"
                                valueTemplate={this.templateDDL} 
                                itemTemplate={this.templateItemDDL} />
                        </div>
                        </div>
                        </div>
                        <div className="card">
                            {}
                            <h3>Responsable</h3>
                            {
                                this.props.ListaEmpleadoDDL.map((entidad) => {
                                    return (
                                        <div 
                                        key={entidad.code} className="p-field-checkbox">
                                            <Checkbox 
                                            name="category" 
                                            value={entidad} 
                                            onChange={this.onResponsableChange} 
                                            checked={this.getResponsables().some(
                                                (item) => item.code === entidad.code)} 
                                            disabled={entidad.code === 'R'} />
                                            <label htmlFor={entidad.code}>{entidad.name}</label>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="card">
                            <h3>Estado</h3>
                            {
                                this.props.ListaEstadoDDL.map((entidad) => {
                                    return (
                                        <div key={entidad.code} className="p-field-checkbox">
                                            <Checkbox 
                                            name="category" 
                                            value={entidad} 
                                            onChange={this.onEstadoChange} 
                                            checked={this.getEstados().some(
                                                (item) => item.code === entidad.code)} 
                                            disabled={entidad.code === 'R'} />
                                            <label htmlFor={entidad.code}>{entidad.name}</label>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="card">
                            <Button 
                                label="Aplicar" 
                                style={{
                                    marginRight:'5px',
                                    background:'#4D80E4',
                                    border: '1px solid #4D80E4'}}
                                icon="pi pi-check" 
                                onClick={this.AplicarFiltro}
                                className="p-ml-auto"
                                autoFocus />
                                 <Button 
                                label="Borrar" 
                                style={{
                                    background:'#4D80E4',
                                    border: '1px solid #4D80E4'}}
                                icon="pi pi-check" 
                                onClick={this.eliminarFiltro}
                                className="p-ml-auto"
                                autoFocus />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}