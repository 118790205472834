import React from 'react'
import { EliminarTodo } from '../../Services/LocalStorage'
import { AuthHelper } from '../../Domain/Utils/AuthHelper';
import '../Assets/Css/NavigationBar.css'

interface IProps {
}

interface IAcordeonState {
    Administracion: boolean;
    Maestro: boolean;
    Seguridad: boolean;
}

interface IState {
    Time: string
    OpenSideDrawer: boolean
    Acordeon: IAcordeonState
    TimeInterval: NodeJS.Timer | null
}

export default class NavigationBar extends React.Component<IProps, IState> {

    constructor(Props: any){
        super(Props);
        this.state = {
            Time : '',
            OpenSideDrawer : false,
            Acordeon: {
                Administracion: false,
                Maestro: false,
                Seguridad: false,
            },
            TimeInterval : null
        }
    }

    componentDidMount(){
        this.setState({TimeInterval : setInterval(
            () => this.CalcularHoraToken(), 1000
        )})
    }

    componentWillUnmount(){
        if(this.state.TimeInterval) clearInterval(this.state.TimeInterval)
    }

    CerrarSesion(){
        EliminarTodo()
        window.location.reload()
    }

    CalcularHoraToken(){
        if(AuthHelper.Get()){
            var date : any = new Date(AuthHelper.GetDecoded().exp * 1000)
            var hoy : any = new Date()

            var diff = Math.round((  date - hoy ) / 1000)
            if(diff < 0){
                EliminarTodo()
                window.location.reload()
            }else{
                var time = diff
                var hours = Math.floor( time / 3600 )
                var minutes : any = Math.floor( (time % 3600) / 60 )
                var seconds : any = time % 60
                minutes = minutes < 10 ? '0' + minutes : minutes
                seconds = seconds < 10 ? '0' + seconds : seconds
                var result = hours + ":" + minutes + ":" + seconds
                this.setState({ Time : result})
            }
        }
    }
    AcordeonHandler = (menu: keyof IAcordeonState) => {
        this.setState((prevState) => ({
            Acordeon: {
                ...prevState.Acordeon,
                [menu]: !prevState.Acordeon[menu as keyof IAcordeonState],
            },
        }));
    };

    SlideDrawer(){
        return(
            <nav className="SlideDrawer">
                <ul className="UserBox">
                    <li className="ItemUserBox">
                        <div className="TitleUser">Bienvenido</div>
                        <div className="NameUser">{AuthHelper.GetDecoded().Empleado}</div>
                    </li>
                </ul>
                <ul className="ItemSlide">
                {AuthHelper.GetDecoded().Area === 'Administracion' ? 
                        <li className="SubMenu" onClick={() => this.AcordeonHandler('Administracion')}>
                            <a  className="aSubMenu">Administracion</a>
                            <ul>
                            <li className={this.state.Acordeon['Administracion'] ? 'SubChildrenBlock' : 'SubChildrenNone'}>
                                    <a href="/Desarrollo/Reporte/Asistencia">Reporte Asistencia</a>
                                </li>
                            </ul>
                        </li>
                        :
                        <></>
                    }
                    <li className="Children"><a href="/Desarrollo/Asistencia">Asistencia</a></li>
                    <li className="Children"><a href="/Desarrollo/Inicio">Inicio</a></li>
                    <li className="Children"><a href="/Desarrollo/BackLog">BackLog</a></li>
                    <li className="Children"><a href="/Desarrollo/Proyecto">Proyecto</a></li>
                    <li className="Children"><a href="/Desarrollo/Ticket">Ticket</a></li>
                    <li className="Children"><a href="/Desarrollo/Tareo">Tareo Desarrollo</a></li>                 
                    <li className="Children"><a href="/Desarrollo/TareoImplantacion">Tareo Implantacion</a></li>                 
                    <li className="SubMenu" onClick={() => this.AcordeonHandler('Maestro')}>
                            <a  className="aSubMenu">Maestro</a>
                            <ul>
                            <li className={this.state.Acordeon['Maestro'] ? 'SubChildrenBlock' : 'SubChildrenNone'}>
                                <a href="/Desarrollo/VersionProducto">Producto</a>
                            </li>
                            <li className={this.state.Acordeon['Maestro'] ? 'SubChildrenBlock' : 'SubChildrenNone'}>
                                <a href="/Desarrollo/Categorizacion">Categorización</a>
                            </li>
                            <li className={this.state.Acordeon['Maestro'] ? 'SubChildrenBlock' : 'SubChildrenNone'}>
                                <a href="/Desarrollo/MotivoVersionamiento">Motivo de Versionamiento</a>
                            </li>
                            <li className={this.state.Acordeon['Maestro'] ? 'SubChildrenBlock' : 'SubChildrenNone'}>
                                <a href="/Desarrollo/Prioridad">Prioridad</a>
                            </li>
                        </ul>
                    </li>
                    <li className="Children"><a href="/Desarrollo/Solicitud">Solicitud</a></li>
                    <li className="Children"><a href="/Desarrollo/Documentacion">Documentacion</a></li>
                    <li className="Children"><a href="/Desarrollo/CasoPrueba">Caso Prueba</a></li>
                    <li className="Children"><a href="/Desarrollo/Incidente">Incidente</a></li>
                    <li className="Children"><a href="/Desarrollo/Calidad">Calidad</a></li>
                    {AuthHelper.GetDecoded().Administrador === 'True' ? 
                        <li className="SubMenu" onClick={() => this.AcordeonHandler('Seguridad')}>
                        <a className="aSubMenu">Seguridad</a>
                        <ul>
                            <li className={this.state.Acordeon['Seguridad'] ? 'SubChildrenBlock' : 'SubChildrenNone'}>
                                <a href="/Desarrollo/Seguridad">Usuario</a>
                            </li>
                            <li className={this.state.Acordeon['Seguridad'] ? 'SubChildrenBlock' : 'SubChildrenNone'}>
                                <a href="/Desarrollo/Seguridad/Empleado">Empleado</a>
                            </li>
                            <li className={this.state.Acordeon['Seguridad'] ? 'SubChildrenBlock' : 'SubChildrenNone'}>
                                <a href="/Desarrollo/Seguridad/Area">Area</a>
                            </li>
                        </ul>
                    </li>
                        :
                        <></>
                    }
                    <li className="Children"><a href="/">Salir</a></li>
                </ul>
            </nav>
        )
    }

    render() {
        return (
            <>
                <header className="toolbar">
                    <nav className="toolbar_navigation">
                        <button className="toggle-button" onClick={() => this.setState({OpenSideDrawer : !this.state.OpenSideDrawer})}>
                            <div className="toggle-button_line"/>
                            <div className="toggle-button_line"/>
                            <div className="toggle-button_line"/>
                        </button>
                        <div className="toolbar_logo">
                            <a>Trellium</a>
                        </div>
                        <div className="spacer"/>
                        <div className="toolbar_navigation_items">
                            <ul>
                                <li style={{color:'white'}}>{this.state.Time}</li>
                                <li><a href="/" onClick={this.CerrarSesion}>Salir</a></li>
                            </ul>
                        </div>
                    </nav>
                </header>
                {
                    this.state.OpenSideDrawer ?
                    <>
                        {this.SlideDrawer()}
                        <div className="BackDrop" onClick={() => this.setState({OpenSideDrawer : !this.state.OpenSideDrawer})}/>
                    </>
                    :
                    <></>
                }
            </>
        );
    }
}