import React from 'react'
import { Modal } from 'react-responsive-modal'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'

export default function ModalArea(props : any){

    return (
        <Modal
        open={props.openModal}
        onClose={props.closeModal}
        center>
            <div className="headerModal">
                {props.area.PKID > 0 ? 
                    <h2 style={{
                        color: '#4D80E4',
                        fontFamily: 'Sarala',
                        fontSize: '22px'}}>
                    Editar Area</h2>
                :
                    <h2 style={{
                        color: '#4D80E4',
                        fontFamily: 'Sarala',
                        fontSize: '22px'}}>
                    Agregar Area</h2>}
            </div>
            <div className="modal-body p-mt-3">
                <div className="p-grid nested-grid">
                    <div className="p-col-12">
                        <div className="p-col">
                            <div className="p-grid p-fluid ">
                                <div className="p-col-12">
                                <label 
                                    className="p-d-block"
                                    style={{
                                        fontFamily: 'Sarala',
                                        fontSize: '20px'}}
                                >Codigo</label>
                                <InputText 
                                    name="Codigo"
                                    type="text"
                                    value={props.area ? props.area.Codigo : ''}
                                    onChange={props.handleChange}/>
                                {props.Errores.Codigo && <small className="p-invalid p-d-block">{props.Errores.Codigo}</small>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-col-12">
                        <div className="p-col">
                            <div className="p-grid p-fluid ">
                                <div className="p-col-12">
                                <label 
                                    className="p-d-block"
                                    style={{
                                        fontFamily: 'Sarala',
                                        fontSize: '20px'}}
                                >Nombre</label>
                                <InputText 
                                    name="Nombre"
                                    type="text"
                                    value={props.area ? props.area.Nombre : ''}
                                    onChange={props.handleChange}/>
                                {props.Errores.Nombre && <small className="p-invalid p-d-block">{props.Errores.Nombre}</small>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-d-flex footer p-ml-auto">
                <Button 
                    type="submit"
                    label="Guardar" 
                    style={{
                        background:'#4D80E4',
                        border: '1px solid #4D80E4'}}
                    icon="pi pi-check" 
                    onClick={props.onSubmit}
                    className="p-ml-auto"
                    autoFocus />
            </div>
        </Modal>
    )
}