import React from 'react'
import CasoPruebaBody from '../Components/CasoPruebaBody'
import CasoPruebaModal from '../Components/CasoPruebaModal'
import CasoPruebaModalPaso from '../Components/CasoPruebaModalPaso'

import { AuthHelper } from '../../Domain/Utils/AuthHelper'
import { CalidadService } from '../../Domain/Services/CalidadService'
import { CasoPruebaService } from '../../Domain/Services/CasoPruebaService'
import { VersionProductoService } from '../../Domain/Services/VersionProductoService'

import { ICasoPrueba } from '../../Domain/Models/ICasoPrueba'
import { IPaso } from '../../Domain/Models/IPaso'
import CasoPruebaHeader from '../Components/CasoPruebaHeader'
import { IPaginacionCasoPrueba } from '../../Domain/Models/IPaginacionCasoPrueba'

interface IProps {
}

interface IState {
    Paginado: any
    ModalCasoPrueba: boolean
    ModalPaso: boolean
    CasoPrueba: ICasoPrueba
    Paso: IPaso
    ListaCasoPrueba: Array<object>
    ListaOriginalCasoPrueba: Array<object>
    ListaTipoPrueba: Array<object>
    ListaModulo: Array<object>
    ListaProducto: Array<object>
    Errores: any
    ErroresPaso: any
    Request: boolean
}

export default class CasoPrueba extends React.Component<IProps, IState> {

    cPaginado = {
        NumeroPagina: 1,
        RegistrosPagina: 100,
        NumeroPaginas: 0,
        fil_Codigo: '',
        fil_Nombre: '',
        fil_Producto: '',
        fil_Version: '',
        fil_Modulo: '',
        fil_Responsable: ''
    }

    cPaso = {
        PKID: 0,
        NumeroPaso: 1,
        Accion: '',
        ResultadoEsperado: '',
        Correcto: 1,
        FechaCreacion: new Date()
    }

    Registros = [
        { RegistrosPagina: 100 },
        { RegistrosPagina: 500 },
        { RegistrosPagina: 5000 }
    ]

    cCasoPrueba = {
        PKID: 0,
        Nombre: '',
        Descripcion: '',
        Modulo: {
            PKID: 0,
            Nombre: ''
        },
        Version: '0',
        FechaCreacion: new Date(),
        Empleado: {
            PKID: parseInt(AuthHelper.GetDecoded().sub, 10),
            Nombre: AuthHelper.GetDecoded().Empleado
        },
        TipoPrueba: {
            PKID: 0,
            Nombre: ''
        },
        Pasos: [],
        Producto: {
            PKID: 0,
            Nombre: '',
            VersionProducto: []
        },
        VersionProducto: {
            PKID: 0,
            Version: ''
        },
        Codigo: '',
        Automatizado: false,
    }

    constructor(Props: any) {
        super(Props)
        this.state = {
            Paginado: this.cPaginado,
            ModalCasoPrueba: false,
            ModalPaso: false,
            CasoPrueba: this.cCasoPrueba,
            Paso: this.cPaso,
            ListaCasoPrueba: [],
            ListaOriginalCasoPrueba: [],
            ListaTipoPrueba: [],
            ListaModulo: [],
            ListaProducto: [],
            Errores: {},
            ErroresPaso: {},
            Request: false
        }
    }

    componentDidMount() {
        this.ListarPaginacion()
        this.ListarTipoPrueba()
        this.ListarModulo()
        this.ListarVersionProducto()
    }

    ListarPaginacion() {
        CasoPruebaService.ListarPaginacion(this.state.Paginado)
            .then((response: any) => {
                if (response.success) {
                    this.setState({
                        Paginado: JSON.parse(response.data).Paginacion,
                        ListaCasoPrueba: JSON.parse(response.data).CasoPrueba,
                        ListaOriginalCasoPrueba: JSON.parse(response.data).CasoPrueba
                    })
                } else {
                    alert(response.data)
                }
            })
            .catch((Error) => {
                alert(Error)
            })
    }

    ListarTipoPrueba() {
        CasoPruebaService.ListarTipoPrueba()
            .then((response: any) => {
                if (response.success) {
                    let lista: Array<object> = []
                    Object.keys(JSON.parse(response.data)).forEach(function (key) {
                        lista.push({
                            code: JSON.parse(response.data)[key].PKID,
                            name: JSON.parse(response.data)[key].Nombre
                        })
                    })
                    this.setState({
                        ListaTipoPrueba: lista
                    })
                } else {
                    alert(response.data)
                }
            })
            .catch((Error) => {
                alert(Error)
            })
    }

    ListarModulo() {
        CalidadService.ListarModulo()
            .then((response: any) => {
                if (response.success) {
                    let lModulo: Array<object> = []
                    Object.keys(JSON.parse(response.data)).forEach(function (key) {
                        lModulo.push({
                            code: JSON.parse(response.data)[key].PKID,
                            name: JSON.parse(response.data)[key].Nombre
                        })
                    })
                    this.setState({ ListaModulo: lModulo })
                } else {
                    alert(response.data)
                }
            })
            .catch((Error: any) => {
                alert(Error)
            })
    }

    ListarVersionProducto() {
        VersionProductoService.Listar()
            .then((response: any) => {
                if (response.success) {
                    var aProducto: Array<object> = []
                    let lProducto: Array<{ PKID: number, Nombre: string }> = JSON.parse(response.data)
                    Object.keys(lProducto).forEach(function (k1: any) {
                        let oProducto: any = lProducto[k1]
                        let aVersionProducto: Array<object> = []
                        if (oProducto.VersionProducto) {
                            Object.keys(oProducto.VersionProducto).forEach(function (k2) {
                                aVersionProducto.push({
                                    code: oProducto.VersionProducto[k2].PKID,
                                    name: oProducto.VersionProducto[k2].Version,
                                })
                            })
                        }
                        aProducto.push({
                            code: lProducto[k1].PKID,
                            name: lProducto[k1].Nombre,
                            VersionProducto: aVersionProducto
                        })
                    })
                    this.setState({ ListaProducto: aProducto })
                } else {
                    alert(response.data)
                }
            })
            .catch((error) => {
                alert(error)
            })
    }

    AplicarFiltros = (Filtros: any) => {
        this.ListarPaginacion();
    }

    OnResponse = (CasoPrueba: any) => {
        if (CasoPrueba) {
            var i = 1
            CasoPrueba.Pasos.forEach((paso: IPaso) => {
                paso.NumeroPaso = i++
            })
            let jLista: any = this.state.ListaProducto
            this.setState({
                CasoPrueba: {
                    ...CasoPrueba,
                    Producto: {
                        ...CasoPrueba.Producto,
                        VersionProducto: jLista.find((itemVersion: any) => itemVersion.code === CasoPrueba.Producto.PKID).VersionProducto
                    }
                },
                Errores: {},
                ErroresPaso: {}
            }, () => this.HideVisibleModal(true))
        } else {
            this.setState({
                CasoPrueba: this.cCasoPrueba,
                Errores: {},
                ErroresPaso: {}
            }, () => this.HideVisibleModal(true))
        }
    }

    HideVisibleModal = (Mode: boolean) => {
        this.setState({
            ModalCasoPrueba: Mode
        })
    }

    OnChangeFilter = (Event: any) => {
        this.setState({
            Paginado: {
                ...this.state.Paginado,
                NumeroPagina: 1,
                [Event.target.name]: Event.target.value
            }
        }, () => {
            //this.ListarPaginacion()
        })
    }

    OnChangeGlobalFilter = (Event: any) => {
        //console.log(Event.target.name)
        console.log(Event.target.value)
        this.setState({
            Paginado: {
                ...this.state.Paginado,
                NumeroPagina: 1,
                [Event.target.name]: Event.target.value
            }
        }, () => {
            //this.ListarPaginacion()
        })
    }

    OnRegistroChange = (Event: any) => {
        this.setState({
            Paginado: {
                ...this.state.Paginado,
                NumeroPagina: 1,
                RegistrosPagina: Event.value.RegistrosPagina,
            }
        }, () => {
            this.ListarPaginacion();
        })
    }

    HandlePreviousLL = () => {
        this.setState({
            Paginado: {
                ...this.state.Paginado,
                NumeroPagina: 1,
            }
        }, () => {
            this.ListarPaginacion()
        })
    }

    HandlePrevious = () => {
        this.setState({
            Paginado: {
                ...this.state.Paginado,
                NumeroPagina: this.state.Paginado.NumeroPagina - 1
            }
        }, () => {
            this.ListarPaginacion()
        })
    }

    HandleFollowing = () => {
        this.setState({
            Paginado: {
                ...this.state.Paginado,
                NumeroPagina: this.state.Paginado.NumeroPagina + 1
            }
        }, () => {
            this.ListarPaginacion()
        })
    }

    HandleFollowingLL = () => {
        this.setState({
            Paginado: {
                ...this.state.Paginado,
                NumeroPagina: this.state.Paginado.NumeroPaginas
            }
        }, () => {
            this.ListarPaginacion()
        })
    }

    HandleSubmit = (Event: any) => {
        Event.preventDefault()
        const result = CasoPruebaService.ValidarSchema(this.state.CasoPrueba);
        if (Object.keys(result).length) {
            return this.setState({ Errores: result });
        } else {
            this.setState({
                Request: true
            }, () => this.CrearActualizar())
        }
    }

    CrearActualizar() {
        CasoPruebaService.CrearActualizar(this.state.CasoPrueba, this.state.Paginado)
            .then((response: any) => {
                if (response.success) {
                    alert(response.data.descripcion)
                    this.setState({
                        CasoPrueba: this.cCasoPrueba,
                        Errores: {},
                        ModalCasoPrueba: !this.state.ModalCasoPrueba,
                        Paginado: response.data.paginado,
                        ListaCasoPrueba: []
                    }, () => this.componentDidMount())
                } else {
                    alert(response.data.data)
                }
            })
            .catch((Error: any) => {
                alert(Error)
            })
            .finally(() => {
                this.setState({ Request: false })
            })
    }

    ActualizarPaso = (Event: any) => {
        this.setState({
            Paso: Event,
            ErroresPaso: {}
        }, () => this.HideVisibleModalPaso(true))
    }

    EliminarPaso = (RowData: any) => {
        let lPasos = this.state.CasoPrueba.Pasos
        if (lPasos.filter((Event: any) => { return Event.NumeroPaso === RowData.NumeroPaso }).length > 0) {
            lPasos = lPasos.filter((Event: any) => { return Event.NumeroPaso !== RowData.NumeroPaso })
        }
        var lOrdenado: Array<object> = []
        if (lPasos.length > 0) {
            lPasos.forEach((Element, Index) => {
                Element = {
                    ...Element,
                    NumeroPaso: Index + 1
                }
                lOrdenado.push(Element)
            });
        }
        this.setState({
            CasoPrueba: {
                ...this.state.CasoPrueba,
                Pasos: lOrdenado
            },
        })
    }

    OnChangeCasoPrueba = (Event: any) => {
        console.log(Event.target.name)
        console.log(Event.target.value)
        switch (Event.target.name) {
            case 'Automatizado':
                this.setState({
                    CasoPrueba: {
                        ...this.state.CasoPrueba,
                        Automatizado: !this.state.CasoPrueba.Automatizado
                    }
                })
                break
            case 'Modulo':
                this.setState({
                    CasoPrueba: {
                        ...this.state.CasoPrueba,
                        Modulo: {
                            PKID: Event.value.code,
                            Nombre: Event.value.name
                        }
                    }
                })
                break
            case 'TipoPrueba':
                this.setState({
                    CasoPrueba: {
                        ...this.state.CasoPrueba,
                        TipoPrueba: {
                            PKID: Event.value.code,
                            Nombre: Event.value.name
                        }
                    }
                })
                break
            case 'Producto':
                this.setState({
                    CasoPrueba: {
                        ...this.state.CasoPrueba,
                        Producto: {
                            PKID: Event.value.code,
                            Nombre: Event.value.name,
                            VersionProducto: Event.value.VersionProducto
                        }
                    },
                })
                break
            case 'VersionProducto':
                this.setState({
                    CasoPrueba: {
                        ...this.state.CasoPrueba,
                        VersionProducto: {
                            PKID: Event.value.code,
                            Version: Event.value.name
                        }
                    },
                })
                break
            default:
                this.setState({
                    CasoPrueba: {
                        ...this.state.CasoPrueba,
                        [Event.target.name]: Event.target.value
                    }
                })
                break
        }
    }

    CrearNuevoPaso = (Event: any) => {
        this.setState({
            Paso: {
                ...this.cPaso,
                NumeroPaso: this.state.CasoPrueba.Pasos.length + 1,
            },
            ErroresPaso: {}
        }, () => this.HideVisibleModalPaso(true))
    }

    HideVisibleModalPaso = (Mode: boolean) => {
        this.setState({
            ModalPaso: Mode
        })
    }

    EliminarCasoPrueba = (Event: any) => {
        if (Event.Version > 0) {
            alert('No se puede eliminar el caso de prueba: Ya existe una versión o más asociada al caso de prueba por lo tanto no se puede eliminar.')
            return
        }
        this.setState({
            Request: true
        }, () => this.Eliminar(Event))
    }

    RowReorder = (Event: any) => {
        var i = 1
        Event.value.forEach((paso: IPaso) => {
            paso.NumeroPaso = i++
        })
        this.setState({
            CasoPrueba: {
                ...this.state.CasoPrueba,
                Pasos: Event.value
            },
        })
    }

    Eliminar(CasoPrueba: ICasoPrueba) {
        CasoPruebaService.Eliminar(CasoPrueba)
            .then((response: any) => {
                if (response.success) {
                    alert(response.data)
                    this.setState({
                        CasoPrueba: this.cCasoPrueba,
                        Errores: {},
                        ModalCasoPrueba: !this.state.ModalCasoPrueba
                    }, () => this.componentDidMount())
                } else {
                    alert(response.data)
                }
            })
            .catch((Error) => {
                alert(Error)
            })
            .finally(() => {
                this.setState({ Request: false })
            })
    }

    HandleSubmitPaso = (Event: any) => {
        Event.preventDefault()
        const result = CasoPruebaService.ValidarPaso(this.state.Paso)
        if (Object.keys(result).length) return this.setState({ ErroresPaso: result });
        else this.CrearActualizarPaso()
    }

    CrearActualizarPaso() {
        let listaPasos = this.state.CasoPrueba.Pasos
        if (listaPasos.filter((e: any) => { return e.NumeroPaso === this.state.Paso.NumeroPaso }).length > 0) {
            listaPasos = listaPasos.filter((e: any) => { return e.NumeroPaso !== this.state.Paso.NumeroPaso })
        }
        listaPasos.push(this.state.Paso)
        listaPasos.sort(function (a: any, b: any) {
            return a.NumeroPaso - b.NumeroPaso
        })
        this.setState({
            Paso: this.cPaso,
            CasoPrueba: {
                ...this.state.CasoPrueba,
                Pasos: listaPasos
            },
            ModalPaso: !this.state.ModalPaso
        })
    }

    OnChangePaso = (Event: any) => {
        this.setState({
            Paso: {
                ...this.state.Paso,
                [Event.target.name]: Event.target.value
            }
        })
    }


    HandleChangeBuscador = (e: any) => {

        const ListaOriginalCasoPrueba = this.state.ListaOriginalCasoPrueba
        const ListaOriginalCasoPruebaFiltoBefore = this.state.ListaOriginalCasoPrueba

        if (e.target.value == '') {
            this.setState({
                ListaCasoPrueba: ListaOriginalCasoPrueba
            })

        } else {

            if (this.state.ListaCasoPrueba.length === 0) {
                
                const listaFiltradaPendiente = ListaOriginalCasoPruebaFiltoBefore.filter(
                    (list: any) => {
                        const valorBuscado = e.target.value.toLowerCase();
                        return (
                            list.Nombre.toLowerCase().includes(valorBuscado) ||
                            list.Codigo.toLowerCase().includes(valorBuscado) ||
                            list.Producto.Nombre.toLowerCase().includes(valorBuscado) ||
                            list.Modulo.Nombre.toLowerCase().includes(valorBuscado) ||
                            list.VersionProducto.Version.toLowerCase().includes(valorBuscado)
                            // Agrega más condiciones según los campos que quieras filtrar
                        );
                    });

                this.setState({
                    ListaCasoPrueba: listaFiltradaPendiente
                })
            } else {

                const listaFiltradaPendiente = this.state.ListaCasoPrueba.filter(
                    (list: any) => {
                        const valorBuscado = e.target.value.toLowerCase();
                        return (
                            list.Nombre.toLowerCase().includes(valorBuscado) ||
                            list.Codigo.toLowerCase().includes(valorBuscado) ||
                            list.Producto.Nombre.toLowerCase().includes(valorBuscado) ||
                            list.Modulo.Nombre.toLowerCase().includes(valorBuscado) ||
                            list.VersionProducto.Version.toLowerCase().includes(valorBuscado)
                            // Agrega más condiciones según los campos que quieras filtrar
                        );
                    });

                this.setState({
                    ListaCasoPrueba: listaFiltradaPendiente
                })
            }
        }
    }

    render() {
        return (
            <div className="container">
                <CasoPruebaHeader
                    OnChangeBuscador={this.HandleChangeBuscador} />
                <CasoPruebaBody

                    OnResponse={this.OnResponse}
                    AplicarFiltros={this.AplicarFiltros}
                    ListaCasoPrueba={this.state.ListaCasoPrueba}
                    Paginado={this.state.Paginado}
                    Registros={this.Registros}
                    OnChangeFilter={this.OnChangeFilter}
                    OnRegistroChange={this.OnRegistroChange}
                    HandlePreviousLL={this.HandlePreviousLL}
                    HandlePrevious={this.HandlePrevious}
                    HandleFollowing={this.HandleFollowing}
                    HandleFollowingLL={this.HandleFollowingLL} />
                <form autoComplete="Off" onSubmit={this.HandleSubmit}>
                    <CasoPruebaModal
                        ActualizarPaso={this.ActualizarPaso}
                        EliminarPaso={this.EliminarPaso}
                        HandleChange={this.OnChangeCasoPrueba}
                        Modal={this.state.ModalCasoPrueba}
                        HideModal={() => this.HideVisibleModal(false)}
                        FormValues={this.state.CasoPrueba}
                        CrearNuevoPaso={this.CrearNuevoPaso}
                        EliminarCasoPrueba={this.EliminarCasoPrueba}
                        ListaModulo={this.state.ListaModulo}
                        ListaTipoPrueba={this.state.ListaTipoPrueba}
                        ListaProducto={this.state.ListaProducto}
                        Errores={this.state.Errores}
                        Request={this.state.Request}
                        RowReorder={this.RowReorder} />
                </form>
                <form autoComplete="Off" onSubmit={this.HandleSubmitPaso}>
                    <CasoPruebaModalPaso
                        Modal={this.state.ModalPaso}
                        HideModal={() => this.HideVisibleModalPaso(false)}
                        FormValues={this.state.Paso}
                        HandleChange={this.OnChangePaso}
                        Errores={this.state.ErroresPaso} />
                </form>
            </div>
        )
    }
}