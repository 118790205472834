import React from 'react'
import { Modal } from 'react-responsive-modal'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { Dropdown} from 'primereact/dropdown'

export default function ModalEmpleado(props : any){

    return (
        <Modal
        open={props.openModal}
        onClose={props.closeModal}
        center>
            <div className="headerModal">
                {props.empleado.PKID > 0 ? 
                    <h2 style={{
                        color: '#4D80E4',
                        fontFamily: 'Sarala',
                        fontSize: '22px'}}>
                    Editar Empleado</h2>
                :
                    <h2 style={{
                        color: '#4D80E4',
                        fontFamily: 'Sarala',
                        fontSize: '22px'}}>
                    Agregar Empleado</h2>}
            </div>
            <div className="modal-body p-mt-3">
                <div className="p-grid nested-grid">
                    <div className="p-col-12">
                        <div className="p-col">
                            <div className="p-grid p-fluid ">
                                <div className="p-col-12">
                                <label 
                                    className="p-d-block"
                                    style={{
                                        fontFamily: 'Sarala',
                                        fontSize: '20px'}}
                                >Nombres y Apellidos</label>
                                <InputText 
                                    name="Nombre"
                                    type="text"
                                    value={props.empleado ? props.empleado.Nombre : ''}
                                    onChange={props.handleChange}/>
                                {props.Errores.Nombre && <small className="p-invalid p-d-block">{props.Errores.Nombre}</small>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-col-12">
                        <div className="p-col">
                            <div className="p-grid p-fluid ">
                                <div className="p-col-12">
                                <label 
                                    className="p-d-block"
                                    style={{
                                        fontFamily: 'Sarala',
                                        fontSize: '20px'}}
                                >Correo Electronico</label>
                                <InputText 
                                    name="CorreoElectronico"
                                    type="text"
                                    value={props.empleado ? props.empleado.CorreoElectronico : ''}
                                    onChange={props.handleChange}/>
                                {props.Errores.CorreoElectronico && <small className="p-invalid p-d-block">{props.Errores.CorreoElectronico}</small>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-col-12">
                        <div className="p-col">
                            <div className="p-grid p-fluid ">
                                <div className="p-col-12">
                                    <label 
                                        className="p-d-block"
                                        style={{
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'}}
                                        >Area</label>
                                    <Dropdown 
                                        value={{
                                            code: props.empleado.Area.PKID,
                                            name: props.empleado.Area.Nombre}}
                                        options={props.listaArea} 
                                        onChange={props.handleChange}
                                        name="Area" 
                                        optionLabel="name" 
                                        placeholder="Seleccione Area"/>
                                    {props.Errores.Area && <small className="p-invalid p-d-block">{props.Errores.Area}</small>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-d-flex footer p-ml-auto">
                <Button 
                    type="submit"
                    label="Guardar" 
                    style={{
                        background:'#4D80E4',
                        border: '1px solid #4D80E4'}}
                    icon="pi pi-check" 
                    onClick={props.onSubmit}
                    className="p-ml-auto"
                    autoFocus />
            </div>
        </Modal>
    )
}