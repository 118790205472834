import React from 'react'
import SolicitudHeader from '../Components/SolicitudHeader'
import SolicitudBody from '../Components/SolicitudBody'
import ModalSolicitud from '../Components/SolicitudModal'

import { SolicitudService } from '../../Domain/Services/SolicitudService'
import { ClienteService } from '../../Domain/Services/ClienteService'
import { EmpleadoService } from '../../Domain/Services/EmpleadoService'
import { VersionProductoService } from '../../Domain/Services/VersionProductoService'
import { TicketService } from '../../Domain/Services/TicketService'

import { ISolicitud } from '../../Domain/Models/ISolicitud'
import { IPaginacion } from '../../Domain/Models/IPaginacion'

interface IProps {
}

interface IState {
    Modal: boolean
    Solicitud: ISolicitud
    Request: boolean
    Pendiente: IPaginacion
    EnCurso: IPaginacion
    Hecho: IPaginacion
    ListaPendienteOriginal: IPaginacion
    ListaEnCursoOriginal: IPaginacion
    ListaHechoOriginal: IPaginacion
    ListaTicket: Array<object>
    ListaClientes: Array<object>
    ListaEmpleados: Array<object>
    ListaProducto: Array<object>
    Errores: any
}

export default class Solicitud extends React.Component<IProps, IState> {

    cPaginacion = {
        Lista: [],
        Paginacion: {
            NumeroPagina: 1,
            RegistrosPagina: 50000,
            NumeroPaginas: 0,
            IDEstado: 1,
            IDUsuario: 0,
        },
        Loading: true
    }

    cSolicitud = {
        PKID: 0,
        TicketAsociado: {
            PKID: 0,
            Nombre: ''
        },
        Numero: 0,
        FechaCreacion: new Date(),
        Nombre: '',
        Detalle: '',
        Cliente: {
            PKID: 0,
            Nombre: ''
        },
        NombreSolicitante: '',
        CargoSolicitante: '',
        AreaSolicitante: '',
        Empleado: {
            PKID: 0,
            Nombre: ''
        },
        TipoSolicitud: {
            PKID: 1,
            Nombre: 'Desarrollo Nuevo'
        },
        Estado: {
            PKID: 1,
            Descripcion: 'Pendiente'
        },
        Producto: {
            PKID: 0,
            Nombre: '',
            VersionProducto: []
        },
        VersionProducto: {
            PKID: 0,
            Version: ''
        }
    }

    constructor(props: any) {
        super(props)
        this.state = {
            Modal: false,
            Solicitud: this.cSolicitud,
            Request: false,
            Pendiente: { ...this.cPaginacion, Paginacion: { ...this.cPaginacion.Paginacion, IDEstado: 1 } },
            EnCurso: { ...this.cPaginacion, Paginacion: { ...this.cPaginacion.Paginacion, IDEstado: 2 } },
            Hecho: { ...this.cPaginacion, Paginacion: { ...this.cPaginacion.Paginacion, IDEstado: 3 } },
            ListaPendienteOriginal: { ...this.cPaginacion, Paginacion: { ...this.cPaginacion.Paginacion, IDEstado: 1 } },
            ListaEnCursoOriginal: { ...this.cPaginacion, Paginacion: { ...this.cPaginacion.Paginacion, IDEstado: 2 } },
            ListaHechoOriginal: { ...this.cPaginacion, Paginacion: { ...this.cPaginacion.Paginacion, IDEstado: 3 } },
            ListaTicket: [],
            ListaClientes: [],
            ListaEmpleados: [],
            ListaProducto: [],
            Errores: {}
        }
    }

    componentDidMount() {
        this.ListarTickets()
        this.ListarClientes()
        this.ListarEmpleados()
        this.ListarVersionProducto()
        this.ListarSolicitudes('Pendiente')
        this.ListarSolicitudes('EnCurso')
        this.ListarSolicitudes('Hecho')
    }

    ListarTickets() {
        TicketService.ListarEstado({ PKID: 2 })
            .then((response: any) => {
                if (response.success) {
                    let lTicket: Array<object> = []
                    Object.keys(JSON.parse(response.data)).forEach(function (key) {
                        lTicket.push({
                            code: JSON.parse(response.data)[key].PKID,
                            name: JSON.parse(response.data)[key].Nombre,
                        });
                    });
                    this.setState({ ListaTicket: lTicket })
                } else {
                    throw new Error(response.data);
                }
            })
            .catch((error) => {
                alert(error)
            })
    }

    ListarClientes() {
        ClienteService.Listar()
            .then((response: any) => {
                if (response.success) {
                    var aCliente: Array<object> = []
                    Object.keys(JSON.parse(response.data)).forEach(function (key) {
                        var iCliente = {
                            code: JSON.parse(response.data)[key].PKID,
                            name: JSON.parse(response.data)[key].Nombre,
                        }
                        aCliente.push(iCliente);
                    });
                    this.setState({ ListaClientes: aCliente })
                } else {
                    throw new Error(response.data);
                }
            })
            .catch((error) => {
                alert(error)
            })
    }

    ListarEmpleados() {
        EmpleadoService.Listar()
            .then((response: any) => {
                if (response.success) {
                    let lEmpleados: Array<object> = []
                    Object.keys(JSON.parse(response.data)).forEach(function (key) {
                        lEmpleados.push({
                            code: JSON.parse(response.data)[key].PKID,
                            name: JSON.parse(response.data)[key].Nombre,
                        });
                    });
                    this.setState({ ListaEmpleados: lEmpleados })
                } else {
                    throw new Error(response.data);
                }
            })
            .catch((error) => {
                alert(error)
            })
    }

    ListarVersionProducto() {
        VersionProductoService.Listar()
            .then((response: any) => {
                if (response.success) {
                    var aProducto: Array<object> = []
                    let lProducto: Array<{ PKID: number, Nombre: string }> = JSON.parse(response.data)
                    Object.keys(lProducto).forEach(function (k1: any) {
                        let oProducto: any = lProducto[k1]
                        let aVersionProducto: Array<object> = []
                        if (oProducto.VersionProducto) {
                            Object.keys(oProducto.VersionProducto).forEach(function (k2) {
                                aVersionProducto.push({
                                    code: oProducto.VersionProducto[k2].PKID,
                                    name: oProducto.VersionProducto[k2].Version,
                                })
                            })
                        }
                        aProducto.push({
                            code: lProducto[k1].PKID,
                            name: lProducto[k1].Nombre,
                            VersionProducto: aVersionProducto
                        })
                    })
                    this.setState({ ListaProducto: aProducto })
                } else {
                    alert(response.data)
                }
            })
            .catch((error) => {
                alert(error)
            })
    }

    ListarSolicitudes = (Estado: string) => {
        SolicitudService.ListarPaginacion(
            {
                'Pendiente': this.state.Pendiente.Paginacion,
                'EnCurso': this.state.EnCurso.Paginacion,
                'Hecho': this.state.Hecho.Paginacion
            }[Estado]
        )
            .then((response: any) => {
                if (response.success) {
                    let jParse = JSON.parse(response.data)
                    let rSolicitud: Array<ISolicitud | any> = jParse.Solicitud ? jParse.Solicitud : []
                    let rPaginacion: any = jParse.Paginacion
                    switch (Estado) {
                        case 'Pendiente':
                            this.setState({ Pendiente: { Lista: this.state.Pendiente.Lista.concat(rSolicitud), Paginacion: rPaginacion, Loading: false } })
                            this.setState({ ListaPendienteOriginal: { Lista: this.state.ListaPendienteOriginal.Lista.concat(rSolicitud), Paginacion: rPaginacion, Loading: false } })

                            break
                        case 'EnCurso':
                            this.setState({ EnCurso: { Lista: this.state.EnCurso.Lista.concat(rSolicitud), Paginacion: rPaginacion, Loading: false } })
                            this.setState({ ListaEnCursoOriginal: { Lista: this.state.ListaEnCursoOriginal.Lista.concat(rSolicitud), Paginacion: rPaginacion, Loading: false } })
                            break
                        case 'Hecho':
                            this.setState({ Hecho: { Lista: this.state.Hecho.Lista.concat(rSolicitud), Paginacion: rPaginacion, Loading: false } })
                            this.setState({ ListaHechoOriginal: { Lista: this.state.ListaHechoOriginal.Lista.concat(rSolicitud), Paginacion: rPaginacion, Loading: false } })
                            break
                        default:
                            break
                    }
                } else {
                    alert(response.data);
                }
            })
            .catch((error) => {
                alert(error)
            })
    }

    HandleSubmit = (Event: any) => {
        Event.preventDefault();
        const result = SolicitudService.ValidarSchema(this.state.Solicitud)
        if (Object.keys(result).length) {
            return this.setState({ Errores: result })
        } else {
            this.setState({
                Request: true
            }, () => this.CrearActualizar())
        }
    }


    OnResponse = (Solicitud: any) => {
        if (Solicitud) {
            let jArray: any = this.ObtenerColeccion(this.state.ListaProducto.find((itemVersion: any) => itemVersion.code === Solicitud.Producto.PKID))
            Solicitud = {
                ...Solicitud,
                Producto: {
                    ...Solicitud.Producto,
                    VersionProducto: jArray.VersionProducto
                }
            }
        }
        this.setState({
            Solicitud: Solicitud ? Solicitud : this.cSolicitud,
            Errores: {}
        }, () => this.HideVisibleModal(true))

    }

    ObtenerColeccion<T>(argument: T | undefined | null): T {
        if (argument === undefined || argument === null) throw new TypeError('No se encontro el valor.')
        return argument;
    }

    Paginacion = (Estado: string) => {
        if (
            (
                {
                    'Pendiente': ((this.state.Pendiente.Paginacion.NumeroPagina < this.state.Pendiente.Paginacion.NumeroPaginas) && !this.state.Pendiente.Loading),
                    'EnCurso': ((this.state.EnCurso.Paginacion.NumeroPagina < this.state.EnCurso.Paginacion.NumeroPaginas) && !this.state.EnCurso.Loading),
                    'Hecho': ((this.state.Hecho.Paginacion.NumeroPagina < this.state.Hecho.Paginacion.NumeroPaginas) && !this.state.Hecho.Loading)
                }[Estado]
            )) {
            switch (Estado) {
                case 'Pendiente':
                    this.setState({
                        Pendiente: {
                            ...this.state.Pendiente,
                            Paginacion: {
                                ...this.state.Pendiente.Paginacion,
                                NumeroPagina: this.state.Pendiente.Paginacion.NumeroPagina + 1
                            },
                            Loading: true
                        }
                    }, () => this.ListarSolicitudes('Pendiente'))
                    break
                case 'EnCurso':
                    this.setState({
                        EnCurso: {
                            ...this.state.EnCurso
                            , Paginacion: {
                                ...this.state.EnCurso.Paginacion,
                                NumeroPagina: this.state.EnCurso.Paginacion.NumeroPagina + 1
                            },
                            Loading: true
                        }
                    }, () => this.ListarSolicitudes('EnCurso'))
                    break
                case 'Hecho':
                    this.setState({
                        Hecho: {
                            ...this.state.Hecho,
                            Paginacion: {
                                ...this.state.Hecho.Paginacion,
                                NumeroPagina: this.state.Hecho.Paginacion.NumeroPagina + 1
                            },
                            Loading: true
                        }
                    }, () => this.ListarSolicitudes('Hecho'))
                    break
                default:
                    break
            }
        }
    }

    MoverEstado = (EstadoI: string, EstadoF: string, PKID: string) => {
        if (EstadoI !== EstadoF) {
            let Obj = { 'Pendiente': this.state.Pendiente.Lista, 'EnCurso': this.state.EnCurso.Lista, 'Hecho': this.state.Hecho.Lista }[EstadoI] || []
            switch (EstadoF) {
                case 'Pendiente':
                    this.setState({
                        Solicitud: SolicitudService.ObtenerObjetoEstado(Obj, PKID, 1)
                    }, () => this.CrearActualizar())
                    break
                case 'EnCurso':
                    this.setState({
                        Solicitud: SolicitudService.ObtenerObjetoEstado(Obj, PKID, 2)
                    }, () => this.CrearActualizar())
                    break
                case 'Hecho':
                    this.setState({
                        Solicitud: SolicitudService.ObtenerObjetoEstado(Obj, PKID, 3)
                    }, () => this.CrearActualizar())
                    break
                default:
                    break
            }
        }
    }

    async CrearActualizar() {
        SolicitudService.CrearActualizar(this.state.Solicitud)
            .then((response: any) => {
                alert(response.data)
                if (response.success) {
                    this.setState({
                        Pendiente: { ...this.cPaginacion, Paginacion: { ...this.cPaginacion.Paginacion, IDEstado: 1 } },
                        EnCurso: { ...this.cPaginacion, Paginacion: { ...this.cPaginacion.Paginacion, IDEstado: 2 } },
                        Hecho: { ...this.cPaginacion, Paginacion: { ...this.cPaginacion.Paginacion, IDEstado: 3 } }
                    }, () => {
                        this.HideVisibleModal(false)
                        this.componentDidMount()
                    })
                }
            })
            .catch((Error) => {
                alert(Error)
            })
            .finally(() => {
                this.setState({ Request: false })
            })
    }

    HandleChange = (Event: any) => {
        switch (Event.target.name) {
            case 'Ticket':
                this.setState({
                    Solicitud: {
                        ...this.state.Solicitud,
                        TicketAsociado: {
                            PKID: Event.value ? Event.value.code : 0,
                            Nombre: Event.value ? Event.value.name : ''
                        }
                    }
                }, () => Event.value ? this.ConsultarTicket() : this.setState({ Solicitud: this.cSolicitud }))
                break
            case 'Cliente':
                this.setState({
                    Solicitud: {
                        ...this.state.Solicitud,
                        Cliente: {
                            PKID: Event.value.code,
                            Nombre: Event.value.name
                        }
                    }
                })
                break
            case 'Empleado':
                this.setState({
                    Solicitud: {
                        ...this.state.Solicitud,
                        Empleado: {
                            PKID: Event.value.code,
                            Nombre: Event.value.name
                        }
                    }
                })
                break
            case 'Producto':
                this.setState({
                    Solicitud: {
                        ...this.state.Solicitud,
                        Producto: {
                            PKID: Event.value.code,
                            Nombre: Event.value.name,
                            VersionProducto: Event.value.VersionProducto
                        }
                    },
                })
                break
            case 'VersionProducto':
                this.setState({
                    Solicitud: {
                        ...this.state.Solicitud,
                        VersionProducto: {
                            PKID: Event.value.code,
                            Version: Event.value.name
                        }
                    },
                })
                break
            default:
                this.setState({
                    Solicitud: {
                        ...this.state.Solicitud,
                        [Event.target.name]: Event.target.value
                    }
                })
                break
        }
    }

    async ConsultarTicket() {
        TicketService.Consultar({ PKID: this.state.Solicitud.TicketAsociado.PKID })
            .then((response: any) => {
                if (response.success) {
                    var Ticket = JSON.parse(response.data)
                    this.setState({
                        Solicitud: {
                            ...this.state.Solicitud,
                            Nombre: Ticket.Nombre,
                            Cliente: {
                                PKID: Ticket.Cliente.PKID,
                                Nombre: Ticket.Cliente.Nombre
                            },
                            Empleado: {
                                PKID: Ticket.Responsable.Empleado.PKID,
                                Nombre: Ticket.Responsable.Empleado.Nombre
                            },
                            TipoSolicitud: {
                                PKID: 2,
                                Nombre: "Requerimiento de Mantenimiento"
                            }
                        }
                    })
                } else {
                    alert(response.data.data)
                }
            })
            .catch((error) => {
                alert(error)
            })
    }

    HideVisibleModal = (Mode: boolean) => {
        this.setState({
            Modal: Mode
        })
    }

    HandleChangeBuscador = (e: any) => {
        const ListaPendienteOriginal = this.state.ListaPendienteOriginal.Lista
        const ListaEnCursoOriginal = this.state.ListaEnCursoOriginal.Lista
        const ListaHechoOriginal = this.state.ListaHechoOriginal.Lista

        const ListaPendienteFiltoBefore = this.state.ListaPendienteOriginal.Lista
        const ListaEnCursoFiltroBefore = this.state.ListaEnCursoOriginal.Lista
        const ListaHechoFiltroBefore = this.state.ListaHechoOriginal.Lista

        if (e.target.value.length === 0) {

            this.setState({
                Pendiente: {
                    ...this.state.Pendiente,
                    Lista: ListaPendienteOriginal
                }
            })
            this.setState({
                EnCurso: {
                    ...this.state.EnCurso,
                    Lista: ListaEnCursoOriginal
                }
            })
            this.setState({
                Hecho: {
                    ...this.state.Hecho,
                    Lista: ListaHechoOriginal
                }
            })
        } else {

            const listaFiltradaPendiente = ListaPendienteFiltoBefore.filter((list) => list.Nombre.toLowerCase().includes(e.target.value.toLowerCase()))
            const listaFiltradaEnCurso = ListaEnCursoFiltroBefore.filter((list) => list.Nombre.toLowerCase().includes(e.target.value.toLowerCase()))
            const listaFiltradaHecho = ListaHechoFiltroBefore.filter((list) => list.Nombre.toLowerCase().includes(e.target.value.toLowerCase()))

            this.setState({
                Pendiente: {
                    ...this.state.Pendiente,
                    Lista: listaFiltradaPendiente
                }
            })

            this.setState({
                EnCurso: {
                    ...this.state.EnCurso,
                    Lista: listaFiltradaEnCurso
                }
            })

            this.setState({
                Hecho: {
                    ...this.state.Hecho,
                    Lista: listaFiltradaHecho
                }
            })
        }
    }

    render() {
        return (
            <div className="container">
                <SolicitudHeader
                    OnClick={this.OnResponse}
                    OnChangeBuscador={this.HandleChangeBuscador} />
                <SolicitudBody
                    Paginacion={this.Paginacion}
                    Pendiente={this.state.Pendiente}
                    EnCurso={this.state.EnCurso}
                    Hecho={this.state.Hecho}
                    OnResponse={this.OnResponse}
                    MoverEstado={this.MoverEstado} />
                <form autoComplete="Off" onSubmit={this.HandleSubmit}>
                    <ModalSolicitud
                        Modal={this.state.Modal}
                        HideModal={() => this.HideVisibleModal(false)}
                        HandleChange={this.HandleChange}
                        FormValues={this.state.Solicitud}
                        ListaTickets={this.state.ListaTicket}
                        ListaClientes={this.state.ListaClientes}
                        ListaEmpleados={this.state.ListaEmpleados}
                        ListaProductos={this.state.ListaProducto}
                        Errores={this.state.Errores}
                        Request={this.state.Request} />
                </form>
            </div>
        )
    }
}