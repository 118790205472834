import { HTTP } from "../Http/Http"

export const EmpleadoRepository = {
    Listar: async () => {
        return await HTTP.POST('Empleado/ListarEmpleados','',{})
    },
    ObtenerEmpleado: async (IDUsuario:any) => {
        return await HTTP.POST('Empleado/ObtenerEmpleado?IDUsuario='+IDUsuario,'',{})
    },
    ListarEmpleadoDesarrollo: async () => {
        return await HTTP.POST('Empleado/ListarEmpleados','',{})
    },
    ListarEmpleadoImplantacion: async () => {
        return await HTTP.GET('Empleado/ListarEmpleadosImplantacion')
    },
    CrearActualizar: async (body : any) => {
        return await HTTP.POST('Empleado/CrearActualizarEmpleado',body,{})
    }
}