import React from 'react'
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Captcha } from 'primereact/captcha';
import ModalCambioContraseña from '../Components/ModalCambioContraseña'
import { EliminarTodo } from '../../Services/LocalStorage'
import { UsuarioService } from '../../Domain/Services/UsuarioService'
import { ICredencial } from '../../Domain/Models/ICredencial'
import { ICambioContraseña} from '../../Domain/Models/ICambioContraseña'
import { AuthHelper } from '../../Domain/Utils/AuthHelper';
import '../Assets/Css/index.css'
import Desarrollo from '../Routes/Desarrollo';
import Implementacion from '../Routes/Implementacion';


interface IProps {
}

interface IState {
    Form: ICredencial
    CambioContraseña: ICambioContraseña
    Modal: boolean
    Request: boolean
}

export default class Login extends React.Component<IProps, IState> {

    cForm = {
        grant_type: '',
        code: '',
        redirect_uri: '',
        Credencial: '',
        Contraseña: '',
        refresh_token: '',
        isValid: false
    }

    cCambioContraseña = {
        IDUsuario : 0,
        AntiguaContrasena : '',
        NuevaContrasena : '',
        RepetirNuevaContrasena : ''
    } 

    constructor(props : any){
        super(props)
        this.state = {
            Form: this.cForm,
            CambioContraseña: this.cCambioContraseña,
            Modal: false,
            Request: false
        }
    }

    componentDidMount(){
        EliminarTodo()
    }

    ConsultarUsuario = (Event : React.ChangeEvent<HTMLFormElement>) => {
        Event.preventDefault()
        this.setState({
            Request: true
        },() => {
            UsuarioService.Autenticar(this.state.Form)
            .then((data : any) => {
                if(data.success){
                    let Token = data.data.access_token
                    AuthHelper.Save(Token)
                    
                    const isAuth = !!localStorage.getItem("TOKEN-UN1FL3X");
                        if(AuthHelper.GetDecoded().IsNuevo === 'True'){
                            this.setState({
                                CambioContraseña:{
                                    ...this.state.CambioContraseña,
                                    IDUsuario: parseInt(AuthHelper.GetDecoded().sub,10)
                                }
                            })
                        }
                        
                        if (isAuth) {
                            if(AuthHelper.GetDecoded().Area === 'Desarrollo'){
                                window.location.assign('Desarrollo')
                            }else if(AuthHelper.GetDecoded().Area === 'Implementacion'){
                                window.location.assign('Desarrollo/TareoImplantacion')
                            }else if(AuthHelper.GetDecoded().Area === 'Administracion'){
                                window.location.assign('Desarrollo/Asistencia')
                            }
                        }
                }else{
                    alert(data.data)
                }
            })
            .catch((error) => {
                alert(error)
            })
            .finally( () => {
                this.setState({Request: false})
            })
        })
    }

    HandleChange = (Event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            Form:{
                ...this.state.Form,
                [Event.target.name]: Event.target.value
            }
        })
    }

    HandleChangeContraseña = (Event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            CambioContraseña:{
                ...this.state.CambioContraseña,
                [Event.target.name]: Event.target.value
            }
        })
    }

    CambioContraseña = (Event : React.ChangeEvent<HTMLFormElement>) => {
        Event.preventDefault()
        this.setState({
            Request: true
        }, () => {
            UsuarioService.CambiarContraseña(this.state.CambioContraseña)
            .then((data : any) => {
                if(data.success){
                    window.location.assign(AuthHelper.GetDecoded().Area)
                }else{
                    alert(data.data)
                }
            })
            .catch((error) => {
                alert(error)
            })
            .finally( () => {
                this.setState({Request: false})
            })
        })
    }

    LimpiarContraseña = () => {
        EliminarTodo()
        this.setState({
            Form: this.cForm,
            CambioContraseña: this.cCambioContraseña,
            Modal: false
        })
    }

    OnResponseCaptcha = (response : any)=>{
        this.setState({
            Form: {
                ...this.state.Form,
                isValid: true
            }
        })
    }

    render(){
        return(
            <div className="authBox">
                <div className="LeftBox">
                    <div className="imageAuth"></div>
                </div>
                <div className="RightBox">
                    <div className="box">
                        <div className="titleAuth">Trellium</div>
                            <form autoComplete="off" onSubmit={this.ConsultarUsuario}>
                                <div className="p-col-fixed p-mt-4">
                                    <div className="p-grid p-fluid">
                                        <div className="p-col-12 p-mt-3">
                                            <InputText 
                                                value={this.state.Form.Credencial}
                                                name="Credencial"
                                                onChange={this.HandleChange}
                                                type="text" 
                                                placeholder="Usuario"/>
                                        </div>
                                    </div>
                                    <div className="p-grid p-fluid">
                                        <div className="p-col-12 p-mt-3">
                                            <InputText 
                                                value={this.state.Form.Contraseña}
                                                name="Contraseña"
                                                onChange={this.HandleChange}
                                                type="password"
                                                placeholder="Contraseña"/>
                                        </div>
                                    </div>
                                    <div className="card p-mt-3">
                                        <Captcha 
                                            siteKey="6LcSJtgZAAAAAJMnW7B5lVApMbfycAOKXvLDgWJB" 
                                            onResponse={this.OnResponseCaptcha}/>
                                    </div>
                                <div className="p-d-flex p-p-3 p-mt-1 p-ml-auto">
                                    <Button 
                                        label={this.state.Request ? "Cargando..." : "Ingresar"}
                                        disabled={this.state.Request}
                                        className="p-ml-auto"
                                        style={{
                                            background:'#4D80E4',
                                            border: '1px solid #4D80E4'}}/>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <form autoComplete="off" onSubmit={this.CambioContraseña}>
                    <ModalCambioContraseña
                        Modal = {this.state.Modal}
                        HideModal = {this.LimpiarContraseña}
                        OnChangeContraseña = {this.HandleChangeContraseña}
                        CambioContraseña = {this.state.CambioContraseña}
                        Request = {this.state.Request}/>
                </form>
            </div>
        )
    }
}
