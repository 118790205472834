import { PrioridadRepository } from '../../Infrastructure/Repositories/PrioridadRepository'
import { IPrioridad } from '../Models/IPrioridad'


interface IErroresPrioridad{
    Descripcion?: string
}


const ValidarPrioridad = (Prioridad: IPrioridad) => {
    const errores: IErroresPrioridad ={}
    if(!Prioridad.Descripcion) errores.Descripcion  = 'Descripción de la prioridad es requerida'
    return errores
}


export const PrioridadService = {
    ValidarPrioridad,   
    Listar: () => {
        return PrioridadRepository.Listar()
    }, 
    ListarPaginacion: (Paginacion: any) => {
        return PrioridadRepository.ListarPaginacion(Paginacion)
    },   
    CrearActualizar: (Prioridad: IPrioridad) => {
        return PrioridadRepository.CrearActualizar(Prioridad)
    }
}