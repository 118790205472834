import React,{useEffect,useCallback, useRef,useState} from 'react'
import './../Assets/css/CuerpoTareo.css'
import { Menu } from 'primereact/menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTicketAlt } from '@fortawesome/fontawesome-free-solid'
import useNearScreen from '../../Infrastructure/Components/useNearScreen'
import debounce from 'just-debounce-it'
import SpeedDial from '@mui/material/SpeedDial';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import Box from '@mui/material/Box';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import { makeStyles } from "@material-ui/core/styles";
import { icon } from '@fortawesome/fontawesome-svg-core';


export default function CuerpoTareo(props){


    const useStyles = makeStyles({
        tooltip: {
          fontSize: '10px',
          width:'10px',
          height:'10px'
        },        
          staticTooltipLabel: {
            backgroundColor : 'red',
            width : '10px',
          },
        fontSize:'10px',
        width:'10px',
        height:'10px'
        });

      const classes = useStyles();

    const actions = [
        { icon: <FileCopyIcon />, name: 'Copiar Tarea' }        
      ];

      
   
    const items = [
        {
            label : 'Requisito',
            name : 'Requisito',
            icon : 'fas fa-file-signature',
            command: props.agregarTarea
        },
        {
            label : 'Ticket',
            name : 'Ticket',
            icon : 'fas fa-ticket-alt',
            className :'lb',
            command : props.agregarTarea
        },
        {
            label : 'Incidente',
            name : 'Incidente',
            icon : 'fas fa-ticket-alt',
            className :'lb',
            command : props.agregarTarea
        }
    ]
    const refPendiente = useRef()
    const refEnCurso = useRef()
    const refHecho = useRef()

    const isNSPendiente = useNearScreen({
        externalRef :  refPendiente,
        once: false
    }).isNearScreen

    const isNSEnCurso = useNearScreen({
        externalRef : !props.Loading && refEnCurso,
        once: false
    }).isNearScreen

    const isNSHecho = useNearScreen({
        externalRef : refHecho,
        once: false
    }).isNearScreen

    const [menu,setMenu] = useState(null)

    const nextPagePendiente = useCallback(debounce(
        () => (props.Paginacion('Pendiente','PaginacionP','LoadingP')),1500
    ), [])

    const debounceNextPage = useCallback(debounce(
        () => (props.Paginacion('EnCurso','PaginacionEC','LoadingEC')), 1500
    ), [])

    const nextPageHecho = useCallback(debounce(
        () => (props.Paginacion('Hecho','PaginacionH','LoadingH')), 1500
    ), [])

    useEffect(function (){
        if(isNSPendiente) nextPagePendiente();
        if(isNSEnCurso) debounceNextPage();
        if(isNSHecho) nextPageHecho();
    })

    const dragStart = e => {
        e.dataTransfer.setData('EstadoPKID',e.target.id)
    }

    const dragOver = e =>{
        e.preventDefault()
        e.stopPropagation()
    }

    const DropPendiente = e => {
        e.preventDefault()
        props.MoverEstado(e.dataTransfer.getData('EstadoPKID').split('-')[0],'Pendiente', e.dataTransfer.getData('EstadoPKID').split('-')[1])
    }

    const DropEnCurso = e => {
        e.preventDefault()
        props.MoverEstado(e.dataTransfer.getData('EstadoPKID').split('-')[0],'EnCurso', e.dataTransfer.getData('EstadoPKID').split('-')[1])
    }

    const DropHecho = e => {
        e.preventDefault()
        props.MoverEstado(e.dataTransfer.getData('EstadoPKID').split('-')[0],'Hecho', e.dataTransfer.getData('EstadoPKID').split('-')[1])
    }

    const renderListado = ( ( ListaTarea, Estado) => {
        if(ListaTarea===null || ListaTarea===undefined) return
        let Lista = ListaTarea.map((entidad,index) => {
            return(
                <li
                    id = {Estado + '-' + entidad.PKID}
                    className = "comment-list-li"
                    draggable
                    onDragStart = {dragStart}
                    onDragOver = {dragOver}
                    key = {Estado + '-' + entidad.PKID}
                    onClick={ () => props.editarTarea(entidad)}>
                    <div className="comment-main-level">
                        <div className="comment-box">
                            <div className="comment-head">

                            <SpeedDial                             
                            direction='right'                                
        ariaLabel="SpeedDial basic example"
        sx={{ position: 'absolute', bottom: 0, left: 0 }}        
        icon={<SpeedDialIcon />}  
        FabProps={{     
            size:'small',   
            textShadow:'0',  
            boxShadow:'0',            
            sx: {              
                backgroundColor:'#fff',
                color:'black',  
                textShadow:'0',  
                boxShadow:'0',                
                '&:hover': {
                    bgcolor: '#fff',
                  }                                    
            },
            
          }}      
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}             
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={e => {
                e.stopPropagation();                
                props.copiarTarea(entidad);
              }}
              
          />
        ))}
      </SpeedDial>

                                <h6 className="comment-name by-author" >
                                    {entidad.Nombre}
                                </h6>
                                {
                                entidad.Ticket?
                                <div className="estrella2" >                                   
                                 <FontAwesomeIcon icon={faTicketAlt} />
                                </div>                        
                                :null}
                                {
                                entidad.Ejecucion?
                                <div className="estrella" >
                                    <i className="fas fa-stopwatch"></i>
                                </div>
                                :null}
                            </div>
                            <div className="comment-content">
                                <div>
                                    <table className="table table-bordered table-sm">
                                        <thead>
                                            <tr>
                                                <th scope="col">Horas Planificadas</th>
                                                <th scope="col">Horas Ejecutadas</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td >{parseFloat(Math.round(entidad.HorasPlanificadas * 100) / 100).toFixed(2)}</td>
                                                <td >{parseFloat(Math.round(entidad.HorasEjecutadas* 100) / 100).toFixed(2)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            )
        })
        return Lista;
    })

    return(
        <div className="ContainerTrello">
            <div className="p-grid" style={{margin: '0px'}}>
                <div className="p-col-12 p-md-6 p-lg-4">
                    <Menu model={items} popup ref={el => setMenu(el)} id="popup_menu" />
                    <div className="list-wrapper">
                        <div className="list" style={{height:'82vh'}}>
                            <div className="list-header is-menu-shown">
                                <h2 className="list-header-name-assist">Pendiente</h2>
                                <div className="open-card-composer" onClick={(event) => menu.toggle(event)}>
                                    <i className="pi pi-plus icon-sm"></i>
                                    <span>Nuevo</span>
                                </div>
                            </div>
                            <div 
                                className="list-cards"
                                onDrop={DropPendiente}
                                onDragOver={dragOver}>
                                <ul id="lPendiente" className="comment-list">
                                    {renderListado(props.ListaTareasPendientes,'Pendiente')}
                                </ul>
                                <div ref={refPendiente}>
                                    {((props.PaginacionP.NumeroPagina < props.PaginacionP.NumeroPaginas) || props.LoadingP)? 
                                        <div className="circleLoad"></div> 
                                        : 
                                        <></>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-col-12 p-md-6 p-lg-4">
                    <div className="list-wrapper">
                        <div className="list" style={{height:'82vh'}}>
                            <div className="list-header is-menu-shown">
                                <h2 className="list-header-name-assist">En Curso</h2>
                            </div>
                            <div 
                                className="list-cards"
                                onDrop={DropEnCurso}
                                onDragOver={dragOver}>
                                <ul id="lEnCurso" className="comment-list">
                                    {renderListado(props.ListaTareasEnCurso,'EnCurso')}
                                </ul>
                                <div ref={refEnCurso}>
                                    {((props.PaginacionEC.NumeroPagina < props.PaginacionEC.NumeroPaginas) || props.LoadingEC)?
                                        <div className="circleLoad"></div> 
                                        : 
                                        <></>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-col-12 p-md-6 p-lg-4">
                    <div className="list-wrapper">
                        <div className="list" style={{height:'82vh'}}>
                            <div className="list-header is-menu-shown">
                                <h2 className="list-header-name-assist">Hecho</h2>
                            </div>
                            <div 
                                className="list-cards"
                                onDrop={DropHecho}
                                onDragOver={dragOver}>
                                <ul id="lHecho" className="comment-list">
                                    {renderListado(props.ListaTareasHecho,'Hecho')}
                                </ul>
                                <div ref={refHecho}>
                                    {((props.PaginacionH.NumeroPagina < props.PaginacionH.NumeroPaginas) || props.LoadingH)?
                                        <div className="circleLoad"></div> 
                                        : 
                                        <></>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}