import React from 'react'
import MotivoVersionamientoBody from '../Components/MotivoVersionamientoBody'
import MotivoVersionamientoModal from '../Components/MotivoVersionamientoModal'
import { MotivoVersionamientoService } from '../../Domain/Services/MotivoVersionamientoService'

import { IMotivoVersionamiento } from '../../Domain/Models/IMotivoVersionamiento'

interface IProps {
}

interface IState {
    Paginado: any
    Registros: Array<object>
    ModalMotivoVersionamiento: boolean
    MotivoVersionamiento : IMotivoVersionamiento    
    ListaCliente: Array<object>
    ListaMotivoVersionamiento: Array<object>
    ErrorMotivoVersionamiento: any    
    Request: boolean
}

export default class MotivoVersionamiento extends React.Component<IProps, IState> {

    cPaginado = {
        NumeroPagina : 1,
        RegistrosPagina : 10,
        NumeroPaginas : 0,
        fil_Nombre : '',
        fil_Modulo : '',
        fil_Responsable : ''
    }

    cRegistro = [
        { RegistrosPagina: 10 },
        { RegistrosPagina: 20 },
        { RegistrosPagina: 30 }
    ]

    cMotivoVersionamiento = {
        PKID : 0,
        Descripcion : ''       
    }

    

    constructor(props: any){
        super(props)
        this.state = {
            Paginado : this.cPaginado,
            Registros : this.cRegistro,
            ModalMotivoVersionamiento : false,
            MotivoVersionamiento : this.cMotivoVersionamiento,  
            ListaCliente : [],
            ListaMotivoVersionamiento : [],
            ErrorMotivoVersionamiento : {},            
            Request: false
        }
    }

    componentDidMount(){
        this.ListarPaginacion()
    }

    ListarPaginacion(){
        MotivoVersionamientoService.ListarPaginacion(this.state.Paginado)
        .then((response: any) => {
            if(response.success){
                this.setState({
                    Paginado: JSON.parse(response.data).Paginacion,
                    ListaMotivoVersionamiento: JSON.parse(response.data).MotivoVersionamiento
                })
            }
        })
        .catch((Error: any) => {
            alert(Error)
        })
    }

    OnResponse = (MotivoVersionamiento: any) => {
        if(MotivoVersionamiento){
            this.setState({ 
                MotivoVersionamiento : MotivoVersionamiento,
                ErrorMotivoVersionamiento: {}
            },() => this.HideVisibleModalMotivoVersionamiento(true))
        }else{
            this.setState({ 
                MotivoVersionamiento : this.cMotivoVersionamiento,
                ErrorMotivoVersionamiento: {}
            },() => this.HideVisibleModalMotivoVersionamiento(true))
        }
    }

    HideVisibleModalMotivoVersionamiento = (Mode: boolean) => {
        this.setState({
            ModalMotivoVersionamiento: Mode
        })
    }

    HandleChangeMotivoVersionamiento = (Event: any) =>{
        this.setState({
            MotivoVersionamiento: {
                ...this.state.MotivoVersionamiento,
                [Event.target.name]: Event.target.value
            }
        })
    }

    HandleSubmitMotivoVersionamiento = (Event: any) =>{
        Event.preventDefault()
        const result = MotivoVersionamientoService.ValidarMotivoVersionamiento(this.state.MotivoVersionamiento);
        if(Object.keys(result).length){
            return this.setState({ErrorMotivoVersionamiento : result});
        }else{
            this.setState({
                Request: true
            }, () => this.CrearActualizarMotivoVersionamiento())
        }
    }

    CrearActualizarMotivoVersionamiento(){
        MotivoVersionamientoService.CrearActualizar(this.state.MotivoVersionamiento)
        .then((response: any) => {
            if(response.success){
                var json = JSON.parse(response.data)
                if(json.PKID){
                    this.setState({
                        ModalMotivoVersionamiento : !this.state.ModalMotivoVersionamiento,
                        MotivoVersionamiento : this.cMotivoVersionamiento,
                        Paginado: this.cPaginado
                    },() => this.ListarPaginacion())
                }
            }else{
                alert(response.data)
            }
        })
        .catch((Error) => {
            alert(Error)
        })
        .finally(() => {
            this.setState({
                Request: false
            })
        })
    }

    HandleSelectRow = (MotivoVersionamiento: IMotivoVersionamiento) => {
        this.setState({ 
            MotivoVersionamiento : MotivoVersionamiento,
            ErrorMotivoVersionamiento : {}
        },() => this.HideVisibleModalMotivoVersionamiento(true))
    }

    

  

   

    OrdenarArray = (Array: Array<any>) => {
        return Array.sort(function (a, b) {
            if (a.Orden > b.Orden) return -1
            if (a.Orden < b.Orden) return 1
            return 0;
        })
    }

    

    OnRegistroChange = (Event: any) =>{
        this.setState({
            Paginado : {
                ...this.state.Paginado,
                NumeroPagina: 1,
                RegistrosPagina: Event.value.RegistrosPagina,
        }},() =>{ this.ListarPaginacion() })
    }

    HandlePreviousLL = (Event: any) =>{
        this.setState({
            Paginado : this.cPaginado
        },() =>{ this.ListarPaginacion() })
    }

    HandlePrevious = (Event: any) =>{
        this.setState({Paginado : {
            ...this.state.Paginado,
            NumeroPagina: this.state.Paginado.NumeroPagina - 1,
        }},() =>{ this.ListarPaginacion() })
    }

    HandleFollowing = () => {
        this.setState({Paginado : {
            ...this.state.Paginado,
            NumeroPagina: this.state.Paginado.NumeroPagina + 1,
        }},() =>{ this.ListarPaginacion() })
    }

    HandleFollowingLL = () => {
        this.setState({Paginado : {
            NumeroPagina: this.state.Paginado.NumeroPaginas,
            RegistrosPagina: this.state.Paginado.RegistrosPagina,
            NumeroPaginas: this.state.Paginado.NumeroPaginas,
            fil_Nombre : this.state.Paginado.fil_Nombre
        }},() =>{ this.ListarPaginacion() })
    }

    render(){
        return(
            <div className="container">
                <MotivoVersionamientoBody
                    ListaMotivoVersionamiento={this.state.ListaMotivoVersionamiento}
                    OnResponse={this.OnResponse}
                    Paginado={this.state.Paginado}
                    Registros= {this.state.Registros}
                    OnRegistroChange = {this.OnRegistroChange}
                    HandlePreviousLL = {this.HandlePreviousLL}
                    HandlePrevious = {this.HandlePrevious}
                    HandleFollowing = {this.HandleFollowing}
                    HandleFollowingLL = {this.HandleFollowingLL}/>
                    <form autoComplete="Off" onSubmit={this.HandleSubmitMotivoVersionamiento}>
                        <MotivoVersionamientoModal
                            Modal = {this.state.ModalMotivoVersionamiento}
                            FormValues = {this.state.MotivoVersionamiento}                            
                            HideModal = {this.HideVisibleModalMotivoVersionamiento}
                            HandleChange = {this.HandleChangeMotivoVersionamiento}
                            ErrorMotivoVersionamiento = {this.state.ErrorMotivoVersionamiento}
                            HandleSelectRow={this.HandleSelectRow}
                            Request={this.state.Request}/>
                    </form>
                    
            </div>
        )
    }
}