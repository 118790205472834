import { HTTP } from "../Http/Http"

export const AsistenciaRepository = {
    ListarAsistencia: async (Asistencia: any) => {
        return await HTTP.POST('Asistencia/ListarAsistencia',Asistencia,{})
    },
    ActualizarAsistencia: async (Asistencia: any) => {
        return await HTTP.POST('Asistencia/ActualizarAsistencia', Asistencia, {})
    },
    CrearActualizarFiltro: async (Filtro: any) => {
        return await HTTP.POST('Asistencia/CrearActualizarFiltro', Filtro, {})
    },
    ObtenerFiltro: async (IDUsuario: number) => {
        return await HTTP.POST('Asistencia/ObtenerFiltro?IDUsuario='+IDUsuario,'',{})
    },
    LimpiarFiltro: async (IDUsuario : number) => {
        return await HTTP.POST('Asistencia/LimpiarFiltro?IDUsuario='+IDUsuario+'&IDTipo='+6,'',{})
    },
}