import React from 'react'
import { InputText } from 'primereact/inputtext'
//import { CKEditor } from '@ckeditor/ckeditor5-react'
import { FileUpload } from 'primereact/fileupload'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
//import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { InputTextarea } from 'primereact/inputtextarea'

interface IProps{
    HandleChange: any
    SubirArchivo: any
    BorrarArchivo: any
    DescargarArchivo: any
    FormValues: any
    ImageArchivo: any
    FechaCreacion: any
    TamañoArchivo: any
}

export default function RequerimientoModalSolucion(Props : IProps){

    const File = React.createRef<FileUpload>()

    /*const DescripcionOCS = (Event, Editor) => {
        const Data = Editor.getData();
        Props.HandleChange({
            target:{
                name: "DescripcionOCS",
                value: Data
            }
        })
    }*/

    const Header = (
        <div className="p-d-flex p-jc-between">
            <FileUpload 
                ref={File}
                mode="basic" 
                name="ArchivosSolucionAdjuntos" 
                auto 
                uploadHandler={(Event) => Props.SubirArchivo(Event,File,'ArchivosSolucionAdjuntos')}
                customUpload
                className="p-ml-auto"
                chooseLabel="Buscar archivo"/>
        </div>
    )

    const OperacionArchivo = (Event: any) =>{
        return(
            <React.Fragment>
                <div className="p-grid p-fluid ">
                    <div className="p-col-6">
                        <Button 
                            type="button"
                            icon="pi pi-times" 
                            style={{width:'30px', height:'30px'}}
                            className="p-button-rounded p-button-outlined"
                            onClick={ () => { 
                                Props.BorrarArchivo(Event, 'ArchivosSolucionAdjuntos')
                            }}/>
                    </div>
                    <div className="p-col-6">
                        <Button 
                            type="button"
                            icon="pi pi-download" 
                            style={{width:'30px', height:'30px'}}
                            className="p-button-rounded p-button-outlined"
                            onClick={ () => { 
                                Props.DescargarArchivo(Event, 'ArchivosSolucionAdjuntos')
                            }}/>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    const abrirURL = () => {
        const { LinkOCS } = Props.FormValues;
        if (LinkOCS) {
          window.open(LinkOCS, '_blank');
        }
      };

    return(
        <div>
            <div className="modal-body p-mt-3">
                <div className="p-grid nested-grid">
                    <div className="p-col-12 p-lg-6">
                        <div className="p-col">
                            <div className="p-grid p-fluid ">
                                <div className="p-col-12">
                                    <label 
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'}}
                                    >Numero OCS</label>
                                    <InputTextarea 
                                    value={Props.FormValues.NumeroOCS? Props.FormValues.NumeroOCS : ''}
                                    onChange={Props.HandleChange}
                                    name="NumeroOCS"
                                    type="text" 
                                    rows={4} 
                                    cols={10}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-col-12 p-lg-6">
                        <div className="p-col">
                            <div className="p-grid p-fluid ">
                                <div className="p-col-12">
                                    <label 
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'}}
                                    >Descripcion OCS</label>
                                     <InputTextarea 
                                    value={Props.FormValues.DescripcionOCS? Props.FormValues.DescripcionOCS : ''}
                                    onChange={Props.HandleChange}
                                    name="DescripcionOCS"
                                    type="text" 
                                    rows={4} 
                                    cols={10}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="p-col-12 p-lg-6">
                        <div className="p-col">
                            <div className="p-grid p-fluid ">
                                <div className="p-col-12">
                                    <label 
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'}}
                                    >Link OCS</label>
                                </div>
                                <div className="p-col-11">
                                    <InputText
                                    value={Props.FormValues.LinkOCS? Props.FormValues.LinkOCS : ''}
                                    onChange={Props.HandleChange}
                                    type="text"
                                    name="LinkOCS"
                                    placeholder="https://"
                                    />
                                </div>
                                <div className="p-col-1">
                                    <Button 
                                    type="button"
                                    icon="pi pi-arrow-circle-right" 
                                    className="p-button-rounded p-button-outlined" 
                                    onClick={abrirURL}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="p-col-12">
                        <div className="p-col">
                            <div className="p-grid p-fluid ">
                                <div className="p-col-12">
                                    <DataTable 
                                        value={Props.FormValues.ArchivosSolucionAdjuntos}
                                        paginator
                                        rows={5}
                                        header={Header}
                                        className="p-datatable-sm">
                                        <Column body={Props.ImageArchivo} header="T. de archivo"></Column>
                                        <Column field="Nombre" header="Nombre"></Column>
                                        <Column body={Props.FechaCreacion} header="Fecha creación"/>
                                        <Column body={Props.TamañoArchivo} header="Tamaño"></Column>
                                        <Column body={OperacionArchivo} header="Operación"></Column>
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}