import { HTTP } from '../Http/Http'

export const IncidenteRepository = {
    ListarPaginacion: async (Paginacion : any) => {
        return await HTTP.POST('Incidencia/ListarPaginacion',Paginacion,{})
    },
    CrearActualizar: async (Incidente : any) => {
        return await HTTP.POST('Incidencia/CrearActualizar',Incidente,{})
    },
}

