import { HTTP } from "../Http/Http"

export const MotivoVersionamientoRepository = {
    Listar: async() =>{
        return await HTTP.POST('MotivoVersionamiento/Listar','',{})
    },
    ListarPaginacion: async(Paginacion: any) =>{
        return await HTTP.POST('MotivoVersionamiento/ListarPaginacion',Paginacion,{})
    },    
    CrearActualizar: async (MotivoVersionamiento: any) => {
        return await HTTP.POST('MotivoVersionamiento/CrearActualizar', MotivoVersionamiento, {})
    }

}