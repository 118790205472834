import { ItemRequerimientoRepository } from "../../Infrastructure/Repositories/ItemRequerimientoRepository"
import { IITemRequerimiento } from "../Models/IITemRequerimiento"
import { IRequerimiento } from "../Models/IRequerimiento"
import { IPaginacion } from "../Models/IPaginacion"

interface IErroresItemRequerimiento{
    Requisito?: string
    Estimacion?: string
    FactorAjuste?: string
    FechaFin?: string
    FechaInicio?: string
}

const ValidarSchema = (ItemRequerimiento: IITemRequerimiento) => {
    const errores: IErroresItemRequerimiento = {}
    if(!ItemRequerimiento.Requisito) errores.Requisito  = 'Requisito es requerido'
    if(!ItemRequerimiento.Estimacion) if(ItemRequerimiento.Estimacion !== 0) errores.Estimacion = 'Horas estimadas es requerido'
    if(!ItemRequerimiento.FactorAjuste) errores.FactorAjuste = 'Factor ajuste es requerido'
    if(!ItemRequerimiento.FechaFin) errores.FechaFin = 'Fecha de finalizacion es requerido'
    if(!ItemRequerimiento.FechaInicio) errores.FechaInicio = 'Fecha de inicio es requerido'
    return errores
}

const ListarPaginacion = (Paginacion: any) => {
    return ItemRequerimientoRepository.ListarPaginacion(Paginacion)
}

const ObtenerObjetoEstado = (Array: Array<IITemRequerimiento>, PKID: string, IDEstado: number) => {
    let aObj: Array<IITemRequerimiento> =  Array.filter( (Obj : IITemRequerimiento) =>{
        return Obj.PKID === parseInt(PKID,10) 
    })
    let Obj: IITemRequerimiento = aObj[0] 
    return {
        ...Obj,
        Estado:{
            ...Obj.Estado,
            PKID: IDEstado
        }
    }
}

const CrearActualizar = (ItemRequerimiento: IITemRequerimiento, Requerimiento: IRequerimiento) => {
    
    const itemRequerimiento_fechaInicio = new Date(ItemRequerimiento.FechaInicio);
    const itemRequerimiento_fechaFin = new Date(ItemRequerimiento.FechaFin);
    const requerimiento_fechaInicio = new Date(Requerimiento.FechaInicio);
    const requerimiento_fechaFin = new Date(Requerimiento.FechaFin);

    itemRequerimiento_fechaInicio.setHours(0,0,0,0);
    itemRequerimiento_fechaFin.setHours(0,0,0,0);
    requerimiento_fechaInicio.setHours(0,0,0,0);
    requerimiento_fechaFin.setHours(0,0,0,0);

    console.log("FECHA INICIO REQUERIMIENTO : "+requerimiento_fechaInicio)
    console.log("FECHA FIN REQUERIMIENTO : "+requerimiento_fechaFin)
    console.log("FECHA INICIO ITEM REQUERIMIENTO : "+itemRequerimiento_fechaInicio)
    console.log("FECHA FIN ITEM REQUERIMIENTO : "+itemRequerimiento_fechaFin)

    console.log(ItemRequerimiento)

    if(itemRequerimiento_fechaInicio.getTime() < requerimiento_fechaInicio.getTime() || itemRequerimiento_fechaFin.getTime() > requerimiento_fechaFin.getTime()){
        console.log("La fecha del item requerimiento no esta dentro de la fecha del requerimiento.")    
        return Promise.reject("La fecha del item requerimiento no esta dentro de la fecha del requerimiento.")

    }else{
        if(itemRequerimiento_fechaInicio.getTime()  > itemRequerimiento_fechaFin.getTime()){
            console.log("La fecha final del requerimiento debe ser mayor que la fecha inicial.")   
            return Promise.reject("La fecha final del requerimiento debe ser mayor que la fecha inicial.")
        }else{
            //return ItemRequerimientoRepository.CrearActualizar(Requerimiento) Antes estaba asi pero el patron dijo cambialo
            return ItemRequerimientoRepository.CrearActualizar(ItemRequerimiento)
        }
    }
}

export const ItemRequerimientoService = {
    ValidarSchema,
    ListarPaginacion,
    ObtenerObjetoEstado,
    CrearActualizar
}