import React, {useEffect ,useCallback} from 'react'
import useNearScreen from './useNearScreen'
import debounce from 'just-debounce-it'

interface IProps {
    ListaProyectos: any
    Paginacion: any
    Pendiente: any
    EnCurso: any
    Hecho: any
    CrearPlanDePruebas: any
    OnResponse: any
    MoverEstado: any
}

export default function TicketBody(Props : IProps){

    const RefPendiente = React.createRef<HTMLInputElement>()
    const RefEnCurso = React.createRef<HTMLInputElement>()
    const RefHecho = React.createRef<HTMLInputElement>()

    const NSPendiente = useNearScreen({
        externalRef :  !Props.Pendiente.Loading && RefPendiente,
        once: false
    }).isNearScreen

    const NSEnCurso = useNearScreen({
        externalRef : !Props.EnCurso.Loading && RefEnCurso,
        once: false
    }).isNearScreen

    const NSHecho = useNearScreen({
        externalRef : !Props.Hecho.Loading && RefHecho,
        once: false
    }).isNearScreen

    const DebounceNextPage = useCallback((Estado : string) => {
        debounce(Props.Paginacion(Estado),5000)
    },[])

    useEffect(function (){
        if(NSPendiente) DebounceNextPage('Pendiente')
        if(NSEnCurso) DebounceNextPage('EnCurso')
        if(NSHecho) DebounceNextPage('Hecho')
    })

    const DragStart = (Event : any) => {
        Event.dataTransfer.setData('EstadoPKID', Event.target.id)
    }

    const DragOver = (Event : React.SyntheticEvent) => {
        Event.preventDefault()
        Event.stopPropagation()
    }

    const DropPendiente = (Event : React.DragEvent) => {
        Event.preventDefault()
        Props.MoverEstado(Event.dataTransfer.getData('EstadoPKID').split('-')[0],'Pendiente', Event.dataTransfer.getData('EstadoPKID').split('-')[1])
    }

    const DropEnCurso = (Event : React.DragEvent) => {
        Event.preventDefault()
        Props.MoverEstado(Event.dataTransfer.getData('EstadoPKID').split('-')[0],'EnCurso', Event.dataTransfer.getData('EstadoPKID').split('-')[1])
    }

    const DropHecho = (Event : React.DragEvent) => {
        Event.preventDefault()
        Props.MoverEstado(Event.dataTransfer.getData('EstadoPKID').split('-')[0],'Hecho', Event.dataTransfer.getData('EstadoPKID').split('-')[1])
    }

    const RenderListadoProyectos = (Proyectos: any) => {
        let Lista = Proyectos.map((Entidad: any) => {
            return(
                <li
                    id={Entidad.PKID} 
                    className="comment-list-li"
                    key={Entidad.PKID} 
                    onClick={() => Props.CrearPlanDePruebas(Entidad)}>
                    <div className="comment-main-level">
                        <div className="comment-box">
                            <div className="comment-head">
                                <h6 className="comment-name by-author" >
                                    {Entidad.Nombre}
                                </h6>
                            </div>
                            <div className="comment-content">
                                <div>
                                    <table className="table table-bordered table-sm">
                                        <thead>
                                            <tr>
                                                <th scope="col">Fecha Inicio</th>
                                                <th scope="col">Fecha Fin</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td ><p>{Entidad.FechaInicio? new Date(Entidad.FechaInicio).getDate() + "/" + (new Date(Entidad.FechaInicio).getMonth() + 1) + "/" + new Date(Entidad.FechaInicio).getFullYear():''}</p></td>
                                                <td ><p>{Entidad.FechaFin? new Date(Entidad.FechaFin).getDate() + "/" + (new Date(Entidad.FechaFin).getMonth() + 1) + "/" + new Date(Entidad.FechaFin).getFullYear():''}</p></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            )
        })
        return Lista
    }

    const RenderListado = (PlanesDePruebas: any, Estado : string) => {
        let Lista = PlanesDePruebas.map((Entidad: any) => {
            return(
                <li
                    id = {Estado + '-' + Entidad.PKID}
                    draggable
                    onDragStart = {DragStart}
                    onDragOver = {DragOver}
                    key = {Estado + '-' + Entidad.PKID}
                    onClick = {() => Props.OnResponse(Entidad, true)}>
                    <div className="comment-main-level">
                        <div className="comment-box">
                            <div className="comment-head">
                                <h6 className="comment-name by-author" >
                                    {Entidad.Nombre}
                                </h6>
                            </div>
                            <div className="comment-content">
                                <div>
                                <table className="table table-bordered table-sm">
                                        <thead>
                                            <tr>
                                                <th scope="col">Proyecto</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td >{Entidad.Proyecto.Nombre}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table className="table table-bordered table-sm">
                                        <thead>
                                            <tr>
                                                <th scope="col">Fecha Inicio</th>
                                                <th scope="col">Fecha Fin</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td ><p>{Entidad.Proyecto.FechaInicio? new Date(Entidad.Proyecto.FechaInicio).getDate() + "/" + (new Date(Entidad.Proyecto.FechaInicio).getMonth() + 1) + "/" + new Date(Entidad.Proyecto.FechaInicio).getFullYear():''}</p></td>
                                                <td ><p>{Entidad.Proyecto.FechaFin? new Date(Entidad.Proyecto.FechaFin).getDate() + "/" + (new Date(Entidad.Proyecto.FechaFin).getMonth() + 1) + "/" + new Date(Entidad.Proyecto.FechaFin).getFullYear():''}</p></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            )
        })
        return Lista
    }

    return(
        <div className="ContainerTrello">
            <div className="p-grid" style={{margin: '0px'}}>
                <div className="p-col-12 p-md-6 p-lg-3">
                    <div className="list-wrapper">
                        <div className="list" style={{height:'82vh'}}>
                            <div className="list-header">
                                <h2 className="list-header-name-assist">Proyectos</h2>
                            </div>
                            <div className="list-cards">
                                <ul className="comment-list">
                                    {RenderListadoProyectos(Props.ListaProyectos)}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-col-12 p-md-6 p-lg-3">
                    <div className="list-wrapper">
                        <div className="list" style={{height:'82vh'}}>
                            <div className="list-header">
                                <h2 className="list-header-name-assist">Pendiente</h2>
                            </div>
                            <div 
                                className="list-cards"
                                onDrop={DropPendiente}
                                onDragOver={DragOver}>
                                <ul id="lPendiente" className="comment-list">
                                    {RenderListado(Props.Pendiente.Lista,'Pendiente')}
                                </ul>
                                <div ref={RefPendiente}>
                                    {((Props.Pendiente.Paginacion.NumeroPagina < Props.Pendiente.Paginacion.NumeroPaginas) || Props.Pendiente.Loading)? 
                                        <div className="circleLoad"></div> 
                                        : 
                                        <></>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-col-12 p-md-6 p-lg-3">
                    <div className="list-wrapper">
                        <div className="list" style={{height:'82vh'}}>
                            <div className="list-header">
                                <h2 className="list-header-name-assist">En Curso</h2>
                            </div>
                            <div 
                                className="list-cards"
                                onDrop={DropEnCurso}
                                onDragOver={DragOver}>
                                <ul id="lEnCurso" className="comment-list">
                                    {RenderListado(Props.EnCurso.Lista,'EnCurso')}
                                </ul>
                                <div ref={RefEnCurso}>
                                    {((Props.EnCurso.Paginacion.NumeroPagina < Props.EnCurso.Paginacion.NumeroPaginas) || Props.EnCurso.Loading)? 
                                        <div className="circleLoad"></div> 
                                        : 
                                        <></>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-col-12 p-md-6 p-lg-3">
                    <div className="list-wrapper">
                        <div className="list" style={{height:'82vh'}}>
                            <div className="list-header">
                                <h2 className="list-header-name-assist">Hecho</h2>
                            </div>
                            <div 
                                className="list-cards"
                                onDrop={DropHecho}
                                onDragOver={DragOver}>
                                <ul id="lHecho" className="comment-list">
                                    {RenderListado(Props.Hecho.Lista,'Hecho')}
                                </ul>
                                <div ref={RefHecho}>
                                    {((Props.Hecho.Paginacion.NumeroPagina < Props.Hecho.Paginacion.NumeroPaginas) || Props.Hecho.Loading)? 
                                        <div className="circleLoad"></div> 
                                        : 
                                        <></>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}