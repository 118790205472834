import { CategorizacionRepository } from '../../Infrastructure/Repositories/CategorizacionRepository'
import { ICategorizacion } from '../Models/ICategorizacion'


interface IErroresCategorizacion{
    Descripcion?: string
}


const ValidarCategorizacion = (Categorizacion: ICategorizacion) => {
    const errores: IErroresCategorizacion ={}
    if(!Categorizacion.Descripcion) errores.Descripcion  = 'Descripción de la categorización es requerida'
    return errores
}


export const CategorizacionService = {
    ValidarCategorizacion,   
    Listar: () => {
        return CategorizacionRepository.Listar()
    }, 
    ListarPaginacion: (Paginacion: any) => {
        return CategorizacionRepository.ListarPaginacion(Paginacion)
    },   
    CrearActualizar: (Categorizacion: ICategorizacion) => {
        return CategorizacionRepository.CrearActualizar(Categorizacion)
    }
}