import React from 'react'
import CalidadHeader from '../Components/CalidadHeader'
import CalidadBody from '../Components/CalidadBody'
import CalidadModal from '../Components/CalidadModal'
import { setPlanPrueba } from '../../Services/LocalStorage'

import { AuthHelper } from '../../Domain/Utils/AuthHelper'
import { PlanPruebaService } from '../../Domain/Services/PlanPruebaService'
import { ProyectoService } from '../../Domain/Services/ProyectoService'

import { IPlanPrueba } from '../../Domain/Models/IPlanPrueba'
import { IPaginacion } from '../../Domain/Models/IPaginacion'

interface IProps {
}

interface IState {
    Modal: boolean
    Pendiente: IPaginacion
    EnCurso: IPaginacion
    Hecho: IPaginacion
    ListaPendienteOriginal: IPaginacion
    ListaEnCursoOriginal: IPaginacion
    ListaHechoOriginal: IPaginacion
    PlanPruebas: IPlanPrueba
    ListaProyectos: Array<object>
    Errores: any
    Request: boolean
}

export default class Calidad extends React.Component<IProps, IState> {

    cPlanPruebas = {
        PKID: 0,
        Nombre: '',
        Descripcion: '',
        FechaCreacion: new Date(),
        Proyecto: {
            Nombre: '',
            Descripcion: '',
            FechaInicio: '',
            FechaFin: ''
        },
        Empleado: {
            PKID: parseInt(AuthHelper.GetDecoded().sub, 10),
            Nombre: AuthHelper.GetDecoded().Empleado
        },
        Estado: {
            PKID: 1,
            Descripcion: 'Pendiente'
        }
    }

    cPaginacion = {
        Lista: [],
        Paginacion: {
            NumeroPagina: 1,
            RegistrosPagina: 5000,
            NumeroPaginas: 0,
            IDEstado: 1,
            IDUsuario: parseInt(AuthHelper.GetDecoded().sub, 10)
        },
        Loading: true
    }

    constructor(Props: any) {
        super(Props)
        this.state = {
            Modal: false,
            Pendiente: { ...this.cPaginacion, Paginacion: { ...this.cPaginacion.Paginacion, IDEstado: 1 } },
            EnCurso: { ...this.cPaginacion, Paginacion: { ...this.cPaginacion.Paginacion, IDEstado: 2 } },
            Hecho: { ...this.cPaginacion, Paginacion: { ...this.cPaginacion.Paginacion, IDEstado: 3 } },
            ListaPendienteOriginal: { ...this.cPaginacion, Paginacion: { ...this.cPaginacion.Paginacion, IDEstado: 1 } },
            ListaEnCursoOriginal: { ...this.cPaginacion, Paginacion: { ...this.cPaginacion.Paginacion, IDEstado: 2 } },
            ListaHechoOriginal: { ...this.cPaginacion, Paginacion: { ...this.cPaginacion.Paginacion, IDEstado: 3 } },
            PlanPruebas: this.cPlanPruebas,
            ListaProyectos: [],
            Errores: {},
            Request: false
        }
    }

    componentDidMount() {
        this.ListarProyectos()
        this.ListarPlanDePruebas('Pendiente')
        this.ListarPlanDePruebas('EnCurso')
        this.ListarPlanDePruebas('Hecho')
    }

    ListarProyectos() {
        ProyectoService.ListarCalidad()
            .then((response: any) => {
                if (response.success) {
                    this.setState({
                        ListaProyectos: JSON.parse(response.data)
                    })
                } else {
                    alert(response.data)
                }
            })
            .catch((error) => {
                alert(error)
            })
    }

    ListarPlanDePruebas = (Estado: string) => {
        PlanPruebaService.ListarPaginacion(
            {
                'Pendiente': this.state.Pendiente.Paginacion,
                'EnCurso': this.state.EnCurso.Paginacion,
                'Hecho': this.state.Hecho.Paginacion
            }[Estado]
        )
            .then((response: any) => {
                if (response.success) {
                    let jParse = JSON.parse(response.data)
                    let rPlanPrueba: Array<IPlanPrueba | any> = jParse.PlanPrueba ? jParse.PlanPrueba : []
                    let rPaginacion: any = jParse.Paginacion
                    switch (Estado) {
                        case 'Pendiente':
                            this.setState({ Pendiente: { Lista: this.state.Pendiente.Lista.concat(rPlanPrueba), Paginacion: rPaginacion, Loading: false } })
                            this.setState({ ListaPendienteOriginal: { Lista: this.state.ListaPendienteOriginal.Lista.concat(rPlanPrueba), Paginacion: rPaginacion, Loading: false } })
                            break
                        case 'EnCurso':
                            this.setState({ EnCurso: { Lista: this.state.EnCurso.Lista.concat(rPlanPrueba), Paginacion: rPaginacion, Loading: false } })
                            this.setState({ ListaEnCursoOriginal: { Lista: this.state.ListaEnCursoOriginal.Lista.concat(rPlanPrueba), Paginacion: rPaginacion, Loading: false } })
                            break
                        case 'Hecho':
                            this.setState({ Hecho: { Lista: this.state.Hecho.Lista.concat(rPlanPrueba), Paginacion: rPaginacion, Loading: false } })
                            this.setState({ ListaHechoOriginal: { Lista: this.state.ListaHechoOriginal.Lista.concat(rPlanPrueba), Paginacion: rPaginacion, Loading: false } })
                            break
                        default:
                            break
                    }
                } else {
                    alert(response.data)
                }
            })
    }

    Paginacion = (Estado: string) => {
        if (
            (
                {
                    'Pendiente': ((this.state.Pendiente.Paginacion.NumeroPagina < this.state.Pendiente.Paginacion.NumeroPaginas) && !this.state.Pendiente.Loading),
                    'EnCurso': ((this.state.EnCurso.Paginacion.NumeroPagina < this.state.EnCurso.Paginacion.NumeroPaginas) && !this.state.EnCurso.Loading),
                    'Hecho': ((this.state.Hecho.Paginacion.NumeroPagina < this.state.Hecho.Paginacion.NumeroPaginas) && !this.state.Hecho.Loading)
                }[Estado]
            )) {
            switch (Estado) {
                case 'Pendiente':
                    this.setState({
                        Pendiente: {
                            ...this.state.Pendiente,
                            Paginacion: {
                                ...this.state.Pendiente.Paginacion,
                                NumeroPagina: this.state.Pendiente.Paginacion.NumeroPagina + 1
                            },
                            Loading: true
                        }
                    }, () => this.ListarPlanDePruebas('Pendiente'))
                    break
                case 'EnCurso':
                    this.setState({
                        EnCurso: {
                            ...this.state.EnCurso
                            , Paginacion: {
                                ...this.state.EnCurso.Paginacion,
                                NumeroPagina: this.state.EnCurso.Paginacion.NumeroPagina + 1
                            },
                            Loading: true
                        }
                    }, () => this.ListarPlanDePruebas('EnCurso'))
                    break
                case 'Hecho':
                    this.setState({
                        Hecho: {
                            ...this.state.Hecho,
                            Paginacion: {
                                ...this.state.Hecho.Paginacion,
                                NumeroPagina: this.state.Hecho.Paginacion.NumeroPagina + 1
                            },
                            Loading: true
                        }
                    }, () => this.ListarPlanDePruebas('Hecho'))
                    break
                default:
                    break
            }
        }
    }


    MoverEstado = (EstadoI: string, EstadoF: string, PKID: string) => {
        if (EstadoI !== EstadoF) {
            let Obj = { 'Pendiente': this.state.Pendiente.Lista, 'EnCurso': this.state.EnCurso.Lista, 'Hecho': this.state.Hecho.Lista }[EstadoI] || []
            switch (EstadoF) {
                case 'Pendiente':
                    this.setState({
                        PlanPruebas: PlanPruebaService.ObtenerObjetoEstado(Obj, PKID, 1)
                    }, () => this.CrearActualizar())
                    break
                case 'EnCurso':
                    this.setState({
                        PlanPruebas: PlanPruebaService.ObtenerObjetoEstado(Obj, PKID, 2)
                    }, () => this.CrearActualizar())
                    break
                case 'Hecho':
                    this.setState({
                        PlanPruebas: PlanPruebaService.ObtenerObjetoEstado(Obj, PKID, 3)
                    }, () => this.CrearActualizar())
                    break
                default:
                    break
            }
        }
    }

    CrearActualizar() {
        PlanPruebaService.CrearActualizar(this.state.PlanPruebas)
            .then((response: any) => {
                if (response.success) {
                    this.setState({
                        Pendiente: { ...this.cPaginacion, Paginacion: { ...this.cPaginacion.Paginacion, IDEstado: 1 } },
                        EnCurso: { ...this.cPaginacion, Paginacion: { ...this.cPaginacion.Paginacion, IDEstado: 2 } },
                        Hecho: { ...this.cPaginacion, Paginacion: { ...this.cPaginacion.Paginacion, IDEstado: 3 } },
                        Modal: false
                    }, () => this.componentDidMount())
                } else {
                    alert(response.data)
                }
            })
            .catch((error) => {
                alert(error)
            })
            .finally(() => {
                this.setState({ Request: false })
            })
    }

    CrearPlanDePruebas = (Event: any) => {
        this.setState({
            PlanPruebas: {
                ...this.state.PlanPruebas,
                Proyecto: Event
            }
        }, () => this.HideVisibleModal(true))
    }

    EditarPlanDePruebas = (Event: any) => {
        setPlanPrueba(JSON.stringify(Event))
        window.location.href = 'Calidad/PlanDePrueba'
    }

    HideVisibleModal = (Mode: boolean) => {
        this.setState({
            Modal: Mode
        })
    }

    HandleSubmit = (Event: any) => {
        Event.preventDefault()
        const result = PlanPruebaService.ValidarSchema(this.state.PlanPruebas)
        if (Object.keys(result).length) {
            return this.setState({ Errores: result });
        } else {
            this.setState({
                Request: true
            }, () => this.CrearActualizar())
        }
    }

    HandleChange = (Event: any) => {
        this.setState({
            PlanPruebas: {
                ...this.state.PlanPruebas,
                [Event.target.name]: Event.target.value
            }
        })
    }

    HandleChangeBuscador = (e: any) => {
        const ListaPendienteOriginal = this.state.ListaPendienteOriginal.Lista
        const ListaEnCursoOriginal = this.state.ListaEnCursoOriginal.Lista
        const ListaHechoOriginal = this.state.ListaHechoOriginal.Lista

        const ListaPendienteFiltoBefore = this.state.ListaPendienteOriginal.Lista
        const ListaEnCursoFiltroBefore = this.state.ListaEnCursoOriginal.Lista
        const ListaHechoFiltroBefore = this.state.ListaHechoOriginal.Lista

        if (e.target.value.length === 0) {
            this.setState({
                Pendiente: {
                    ...this.state.Pendiente,
                    Lista: ListaPendienteOriginal
                }
            })
            this.setState({
                EnCurso: {
                    ...this.state.EnCurso,
                    Lista: ListaEnCursoOriginal
                }
            })
            this.setState({
                Hecho: {
                    ...this.state.Hecho,
                    Lista: ListaHechoOriginal
                }
            })
        } else {
            const listaFiltradaPendiente = ListaPendienteFiltoBefore.filter((list) => list.Nombre.toLowerCase().includes(e.target.value.toLowerCase()))
            const listaFiltradaEnCurso = ListaEnCursoFiltroBefore.filter((list) => list.Nombre.toLowerCase().includes(e.target.value.toLowerCase()))
            const listaFiltradaHecho = ListaHechoFiltroBefore.filter((list) => list.Nombre.toLowerCase().includes(e.target.value.toLowerCase()))

            this.setState({
                Pendiente: {
                    ...this.state.Pendiente,
                    Lista: listaFiltradaPendiente
                }
            })

            this.setState({
                EnCurso: {
                    ...this.state.EnCurso,
                    Lista: listaFiltradaEnCurso
                }
            })

            this.setState({
                Hecho: {
                    ...this.state.Hecho,
                    Lista: listaFiltradaHecho
                }
            })
        }
    }

    render() {
        return (
            <div className="container">
                <CalidadHeader
                    OnChangeBuscador={this.HandleChangeBuscador} />
                <CalidadBody
                    ListaProyectos={this.state.ListaProyectos}
                    Paginacion={this.Paginacion}
                    Pendiente={this.state.Pendiente}
                    EnCurso={this.state.EnCurso}
                    Hecho={this.state.Hecho}
                    CrearPlanDePruebas={this.CrearPlanDePruebas}
                    OnResponse={this.EditarPlanDePruebas}
                    MoverEstado={this.MoverEstado} />
                <form autoComplete="Off" onSubmit={this.HandleSubmit}>
                    <CalidadModal
                        Modal={this.state.Modal}
                        HideModal={() => this.HideVisibleModal(false)}
                        HandleChange={this.HandleChange}
                        FormValues={this.state.PlanPruebas}
                        Errores={this.state.Errores}
                        Request={this.state.Request} />
                </form>
            </div>
        )
    }
}