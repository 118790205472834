import jwt from 'jwt-decode'
import { IToken } from '../Models/IToken'

const TokenKey = 'TOKEN-UN1FL3X'

const Get = () => {
    return localStorage.getItem(TokenKey)
}

const GetDecoded = () => {
    var Decoded : IToken = jwt(Get() || '')
    return Decoded
}

const Save = (Token: string) =>{
    localStorage.setItem('TOKEN-UN1FL3X',Token);
}

const Delete = () => {
    return localStorage.getItem(TokenKey)
}

export const AuthHelper = {
    Get,
    GetDecoded,
    Save,
    Delete
}