import React from 'react'
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';


interface IProps {
    OnChangeBuscador: any
}

export default function ReporteAsistenciaHeader(Props: IProps) {

    return (
        <div className="p-col-12">
            <div className="CrumbBeetwen">
                <div className="p-d-flex">
                    <ul className="breadcrumb">
                        <li><a href="/Desarrollo/Inicio">Inicio</a></li>
                        <li>Reporte de Asistencia</li>
                    </ul>
                    <div className="BtnFiltro p-ml-auto">
                        <div className="p-d-flex">
                            <div className="p-mr-2">
                                <label
                                    style={{
                                        color: '#4D80E4',
                                        fontFamily: 'Sarala',
                                        fontSize: '20px'
                                    }}
                                >Buscador </label>
                                <InputText
                                    onChange={Props.OnChangeBuscador}
                                    name="Buscador"
                                    type="Text" />
                            </div>
                            <div className="p-mr-2">
                                <Button
                                    id="OpenFilter"
                                    type="button"
                                    icon="fas fa-filter"
                                    onClick={() => {
                                        (document.getElementById('PanelFiltro') as HTMLInputElement).classList.add('active');
                                        (document.getElementById('PopupFiltro') as HTMLInputElement).classList.add('active');
                                    }}
                                    className="p-button-rounded p-button-text p-button-plain" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}