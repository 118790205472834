import React from 'react'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import { Button } from 'primereact/button'
import { Calendar } from 'primereact/calendar'
import { Dropdown } from 'primereact/dropdown'
import { Local } from './LocaleSettings'
import { Editor } from 'primereact/editor'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import '../Assets/Css/PanelProyecto.css'
import { FileUpload } from 'primereact/fileupload';
import { Checkbox } from 'primereact/checkbox'

interface IProps{
    Modal: boolean
    HideModal: any
    HandleChange: any
    BackLog: any    
    ListaEmpleado: Array<object>
    ListaEstado: Array<object>
    Errores: any
    Request: boolean
    ListaProducto: Array<object>
    ListaCliente: Array<object>
    ListaMotivoVersionamiento: Array<object>
    CrearNuevoStakeholder: any
    ActualizarStakeholder: any
    EliminarStakeholder: any
    OnRowReorder: any
    SubirArchivo: any
    BorrarArchivo: any
    DescargarArchivo: any
}

export default function BackLogModal(Props : IProps){

    const File = React.createRef<FileUpload>()

    const ImageArchivo = (RowData: any) => {
        var extension = RowData.Extension
        let icono=''
        let fondo=''

        if(extension === 'txt'){
            icono = 'pi pi-file'
            fondo = '#000000'
        } 
        else if(extension === 'pdf'){
            icono = 'pi pi-file-pdf'
            fondo = '#ff471a'
        } 
        else if(extension==='docx' || extension==='docm' || extension==='dotx' || extension==='dotm' || extension==='doc' || extension==='dot' || extension==='rtf'){
            icono = 'far fa-file-word'
            fondo = '#80e5ff'
        } 
        else if(extension==='xls' || extension==='xlsx' || extension==='xlsm' || extension==='xltx' || extension==='xltm'){
            icono = 'far fa-file-excel'
            fondo = '#00802b'
        } 
        else if(extension==='pptx' || extension==='pptm' || extension==='ppt' || extension==='potx'){
            icono = 'far fa-file-powerpoint'
            fondo = '#ffa64d'
        } 
        else if(extension==='sql' || extension==='js' || extension==='vb' || extension==='cs'){
            icono = 'fas fa-scroll'
            fondo = '#66ff66'
        } 
        else if(extension==='zip' || extension==='7z' || extension==='rar'){
            icono = 'far fa-file-archive'
            fondo = '#3385ff'
        }
        else{
            icono = 'pi pi-question'
            fondo = '#000000'
        }

        return(
            <React.Fragment>
                <div className="p-grid p-fluid ">
                    <div className="p-col-12">
                            <i className={icono} style={{'fontSize': '2em', color:`${fondo}`}}></i>
                        </div>
                </div>
            </React.Fragment>
        )
    }

    const TamañoArchivo = (RowData: any) => {
        return(<p>{(RowData.Tamagno / Math.pow(1024,2)).toFixed(2) + 'MB'}</p>)
    }

    const OperacionArchivo =(Event: any) => {
        return(
            <React.Fragment>
                <div className="p-grid p-fluid ">
                    <div className="p-col-6">
                        <Button 
                            type="button"
                            icon="pi pi-times" 
                            style={{width:'30px', height:'30px'}}
                            className="p-button-rounded p-button-outlined"
                            onClick={ () => { 
                                Props.BorrarArchivo(Event)
                            }}/>
                    </div>
                    <div className="p-col-6">
                        <Button 
                            type="button"
                            icon="pi pi-download" 
                            style={{width:'30px', height:'30px'}}
                            className="p-button-rounded p-button-outlined"
                            onClick={ () => { 
                                Props.DescargarArchivo(Event)
                            }}/>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    const FechaCreacion = (RowData: any) => {
        let newDate
        RowData.FechaCreacion !== '' ? newDate = new Date(RowData.FechaCreacion) : newDate = new Date() 
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        return `${date}${'/'}${month<10?`0${month}`:`${month}`}${'/'}${year}`
    }

    const header = (
        <>
            <span className="ql-formats">
                <button className="ql-bold" aria-label="Bold"></button>
                <button className="ql-italic" aria-label="Italic"></button>
                <button className="ql-underline" aria-label="Underline"></button>
            </span>
            <span className="ql-formats">
                <button type="button" className="ql-list" value="ordered" aria-label="Ordered List">
                    <svg viewBox="0 0 18 18"> 
                        <line className="ql-stroke" x1="7" x2="15" y1="4" y2="4"></line> 
                        <line className="ql-stroke" x1="7" x2="15" y1="9" y2="9"></line> 
                        <line className="ql-stroke" x1="7" x2="15" y1="14" y2="14"></line> 
                        <line className="ql-stroke ql-thin" x1="2.5" x2="4.5" y1="5.5" y2="5.5"></line> 
                        <path className="ql-fill" d="M3.5,6A0.5,0.5,0,0,1,3,5.5V3.085l-0.276.138A0.5,0.5,0,0,1,2.053,3c-0.124-.247-0.023-0.324.224-0.447l1-.5A0.5,0.5,0,0,1,4,2.5v3A0.5,0.5,0,0,1,3.5,6Z"></path> 
                        <path className="ql-stroke ql-thin" d="M4.5,10.5h-2c0-.234,1.85-1.076,1.85-2.234A0.959,0.959,0,0,0,2.5,8.156"></path> 
                        <path className="ql-stroke ql-thin" d="M2.5,14.846a0.959,0.959,0,0,0,1.85-.109A0.7,0.7,0,0,0,3.75,14a0.688,0.688,0,0,0,.6-0.736,0.959,0.959,0,0,0-1.85-.109"></path> 
                    </svg>
                </button>
                <button type="button" className="ql-list" value="bullet" aria-label="Unordered List">
                    <svg viewBox="0 0 18 18"> 
                        <line className="ql-stroke" x1="6" x2="15" y1="4" y2="4"></line> 
                        <line className="ql-stroke" x1="6" x2="15" y1="9" y2="9"></line> 
                        <line className="ql-stroke" x1="6" x2="15" y1="14" y2="14"></line> 
                        <line className="ql-stroke" x1="3" x2="3" y1="4" y2="4"></line> 
                        <line className="ql-stroke" x1="3" x2="3" y1="9" y2="9"></line> 
                        <line className="ql-stroke" x1="3" x2="3" y1="14" y2="14"></line> 
                    </svg>
                </button>
            </span>
        </>
    );

    const EditorDescripcion = (Event: any) => {
        Props.HandleChange({
            target:{
                name: "Descripcion",
                value: Event.htmlValue?Event.htmlValue.replace('<span ','<div ').replace('</span>','</div>'):''
            }
        })
    }

    const RenderFooter = () =>{
        return (
            <Button 
                type="submit"
                label={Props.Request ? "Cargando..." : "Aceptar"}
                style={{
                    background:'#4D80E4',
                    border: '1px solid #4D80E4'}}
                icon={Props.Request ? "" : "pi pi-check" } 
                className="p-ml-auto"
                disabled={Props.Request}
                autoFocus/>
        )
    }

    const BodyTemplate2 = (RowData: any) => {
        return (
            <React.Fragment>
                <div className="p-grid p-fluid ">
                    <div className="p-col-6">
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-outlined" type="text" onClick={()=>{ Props.ActualizarStakeholder(RowData) }} />
                    </div>
                    <div className="p-col-6">
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-outlined" type="text" onClick={()=>{ Props.EliminarStakeholder(RowData) }} />
                    </div>
                </div>
            </React.Fragment>
        );
    }

    const Header = (
        <div className="p-d-flex p-jc-between">
            <FileUpload 
                ref={File}
                mode="basic" 
                name="file" 
                auto 
                uploadHandler={(Event) => Props.SubirArchivo(Event,File)}
                customUpload
                className="p-ml-auto"
                chooseLabel="Buscar archivo"/>
        </div>
    )

        
    return(
        <Dialog
            header="Crear BackLog"
            visible={Props.Modal}
            style={{ width: '110vh' }} 
            footer={RenderFooter()} 
            onHide={() => Props.HideModal(null, false)}>
                <div className="p-grid nested-grid">
                    <div className="p-col-12 p-lg-6">
                        <div className="p-col">
                            <div className="p-grid p-fluid ">
                                <div className="p-col-12">
                                    <label 
                                        className="p-d-block"
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'}}
                                    >Nombre</label>
                                    <InputText 
                                        value={Props.BackLog.Nombre}
                                        onChange={Props.HandleChange}
                                        name="Nombre"
                                        type="text" />
                                    {Props.Errores.Nombre && <small className="p-invalid p-d-block">{Props.Errores.Nombre}</small>}        
                                </div>
                            </div>
                        </div>
                        <div className="p-col">
                            <div className="p-grid p-fluid ">
                                <div className="p-col-12">
                                    <label 
                                        className="p-d-block"
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'}}
                                    >Descripción</label>
                                     <Editor 
                                    style={{height:'120px'}} 
                                    value={Props.BackLog.Descripcion ? Props.BackLog.Descripcion:''} 
                                    onTextChange={EditorDescripcion}
                                    headerTemplate={header}/>                                    
                                    {Props.Errores.Descripcion && <small className="p-invalid p-d-block">{Props.Errores.Descripcion}</small>} 
                                </div>
                            </div>
                        </div> 
                        <div className="p-col">
                            <div className="p-grid p-fluid ">
                                <div className="p-col-12">
                                    <label 
                                        className="p-d-block"
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'}}
                                    >Link Documentación Desarrollo</label>
                                     <InputText 
                                        value={Props.BackLog.LinkDocumentacionDesarrollo}
                                        onChange={Props.HandleChange}
                                        name="LinkDocumentacionDesarrollo"
                                        type="text" />                      
                                    {Props.Errores.LinkDocumentacionDesarrollo && <small className="p-invalid p-d-block">{Props.Errores.LinkDocumentacionDesarrollo}</small>} 
                                </div>
                            </div>
                        </div> 
                        <div className="p-col">  
                            <div className="p-grid p-fluid">
                                <div className="p-col-12">
                                    <label 
                                        style={{
                                            color : '#4D80E4',
                                            fontFamily : 'Sarala',
                                            fontSize : '19px'}}>
                                    Responsable de creación</label>
                                    <InputText 
                                       value={Props.BackLog.Usuario.NombreEmpleado}
                                       disabled
                                       name="Responsable"
                                       type="text" 
                                       placeholder="Responsable"/>
                                </div>
                            </div>
                        </div>  
                        <div className="p-col">
                            <div className="p-grid p-fluid ">
                                <div className="p-col-12">
                                    <label 
                                        className="p-d-block"
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'}}
                                        >Cliente Beneficiado</label>
                                    <Dropdown 
                                        value = {{
                                            code: Props.BackLog.Cliente.PKID,
                                            name: Props.BackLog.Cliente.Nombre}}
                                        options = {Props.ListaCliente} 
                                        onChange = {Props.HandleChange} 
                                        name = "Cliente"
                                        optionLabel = "name" 
                                        placeholder = "Seleccione Cliente"/>
                                    {Props.Errores.Cliente && <small className="p-invalid p-d-block">{Props.Errores.Cliente}</small>}
                                </div>
                            </div>
                        </div>

                        <div className="p-col">
                            <div className="p-grid p-fluid ">
                                <div className="p-col-12">
                                    <label 
                                        className="p-d-block"
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'}}
                                        >Motivo de Versionamiento</label>
                                <Dropdown
                                        value={{
                                            code: Props.BackLog.MotivoVersionamiento.PKID,
                                            name: Props.BackLog.MotivoVersionamiento.Descripcion}}
                                        options={Props.ListaMotivoVersionamiento}
                                        name = "MotivoVersionamiento"
                                        onChange={Props.HandleChange}
                                        optionLabel="name"
                                        placeholder="Seleccione Motivo de Versionamiento"/>
                                    {Props.Errores.MotivoVersionamiento && <small className="p-invalid p-d-block">{Props.Errores.MotivoVersionamiento}</small>}        
                                </div>
                            </div>
                        </div>

                        <div className="p-col">
                            <div className="p-grid p-fluid ">
                                <div className="p-col-12">
                                    <label 
                                        className="p-d-block"
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'}}
                                    >Detalle de Versionamiento</label>
                                      <InputTextarea 
                                        value={Props.BackLog.DetalleVersionamiento}
                                        onChange={Props.HandleChange}
                                        name="DetalleVersionamiento"
                                        type="text" 
                                        rows={5} 
                                        cols={10}/>                     
                                    {Props.Errores.DetalleVersionamiento && <small className="p-invalid p-d-block">{Props.Errores.DetalleVersionamiento}</small>} 
                                </div>
                            </div>
                        </div> 

                        <div className="p-col">
                            <div className="p-grid p-fluid ">
                                <div className="p-col-12">                                
                                     <Checkbox       
                                     inputId="binary"  
                    checked={Props.BackLog.Activo} 
                    onChange={Props.HandleChange}
                    name="Activo" />
                      <label 
                                        className=""
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'}}
                                        >      Activo</label>
                                    {Props.Errores.Activo && <small className="p-invalid p-d-block">{Props.Errores.Activo}</small>}        
                                </div>
                            </div>
                        </div>

                        <div className="p-col"> 
                            <div className="p-grid p-fluid">
                                <div className="p-col-12">
                                    <label 
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily : 'Sarala',
                                            fontSize : '19px'}}>
                                    Producto</label>
                                    <Dropdown 
                                        value = {{
                                            code: Props.BackLog.Producto?Props.BackLog.Producto.PKID:0,
                                            name: Props.BackLog.Producto?Props.BackLog.Producto.Nombre:"",
                                            VersionProducto : Props.BackLog.Producto?Props.BackLog.Producto.VersionProducto:""}}
                                        options = {Props.ListaProducto}
                                        onChange = {Props.HandleChange}
                                        optionLabel = "name"
                                        disabled={Props.BackLog.Estado? (Props.BackLog.Estado.PKID === 3):false}
                                        name = "Producto"
                                        placeholder = "Seleccione Producto"/>
                                    {Props.Errores.Producto && <small className="p-invalid p-d-block">{Props.Errores.Producto}</small>}
                                </div>
                            </div>              
                        </div>    
                        <div className="p-col"> 
                            <div className="p-grid p-fluid">
                                <div className="p-col-12">
                                    <label 
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily : 'Sarala',
                                            fontSize : '19px'}}>
                                    Versión del Producto</label>
                                    <Dropdown 
                                        value={{
                                            code: Props.BackLog.VersionProducto?Props.BackLog.VersionProducto.PKID:0,
                                            name: Props.BackLog.VersionProducto?Props.BackLog.VersionProducto.Version:""}}
                                        options={Props.BackLog.VersionProducto?Props.BackLog.Producto.VersionProducto:""}
                                        onChange={Props.HandleChange}
                                        optionLabel="name"
                                        disabled={Props.BackLog.Estado? (Props.BackLog.Estado.PKID === 3):false}
                                        name = "VersionProducto"
                                        placeholder="Seleccione Versión"/>
                                    {Props.Errores.VersionProducto && <small className="p-invalid p-d-block">{Props.Errores.VersionProducto}</small>}
                                </div>
                            </div>
                        </div>  

                        <div className="p-col">
                            <div className="p-grid p-fluid ">
                                <div className="p-col-12 ">
                                <label 
                                    style={{
                                        color: '#4D80E4',
                                        fontFamily: 'Sarala',
                                        fontSize: '20px'}}
                                >Fecha de Entrega</label>
                                <Calendar 
                                    value={Props.BackLog.FechaEntrega} 
                                    onChange={Props.HandleChange}
                                    showTime 
                                    showSeconds
                                    name="FechaEntrega"
                                    locale={Local} 
                                    dateFormat="dd/mm/yy"
                                    />                                   
                                </div>                                
                            </div>
                        </div>

                        <div className="p-col">
                            <div className="p-grid p-fluid ">
                                <div className="p-col-12">
                                    <label 
                                        className="p-d-block"
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'}}
                                    >Pre-requisitos</label>
                                    <InputTextarea 
                                        value={Props.BackLog.Prerequisitos}
                                        onChange={Props.HandleChange}
                                        name="Prerequisitos"
                                        type="text" 
                                        rows={5} 
                                        cols={10}/>
                                    {Props.Errores.Prerequisitos && <small className="p-invalid p-d-block">{Props.Errores.Prerequisitos}</small>} 
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="p-col-12 p-lg-6">
                    <div className="p-col">
                            <div className="p-grid p-fluid ">
                                <div className="p-col-12 ">
                                <label 
                                        className="p-d-block"
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'}}
                                        >Responsable Origen</label>
                                    <Dropdown 
                                    style={{
                                        overflow: 'visible'
                                    }}
                                        value={{
                                            code: Props.BackLog.Empleado?Props.BackLog.Empleado.PKID:0,
                                            name: Props.BackLog.Empleado?Props.BackLog.Empleado.Nombre:''}}
                                        options={Props.ListaEmpleado}
                                        name = "Empleado"
                                        onChange={Props.HandleChange}
                                        optionLabel="name"
                                        placeholder="Seleccione el Responsable"/>
                                    {Props.Errores.Empleado && <small className="p-invalid p-d-block">{Props.Errores.Empleado}</small>}                                    
                                </div>                                
                            </div>
                        </div>
                        <div className="p-col">
                            <div className="p-grid p-fluid ">
                                <div className="p-col-12 ">
                                <label 
                                    style={{
                                        color: '#4D80E4',
                                        fontFamily: 'Sarala',
                                        fontSize: '20px'}}
                                >Fecha de creación</label>
                                <Calendar 
                                    value={Props.BackLog.FechaCreacion} 
                                    onChange={Props.HandleChange}
                                    showTime 
                                    showSeconds
                                    name="FechaCreacion"
                                    locale={Local} 
                                    dateFormat="dd/mm/yy"
                                    disabled/>                                   
                                </div>                                
                            </div>
                        </div>
                        <div className="p-col">
                            <div className="p-grid p-fluid ">
                                <div className="p-col-12 ">
                                    <label 
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'}}
                                    >Versión</label>
                                    <InputText 
                                    value={Props.BackLog.Version}
                                    onChange={Props.HandleChange}
                                    type="text"
                                    name="Version"
                                    disabled/>                                    
                                </div>                                
                            </div>
                        </div>

                        <div className="p-col">
                            <div className="p-grid p-fluid ">
                                <div className="p-col-12">
                                    <label 
                                        className="p-d-block"
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'}}
                                    >Objetivo</label>
                                    <InputTextarea 
                                        value={Props.BackLog.Objetivo}
                                        onChange={Props.HandleChange}
                                        name="Objetivo"
                                        type="text" 
                                        rows={5} 
                                        cols={10}/>
                                    {Props.Errores.Objetivo && <small className="p-invalid p-d-block">{Props.Errores.Objetivo}</small>} 
                                </div>
                            </div>
                        </div>

                        {Props.BackLog.PKID > 0 ?
                            <div className="p-col">
                                <div className="p-grid p-fluid ">
                                    <div className="p-col-12">
                                        <label 
                                            className="p-d-block"
                                            style={{
                                                color: '#4D80E4',
                                                fontFamily: 'Sarala',
                                                fontSize: '20px'}}
                                        >Estado</label>
                                        <Dropdown 
                                            value = {{
                                                code: Props.BackLog.Estado.PKID,
                                                name: Props.BackLog.Estado.Descripcion}}
                                            options = {Props.ListaEstado}
                                            onChange = {Props.HandleChange}
                                            name = "Estado"
                                            optionLabel = "name"
                                            placeholder = "Seleccione el Estado"/>
                                        {Props.Errores.Estado && <small className="p-invalid p-d-block">{Props.Errores.Estado}</small>}        
                                    </div>
                                </div>
                            </div>
                            :
                            <></>
                        }

                            <div className="p-col-12">
                                        <div className="open-card-composer"  onClick={(event) => Props.CrearNuevoStakeholder(event)}>
                                            <i className="pi pi-plus icon-sm"></i>
                                            <span>Nuevo Stakeholder</span>
                                        </div>
                                        <label 
                                            className="p-d-block"
                                            style={{
                                                color: '#4D80E4',
                                                fontFamily: 'Sarala',
                                                fontSize: '20px'}}
                                            >Stakeholders</label>
                                       
                                       <DataTable  
                                            value={Props.BackLog.Stakeholders} 
                                            paginator
                                            className="p-datatable-sm"
                                            columnResizeMode="fit"
                                            reorderableColumns 
                                            onRowReorder={Props.OnRowReorder}
                                            rowsPerPageOptions={[5,8,10]}
                                            scrollable 
                                            dataKey="id" rows={5}>
                                            <Column  rowReorder style={{ width: '20px' }}></Column>
                                            <Column field="Numero" header="Nro" style={{ width: '40px' }}></Column>                                            
                                            <Column field="Descripcion" header="Descripción" style={{ width: '200px' }}></Column>
                                            <Column body={BodyTemplate2}  header="Acción" style={{ width: '80px' }}></Column>
                                        </DataTable>
                                        
                                    </div>

                                    <div className="p-col">
                            <div className="p-grid p-fluid ">
                        <div className="p-col-12">
                                    <label 
                                        className="p-d-block"
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'}}
                                        >Archivos adjuntos</label>
                                    <DataTable 
                                        value={Props.BackLog.ArchivosAdjuntos}
                                        paginator
                                        rows={5}
                                        header={Header}
                                        className="p-datatable-sm">
                                            <Column body={ImageArchivo} header="T. de archivo"></Column>
                                            <Column field="Nombre" header="Nombre"></Column>
                                            <Column body={FechaCreacion} header="Fecha creación"/>
                                            <Column body={TamañoArchivo} header="Tamaño"></Column>
                                            <Column body={OperacionArchivo} header="Operación"></Column>
                                    </DataTable>
                                </div>
                                </div>
                        </div>
                        
                    </div> 
                </div>
        </Dialog>
   )
}