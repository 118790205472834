import { ProyectoRepository } from '../../Infrastructure/Repositories/ProyectoRepository'
import { IBackLog } from '../Models/IBackLog'
import { IPlanPrueba } from '../Models/IPlanPrueba'

interface IErroresBackLog{
    Nombre?: string
    Descripcion?: string
    FechaInicio?: string
    FechaFin?: string
    HorasGerencia?: string
    HorasEstimadas?: string
    Producto?: string
    VersionProducto?: string
    ImplementacionBackLog?: string
    ImplementacionBackLogVersion?: string
}

const ValidarSchema = (BackLog: IBackLog) => {
    const errores: IErroresBackLog ={}
    if(!BackLog.Nombre) errores.Nombre  = 'Nombre del proyecto es requerido'
    if(!BackLog.Descripcion) errores.Descripcion = 'Descripcion del proyecto es requerido'
    if(!BackLog.FechaInicio) errores.FechaInicio = 'Fecha de inicio es requerido'
    if(!BackLog.FechaFin) errores.FechaFin = 'Fecha de finalizacion es requerido'
    if(!BackLog.HorasGerencia) if(BackLog.HorasGerencia !== 0) errores.HorasGerencia = 'Horas de gerencia es requerido'
    if(!BackLog.HorasEstimadas) if(BackLog.HorasEstimadas !== 0) errores.HorasEstimadas = 'Horas estimadas es requerido'
    if(!BackLog.Producto.PKID) errores.Producto = 'Producto es requerido'
    if(!BackLog.VersionProducto.PKID) errores.VersionProducto = 'Versión es requerido'
    if(!BackLog.ImplementacionBackLog.PKID) errores.ImplementacionBackLog = 'BackLog es requerido'
    if(!BackLog.ImplementacionBackLogVersion.PKID) errores.ImplementacionBackLogVersion = 'BackLog Versión es requerido'
    return errores;
}

const ObtenerObjetoEstado = (Array: Array<IBackLog>, PKID: string, IDEstado: number) => {
    let aObj: Array<IBackLog> =  Array.filter( (Obj : IBackLog) =>{
        return Obj.PKID === parseInt(PKID,10) 
    })
    let Obj: IBackLog = aObj[0] 
    return {
        ...Obj,
        Estado:{
            ...Obj.Estado,
            PKID: IDEstado
        }
    }
}

const CrearVersion = (BackLog: IBackLog, PasBackLog: IBackLog) => {
    if(new Date(BackLog.FechaInicio) > new Date(BackLog.FechaFin)){
        return Promise.reject("La fecha final del proyecto debe ser mayor que la fecha inicial.")
    }else{
        if(BackLog.Estado.PKID === 1 && BackLog.PKID !== 0){
            return Promise.reject('Proyecto no puede pasar a estado pendiente.')
        }else{
            if(PasBackLog.Estado.PKID !== 3){
                return ProyectoRepository.CrearVersion(BackLog)
            }else{
                return Promise.reject('No se puede crear nueva version')
            }
        }
    }
}

const GuardarTemporal = (BackLog: IBackLog, PasBackLog: IBackLog) => {
   	console.log("FECHA DE INICIO DEL PROYECTO : "+BackLog.FechaInicio)
    console.log("FECHA DE FIN DEL PROYECTO : "+BackLog.FechaFin)
    console.log("BackLog.Estado.PKID : "+BackLog.Estado.PKID)
    console.log("BackLog.PKID : "+BackLog.PKID)
    if(new Date(BackLog.FechaInicio) > new Date(BackLog.FechaFin)){
        return Promise.reject("La fecha final del proyecto debe ser mayor que la fecha inicial.")
    }else{
        if(BackLog.Estado.PKID === 1 && BackLog.PKID !== 0){
            return Promise.reject('Proyecto no puede pasar a estado pendiente.')
        }else{
            if(PasBackLog.Estado.PKID !== 3){
                return ProyectoRepository.CrearActualizar(BackLog)
            }else{
                return Promise.reject('No se puede actualizar el proyecto')
            }
        }
    }
}

export const ProyectoService = {
    ValidarSchema,
    ObtenerObjetoEstado,
    Consultar: (BackLog: any) => {
        return ProyectoRepository.Consultar(BackLog)
    },
    ConsultarEnPrueba: (PlanPruebas: IPlanPrueba) => {
        return ProyectoRepository.ConsultarEnPrueba(PlanPruebas)
    },
    ObtenerFiltro: (IDUsuario: number) => {
        return ProyectoRepository.ObtenerFiltro(IDUsuario)
    },
    Listar: () => {
        return ProyectoRepository.Listar()
    },
    ListarPaginacion: (Paginacion: any) => {
        return ProyectoRepository.ListarPaginacion(Paginacion)
    },
    CrearActualizar: (BackLog: IBackLog) => {
        return ProyectoRepository.CrearActualizar(BackLog)
    },
    GuardarTemporal,
    CrearActualizarFiltro: (Filtro: any) => {
        return ProyectoRepository.CrearActualizarFiltro(Filtro)
    },
    LimpiarFiltro: (IDUsuario : number) => {
        return ProyectoRepository.LimpiarFiltro(IDUsuario)
    },
    CrearVersion, 
    ListarCalidad: () => {
        return ProyectoRepository.ListarCalidad()
    },
    ListarProyectosImplantacion: (Paginado: any) => {
        return ProyectoRepository.ListarProyectosImplantacion(Paginado)
    },
}