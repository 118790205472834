import { VersionProductoRepository } from '../../Infrastructure/Repositories/VersionProductoRepository'
import { IProducto } from '../Models/IProducto'
import { IVersionProducto } from '../Models/IVersionProducto'

interface IErroresProducto{
    Nombre?: string
}

interface IErroresProductoVersion{
    Version?: string
    Orden?: string
}

const ValidarProducto = (Producto: IProducto) => {
    const errores: IErroresProducto ={}
    if(!Producto.Nombre) errores.Nombre  = 'Nombre del producto es requerido'
    return errores
}

const ValidarVersionProducto = (ProductoVersion: IVersionProducto) => {
    const errores: IErroresProductoVersion ={}
    if(!ProductoVersion.Version) errores.Version  = 'Version es requerido'
    if(!ProductoVersion.Orden) errores.Orden  = 'Orden es requerido'
    return errores
}

export const VersionProductoService = {
    ValidarProducto,
    ValidarVersionProducto,
    ListarPaginacion: (Paginacion: any) => {
        return VersionProductoRepository.ListarPaginacion(Paginacion)
    },
    Listar: () => {
        return VersionProductoRepository.Listar()
    },
    CrearActualizar: (Producto: IProducto) => {
        return VersionProductoRepository.CrearActualizar(Producto)
    }
}