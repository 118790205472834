import { EmpleadoRepository } from '../../Infrastructure/Repositories/EmpleadoRepository'
import { EmpleadoDTO } from '../Models/EmpleadoDTO'

export const EmpleadoService = {
    Listar: () => {
        return EmpleadoRepository.Listar()
    },
    ListarEmpleadoDesarrollo: () => {
        return EmpleadoRepository.ListarEmpleadoDesarrollo()
    },
    ListarEmpleadoImplantacion: () => {
        return EmpleadoRepository.ListarEmpleadoImplantacion()
    },
    ObtenerEmpleado: (IDUsuario:any) => {
        return EmpleadoRepository.ObtenerEmpleado(IDUsuario)
    },
    CrearActualizar: (body : EmpleadoDTO) => {
        return EmpleadoRepository.CrearActualizar(body)
    }
}