import React from 'react'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'

interface IProps{
    Modal: boolean
    FormValues: any
    CrearVersionProducto: any
    HideModal: any
    HandleChange: any
    ErrorProducto: any
    HandleSelectRow: any
    Request: boolean
}

export default function ProductoModal(Props : IProps){

    const header = (
        <div className="p-d-flex p-ml-auto">
            <Button 
                label = "Nueva Version Producto" 
                onClick = {Props.CrearVersionProducto} 
                autoFocus
                type="button"
                className = "p-ml-auto"/>
        </div>
    )

    const RenderFooter = () =>{
        return (
            <Button 
                type="submit"
                label={Props.Request ? "Cargando..." : "Aceptar"}
                style={{
                    background:'#4D80E4',
                    border: '1px solid #4D80E4'}}
                icon={Props.Request ? "" : "pi pi-check" } 
                className="p-ml-auto"
                disabled={Props.Request}
                autoFocus/>
        )
    }

    return(
        <Dialog
            header='Producto'
            visible={Props.Modal}
            style={{ width: '110vh' }}
            footer={RenderFooter()} 
            onHide={Props.HideModal}>
                <div className="p-grid nested-grid">
                    <div className= {Props.FormValues.PKID > 0 ? "p-col-12 p-lg-6" : "p-col-12"}>
                        <div className="p-col">
                            <div className="p-grid p-fluid ">
                                <div className="p-col-12">
                                    <label 
                                        className="p-d-block"
                                        style={{
                                            color : '#4D80E4',
                                            fontFamily : 'Sarala',
                                            fontSize : '20px'}}>
                                    Nombre</label>
                                    <InputText 
                                        value={Props.FormValues.Nombre}
                                        onChange={Props.HandleChange}
                                        name="Nombre"
                                        type="text"/>
                                    {Props.ErrorProducto.Nombre && <small className="p-invalid p-d-block">{Props.ErrorProducto.Nombre}</small>}
                                </div>
                            </div>
                        </div>
                    </div>
                    {Props.FormValues.PKID > 0?
                        <div className="p-col-12 p-lg-6">
                            <div className="p-col">
                                <div className="p-grid p-fluid ">
                                    <div className="p-col-12">
                                        <label 
                                            className="p-d-block"
                                            style={{
                                                color: '#4D80E4',
                                                fontFamily: 'Sarala',
                                                fontSize: '20px'}}
                                        >UltimaVersion</label>
                                        <InputText 
                                            value={Props.FormValues.UltimaVersion}
                                            onChange={Props.HandleChange}
                                            name="Version"
                                            disabled
                                            type="text"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    :<></>}
                </div>
                {Props.FormValues.PKID > 0?
                    <div className="p-col-12">
                        <DataTable 
                            value={Props.FormValues.VersionProducto ? Props.FormValues.VersionProducto : []} 
                            paginator 
                            rows={5}
                            onSelectionChange={(e) => Props.HandleSelectRow(e.value)}
                            rowsPerPageOptions={[5,10,15]}
                            header={header}
                            selectionMode="single">
                                <Column field="Orden" header="Orden" sortable style={{width:'70%'}}/>
                                <Column field="Version" header="Version" sortable style={{width:'70%'}}/>
                        </DataTable>
                    </div>
                :<></>}
        </Dialog>
    )
}