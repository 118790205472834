import { AsistenciaRepository } from '../../Infrastructure/Repositories/AsistenciaRepository'
import { IAsistencia } from '../Models/IAsistencia'

interface IErroresDocumento{
    Permiso?: string
    Motivo?: string
}

const ValidarSchema = (Asistencia: IAsistencia) => {
    const Errores: IErroresDocumento = {}
    if(!Asistencia.Permiso) Errores.Permiso = 'Nombre es requerido'
    if(!Asistencia.Motivo) Errores.Motivo = 'Link es requerido'
    return Errores
}

export const AsistenciaService = {  
    ListarAsistencia: (Asistencia: any) => {
        return AsistenciaRepository.ListarAsistencia(Asistencia)
    },
    //ValidarSchema,
    ActualizarAsistencia: (Asistencia: IAsistencia) => {
        return AsistenciaRepository.ActualizarAsistencia(Asistencia)
    },
    CrearActualizarFiltro: (Filtro: any) => {
        return AsistenciaRepository.CrearActualizarFiltro(Filtro)
    },
    ObtenerFiltro: (IDUsuario: number) => {
        return AsistenciaRepository.ObtenerFiltro(IDUsuario)
    },
    LimpiarFiltro: (IDUsuario : number) => {
        return AsistenciaRepository.LimpiarFiltro(IDUsuario)
    },
}