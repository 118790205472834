import React from 'react'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Editor } from 'primereact/editor'

interface IProps{
    Modal: boolean
    HideModal: any
    FormValues: any
    HandleChange: any
    CrearNuevoPaso: any
    AbrirListaCasoPrueba: any
    ActualizarPaso: any
    EliminarPaso: any
    ListaCasoPrueba: Array<object>
    ListaCasoPruebaSeleccionado: Array<object>
    ListaModulo: Array<object>
    ListaTipoPrueba: Array<object>
    Errores: any
    Request: boolean
    OnRowSelectCasoPrueba: any
    HandleSubmit: any
}

export default function CasoPruebaVersionModal(Props: IProps){

    const Header = (
        <>
            <span className="ql-formats">
                <button className="ql-bold" aria-label="Bold"></button>
                <button className="ql-italic" aria-label="Italic"></button>
                <button className="ql-underline" aria-label="Underline"></button>
            </span>
            <span className="ql-formats">
                <button type="button" className="ql-list" value="ordered" aria-label="Ordered List">
                    <svg viewBox="0 0 18 18"> 
                        <line className="ql-stroke" x1="7" x2="15" y1="4" y2="4"></line> 
                        <line className="ql-stroke" x1="7" x2="15" y1="9" y2="9"></line> 
                        <line className="ql-stroke" x1="7" x2="15" y1="14" y2="14"></line> 
                        <line className="ql-stroke ql-thin" x1="2.5" x2="4.5" y1="5.5" y2="5.5"></line> 
                        <path className="ql-fill" d="M3.5,6A0.5,0.5,0,0,1,3,5.5V3.085l-0.276.138A0.5,0.5,0,0,1,2.053,3c-0.124-.247-0.023-0.324.224-0.447l1-.5A0.5,0.5,0,0,1,4,2.5v3A0.5,0.5,0,0,1,3.5,6Z"></path> 
                        <path className="ql-stroke ql-thin" d="M4.5,10.5h-2c0-.234,1.85-1.076,1.85-2.234A0.959,0.959,0,0,0,2.5,8.156"></path> 
                        <path className="ql-stroke ql-thin" d="M2.5,14.846a0.959,0.959,0,0,0,1.85-.109A0.7,0.7,0,0,0,3.75,14a0.688,0.688,0,0,0,.6-0.736,0.959,0.959,0,0,0-1.85-.109"></path> 
                    </svg>
                </button>
                <button type="button" className="ql-list" value="bullet" aria-label="Unordered List">
                    <svg viewBox="0 0 18 18"> 
                        <line className="ql-stroke" x1="6" x2="15" y1="4" y2="4"></line> 
                        <line className="ql-stroke" x1="6" x2="15" y1="9" y2="9"></line> 
                        <line className="ql-stroke" x1="6" x2="15" y1="14" y2="14"></line> 
                        <line className="ql-stroke" x1="3" x2="3" y1="4" y2="4"></line> 
                        <line className="ql-stroke" x1="3" x2="3" y1="9" y2="9"></line> 
                        <line className="ql-stroke" x1="3" x2="3" y1="14" y2="14"></line> 
                    </svg>
                </button>
            </span>
        </>
    )

    const actionBodyTemplate = (RowData: any) => {
        return (
            <React.Fragment>
                <div className="p-grid p-fluid ">
                    <div className="p-col-6">
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-outlined" type="text" onClick={() => {Props.ActualizarPaso(RowData)}} />
                    </div>
                    <div className="p-col-6">
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-outlined" type="text" onClick={() => {Props.EliminarPaso(RowData)}} />
                    </div>
                </div>
            </React.Fragment>
        );
    }

    const EditorDescripcion = (Event: any) => {
        Props.HandleChange({
            target:{
                name: "Descripcion",
                value: Event.htmlValue?Event.htmlValue.replace('<span ','<div ').replace('</span>','</div>'):''
            }
        })
    }

    const OnRowSelect = (row: any) => {        
        Props.OnRowSelectCasoPrueba(row)
    }

    const RenderFooter = () =>{
        return (
            <Button 
                //type="submit"
                onClick={Props.HandleSubmit}
                label={Props.Request ? "Cargando..." : "Aceptar"}
                style={{
                    background:'#4D80E4',
                    border: '1px solid #4D80E4'}}
                icon={Props.Request ? "" : "pi pi-check" }
                className="p-ml-auto"
                disabled={Props.Request}
                autoFocus />
        )
    }

    return(
        <Dialog
            header='Caso de Prueba Version'
            visible={Props.Modal}
            style={{ width: '110vh' }}
            footer={RenderFooter()} 
            onHide={Props.HideModal}>
                <div className="p-grid nested-grid">
                    <div className="p-col-12">
                        <div className="p-col">
                            <div className="p-grid p-fluid p-mr-1 p-ml-1">
                                <div className="p-col-12">
                                <div className="open-card-composer" style={{width:'100%'}} onClick={(event) => Props.AbrirListaCasoPrueba(event)}>
                                <i className="pi pi-plus icon-sm"></i>
                                <span>Seleccione un caso de prueba</span>
                                </div>     
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="p-col-12">
                        <div className="p-col">                            
                        <DataTable 
                                value={Props.ListaCasoPruebaSeleccionado}
                                rows={5}      
                                onRowClick={(row)=> OnRowSelect(row)}                          
                                paginator
                                className="p-datatable-sm"   
                                >  
                                    <Column field="Nombre" header="Caso de Prueba" style={{width:'60%', center:'true', cursor:'pointer'}}></Column>  
                                    <Column field="Producto.Nombre" header="Producto" style={{width:'20%', center:'true', cursor:'pointer'}}></Column>    
                                    <Column field="VersionProducto.Version" header="Version del Producto" style={{width:'20%', center:'true', cursor:'pointer'}}>
                                        </Column>       
                            </DataTable>
                        </div>
                    </div>

                    <div className="p-col-12 p-lg-6">
                        <div className="p-col">
                            <div className="p-grid p-fluid p-mr-1 p-ml-1">
                                <div className="p-col-12">
                                    <label className="p-d-block" 
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'}}>Nombre</label>
                                    <InputText
                                        name="Nombre"
                                        disabled
                                        onChange={Props.HandleChange}
                                        value={Props.FormValues.Nombre}
                                        type="text"/>
                                    {Props.Errores.Nombre && <small className="p-invalid p-d-block">{Props.Errores.Nombre}</small>}
                                </div>
                                <div className="p-col-12">
                                    <label className="p-d-block" 
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'}}>Descripcion</label>
                                    <Editor 
                                        style={{height:'120px'}} 
                                        value={Props.FormValues.Descripcion ? Props.FormValues.Descripcion:''} 
                                        onTextChange={EditorDescripcion}
                                        headerTemplate={Header}/>
                                    {Props.Errores.Descripcion && <small className="p-invalid p-d-block">{Props.Errores.Descripcion}</small>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-col-12 p-lg-6">
                        <div className="p-col">
                            <div className="p-grid p-fluid p-mr-1 p-ml-1">
                                <div className="p-col-12">
                                    <label className="p-d-block" 
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'}}>Modulo</label>
                                    <Dropdown 
                                        value={{
                                            code: Props.FormValues.Modulo.PKID,
                                            name: Props.FormValues.Modulo.Nombre}}
                                        options={Props.ListaModulo}
                                        onChange={Props.HandleChange}
                                        optionLabel="name"
                                        name="Modulo"
                                        placeholder="Seleccione el Modulo"/>
                                    {Props.Errores.Modulo && <small className="p-invalid p-d-block">{Props.Errores.Modulo}</small>}
                                </div>
                                <div className="p-col-12">
                                    <label className="p-d-block" 
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'}}>Responsable</label>
                                    <InputText
                                        name="Empleado"
                                        disabled
                                        onChange={Props.HandleChange}
                                        value={Props.FormValues.Empleado.Nombre}
                                        type="text" />
                                    <small className="p-d-block" style={{color:'#fff'}}>.</small>
                                </div>
                                <div className="p-col-12">
                                    <label className="p-d-block" 
                                        style={{
                                            color : '#4D80E4',
                                            fontFamily : 'Sarala',
                                            fontSize : '20px'}}>Tipo de Prueba</label>
                                    <Dropdown 
                                        value={{
                                            code: Props.FormValues.TipoPrueba.PKID,
                                            name: Props.FormValues.TipoPrueba.Nombre}}
                                        options={Props.ListaTipoPrueba}
                                        onChange={Props.HandleChange}
                                        optionLabel="name"
                                        name="TipoPrueba"
                                        placeholder="Seleccione Tipo de Prueba"/>
                                    {Props.Errores.TipoPrueba && <small className="p-invalid p-d-block">{Props.Errores.TipoPrueba}</small>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-col-12">
                        <div className="p-col">
                            <div className="open-card-composer" style={{width:'15%'}} onClick={(event) => Props.CrearNuevoPaso(event)}>
                                <i className="pi pi-plus icon-sm"></i>
                                <span>Nuevo Paso</span>
                            </div>
                            <DataTable 
                                value={Props.FormValues.Pasos}
                                rows={5}
                                paginator
                                className="p-datatable-sm">
                                    <Column  rowReorder style={{ width: '5%' }}></Column>
                                    <Column field="NumeroPaso" header="Paso" style={{width:'20%'}}></Column>
                                    <Column field="Accion" header="Accion" style={{width:'30%', center:'true'}}></Column>
                                    <Column field="ResultadoEsperado" header="Resultado Esperado" style={{width:'35%'}}></Column>
                                    <Column body={actionBodyTemplate}  header="Accion" style={{width:'10%'}}></Column>
                            </DataTable>
                        </div>
                    </div>
                </div>
        </Dialog>
    )
}