import React  from 'react'
import { Checkbox } from 'primereact/checkbox'
import { Button } from 'primereact/button'

export default function ProyectoFiltro(props : any){

    const GetResponsables = () => {
        if(props.Responsables !== null){
            if(Array.isArray(props.Responsables)){
                let Responsables = props.Responsables
                let lResponsables: Array<object> = []
                let checksBd = props.Filtro.IDSResponsables.split('-')
                Object.keys(checksBd).forEach(function(key){
                    if(checksBd[key]!==''){
                        let itemCheck=null
                        Object.keys(Responsables).forEach(function(key2){
                            if(Responsables[key2].code === parseInt(checksBd[key])){
                                itemCheck=Responsables[key2]
                            }
                        })
                        if(itemCheck!=null){
                            lResponsables.push(itemCheck)
                        }
                    }
                })
                return lResponsables
            }else{
                return []
            }
        }else{
            return []
        }
    }

    const GetEstados = () => {
        if(props.Estados!==null){
            if(Array.isArray(props.Estados)){
                let Estados = props.Estados
                let lEstados: Array<object> = []
                let checksBd = props.Filtro.IDSEstados.split('-')
                Object.keys(checksBd).forEach(function(key){
                    if(checksBd[key]!==''){
                        let itemCheck=null
                        Object.keys(Estados).forEach(function(key2){
                            if(Estados[key2].code===parseInt(checksBd[key])){
                                itemCheck=Estados[key2]
                            }
                        })
                        if(itemCheck!=null){
                            lEstados.push(itemCheck)
                        }
                    }
                })
                return lEstados
            }else{
                return []
            }
        }else{
            return []
        }
    }
    
    const OnResponsableChange = (e: any) => {
        let Responsablesleccionados = GetResponsables()
        if (e.checked) {
            Responsablesleccionados.push(e.value)
        }else{
            for (let i = 0; i < Responsablesleccionados.length; i++) {
                const temporal: any = Responsablesleccionados[i]
                if (temporal.code === e.value.code) {
                    Responsablesleccionados.splice(i, 1)
                    break;
                }
            }
        }

        let itemsChecks=''
        Object.keys(Responsablesleccionados).forEach(function(key: any){
            var it: any = Responsablesleccionados[key]
            itemsChecks=itemsChecks+'-'+it.code
        })
        props.onChangeFiltro(itemsChecks,"IDSResponsables")
        //CerrarFiltro()
    }

    const OnEstadoChange=(e: any)=>{
        let EstadosSeleccionados = GetEstados()

        if (e.checked) {
            EstadosSeleccionados.push(e.value)
        }

        else {
            for (let i = 0; i < EstadosSeleccionados.length; i++) {
                const temporal: any = EstadosSeleccionados[i]
                if (temporal.code === e.value.code) {
                    EstadosSeleccionados.splice(i, 1)
                    break;
                }
            }
        }

        let itemsChecks=''
        Object.keys(EstadosSeleccionados).forEach(function(key: any){
            var it: any = EstadosSeleccionados[key]
            itemsChecks = itemsChecks+'-'+it.code
        });
        props.onChangeFiltro(itemsChecks,"IDSEstados")
        //CerrarFiltro()
    }

    const ClickResetear=(e: any)=>{
        props.ClickResetear(e)
        //CerrarFiltro()
    }

    const ClickAplicar=(e: any)=>{
        props.ClickAplicar(e)
        CerrarFiltro()
    }

    const CerrarFiltro = () => {
        (document.getElementById('PanelFiltro') as HTMLInputElement).classList.remove('active');
        (document.getElementById('PopupFiltro') as HTMLInputElement).classList.remove('active');
    }

    return(
        <div id="PanelFiltro" className="Slide-Filtro">
            <div className="PopupFiltro" id="PopupFiltro">
                <div className="HeaderPopup">
                    <div className="p-d-flex p-jc-between">
                        <div className="Title">Filtro</div>
                        <div className="btn-cerrar-popup" onClick={(e) => CerrarFiltro()}
                        ><i className="fas fa-times"></i></div>
                    </div>
                </div>
                <div className="BodyFiltro">
                    <div className="card">
                        <h3>Responsable</h3>
                        {
                            props.Responsables.map((entidad: any) => {
                                return (
                                    <div 
                                        key={entidad.code} 
                                        className="p-field-checkbox">
                                            <Checkbox 
                                                name="category" 
                                                value={entidad} 
                                                onChange={OnResponsableChange} 
                                                checked={
                                                    GetResponsables().some(
                                                        (item: any) => item.code === entidad.code
                                                    )} 
                                                disabled={entidad.code === 'R'} />
                                            <label htmlFor={entidad.code}>{entidad.name}</label>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="card">
                        <h3>Estado</h3>
                        {
                            props.Estados.map((entidad: any) => {
                                return (
                                    <div key={entidad.code} className="p-field-checkbox">
                                        <Checkbox 
                                            name="category" 
                                            value={entidad} 
                                            onChange={OnEstadoChange} 
                                            checked={
                                                GetEstados().some(
                                                    (item: any) => item.code === entidad.code
                                                )} 
                                            disabled={entidad.code === 'R'} />
                                        <label htmlFor={entidad.code}>{entidad.name}</label>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="card">
                        <Button 
                            label="Aplicar" 
                            style={{
                                marginRight:'5px',
                                background:'#4D80E4',
                                border: '1px solid #4D80E4'}}
                            icon="pi pi-check" 
                            onClick={ClickAplicar}                            
                            className="p-ml-auto"
                            autoFocus />
                        <Button 
                            label="Borrar" 
                            style={{
                                background:'#4D80E4',
                                border: '1px solid #4D80E4'}}
                            icon="pi pi-check" 
                            onClick={ClickResetear}
                            className="p-ml-auto"
                            autoFocus />
                    </div>
                </div>
            </div>
        </div>
    )
}
