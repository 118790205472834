import React, { useState } from 'react'
import { Button } from 'primereact/button'
import { TabMenu } from 'primereact/tabmenu'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import { Dropdown } from 'primereact/dropdown'
import '../Assets/Css/PanelRequerimiento2.css'
import { Editor } from 'primereact/editor'

interface IProps {
    Mode: boolean
    OnSubmit: any
    Solicitudes: any
    HandleChange: any
    FormValues: any
    ListaPrioridad: any
    ListaEstado: any
    Errores: any
    Request: boolean
}

export default function ItemBackLogModal(Props: IProps) {

    const header = (
        <>
            <span className="ql-formats">
                <button className="ql-bold" aria-label="Bold"></button>
                <button className="ql-italic" aria-label="Italic"></button>
                <button className="ql-underline" aria-label="Underline"></button>
            </span>
            <span className="ql-formats">
                <button type="button" className="ql-list" value="ordered" aria-label="Ordered List">
                    <svg viewBox="0 0 18 18">
                        <line className="ql-stroke" x1="7" x2="15" y1="4" y2="4"></line>
                        <line className="ql-stroke" x1="7" x2="15" y1="9" y2="9"></line>
                        <line className="ql-stroke" x1="7" x2="15" y1="14" y2="14"></line>
                        <line className="ql-stroke ql-thin" x1="2.5" x2="4.5" y1="5.5" y2="5.5"></line>
                        <path className="ql-fill" d="M3.5,6A0.5,0.5,0,0,1,3,5.5V3.085l-0.276.138A0.5,0.5,0,0,1,2.053,3c-0.124-.247-0.023-0.324.224-0.447l1-.5A0.5,0.5,0,0,1,4,2.5v3A0.5,0.5,0,0,1,3.5,6Z"></path>
                        <path className="ql-stroke ql-thin" d="M4.5,10.5h-2c0-.234,1.85-1.076,1.85-2.234A0.959,0.959,0,0,0,2.5,8.156"></path>
                        <path className="ql-stroke ql-thin" d="M2.5,14.846a0.959,0.959,0,0,0,1.85-.109A0.7,0.7,0,0,0,3.75,14a0.688,0.688,0,0,0,.6-0.736,0.959,0.959,0,0,0-1.85-.109"></path>
                    </svg>
                </button>
                <button type="button" className="ql-list" value="bullet" aria-label="Unordered List">
                    <svg viewBox="0 0 18 18">
                        <line className="ql-stroke" x1="6" x2="15" y1="4" y2="4"></line>
                        <line className="ql-stroke" x1="6" x2="15" y1="9" y2="9"></line>
                        <line className="ql-stroke" x1="6" x2="15" y1="14" y2="14"></line>
                        <line className="ql-stroke" x1="3" x2="3" y1="4" y2="4"></line>
                        <line className="ql-stroke" x1="3" x2="3" y1="9" y2="9"></line>
                        <line className="ql-stroke" x1="3" x2="3" y1="14" y2="14"></line>
                    </svg>
                </button>
            </span>
        </>
    );

    const EditorDescripcion = (Event: any) => {
        Props.HandleChange({
            target: {
                name: "Descripcion",
                value: Event.htmlValue ? Event.htmlValue.replace('<span ', '<div ').replace('</span>', '</div>') : ''
            }
        })
    }

    const Items = [
        { label: 'Requerimiento', icon: 'pi pi-fw pi-pencil' },
        { label: 'Solución', icon: 'pi pi-fw pi-file' }
    ]

    const [ActiveItem, setActiveItem] = useState(Items[0])

    const ImageArchivo = (RowData: any) => {
        var extension = RowData.Extension
        let icono = ''
        let fondo = ''

        if (extension === 'txt') {
            icono = 'pi pi-file'
            fondo = '#000000'
        }
        else if (extension === 'pdf') {
            icono = 'pi pi-file-pdf'
            fondo = '#ff471a'
        }
        else if (extension === 'docx' || extension === 'docm' || extension === 'dotx' || extension === 'dotm' || extension === 'doc' || extension === 'dot' || extension === 'rtf') {
            icono = 'far fa-file-word'
            fondo = '#80e5ff'
        }
        else if (extension === 'xls' || extension === 'xlsx' || extension === 'xlsm' || extension === 'xltx' || extension === 'xltm') {
            icono = 'far fa-file-excel'
            fondo = '#00802b'
        }
        else if (extension === 'pptx' || extension === 'pptm' || extension === 'ppt' || extension === 'potx') {
            icono = 'far fa-file-powerpoint'
            fondo = '#ffa64d'
        }
        else if (extension === 'sql' || extension === 'js' || extension === 'vb' || extension === 'cs') {
            icono = 'fas fa-scroll'
            fondo = '#66ff66'
        }
        else if (extension === 'zip' || extension === '7z' || extension === 'rar') {
            icono = 'far fa-file-archive'
            fondo = '#3385ff'
        }
        else {
            icono = 'pi pi-question'
            fondo = '#000000'
        }

        return (
            <React.Fragment>
                <div className="p-grid p-fluid ">
                    <div className="p-col-12">
                        <i className={icono} style={{ 'fontSize': '2em', color: `${fondo}` }}></i>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    const FechaCreacion = (RowData: any) => {
        let newDate
        RowData.FechaCreacion !== '' ? newDate = new Date(RowData.FechaCreacion) : newDate = new Date()
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        return `${date}${'/'}${month < 10 ? `0${month}` : `${month}`}${'/'}${year}`
    }

    const TamañoArchivo = (RowData: any) => {
        return (<p>{(RowData.Tamagno / Math.pow(1024, 2)).toFixed(2) + 'MB'}</p>)
    }

    return (
        <div id="PanelRequerimiento" className={Props.Mode ? 'PanelRequerimientoI' : 'PanelRequerimientoD'}>
            <div id="PopupRequerimiento" className={Props.Mode ? 'PopupRequerimientoI' : 'PopupRequerimientoD1'}>
                <div className="p-d-flex p-jc-between">
                    <p style={{
                        color: '#4D80E4',
                        fontFamily: 'Sarala',
                        fontSize: '22px'
                    }}>ItemBackLog</p>
                    <i className="fas fa-times btn-cerrar-requerimiento" onClick={() => {
                        (document.getElementById('PanelRequerimiento') as HTMLInputElement).classList.remove('active');
                        (document.getElementById('PopupRequerimiento') as HTMLInputElement).classList.remove('active');
                    }}></i>
                </div>
                <form autoComplete="off" onSubmit={Props.OnSubmit}>
                    <div className="p-col">
                        <div className="p-grid p-fluid ">
                            <div className="p-col-12">
                                <label
                                    className="p-d-block"
                                    style={{
                                        color: '#4D80E4',
                                        fontFamily: 'Sarala',
                                        fontSize: '20px'
                                    }}
                                >Nombre</label>
                                <InputText
                                    value={Props.FormValues.Nombre}
                                    onChange={Props.HandleChange}
                                    name="Nombre"
                                    type="text" />
                                {Props.Errores.Nombre && <small className="p-invalid p-d-block">{Props.Errores.Nombre}</small>}
                            </div>
                        </div>
                    </div>

                    <div className="p-col-12">
                        <label
                            className="p-d-block"
                            style={{
                                color: '#4D80E4',
                                fontFamily: 'Sarala',
                                fontSize: '20px'
                            }}
                        >Descripción</label>
                        <Editor
                            style={{ height: '120px' }}
                            value={Props.FormValues.Descripcion ? Props.FormValues.Descripcion : ''}
                            onTextChange={EditorDescripcion}
                            headerTemplate={header} />
                        {Props.Errores.Descripcion && <small className="p-invalid p-d-block">{Props.Errores.Descripcion}</small>}
                    </div>
                    <div className="p-grid p-fluid p-mr-1 p-ml-1">
                        {Props.FormValues.PKID > 0 ?
                            <div className="p-col-12 p-md-4">
                                <label
                                    className="p-d-block"
                                    style={{
                                        color: '#4D80E4',
                                        fontFamily: 'Sarala',
                                        fontSize: '20px'
                                    }}
                                >Orden</label>
                                <InputText
                                    value={Props.FormValues.Orden}
                                    onChange={Props.HandleChange}
                                    name="Orden"
                                    type="number"
                                    min="1" />
                                {Props.Errores.Orden && <small className="p-invalid p-d-block">{Props.Errores.Orden}</small>}
                            </div>
                            :
                            <div className="p-col-12 p-md-6">
                                <label
                                    className="p-d-block"
                                    style={{
                                        color: '#4D80E4',
                                        fontFamily: 'Sarala',
                                        fontSize: '20px'
                                    }}
                                >Orden</label>
                                <InputText
                                    value={Props.FormValues.Orden}
                                    onChange={Props.HandleChange}
                                    name="Orden"
                                    type="number"
                                    min="1" />
                                {Props.Errores.Orden && <small className="p-invalid p-d-block">{Props.Errores.Orden}</small>}
                            </div>
                        }
                        {Props.FormValues.PKID > 0 ?
                            <div className="p-col-12 p-md-4">
                                <label
                                    className="p-d-block"
                                    style={{
                                        color: '#4D80E4',
                                        fontFamily: 'Sarala',
                                        fontSize: '20px'
                                    }}
                                >Prioridad</label>
                                <Dropdown
                                    style={{
                                        overflow: 'visible'
                                    }}
                                    value={{
                                        code: Props.FormValues.Prioridad ? Props.FormValues.Prioridad.PKID : 0,
                                        name: Props.FormValues.Prioridad ? Props.FormValues.Prioridad.Descripcion : ''
                                    }}
                                    options={Props.ListaPrioridad}
                                    name="Prioridad"
                                    onChange={Props.HandleChange}
                                    optionLabel="name"
                                    placeholder="Seleccione la Prioridad" />
                                {Props.Errores.Prioridad && <small className="p-invalid p-d-block">{Props.Errores.Prioridad}</small>}
                            </div>
                            :
                            <div className="p-col-12 p-md-6">
                                <label
                                    className="p-d-block"
                                    style={{
                                        color: '#4D80E4',
                                        fontFamily: 'Sarala',
                                        fontSize: '20px'
                                    }}
                                >Prioridad</label>
                                <Dropdown
                                    style={{
                                        overflow: 'visible'
                                    }}
                                    value={{
                                        code: Props.FormValues.Prioridad ? Props.FormValues.Prioridad.PKID : 0,
                                        name: Props.FormValues.Prioridad ? Props.FormValues.Prioridad.Descripcion : ''
                                    }}
                                    options={Props.ListaPrioridad}
                                    name="Prioridad"
                                    onChange={Props.HandleChange}
                                    optionLabel="name"
                                    placeholder="Seleccione la Prioridad" />
                                {Props.Errores.Prioridad && <small className="p-invalid p-d-block">{Props.Errores.Prioridad}</small>}
                            </div>
                        }
                        {Props.FormValues.PKID > 0 ?
                            <div className="p-col-12 p-md-4">
                                <label
                                    className="p-d-block"
                                    style={{
                                        color: '#4D80E4',
                                        fontFamily: 'Sarala',
                                        fontSize: '20px'
                                    }}
                                >Estado</label>
                                <Dropdown
                                    value={{
                                        code: Props.FormValues.Estado.PKID,
                                        name: Props.FormValues.Estado.Descripcion
                                    }}
                                    options={Props.ListaEstado}
                                    onChange={Props.HandleChange}
                                    name="Estado"
                                    optionLabel="name"
                                    placeholder="Seleccione el Estado" />
                                {Props.Errores.Estado && <small className="p-invalid p-d-block">{Props.Errores.Estado}</small>}
                            </div>
                            :
                            <></>
                        }
                    </div>
                    <div className="p-d-flex footer">
                        <Button
                            label={Props.Request ? "Cargando..." : "Aceptar"}
                            style={{
                                background: '#4D80E4',
                                border: '1px solid #4D80E4'
                            }}
                            icon={Props.Request ? "" : "pi pi-check"}
                            className="p-ml-auto"
                            disabled={Props.Request}
                            autoFocus />
                    </div>
                </form>
            </div>
        </div>
    )
}