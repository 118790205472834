import React from 'react'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'

interface IProps {
    Modal: boolean
    HideModal: any
    OnChangeContraseña: any
    CambioContraseña: any
    Request: boolean
}

export default function  ModalCambioContraseña(Props: IProps) {

    const RenderFooter = () => {
        return(
            <Button 
                label = { Props.Request ? "Cargando..." : "Aceptar" } 
                style = {{
                    background : '#4D80E4',
                    border : '1px solid #4D80E4'}}
                icon = { Props.Request ? "" : "pi pi-check" }
                className = "p-ml-auto"
                disabled = { Props.Request }
                autoFocus />
        )
    }
    
    return(
        <Dialog
            header='Cambio de contraseña'
            visible={Props.Modal}
            style={{ width: '90vh' }}
            footer={RenderFooter()} 
            onHide={Props.HideModal}>
                <div className="modal-body p-mt-3">
                    <div className="p-grid nested-grid">
                        <div className="p-col-12">
                            <div className="p-grid p-fluid ">
                                <div className="p-col">
                                    <label 
                                        className="p-d-block"
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'}}
                                    >Contraseña antigua</label>
                                    <InputText 
                                        name="AntiguaContrasena"
                                        type="password"
                                        value={Props.CambioContraseña.AntiguaContrasena}
                                        onChange={Props.OnChangeContraseña}/>    
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12">
                            <div className="p-grid p-fluid ">
                                <div className="p-col">
                                    <label 
                                        className="p-d-block"
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'}}
                                    >Contraseña nueva</label>
                                    <InputText 
                                        name="NuevaContrasena"
                                        type="password"
                                        value={Props.CambioContraseña.NuevaContrasena}
                                        onChange={Props.OnChangeContraseña}/>    
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12">
                            <div className="p-grid p-fluid ">
                                <div className="p-col">
                                    <label 
                                        className="p-d-block"
                                        style={{
                                            color: '#4D80E4',
                                            fontFamily: 'Sarala',
                                            fontSize: '20px'}}
                                    >Repetir contraseña nueva</label>
                                    <InputText 
                                        name="RepetirNuevaContrasena"
                                        type="password"
                                        value={Props.CambioContraseña.RepetirNuevaContrasena}
                                        onChange={Props.OnChangeContraseña}/>    
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
        </Dialog>
    )
}