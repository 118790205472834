import React,{useEffect,useCallback} from 'react'
import { InputText } from 'primereact/inputtext'
import useNearScreen from './useNearScreen'
import debounce from 'just-debounce-it'

interface IProps {
    Paginacion: any
    Pendiente: any
    EnCurso: any
    Hecho: any
    OnResponse: any
    MoverEstado: any
}

export default function SolicitudBody(Props : IProps){

    const RefPendiente = React.createRef<HTMLInputElement>()
    const RefEnCurso = React.createRef<HTMLInputElement>()
    const RefHecho = React.createRef<HTMLInputElement>()

    const NSPendiente = useNearScreen({
        externalRef :  !Props.Pendiente.Loading && RefPendiente,
        once: false
    }).isNearScreen

    const NSEnCurso = useNearScreen({
        externalRef : !Props.EnCurso.Loading && RefEnCurso,
        once: false
    }).isNearScreen

    const NSHecho = useNearScreen({
        externalRef : !Props.Hecho.Loading && RefHecho,
        once: false
    }).isNearScreen

    const DebounceNextPage = useCallback((Estado : string) => {
        debounce(Props.Paginacion(Estado),5000)
    },[])

    useEffect(function (){
        if(NSPendiente) DebounceNextPage('Pendiente')
        if(NSEnCurso) DebounceNextPage('EnCurso')
        if(NSHecho) DebounceNextPage('Hecho')
    })

    const DragStart = (Event : any) => {
        Event.dataTransfer.setData('EstadoPKID', Event.target.id)
    }

    const DragOver = (Event : React.SyntheticEvent) => {
        Event.preventDefault()
        Event.stopPropagation()
    }

    const DropPendiente = (Event : React.DragEvent) => {
        Event.preventDefault()
        Props.MoverEstado(Event.dataTransfer.getData('EstadoPKID').split('-')[0],'Pendiente', Event.dataTransfer.getData('EstadoPKID').split('-')[1])
    }

    const DropEnCurso = (Event : React.DragEvent) => {
        Event.preventDefault()
        Props.MoverEstado(Event.dataTransfer.getData('EstadoPKID').split('-')[0],'EnCurso', Event.dataTransfer.getData('EstadoPKID').split('-')[1])
    }

    const DropHecho = (Event : React.DragEvent) => {
        Event.preventDefault()
        Props.MoverEstado(Event.dataTransfer.getData('EstadoPKID').split('-')[0],'Hecho', Event.dataTransfer.getData('EstadoPKID').split('-')[1])
    }

    const RenderListado = (Solicitudes: any,Estado: string) => {
        let Lista = Solicitudes.map( ( Entidad: any ) => {
            return(
                <li
                    id = {Estado + '-' + Entidad.PKID}
                    draggable
                    onDragStart = {DragStart}
                    onDragOver = {DragOver}
                    key = {Estado + '-' + Entidad.PKID}
                    onClick = { () => Props.OnResponse(Entidad, true)}>
                    <div className="comment-main-level">
                        <div className="comment-box">
                            <div className="comment-head">
                                <h6 className="comment-name by-author" >
                                    {Entidad.Nombre}
                                </h6>
                                <div className="estrella">
                                    {Entidad.Numero}
                                </div>
                            </div>
                            <div className="comment-content">
                                <div className="p-grid p-fluid p-ml-1 p-mr-1">
                                    <div className="p-col-12 p-md-6">
                                        <label className="p-ml-2 p-mr-2">Cliente</label>
                                        <InputText 
                                            value={Entidad.Cliente.Nombre}
                                            disabled
                                            type="text"/>
                                    </div>
                                    <div className="p-col-12 p-md-6">
                                        <label className="p-ml-2 p-mr-2">Consultor</label>
                                        <InputText 
                                            value={Entidad.Empleado.Nombre}
                                            disabled
                                            type="text"/>
                                    </div>
                                </div>
                                <div className="p-grid p-fluid p-ml-1 p-mr-1">
                                    <div className="p-col-12 p-md-6">
                                        <label className="p-ml-2 p-mr-2">Solicitante</label>
                                        <InputText 
                                            value={Entidad.NombreSolicitante}
                                            disabled
                                            type="text"/>
                                    </div>
                                    <div className="p-col-12 p-md-6">
                                        <label className="p-ml-2 p-mr-2">Tipo</label>
                                        <InputText 
                                            value={Entidad.TipoSolicitud.Nombre}
                                            disabled
                                            type="text"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            )
        })
        return Lista;
    }

    return(
        <div className="ContainerTrello">
            <div className="p-grid" style={{margin: '0px'}}>
                <div className="p-col-12 p-md-6 p-lg-4">
                    <div className="list-wrapper">
                        <div className="list p-shadow-3" style={{height:'82vh'}}>
                            <div className="list-header is-menu-shown">
                                <h2 className="list-header-name-assist lbls">Pendiente</h2>
                            </div>
                            <div 
                                className="list-cards"
                                onDrop={DropPendiente}
                                onDragOver={DragOver}>
                                    <ul id="lPendiente" className="comment-list">
                                        {RenderListado(Props.Pendiente.Lista,'Pendiente')}
                                    </ul>
                                    <div ref={RefPendiente}>
                                    {((Props.Pendiente.Paginacion.NumeroPagina < Props.Pendiente.Paginacion.NumeroPaginas) || Props.Pendiente.Loading)? 
                                            <div className="circleLoad"></div> 
                                        : 
                                            <></>}
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-col-12 p-md-6 p-lg-4">
                    <div className="list-wrapper">
                        <div className="list p-shadow-3" style={{height:'82vh'}}>
                            <div className="list-header is-menu-shown">
                                <h2 className="list-header-name-assist lbls">En Curso</h2>
                            </div>
                            <div 
                                className="list-cards"
                                onDrop={DropEnCurso}
                                onDragOver={DragOver}>
                                    <ul id="lEnCurso" className="comment-list">
                                        {RenderListado(Props.EnCurso.Lista,'EnCurso')}
                                    </ul>
                                    <div ref={RefEnCurso}>
                                        {((Props.EnCurso.Paginacion.NumeroPagina < Props.EnCurso.Paginacion.NumeroPaginas) || Props.EnCurso.Loading)?
                                            <div className="circleLoad"></div> 
                                            : 
                                            <></>}
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-col-12 p-md-6 p-lg-4">
                    <div className="list-wrapper">
                        <div className="list p-shadow-3" style={{height:'82vh'}}>
                            <div className="list-header is-menu-shown">
                                <h2 className="list-header-name-assist lbls">Hecho</h2>
                            </div>
                            <div 
                                className="list-cards"
                                onDrop={DropHecho}
                                onDragOver={DragOver}>
                                    <ul id="lHecho" className="comment-list">
                                        {RenderListado(Props.Hecho.Lista,'Hecho')}
                                    </ul>
                                    <div ref={RefHecho}>
                                        {((Props.Hecho.Paginacion.NumeroPagina < Props.Hecho.Paginacion.NumeroPaginas) || Props.Hecho.Loading)? 
                                            <div className="circleLoad"></div> 
                                            : 
                                            <></>}
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}