import { ItemPlanPruebaRepository } from '../../Infrastructure/Repositories/ItemPlanPruebaRepository'
import { IItemPlanDePrueba } from '../Models/IItemPlanDePrueba'

const ValidarSchema = (ItemPlanDePrueba: IItemPlanDePrueba) => {
    const Errores: {
        Nombre?: string
        Requerimiento?: string} = {}
    if(!ItemPlanDePrueba.Nombre) Errores.Nombre = 'Nombre es requerido'
    if(!ItemPlanDePrueba.EsDeRegresion) if(!ItemPlanDePrueba.Requerimiento.PKID) Errores.Requerimiento = 'Requerimiento es requerido'    
    return Errores
}

const ObtenerObjetoEstado = (Array: Array<IItemPlanDePrueba>, PKID: string, IDEstado: number) => {
    let aObj: Array<IItemPlanDePrueba> =  Array.filter( (Obj : IItemPlanDePrueba) =>{
        return Obj.PKID === parseInt(PKID,10) 
    })
    let Obj: IItemPlanDePrueba = aObj[0] 
    return {
        ...Obj,
        Estado:{
            ...Obj.Estado,
            PKID: IDEstado
        }
    }
}

const ValidarPaso = (Paso: any) => {
    const Errores: {Accion?: string, ResultadoEsperado?: string} = {}
    if(!Paso.Accion) Errores.Accion = 'Accion es requerido'
    if(!Paso.ResultadoEsperado) Errores.ResultadoEsperado = 'Resultado esperado es requerido'
    return Errores
}

export const ItemPlanPruebaService = {
    ValidarSchema,
    ValidarPaso,
    ObtenerObjetoEstado,
    ListarPaginacion: (Paginacion: any) => {
        return ItemPlanPruebaRepository.ListarPaginacion(Paginacion)
    },
    CrearActualizar: (ItemPlanPrueba: IItemPlanDePrueba) => {
        return ItemPlanPruebaRepository.CrearActualizar(ItemPlanPrueba)
    }
}