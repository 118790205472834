import React from 'react'

export default function InicioHeader(){
    return(
        <div className="p-col-12">
            <div className="CrumbBeetwen">
                <div className="p-d-flex">
                    <ul className="breadcrumb">
                        <li>Inicio</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}