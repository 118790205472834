import React,{useEffect,useCallback, useRef, useState} from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Menu } from 'primereact/menu';
import { InputText } from 'primereact/inputtext';

import useNearScreen from './useNearScreen'
import debounce from 'just-debounce-it'

interface IProps {
    Paginacion: any
    Pendiente: any
    EnCurso: any
    Hecho: any
    NuevoItemPlanDePruebasRegresion: any
    OnResponse: any
    ListaCasosPruebaVersion: any
    MoverEstado: any
}

export default function PlanDePruebasBody(Props : IProps){

    const Items = [
        {
            label: 'Item Plan de Prueba',
            icon: 'fas fa-file-signature',
            style:{color: '#d3d5fd'},
            command: () => Props.OnResponse(null)
        },
        {
            label: 'Regresion',
            icon: 'fas fa-ticket-alt',
            className:'lb',
            command: Props.NuevoItemPlanDePruebasRegresion
        }
    ]

    const [ Filtro, setFiltro] = useState(null)
    const [ menu, setMenu]: any = useState(null)
    const RefPendiente = React.createRef<HTMLInputElement>()
    const RefEnCurso = React.createRef<HTMLInputElement>()
    const RefHecho = React.createRef<HTMLInputElement>()

    const NSPendiente = useNearScreen({
        externalRef :  !Props.Pendiente.Loading && RefPendiente,
        once: false
    }).isNearScreen

    const NSEnCurso = useNearScreen({
        externalRef : !Props.EnCurso.Loading && RefEnCurso,
        once: false
    }).isNearScreen

    const NSHecho = useNearScreen({
        externalRef : !Props.Hecho.Loading && RefHecho,
        once: false
    }).isNearScreen

    const DebounceNextPage = useCallback((Estado : string) => {
        debounce(Props.Paginacion(Estado),5000)
    },[])

    useEffect(function (){
        if(NSPendiente) DebounceNextPage('Pendiente')
        if(NSEnCurso) DebounceNextPage('EnCurso')
        if(NSHecho) DebounceNextPage('Hecho')
    })

    const DragStart = (Event : any) => {
        Event.dataTransfer.setData('EstadoPKID', Event.target.id)
    }

    const DragOver = (Event : React.SyntheticEvent) => {
        Event.preventDefault()
        Event.stopPropagation()
    }

    const DropPendiente = (Event : React.DragEvent) => {
        Event.preventDefault()
        Props.MoverEstado(Event.dataTransfer.getData('EstadoPKID').split('-')[0],'Pendiente', Event.dataTransfer.getData('EstadoPKID').split('-')[1])
    }

    const DropEnCurso = (Event : React.DragEvent) => {
        Event.preventDefault()
        Props.MoverEstado(Event.dataTransfer.getData('EstadoPKID').split('-')[0],'EnCurso', Event.dataTransfer.getData('EstadoPKID').split('-')[1])
    }

    const DropHecho = (Event : React.DragEvent) => {
        Event.preventDefault()
        Props.MoverEstado(Event.dataTransfer.getData('EstadoPKID').split('-')[0],'Hecho', Event.dataTransfer.getData('EstadoPKID').split('-')[1])
    }

    const RenderListado = (ItemsPlanDePruebas: any, Estado : string) => {
        let Lista = ItemsPlanDePruebas.map((Entidad: any) => {
            return(
                <li
                id = {Estado + '-' + Entidad.PKID}
                draggable
                onDragStart = {DragStart}
                onDragOver = {DragOver}
                key = {Estado + '-' + Entidad.PKID}
                    onClick={ () => Props.OnResponse(Entidad)}>
                    <div className="comment-main-level">
                        <div className="comment-box">
                            <div className="comment-head">
                                <h6 className="comment-name by-author" >
                                    {Entidad.Nombre}
                                </h6>
                            </div>
                            <div className="comment-content">
                                <div>
                                    {Entidad.Requerimiento!==null && Entidad.Requerimiento!==undefined?
                                    <div>
                                        <table className="table table-bordered table-sm">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Requerimiento</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td >{Entidad.Requerimiento.Requisito}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table className="table table-bordered table-sm">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Fecha Inicio</th>
                                                    <th scope="col">Fecha Fin</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td ><p>{Entidad.Requerimiento.FechaInicio? new Date(Entidad.Requerimiento.FechaInicio).getDate() + "/" + (new Date(Entidad.Requerimiento.FechaInicio).getMonth() + 1) + "/" + new Date(Entidad.Requerimiento.FechaInicio).getFullYear():''}</p></td>
                                                    <td ><p>{Entidad.Requerimiento.FechaFin? new Date(Entidad.Requerimiento.FechaFin).getDate() + "/" + (new Date(Entidad.Requerimiento.FechaFin).getMonth() + 1) + "/" + new Date(Entidad.Requerimiento.FechaFin).getFullYear():''}</p></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    :
                                    <div>Es de Regresión</div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            )
        })
        return Lista
    }

    const Header = (
        <div className="table-header" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
            <div  style={{width:'15%'}}></div>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText 
                    type="search" 
                    onInput={(Event: any) => setFiltro(Event.target.value)} 
                    placeholder="Buscar..."/>
            </span>
        </div>
    )

    const ActionBodyTemplate = (Event: any) => {
        return(
            <p>
                {
                Event.FechaCreacion? 
                    new Date(Event.FechaCreacion).getDate() + "/" + (new Date(Event.FechaCreacion).getMonth() + 1) + "/" + new Date(Event.FechaCreacion).getFullYear()
                    :
                    ''
                }
            </p>
        )
    }

    return(
        <div className="ContainerTrello">
            <div className="p-grid" style={{margin: '0px'}}>
                <div className="p-col-12 p-md-6 p-lg-4">
                    <Menu model={Items} popup ref={(El: any) => setMenu(El)} id="popup_menu" />
                    <div className="list-wrapper">
                        <div className="list" style={{height:'50vh'}}>
                            <div className="list-header">
                                <h2 className="list-header-name-assist">Pendiente</h2>
                                <div 
                                    className="open-card-composer" 
                                    onClick={(event) => menu.toggle(event)}>
                                    <i className="pi pi-plus icon-sm"></i>
                                    <span>Nuevo</span>
                                </div>
                            </div>
                            <div 
                                className="list-cards"
                                onDrop={DropPendiente}
                                onDragOver={DragOver}>
                                <ul id="lPendiente" className="comment-list">
                                    {RenderListado(Props.Pendiente.Lista,'Pendiente')}
                                </ul>
                                <div ref={RefPendiente}>
                                    {((Props.Pendiente.Paginacion.NumeroPagina < Props.Pendiente.Paginacion.NumeroPaginas) || Props.Pendiente.Loading)? 
                                        <div className="circleLoad"></div> 
                                        : 
                                        <></>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-col-12 p-md-6 p-lg-4">
                    <div className="list-wrapper">
                        <div className="list" style={{height:'50vh'}}>
                            <div className="list-header is-menu-shown">
                                <h2 className="list-header-name-assist">En Curso</h2>
                            </div>
                            <div 
                                className="list-cards"
                                onDrop={DropEnCurso}
                                onDragOver={DragOver}>
                                <ul id="lEnCurso" className="comment-list">
                                    {RenderListado(Props.EnCurso.Lista,'EnCurso')}
                                </ul>
                                <div ref={RefEnCurso}>
                                    {((Props.EnCurso.Paginacion.NumeroPagina < Props.EnCurso.Paginacion.NumeroPaginas) || Props.EnCurso.Loading)? 
                                        <div className="circleLoad"></div> 
                                        : 
                                        <></>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-col-12 p-md-6 p-lg-4">
                    <div className="list-wrapper">
                        <div className="list" style={{height:'50vh'}}>
                            <div className="list-header is-menu-shown">
                                <h2 className="list-header-name-assist">Hecho</h2>
                            </div>
                            <div 
                                className="list-cards"
                                onDrop={DropHecho}
                                onDragOver={DragOver}>
                                <ul id="lHecho" className="comment-list">
                                    {RenderListado(Props.Hecho.Lista,'Hecho')}
                                </ul>
                                <div ref={RefHecho}>
                                    {((Props.Hecho.Paginacion.NumeroPagina < Props.Hecho.Paginacion.NumeroPaginas) || Props.Hecho.Loading)? 
                                        <div className="circleLoad"></div> 
                                        : 
                                        <></>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-col-12">
                    <div className="p-col">
                        <div className="list-header is-menu-shown">
                            <h2 className="list-header-name-assist lbls">Casos de Prueba del Proyecto</h2>
                        </div>
                        <DataTable  
                            value={Props.ListaCasosPruebaVersion} 
                            paginator
                            className="p-datatable-sm"
                            columnResizeMode="fit"
                            rows={10}
                            rowsPerPageOptions={[10,25,30]}
                            globalFilter={Filtro}
                            header={Header}>
                            <Column field="Nombre" header="Nombre" sortable filter filterMatchMode="contains" filterPlaceholder="Buscar Nombre..."></Column>
                            <Column field="Producto.Nombre" header="Producto" sortable></Column>
                            <Column field="Modulo.Nombre" header="Modulo" sortField="Modulo.Nombre" sortable filter filterMatchMode="contains" filterPlaceholder="Buscar Modulo..." ></Column>
                            <Column field="TipoPrueba.Nombre" header="Tipo Prueba" sortable></Column>
                            <Column body={ActionBodyTemplate} header="Fecha de Creación"></Column>
                            <Column field="Empleado.Nombre" header="Responsable" sortable filter filterMatchMode="contains" filterPlaceholder="Buscar Empleado..."></Column>
                            <Column field="Version" header="Version" sortable></Column>
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
    )
}
