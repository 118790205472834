import { UsuarioRepository } from '../../Infrastructure/Repositories/UsuarioRepository'
import { ICredencial } from '../Models/ICredencial'
import { ICambioContraseña } from '../Models/ICambioContraseña'
import { IUsuario } from '../Models/IUsuario'

const Autenticar = (body: ICredencial) => {
    if(body.isValid) return UsuarioRepository.Auntenticar(body)
    else throw new Error("Confirma que no eres un robot.")
}

const CambiarContraseña = (body : ICambioContraseña) => {
    if(body.NuevaContrasena !== body.RepetirNuevaContrasena) throw new Error("Las nuevas contraseñas son distintas.")
    else{
        if(body.AntiguaContrasena === body.NuevaContrasena) throw new Error("No se puede utilizar la antigua contraseña, como nueva.")
        else{
            if(ValidarExpresion(body.NuevaContrasena)) return UsuarioRepository.CambioContraseña(body)
            else throw new Error("No se acepta la contraseña, la contraseña debe contener: \n 1. Minimo de 8 caracteres y maximo de 15 caracteres. \n 2. Al menos una letra mayúscula, minuscula. \n 3. Al menos un digito numerico. \n 4. No se aceptan espacios en blanco. \n 5. Al menos un caracter especial (@, $, !, %, *, ?, &).")
        }
    } 
}

const ValidarExpresion = (Contrasena : string) => {
    var Band = false
    var regex = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])([A-Za-z\d$@$!%*?&]|[^ ]){8,15}$/)
    if(regex.test(Contrasena)) Band = !Band
    return Band
}

export const UsuarioService = {
    Autenticar,
    Listar: (IDUsuario : number) => {
        return UsuarioRepository.Listar(IDUsuario)
    },
    CrearActualizar: async (body : IUsuario) => {
        return UsuarioRepository.CrearActualizar(body)
    },
    CambiarContraseña,
    Consultar: async (IDUsuario: number) => {
        return UsuarioRepository.Consultar(IDUsuario)
    },
    RegistrarAsistencia: async (IDUsuario : number) => {
        return UsuarioRepository.RegistrarAsistencia(IDUsuario)
    },
    RegistrarSalida: async (IDUsuario : number) => {
        return UsuarioRepository.RegistrarSalida(IDUsuario)
    }
}