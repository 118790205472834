import React from 'react'
import { InputText } from 'primereact/inputtext'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default function HeaderCasoPruebaVersion(props){

    const EditorDescripcion = (e, editor) => {
        const data = editor.getData();
        props.OnChange({
            target:{
                name:"Descripcion",
                value:data
            }
        })
    }

    return(
        <div className="p-col-12">
            <div className="p-grid nested-grid">
                <div className="p-col-12" style={{border:'1px solid #4D80E4'}}>
                    <div className="p-grid nested-grid">
                        <div className="p-col-12 p-lg-6">
                            <div className="p-col">
                                <div className="p-grid p-fluid p-mr-1 p-ml-1">
                                    <div className="p-col-12">
                                        <label className="p-d-block" 
                                            style={{
                                                color: '#4D80E4',
                                                fontFamily: 'Sarala',
                                                fontSize: '20px'}}>Nombre</label>
                                        <InputText
                                            name="Nombre"
                                            disabled
                                            value={props.CasoPruebaVersion.Nombre}
                                            type="text" />
                                    </div>
                                    <div className="p-col-12">
                                        <label className="p-d-block" 
                                            style={{
                                                color : '#4D80E4',
                                                fontFamily : 'Sarala',
                                                fontSize : '20px'}}>Modulo</label>
                                        <InputText
                                            name="Modulo"
                                            disabled
                                            value={props.CasoPruebaVersion.Modulo.Nombre}
                                            type="text"/>
                                    </div>
                                    <div className="p-col-12">
                                        <label className="p-d-block" 
                                            style={{
                                                color: '#4D80E4',
                                                fontFamily : 'Sarala',
                                                fontSize: '20px'}}>Responsable</label>
                                        <InputText
                                            name="Empleado"
                                            disabled
                                            value={props.CasoPruebaVersion.Empleado.Nombre}
                                            type="text" />
                                    </div>
                                    <div className="p-col-12">
                                        <label className="p-d-block" 
                                            style={{
                                                color: '#4D80E4',
                                                fontFamily : 'Sarala',
                                                fontSize: '20px'}}>Producto</label>
                                        <InputText
                                            name="Producto"
                                            disabled
                                            value={props.CasoPruebaVersion.Producto.Nombre}
                                            type="text" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12 p-lg-6">
                            <div className="p-col">
                                <div className="p-grid p-fluid p-mr-1 p-ml-1">
                                    <div className="p-col-12">
                                        <label className="p-d-block" 
                                            style={{
                                                color : '#4D80E4',
                                                fontFamily : 'Sarala',
                                                fontSize : '20px'}}>Descripcion</label>
                                        <CKEditor
                                            editor={ ClassicEditor }
                                            data={props.CasoPruebaVersion.Descripcion}
                                            disabled
                                            onChange={EditorDescripcion}/>
                                    </div>
                                    <div className="p-col-12">
                                        <label className="p-d-block" 
                                            style={{
                                                color : '#4D80E4',
                                                fontFamily : 'Sarala',
                                                fontSize : '20px'}}>Tipo de Prueba</label>
                                        <InputText
                                            name="Empleado"
                                            disabled
                                            value={props.CasoPruebaVersion.TipoPrueba.Nombre}
                                            type="text"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    )
}